import React, { Fragment } from 'react'
import {
  Box,
  Card,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import Label from './Common/Label'
import { useSelector } from 'react-redux'
import { useGetPaletteColorsQueryQuery } from '../../../../../api/configuration'
import ColorPicker from 'react-best-gradient-color-picker'
import TransparentBg from '../../../../../assets/Image/TransparentBg.png'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export const Color = (props) => {
  const {
    value,
    onChange,
    name = 'Color',
    helperText = '',
    disabled = false,
  } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [selectedTab, setSelectedTab] = React.useState(0)
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const { data: paletteData, isLoading: paletteDataLoading } =
    useGetPaletteColorsQueryQuery(
      {
        slug: 'palette',
        activeSite,
      },
      {
        skip: !activeSite,
      },
    )

  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} helperText={helperText} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                size={'small'}
                label="Palette"
                sx={{ textTransform: 'none' }}
                disabled={disabled}
              />
              <Tab
                label="Custom"
                sx={{ textTransform: 'none' }}
                disabled={disabled}
              />
            </Tabs>
          </Box>
          {selectedTab === 0 && (
            <Box sx={{ p: 1 }}>
              <Grid container spacing={2}>
                {paletteDataLoading && (
                  <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                    <LinearProgress />
                  </Grid>
                )}
                {!paletteDataLoading &&
                paletteData &&
                paletteData.length > 0 ? (
                  <Fragment>
                    {paletteData.map((color, index) => {
                      return (
                        <Grid item key={index}>
                          <Card
                            raised
                            onClick={() => {
                              if (!disabled) {
                                onChange(color)
                              }
                            }}
                            sx={{
                              position: 'relative',
                              background: color,
                              border:
                                value === color
                                  ? '3px solid #263238'
                                  : '3px solid white',
                              p: 2.5,
                              cursor: disabled ? 'not-allowed' : 'pointer',
                              borderRadius: '50%',
                              overflow: 'visible',
                            }}
                          >
                            {value === color && (
                              <CheckBoxIcon
                                sx={{
                                  position: 'absolute',
                                  fontSize: '1.3rem',
                                  top: 10,
                                  right: 10,
                                  color: '#263238',
                                }}
                              />
                            )}
                          </Card>
                        </Grid>
                      )
                    })}
                  </Fragment>
                ) : (
                  <Typography variant="caption">
                    No palettes added yet!
                  </Typography>
                )}
                <Grid item>
                  <Card
                    raised
                    onClick={() => {
                      if (!disabled) {
                        onChange('transparent')
                      }
                    }}
                    sx={{
                      backgroundImage: `url(${TransparentBg})`,
                      cursor: disabled ? 'not-allowed' : 'pointer',
                      p: 2.5,
                      borderRadius: '50%',
                      overflow: 'visible',
                      position: 'relative',
                      border:
                        value === 'transparent'
                          ? '3px solid #263238'
                          : '3px solid white',
                    }}
                  >
                    {value === 'transparent' && (
                      <CheckBoxIcon
                        sx={{
                          position: 'absolute',
                          fontSize: '1.3rem',
                          top: 10,
                          right: 10,
                          color: 'green',
                        }}
                      />
                    )}
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}
          {selectedTab === 1 && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  p: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ColorPicker
                  value={value}
                  onChange={onChange}
                  hidePresets={true}
                  hideEyeDrop={true}
                  hideAdvancedSliders={true}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Color
