import { Box, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import withRights from '../../components/withRights'
import ProductMetrics from './ProductMetrics/ProductMetrics'
// import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
// import BusinessMetrics from './BusinessMetrics/BusinessMetrics'
import TechMetrics from './TechMetrics/TechMetrics'
import {
  useGetMediaCountQuery,
  useGetPagesCountQuery,
  useGetPostsCountQuery,
  useGetUserCountQuery,
} from '../../api/product'
import { useSelector } from 'react-redux'

const ProductDashboard = (props) => {
  const userInfo = useSelector((state) => state.session.userInfo)
  const [userMetrics, setUserMetrics] = useState({
    tenantOnBoarded: '',
    usersOnBoarded: '',
    usersCountByTenants: [],
  })
  const [pageMetrics, setPageMetrics] = useState({
    pagesCreated: null,
  })
  const [postMetrics, setPostMetrics] = useState({
    postsCreated: null,
  })
  const [fileMetrics, setFileMetrics] = useState({
    filesUploaded: null,
    filesCountByTenants: [],
  })

  const {
    data: userCountData,
    isSuccess: userCountSuccess,
    isError: userCountError,
  } = useGetUserCountQuery({
    userInfo,
  })

  const {
    data: mediaCountData,
    isSuccess: mediaCountSuccess,
    isError: mediaCountError,
  } = useGetMediaCountQuery(
    {
      userInfo,
    },
    {
      skip: !(userCountSuccess || userCountError),
    },
  )

  const {
    data: pageCountData,
    isSuccess: pageCountSuccess,
    isError: pageCountError,
  } = useGetPagesCountQuery(
    {
      userInfo,
    },
    {
      skip: !(mediaCountSuccess || mediaCountError),
    },
  )

  const { data: postCountData } = useGetPostsCountQuery(
    {
      userInfo,
    },
    {
      skip: !(pageCountSuccess || pageCountError),
    },
  )

  useEffect(() => {
    if (userCountData && userCountData.length > 0) {
      const updated = { ...userMetrics }
      const formattedData = []
      updated.tenantOnBoarded = userCountData.length
      let totalUsers = 0
      userCountData.forEach((userData) => {
        totalUsers = userData.users + totalUsers
        formattedData.push({
          Site: userData.siteName,
          Description: userData.description,
          'No of users': userData.users,
          'Active users': userData.active_users,
        })
      })
      formattedData.sort((a, b) => b['No of users'] - a['No of users'])
      updated.usersOnBoarded = totalUsers
      updated.tenantInfo = formattedData.map((item) => {
        const { 'Active users': active_users, ...rest } = item
        return rest
      })
      updated.usersCountByTenants = formattedData
      setUserMetrics(updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCountData])

  useEffect(() => {
    if (mediaCountData && mediaCountData.length > 0) {
      const updated = { ...mediaCountData }
      const formattedData = []
      let filesUploaded = 0
      mediaCountData.forEach((mediaData) => {
        filesUploaded = mediaData.media + filesUploaded
        formattedData.push({
          Site: mediaData.siteName,
          Description: mediaData.description,
          'No of media files': mediaData.media,
        })
      })
      formattedData.sort(
        (a, b) => b['No of media files'] - a['No of media files'],
      )
      updated.filesUploaded = filesUploaded
      updated.filesCountByTenants = formattedData
      setFileMetrics(updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaCountData])

  useEffect(() => {
    if (pageCountData) {
      const { data = [] } = pageCountData
      const updated = { ...data }
      const formattedData = []
      const formattedPublished = []
      let pagesCreated = 0
      let pagesPublished = 0
      data.forEach((pageData) => {
        pagesCreated = pageData.pages_created + pagesCreated
        pagesPublished = pageData.pages_published + pagesPublished
        formattedData.push({
          Site: pageData.siteName,
          Description: pageData.description,
          'No of pages created': pageData.pages_created,
        })
        formattedPublished.push({
          Site: pageData.siteName,
          Description: pageData.description,
          'No of pages published': pageData.pages_published,
        })
      })
      formattedData.sort(
        (a, b) => b['No of pages created'] - a['No of pages created'],
      )
      formattedPublished.sort(
        (a, b) => b['No of pages published'] - a['No of pages published'],
      )
      updated.pagesCreated = pagesCreated
      updated.pagesPublished = pagesPublished
      updated.pagesByTenants = formattedData
      updated.pagesPublishByTenants = formattedPublished
      setPageMetrics(updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCountData])

  useEffect(() => {
    if (postCountData && postCountData.length > 0) {
      const updated = { ...postCountData }
      const formattedData = []
      const formattedPublished = []
      let postsCreated = 0
      let postsPublished = 0
      postCountData.forEach((postData) => {
        postsCreated = postData.posts_created + postsCreated
        postsPublished = postData.posts_published + postsPublished
        formattedData.push({
          Site: postData.siteName,
          Description: postData.description,
          'No of posts created': postData.posts_created,
        })
        formattedPublished.push({
          Site: postData.siteName,
          Description: postData.description,
          'No of posts published': postData.posts_published,
        })
      })
      formattedData.sort(
        (a, b) => b['No of posts created'] - a['No of posts created'],
      )
      formattedPublished.sort(
        (a, b) => b['No of posts published'] - a['No of posts published'],
      )
      updated.postsCreated = postsCreated
      updated.postsPublished = postsPublished
      updated.postsByTenants = formattedData
      updated.postsPublishByTenants = formattedPublished
      setPostMetrics(updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCountData])

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <>
        <Box sx={{ display: 'flex', background: '#E6F0FB', px: 2, py: 3.2 }}>
          <Box>
            <Typography
              variant={'h5'}
              component={'h3'}
              sx={{
                fontFamily: 'Helvetica For Target',
                fontWeight: 'Bold',
                // marginBottom: 1,
                color: '#303F9F',
                mr: 2,
                fontSize: 28,
              }}
              data-testid="greeting-head"
            >
              Welcome Back,{' '}
              <span style={{ fontSize: 18, color: 'rgb(121, 134, 196)' }}>
                <span style={{ color: 'rgba(204, 134, 196)' }}>Stencil</span>{' '}
                Administrator
              </span>
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: 'darkblue' }}
              data-testid="caption-content"
            >
              The product dashboard - The data's related to tenant activities,
              product performance and metrics
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </>
      <ProductMetrics
        data={{
          ...userMetrics,
          ...pageMetrics,
          ...postMetrics,
          ...fileMetrics,
        }}
      />
      {/* <BusinessMetrics /> */}
      <TechMetrics />
    </Box>
  )
}

const moduleAttr = {
  name: 'ProductDashboard',
  actions: [],
}

export default withRights(ProductDashboard, moduleAttr)
