import React, { useState, useEffect } from 'react'
import {
  Box,
  SwipeableDrawer,
  Typography,
  TablePagination,
} from '@mui/material'
import AddExistingSpeakers from './AddExistingSpeakers'
import SpeakersList from './SpeakersList'
import { NOSPEAKERS } from '../../../../assets/Image'
import { ModuleHead, NoRecords } from '../Common'
import { useSelector } from 'react-redux'
import {
  useListSpeakerByEventQuery,
  useRemoveSpeakersMutation,
} from '../../../../api/event'
import { useNavigate } from 'react-router-dom'
import DeleteSpeaker from './DeleteSpeaker'
import { Loader, withRights } from '../../../../components'
import AccessDenied from '../../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../../utils/rightUtils'

// const listOfYears = Array.from(
//   { length: 5 },
//   (_, i) => getCurrentYear - i
// ).reverse()

const Speakers = (props) => {
  const {
    event = {},
    editDisabled = false,
    rights = {},
    rightsLoading,
    noSiteAccess = false,
  } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const { id = null } = event
  const [addSpeakersButton, setAddSpeakersButton] = useState(false)
  const [listType, setListType] = React.useState('list')
  const [speakerListData, setSpeakerListData] = useState('')
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [search, setSearch] = React.useState('')
  const [speakersToDelete, setSpeakersToDelete] = useState([])
  const [deleteConfirm, initDeleteConfirm] = useState(false)
  const [pageVal, setPageVal] = React.useState(0)
  const [perpage, setPerPage] = React.useState(12)
  const [totalSpeakers, setTotalSpeakers] = useState(0)
  const navigate = useNavigate()

  const { data: speakersList, isLoading: speakersLoading } =
    useListSpeakerByEventQuery(
      { search, eventid: id, activeSite, pageVal: pageVal + 1, perpage },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  const [
    removeSpeaker,
    { isLoading: removeSpeakersLoading, isSuccess: removeSpeakerSuccess },
  ] = useRemoveSpeakersMutation()

  useEffect(() => {
    if (removeSpeakerSuccess) {
      initDeleteConfirm(false)
    }
    if (speakersList) {
      setSpeakerListData(speakersList.data)
      setTotalSpeakers(speakersList.total)
    }
  }, [speakersList, removeSpeakerSuccess])

  const handleSpeakers = () => {
    setAddSpeakersButton(!addSpeakersButton)
  }

  const navigateSpeakers = () => {
    navigate(`/events/speakers/create/${id}`)
  }

  const actionsAllowed = {
    userCanCreateSpeaker: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
    userCanAddSpeaker: checkUserIsAllowed('add', permittedActions, superRights),
    userCanEditSpeaker: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
    ),
    userCanDeleteSpeaker: checkUserIsAllowed(
      'delete',
      permittedActions,
      superRights,
    ),
    userCanViewSpeaker: checkUserIsAllowed(
      'view',
      permittedActions,
      superRights,
    ),
  }

  const actionButtons = [
    {
      label: 'Add Speakers',
      variant: 'outlined',
      cb: handleSpeakers,
      disabled: !actionsAllowed?.userCanAddSpeaker,
    },
    {
      label: 'Create New Speaker',
      cb: navigateSpeakers,
      disabled: !actionsAllowed?.userCanCreateSpeaker,
    },
  ]

  const onDelete = () => {
    if (speakersToDelete.length > 0) {
      removeSpeaker({
        ids: speakersToDelete,
        eventid: id,
        activeSite,
      })
    }
  }
  const openDeleteDialog = () => {
    initDeleteConfirm(true)
  }

  const handleChangePage = (event, newPage) => {
    setPageVal(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    setPageVal(0)
  }

  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess ? (
    <>
      <Box data-testid="participant-wrapper">
        <ModuleHead
          search={search}
          setSearch={setSearch}
          listType={listType}
          setListType={setListType}
          actionButtons={actionButtons}
          onDelete={openDeleteDialog}
          allActionsDisabled={editDisabled}
          hideViewBtns={true}
          rights={rights}
          disableDelete={
            speakerListData.length === 0 ||
            !actionsAllowed?.userCanDeleteSpeaker
          }
        />
      </Box>
      {speakerListData && speakerListData.length === 0 ? (
        <NoRecords
          btns={[
            {
              label: 'Add a speaker',
              cb: handleSpeakers,
              disabled: !actionsAllowed?.userCanAddSpeaker,
            },
            {
              label: 'Create a new speaker',
              cb: navigateSpeakers,
              disabled: !actionsAllowed?.userCanCreateSpeaker,
            },
          ]}
          img={NOSPEAKERS}
          editDisabled={editDisabled}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Add an existing speaker or create a new speaker <br />
          </Typography>
        </NoRecords>
      ) : (
        <>
          <SpeakersList
            speakerListData={speakerListData}
            speakersLoading={speakersLoading}
            speakersToDelete={speakersToDelete}
            removeSpeakersLoading={removeSpeakersLoading}
            removeSpeakerSuccess={removeSpeakerSuccess}
            setSpeakersToDelete={setSpeakersToDelete}
            eventId={id}
            editDisabled={editDisabled}
            actionsAllowed={actionsAllowed}
          ></SpeakersList>

          <TablePagination
            component="div"
            count={totalSpeakers}
            page={pageVal}
            onPageChange={handleChangePage}
            rowsPerPage={perpage}
            rowsPerPageOptions={[10, 12, 25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              '& .MuiTablePagination-toolbar': {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'baseline',
              },
            }}
          />
        </>
      )}
      <SwipeableDrawer
        anchor={'right'}
        open={addSpeakersButton}
        onClose={() => handleSpeakers()}
        onOpen={() => handleSpeakers()}
        PaperProps={{
          sx: {
            width: '25%',
            // marginTop: '65px',
          },
        }}
      >
        <AddExistingSpeakers
          onClose={() => handleSpeakers()}
        ></AddExistingSpeakers>
      </SwipeableDrawer>
      <DeleteSpeaker
        onConfirm={onDelete}
        onClose={() => initDeleteConfirm(false)}
        open={deleteConfirm}
      />
    </>
  ) : (
    <AccessDenied module="Speakers" />
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'View',
      access: 'View',
    },
    {
      slug: 'Add',
      access: 'Add',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Create',
      access: 'Create',
    },
    {
      slug: 'Delete',
      access: 'Delete',
    },
  ],
}

export default withRights(Speakers, moduleAttr)
