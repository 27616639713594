import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider, useAuth } from '@praxis/component-auth'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AnalyticsProvider, useAnalytics } from '@praxis/component-analytics'
import { store } from './store'
import { Provider, useDispatch } from 'react-redux'
import Loader from './components/Loader'
import apiConfig from './apiConfig'
import './App.scss'
import 'canvas-ui-css-marquette'
import 'react-sortable-tree/style.css'
import Layout from './Layout/Layout'
import { setUserSession } from './api/session'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import moment from '@date-io/moment'
import { statusColors } from './utils/themeHelper'
import 'pattern.css'
import '@enterprise-ui/canvas-ui-css'
import 'react-quill/dist/quill.bubble.css'
import 'stencil-tenant/dist/styles.css'
import { CookiesProvider } from 'react-cookie'
import { useIdleTimer } from 'react-idle-timer'
import { useUpdateMyActivityMutation } from './api/profile'
import { useSelector } from 'react-redux'
// import { IconButton } from '@mui/material'
import 'stencil-tenant/dist/styles.css'

const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

const Main = (props) => {
  const auth = useAuth() // connect to the `AuthContext` provided by `App` above, using a React hook.
  const { isAuthorized, login, logout, session } = auth
  const colorMode = React.useContext(ColorModeContext)
  const { trackClick } = useAnalytics()
  const dispatch = useDispatch()

  const [updateMyActivity] = useUpdateMyActivityMutation()
  const userId = useSelector((state) => state?.session?.profile?._id)

  const { getActiveTime } = useIdleTimer({
    timeout: 120_000,
    throttle: 500,
    eventsThrottle: 200,
    immediateEvents: ['visibilitychange'],
  })

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      trackClick('main')
    }
  }, [trackClick])

  useEffect(() => {
    if (!isAuthorized()) {
      login()
    } else {
      const { userInfo, identityToken } = session
      dispatch(setUserSession({ userInfo, identityToken }))
    }
  }, [login, isAuthorized, dispatch, session])

  useEffect(() => {
    const onVisibilityChange = async () => {
      if (isAuthorized()) {
        if (document.visibilityState === 'hidden') {
          const activityTimeSecs = getActiveTime() / 1000
          let level = ''
          if (activityTimeSecs < 300) {
            return
          } else if (activityTimeSecs < 600) {
            level = 'low'
          } else if (activityTimeSecs < 900) {
            level = 'medium'
          } else {
            level = 'high'
            document.removeEventListener('visibilitychange', onVisibilityChange)
          }
          const lastUpdateTime = sessionStorage.getItem(`cms-activity-${level}`)
          if (
            !lastUpdateTime ||
            Date.now() - parseInt(lastUpdateTime) > 86400000
          ) {
            updateMyActivity({
              userid: userId,
              level,
            })
            sessionStorage.setItem(`cms-activity-${level}`, Date.now())
          }
        }
      }
    }
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <React.Fragment>
      {isAuthorized() && (
        <LocalizationProvider dateAdapter={moment}>
          <Layout
            {...props}
            toggleDarkMode={colorMode.toggleColorMode}
            logout={logout}
          />
        </LocalizationProvider>
      )}
    </React.Fragment>
  )
}

const App = () => {
  const [mode, setMode] = React.useState('light')
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    [],
  )

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...statusColors,
        },
        typography: {
          fontFamily: ['Helvetica For Target'],
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: `
              @font-face {
                font-family: 'HelveticaForTarget';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                src: local('HelveticaForTarget'), local('HelveticaForTarget'), url(${HelveticaForTarget}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
              }
            `,
          },
        },
      }),
    [mode],
  )

  return (
    <Provider store={store}>
      <AuthProvider
        clientId={apiConfig.auth.clientId}
        authorizationUrl={apiConfig.auth.authorizationUrl}
        logoutUrl={apiConfig.logoutUrl}
        loadingIndicator={Loader}
      >
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <HelveticaForTarget
            variants={['n4', 'i1', 'n7']}
            loaderConfig={{
              fontactive: (familyName, fvd) => {
                console.log(`${familyName} ${fvd} active. do something...`)
              },
            }}
          />
          <Router>
            <AnalyticsProvider
              eventManagerConfiguration={apiConfig.analytics}
              trackLocations={process.env.REACT_APP_ENV === 'prod'}
            >
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <SnackbarProvider maxSnack={3}>
                    <Main />
                  </SnackbarProvider>
                </ThemeProvider>
              </ColorModeContext.Provider>
            </AnalyticsProvider>
          </Router>
        </CookiesProvider>
      </AuthProvider>
    </Provider>
  )
}

export default App
