import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {
  Box,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
  LinearProgress,
  Alert,
  TextField,
} from '@mui/material'
import { Link as RLink } from 'react-router-dom'
import moment from 'moment'
import {
  getStatusColorOrLabel,
  draftVersionExistOrNot,
  STAGING_STATUS_LIST,
} from '../pageUtils'
import { Check, Close } from '@mui/icons-material'
import {
  useAddCommentMutation,
  useDeleteRevisionMutation,
  useGenerateNewDraftMutation,
  useGeneratePreviewMutation,
  useSendForApprovalMutation,
} from '../../../api/page'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ShowVersions(props) {
  const {
    open,
    close,
    data = {},
    userCanRollback = false,
    userCanEdit = false,
    siteData = {},
  } = props

  const { name = '', revisions = [], site = '' } = data

  const [selectedRevision, setSelectedRevision] = React.useState(null)
  const [selectedRevisionForDelete, setSelectedRevisionForDelete] =
    React.useState(null)
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [comment, setComment] = React.useState('')
  const { enqueueSnackbar } = useSnackbar()

  const draftExist = draftVersionExistOrNot(revisions)

  const [addCommentToPage] = useAddCommentMutation()

  const handleClose = () => {
    resetDraftMakingMutation()
    close(false)
  }

  const [
    generatePreview,
    {
      data: previewData,
      isSuccess: previewGenerated,
      isLoading: previewGenerationInProgress,
    },
  ] = useGeneratePreviewMutation()

  const [
    generateDraft,
    {
      data: generatedDraft,
      isError: generateDraftFailure,
      isLoading: draftGenerationInProgress,
      reset: resetDraftMakingMutation,
    },
  ] = useGenerateNewDraftMutation()

  const [
    changingPageStatus,
    {
      isSuccess: statusChangeCompleted,
      isError: statusChangeFailed,
      isLoading: statusChangeInProgress,
    },
  ] = useSendForApprovalMutation()

  const [
    deleteRevisionFn,
    {
      isSuccess: deleteRevisionSuccess,
      isError: deleteRevisionFailed,
      isLoading: deleteRevisionInProgress,
    },
  ] = useDeleteRevisionMutation()

  React.useEffect(() => {
    setSelectedRevision(null)
  }, [open])

  React.useEffect(() => {
    if (previewGenerated && previewData) {
      if (siteData && siteData.siteUrl && previewGenerated) {
        window.open(
          `${siteData.siteUrl}${data.slug ? data.slug : ''}?preview=${
            previewData ? previewData.token : ''
          }`,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewGenerated])

  const initiatePreview = (revision) => {
    generatePreview({
      id: revision.content,
      revision: revision.id,
    })
  }

  const initiateEdit = (revision) => {
    generateDraft({
      site: site,
      page: revision.content,
      revision: revision.id,
    })
  }

  const initiateRollback = (revision) => {
    setComment('')
    setSelectedRevision(revision)
    setSelectedRevisionForDelete(null)
  }

  const initDeleteVersion = (version) => {
    setSelectedRevisionForDelete(version)
    setSelectedRevision(null)
  }

  const changePageStatus = () => {
    addCommentToPage({
      id: selectedRevision.content,
      revision: selectedRevision.id,
      comment,
    })
    const payload = {
      id: selectedRevision.id,
      assignedto: userInfo.lanId,
      site: activeSite._id,
      status: 'published',
    }
    changingPageStatus(payload)
  }

  const deleteRevision = () => {
    addCommentToPage({
      id: selectedRevisionForDelete.content,
      revision: selectedRevisionForDelete.id,
      comment,
    })
    const payload = {
      revision: selectedRevisionForDelete.id,
      site: activeSite._id,
    }
    deleteRevisionFn(payload)
  }

  React.useEffect(() => {
    if (statusChangeCompleted) {
      setSelectedRevision(null)
      close()
      enqueueSnackbar('Rollback successful', {
        action: null,
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChangeCompleted])

  React.useEffect(() => {
    if (statusChangeFailed) {
      enqueueSnackbar('Rollback is failed!, Please try again', {
        action: null,
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChangeFailed])

  React.useEffect(() => {
    if (deleteRevisionSuccess) {
      setSelectedRevisionForDelete(null)
      close()
      enqueueSnackbar('Draft version deletion completed successfully!', {
        action: null,
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRevisionSuccess])

  React.useEffect(() => {
    if (deleteRevisionFailed) {
      enqueueSnackbar('Draft version deletion failed!, Please try again', {
        action: null,
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRevisionFailed])

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#5C5C5C',
            fontSize: 24,
          }}
          data-testid="show-versions-heading"
        >
          Version History
        </DialogTitle>
        {generatedDraft ? (
          <Alert icon={<Check fontSize="inherit" />} severity="success">
            The draft version generated. To edit page{' '}
            <strong>
              <RLink to={`/pages/layout/${generatedDraft.id}`}>
                click here
              </RLink>
            </strong>
          </Alert>
        ) : (
          <Box sx={{ pl: 5, pr: 5, minHeight: '55vh' }}>
            <Typography
              data-testid="show-versions-mod-pagename"
              variant="h3"
              sx={{ fontSize: 16, color: '#5C5C5C' }}
            >
              Pages /{' '}
              <span
                style={{ fontSize: 20, fontWeight: 'bold' }}
                data-testid="show-versions-pagename"
              >
                {name}
              </span>
            </Typography>
            <Box sx={{ mt: 3 }}>
              {(previewGenerationInProgress ||
                draftGenerationInProgress ||
                statusChangeInProgress) && <LinearProgress />}

              {generateDraftFailure && (
                <Alert severity="error">
                  Unable to create draft version!. Please try again.
                </Alert>
              )}
              {selectedRevision && (
                <Alert
                  severity="warning"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 2,
                    '& .MuiAlert-message': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                  action={
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{ textTransform: 'none', mr: 2 }}
                        onClick={changePageStatus}
                        disabled={statusChangeInProgress || !comment}
                      >
                        Yes, take it live
                      </Button>{' '}
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setSelectedRevision(null)
                        }}
                        sx={{ textTransform: 'none', mr: 2 }}
                      >
                        No, cancel
                      </Button>
                    </>
                  }
                >
                  Do you want to take{' '}
                  <strong>
                    {' '}
                    &nbsp;Version {selectedRevision.version}&nbsp;
                  </strong>{' '}
                  live?{' '}
                  <TextField
                    variant="standard"
                    placeholder="Enter your comment *"
                    sx={{ ml: 2 }}
                    size="small"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Alert>
              )}
              {selectedRevisionForDelete && (
                <Alert
                  severity="warning"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 2,
                    '& .MuiAlert-message': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                  action={
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        sx={{ textTransform: 'none', mr: 2 }}
                        onClick={deleteRevision}
                        disabled={deleteRevisionInProgress || !comment}
                      >
                        Delete
                      </Button>{' '}
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setSelectedRevisionForDelete(null)
                        }}
                        sx={{ textTransform: 'none', mr: 2 }}
                      >
                        Cancel
                      </Button>
                    </>
                  }
                >
                  Do you really want to delete the draft{' '}
                  <strong>
                    &nbsp;version {selectedRevisionForDelete.version}&nbsp;
                  </strong>{' '}
                  ?
                  <TextField
                    variant="standard"
                    placeholder="Enter the comment *"
                    sx={{ ml: 2 }}
                    size="small"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Alert>
              )}
              <Table
                size="small"
                sx={{
                  minWidth: 650,
                  border: 1,
                  borderColor: '#939393',
                  boxShadow: 0,
                  '& th': {
                    fontWeight: 'bold',
                    fontSize: 16,
                  },
                  '& td': {
                    fontSize: 14,
                  },
                  '& td, th': {
                    border: 1,
                    borderColor: '#939393',
                  },
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow scope="row">
                    <TableCell align="center" scope="col">
                      Version
                    </TableCell>
                    <TableCell align="center" scope="col">
                      Created On
                    </TableCell>
                    <TableCell align="center" scope="col">
                      Status
                    </TableCell>
                    <TableCell align="center" scope="col">
                      Comments
                    </TableCell>
                    <TableCell align="center" scope="col">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {revisions.map((revision, key) => (
                    <TableRow
                      key={key}
                      data-testid={`revision-row-${key}`}
                      scope="row"
                    >
                      <TableCell align="center" scope="col">
                        Version {revision.version}
                      </TableCell>
                      <TableCell align="center" scope="col">
                        {moment(revision.createdAt).format(
                          'DD/MM/YYYY h:mm:ss A',
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={(theme) => ({
                          color: theme.palette[revision.status?.status].main,
                          fontWeight: 'bold',
                        })}
                        scope="col"
                      >
                        {getStatusColorOrLabel(
                          revision.status?.status,
                          'label',
                        )}
                      </TableCell>
                      <TableCell align="center" scope="col">
                        {revision.comments ? revision?.comments : 'NA'}
                      </TableCell>
                      <TableCell align="center" scope="col">
                        {
                          <>
                            <Button
                              variant="text"
                              sx={{ textTransform: 'none', color: '#2171FF' }}
                              onClick={() => initiatePreview(revision)}
                              aria-label="opens new window of revision preview"
                            >
                              Preview
                            </Button>
                            {!STAGING_STATUS_LIST.includes(
                              revision.status?.status,
                            ) && (
                              <>
                                {' | '}
                                <Button
                                  variant="text"
                                  sx={{
                                    textTransform: 'none',
                                    color: '#2171FF',
                                  }}
                                  onClick={() => initiateEdit(revision)}
                                  disabled={!userCanEdit || draftExist}
                                  aria-label="Clone and make a draft"
                                >
                                  Clone and make a draft
                                </Button>
                              </>
                            )}
                            {!STAGING_STATUS_LIST.includes(
                              revision.status?.status,
                            ) && (
                              <>
                                {' | '}
                                <Button
                                  variant="text"
                                  sx={{
                                    textTransform: 'none',
                                    color: '#2171FF',
                                  }}
                                  disabled={
                                    revision.status?.status === 'published' ||
                                    !userCanRollback
                                  }
                                  onClick={() => initiateRollback(revision)}
                                  aria-label="Roll back to this resvision"
                                >
                                  Roll back
                                </Button>
                              </>
                            )}
                            {revision.status?.status === 'draft' && (
                              <>
                                {' | '}
                                <Button
                                  variant="text"
                                  sx={{
                                    textTransform: 'none',
                                    color: '#2171FF',
                                  }}
                                  disabled={!userCanRollback}
                                  onClick={() => initDeleteVersion(revision)}
                                  aria-label="Delete this revision"
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        )}
        <IconButton
          size="large"
          sx={{ position: 'absolute', right: 0, top: 0 }}
          onClick={handleClose}
          aria-label="Close the dialog"
        >
          <Close />
        </IconButton>
      </Dialog>
    </div>
  )
}
