import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Icon,
  Typography,
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import styled from '@emotion/styled'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <Icon {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const ChipFilters = (props) => {
  const {
    title = '',
    icon,
    options = [],
    value = [],
    onChange,
    type = 'multi',
    valueForSingle = null,
    bodyText = '',
    defaultExpanded = true,
    disabled = false,
    onlyOneAllowed = false,
  } = props
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  const handleToggle = (currentValue) => {
    onChange(
      onlyOneAllowed
        ? [currentValue]
        : type === 'multi'
        ? value.includes(currentValue)
          ? value.filter((c) => c !== currentValue)
          : [...value, currentValue]
        : currentValue,
    )
  }
  return (
    <>
      <Card
        data-testid="chip-filter-wrapper"
        sx={{
          m: 1,
          borderRadius: 3,
          width: '100%',
          pb: 0,
        }}
      >
        <CardActionArea onClick={() => setExpanded(!expanded)}>
          <CardActions
            disableSpacing
            sx={{ background: '#F7F7F7', p: 1, pl: 1, pr: 1 }}
          >
            <Box
              sx={{
                color: '#666666',
                '& .MuiSvgIcon-root': { fontSize: 18 },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {icon && icon}
              <Typography
                data-testid="filter-title"
                sx={{
                  ml: 1,
                  fontSize: 14,
                  fontWeight: 600,
                  color: '#333333',
                }}
              >
                {title}
              </Typography>
            </Box>
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
              size="small"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
        </CardActionArea>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            sx={{
              p: 1,
              '&:last-child': { pb: 1 },
            }}
          >
            {bodyText && (
              <Typography sx={{ fontSize: 14, color: '#333333' }}>
                {bodyText}
              </Typography>
            )}
            {options.length > 0 &&
              options.map((option, key) => {
                const label =
                  typeof option === 'object' ? option?.label : option
                const optValue =
                  typeof option === 'object' ? option?.value : option
                const selected =
                  type === 'multi'
                    ? value.includes(optValue)
                    : valueForSingle === optValue
                return (
                  <Chip
                    key={key}
                    label={label}
                    variant="outlined"
                    onClick={() => handleToggle(optValue)}
                    data-testid={`chip-option-${key}`}
                    disabled={disabled}
                    icon={
                      selected ? (
                        onlyOneAllowed || type === 'single' ? (
                          <RadioButtonCheckedIcon
                            sx={{ color: '#5FBF5F !important' }}
                          />
                        ) : (
                          <CheckBoxIcon sx={{ color: '#5FBF5F !important' }} />
                        )
                      ) : onlyOneAllowed || type === 'single' ? (
                        <RadioButtonUncheckedIcon sx={{ color: '#333' }} />
                      ) : (
                        <CheckBoxOutlineBlankIcon sx={{ color: '#333' }} />
                      )
                    }
                    sx={{
                      m: 0.8,
                      border: 0,
                      borderRadius: 0,
                      ...(selected
                        ? {
                            fontWeight: 700,
                          }
                        : {
                            color: '#333',
                          }),
                    }}
                  />
                )
              })}
          </CardContent>
        </Collapse>
      </Card>
    </>
  )
}

ChipFilters.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.any),
}

export default ChipFilters
