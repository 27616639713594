import React from 'react'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  SvgIcon,
  SwipeableDrawer,
} from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { ADVANCED_PARTICIPANTS_FILTERS, INITIAL_FILTER_VALUES } from '../Utils'
import _ from 'lodash'
import { ChipFilters } from '../../Common'
import * as mui from '@mui/icons-material'
import DateFilters from '../../Common/DateFilters/DateFilters'
import moment from 'moment'

export const AdvancedFilters = (props) => {
  const { open, setOpen, filters, setFilters } = props

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(false)}
      PaperProps={{
        sx: {
          width: '25%',
        },
      }}
    >
      <List sx={{ height: '26vh' }}>
        <ListItem>
          <ListItemText
            primary={`Choose Filters`}
            primaryTypographyProps={{
              fontSize: 20,
              fontWeight: 700,
              color: '#333333',
              p: 1,
            }}
          />
        </ListItem>
        <Divider sx={{ bgcolor: '#E6E6E6' }} />
        <ListItem sx={{ display: 'flex' }}>
          <ListItemText
            primary={`Choose the filters to find participants`}
            primaryTypographyProps={{
              fontSize: 14,
              color: '#888888',
            }}
          />
          <Button
            size="small"
            sx={{ mr: 1, fontSize: 12, textTransform: 'none' }}
            variant="contained"
            color="secondary"
            startIcon={<RestartAltIcon />}
            disabled={_.isEqual(INITIAL_FILTER_VALUES, filters)}
            onClick={() => setFilters(INITIAL_FILTER_VALUES)}
          >
            Clear Filters
          </Button>
        </ListItem>
        <Divider sx={{ bgcolor: '#E6E6E6' }} />
        {ADVANCED_PARTICIPANTS_FILTERS &&
          ADVANCED_PARTICIPANTS_FILTERS.map((filter, key) => {
            return (
              <Box key={key} sx={{ m: 1 }}>
                {filter?.type === 'date_period' ? (
                  <DateFilters
                    {...filter}
                    icon={<SvgIcon component={mui[filter?.icon]} />}
                    value={{
                      gte: filters[`${filter?.flag}_from`],
                      lt: filters[`${filter?.flag}_to`],
                    }}
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        [`${filter?.flag}_from`]: value?.gte
                          ? moment(value?.gte).format('YYYY-MM-DD')
                          : '',
                        [`${filter?.flag}_to`]: value?.lt
                          ? moment(value?.lt).format('YYYY-MM-DD')
                          : '',
                      })
                    }
                  />
                ) : (
                  <ChipFilters
                    {...filter}
                    icon={<SvgIcon component={mui[filter?.icon]} />}
                    value={filters[filter?.flag]}
                    valueForSingle={
                      filter?.type === 'single' ? filters[filter?.flag] : null
                    }
                    onChange={(value) =>
                      setFilters({ ...filters, [filter?.flag]: value })
                    }
                  />
                )}
                <Divider sx={{ mt: 1 }} />
              </Box>
            )
          })}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ textTransform: 'none', width: '35%', ml: 0.5, mr: 0.5 }}
            variant="outlined"
            onClick={() => {
              setFilters({ ...INITIAL_FILTER_VALUES })
              setOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'none', width: '35%', ml: 0.5, mr: 0.5 }}
            variant="contained"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Apply
          </Button>
        </Box>
      </List>
    </SwipeableDrawer>
  )
}

export default AdvancedFilters
