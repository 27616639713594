import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Button,
  Chip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
  Avatar,
  IconButton,
  LinearProgress,
  Tooltip,
  SwipeableDrawer,
  Popover,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  ButtonGroup,
  MenuList,
} from '@mui/material'
import { Check, CloseOutlined, ArrowDropDown } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import {
  useGetApproversListQuery,
  useGetUsersListQuery,
  useGetUserMapQuery,
} from '../../../../api/users'
import PreviewFullscreen from '../FullScreenPreview/PreviewFullscreen'
import PreviewIcon from '@mui/icons-material/Preview'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import { useGetConfigurationBySlugQuery } from '../../../../api/configuration'
import {
  useGeneratePreviewMutation,
  useSendForApprovalMutation,
  useGetCommentsQuery,
  useAddCommentMutation,
} from '../../../../api/page'
import {
  useSaveUsedMediaMutation,
  useGetUsedMediaQuery,
} from '../../../../api/page'
import { persistMediaList } from '../../../../api/session'
import { useSnackbar } from 'notistack'
import UpdateIcon from '@mui/icons-material/Update'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import ActivityPanel from './ActivityPanel'
import CommentsPanel from './CommentsPanel'
import AssetsPanel from './AssetsPanel'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import StatusStepper from './StatusStepper'
import { formatDate } from '../../../../utils/helper'
const TopLayout = (props) => {
  const {
    layout,
    updatePageLayout,
    updateInProgress,
    updateLayoutSuccess,
    status = {},
    id,
    pageId = '',
    reFetchPage,
    loadingPageData,
    pageActions = {},
    pageName = '',
    pageData,
    activity,
    reFetchStatus,
    pageSubStatus,
  } = props

  const [action, setAction] = useState(false)
  const [sendStatus, setSendStatus] = useState(false)
  const [sendToApprover, setSendToApprover] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const { pageChangesAllowed = false, addCommentAllowed = false } = pageActions

  const [openFullScreenPreview, setFullscreenPreview] = useState(false)
  const [initChangeStatus, changeStatus] = useState(false)
  const [approversList, setApproversList] = useState([])
  const [selectedApprover, setSelectedApprover] = useState()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const mediaArr = useSelector((state) => state.session.mediaList)
  const [siteData, setSiteData] = useState({})
  const [loadingPreviewButton, setLoadingPreviewButton] = useState(false)
  const initialStateIcons = {
    activityIcon: false,
    commentsIcon: false,
    assetsIcon: false,
  }
  const [iconClicked, setIconClicked] = React.useState(initialStateIcons)
  const [comments, setComments] = React.useState([])
  const [userData, setUsers] = React.useState([])
  const [currentComment, setCurrentComment] = React.useState('')
  const { data: users } = useGetUsersListQuery()

  const { data: commentsData } = useGetCommentsQuery(
    { id: pageId, activeSite },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeSite || !pageId,
    },
  )

  const [addCommentToPage, { isLoading: saveInProgress }] =
    useAddCommentMutation()

  useEffect(() => {
    if (commentsData) {
      setComments([...commentsData].reverse())
    }
  }, [commentsData])

  useEffect(() => {
    if (users) {
      setUsers(
        ...[
          users
            .filter((u) => u.title)
            .map((user) => {
              return {
                ...user,
                display: user.displayName,
                title: user.title ? user.title : 'Unknown',
              }
            }),
        ],
      )
    }
  }, [users])
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const { data: approversListData } = useGetApproversListQuery(activeSite, {
    refetchOnMountOrArgChange: true,
    skip: !activeSite,
  })

  const { data: usermap } = useGetUserMapQuery(activeSite?._id, {
    skip: !activeSite,
  })

  let tailoredUserGroup = usermap?.filter((obj) => {
    return selectedGroup === obj?._id
  })

  const { data: savedMediaList } = useGetUsedMediaQuery(
    { pageId: pageData?.content?.id, revisionId: pageData?._id },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeSite,
    },
  )
  const [saveMedia] = useSaveUsedMediaMutation()
  const doPersistSavedMedia = async (body) => {
    await saveMedia(body).then(() => {})
  }

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'site',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const { assigned_to = {} } = status

  const [
    changingPageStatus,
    {
      isSuccess: statusChangeDone,
      isLoading: statusChangeInProgress,
      isError: statusChangeFailure,
    },
  ] = useSendForApprovalMutation()

  const [
    generatePreview,
    {
      data: previewData,
      isSuccess: previewGenerated,
      isLoading: previewGenerationInProgress,
    },
  ] = useGeneratePreviewMutation()

  useEffect(() => {
    if (approversListData && approversListData.length > 0) {
      setApproversList(approversListData)
    }
  }, [approversListData])

  useEffect(() => {
    if (statusChangeDone) {
      reFetchPage()
      reFetchStatus()
      changeStatus(false)
      setSendStatus(false)
    }
  }, [reFetchPage, statusChangeDone, changeStatus, reFetchStatus])

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'site') {
          setSiteData(conf.value ? conf.value : {})
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  useEffect(() => {
    if (statusChangeFailure) {
      enqueueSnackbar('Unable to change the page status! please try again.', {
        action: null,
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }, [enqueueSnackbar, statusChangeFailure])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedApprover(value)
  }

  const sendToHandleChange = (event) => {
    setSendToApprover(event?.target?.value)
  }
  const groupChange = (event) => {
    setSelectedGroup(event?.target?.value)
  }

  const getLabelValueByLanId = (lanId) => {
    if (lanId && approversList) {
      const found = approversList.find((a) => a.lanId === lanId)
      return found ? found.name : lanId
    }
    return ''
  }

  const getLabelValueByRoleId = (roleId) => {
    if (roleId && usermap) {
      const found = usermap.find((u) => u.role === roleId)
      return found ? found.rolename : roleId
    }
    return ''
  }

  const sendForApproval = () => {
    const payload = {
      id,
      assignedto: selectedApprover,
      site: activeSite._id,
      status: 'pending_approval',
    }
    changingPageStatus(payload)
  }

  const sendToOthers = () => {
    const payload = {
      id,
      assignedto: sendToApprover,
      site: activeSite._id,
      status: status.status,
      team: getLabelValueByRoleId(selectedGroup),
      sub_status: action,
    }
    changingPageStatus(payload)
  }

  const backToWIP = () => {
    const payload = {
      id,
      assignedto: userInfo.lanId,
      site: activeSite._id,
      status: 'draft',
    }
    changingPageStatus(payload)
  }

  const isChangeStatusLoading = () => {
    return statusChangeInProgress || loadingPageData
  }

  const onSitePreview = () => {
    if (siteData && siteData.siteUrl && previewGenerated) {
      window.open(
        `${siteData.siteUrl}${
          pageData?.content?.slug ? pageData?.content?.slug : ''
        }?preview=${previewData ? previewData.token : ''}`,
      )
    }
  }

  useEffect(() => {
    if (previewGenerationInProgress) {
      setLoadingPreviewButton(true)
    }
    if (!previewGenerationInProgress && previewGenerated) {
      setLoadingPreviewButton(false)
      onSitePreview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewGenerated, previewGenerationInProgress])

  useEffect(() => {
    //save the usedmedia data
    if (updateLayoutSuccess) {
      const usedMediaIds =
        mediaArr?.length > 0
          ? mediaArr.map((media) => {
              return media?.id || media?._id
            })
          : []
      const body = {
        content: pageData?.content?.id,
        revision: pageData?._id,
        data: usedMediaIds,
      }
      doPersistSavedMedia(body)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLayoutSuccess])

  useEffect(() => {
    if (savedMediaList && savedMediaList?.length > 0) {
      const data = savedMediaList[0].data
      let sortedArray = [...data]
      let sortedMedia = sortedArray.sort((e1, e2) =>
        e1.size < e2.size ? 1 : e1.size > e2.size ? -1 : 0,
      )
      dispatch(persistMediaList(sortedMedia))
    } else {
      dispatch(persistMediaList([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedMediaList])

  const handleMenuHeader = (iconName) => {
    var icon = {
      ...initialStateIcons,
      [iconName]: !iconClicked[iconName],
    }
    setIconClicked(icon)
  }

  const onChangeComment = (e) => {
    setCurrentComment(e.target.value)
  }

  const addComment = () => {
    if (currentComment) {
      let users = []
      const regexTest = new RegExp(/\(([(a-zA-Z0-9)]+)\)/g)
      const str = currentComment
      let m

      while ((m = regexTest.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regexTest.lastIndex) {
          regexTest.lastIndex++
        }

        users.push(m[1])
      }
      addCommentToPage({
        id: pageId,
        revision: pageData._id,
        comment: currentComment,
        users: users,
        comment_type: 'user',
      })
    }
  }

  const onEmojiClick = (event, emojiObject) => {
    setCurrentComment(currentComment + emojiObject.emoji)
  }

  const RenderGroups = (group, index) => {
    return (
      <MenuItem
        key={index}
        value={group?.role}
        sx={{
          fontSize: '14px',
        }}
      >
        {group?.rolename}
      </MenuItem>
    )
  }

  const RenderUserGroup = (group) => {
    const items = group?.users?.map((user, index) => {
      return (
        <MenuItem
          key={index}
          value={user?.lanId}
          sx={{
            fontSize: '14px',
          }}
        >
          {user.displayName}
        </MenuItem>
      )
    })

    return [items]
  }

  const [anchorPopOverEl, setAnchorPopOverEl] = React.useState(null)
  const popOverRef = React.useRef(null)

  const handleClick = (event) => {
    setAnchorPopOverEl(popOverRef.current)
  }

  const isPopOverOpen = Boolean(anchorPopOverEl)
  const popOverId = isPopOverOpen ? 'simple-popover' : undefined

  const handleClose = () => {
    setAnchorPopOverEl(null)
  }

  const options = ['Reassign', 'Done & Reassign']
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const optionClick = () => {
    if (options[selectedIndex] === 'Reassign') {
      setAction('TODO')
    } else {
      setAction('DONE')
    }
    setSendStatus(true)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const closeClick = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ display: 'flex', m: 2, gap: '16px', alignItems: 'center' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ mr: '10px' }}>
              {pageName}
            </Typography>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Box>
                <Chip
                  label={
                    status.status === 'draft'
                      ? 'WIP'
                      : status.status === 'pending_approval'
                      ? 'Pending approval'
                      : status.status === 'approved'
                      ? 'Approved'
                      : status.status === 'published'
                      ? 'Published'
                      : status.status === 'rejected'
                      ? 'Rejected'
                      : status.status === 'archived'
                      ? 'Archived'
                      : status.status
                  }
                  color={
                    status.status === 'draft'
                      ? 'info'
                      : status.status === 'pending_approval'
                      ? 'warning'
                      : status.status === 'rejected'
                      ? 'error'
                      : 'success'
                  }
                  sx={(theme) => {
                    return {
                      backgroundColor: theme.palette[status.status].main,
                      height: 26,
                    }
                  }}
                />
              </Box>
            </Box>
          </div>

          {pageSubStatus && pageSubStatus.length > 0 && pageSubStatus[0] && (
            <Button
              ref={popOverRef}
              disableFocusRipple
              variant="text"
              sx={{
                textTransform: 'none',
                fontSize: '12px',
                ':focus': { border: '2px dashed #666666' },
                color: '#CC0000',
                ml: '-10px',
              }}
              onClick={handleClick}
            >
              Last updated by - {pageSubStatus[0]?.team},{' '}
              {`${formatDate(pageSubStatus[0]?.createdAt)}`}
            </Button>
          )}
        </div>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <Tooltip title="Preview on Site">
            <IconButton
              aria-label="site-preview"
              onClick={() => {
                if (
                  (!previewGenerated ||
                    !previewData ||
                    previewData.page !== id) &&
                  !previewGenerationInProgress
                ) {
                  generatePreview({
                    id: pageData.content?.id,
                    revision: id,
                  })
                }
              }}
              sx={{ border: '1px solid #E0E0E0', borderRadius: 0, padding: 1 }}
              variant="outlined"
            >
              <OpenInBrowserIcon fontSize="medium"></OpenInBrowserIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Preview on Stencil">
            <IconButton
              aria-label="cms-preview"
              onClick={() => {
                setFullscreenPreview(true)
              }}
              sx={{ border: '1px solid #E0E0E0', borderRadius: 0, padding: 1 }}
              variant="outlined"
            >
              <PreviewIcon fontSize="medium"></PreviewIcon>
            </IconButton>
          </Tooltip>
          {loadingPreviewButton && <CircularProgress size={30} sx={{ m: 1 }} />}
        </Box>
        <Tooltip title="Assets">
          <IconButton
            aria-label="activity-icon"
            onClick={() => handleMenuHeader('assetsIcon')}
            sx={{ borderRadius: 0, padding: 1 }}
          >
            <CollectionsBookmarkIcon
              sx={{ color: '#CC0000' }}
              fontSize="medium"
            ></CollectionsBookmarkIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Activity">
          <IconButton
            aria-label="activity-icon"
            onClick={() => handleMenuHeader('activityIcon')}
            sx={{ borderRadius: 0, padding: 1 }}
          >
            <UpdateIcon
              sx={{ color: '#CC0000' }}
              fontSize="medium"
            ></UpdateIcon>
          </IconButton>
        </Tooltip>
        <Box sx={{ cursor: 'pointer' }}>
          <Tooltip title="Chat">
            <IconButton
              aria-label="activity-icon"
              onClick={() => handleMenuHeader('commentsIcon')}
              sx={{ borderRadius: 0, padding: 1 }}
            >
              <ChatBubbleOutlineIcon
                sx={{ color: '#CC0000' }}
                fontSize="medium"
              ></ChatBubbleOutlineIcon>
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          {sendStatus ? (
            <>
              <Box display={'flex'}>
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Group
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="user-group"
                    label="Group"
                    variant="outlined"
                    renderValue={(selected) => {
                      const label = getLabelValueByRoleId(selected)
                      return (
                        <Box
                          sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                          <Chip size="small" key={selected} label={label} />
                        </Box>
                      )
                    }}
                    value={selectedGroup}
                    onChange={groupChange}
                    size="small"
                    sx={{ minWidth: 150, maxWidth: 300, mt: '0 !important' }}
                  >
                    {usermap?.map((group, index) => RenderGroups(group, index))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  sx={{
                    minWidth: 120,
                  }}
                  disabled={selectedGroup ? false : true}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Send To
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="user-selected"
                    variant="outlined"
                    label="Send To"
                    renderValue={(selected) => {
                      const label = getLabelValueByLanId(selected)
                      return (
                        <Box
                          sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                          <Chip size="small" key={selected} label={label} />
                        </Box>
                      )
                    }}
                    value={sendToApprover}
                    onChange={sendToHandleChange}
                    size="small"
                    sx={{ minWidth: 150, maxWidth: 300, mt: '0 !important' }}
                  >
                    {tailoredUserGroup?.map((group, index) =>
                      RenderUserGroup(group, index),
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Button
                sx={{
                  textTransform: 'none',
                  ml: 1,
                  backgroundColor: '#CC0000',
                  ':hover': {
                    backgroundColor: '#CC0000',
                    borderColor: '#CC0000',
                  },
                }}
                variant="contained"
                size={'small'}
                onClick={sendToOthers}
                disabled={!sendToApprover || statusChangeInProgress}
              >
                Send
              </Button>
              <Button
                sx={{ textTransform: 'none', ml: 1, mr: 1 }}
                onClick={() => {
                  setSendStatus(false)
                  setSendToApprover(null)
                }}
                size={'small'}
                variant="outlined"
              >
                Cancel
              </Button>
            </>
          ) : (
            !initChangeStatus && (
              <>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef}
                  aria-label="split button"
                  sx={{ mr: '5px', minWidth: '150px', zIndex: 1 }}
                >
                  <Button
                    sx={{
                      textTransform: 'none',
                      borderColor: '#CC0000',
                      color: '#CC0000',
                      fontSize: 14,
                      borderRadius: '12px',
                      background: 'transparent',
                      ':hover': {
                        backgroundColor: 'transparent',
                        borderColor: '#CC0000',
                      },
                    }}
                    onClick={optionClick}
                    disabled={updateInProgress || !pageChangesAllowed}
                  >
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="assign"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    disabled={updateInProgress || !pageChangesAllowed}
                    sx={{
                      textTransform: 'none',
                      borderColor: '#CC0000',
                      color: '#CC0000',
                      fontSize: 14,
                      borderRadius: '12px',
                      background: 'transparent',
                      ':hover': {
                        backgroundColor: 'transparent',
                        borderColor: '#CC0000',
                      },
                    }}
                  >
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal={false}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={closeClick}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )
          )}
          {initChangeStatus ? (
            <>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="grouped-select" sx={{ mt: '-20px' }}>
                  Approver
                </InputLabel>
                <Select
                  defaultValue=""
                  id="grouped-select"
                  label="Approver"
                  renderValue={(selected) => {
                    const label = getLabelValueByLanId(selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip size="small" key={selected} label={label} />
                      </Box>
                    )
                  }}
                  value={selectedApprover}
                  onChange={handleChange}
                  size="small"
                  sx={{ maxWidth: 300, mt: '0 !important' }}
                >
                  {approversList.map((approver, key) => {
                    return (
                      <MenuItem key={key} value={approver.lanId}>
                        {approver.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <Button
                sx={{
                  textTransform: 'none',
                  ml: 1,
                  backgroundColor: '#CC0000',
                  ':hover': {
                    backgroundColor: '#CC0000',
                    borderColor: '#CC0000',
                  },
                }}
                variant="contained"
                size={'small'}
                onClick={sendForApproval}
                disabled={!selectedApprover || statusChangeInProgress}
              >
                Send
              </Button>
              <Button
                sx={{ textTransform: 'none', ml: 1 }}
                onClick={() => {
                  changeStatus(false)
                  setSelectedApprover(null)
                }}
                size={'small'}
                variant="outlined"
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              {status.status === 'draft' &&
                !isChangeStatusLoading() &&
                !sendStatus && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => changeStatus(true)}
                    disabled={updateInProgress || !pageChangesAllowed}
                    sx={{
                      textTransform: 'none',
                      borderColor: '#CC0000',
                      color: '#CC0000',
                      py: 0.5,
                      px: 1,
                      fontSize: 14,
                      borderRadius: '12px',
                      background: 'transparent',
                      ':hover': {
                        backgroundColor: 'transparent',
                        borderColor: '#CC0000',
                      },
                    }}
                  >
                    Send for Approval
                  </Button>
                )}
              {(status.status === 'pending_approval' ||
                status.status === 'rejected') &&
                !isChangeStatusLoading() && (
                  <>
                    <Button
                      sx={{ textTransform: 'none', ml: 2 }}
                      variant="outlined"
                      size="small"
                      onClick={() => backToWIP()}
                    >
                      Back to WIP
                    </Button>
                    <Typography variant="caption" sx={{ ml: 1 }}>
                      Assigned to <strong>{assigned_to.displayName}</strong>
                    </Typography>
                  </>
                )}
            </>
          )}
        </Box>
        <Button
          variant="outlined"
          size="small"
          onClick={updatePageLayout}
          disabled={updateInProgress || !pageChangesAllowed}
          sx={{
            textTransform: 'none',
            backgroundColor:
              updateInProgress || !pageChangesAllowed
                ? 'transparent'
                : '#CC0000',
            borderColor: '#CC0000',
            color: '#fff',
            py: 0.5,
            px: 2,
            fontSize: 14,
            borderRadius: '12px',
            ':hover': {
              backgroundColor: '#CC0000',
              borderColor: '#CC0000',
            },
          }}
        >
          Save
        </Button>
        <Box>
          {updateInProgress && <CircularProgress size={30} sx={{ ml: 1 }} />}
          {updateLayoutSuccess && (
            <Avatar
              size={'small'}
              sx={{ bgcolor: '#4caf50', width: 30, height: 30, ml: 1 }}
            >
              <Check />
            </Avatar>
          )}
        </Box>
      </Box>
      <PreviewFullscreen
        open={openFullScreenPreview}
        handleClose={() => setFullscreenPreview(false)}
        layout={layout}
      />
      <Box sx={{ minHeight: 10 }}>
        {isChangeStatusLoading() && <LinearProgress color="inherit" />}
      </Box>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['activityIcon']}
        onClose={() => handleMenuHeader('activityIcon')}
        onOpen={() => handleMenuHeader('activityIcon')}
        PaperProps={{
          sx: {
            width: '25%',
            marginTop: '65px',
          },
        }}
      >
        <ActivityPanel
          activity={activity}
          onClose={() => handleMenuHeader('activityIcon')}
        ></ActivityPanel>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['assetsIcon']}
        onClose={() => handleMenuHeader('assetsIcon')}
        onOpen={() => handleMenuHeader('assetsIcon')}
        PaperProps={{
          sx: {
            width: '25%',
            marginTop: '65px',
          },
        }}
      >
        <AssetsPanel
          onClose={() => handleMenuHeader('assetsIcon')}
        ></AssetsPanel>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['commentsIcon']}
        onClose={() => handleMenuHeader('commentsIcon')}
        onOpen={() => handleMenuHeader('commentsIcon')}
        PaperProps={{
          sx: {
            width: '25%',
            marginTop: '65px',
          },
        }}
      >
        <CommentsPanel
          comments={comments}
          saveInProgress={saveInProgress}
          userData={userData}
          pageChangesAllowed={pageChangesAllowed}
          addCommentAllowed={addCommentAllowed}
          addComment={addComment}
          currentComment={currentComment}
          onChangeComment={onChangeComment}
          onEmojiClick={onEmojiClick}
          id={id}
          onClose={() => handleMenuHeader('commentsIcon')}
        ></CommentsPanel>
      </SwipeableDrawer>
      <Popover
        id={popOverId}
        open={isPopOverOpen}
        anchorEl={anchorPopOverEl}
        onClose={() => {
          setAnchorPopOverEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 0 }}
      >
        <Box
          sx={{
            display: 'flex',
            minWidth: '350px',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px',
          }}
        >
          <Typography variant="body1" sx={{ mr: '10px', fontWeight: 'bold' }}>
            Page History
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box sx={{ overflow: 'scroll', maxHeight: '600px', ml: 2 }}>
          <StatusStepper pageStatus={pageSubStatus} />
        </Box>
      </Popover>
    </>
  )
}

export default TopLayout
