import React from 'react'
import { Box, Typography, Avatar, LinearProgress } from '@mui/material'
import { ViewPopup } from '../../Common/ViewPopup'
import moment from 'moment'

const ListingSpeakers = (props) => {
  const { speakersData, speakersFetching } = props
  const [open, setOpen] = React.useState(false)
  const [speakerIndex, setSpeakerIndex] = React.useState(0)
  const [speakerMoreClicked, setSpeakerMoreClicked] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const onReadMore = (index) => {
    setSpeakerMoreClicked(!speakerMoreClicked)
    setSpeakerIndex(index)
    if (speakerIndex !== index) {
      setSpeakerMoreClicked(true)
    }
  }

  return (
    <>
      {speakersFetching && <LinearProgress></LinearProgress>}
      <Box data-testid="listing-speakers-wrapper">
        {speakersData && speakersData.workshop && (
          <Box sx={{ borderBottom: '1px solid #E6E6E6' }}>
            <Typography
              variant="h5"
              sx={{
                color: '#CC0000',
                fontWeight: 'bold',
                pt: 3,
                pb: 2,
                px: 3,
              }}
            >
              {speakersData.workshop.name}
            </Typography>
          </Box>
        )}
        {speakersData &&
          speakersData.data?.length > 0 &&
          speakersData.data.map((speaker, index) => {
            const { speaker_meta, member_data } = speaker
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    px: 3,
                    py: 3,
                    gap: '12px',
                    borderBottom: '1px solid #E6E6E6',
                  }}
                >
                  <Avatar
                    alt="speaker_image"
                    src={speaker_meta?.engagement_image}
                    sx={{ height: 80, width: 80 }}
                  ></Avatar>
                  <Box>
                    <Typography variant="h6" sx={{ pb: 2 }}>
                      <strong>{member_data?.name}</strong>{' '}
                      <span style={{ fontSize: '16px' }}>
                        {speaker_meta?.designation} – {member_data?.company}
                      </span>
                    </Typography>
                    <Typography variant="body1" sx={{ pb: 2 }}>
                      Starts from
                      {moment(speaker?.start_time, ['h:mm']).format(
                        'hh:mm A',
                      )}{' '}
                      - {moment(speaker?.end_time, ['h:mm']).format('hh:mm A')}
                    </Typography>
                    <Typography variant="body1">
                      {speakerIndex === index && speakerMoreClicked
                        ? speaker_meta?.bio
                        : `${speaker_meta?.bio.substring(0, 300)}...`}
                      <span
                        onClick={() => onReadMore(index)}
                        style={{
                          color: '#CC0000',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        {' '}
                        {speakerIndex === index && speakerMoreClicked
                          ? 'Read less'
                          : 'Read more'}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </>
            )
          })}
        <ViewPopup
          open={open}
          onClose={handleClose}
          name=""
          company=""
          designation=""
          bio=""
          image=""
        />
      </Box>
    </>
  )
}

export default ListingSpeakers
