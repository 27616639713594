import React, { useEffect } from 'react'
import { Card, Button, Alert, AlertTitle } from '@mui/material'
import SectionList from './SectionsList/SectionList'
import { useGenerateNewDraftMutation } from '../../../../api/page'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

export default function Main(props) {
  const navigate = useNavigate()
  const {
    layout,
    setLayout,
    selectComponentForEdit,
    onSectionClick,
    selectedComponent,
    status = {},
    id,
    loadingPageData,
    pageActions = {},
    allowUserToEdit = false,
    name = '',
    pageData,
  } = props

  const { pageChangesAllowed = false } = pageActions
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { enqueueSnackbar } = useSnackbar()

  const { assigned_to = {} } = status

  const [
    generateDraft,
    {
      data: generatedDraft,
      isError: generateDraftFailure,
      isLoading: draftGenerationInProgress,
    },
  ] = useGenerateNewDraftMutation()

  const generateNewDraft = () => {
    generateDraft({
      site: activeSite._id,
      page: pageData.content?.id,
      revision: id,
    })
  }

  useEffect(() => {
    if (generatedDraft) {
      navigate(`/pages/layout/${generatedDraft.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftGenerationInProgress])

  useEffect(() => {
    if (generateDraftFailure) {
      enqueueSnackbar(
        'Unable to generate a draft from the page. Please try again.',
        {
          action: null,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateDraftFailure])

  return (
    <>
      <Card
        sx={{
          height: 'calc(100vh - 64px)',
          borderRadius: 0,
          background: '#F6F6F6',
          overflow: 'scroll',
          p: 1,
        }}
        elevation={0}
      >
        {!pageChangesAllowed && status?.status === 'draft' && (
          <Alert variant="outlined" severity="warning" sx={{ mb: 1 }}>
            <AlertTitle>
              <strong>Read Only Mode - Edit Restricted</strong>
            </AlertTitle>
            You are viewing the page in <strong>read-only</strong> mode. This
            page is assigned to <strong>{assigned_to.displayName}</strong> but
            you can comment on this page
            <br />
            If you are <strong>administrator</strong>, you can assign this page
            to yourself to perform edit operation
          </Alert>
        )}
        {!pageChangesAllowed &&
          status?.status !== 'draft' &&
          status?.status !== 'archived' &&
          status?.status !== 'published' && (
            <Alert variant="outlined" severity="warning" sx={{ mb: 1 }}>
              <AlertTitle>
                <strong>Read Only Mode - Edit Restricted</strong>
              </AlertTitle>
              Only draft versions can be edited. <br />
              If you want to make any changes click the{' '}
              <strong>Back to WIP</strong> option.
            </Alert>
          )}
        {!pageChangesAllowed &&
          (status?.status === 'published' || status?.status === 'archived') && (
            <Alert variant="outlined" severity="warning" sx={{ mb: 1 }}>
              <AlertTitle>
                <strong>
                  Read Only Mode - You are viewing the published version of the
                  page
                </strong>
              </AlertTitle>
              {allowUserToEdit && (
                <>
                  To edit a published page,{' '}
                  <Button
                    sx={{ textTransform: 'none' }}
                    size="small"
                    variant="contained"
                    onClick={generateNewDraft}
                    disabled={draftGenerationInProgress}
                  >
                    click here
                  </Button>{' '}
                  to clone and make a draft.{' '}
                </>
              )}
            </Alert>
          )}
        <SectionList
          sections={layout}
          setLayout={setLayout}
          selectedComponent={selectedComponent}
          selectComponentForEdit={selectComponentForEdit}
          onSectionClick={onSectionClick}
          pageChangesAllowed={pageChangesAllowed}
          loadingPageData={loadingPageData}
          name={name}
        />
      </Card>
    </>
  )
}
