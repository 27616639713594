import React from 'react'
import SortableTree from 'react-sortable-tree'
import { Tooltip, IconButton, Button, Typography, Box } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import AddLinkIcon from '@mui/icons-material/AddLink'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import { checkUserIsAllowed } from '../../../utils/rightUtils'
import ListLoader from '../../../components/ListLoader'

export default function SortableMenu(props) {
  const {
    treeData,
    onChange,
    maxDepth = 6,
    handleAddClick,
    handleEdit,
    viewNode,
    deleteNode,
    addNew,
    superUser,
    permittedActions,
    readOnly = false,
    loading = false,
  } = props
  const editAllowed =
    checkUserIsAllowed('Edit', permittedActions, superUser) && !readOnly
  const getActions = (editAllowed, rowInfo, node) => [
    <Tooltip title="View Details">
      <div>
        <IconButton aria-label="View Details" onClick={(e) => viewNode(node)}>
          <InfoIcon
            fontSize="small"
            color="primary"
            sx={{ color: '#888888' }}
          />
        </IconButton>
      </div>
    </Tooltip>,
    ...(editAllowed
      ? [
          <Tooltip title="Edit Menu">
            <div>
              <IconButton
                aria-label="Edit Menu"
                onClick={(e) => handleEdit(rowInfo)}
              >
                <EditIcon
                  fontSize="small"
                  color="primary"
                  sx={{ color: '#888888' }}
                />
              </IconButton>
            </div>
          </Tooltip>,
          <Tooltip title="Add New Page">
            <div>
              <IconButton
                aria-label="Add Page"
                onClick={(e) => handleAddClick(e, rowInfo, 'page')}
              >
                <NoteAddIcon
                  fontSize="small"
                  color="primary"
                  sx={{ color: '#888888' }}
                />
              </IconButton>
            </div>
          </Tooltip>,
          <Tooltip title="Add New Link">
            <div>
              <IconButton
                aria-label="Add link"
                onClick={(e) => handleAddClick(e, rowInfo, 'link')}
              >
                <AddLinkIcon
                  fontSize="small"
                  color="primary"
                  sx={{ color: '#888888' }}
                />
              </IconButton>
            </div>
          </Tooltip>,
          <Tooltip title="Delete">
            <div>
              <IconButton
                aria-label="Delete"
                onClick={(e) => deleteNode(rowInfo)}
              >
                <DeleteIcon fontSize="small" color="secondary" />
              </IconButton>
            </div>
          </Tooltip>,
        ]
      : []),
  ]
  return (
    <>
      {!loading ? (
        <SortableTree
          treeData={treeData}
          onChange={(treeData) => onChange([...treeData])}
          isVirtualized={false}
          rowHeight={80}
          maxDepth={maxDepth}
          canDrag={editAllowed}
          generateNodeProps={(rowInfo) => {
            const { node } = rowInfo
            return {
              buttons: getActions(editAllowed, rowInfo, node),
              title: node.name,
              subtitle: node.slug,
            }
          }}
          placeholderRenderer={() => {
            return (
              <Box
                sx={{
                  boxShadow: 4,
                  p: 3,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body" sx={{ mr: 4 }}>
                  Looks like header menu is <strong>empty</strong>. Add a
                  page/link to the menu.
                </Typography>
                <div>
                  <Button
                    aria-label="Add Page"
                    onClick={(e) => addNew('page')}
                    startIcon={<NoteAddIcon fontSize="small" />}
                    sx={{
                      textTransform: 'none',
                      mr: 5,
                      color: '#CC0000',
                      borderColor: '#CC0000',
                    }}
                    variant={'outlined'}
                    disabled={!editAllowed}
                  >
                    Add New Page Menu
                  </Button>
                  <Button
                    aria-label="Add Page"
                    onClick={(e) => addNew('link')}
                    startIcon={<AddLinkIcon fontSize="small" />}
                    sx={{
                      textTransform: 'none',
                      color: '#CC0000',
                      borderColor: '#CC0000',
                    }}
                    variant={'outlined'}
                    disabled={!editAllowed}
                  >
                    Add New Link Menu
                  </Button>
                </div>
              </Box>
            )
          }}
        />
      ) : (
        <>
          <ListLoader />
        </>
      )}
    </>
  )
}
