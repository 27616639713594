import { Box, Button, Typography } from '@mui/material'
import React from 'react'

export const NoRecords = (props) => {
  const { img = '', btns = [], children, editDisabled = false } = props
  return (
    <Box
      data-testid="wrapper-no-records"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F6F7F8',
        boxShadow: '0px 0px 3px 0px #888888',
        mt: 2,
      }}
    >
      {img && (
        <Box sx={{ margin: '70px' }}>
          <img src={img} alt="powerofpen" />
        </Box>
      )}
      <Box sx={{ textAlign: 'center' }}>
        {children}
        {btns &&
          btns.length > 0 &&
          btns.map((btn, key) => {
            return (
              <React.Fragment key={key}>
                {key !== 0 && (
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    or
                  </Typography>
                )}
                <Button
                  key={key}
                  variant="text"
                  onClick={btn.cb}
                  data-testid={`action-btn-${key}`}
                  sx={{
                    m: 2,
                    color: '#CC0000',
                    cursor: 'pointer',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: 24,
                  }}
                  disabled={editDisabled || btn.disabled}
                >
                  {btn.label}
                </Button>
              </React.Fragment>
            )
          })}
      </Box>
    </Box>
  )
}

export default NoRecords
