import React from 'react'
import { Box, Paper, Button } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const AddSection = (props) => {
  const { onClickAddSection, pageChangesAllowed } = props
  return (
    <Box sx={{ width: 'auto', height: '100px' }}>
      <Paper
        elevation={3}
        sx={{
          height: '100px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="outlined"
          startIcon={<AddCircleIcon />}
          size="small"
          onClick={onClickAddSection}
          disabled={!pageChangesAllowed}
          sx={{
            textTransform: 'none',
            backgroundColor: !pageChangesAllowed ? 'lightgray' : '#CC0000',
            borderColor: '#CC0000',
            color: '#fff',
            py: 1,
            px: 8,
            fontSize: 16,
            borderRadius: '8px',
            ':hover': {
              backgroundColor: '#CC0000',
            },
          }}
        >
          Add New Section
        </Button>
      </Paper>
    </Box>
  )
}

export default AddSection
