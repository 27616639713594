import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import Label from './Common/Label'

export const LineHeight = (props) => {
  const {
    value,
    onChange,
    name = 'Line height',
    allowedValues,
    disabled = false,
  } = props
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        <ToggleButtonGroup
          value={value}
          size={'small'}
          exclusive
          onChange={(_, val) => onChange(val)}
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              textTransform: 'none',
            },
            '& .Mui-selected': {
              background: 'grey.light',
              fontWeight: 'bold',
            },
          }}
          disabled={disabled}
        >
          {allowedValues.map((val, key) => {
            return (
              <ToggleButton value={val.value} aria-label={val.label}>
                {val.label}
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

export default LineHeight
