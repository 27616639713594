import React, { useEffect, useState } from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Typography,
  Card,
  Alert,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { ChipFilters, Search } from '../../Common'
import _ from 'lodash'
import DateRangeIcon from '@mui/icons-material/DateRange'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import BusinessIcon from '@mui/icons-material/Business'
import WorkIcon from '@mui/icons-material/Work'
import {
  useGetAllParticipantsQuery,
  useMapParticipantsMutation,
} from '../../../../../api/participant'
import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { STATUS_LABEL, STATUS_OPTIONS } from '../Utils/Constants'

const getCurrentYear = new Date().getFullYear() // current year
const listOfYears = Array.from(
  { length: 4 },
  (_, i) => getCurrentYear - i,
).reverse()

const INITIAL_FILTER_VALUES = {
  search: '',
  year: [],
  status: [],
  company: [],
  experience: [],
  page: 1,
  perpage: 100,
}

const FILTER_OPTIONS = [
  {
    title: 'By Year (Select year to continue)',
    icon: <DateRangeIcon />,
    flag: 'year',
    options: [...listOfYears],
    onlyOneAllowed: true,
  },
  {
    title: 'By Status',
    icon: <CheckCircleIcon />,
    flag: 'status',
    options: STATUS_OPTIONS,
  },
  {
    title: 'By Company',
    icon: <BusinessIcon />,
    flag: 'company',
    options: [
      { label: 'Target', value: 'target' },
      { label: 'Non-Target', value: 'non-target' },
    ],
  },
  {
    title: 'By Experience (in years)',
    icon: <WorkIcon />,
    flag: 'experience',
    options: [
      { label: '0-3 Exp', value: '0-3' },
      { label: '3-6 Exp', value: '3-6' },
      { label: '6-9 Exp', value: '6-9' },
      { label: '9-15 Exp', value: '9-15' },
      { label: '15+ Exp', value: '15-50' },
    ],
  },
]

const INITIAL_IMPORT_VALUE = {
  open: false,
  data: null,
  statusMap: {},
}

const ImportParticipant = (props) => {
  const { onClose, eventid } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [filtersOpen, setFiltersOpen] = React.useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const [filters, setFilters] = useState(INITIAL_FILTER_VALUES)
  const [readyForImport, setReadyForImport] = useState(INITIAL_IMPORT_VALUE)
  const [payloadQueue, setPayloadQueue] = useState([])

  const { data: participants, isFetching: participantsLoading } =
    useGetAllParticipantsQuery(
      { filters: { ...filters, exclude_event: eventid }, activeSite },
      {
        skip: !activeSite || !filters?.year?.[0],
        refetchOnMountOrArgChange: true,
      },
    )

  useEffect(() => {
    if (participants) {
      setReadyForImport({
        open: false,
        data: participants?.data,
        total: participants?.total,
      })
    }
  }, [participants])

  const nextStep = () => {
    setFiltersOpen(false)
    setReadyForImport({
      ...readyForImport,
      open: true,
    })
    setFiltersOpen(true)
  }

  const cancelImport = () => {
    setFilters({ ...INITIAL_FILTER_VALUES, year: filters?.year })
    setReadyForImport(INITIAL_IMPORT_VALUE)
  }

  const [
    importParticipantsToEvent,
    {
      isLoading: importInProgress,
      isSuccess: importSuccess,
      isError: importError,
    },
  ] = useMapParticipantsMutation()

  useEffect(() => {
    if (importSuccess && payloadQueue.length === 0 && !importInProgress) {
      enqueueSnackbar('Participants import successful', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      onClose()
    }
    if (importError) {
      enqueueSnackbar('Participants import failed. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      setPayloadQueue([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importSuccess, importError])

  const initiateImport = () => {
    const payloads = []
    if (readyForImport && readyForImport.data) {
      readyForImport.data.map((idata) => {
        payloads.push({
          current_status: idata.status,
          new_status: readyForImport?.statusChanges?.[idata.status]
            ? readyForImport?.statusChanges?.[idata.status]
            : idata.status,
          p_ids: idata.p_ids,
        })
        return 0
      })
    }
    importParticipantsToEvent({
      payload: { data: payloads },
      eventid,
    })
  }

  return (
    <Box>
      {!readyForImport.open ? (
        <List sx={{ height: '26vh' }}>
          <ListItem sx={{ display: 'flex' }}>
            <ListItemText
              primary={`Import Participants`}
              primaryTypographyProps={{
                fontSize: 20,
                fontWeight: 'bold',
              }}
            />
          </ListItem>
          <Divider sx={{ bgcolor: '#E6E6E6' }} />
          {filtersOpen && (
            <Search
              showReset={true}
              term={filters?.search}
              setTerm={(val) => setFilters({ ...filters, search: val })}
              resetSearch={() => setFilters({ ...INITIAL_FILTER_VALUES })}
              disableReset={_.isEqual(INITIAL_FILTER_VALUES, filters)}
            />
          )}
          {participantsLoading ? (
            <ProgressBar indeterminate />
          ) : (
            <>
              {filters?.year?.[0] && (
                <Typography
                  sx={{
                    fontSize: 13,
                    color: '#2951A3',
                    textAlign: 'center',
                    background: '#E8EEFC',
                    p: 1,
                    display: filtersOpen ? 'block' : 'none',
                    border: '1px solid #638DE3',
                  }}
                >
                  <strong>{readyForImport?.total}</strong> participants found
                </Typography>
              )}
            </>
          )}
          <Divider />
          {filtersOpen && (
            <>
              {FILTER_OPTIONS &&
                FILTER_OPTIONS.map((filter, key) => {
                  return (
                    <Box key={key} sx={{ m: 1 }}>
                      <ChipFilters
                        {...filter}
                        value={filters[filter?.flag]}
                        onChange={(value) =>
                          setFilters({ ...filters, [filter?.flag]: value })
                        }
                        disabled={
                          filter?.flag !== 'year' &&
                          (!filters?.year || filters?.year.length === 0)
                        }
                      />
                      <Divider sx={{ mt: 1 }} />
                    </Box>
                  )
                })}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  sx={{ textTransform: 'none', width: '35%', ml: 0.5, mr: 0.5 }}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ textTransform: 'none', width: '35%', ml: 0.5, mr: 0.5 }}
                  variant="contained"
                  color="secondary"
                  onClick={nextStep}
                  disabled={
                    !participants?.data || participants?.data.length === 0
                  }
                >
                  Apply
                </Button>
              </Box>
            </>
          )}
        </List>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100vh',
            p: 1.5,
            background: 'rgba(0,0,0,0.2)',
          }}
        >
          <Card sx={{ width: '100%', p: 2, position: 'relative' }}>
            <Typography
              gutterBottom
              variant="h3"
              sx={{ fontSize: 20, color: '#333333', fontWeight: '700', mb: 1 }}
            >
              We have found {readyForImport?.total} participant
              {readyForImport.total > 1 ? 's' : ''}
            </Typography>
            <Typography sx={{ fontSize: 16, color: '#666666', mb: 1 }}>
              Please confirm status of the participants before import
            </Typography>
            {readyForImport?.data &&
              readyForImport.data.map((record, key) => {
                const { status, count } = record
                return (
                  <React.Fragment key={key}>
                    <ChipFilters
                      title={`${count} ${
                        STATUS_LABEL?.[status]
                          ? STATUS_LABEL?.[status]
                          : _.capitalize(status)
                      }`}
                      options={STATUS_OPTIONS}
                      onChange={(value) =>
                        setReadyForImport({
                          ...readyForImport,
                          statusChanges: {
                            ...readyForImport.statusChanges,
                            [status]: value,
                          },
                        })
                      }
                      valueForSingle={
                        readyForImport?.statusChanges?.[status]
                          ? readyForImport?.statusChanges?.[status]
                          : status
                      }
                      type={'single'}
                      bodyText={`Select below option to change the status of the participants`}
                      defaultExpanded={key === 0}
                    />
                  </React.Fragment>
                )
              })}
            <Box sx={{ p: 1 }}>
              <Alert severity="warning">
                Once you add this status to the listing you will not able to
                revert back to the filters. Please confirm before taking this
                action!
              </Alert>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={{ textTransform: 'none', width: '40%', ml: 0.25, mr: 0.25 }}
                variant="outlined"
                onClick={cancelImport}
              >
                Cancel
              </Button>
              <Button
                sx={{ textTransform: 'none', width: '40%', ml: 0.25, mr: 0.25 }}
                variant="contained"
                color="secondary"
                onClick={initiateImport}
              >
                Yes, import
              </Button>
            </Box>
          </Card>
        </Box>
      )}
    </Box>
  )
}

export default ImportParticipant
