import React from 'react'
import { showComponent } from './RenderingUtils'
import { Box } from '@mui/system'

export const ConditionalRender = (props) => {
  const { children, when = [], values = {} } = props
  const visible = showComponent({ when }, values)
  return visible ? <Box data-testid="children">{children}</Box> : <></>
}

export default ConditionalRender
