import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import FormStyledInput from './FormStyledInput'
import { useField } from 'formik'
import { PropTypes } from 'prop-types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, selected, theme) {
  return {
    fontWeight:
      selected.label === name
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightMedium,
  }
}

export const FormSelectSpeaker = (props) => {
  const {
    label = '',
    options = [],
    chipColor = 'success',
    multiple = true,
    speakerListData = [],
    disableFields = false,
    form,
    module = 'workshop',
    speakersSavedList,
    speakerIndex,
    setSpeakersToAdd,
    speakersToAdd,
  } = props

  // eslint-disable-next-line no-unused-vars
  const [field, _state, { setTouched }] = useField(props?.field?.name)
  const { value = [] } = field
  const { setValues } = form
  const [selectedOption, setSelectedOption] = React.useState(
    value.length > 0 ? options.find((option) => option.value === value[0]) : {},
  )

  const theme = useTheme()

  const handleChange = (event) => {
    const selectedId = event.target.value
    setTouched(true)
    setSelectedOption(options.find((option) => option.value === selectedId))
    Object.keys(speakerListData).map((item) => {
      if (selectedId === item) {
        let data = speakerListData[item]
        var obj = {
          name: [selectedId],
          description: data.meta.bio,
          designation: data.meta.designation,
          company: data.user.company,
          email: data.user.email,
          start_time: '',
          end_time: '',
          engagement_image: data.meta.engagement_image,
          id: item,
        }

        if (module === 'mentorship') {
          obj.sessions = [
            {
              start_time: '',
              end_time: '',
              time_slot: '',
              break_time: '',
            },
          ]
        }
        const newData = [
          ...speakersToAdd.slice(0, speakerIndex),
          obj,
          ...speakersToAdd.slice(speakerIndex + 1),
        ]
        setSpeakersToAdd(newData)
        setValues(obj)
        return obj
      }
      return obj
    })
  }

  return (
    <FormStyledInput>
      <FormControl size="small" fullWidth>
        <InputLabel id="multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="multiple-chip-label"
          multiple={multiple}
          fullWidth
          value={value || ''}
          {...field}
          data-testid="selectspeaker"
          onChange={handleChange}
          disabled={disableFields}
          sx={{
            '& .Mui-disabled': {
              WebkitTextFillColor: '#fff !important',
            },
          }}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Chip
                key={selected}
                label={selectedOption?.label ? selectedOption?.label : ''}
                color={chipColor}
                sx={{ opacity: disableFields ? '0.6' : '1', color: '#fff' }}
              />
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {options.length > 0 &&
            options.map((option, key) => {
              const label = typeof option === 'object' ? option?.label : option
              return (
                <MenuItem
                  key={key}
                  value={option.value}
                  style={getStyles(label, value, theme)}
                  disabled={
                    speakersSavedList?.find((item) => item.id === option.value)
                      ?.id
                      ? true
                      : false
                  }
                >
                  {label}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    </FormStyledInput>
  )
}

FormSelectSpeaker.propTypes = {
  field: PropTypes.shape({ name: PropTypes.string.isRequired }),
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
}

export default FormSelectSpeaker
