import React, { useContext } from 'react'
import { FieldsMapContext } from '../../../EditComponent/FieldsMapContext'
import _ from 'lodash'
import { Box, Chip, Typography } from '@mui/material'
import { Link } from '@mui/icons-material'

export const DataConnectivityIndicator = (props) => {
  const { children, flag = '' } = props
  const fieldsMap = useContext(FieldsMapContext)
  const { map = {}, dataset = '', model = '' } = fieldsMap ? fieldsMap : {}
  const mapKeys = Object.keys(map)
  return mapKeys && mapKeys.length > 0 && mapKeys.includes(flag) ? (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          background: '#F6F6F6',
          p: 0.5,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ fontSize: 12, fontWeight: 'bold', color: '' }}>
          Data source :{' '}
          <Chip
            icon={<Link />}
            label={`${_.startCase(dataset)} / ${_.startCase(
              model,
            )} / ${_.startCase(map[flag])}`}
            size="small"
            color="secondary"
            variant="outlined"
            sx={{ fontWeight: 'bold', borderRadius: 2 }}
          />
        </Typography>
      </Box>
    </>
  ) : (
    <>{children}</>
  )
}

export default DataConnectivityIndicator
