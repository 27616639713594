import React from 'react'
import { Box, Skeleton } from '@mui/material'

const ListLoader = (props) => {
  const { numberOfBlocks, height = 100, spacing = 2 } = props
  const dummyArray = [...Array(numberOfBlocks)]
  return (
    <Box>
      {dummyArray.map((_, key) => {
        return (
          <Skeleton
            key={key}
            animation="wave"
            variant="rectangular"
            width="100%"
            height={height}
            sx={{ marginTop: spacing }}
          />
        )
      })}
    </Box>
  )
}

export default ListLoader
