import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material'

export default function AddLink(props) {
  const { open = false, handleClose, addLink, reset = false, setReset } = props
  const [newLink, setNewLink] = useState({
    name: '',
    slug: '',
  })

  const addLinkToNode = () => {
    const { name, slug } = newLink
    addLink({
      name,
      slug,
      children: [],
    })
  }

  useEffect(() => {
    if (reset) {
      setReset(false)
      setNewLink({ name: '', slug: '' })
    }
  }, [reset, setReset])

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>New link</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              required
              sx={{ pb: 5 }}
              label="Link Label"
              value={newLink.name}
              variant="standard"
              onChange={(e) => setNewLink({ ...newLink, name: e.target.value })}
            />
            <TextField
              fullWidth
              required
              type="url"
              label="URL"
              variant="standard"
              value={newLink.slug}
              onChange={(e) => setNewLink({ ...newLink, slug: e.target.value })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!newLink.name || !newLink.slug}
            sx={{ textTransform: 'none' }}
            onClick={addLinkToNode}
            color={'primary'}
            variant="contained"
          >
            Add
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={handleClose}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
