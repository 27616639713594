import React from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})
const CustomSnackbar = (props) => {
  const { open, close, msg } = props
  const { message = 'something went wrong!', type = 'warning', color } = msg

  //Snackbar Alert could be success,error,warning,info
  // by default type will be succses

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={close}
      key={`snack-key-${message}`}
      autoHideDuration={7000}
    >
      <Alert
        onClose={close}
        severity={type}
        sx={{ width: '100%', background: color ? color : undefined }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar
