import React, { useRef, useState } from 'react'
import { Box, Typography, Avatar, IconButton, Divider } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import moment from 'moment'

// import { EMPTYLISTIMAGE } from '../../../../../assets/Image'

const ListingParticipants = (props) => {
  const { participantsbyMentorsData } = props
  const refScroll = useRef(null)
  const [scrolledRight, setScrolledRight] = useState(false)

  const getSessionTimings = (timeData) => {
    if (timeData) {
      let slotValues = timeData.split('-')
      let startValue = moment(slotValues[0], ['h:mm']).toDate()
      let startTime = moment(startValue).format('HH:mm A')
      let endValue = moment(slotValues[1], ['h:mm']).toDate()
      let endTime = moment(endValue).format('HH:mm A')

      return startTime + '-' + endTime
    }
  }

  const scroll = (scrollOffset) => {
    refScroll.current.scrollLeft += scrollOffset
    if (refScroll.current.scrollLeft <= 400) {
      setScrolledRight(false)
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'hidden',
          position: 'relative',
          scrollBehavior: 'smooth',
        }}
        ref={refScroll}
      >
        {scrolledRight && (
          <Box
            sx={{
              width: '20px',
              backgroundColor: '#D9D9D9',
              position: 'sticky',
              display: 'flex',
              justifyContent: 'center',
              left: 0,
              zIndex: 1,
            }}
            onClick={() => scroll(-400)}
          >
            <IconButton
              sx={{
                p: 0,
                disableRipple: {
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              <ArrowLeftIcon></ArrowLeftIcon>
            </IconButton>
          </Box>
        )}
        {participantsbyMentorsData.length > 0 &&
          participantsbyMentorsData.map((participant, index) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    p: 2,
                    gap: 2,
                    flex: '0 0 auto',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Avatar
                      alt="speaker_image"
                      src={''}
                      sx={{ height: 50, width: 50, alignSelf: 'center' }}
                    ></Avatar>
                    <Box
                      sx={{
                        borderRight: '1px solid #888888',
                        pr: 2,
                      }}
                    >
                      <Typography variant="body1">
                        <strong>{participant.user.name}</strong>{' '}
                        <span>{participant.user.company}</span>
                      </Typography>
                      <Typography variant="body1">
                        {' '}
                        {participant.meta.designation} -{' '}
                        {participant.meta.exp_years}yrs Exp,{' '}
                        {participant.user.email}
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 3 }}>
                        <Typography variant="body1" sx={{ fontSize: '12px' }}>
                          {' '}
                          {getSessionTimings(participant.slot)}
                        </Typography>
                        {/* <Typography sx={{ fontSize: '12px', color: '#3E72DB' }}>
                    <strong>{participant.meta.designation}</strong>
                  </Typography> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )
          })}
        {participantsbyMentorsData.length > 2 && (
          <Box
            sx={{
              width: '20px',
              backgroundColor: '#D9D9D9',
              position: 'sticky',
              display: 'flex',
              justifyContent: 'center',
              right: 0,
              zIndex: 1,
            }}
            onClick={() => {
              scroll(400)
              setScrolledRight(true)
            }}
          >
            <IconButton
              sx={{
                p: 0,
                disableRipple: {
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              <ArrowRightIcon sx={{ p: 0 }}></ArrowRightIcon>
            </IconButton>
          </Box>
        )}
      </Box>
      <Divider></Divider>
    </>
  )
}

export default ListingParticipants
