import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
} from '@mui/material'

export default function EditMenuData(props) {
  const { open = false, handleClose, data = {}, handleEdit } = props
  const { node = {} } = data
  const [menu, setMenu] = useState(node)

  useEffect(() => {
    if (open) {
      setMenu(node)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Edit Menu</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              required
              sx={{ pb: 5 }}
              label="Menu Label"
              value={menu.name}
              variant="standard"
              onChange={(e) => setMenu({ ...menu, name: e.target.value })}
            />
            <TextField
              fullWidth
              required
              label="URL"
              sx={{ pb: 5 }}
              value={menu.slug}
              variant="standard"
              onChange={(e) => setMenu({ ...menu, slug: e.target.value })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => handleEdit(menu, data)}>
            Save
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
