import React, { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import NoRecordsWorkshop from '../Common/NoRecordsWorkshop/NoRecordsWorkshop'
import { List } from './List'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  useListWorkshopsQuery,
  useListSpeakersByWorkshopsQuery,
  useDownloadWorskhopQuery,
  useGetParticipantsQuery,
} from '../../../../api/workshop'
import { EMPTYLISTIMAGE } from '../../../../assets/Image'
import moment from 'moment'
import { Loader, withRights } from '../../../../components'
import AccessDenied from '../../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../../utils/rightUtils'
import { getExperienceLabel } from '../Utils/Helper'

const initial_values = {
  search: '',
}
const Workshop = (props) => {
  const navigate = useNavigate()
  const {
    event = {},
    editDisabled = false,
    rights = {},
    rightsLoading,
    noSiteAccess = false,
  } = props
  const { id = null } = event
  const [workshopListData, setWorkshopListData] = useState([])
  const [workshopId, setWorkshopId] = useState('')
  const [workshopSpeakerData, setWorkshopSpeakerData] = useState([])
  const [partcipantsListData, setParticipantsListData] = useState([])
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [filters, setFilters] = useState(initial_values)
  const [worskhopExcelData, setWorkshopExcelData] = useState([])
  const { moduleAccess, permittedActions, superRights } = rights

  const fileType = 'text/csv;charset=utf-8;'
  const fileName = 'WorkshopData'

  const navigateToCreate = () => {
    navigate(`/events/workshop/create/${id}`)
  }

  const { data: workshopData, isFetching: workshopFetching } =
    useListWorkshopsQuery(
      { eventid: id, filters, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  const { data: speakersData, isFetching: speakersFetching } =
    useListSpeakersByWorkshopsQuery(
      {
        eventid: id,
        workshop_id: workshopId,
        activeSite,
      },
      { skip: !activeSite || !workshopId, refetchOnMountOrArgChange: true },
    )

  const { data: worskhopExportExcelData } = useDownloadWorskhopQuery(
    {
      eventid: id,
      activeSite,
    },
    { skip: !activeSite },
  )

  const { data: particpantData, isFetching: partcipantsFetching } =
    useGetParticipantsQuery(
      {
        eventid: id,
        workshop_id: workshopId,
        activeSite,
      },
      { skip: !activeSite || !workshopId, refetchOnMountOrArgChange: true },
    )

  useEffect(() => {
    if (particpantData) {
      setParticipantsListData(particpantData.data)
    }
  }, [particpantData])

  useEffect(() => {
    if (worskhopExportExcelData) {
      setWorkshopExcelData(worskhopExportExcelData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worskhopExportExcelData])

  useEffect(() => {
    if (workshopData) {
      setWorkshopListData(workshopData.data)
      if (workshopData.data.length > 0) {
        setWorkshopId(workshopData?.data[0]?._id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshopData])

  useEffect(() => {
    if (speakersData) {
      setWorkshopSpeakerData(speakersData)
    }
  }, [speakersData])

  const exportToCSV = () => {
    var csvRows = []
    var obj = {}
    var data = []

    worskhopExcelData.map((item) => {
      const approvedParticipants = item.participants.filter(
        (p) => p.status === 'CONFIRMED',
      )

      approvedParticipants.map((participant) => {
        obj = {}
        obj['Workshop Name'] = item.workshop.name
        obj['Description'] = item.workshop.description
        obj['Workshop Start date'] = moment
          .utc(item.workshop?.start_date)
          .format('MM/DD/YYYY')
        obj['Venue'] = item.workshop.venue ? item.workshop.venue : ''
        obj['Zoom link'] = item.workshop.zoom_link
          ? item.workshop.zoom_link
          : ''
        obj['Workshop Session Start Time'] = moment(item.workshop.start_time, [
          'h:mm',
        ]).format('hh:mm A')
        obj['Workshop Session End Time'] = moment(item.workshop.end_time, [
          'h:mm',
        ]).format('hh:mm A')
        obj[`Approved participant`] =
          participant.status === 'CONFIRMED' && participant?.user?.email
            ? participant.user.email
            : ''
        obj['Participant Company'] = participant?.user?.company
          ? participant?.user?.company
          : ''
        obj['Exp'] = `Exp ${getExperienceLabel({
          ...participant?.meta?.exp,
          experience: participant?.meta?.experience,
        })}`
        obj['Participant Designation'] = participant?.meta?.designation
          ? participant?.meta?.designation
          : ''
        data.push(obj)
        return obj
      })
      if (approvedParticipants.length === 0) {
        obj = {}
        obj['Workshop Name'] = item.workshop.name
        obj['Description'] = item.workshop.description
        obj['Workshop Start date'] = moment
          .utc(item.workshop?.start_date)
          .format('MM/DD/YYYY')
        obj['Venue'] = item.workshop.venue ? item.workshop.venue : ''
        obj['Zoom link'] = item.workshop.zoom_link
          ? item.workshop.zoom_link
          : ''
        obj['Workshop Session Start Time'] = moment(item.workshop.start_time, [
          'h:mm',
        ]).format('hh:mm A')
        obj['Workshop Session End Time'] = moment(item.workshop.end_time, [
          'h:mm',
        ]).format('hh:mm A')
        obj[`Approved participant`] = ''
        obj['Participant Company'] = ''
        obj['Participant Designation'] = ''
        obj['Exp'] = ''
        data.push(obj)
      }
      return item
    })
    if (data && data.length > 0) {
      const headers = Object.keys(data[0])
      csvRows.push(headers.join(','))
      for (const row of data) {
        const values = headers.map((header) => {
          const val = row[header]
          return `"${val}"`
        })
        csvRows.push(values.join(','))
      }
      csvRows = csvRows.join('\n')

      const blob = new Blob([csvRows], { type: fileType })
      const a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  const actionsAllowed = {
    userCanCreateWorkshop: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
    userCanEditWorkshop: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
    ),
    userCanDownloadWorkshop: checkUserIsAllowed(
      'download',
      permittedActions,
      superRights,
    ),
    userCanViewWorkshop: checkUserIsAllowed(
      'view',
      permittedActions,
      superRights,
    ),
  }
  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess ? (
    <Box>
      <Box sx={{ textAlign: 'right', mb: 2 }}>
        <Button
          disabled={editDisabled || !actionsAllowed.userCanCreateWorkshop}
          variant={'contained'}
          sx={{
            backgroundColor: '#CC0000',
            textTransform: 'none',
            fontSize: '16px',
            '&:hover': {
              background: '#CC0000',
            },
          }}
          onClick={() => navigateToCreate()}
        >
          Create a New Workshop
        </Button>
      </Box>

      {workshopListData && workshopListData.length === 0 && !filters.search ? (
        <NoRecordsWorkshop
          label={'Workshop'}
          image={EMPTYLISTIMAGE}
          editDisabled={editDisabled}
          btn={{
            label: 'Create a New Workshop',
            cb: navigateToCreate,
          }}
          disableWorkshop={!actionsAllowed.userCanCreateWorkshop}
          navigateToCreate={navigateToCreate}
        />
      ) : (
        <List
          eventId={id}
          workshopListData={workshopListData}
          workshopFetching={workshopFetching}
          speakersFetching={speakersFetching}
          setWorkshopId={setWorkshopId}
          workshopId={workshopId}
          workshopSpeakerData={workshopSpeakerData}
          setFilters={setFilters}
          filters={filters}
          exportToCSV={exportToCSV}
          editDisabled={editDisabled}
          partcipantsListData={partcipantsListData}
          partcipantsFetching={partcipantsFetching}
          actionsAllowed={actionsAllowed}
        />
      )}
    </Box>
  ) : (
    <AccessDenied module="Workshop" />
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'View',
      access: 'View',
    },
    {
      slug: 'Download',
      access: 'Download',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Create',
      access: 'Create',
    },
  ],
}

export default withRights(Workshop, moduleAttr)
