import React, { Fragment, useEffect, useContext } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Alignment,
  Color,
  FontSize,
  Format,
  LineHeight,
  InsideMargin,
  Text,
  RichText,
  ButtonSize,
  NoOfItems,
  Enum,
  Number,
  IconSelect,
} from '../ElementFields'
import {
  Animation,
  Position,
  Stepper,
  TabularControl,
  Media,
} from '../AttributeModifiers/Common'
import {
  AttrButtonGroup,
  ColorGradientPicker,
  Spacing,
  Typo,
  Text as TextInp,
} from '../AttributeModifiers'
import assign from 'lodash/assign'
import GroupLoader from './GroupLoader'
import { getValueByPath } from '../../../../../utils/dataParser'
import { ButtonTextColor } from '../AttributeModifiers/Button'
import { DataConnector } from '../AttributeModifiers/Common/DataConnector'
import { useGetConfigurationBySlugQuery } from '../../../../../api/configuration'
import { useSelector } from 'react-redux'
import { dynamicFieldSupportAvailability } from '../AttributeModifiers/Common/DataConnector/Utils'
import { FieldsMapContext } from './FieldsMapContext'

const types = {
  alignment: Alignment,
  color: Color,
  fontSize: FontSize,
  format: Format,
  lineHeight: LineHeight,
  insideMargin: InsideMargin,
  text: Text,
  richText: RichText,
  buttonSize: ButtonSize,
  noOfItems: NoOfItems,
  enum: Enum,
  media: Media,
  number: Number,
  icon: IconSelect,
  typo: Typo,
  btnGroup: AttrButtonGroup,
  colorPicker: ColorGradientPicker,
  stepper: Stepper,
  spacing: Spacing,
  textInp: TextInp,
  animation: Animation,
  tabularControls: TabularControl,
  bcTcColor: ButtonTextColor,
  position: Position,
}

const loadField = (props) => {
  const Field = types[props.type]
  return <Field {...props}></Field>
}

const FieldsLoaderUpdated = (props) => {
  const {
    group,
    values,
    onChange,
    groupNo,
    editAllowed = false,
    device = null,
    dynamic = false,
    setFieldMap,
    connected,
    path,
  } = props

  const { element, customAttributes, additionalAttributes, removeAttributes } =
    group
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const [eventEnabled, setEventEnabled] = React.useState(false)
  const fieldsMap = useContext(FieldsMapContext)

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'event',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  useEffect(() => {
    const updateSettings = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'event') {
          setEventEnabled(conf?.value?.enabled ? true : false)
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateSettings(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  const [activeAttributes, setActiveAttributes] = React.useState([])
  const [limit, setLimit] = React.useState(0)
  const [expanded, setExpanded] = React.useState(false)

  const isDevRole = true //TODO this should be change with dev role in future

  const processActiveAttributes = (
    attributes,
    customAttributes,
    additionalAttributes,
    removeAttributes,
  ) => {
    const processedAttributes = []
    const updatedAttributes = attributes.map((attr) => {
      if (attr.limitToChild && values[attr.slug] > -1) {
        setLimit(values[attr.slug])
      }
      const updatedAttr = { ...attr }
      if (customAttributes && customAttributes.length > 0) {
        const found = customAttributes.find((o) => o.slug === updatedAttr.slug)
        if (found) {
          return assign({}, updatedAttr, found)
        }
      }
      return attr
    })
    processedAttributes.push(...updatedAttributes)
    if (additionalAttributes && additionalAttributes.length > 0) {
      processedAttributes.push(...additionalAttributes)
    }
    const finalAttr = [
      ...(removeAttributes && removeAttributes.length > 0
        ? processedAttributes.filter(
            (pAttr) => !removeAttributes.includes(pAttr.slug),
          )
        : processedAttributes),
    ]
    setActiveAttributes(finalAttr)
  }

  useEffect(() => {
    if (element && element.attributes && element.attributes.length > 0) {
      processActiveAttributes(
        element.attributes,
        customAttributes,
        additionalAttributes,
        removeAttributes,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalAttributes, customAttributes, element, removeAttributes])

  const checkConditions = (peerValues, key, condition) => {
    const fieldCond = condition[key]
    if (typeof fieldCond === 'object') {
      if (fieldCond.neq) {
        return peerValues[key] !== fieldCond.neq
      } else {
        let check = true
        Object.keys(fieldCond).map((sKey) => {
          const condValue = fieldCond[sKey]
          const localCheck =
            peerValues[key] &&
            peerValues[key][sKey] &&
            peerValues[key][sKey] === condValue
          if (!localCheck) {
            check = false
          }
          return localCheck
        })
        return check
      }
    } else {
      if (
        !peerValues ||
        !peerValues[key] ||
        (peerValues[key] && fieldCond && peerValues[key] !== fieldCond)
      ) {
        return false
      } else {
        return true
      }
    }
  }

  const showComponent = (field, peerValues) => {
    let show = true
    if (field.when && field.when.length > 0) {
      field.when.map((c) => {
        let keys = Object.keys(c)
        keys.map((k) => {
          if (show) {
            show = checkConditions(peerValues, k, c)
          }
          return show
        })
        return show
      })
    }
    return show
  }

  const accordionToggle = (expanded) => {
    setExpanded(expanded)
  }

  const checkDynamicDataExists = (values, slug, path) => {
    if (!values || !slug) {
      return 0
    }
    const enabled =
      Object.keys(values).includes(`${slug}_dynamic`) &&
      values?.[`${slug}_dynamic`]?.dynamicDataEnabled
    if (enabled && !fieldsMap?.map) {
      setFieldMap({
        map: values?.[`${slug}_dynamic`]?.fieldsMap,
        dataset: values?.[`${slug}_dynamic`]?.dataset,
        model: values?.[`${slug}_dynamic`]?.model,
      })
    }
    return enabled
  }

  const dataSourceConnected = checkDynamicDataExists(
    values,
    element?.elements?.[0]?.element?.slug,
    path,
  )

  return (
    <>
      <Accordion
        disableGutters
        onChange={(_e, expanded) => {
          accordionToggle(expanded)
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-blanket"
          sx={{
            bgcolor: '#F5F5F5',
            '& .Mui-expanded p': {
              fontWeight: 'bold',
            },
          }}
        >
          <Typography>
            {`${element.name} ${groupNo ? '#' + groupNo : ''}`}
          </Typography>
        </AccordionSummary>
        {expanded && (
          <AccordionDetails
            sx={{
              p: 1,
              boxShadow: 4,
              border: '1px solid #f6f6f6',
              '& .attributes:nth-of-type(even)': {
                borderTop: '1px solid #e8e8e8',
                borderBottom: '1px solid #e8e8e8',
                background: '#F6F6F6',
                p: 0.5,
                pb: 2,
                pt: 0,
              },
              '& .attributes:nth-of-type(odd)': {
                p: 0.5,
                pb: 2,
                pt: 0,
              },
            }}
          >
            {activeAttributes &&
              activeAttributes.length > 0 &&
              activeAttributes.map((field, key) => {
                const value =
                  field?.soloDeviceAttr && device !== 'desktop'
                    ? values?.[
                        `${field?.slug}${
                          device.charAt(0).toUpperCase() + device.slice(1)
                        }`
                      ]
                    : field?.slug && field?.slug?.includes('*')
                    ? values
                    : getValueByPath(values, field.slug)
                const showAttribute =
                  (device === field.device || !field.device) &&
                  showComponent(field, values)
                return (
                  showAttribute && (
                    <Box
                      sx={{
                        pb: 1,
                        pt: 1,
                        display: `${
                          field?.isForDeveloper && !isDevRole ? 'none' : 'block'
                        } `,
                      }}
                      key={key}
                      className="attributes"
                    >
                      {loadField({
                        ...field,
                        device,
                        value: value,
                        onChange: function (val, fieldSlug) {
                          if (field.limitToChild) {
                            setLimit(val)
                          }
                          onChange(
                            fieldSlug && !fieldSlug.includes('*')
                              ? fieldSlug
                              : fieldSlug && fieldSlug.includes('*')
                              ? fieldSlug.replace(`${field.slug}.`, '')
                              : field.slug,
                            val ? val : field.defaultValue,
                          )
                        },
                        disabled: !editAllowed,
                        connected,
                        path: path,
                      })}
                    </Box>
                  )
                )
              })}
            {element.composite && element.multi && (
              <Box>
                {eventEnabled && dynamicFieldSupportAvailability(element) && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {dataSourceConnected ? (
                      <Typography sx={{ fontSize: 14, color: '#666666' }}>
                        Active datasource connected to this component
                      </Typography>
                    ) : (
                      <Typography sx={{ fontSize: 14, color: '#666666' }}>
                        Fill the details / Connect to a datasource (optional)
                      </Typography>
                    )}
                    <DataConnector
                      values={values}
                      meta={element}
                      onChange={onChange}
                      master={element.slug}
                      setFieldMapContext={setFieldMap}
                      path={path}
                      disabled={!editAllowed}
                    />
                  </Box>
                )}
                {!checkDynamicDataExists(
                  values,
                  element?.elements?.[0]?.element?.slug,
                  path,
                ) && !dynamic ? (
                  [...Array(limit)].map((_, key) => (
                    <Fragment key={key}>
                      <GroupLoader
                        elements={element.elements}
                        master={element.slug}
                        onChangeAttr={onChange}
                        position={key}
                        multi={element.multi}
                        values={values}
                        key={key}
                        editAllowed={editAllowed}
                        device={device}
                        path={`${path}[]`}
                      />
                    </Fragment>
                  ))
                ) : (
                  <GroupLoader
                    elements={element.elements}
                    master={element.slug}
                    onChangeAttr={onChange}
                    values={values}
                    editAllowed={editAllowed}
                    device={device}
                    dynamic={checkDynamicDataExists(
                      values,
                      element?.elements?.[0]?.element?.slug,
                      path,
                    )}
                    path={path}
                  />
                )}
              </Box>
            )}
            {element.composite && !element.multi && (
              <GroupLoader
                elements={element.elements}
                master={element.slug}
                onChangeAttr={onChange}
                values={values}
                editAllowed={editAllowed}
                device={device}
                dynamic={dynamic}
                path={path}
              />
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </>
  )
}

export default FieldsLoaderUpdated
