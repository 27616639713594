import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import Label from './Common/Label'

export const InsideMargin = (props) => {
  const { value, onChange, name = 'Inside margin', disabled = false } = props
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        <ToggleButtonGroup
          value={value}
          size={'small'}
          exclusive
          onChange={(_, val) => onChange(val)}
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              textTransform: 'none',
            },
            '& .Mui-selected': {
              background: 'grey.light',
              fontWeight: 'bold',
            },
          }}
          disabled={disabled}
        >
          <ToggleButton value={0} aria-label="Default">
            Default
          </ToggleButton>
          <ToggleButton value={1} aria-label="1">
            1
          </ToggleButton>
          <ToggleButton value={2} aria-label="2">
            2
          </ToggleButton>
          <ToggleButton value={3} aria-label="3">
            3
          </ToggleButton>
          <ToggleButton value={4} aria-label="4">
            4
          </ToggleButton>
          <ToggleButton value={5} aria-label="5">
            5
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

export default InsideMargin
