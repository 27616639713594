import { Alert, Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { FormHeading } from '../../../FormComponents'
import FormForEdit from './FormForEdit'
import { useParams } from 'react-router-dom'
import { useGetSpeakerByIdQuery } from '../../../../../api/event'
import { Placeholder, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'
import { Loader, withRights } from '../../../../../components'
import { checkUserIsAllowed } from '../../../../../utils/rightUtils'
import AccessDenied from '../../../../../Layout/AccessDenied'
import {
  useGetCategoriesQuery,
  useGetTagsQuery,
} from '../../../../../api/taxonomy'
import { useSelector } from 'react-redux'

const pathTrail = (id) => {
  return [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${id}`,
      label: 'Event Setup',
    },
    {
      to: '',
      label: 'Edit Speaker',
    },
  ]
}

/**
 *
 * TODO: Test case needs to be added
 */
export const EditSpeaker = (props) => {
  const { rights = {}, rightsLoading, noSiteAccess = false } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const [customFields, setCustomFields] = React.useState([])
  const [resetFormNow, setResetFormStatus] = React.useState(false)
  const [speakerData, setSpeakerData] = React.useState({})
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  let { id, speakerId } = useParams()
  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }

  const actionsAllowed = {
    userCanEditSpeaker: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
    ),
  }
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const { userCanEditSpeaker } = actionsAllowed

  const {
    data: speakerDetails,
    isFetching: speakerIsLoading,
    error: speakerLoadError,
  } = useGetSpeakerByIdQuery(speakerId, {
    skip: !speakerId,
    refetchOnMountOrArgChange: true,
  })

  const { data: categoriesList } = useGetCategoriesQuery(
    {
      filters: {},
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  const { data: tagsList } = useGetTagsQuery(
    {
      filters: {},
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  useEffect(() => {
    if (categoriesList) {
      const cat = []
      categoriesList.map((c) => {
        cat.push({ label: c.name, value: c.name })
        return 0
      })
      setCategories(cat)
    }
  }, [categoriesList])

  useEffect(() => {
    if (tagsList) {
      const tagList = []
      tagsList.map((t) => {
        tagList.push({ label: t.name, value: t.name })
        return 0
      })
      setTags(tagList)
    }
  }, [tagsList])

  useEffect(() => {
    if (speakerDetails) {
      setSpeakerData({
        ...speakerDetails.member,
        ...speakerDetails.speaker,
        year: moment().set('year', speakerDetails?.speaker.year),
      })
      if (speakerDetails?.speaker?.customFields) {
        setCustomFields([...speakerDetails.speaker.customFields])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speakerDetails])

  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess && userCanEditSpeaker ? (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }}>
        <Breadcrumbs trail={pathTrail(id)} />
        <Box sx={{ mt: 2, width: '100%', background: '#F6F7F8' }}>
          {!speakerLoadError ? (
            speakerIsLoading ? (
              <>
                <ProgressBar indeterminate />
                <Placeholder
                  style={{
                    flexGrow: 1,
                    marginLeft: 0,
                    marginTop: 0,
                    padding: 20,
                  }}
                >
                  <Placeholder.Rect height="80vh" width="100%" emphasized />
                </Placeholder>
              </>
            ) : (
              <>
                <FormHeading
                  text="Edit Speaker"
                  btns={[
                    {
                      label: 'Add new input',
                      type: 'select',
                      cb: addNewInput,
                      icon: 'Add',
                      options: [
                        { label: 'Text input', value: 'text' },
                        { label: 'Date input', value: 'date' },
                        { label: 'Switch input', value: 'switch' },
                      ],
                    },
                  ]}
                />
                <Divider variant="middle" sx={{ m: 0.5 }} />
                <Box container sx={{ display: 'flex', background: '#F6F7F8' }}>
                  <FormForEdit
                    customFields={customFields}
                    setCustomFields={setCustomFields}
                    reset={resetFormNow}
                    initialValues={speakerData}
                    setResetFormStatus={setResetFormStatus}
                    domains={categories}
                    skills={tags}
                  />
                </Box>
              </>
            )
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Alert severity="warning">
                Unable to load the speaker details! Please try again by
                refreshing the page. if issue persists reach out to product team
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <AccessDenied module="Speakers" />
    </Box>
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Edit',
      access: 'Edit',
    },
  ],
}
export default withRights(EditSpeaker, moduleAttr)
