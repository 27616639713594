import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const MenuButton = (props) => {
  const { label = '', options = [], onClick, startIcon } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const optionClick = (val) => {
    setAnchorEl(null)
    onClick(val)
  }

  return (
    <div>
      <Button
        id="basic-button"
        size="small"
        variant="outlined"
        color="secondary"
        startIcon={startIcon}
        aria-controls={open ? 'menu-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          textTransform: 'none',
          height: 35,
          mr: 1,
        }}
      >
        {label}
      </Button>
      <Menu
        id="menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button-opt',
        }}
      >
        {options &&
          options.length > 0 &&
          options.map((opt, key) => {
            return (
              <MenuItem key={key} onClick={() => optionClick(opt.value)}>
                {opt.label}
              </MenuItem>
            )
          })}
      </Menu>
    </div>
  )
}

export default MenuButton
