import { Box, Typography, Tooltip, Zoom, IconButton } from '@mui/material'
import React from 'react'
import InfoIcon from '@mui/icons-material/Info'

export const Label = (props) => {
  const { name = '', disabled = false, helperText = '' } = props
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', pt: 1, pb: 1 }}>
        <Typography
          data-testid={disabled ? 'label-elm-disabled' : 'label-elm'}
          sx={{
            fontSize: 13,
            fontWeight: 600,
            color: disabled ? 'rgba(0, 0, 0, 30%)' : '#000000',
            pr: 0.5,
          }}
        >
          {name}
        </Typography>
        {!disabled && helperText && (
          <Tooltip
            data-testid="tooltip"
            title={helperText}
            placement="top"
            arrow
            TransitionComponent={Zoom}
          >
            <IconButton data-testid="help-btn">
              <InfoIcon
                fontSize="small"
                sx={{ color: disabled ? 'rgba(0, 0, 0, 30%)' : '#000000' }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export default Label
