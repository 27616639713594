export const formatParticipantHistoryData = (historyInfo) => {
  if (!historyInfo) return []
  const formatted = historyInfo.map((log) => {
    const user = log?.updated_by?.displayName
    const status = log?.data?.status
    const timestamp = log?.updatedAt
    const comments = log?.data?.comments
    const updated = {
      status,
      message: `<strong>${
        user ? user : 'System'
      }</strong> changed the participant status to <strong>${status}</strong>`,
      comments,
      time: `${timestamp}`,
    }
    return updated
  })
  return formatted
}
