import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Close, Search, ArrowForward } from '@mui/icons-material'
import { useGetSiteActivityQuery } from '../../api/newDashboard'
import { formatDate } from '../../utils/helper'

const SiteActivityList = (props) => {
  const { onClose } = props

  const [activityList, setActivityList] = useState([])
  const [searchText, setSearchText] = useState('')

  const userId = useSelector((state) => state?.session?.profile?._id)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const navigate = useNavigate()

  const { data: activityData, isFetching: isActivityLoading } =
    useGetSiteActivityQuery({
      site: activeSite._id,
    })

  useEffect(() => {
    if (activityData) {
      const filteredActiviyList = activityData.data.filter((activity) => {
        const heading =
          activity.type === 'page'
            ? activity.page_data.name
            : activity.post_data.title
        return heading
          .trim()
          .toLowerCase()
          .includes(searchText.trim().toLowerCase())
      })
      setActivityList(filteredActiviyList)
    }
  }, [activityData, searchText])

  const navigateForEdit = (activity) => {
    const editUrl =
      activity.type === 'page'
        ? activity.revision
          ? `/pages/layout/${activity.revision}`
          : `/pages/update/${activity.page_data._id}`
        : `/posts/design/${activity.post_data._id}`
    navigate(editUrl)
  }
  const getActivityContent = (activity) => {
    const { status, updated_by, type } = activity
    switch (status) {
      case 'edited':
        return `${
          userId === updated_by?._id ? 'You' : updated_by?.displayName
        } edited the ${type}`
      case 'published':
        return `${
          userId === updated_by?._id ? 'You' : updated_by?.displayName
        } published the ${type}`
      case 'unpublished':
        return `${
          userId === updated_by?._id ? 'You' : updated_by?.displayName
        } unpublished the ${type}`
      default:
        return status
    }
  }

  return (
    <Stack direction="column" width={400} height="100%">
      <Stack
        direction="row"
        height={60}
        alignItems="center"
        px={2}
        sx={{
          flexShrink: 0,
          background: '#F6F7F8',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="#1A1A1A" flex={1}>
          Activity List
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Box pt={2} pb={1} px={1}>
        <TextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          placeholder="Search"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box height="100%" width="100%" overflow="scroll">
        {isActivityLoading ? (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <CircularProgress color="secondary" />
          </Stack>
        ) : activityData.data.length === 0 ? (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <Typography
              color="#1A1A1A"
              textTransform="capitalize"
              fontSize="small"
            >
              No Activity present!
            </Typography>
          </Stack>
        ) : activityList.length === 0 ? (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <Typography
              color="#1A1A1A"
              textTransform="capitalize"
              fontSize="small"
            >
              No results match your filters!
            </Typography>
          </Stack>
        ) : (
          <Stack direction="column" pt={1} pb={2} px={1} gap={0.5}>
            {activityList.map((activity) => (
              <Card elevation={2}>
                <Stack direction="row" px={2} py={0.5} alignItems="center">
                  <Box flex={1} minWidth={0}>
                    <Typography fontWeight="bold" color="#1A1A1A">
                      {activity.type === 'page'
                        ? activity.page_data.name
                        : activity.post_data.title}
                    </Typography>
                    <Typography
                      color="#1A1A1A"
                      textTransform="capitalize"
                      fontSize="small"
                      sx={{
                        wordWrap: 'break-word',
                      }}
                    >
                      {getActivityContent(activity)}
                    </Typography>
                    <Typography color="#7D7D7D" fontSize="small">
                      {`${formatDate(activity.createdAt)}`}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      color="secondary"
                      onClick={(e) => navigateForEdit(activity)}
                      sx={{
                        textTransform: 'capitalize',
                      }}
                    >
                      View {activity.type}
                      <ArrowForward fontSize="small" />
                    </Button>
                  </Box>
                </Stack>
              </Card>
            ))}
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default SiteActivityList
