import React from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  TextField,
  Typography,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  LinearProgress,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const OpenDailog = (props) => {
  const {
    handleClose,
    listData,
    dialogTitle = 'Approved Participants',
    handleMenuClick,
    handleMenuClose,
    menuOpen,
    anchorEl,
    handleCancel,
    cancelBookingLoading,
    cancelInQueueLoading,
    page,
  } = props

  return (
    <Box>
      <List sx={{ height: '27vh' }}>
        <ListItem>
          <ListItemText
            primary={`${dialogTitle}`}
            primaryTypographyProps={{ fontSize: 20, fontWeight: 'bold' }}
          ></ListItemText>
        </ListItem>
        <Divider sx={{ bgcolor: '#E6E6E6' }} />
        <ListItem sx={{ justifyContent: 'space-between', my: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Found
          </Typography>
          <Chip label={listData?.length} />
          <TextField
            id="standard-bare"
            variant="outlined"
            placeholder="Search"
            // value={filters.search}
            name="search"
            // onChange={(e) => onHandleFilters(e.target)}
            sx={{
              width: '70%',
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </ListItem>
        <Divider sx={{ bgcolor: '#616161' }} />
        {cancelBookingLoading ||
          (cancelInQueueLoading && <LinearProgress></LinearProgress>)}
        {listData &&
          listData?.length > 0 &&
          listData.map((item, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  alignItems: 'flex-start',
                  justifyContent:
                    dialogTitle === 'Total Mentors' ? 'start' : 'space-between',
                }}
              >
                <Avatar
                  alt="speaker_image"
                  src={
                    dialogTitle === 'Total Mentors' ? item.engagement_image : ''
                  }
                  sx={{ height: 70, width: 70 }}
                ></Avatar>
                {dialogTitle === 'Total Mentors' ? (
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'bold', p: 0.5 }}
                    >
                      {item.member_data?.name}{' '}
                      <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                        {item.member_data?.company},
                      </span>
                    </Typography>
                    <Typography variant="body1" sx={{ p: 0.5 }}>
                      {item.speaker_meta.designation}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', p: 0.5 }}
                      >
                        {item.user.name}{' '}
                        <span style={{ fontWeight: 'normal', fontSize: 14 }}>
                          {item.user.company}, {item.meta.city}{' '}
                        </span>
                      </Typography>
                      <Typography variant="body1" sx={{ p: 0.5 }}>
                        {item.meta.designation} – {item.meta.exp_years} Exp -{' '}
                        {item.user.email}
                      </Typography>
                      {/* <Typography variant="body1" sx={{ p: 0.5, fontSize: 12 }}>
                    10:15 AM To 10:25 PM
                  </Typography> */}
                    </Box>
                    <IconButton onClick={handleMenuClick}>
                      <MoreVertIcon></MoreVertIcon>
                    </IconButton>
                    <Menu
                      id="menu"
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      PaperProps={{
                        style: {
                          width: '10ch',
                        },
                      }}
                    >
                      {page === 'workshop' ? (
                        <MenuItem onClick={() => handleCancel(item.user?._id)}>
                          Cancel
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() =>
                            handleCancel(
                              item.user?._id,
                              item.speaker._id,
                              item._id,
                            )
                          }
                        >
                          Cancel
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )}
              </ListItem>
            )
          })}
      </List>

      <Box>
        <IconButton
          type="submit"
          aria-label="close menu"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: '12px',
            right: '5px',
            float: 'right',
          }}
        >
          <CloseIcon
            sx={{
              color: 'rgb(0,0,0,0.87)',
              fontSize: '2rem',
            }}
          ></CloseIcon>
        </IconButton>
      </Box>
    </Box>
  )
}

export default OpenDailog
