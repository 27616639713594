const expSplit = [3, 7, 12]
/**
 * Getting experience label from exp obj
 * @param {number} from
 * @param {number} to
 * @returns string label
 */
export const getExperienceLabel = ({ from, to, experience = '' }) => {
  if (!from && !to) {
    return (experience && experience?.indexOf('-')) ||
      (experience && experience?.indexOf('+'))
      ? experience
      : ''
  }
  const fromInt = parseInt(from)
  if (fromInt >= 12 && !to) {
    return '12+'
  }
  const toInt = parseInt(to)
  if (expSplit.includes(fromInt) && expSplit.includes(toInt)) {
    return `${from}-${to}`
  }

  const maxPos = expSplit.filter((e) => e > from).shift()
  const pos = expSplit.indexOf(maxPos)
  return `${expSplit?.[pos - 1] ? expSplit?.[pos - 1] : 0}-${expSplit?.[pos]}`
}
