import React from 'react'
import { Box, Button, Card, IconButton, SvgIcon } from '@mui/material'
import Label from './Common/Label'
import SelectIcon from './Common/SelectIcon'
import * as mui from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'

export const IconSelect = (props) => {
  const { value, onChange, name = 'Select icon', disabled = false } = props
  const [open, setOpen] = React.useState(false)
  const confirmSelection = (value) => {
    onChange(value)
    setOpen(false)
  }

  const deleteSelectedIcon = () => {
    onChange('')
  }
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        {value ? (
          <Card sx={{ p: 2, alignItems: 'center', display: 'flex' }}>
            <SvgIcon component={mui[value]} />
            <IconButton disabled={disabled}>
              <DeleteIcon onClick={() => deleteSelectedIcon()} />
            </IconButton>
          </Card>
        ) : (
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            size="small"
            onClick={() => setOpen(true)}
            disabled={disabled}
          >
            Select icon
          </Button>
        )}
        <SelectIcon
          open={open}
          handleClose={() => setOpen(false)}
          confirmSelection={confirmSelection}
        />
      </Box>
    </Box>
  )
}

export default IconSelect
