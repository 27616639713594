import { Card, CardActionArea, Skeleton } from '@mui/material'
import ConditionalWrapper from '../../../components/ConditionalWrapper'

import MetricsCardIcon from '../../../assets/Image/metrics_card_icon.svg'

const MetricsCard = (props) => {
  const {
    title,
    value,
    units,
    isLoading,
    color = '#4318FF',
    isSelected = false,
    onSelect = null,
  } = props

  return (
    <Card
      elevation={1}
      sx={{
        borderRadius: '20px',
        padding: onSelect ? 0 : '14px',
        boxShadow:
          isSelected &&
          '0px 2px 1px -1px rgba(204, 0, 0, 0.2), 0px 1px 1px 0px rgba(204, 0, 0, 0.2), 0px 1px 3px 0px rgba(204, 0, 0, 0.2)',
        border:
          onSelect && isSelected ? '2px solid #CC0000' : '2px solid #FFFFFF',
        cursor: onSelect ? 'pointer' : 'default',
        ':hover': {
          border: onSelect
            ? !isSelected
              ? '2px solid #EEEEEE'
              : '2px solid #CC0000'
            : '2px solid #FFFFFF',
        },
        ':active': {
          scale: onSelect ? '0.98' : '1',
        },
        transition: 'scale 0.1s ease-in-out',
      }}
      onClick={(e) => {
        onSelect && onSelect()
      }}
    >
      <ConditionalWrapper
        condition={!!onSelect}
        wrapper={(children) => (
          <CardActionArea sx={{ padding: '14px' }}>{children}</CardActionArea>
        )}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'minmax(0, 1fr) minmax(0,3fr)',
            gap: '0 20px',
          }}
        >
          <div
            style={{
              aspectRatio: '1/1',
              width: '100%',
              borderRadius: '50%',
              background: `${color}33`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gridRow: '1/3',
            }}
          >
            <MetricsCardIcon style={{ fill: color, opacity: 1 }} />
          </div>
          <div
            style={{
              color: '#A3AED0',
              fontSize: '14px',
              height: '3em',
              lineHeight: '1em',
              marginBottom: '10px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                color: '#2B3674',
                fontSize: '24px',
                fontWeight: 'bold',
                flex: 1,
              }}
            >
              {isLoading ? <Skeleton /> : value}
            </span>
            <span style={{ color: '#A3AED0', fontSize: '14px' }}>{units}</span>
          </div>
        </div>
      </ConditionalWrapper>
    </Card>
  )
}

export default MetricsCard
