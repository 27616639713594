import React from 'react'
import { Box } from '@mui/material'

export const NoEvents = (props) => {
  const {
    img = '',
    label,
    btn = '',
    type = 'active',
    editDisabled,
    noContent = false,
  } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ margin: '70px' }}>
        <img src={img} alt="powerofpen" width={266} />
      </Box>
      {!noContent && (
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ fontWeight: 'bold', fontSize: 24 }}>
            Hey! You don't have any active {label}{' '}
            {type === 'active' ? '' : 'for the next year'}. <br />
            To create an event, please click on the{' '}
            <span
              // onClick={() => navigate('/events/create')}
              onClick={btn.cb}
              sx={{
                color: '#CC0000',
                cursor: 'pointer',
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: 24,
                p: 0,
                mt: -1,
              }}
              disabled={editDisabled}
              data-testid="create-newevent-link"
            >
              {btn.label}
            </span>{' '}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default NoEvents
