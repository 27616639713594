import { createSlice } from '@reduxjs/toolkit'
import { profileApi } from '../api/profile'
import { isUserOnlyHavingDemoAccess } from './apiUtils'

const initialState = {
  userInfo: {
    fullName: '',
  },
  identityToken: '',
  profile: {
    sites: [],
    noSiteAccess: false,
  },
  sideBarOpen: false,
  isEditModeOn: false,
  mediaList: [],
  myRoleList: [],
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setUserSession: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userInfo = action.payload
        ? action.payload.userInfo
        : initialState.userInfo
      state.identityToken = action.payload.identityToken
        ? action.payload.identityToken
        : initialState.identityToken
    },
    toggleSideBar: (state) => {
      state.sideBarOpen = !state.sideBarOpen
    },
    enableSiteAccess: (state) => {
      state.profile.noSiteAccess = false
    },
    toggleEditMode: (state) => {
      state.isEditModeOn = !state.isEditModeOn
    },
    persistMediaList: (state, action) => {
      state.mediaList =
        action?.payload?.length > 0 ? action?.payload : initialState?.mediaList
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.getProfile.matchFulfilled,
      (state, { payload }) => {
        state.profile =
          payload && payload.length > 0
            ? {
                ...payload[0],
                noSiteAccess: isUserOnlyHavingDemoAccess(payload),
              }
            : { ...initialState.profile, noSiteAccess: true }
      },
    )
  },
})

// Action creators are generated for each case reducer function
export const {
  setUserSession,
  toggleSideBar,
  enableSiteAccess,
  toggleEditMode,
  persistMediaList,
} = sessionSlice.actions

export default sessionSlice.reducer
