import { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  Box,
  ButtonGroup,
  Button,
  Select,
  MenuItem,
  Stack,
  SvgIcon,
} from '@mui/material'
import { CalendarToday } from '@mui/icons-material'

import MetricsCard from './Widgets/MetricsCard'
import PieChartCard from './Widgets/PieChartCard'
import BarChartCard from './Widgets/BarChartCard'
import ImpactCard from './Widgets/ImpactCard'
import SearchKeywordsCard from './Widgets/SearchKeywordsCard'
import MobileUsersCard from './Widgets/MobileUsersCard'
import BusinessOverviewTableCard from './Widgets/BusinessOverviewTableCard'
import ProductOverviewTableCard from './Widgets/ProductOverviewTableCard'
import ProductOverviewChartCard from './Widgets/ProductOverviewChartCard'

import { useGetAdminMetricsDataQuery } from '../../api/newDashboard'
import {
  ADMIN_DASHBOARD_METRICS,
  INITIAL_ADMIN_DASHBOARD_STATE,
} from './dashboardConstants'

// Following lines are for dummy dashboard data. Remove after API integration.
const dummyData = require('./dummyDashboardData.json')

const TABS = ['Product Overview', 'Business Overview']

const productOverviewReducer = (state, action) => {
  switch (action.type) {
    case 'updateMetricsCounts': {
      const {
        total_pages_published,
        active_users,
        total_files_uploaded,
        total_posts,
        total_users_onboarded,
        total_pages_created,
        total_pages_published_by_month,
        total_files_uploaded_by_month,
        total_pages_created_by_month,
        total_posts_created_by_month,
      } = action.value.counts

      return {
        ...state,
        metrics: {
          ...state.metrics,
          pages_created: {
            ...state.metrics.pages_created,
            count: total_pages_created,
            timeSeries: Object.keys(total_pages_created_by_month).map(
              (month) => ({
                month: month,
                value: total_pages_created_by_month[month],
              }),
            ),
          },
          pages_published: {
            ...state.metrics.pages_published,
            count: total_pages_published,
            timeSeries: Object.keys(total_pages_published_by_month).map(
              (month) => ({
                month: month,
                value: total_pages_published_by_month[month],
              }),
            ),
          },
          posts_created: {
            ...state.metrics.posts_created,
            count: total_posts,
            timeSeries: Object.keys(total_posts_created_by_month).map(
              (month) => ({
                month: month,
                value: total_posts_created_by_month[month],
              }),
            ),
          },
          users_onboarded: {
            ...state.metrics.users_onboarded,
            count: total_users_onboarded,
          },
          active_users: {
            ...state.metrics.active_users,
            count: active_users,
          },
          files_uploaded: {
            ...state.metrics.files_uploaded,
            count: total_files_uploaded,
            timeSeries: Object.keys(total_files_uploaded_by_month).map(
              (month) => ({
                month: month,
                value: total_files_uploaded_by_month[month],
              }),
            ),
          },
        },
      }
    }
    default:
      return { ...state }
  }
}

const AdminWebsiteDashboard = (props) => {
  const [selectedTab, setSelectedTab] = useState(TABS[0])
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [productOverviewData, dispatchProductOverviewData] = useReducer(
    productOverviewReducer,
    INITIAL_ADMIN_DASHBOARD_STATE.product_overview,
  )
  const [businessOverviewData, setBusinessOverviewData] = useState(
    INITIAL_ADMIN_DASHBOARD_STATE.business_overview,
  )

  const activeSite = useSelector((state) => state.tenant.activeSite)

  const { data: metricsData, isFetching: isMetricsLoading } =
    useGetAdminMetricsDataQuery({
      year: selectedYear,
      site: activeSite._id,
    })

  useEffect(() => {
    if (metricsData) {
      dispatchProductOverviewData({
        type: 'updateMetricsCounts',
        value: metricsData,
      })
    }
  }, [metricsData])
  useEffect(() => {
    setBusinessOverviewData(dummyData.business_overview)
  }, [])

  return (
    <Box margin={2}>
      <ButtonGroup disableElevation>
        {TABS.map((tabValue) => (
          <Button
            disabled={tabValue === 'Business Overview'}
            key={tabValue}
            variant={selectedTab === tabValue ? 'contained' : 'outlined'}
            onClick={() => setSelectedTab(tabValue)}
            color="secondary"
          >
            {tabValue}
          </Button>
        ))}
      </ButtonGroup>
      {selectedTab === TABS[0] && (
        <Box padding={2} marginTop={2} sx={{ backgroundColor: '#F6F7F8' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
          >
            <h3>
              {selectedYear === moment().year() ? 'Current year' : selectedYear}{' '}
              status
            </h3>
            <Select
              size="small"
              value={selectedYear}
              onChange={(event) => {
                setSelectedYear(event.target.value)
              }}
              renderValue={(value) => {
                return (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <SvgIcon style={{ color: '#5C5C5C' }} fontSize="small">
                      <CalendarToday />
                    </SvgIcon>
                    {value}
                  </Box>
                )
              }}
            >
              {Array.from({ length: 5 }, (_, i) => moment().year() - i).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ),
              )}
            </Select>
          </div>
          {/* First row */}
          <Stack direction="row" flexWrap="wrap" gap="20px">
            {ADMIN_DASHBOARD_METRICS.product_overview.map((metricDetails) => (
              <Box
                key={metricDetails.key}
                maxHeight={150}
                maxWidth={300}
                sx={{
                  flex: '1 0 200px',
                }}
              >
                <MetricsCard
                  title={metricDetails.title}
                  value={productOverviewData.metrics[metricDetails.key].count}
                  color={metricDetails.color}
                  isLoading={isMetricsLoading}
                />
              </Box>
            ))}
          </Stack>
          {/* Second row */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <ProductOverviewChartCard
              isLoading={isMetricsLoading}
              metrics={productOverviewData.metrics}
            />
            <ProductOverviewTableCard selectedYear={selectedYear} />
          </div>
        </Box>
      )}
      {selectedTab === TABS[1] && (
        <Box padding={2} marginTop={2} sx={{ backgroundColor: '#F6F7F8' }}>
          {/* First row */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              gap: '20px',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                gap: '20px',
              }}
            >
              <div style={{ gridColumn: '1 / 4' }}>
                <h3>Stencil Metrics</h3>
              </div>
              <MetricsCard
                title="Page Views"
                value={businessOverviewData.metrics.page_views}
                color="#EDB508"
              />
              <MetricsCard
                title="Active Users"
                value={businessOverviewData.metrics.active_users}
                color="#4318FF"
              />
              <MetricsCard
                title="Blogs/Pages Published"
                value={businessOverviewData.metrics.pages_published}
                color="#2951A3"
              />
              <div style={{ gridColumn: '1 / 4' }}>
                <h3>Website Metrics</h3>
              </div>
              <MetricsCard
                title="Avg. Page Load Time"
                value={businessOverviewData.metrics.avg_page_load_time}
                units="secs"
                color="#2951A3"
              />
              <MetricsCard
                title="Avg. Time on Page"
                value={businessOverviewData.metrics.avg_time_on_page}
                units="secs"
                color="#27633B"
              />
              <MetricsCard
                title="Avg. Pages/Session"
                value={businessOverviewData.metrics.avg_pages_per_session}
                color="#CC0000"
              />
              <MetricsCard
                title="Avg. Page Creation Time"
                value={businessOverviewData.metrics.avg_page_creation_time}
                units="mins"
                color="#EDB508"
              />
              <MetricsCard
                title="Avg. Page edit Time"
                value={businessOverviewData.metrics.avg_page_edit_time}
                units="mins"
                color="#4318FF"
              />
              <MetricsCard
                title="Bounce Rate"
                value={businessOverviewData.metrics.bounce_rate}
                color="#2951A3"
              />
            </div>
            <Stack direction="column">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row-reverse',
                  marginBottom: '20px',
                }}
              >
                <Select
                  size="small"
                  value={selectedYear}
                  onChange={(event) => {
                    setSelectedYear(event.target.value)
                  }}
                >
                  {Array.from({ length: 5 }, (_, i) => moment().year() - i).map(
                    (year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </div>
              <BusinessOverviewTableCard
                tableData={businessOverviewData.page_details}
              />
            </Stack>
          </div>
          {/* Second row */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
              gap: '20px',
              margin: '20px 0',
            }}
          >
            <BarChartCard
              title="Avg. Page Load Time (sec)"
              count={businessOverviewData.page_load_time.avg}
              data={[
                {
                  name: 'Web',
                  value: businessOverviewData.page_load_time.web,
                  fill: '#006649',
                },
                {
                  name: 'Mob',
                  value: businessOverviewData.page_load_time.mob,
                  fill: '#CC0000',
                },
                {
                  name: 'Tab',
                  value: businessOverviewData.page_load_time.tab,
                  fill: '#E2BA0C',
                },
              ]}
            />
            <PieChartCard
              title="Most viewed Browsers"
              count={businessOverviewData.top_browsers.count}
              data={businessOverviewData.top_browsers.data}
            />
            <PieChartCard
              title="Source- Direct URL or Social platforms"
              count={businessOverviewData.sources.count}
              data={businessOverviewData.sources.data}
            />
            <ImpactCard
              title="Broken link Records"
              units="Effect"
              data={businessOverviewData.broken_link_records.data}
            />
          </div>
          {/* Third row */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              gap: '20px',
            }}
          >
            <SearchKeywordsCard
              title="Keywords users are trying to search"
              count={businessOverviewData.search_keywords.count}
              data={businessOverviewData.search_keywords.data}
            />
            <MobileUsersCard data={businessOverviewData.mobile_users} />
          </div>
        </Box>
      )}
    </Box>
  )
}

export default AdminWebsiteDashboard
