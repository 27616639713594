import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { usePushNotificationMutation } from '../../../../api/event'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Close } from '@mui/icons-material'
import _ from 'lodash'
import EngagementsList from './EngagementsList'
import { Loader, withRights } from '../../../../components'
import { checkUserIsAllowed } from '../../../../utils/rightUtils'
import AccessDenied from '../../../../Layout/AccessDenied'

const Notifications = (props) => {
  const { rights = {}, rightsLoading, noSiteAccess = false } = props

  const { moduleAccess, permittedActions, superRights } = rights

  const actionsAllowed = {
    userCanSendNotification: checkUserIsAllowed(
      'notifications',
      permittedActions,
      superRights,
    ),
  }
  const pages = [
    {
      value: '/profile',
      label: 'Profile',
    },
    {
      value: '/pastHighLight',
      label: 'Past HighLight',
    },
    {
      value: '/speakers',
      label: 'Speakers',
    },
    {
      value: '/quiz',
      label: 'Quiz',
    },
    {
      value: '/wordSearch',
      label: 'Word Search',
    },
    {
      value: '/jigsaw',
      label: 'Jigsaw',
    },
    {
      value: '/anagram',
      label: 'Anagram',
    },
    {
      value: '/gameWinner',
      label: 'Game Winner',
    },
    {
      value: '/mentorship',
      label: 'Mentorship',
    },
    {
      value: '/agenda',
      label: 'Agenda',
    },
    {
      value: '/socialMedia',
      label: 'SocialMedia',
    },
    {
      value: '/workshop',
      label: 'Workshop',
    },
    {
      value: '/useFullTips',
      label: 'Usefull Tips',
    },
    {
      value: '/qrCode',
      label: 'Qr Code',
    },
    {
      value: '/rating',
      label: 'Feedback',
    },
    {
      value: '/myActivity',
      label: 'Activity',
    },
  ]

  const params = useParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { id: eventId } = params
  const formRef = useRef()
  const [notification, setNotification] = useState({
    title: '',
    page: '',
    body: '',
    quiz_name: '',
    engagementId: '',
    engagementName: '',
  })

  const [pushNewNotification, { isSuccess }] = usePushNotificationMutation()

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Notification published successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: (key) => (
          <>
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Close></Close>
            </IconButton>
          </>
        ),
      })
      setNotification({
        title: '',
        page: '',
        body: '',
        quiz_name: '',
        engagementId: '',
        engagementName: '',
      })
    }
  }, [closeSnackbar, enqueueSnackbar, isSuccess])

  function handleSubmit(e) {
    e.preventDefault()
    const isValidForm = formRef.current.reportValidity()
    if (isValidForm) {
      pushNewNotification({
        eventid: eventId,
        reqBody: {
          type: 'PUSH_NOTIFICATIONS',
          select_all: true,
          participants: ['all'],
          quiz_name: notification.quiz_name,
          program: notification.engagementId,
          program_name: notification.engagementName,
          notification: {
            title: notification.title,
            body: notification.body,
            data: {
              redirect_to: notification.page,
            },
          },
          filters: {
            page: 1,
            status: ['APPROVED'],
            search: '',
            perpage: 15,
          },
        },
      })
    }
  }
  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess ? (
    <Box
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
      sx={{
        width: '50%',
        margin: '40px  auto',
        '& .MuiTextField-root': { m: 1 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off"
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item md={4}>
            <FormLabel>Enter the title*</FormLabel>
          </Grid>
          <Grid item md={6}>
            <TextField
              size="small"
              required
              fullWidth
              value={notification.title}
              onChange={(e) =>
                setNotification({ ...notification, title: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          width: '100%',
        }}
      >
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item md={4}>
            <FormLabel>Enter the Body*</FormLabel>
          </Grid>
          <Grid item md={6}>
            <TextField
              required
              fullWidth
              multiline
              rows={3}
              value={notification.body}
              onChange={(e) =>
                setNotification({ ...notification, body: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          width: '100%',
        }}
      >
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item md={4}>
            <FormLabel>Choose the landing page*</FormLabel>
          </Grid>
          <Grid item md={6}>
            <TextField
              size="small"
              fullWidth
              select
              label="Select"
              required
              value={notification.page}
              onChange={(e) => {
                setNotification({ ...notification, page: e.target.value })
              }}
            >
              {_.sortBy(pages, ['label']).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          width: '100%',
        }}
      >
        {notification.page === '/jigsaw' ||
        notification.page === '/wordSearch' ? (
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item md={4}>
              {/* <FormLabel>Skip participated cohorts</FormLabel> */}
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setNotification({
                        ...notification,
                        quiz_name:
                          e.target.checked &&
                          (notification.page === '/jigsaw'
                            ? 'JIGSAW'
                            : notification.page === '/wordSearch'
                            ? 'WORD_SEARCH'
                            : ''),
                      })
                    }}
                  />
                }
                label="Skip played users"
              />
            </Grid>
          </Grid>
        ) : null}
      </div>
      {(notification.page === '/myActivity' ||
        notification.page === '/rating') && (
        <EngagementsList
          notification={notification}
          setNotification={setNotification}
        />
      )}

      <div style={{ width: '100%', textAlign: 'center', marginTop: '25px' }}>
        <Button
          sx={{
            textTransform: 'none',
            minWidth: '148px',
            minHeight: '44px',
            fontSize: '16px',
            fontWeight: 'bold',
            backgroundColor: '#CC0000',
            border: '1px solid #CC0000',
            ':hover': {
              backgroundColor: '#CC0000',
              color: '#fff',
            },
            ':focus': { border: '2px dashed #666666' },
          }}
          disableFocusRipple
          disableElevation
          variant="contained"
          disabled={!actionsAllowed?.userCanSendNotification}
          onClick={(e) => handleSubmit(e)}
        >
          Publish
        </Button>
      </div>
    </Box>
  ) : (
    <AccessDenied />
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Read',
      access: 'Read',
    },
    {
      slug: 'Notification',
      access: 'Notification',
    },
  ],
}

export default withRights(Notifications, moduleAttr)
