import React from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import {
  FieldLoader,
  disableControlsBasedOnDevice,
  showComponent,
} from '../../Helpers'
import { getValueByPath } from '../../../../../../../utils/dataParser'
import { Label } from '../Label'

export const TabularControl = (props) => {
  const {
    attrList = [],
    onChange,
    value = {},
    device = '',
    slug = '',
    disabled = false,
    enableLabel = false,
    name = '',
    helperText = '',
    isFromTabular = false,
    path = '',
  } = props
  return (
    <Box>
      {enableLabel && <Label name={name} helperText={helperText} />}
      <TableContainer component={Paper} sx={{ borderRadius: 0, boxShadow: 0 }}>
        <Table size="small" sx={{}}>
          <TableBody>
            {attrList.map((row) => {
              const showAttribute =
                (device === row.device || !row.device) &&
                showComponent(row, value)
              return (
                showAttribute && (
                  <TableRow
                    key={row.name}
                    sx={{
                      '& .MuiTableCell-root': {
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 13 }}
                      width={'35%'}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="center" sx={{ p: 0 }} width={'65%'}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          p: 0.5,
                        }}
                      >
                        <FieldLoader
                          {...row}
                          onChange={onChange}
                          device={device}
                          slug={`${slug}.${row.slug}`}
                          disabled={disableControlsBasedOnDevice(
                            device,
                            row.forDevices,
                            disabled,
                          )}
                          value={
                            device &&
                            device !== 'desktop' &&
                            row.forDevices &&
                            row.forDevices.includes(device) &&
                            !row.noSlugChange
                              ? getValueByPath(
                                  value,
                                  `${row.slug}${
                                    device.charAt(0).toUpperCase() +
                                    device.slice(1)
                                  }`,
                                )
                              : getValueByPath(value, row.slug)
                          }
                          isFromTabular={isFromTabular}
                          path={path}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TabularControl
