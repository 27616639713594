import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { Visibility } from '@mui/icons-material'

import { useGetPagesOrPostsQuery } from '../../../api/newDashboard'
import {
  currentVersionEditable,
  getLatestVersionFromPage,
  getStatusOfThePageVersion,
  versionAssignedToThisUser,
} from '../../Pages/pageUtils'
import { STATUS_TEXT } from '../../../utils/constant'

const CARD_FILTER_MAPPING = {
  my_assignments: {
    assigned_to_me: true,
  },
  pending_requests: {
    status: 'pending_approval',
  },
  approved_requests: {
    status: 'approved',
  },
  drafts: {
    status: 'draft',
  },
  published: {
    status: 'published',
  },
  unpublished: {
    status: 'unpublished',
  },
  rejected_requests: {
    status: 'rejected',
  },
}
const TABS = ['page', 'post']
const PAGE_SIZE = 10

const PagesAndPostsTable = (props) => {
  const { selectedYear, site, selectedCard, actionsAllowed } = props
  const [selectedTab, setSelectedTab] = useState(TABS[0])
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)

  const userInfo = useSelector((state) => state.session.userInfo)
  const navigate = useNavigate()

  const { data: pagesOrPostsData, isFetching } = useGetPagesOrPostsQuery({
    type: selectedTab,
    site: site,
    pageNo: page + 1,
    year: selectedYear,
    filters: {
      ...CARD_FILTER_MAPPING[selectedCard],
    },
  })

  useEffect(() => {
    if (pagesOrPostsData) {
      setRows(
        pagesOrPostsData.pages
          ? pagesOrPostsData.pages
          : pagesOrPostsData.posts,
      )
      setRowCount(pagesOrPostsData.total)
    }
  }, [pagesOrPostsData])

  const navigateTheUserToEdit = (pageId, versionId) => {
    if (pageId && versionId) {
      return navigate(`/pages/layout/${versionId}`)
    }
  }

  const pageEditInit = (selectedPageForEdit) => {
    const { revisions = [], _id = '' } = selectedPageForEdit
    const latestVersionFound = revisions.slice(-1).pop()
    const { id = '' } = latestVersionFound
    return navigateTheUserToEdit(_id, id)
  }

  const getStatusInfo = (status, type = 'label') => {
    return STATUS_TEXT[status]
      ? STATUS_TEXT[status][type]
      : type === 'label'
      ? status
        ? status.replace('_', ' ')
        : 'unknown'
      : 'primary'
  }

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
      }}
    >
      <Box>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => {
            setSelectedTab(newValue)
            setRows([])
            setRowCount(0)
            setPage(0)
          }}
          textColor="inherit"
          variant="standard"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#CC0000',
            },
            '& .MuiTab-root': {
              fontSize: '1.1rem',
            },
            ml: 3,
          }}
        >
          <Tab
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
            label={<span>Pages</span>}
            value="page"
          />
          <Tab
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
            label={<span>Posts</span>}
            value="post"
          />
        </Tabs>
      </Box>
      <Stack flex={1} px={2}>
        <DataGrid
          autoHeight={true}
          getRowId={(row) => row._id}
          rows={isFetching ? [] : rows}
          columns={
            selectedTab === TABS[0]
              ? [
                  {
                    field: 'name',
                    headerName: 'Page Name',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                      <Typography
                        sx={{
                          fontSize: 14,
                          wordWrap: 'break-word',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                  {
                    field: 'slug',
                    headerName: 'URL',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                      <Typography
                        sx={{
                          fontSize: 14,
                          wordWrap: 'break-word',
                        }}
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                  {
                    field: 'modified_by',
                    valueGetter: ({ row }) => row.updated_by.firstName,
                    headerName: 'Modified by',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                      <Typography
                        sx={{
                          fontSize: 14,
                          wordWrap: 'break-word',
                        }}
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                  {
                    field: 'status',
                    valueGetter: ({ row }) =>
                      row.revisions.at(-1).status.status,
                    headerName: 'Status',
                    width: 200,
                    sortable: false,
                    renderCell: (params) =>
                      selectedCard === 'Published' ? (
                        <Box
                          sx={(theme) => ({
                            color:
                              theme.palette[getStatusInfo('published', 'color')]
                                .main,
                            fontWeight: 'bold',
                          })}
                        >
                          <Button
                            sx={(theme) => ({
                              backgroundColor: `${getStatusInfo(
                                'published',
                                'bgcolor',
                              )}`,
                              textTransform: 'none',
                              color:
                                theme.palette[
                                  getStatusInfo('published', 'color')
                                ].main,
                              fontWeight: 'bold',
                              display: 'flex',
                              minWidth: 100,
                              height: 35,
                            })}
                          >
                            {getStatusInfo('published')}
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={(theme) => ({
                            color:
                              theme.palette[
                                getStatusInfo(params.value, 'color')
                              ].main,
                            fontWeight: 'bold',
                          })}
                        >
                          <Button
                            sx={(theme) => ({
                              backgroundColor: `${getStatusInfo(
                                params.value,
                                'bgcolor',
                              )}`,
                              textTransform: 'none',
                              color:
                                theme.palette[
                                  getStatusInfo(params.value, 'color')
                                ].main,
                              fontWeight: 'bold',
                              display: 'flex',
                              minWidth: 100,
                              height: 35,
                            })}
                          >
                            {getStatusInfo(params.value)}
                          </Button>
                        </Box>
                      ),
                  },
                  {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Actions',
                    width: 100,
                    getActions: (params) => {
                      const latestVersion = getLatestVersionFromPage(params.row)
                      const versionAssignedToMe = versionAssignedToThisUser(
                        latestVersion,
                        userInfo.lanId,
                      )
                      const statusOfVersion =
                        getStatusOfThePageVersion(latestVersion)
                      const editableVersion =
                        currentVersionEditable(statusOfVersion)
                      return [
                        <GridActionsCellItem
                          icon={<Visibility />}
                          label="Edit"
                          className="textPrimary"
                          disabled={
                            (!actionsAllowed.userCanEditPage &&
                              !actionsAllowed.userCanReviewPage) ||
                            (versionAssignedToMe && editableVersion)
                          }
                          onClick={() => {
                            pageEditInit(params.row)
                          }}
                          color="secondary"
                        />,
                      ]
                    },
                  },
                ]
              : [
                  {
                    field: 'title',
                    headerName: 'Post Title',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                      <Typography
                        sx={{
                          fontSize: 14,
                          wordWrap: 'break-word',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                  {
                    field: 'slug',
                    headerName: 'URL',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                      <Typography
                        sx={{
                          fontSize: 14,
                          wordWrap: 'break-word',
                        }}
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                  {
                    field: 'modified_by',
                    valueGetter: ({ row }) => row.updated_by.firstName,
                    headerName: 'Modified by',
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                      <Typography
                        sx={{
                          fontSize: 14,
                          wordWrap: 'break-word',
                        }}
                      >
                        {params.value}
                      </Typography>
                    ),
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    width: 200,
                    sortable: false,
                    renderCell: (params) => (
                      <Box
                        sx={(theme) => ({
                          color:
                            theme.palette[
                              getStatusInfo(params.value.status, 'color')
                            ].main,
                          fontWeight: 'bold',
                        })}
                      >
                        <Button
                          sx={(theme) => ({
                            backgroundColor: `${getStatusInfo(
                              params.value.status,
                              'bgcolor',
                            )}`,
                            textTransform: 'none',
                            color:
                              theme.palette[
                                getStatusInfo(params.value.status, 'color')
                              ].main,
                            fontWeight: 'bold',
                            display: 'flex',
                            minWidth: 100,
                            height: 35,
                          })}
                        >
                          {getStatusInfo(params.value.status)}
                        </Button>
                      </Box>
                    ),
                  },
                  {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Actions',
                    width: 100,
                    getActions: (params) => {
                      return [
                        <GridActionsCellItem
                          icon={<Visibility />}
                          label="Edit"
                          className="textPrimary"
                          disabled={
                            !actionsAllowed.userCanEditPost &&
                            !actionsAllowed.userCanReviewPost
                          }
                          onClick={() => navigate(`/posts/design/${params.id}`)}
                          color="secondary"
                        />,
                      ]
                    },
                  },
                ]
          }
          disableColumnMenu
          disableSelectionOnClick
          rowCount={rowCount}
          pageSize={PAGE_SIZE}
          rowsPerPageOptions={[PAGE_SIZE]}
          paginationMode="server"
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          loading={isFetching}
          localeText={{ noRowsLabel: `No ${selectedTab}s found!` }}
          classes={{
            columnHeader: 'table-header',
            columnHeaderTitle: 'table-header-title',
          }}
          sx={{
            '& .table-header': {
              backgroundColor: '#EEEEEE',
              '.table-header-title': {
                fontWeight: 'bold',
                fontSize: '1rem',
              },
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
          }}
        />
      </Stack>
    </Stack>
  )
}

export default PagesAndPostsTable
