import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import apiConfig from '../apiConfig'

export const axiosMiddleware = (store) => (next) => (action) => {
  if (
    action.type.includes(`/focused`) ||
    action.type.includes(`profile/executeQuery/fulfilled`)
  ) {
    setInterceptors(store)
  }

  return next(action)
}

export const setInterceptors = (store) => {
  if (!store) {
    return
  }
  if (apiConfig.isPOL) {
    validateSSO()
  }
}

export const validateSSO = () => {
  const ssoCookie = Cookies.get('SSOSESSIONINFO')
  if (!ssoCookie) return false
  const token = jwt_decode(localStorage.getItem('id_token'))
  const sessionDetails = JSON.parse(window.atob(ssoCookie))
  const isDifferentUser =
    token.sub &&
    sessionDetails.user &&
    String(token.sub) !== String(sessionDetails.user)
  if (token.sub && sessionDetails.user && isDifferentUser) {
    localStorage.removeItem('id_token')
    window.location.reload()
  }
}

export const JSONStorage = {
  getItem: (key) => {
    try {
      return JSON.parse(window.atob(localStorage.getItem(key)))
    } catch (e) {
      console.log(localStorage.getItem(key))
      // eslint-disable-next-line
      console.warn(`Failed to parse value from localStorage for key: ${key}`)
      return null
    }
  },
  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  removeItem: (key) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  },
}
