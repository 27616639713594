import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Paper,
  createTheme,
  IconButton,
  GlobalStyles,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import DesktopMacIcon from '@mui/icons-material/DesktopMac'
import TabletIcon from '@mui/icons-material/Tablet'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
// import TextBlock from '../../../../globalComponents/TextBlock/TextBlock'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { deepmerge } from '@mui/utils'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { ComponentLoader } from 'enterprise-stencil'
import utilFunction from '../../../../utils/themeHelper'

const spoofedBreakpoints = {
  xs: { xs: 0, sm: 10000, md: 10001, lg: 10002, xl: 10003 },
  sm: { xs: 0, sm: 1, md: 10001, lg: 10002, xl: 10003 },
  md: { xs: 0, sm: 1, md: 2, lg: 10002, xl: 10003 },
  lg: { xs: 0, sm: 1, md: 2, lg: 3, xl: 10003 },
  xl: { xs: 0, sm: 1, md: 2, lg: 3, xl: 4 },
}

const Preview = (props) => {
  const {
    width,
    component,
    hideControls,
    defaultVal = false,
    defaultZoomVal = 100,
  } = props
  const [preview, setPreview] = useState(defaultVal)
  const [zoomVal, setZoom] = useState(defaultZoomVal)
  const themeOptions = {
    palette: {
      primary: {
        main: '#0A58CA',
      },
      secondary: {
        main: '#e53935',
      },
    },
  }

  useEffect(() => {
    setPreview(defaultVal)
  }, [defaultVal])

  const scale = (flag) => {
    let newVal = zoomVal
    if (flag) {
      newVal = zoomVal !== 100 ? zoomVal + 10 : zoomVal
    } else {
      newVal = zoomVal !== 0 ? zoomVal - 10 : zoomVal
    }
    setZoom(newVal)
  }

  const [theme, setTheme] = useState(createTheme(themeOptions))
  const onChangeDevice = (val) => {
    setPreview(val)
  }

  useEffect(() => {
    setTheme(
      preview
        ? createTheme(
            deepmerge(
              { breakpoints: { values: spoofedBreakpoints[preview] } },
              themeOptions,
            ),
          )
        : createTheme(deepmerge(themeOptions)),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview])

  const variantStyles = {
    // h3 & h4 font sizes
    '& h2.h3-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontxs': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& h3.h4-fontxs': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    // button text
    '& button.button-textxl': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Mobile').sizeVal,
      },
    },
    '& button.button-textl': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'L', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'L', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'L', 'Mobile').sizeVal,
      },
    },
    '& button.button-textm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('button', 'M', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('button', 'M', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'M', 'Mobile').sizeVal,
      },
      // [theme.breakpoints.up("lg")]: {
      //   fontSize: `${utilFunction("button", "M", "Desktop", true).sizeVal}vw`,
      //   lineHeight:
      //     utilFunction("button", "M", "Desktop", true).sizeVal +
      //     0.11142858 +
      //     "vw",
      // },
      // [theme.breakpoints.down("lg")]: {
      //   fontSize: `${utilFunction("button", "M", "Tablet", true).sizeVal}vw`,
      //   lineHeight:
      //     utilFunction("button", "M", "Tablet", true).sizeVal +
      //     0.260714285 +
      //     "vw",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   fontSize: `${utilFunction("button", "M", "Mobile", true).sizeVal}vw`,
      //   lineHeight:
      //     utilFunction("button", "M", "Mobile", true).sizeVal +
      //     0.472222221 +
      //     "vw",
      // },
    },
    '& button.button-texts': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'S', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'S', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'S', 'Mobile').sizeVal,
      },
    },
    '& button.button-textxs': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Mobile').sizeVal,
      },
    },

    //button specific tab component
    '& .preview .MuiTabs-flexContainer button.Mui-selected': {
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .preview .MuiTabs-flexContainer button': {
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .preview .MuiTabs-flexContainer a': {
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },

    //button sizes
    '& button.button-size': {
      // minWidth: 204,
      // minHeight: 52,
      // [theme.breakpoints.up("lg")]: {
      //   minWidth: "11.36571432vw",
      //   minHeight: "2.897142865vw",
      // },
      // [theme.breakpoints.down("lg")]: {
      //   minWidth: "26.59285714vw",
      //   minHeight: "5.778571428vw",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   minWidth: "48.166666664vw",
      //   minHeight: "12.27777778vw",
      // },
    },

    // body1 fontsizes
    '& .body1-fontxl p,.body1-fontxl li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& .body1-fontl p, .body1-fontl li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& .body1-fontm p,.body1-fontm li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& .body1-fonts p,.body1-fonts li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& .body1-fontxs p,.body1-fontxs li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    // anchor tags
    '& a': {
      color: 'black',
      fontWeight: 'bold',
      fontSize: '20px',
      textDecorationColor: 'black !important',
    },

    //svg icons
    '& .button-textm span svg': {
      width: '2.5rem',
      height: '2.5rem',
    },

    // numbered lists

    '& .body1-general ol': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      marginLeft: '35px',
    },

    '& .body1-general ol li': {
      counterIncrement: 'step-counter',
      marginBottom: '30px',
      position: 'relative',
    },

    '& .body1-general ol li::before': {
      content: 'counter(step-counter)',
      marginRight: '10px',
      backgroundColor: '#000',
      color: 'white',
      padding: '4px 10px',
      borderRadius: '50%',
      position: 'absolute',
      marginLeft: '-40px',
    },

    // svg arrows
    '& .slider-control-centerleft': {
      [theme.breakpoints.up('md')]: {
        left: '8% !important',
        display: 'block',
      },
      button: {
        color: 'black',
      },
      display: 'none',
    },

    '& .slider-control-centerright': {
      [theme.breakpoints.up('md')]: {
        right: '8% !important',
        display: 'block',
      },
      button: {
        color: 'black',
      },
      display: 'none',
    },

    '& .article-body': {
      fontSize: 18,
      '& h3': {
        fontWeight: 'normal',
        [theme.breakpoints.up('sm')]: {
          fontSize: utilFunction('h3', 'L', 'Desktop').sizeVal,
          lineHeight: utilFunction('h3', 'L', 'Desktop').sizeVal + 2 + 'px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: utilFunction('h3', 'L', 'Tablet').sizeVal,
          lineHeight: utilFunction('h3', 'L', 'Tablet').sizeVal + 2 + 'px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: utilFunction('h3', 'L', 'Mobile').sizeVal,
          lineHeight: utilFunction('h3', 'XL', 'Mobile').sizeVal + 2 + 'px',
        },
      },
    },

    '& .article-body p': {
      fontSize: 18,
      fontWeight: 'normal',
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& .article-body .MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },

    // accordion article
    '& .article-body .MuiAccordionSummary-content p.accordion-list': {
      marginRight: '10px',
      backgroundColor: '#000',
      color: 'white',
      padding: '4px 10px',
      borderRadius: '50%',
      position: 'absolute',
      marginLeft: '-40px',
    },

    '& .article-body .MuiAccordionDetails-root .body1-general p': {
      marginTop: '0',
      marginBottom: '0',
    },

    '& .slider-container .slide .MuiGrid-item': {
      opacity: '0 !important',
    },
    '& .slider-container .slide-visible': {
      opacity: '1 !important',
    },
    '& .slider-container .slide-visible .MuiGrid-item': {
      opacity: '1 !important',
    },

    //carousel
    '& .slider-container .slider-control-bottomcenter .pagingDots': {
      // top: "0px !important",
      [theme.breakpoints.up('sm')]: {
        svg: {
          width: '13px',
          height: '13px',
          circle: {
            cx: 6,
            cy: 6,
            r: 6,
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        top: '20px !important',
        svg: {
          width: '13px',
          height: '13px',
          circle: {
            cx: 4,
            cy: 4,
            r: 4,
          },
        },
      },
    },
    '& .slider-container .slider-list': {
      [theme.breakpoints.up('md')]: {
        marginBottom: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '30px',
      },
    },
    '& .stats-li li': {
      listStyle: 'none',
    },
    '& .stats-li ul': {
      paddingLeft: 0,
    },
    '& .visibility-hidden': {
      position: 'absolute',
      clip: 'rect(1px, 1px, 1px, 1px)',
      clipPath: 'inset(0px 0px 99.9% 99.9%)',
      overflow: 'hidden',
      height: 1,
      width: 1,
      padding: 0,
      border: 0,
    },
  }

  return (
    <div>
      {!hideControls && (
        <Box
          sx={{
            minHeight: 'calc(50% - 64px)',
            overflow: 'auto',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              position: 'relative',
            }}
          >
            <ToggleButtonGroup
              value={preview}
              exclusive
              aria-label="text alignment"
              size={'small'}
            >
              <ToggleButton
                onClick={(_, val) => onChangeDevice(val)}
                value={false}
                aria-label="Desktop preview"
              >
                <DesktopMacIcon />
              </ToggleButton>
              <ToggleButton
                onClick={(_, val) => onChangeDevice(val)}
                value={'sm'}
                aria-label="Tablet preview"
              >
                <TabletIcon />
              </ToggleButton>
              <ToggleButton
                onClick={(_, val) => onChangeDevice(val)}
                value={'xs'}
                aria-label="Mobile preview"
              >
                <PhoneAndroidIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {!preview && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="caption">
                  <strong>Zoom:</strong> {zoomVal}%
                </Typography>
                <IconButton
                  disabled={zoomVal === 100}
                  onClick={() => scale(true)}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  disabled={zoomVal === 10}
                  onClick={() => scale(false)}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      )}
      <Box
        sx={{
          minHeight: 'calc(50% - 64px)',
          bgcolor: '#757575',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyles styles={variantStyles} />
          <Box
            sx={{
              p: hideControls ? 0 : 1,
              overflowX: hideControls ? 'hidden' : 'auto',
            }}
            className="article-body"
          >
            <Paper
              elevation={0}
              square
              sx={{
                p: 1,
                backgroundColor: 'default',
                width: {
                  xs: (375 / 100) * width,
                  sm: (768 / 100) * width,
                  md: (1000 / 100) * width,
                  lg: `${width}%`,
                },
                zoom: {
                  xs: '100%',
                  lg: `${zoomVal}%`,
                },
                overflow: 'hidden',
              }}
            >
              <ComponentLoader {...component} preview={true} />
            </Paper>
          </Box>
        </ThemeProvider>
      </Box>
    </div>
  )
}

export default Preview
