import React from 'react'
import { Box } from '@mui/material'
import ReactQuill from 'react-quill'
import Label from './Common/Label'

export const RichText = (props) => {
  const { name = 'Text', value, onChange, disabled = false } = props
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box
        sx={{
          display: 'flex',
          width: '70%',
          justifyContent: 'left',
          color: '#c4c4c4',
          border: '1px solid',
          borderRadius: 1,
          '& .quill': {
            width: '100%',
          },
        }}
      >
        <ReactQuill
          value={value ? value : ''}
          onChange={(newValue, _, source) => {
            if (source === 'user') {
              onChange(newValue)
            }
          }}
          theme="bubble"
          modules={{
            toolbar: [
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'link',
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
              { script: 'super' },
              { script: 'sub' },
            ],
          }}
          formats={[
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'script',
          ]}
          readOnly={disabled}
        />
      </Box>
    </Box>
  )
}

export default RichText
