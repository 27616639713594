import {
  Alert,
  Box,
  Button,
  LinearProgress,
  SwipeableDrawer,
} from '@mui/material'
import React, { useEffect } from 'react'
import { ModuleHead, NoRecords } from '../Common'
import { NOSPEAKERS } from '../../../../assets/Image'
import { ImportParticipant } from './ImportParticipant'
import {
  useGetParticipantsCountOfEventQuery,
  useGetParticipantsOfEventQuery,
  useRemoveParticipantMutation,
} from '../../../../api/participant'
import { useSelector } from 'react-redux'
import { ListParticipants } from './ListParticipants'
import { Dialog } from '@enterprise-ui/canvas-ui-react'
import { useSnackbar } from 'notistack'
import { AdvancedFilters } from './AdvancedFilters'
import { INITIAL_FILTER_VALUES } from './Utils'
import { useNavigate } from 'react-router-dom'
import { Loader, withRights } from '../../../../components'
import AccessDenied from '../../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../../utils/rightUtils'
import { useDebounce } from '@uidotdev/usehooks'
import { getExperienceLabel } from '../Utils/Helper'

export const Participants = (props) => {
  const {
    event = {},
    editDisabled = false,
    rights = {},
    rightsLoading,
    noSiteAccess = false,
  } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const { id = null } = event
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [openImportOption, setOpenImportOption] = React.useState(false)
  const [advancedFilterOpen, setAdvancedFilterOpen] = React.useState(false)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [filters, setFilters] = React.useState(INITIAL_FILTER_VALUES)
  const [listType, setListType] = React.useState('list')
  const [participants, setParticipants] = React.useState([])
  const [participantsCount, setParticipantsCount] = React.useState([])
  const [deleteInitiated, initiateDelete] = React.useState(false)
  const [selectedParticipants, setSelectedParticipants] = React.useState([])
  const [flagForAllSelection, setFlagForAllSelection] = React.useState(false)
  const [allSelected, setAllSelected] = React.useState(false)
  const [enableDownload, setEnableDownload] = React.useState(false)

  const actionsAllowed = {
    userCanCreateParticipant: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
    userCanEditParticipant: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
    ),
    userCanDeleteParticipant: checkUserIsAllowed(
      'delete',
      permittedActions,
      superRights,
    ),
    userCanReview: checkUserIsAllowed('review', permittedActions, superRights),
    userCanSendEmail: checkUserIsAllowed(
      'email',
      permittedActions,
      superRights,
    ),
    userCanDownload: checkUserIsAllowed(
      'download',
      permittedActions,
      superRights,
    ),
  }

  const debouncedSearchQuery = useDebounce(filters, 500)

  const { data: participantsData, isFetching: participantsLoading } =
    useGetParticipantsOfEventQuery(
      { filters: debouncedSearchQuery, eventid: id, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  const {
    data: participantsDataForDownload,
    isFetching: participantsDownloadLoading,
  } = useGetParticipantsOfEventQuery(
    { filters, eventid: id, activeSite, download: true },
    { skip: !activeSite || !enableDownload, refetchOnMountOrArgChange: true },
  )

  const { data: participantsCountData, isLoading: countLoading } =
    useGetParticipantsCountOfEventQuery(
      { filters: debouncedSearchQuery, eventid: id, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  const [
    removeParticipants,
    { isSuccess: removeSuccess, isError: removeError },
  ] = useRemoveParticipantMutation()

  useEffect(() => {
    if (participantsDataForDownload && enableDownload) {
      setEnableDownload(false)
      exportToCSV()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsDataForDownload])

  const exportToCSV = () => {
    var csvRows = []
    const data = []

    if (participantsDataForDownload) {
      participantsDataForDownload.map((participant) => {
        const obj = {
          name: participant?.user?.name,
          company: participant?.user?.company,
          created_at: participant?.user?.createdAt,
          email: participant?.user?.email,
          gender: participant?.user?.gender,
          imported: participant?.user?.imported ? 'Yes' : 'No',
          mobile: participant?.user?.mobile,
          status: participant?.status,
          attended: participant?.attended ? 'Yes' : 'No',
          experience: `Exp ${getExperienceLabel({
            ...participant?.meta?.exp,
            experience: participant?.meta?.experience,
          })}`,
          designation: participant?.meta?.designation
            ? participant?.meta?.designation
            : '',
          areas_of_interest: participant?.meta?.areas_of_interest
            ? participant?.meta?.areas_of_interest
            : '',
          channel: participant?.meta?.advertisement_channel
            ? participant?.meta?.advertisement_channel
            : '',
          city: participant?.meta?.city ? participant?.meta?.city : '',
          state: participant?.meta?.state ? participant?.meta?.state : '',
          linkedin_profile_url: participant?.meta?.linkedin_profile_url
            ? participant?.meta?.linkedin_profile_url
            : '',
          auto_approved: participant?.auto_approved ? 'Yes' : 'No',
          comments: participant?.comments ? participant?.comments : '',
        }
        data.push(obj)
        return 0
      })
      const headers = Object.keys(data[0])
      csvRows.push(headers.join(','))
      for (const row of data) {
        const values = headers.map((header) => {
          const val = row[header]
          return `"${val}"`
        })
        csvRows.push(values.join(','))
      }
      csvRows = csvRows.join('\n')

      const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' })
      const a = document.createElement('a')
      a.download = 'participants.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  useEffect(() => {
    if (removeSuccess) {
      enqueueSnackbar('Participants removed from event successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  }, [enqueueSnackbar, removeSuccess])

  useEffect(() => {
    if (removeError) {
      enqueueSnackbar('Unable to remove !. Please try again', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  }, [enqueueSnackbar, removeError])

  useEffect(() => {
    if (participantsData?.data && participantsData?.data) {
      setParticipants(participantsData.data)
    }
  }, [participantsData])

  useEffect(() => {
    if (filters?.status && filters?.status.length === 0) {
      setAllSelected(false)
      setFlagForAllSelection(false)
    }
  }, [filters])

  useEffect(() => {
    if (participantsCountData && participantsCountData.length > 0) {
      const updated = {}
      participantsCountData.map((countData) => {
        updated[countData.status.toLowerCase()] = countData?.count
        return 0
      })
      setParticipantsCount(updated)
    }
  }, [participantsCountData])

  const createNewParticipant = () => {
    navigate(`/events/participants/create/${id}`)
  }

  const openDrawerForImport = () => {
    setOpenImportOption(true)
  }

  const closeDrawerForImport = () => {
    setOpenImportOption(false)
  }

  const confirmDelete = () => {
    removeParticipants({
      participantIds: [...selectedParticipants],
      eventid: id,
      activeSite,
    })
    initiateDelete(false)
  }

  const cancelDelete = () => {
    initiateDelete(false)
  }

  const actionButtons = [
    {
      label: 'Import Participants',
      variant: 'outlined',
      cb: openDrawerForImport,
      disabled: !actionsAllowed?.userCanEditParticipant,
    },
    {
      label: 'Create a New Participant',
      cb: createNewParticipant,
      disabled: !actionsAllowed?.userCanCreateParticipant,
    },
  ]
  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess ? (
    <Box data-testid="participant-wrapper">
      <ModuleHead
        search={filters?.search}
        setSearch={(val) => setFilters({ ...filters, search: val })}
        listType={listType}
        setListType={setListType}
        actionButtons={actionButtons}
        onDelete={() => {
          initiateDelete(true)
        }}
        disableDelete={
          selectedParticipants.length === 0 ||
          !actionsAllowed?.userCanDeleteParticipant
        }
        allActionsDisabled={editDisabled}
        hideViewBtns={true}
      />
      {allSelected && (
        <Alert sx={{ mt: 2, alignItems: 'center' }} severity="info">
          <>
            Currenly there are {participantsData?.total} participants selected
            <Button
              sx={{
                backgroundColor: '#CC0000',
                textTransform: 'none',
                fontSize: '16px',
                color: '#fff',
                '&:hover': {
                  background: '#CC0000',
                },
                mr: 3,
                ml: 3,
                lineHeight: 1.5,
              }}
              color="inherit"
              size="small"
              variant="contained"
              onClick={() => {
                setAllSelected(false)
                setFlagForAllSelection(false)
              }}
            >
              Clear selection
            </Button>
          </>
        </Alert>
      )}
      {flagForAllSelection && participantsData?.total > 1 && !allSelected && (
        <Alert sx={{ mt: 2, alignItems: 'center' }} severity="info">
          <>
            There are <strong>{participantsData?.total} participants</strong>{' '}
            available for the criteria you have selected.{' '}
            <strong>
              Do you want to select all {participantsData?.total} participants?
            </strong>
            <>
              <Button
                sx={{
                  backgroundColor: '#CC0000',
                  textTransform: 'none',
                  fontSize: '16px',
                  color: '#fff',
                  '&:hover': {
                    background: '#CC0000',
                  },
                  mr: 3,
                  ml: 3,
                  lineHeight: 1.5,
                }}
                aria-label="yes select"
                color="inherit"
                size="small"
                variant="contained"
                onClick={() => {
                  setAllSelected(true)
                  setFlagForAllSelection(false)
                }}
              >
                Yes, select
              </Button>
              <Button
                aria-label="close"
                color="inherit"
                size="small"
                sx={{
                  textTransform: 'none',
                  color: '#CC0000',
                  border: '1px solid #CC0000',
                  fontSize: '16px',
                  '&:hover': {
                    border: '1px solid #CC0000',
                  },
                  mr: 3,
                  lineHeight: 1.5,
                }}
                onClick={() => {
                  setFlagForAllSelection(false)
                }}
              >
                Cancel
              </Button>
            </>
          </>
        </Alert>
      )}
      {participantsLoading || countLoading ? (
        <>
          <LinearProgress sx={{ mt: 1, ml: 1, mr: 1 }} />
        </>
      ) : (
        <Box>
          {participants &&
          participants.length === 0 &&
          filters?.status?.length === 0 ? (
            <NoRecords
              btns={[
                { label: 'Import Participants', cb: openDrawerForImport },
                { label: 'Create a New Participant', cb: createNewParticipant },
              ]}
              img={NOSPEAKERS}
            ></NoRecords>
          ) : (
            <ListParticipants
              participants={participants}
              participantsCount={participantsCount}
              participantsCountLoading={countLoading}
              filters={filters}
              setFilters={setFilters}
              loading={participantsLoading}
              participantsTotalCount={participantsData?.total}
              deleteInitiated={deleteInitiated}
              initiateDelete={initiateDelete}
              selectedParticipants={selectedParticipants}
              setSelectedParticipants={setSelectedParticipants}
              onClickFilter={() => setAdvancedFilterOpen(true)}
              eventid={id}
              editDisabled={editDisabled}
              flagForAllSelection={flagForAllSelection}
              setFlagForAllSelection={setFlagForAllSelection}
              allSelected={allSelected}
              exportToCSV={() => setEnableDownload(true)}
              downloadableDataIsLoading={participantsDownloadLoading}
              actionsAllowed={actionsAllowed}
            />
          )}
        </Box>
      )}
      <SwipeableDrawer
        anchor={'right'}
        open={openImportOption}
        onClose={() => closeDrawerForImport()}
        onOpen={() => closeDrawerForImport()}
        PaperProps={{
          sx: {
            width: '25%',
          },
        }}
      >
        <ImportParticipant
          onClose={() => closeDrawerForImport()}
          eventid={id}
        />
      </SwipeableDrawer>
      {deleteInitiated && (
        <Dialog
          headingText={`Are you sure you want to remove ${
            selectedParticipants.length
          } participant${
            selectedParticipants.length === 1 ? '' : 's'
          } from this event?`}
          approveButtonText="Yes"
          onApprove={confirmDelete}
          isVisible
          onRefuse={() => {
            cancelDelete()
          }}
          refuseButtonText="No thanks"
        />
      )}
      <AdvancedFilters
        open={advancedFilterOpen}
        setOpen={setAdvancedFilterOpen}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  ) : (
    <AccessDenied module="Participants" />
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Read',
      access: 'Read',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Create',
      access: 'Create',
    },
    {
      slug: 'Delete',
      access: 'Delete',
    },
    {
      slug: 'Email',
      access: 'Email',
    },
    {
      slug: 'Download',
      access: 'Download',
    },
    {
      slug: 'Review',
      access: 'Review',
    },
  ],
}

export default withRights(Participants, moduleAttr)
