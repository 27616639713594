import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiConfig from '../apiConfig'

const { api } = apiConfig

export const eventApi = createApi({
  reducerPath: 'event',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.host}events`,
    prepareHeaders: (headers, { getState }) => {
      const identityToken = getState().session.identityToken
      const lanId = getState().session.userInfo.lanId
      const bearer = getState().session.userInfo.accessToken
      // If we have a token set in state, let's assume that we should be passing it.
      if (bearer && lanId) {
        headers.set('lanid', lanId)
        headers.set('authorization', bearer)
        headers.set('x-api-key', api.apiKey)
        headers.set('x-id-token', identityToken)
      }
      return headers
    },
  }),
  tagTypes: ['Events'],
  endpoints: (builder) => ({
    newEvent: builder.mutation({
      query: ({ event, activeSite }) => ({
        url: `/save`,
        method: 'POST',
        body: {
          ...event,
          site: activeSite._id,
        },
      }),
      invalidatesTags: ['Events'],
    }),
    editEvent: builder.mutation({
      query: ({ event }) => ({
        url: `/save/${event.id}`,
        method: 'POST',
        body: {
          ...event,
        },
      }),
      invalidatesTags: ['Events', 'Event'],
    }),
    archiveEvent: builder.mutation({
      query: (event) => ({
        url: `/save/${event.id}`,
        method: 'POST',
        body: {
          ...event,
        },
      }),
      invalidatesTags: ['Events'],
    }),
    getEvent: builder.query({
      query: (eventId) => {
        return {
          url: `/event/${eventId}`,
          method: 'GET',
        }
      },
      providesTags: ['Event'],
    }),
    getAllEvents: builder.query({
      query: (activeSite) => ({
        url: `/list/${activeSite?._id}`,
        method: 'GET',
      }),
      providesTags: ['Events'],
    }),
    getAllSpeakers: builder.query({
      query: ({ filters, activeSite }) => ({
        url: `/speaker/list`,
        method: 'GET',
        params: {
          ...filters,
          siteid: activeSite._id,
        },
      }),
      providesTags: ['Speakers'],
    }),
    newSpeaker: builder.mutation({
      query: ({ speakerdata, eventid, activeSite }) => ({
        url: `/${eventid}/speaker/create`,
        method: 'POST',
        body: {
          ...speakerdata,
          site: activeSite._id,
        },
      }),
      invalidatesTags: ['Speakers'],
    }),
    addSpeaker: builder.mutation({
      query: ({ ids, eventid, activeSite }) => ({
        url: `/${eventid}/speaker/add`,
        method: 'POST',
        body: {
          ids,
          site: activeSite._id,
        },
      }),
      invalidatesTags: ['Speakers'],
    }),
    listSpeakerByEvent: builder.query({
      query: ({ search, eventid, activeSite, pageVal, perpage }) => ({
        url: `speaker/list`,
        method: 'GET',
        params: {
          search,
          eventid: eventid,
          siteid: activeSite._id,
          page: pageVal,
          perpage: perpage,
        },
      }),
      providesTags: ['Speakers'],
    }),
    getSpeakerById: builder.query({
      query: (speakerId) => {
        return {
          url: `/speaker/${speakerId}`,
          method: 'GET',
        }
      },
      invalidatesTags: ['Speakers'],
    }),
    updateSpeaker: builder.mutation({
      query: ({ speaker, speakerId, activeSite }) => ({
        url: `/speaker/update/${speakerId}`,
        method: 'POST',
        body: {
          ...speaker,
          site: activeSite._id,
        },
      }),
      invalidatesTags: ['Speakers'],
    }),
    removeSpeakers: builder.mutation({
      query: ({ ids, eventid, activeSite }) => ({
        url: `/${eventid}/speaker/remove`,
        method: 'POST',
        body: {
          ids,
          site: activeSite._id,
        },
      }),
      invalidatesTags: ['Speakers'],
    }),
    pushNotification: builder.mutation({
      query: ({ eventid, reqBody }) => ({
        url: `/${eventid}/participants/sendmail`,
        method: 'POST',
        body: {
          ...reqBody,
        },
      }),
      invalidatesTags: ['Events'],
    }),
  }),
})

export const {
  useNewEventMutation,
  useGetEventQuery,
  useGetAllEventsQuery,
  useEditEventMutation,
  useArchiveEventMutation,
  useGetAllSpeakersQuery,
  useNewSpeakerMutation,
  useAddSpeakerMutation,
  useListSpeakerByEventQuery,
  useUpdateSpeakerMutation,
  useGetSpeakerByIdQuery,
  useRemoveSpeakersMutation,
  usePushNotificationMutation,
} = eventApi
