import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material'

export default function EditTaxonomy(props) {
  const { open, handleClose, save, data, title } = props
  const [localData, setLocalData] = useState({})

  useEffect(() => {
    if (open) {
      setLocalData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onChangeVal = (key, val) => {
    setLocalData({
      ...localData,
      [key]: val,
    })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label={'Name'}
          type="text"
          fullWidth
          variant="standard"
          value={localData.name}
          onChange={(e) => onChangeVal('name', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => save(localData)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}
