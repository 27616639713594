import React, { Fragment } from 'react'
import { Alert, Card, Chip, Tabs, Tab, Box, Tooltip } from '@mui/material'
// import LoadComponentFields from './EditComponent/LoadComponentFields'
import LoadComponentFieldsDynamic from './EditComponent/LoadComponentFieldsDynamic'
import { DesktopMac, PhoneAndroid, Tablet } from '@mui/icons-material'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `post-tabpanel-${index}`,
    'aria-controls': `post-tabpanel-${index}`,
  }
}

export default function RightArea(props) {
  const { layout, selectedComponent, setLayout, postActions = {} } = props
  const { postChangesAllowed = false } = postActions
  const [value, setValue] = React.useState(0)

  const updateAttributes = (attributes) => {
    const newComponentData = {
      ...layout[selectedComponent.row].components[selectedComponent.col],
      attributes,
    }

    const newSectionData = {
      ...layout[selectedComponent.row],
      components: [
        ...layout[selectedComponent.row].components.slice(
          0,
          selectedComponent.col,
        ),
        newComponentData,
        ...layout[selectedComponent.row].components.slice(
          selectedComponent.col + 1,
        ),
      ],
    }

    const newLayout = [
      ...layout.slice(0, selectedComponent.row),
      newSectionData,
      ...layout.slice(selectedComponent.row + 1),
    ]

    setLayout(newLayout)
  }

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Card
        sx={{
          height: 'calc(100vh - 64px)',
          borderRadius: 0,
          p: 0,
          overflow: 'scroll',
          borderLeft: '1px solid #d3d3d3',
        }}
        elevation={1}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Post Controls"
              sx={{
                '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
                  background: '#CC0000',
                  color: '#FFF',
                },
              }}
              TabIndicatorProps={{ style: { backgroundColor: '#FFF' } }}
            >
              <Tooltip title="Desktop & Common Settings" arrow>
                <Tab
                  sx={{
                    textTransform: 'none',
                    minWidth: 140,
                    border: '1px solid #F0F0F0',
                  }}
                  icon={<DesktopMac fontSize="small" />}
                  {...a11yProps(0)}
                />
              </Tooltip>
              <Tooltip
                title="Tablet Settings: All settings here would apply to both landscape and portrait modes"
                arrow
              >
                <Tab
                  sx={{
                    textTransform: 'none',
                    border: '1px solid #F0F0F0',
                    minWidth: 140,
                  }}
                  icon={<Tablet fontSize="small" />}
                  {...a11yProps(1)}
                />
              </Tooltip>
              <Tooltip title="Mobile Settings" arrow>
                <Tab
                  sx={{
                    textTransform: 'none',
                    minWidth: 140,
                    border: '1px solid #F0F0F0',
                  }}
                  icon={<PhoneAndroid fontSize="small" />}
                  {...a11yProps(2)}
                />
              </Tooltip>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {selectedComponent ? (
              <>
                <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <strong>
                    {
                      layout[selectedComponent.row].components[
                        selectedComponent.col
                      ].name
                    }
                  </strong>{' '}
                  selected in
                  <Chip
                    variant="contained"
                    size={'small'}
                    color={'primary'}
                    sx={{ m: 1, borderRadius: 0 }}
                    label={`Section #${selectedComponent.row + 1}`}
                  />
                  <Chip
                    variant="contained"
                    size={'small'}
                    color={'primary'}
                    sx={{ borderRadius: 0 }}
                    label={`Column #${selectedComponent.col + 1}`}
                  />
                </Box>
                {/* TODO Dynamic field loader*/}
                <LoadComponentFieldsDynamic
                  componentAttr={
                    layout[selectedComponent.row].components[
                      selectedComponent.col
                    ]
                  }
                  setComponentAttr={updateAttributes}
                  editAllowed={postChangesAllowed}
                  device={'desktop'}
                />
              </>
            ) : (
              <Alert severity="info">Select a component to customize</Alert>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {selectedComponent ? (
              <>
                <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <strong>
                    {
                      layout[selectedComponent.row].components[
                        selectedComponent.col
                      ].name
                    }
                  </strong>{' '}
                  selected in
                  <Chip
                    variant="contained"
                    size={'small'}
                    color={'primary'}
                    sx={{ m: 1, borderRadius: 0 }}
                    label={`Section #${selectedComponent.row + 1}`}
                  />
                  <Chip
                    variant="contained"
                    size={'small'}
                    color={'primary'}
                    sx={{ borderRadius: 0 }}
                    label={`Column #${selectedComponent.col + 1}`}
                  />
                </Box>
                <LoadComponentFieldsDynamic
                  componentAttr={
                    layout[selectedComponent.row]?.components[
                      selectedComponent.col
                    ]
                  }
                  setComponentAttr={updateAttributes}
                  editAllowed={postChangesAllowed}
                  device={'tablet'}
                />
              </>
            ) : (
              <Alert severity="info">Select a component to customize</Alert>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {selectedComponent ? (
              <>
                <Box sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  <strong>
                    {
                      layout[selectedComponent.row].components[
                        selectedComponent.col
                      ].name
                    }
                  </strong>{' '}
                  selected in
                  <Chip
                    variant="contained"
                    size={'small'}
                    color={'primary'}
                    sx={{ m: 1, borderRadius: 0 }}
                    label={`Section #${selectedComponent.row + 1}`}
                  />
                  <Chip
                    variant="contained"
                    size={'small'}
                    color={'primary'}
                    sx={{ borderRadius: 0 }}
                    label={`Column #${selectedComponent.col + 1}`}
                  />
                </Box>
                <LoadComponentFieldsDynamic
                  componentAttr={
                    layout[selectedComponent.row].components[
                      selectedComponent.col
                    ]
                  }
                  setComponentAttr={updateAttributes}
                  editAllowed={postChangesAllowed}
                  device={'tablet'}
                />
              </>
            ) : (
              <Alert severity="info">Select a component to customize</Alert>
            )}
          </TabPanel>
        </Box>
      </Card>
    </>
  )
}
