import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Stack,
  Typography,
  Tooltip,
  CardActions,
  IconButton,
  FormGroup,
  FormControlLabel,
  Pagination,
  Button,
  CircularProgress,
  Divider,
} from '@mui/material'
import { InsertLink, ArrowBack } from '@mui/icons-material'
import React from 'react'
import GridList from '../../components/GridList'
import { getSize, expireDate } from '../../utils/helper'
import { BGFILE } from '../../assets/Image'
import MyCarousel from '../../components/MyCarousel'
import { useSelector } from 'react-redux'
import {
  useDeleteFilesPermentlyMutation,
  useListDeletedFilesQuery,
  useRestoreFilesMutation,
} from '../../api/mediaLib'
import DeleteDialog from './DeleteDialog'
import RestoreDialog from './RestoreDialog'
import noData from '../../assets/Image/no_data.gif'

const RecentlyDeleted = (props) => {
  const { BASEURL, callBack, openSnackbarWithMsg } = props
  const [checkedState, setCheckedState] = React.useState({})
  const [currnetPage, setCurrnetPage] = React.useState(1)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [previewOpen, setPreviewOpen] = React.useState(false)
  const [totalPage, setTotalPage] = React.useState(0)
  const [isDeleteAlertOpen, setDeleteAlertOpen] = React.useState(false)
  const [isRestoreAlertOpen, setRestoreAlertOpen] = React.useState(false)
  const { userInfo, profile } = useSelector((state) => state.session)

  const activeSite = useSelector((state) => state.tenant.activeSite)
  const siteId = useSelector((state) => state.tenant.activeSite?._id)
  const itemPerPage = 12

  function getDecimalPart(num) {
    if (Number.isInteger(num)) {
      return num
    }

    const decimalStr = num.toString().split('.')[0]
    return Number(decimalStr)
  }

  const {
    data: listOfDeletedFiles,
    isLoading: loading,
    refetch: reFetchFiles,
  } = useListDeletedFilesQuery(
    {
      currnetPage,
      siteId,
      created_by: profile._id,
    },
    { skip: !(userInfo.lanId && activeSite) },
  )

  const [restoreFiles] = useRestoreFilesMutation()

  const [deletePermently] = useDeleteFilesPermentlyMutation()

  const restoreFileAction = () => {
    const data = Object.keys(checkedState)
    const body = {
      data: data,
      site: siteId,
    }
    restoreFiles(body).then((res) => {
      res?.data?.status === 'restored' &&
        openSnackbarWithMsg(
          'Selected Files has been Restored Successfully',
          'success',
          '#303F9F',
        )
      reFetchFiles()
      setCheckedState({})
    })
  }

  const deletePermentlyAction = () => {
    const data = Object.keys(checkedState)
    const body = {
      data: data,
      site: siteId,
    }
    deletePermently(body).then((res) => {
      res?.data?.status === 'deleted' &&
        openSnackbarWithMsg(
          'Selected Files has been Deleted Permanently Successful',
          'success',
          '#303F9F',
        )
      reFetchFiles()
      setCheckedState({})
    })
  }

  React.useEffect(() => {
    reFetchFiles()
    if (listOfDeletedFiles && listOfDeletedFiles?.files?.length > 0) {
      if (listOfDeletedFiles && listOfDeletedFiles?.total > itemPerPage) {
        const temp =
          listOfDeletedFiles.total / itemPerPage <= 1
            ? 1
            : getDecimalPart(listOfDeletedFiles?.total / itemPerPage)
        const exactTotalPage =
          listOfDeletedFiles?.total % itemPerPage > 0 ? temp + 1 : temp
        setTotalPage(exactTotalPage)
      } else if (listOfDeletedFiles && listOfDeletedFiles?.total === 0) {
        setTotalPage(0)
      } else {
        setTotalPage(1)
      }
    } else if (listOfDeletedFiles?.total > 0) {
      const temp =
        listOfDeletedFiles.total / itemPerPage <= 1
          ? 1
          : getDecimalPart(listOfDeletedFiles?.total / itemPerPage)
      const exactTotalPage =
        listOfDeletedFiles?.total % itemPerPage > 0 ? temp + 1 : temp
      setCurrnetPage(exactTotalPage)
    } else {
      setTotalPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfDeletedFiles])

  const handleOnChange = (checked, value) => {
    const tempState = { ...checkedState }
    if (checked) {
      tempState[value._id] = value
    } else {
      delete tempState[value._id]
    }
    setCheckedState(tempState)
  }

  const handlePreview = (value) => {
    if (value.type.includes('image')) {
      setSelectedItem(value)
      setPreviewOpen(true)
    } else if (
      !(value.type.includes('audio') || value.type.includes('video'))
    ) {
      window.open(`${BASEURL}${value.path}`)
    }
  }

  const deselectAll = (e, value) => {
    setCheckedState({})
  }

  const handleMedia = (value) => {
    return value.type.split('/')[0] === 'image'
      ? `${BASEURL}${value && value.path}`
      : value.type.includes('audio')
      ? `${BASEURL}${value && value.path}`
      : value.type.includes('video')
      ? `${BASEURL}${value && value.path}`
      : value.type.includes('pdf')
      ? `${BASEURL}${value && value.path}`
      : BGFILE
  }

  const handlePagination = (event, value) => {
    setCurrnetPage(value)
  }

  const deleteConfirmationAction = () => {
    deletePermentlyAction()
    setDeleteAlertOpen(false)
  }
  const deleteCancelAction = () => {
    setDeleteAlertOpen(false)
    setCheckedState({})
  }

  const restoreConfirmationAction = () => {
    restoreFileAction()
    setRestoreAlertOpen(false)
  }
  const restoreCancelAction = () => {
    setRestoreAlertOpen(false)
    setCheckedState({})
  }

  const GridView = (value, index) => {
    const sameday = expireDate(value?.updatedAt) > 3 ? true : false
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <input
          aria-label={`${value?.name?.split('.')[0]}`}
          type="checkbox"
          checked={checkedState[value?._id] !== undefined}
          onChange={(e) => {
            handleOnChange(e.target.checked, value)
          }}
          onKeyDown={(e) => {
            e.key === 'Enter' && handleOnChange(!e?.target?.checked, value)
          }}
          style={{
            width: 17,
            height: 17,
            cursor: 'pointer',
            margin: '10px 0px',
            ':focus': { border: '2px dashed #666666' },
            accentColor: '#CC0000',
          }}
        />
        <Card
          key={`card-${value?._id}`}
          variant="elevation"
          elevation={2}
          sx={{
            margin: '10px',
            maxHeight: 350,
            width: 350,
            borderColor:
              checkedState[value?._id] !== undefined
                ? '#CC0000'
                : 'transparent',
            borderWidth: 4,
            borderStyle:
              checkedState[value?._id] !== undefined ? 'solid' : 'none',

            flexGrow: 1,
          }}
        >
          <div
            tabIndex={0}
            onKeyPress={() => {
              handlePreview(value)
            }}
            style={{ focus: { border: 'thin solid black' } }}
          >
            <CardMedia
              key={`card-media-${value?._id}`}
              component={
                value?.type?.includes('audio')
                  ? 'audio'
                  : value?.type?.includes('video')
                  ? 'video'
                  : value?.type?.includes('pdf')
                  ? 'iframe'
                  : 'img'
              }
              image={handleMedia(value)}
              autoPlay={false}
              controls
              alt={value && value?.name.split('.')[0]}
              sx={{
                objectFit: 'cover',
                height: 200,
                cursor: 'pointer',
                backgroundColor: value?.type.includes('audio')
                  ? '#ECB22E'
                  : 'transparent',
                '&::-webkit-media-controls-enclosure': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => {
                handlePreview(value)
              }}
              onMouseOver={(event) => event.target.play && event.target.play()}
              onMouseOut={(event) => event.target.pause && event.target.pause()}
            />
          </div>
          <Box
            sx={{
              backgroundColor: '#403d42',
              textAlign: 'center',
              color: sameday ? '#fff' : 'red',
              fontSize: '18px',
            }}
          >
            {expireDate(value?.updatedAt) > 1
              ? `${expireDate(value?.updatedAt)} days left`
              : `${expireDate(value?.updatedAt)} day left`}
          </Box>
          <CardContent sx={{ padding: '5px 20px', height: 70 }}>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '5px 0px',
                }}
              >
                <Chip
                  size={'small'}
                  label={
                    value &&
                    value.type &&
                    value.type.split('/').length > 1 &&
                    value.type.split('/')[1]
                  }
                  sx={{
                    borderRadius: 0,
                  }}
                />
                <Stack
                  direction={'row'}
                  spacing={1}
                  sx={{
                    overflowY: 'scroll',

                    paddingLeft: '10px',
                    '&::-webkit-scrollbar': {
                      width: '0.4em',
                    },
                  }}
                >
                  {value.tags.length > 0 ? (
                    value.tags.map((tag) => {
                      return tag !== '' ? (
                        <Chip
                          size={'small'}
                          label={tag}
                          sx={{
                            borderRadius: 10,
                          }}
                        />
                      ) : (
                        <></>
                      )
                    })
                  ) : (
                    <Box
                      sx={{
                        borderRadius: 1,
                        borderWidth: 1,
                        borderStyle: 'double',
                        padding: 0.5,
                        opacity: 0,
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.main"
                        noWrap={true}
                      >
                        {'PlaceHolder'}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Box>
              <Tooltip title={value.name.split('.')[0]}>
                <Typography
                  gutterBottom
                  // variant="h6"
                  component="div"
                  color="text.secondary"
                  noWrap={true}
                  sx={{}}
                >
                  {value.name.split('.')[0]}
                </Typography>
              </Tooltip>
            </div>
          </CardContent>
          <CardActions
            sx={{
              padding: '10px 15px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              disableFocusRipple
              aria-label={`${value.name.split('.')[0]}-copy`}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${BASEURL}${value && value.path}`,
                )
                openSnackbarWithMsg('Link copied', 'success', '#303F9F')
              }}
              sx={{ ':focus': { border: '2px dashed #666666' } }}
            >
              <InsertLink />
            </IconButton>
            <Box>{`Size: ${value.size ? getSize(value.size) : 'NA'}`}</Box>
          </CardActions>
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Box
        sx={{
          width: 'calc(100vw - 70px)',
          margin: '0px 10px',
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'start',
            margin: '10px 10px 0px 10px',
          }}
        >
          <>
            <Box
              flexDirection="row"
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <IconButton
                disableFocusRipple
                aria-label={`back-to-media-library`}
                onClick={() => {
                  callBack(false)
                }}
                sx={{
                  ':focus': { border: '2px dashed #666666' },
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography
                variant={'h6'}
                sx={{
                  fontFamily: 'Helvetica For Target',
                  ml: '15px',
                }}
              >
                {`${Object.keys(checkedState).length} item${
                  Object.keys(checkedState).length > 1 ? 's' : ''
                } selected`}
              </Typography>
            </Box>
            <Stack
              direction={'row'}
              spacing={1}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  marginRight: '100px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Button
                  disableFocusRipple
                  variant="text"
                  sx={{
                    textTransform: 'none',
                    fontSize: '18px',
                    ':focus': { border: '2px dashed #666666' },
                  }}
                  disabled={
                    Object.keys(checkedState).length !== 0 ? false : true
                  }
                  onClick={() => {
                    setDeleteAlertOpen(true)
                  }}
                >
                  Permanently Delete
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem />

                <Button
                  disableFocusRipple
                  variant="text"
                  sx={{
                    textTransform: 'none',
                    fontSize: '18px',
                    ':focus': { border: '2px dashed #666666' },
                  }}
                  disabled={
                    Object.keys(checkedState).length !== 0 ? false : true
                  }
                  onClick={() => {
                    setRestoreAlertOpen(true)
                  }}
                >
                  Restore
                </Button>
              </div>

              <FormGroup>
                <FormControlLabel
                  control={
                    <input
                      type="checkbox"
                      checked={
                        Object.keys(checkedState).length > 0 ? true : false
                      }
                      onChange={deselectAll}
                      style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                        marginRight: '10px',
                        accentColor: '#CC0000',
                      }}
                      onKeyDown={(e) => {
                        e.key === 'Enter' && deselectAll()
                      }}
                    />
                  }
                  label="Deselect all"
                  disabled={
                    listOfDeletedFiles?.files?.length !== 0 ? false : true
                  }
                />
              </FormGroup>
            </Stack>
          </>
        </Stack>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: 650,
              margin: '10px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : listOfDeletedFiles?.files?.length === 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: 500,
              margin: '10px',
            }}
          >
            <img
              src={noData}
              alt="no data found"
              style={{ height: '250px', width: '250px' }}
            />
          </Box>
        ) : (
          <GridList
            key={'grid-list'}
            data={listOfDeletedFiles?.files}
            children={GridView}
          />
        )}

        {listOfDeletedFiles?.total !== 0 && (
          <Stack alignItems={'flex-end'}>
            <Pagination
              count={totalPage}
              showFirstButton
              showLastButton
              color="primary"
              page={currnetPage}
              onChange={handlePagination}
              disabled={totalPage === 0 ? true : false}
              sx={{ ':focus': { border: '2px dashed #666666' }, mb: 2 }}
            />
          </Stack>
        )}

        {selectedItem && (
          <MyCarousel
            shouldArrowShow={true}
            itemPicked={selectedItem}
            items={[
              selectedItem && `${BASEURL}${selectedItem && selectedItem?.path}`,
            ]}
            open={previewOpen}
            close={setPreviewOpen}
            itemName={selectedItem?.name?.split('.')[0]}
          />
        )}
        {isDeleteAlertOpen && (
          <DeleteDialog
            open={isDeleteAlertOpen}
            close={setDeleteAlertOpen}
            deleteConfirmationAction={deleteConfirmationAction}
            deleteCancelAction={deleteCancelAction}
            deleteItemCount={Object.keys(checkedState).length}
          />
        )}
        {isRestoreAlertOpen && (
          <RestoreDialog
            open={isRestoreAlertOpen}
            close={setRestoreAlertOpen}
            restoreConfirmationAction={restoreConfirmationAction}
            restoreCancelAction={restoreCancelAction}
            restoreItemCount={Object.keys(checkedState).length}
          />
        )}
      </Box>
    </div>
  )
}

export default RecentlyDeleted
