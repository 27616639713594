import React from 'react'
import { Box, Grid, Button } from '@mui/material'
import NoEvents from '../NoEvents/NoEvents'
import { Search } from '../Search'

const NoRecordsWorkshop = (props) => {
  const { navigateToCreate, btn, label, image, editDisabled, disableWorkshop } =
    props
  const [search, setSearch] = React.useState('')
  return (
    <Box data-testid="wrapper-no-records">
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={5}>
          <Box
            sx={{
              padding: 1,
              backgroundColor: '#F6F7F8',
              height: '58vh',
              boxShadow: '0px 0px 3px 0px #888888',
            }}
          >
            <Search
              showReset={false}
              term={search}
              placeholder={'Search'}
              setTerm={(val) => setSearch(val)}
              resetSearch={() => setSearch('')}
              data-testid="search-box"
            />
            <Box
              sx={{
                fontWeight: 'bold',
                fontSize: 24,
                textAlign: 'center',
                m: 6,
              }}
              data-testid="label-workshop"
            >
              {/* No {label}, for this year <br />
              please click{' '} */}
              <Button
                variant="text"
                onClick={btn.cb}
                sx={{
                  color: '#CC0000',
                  cursor: 'pointer',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  fontSize: 24,
                  p: 0,
                  mt: -1,
                }}
                data-testid="create-workshop-link"
                disabled={editDisabled || disableWorkshop}
              >
                {btn.label}
              </Button>{' '}
              {/* <br /> button on top right corner to create {label}! */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              backgroundColor: '#F6F7F8',
              boxShadow: '0px 0px 3px 0px #888888',
              height: '58vh',
            }}
          >
            <NoEvents
              img={image}
              label={label}
              btn={{
                label: `Create New ${label}`,
                cb: navigateToCreate,
              }}
              editDisabled={editDisabled}
              noContent={true}
            ></NoEvents>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NoRecordsWorkshop
