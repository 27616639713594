import React from 'react'
// import PropTypes from 'prop-types'
import { Box, Divider } from '@mui/material'
import CreateParticipantForm from './CreateParticipantForm'
import { FormHeading } from '../../../FormComponents'
import { useParams, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { Loader, withRights } from '../../../../../components'
import { checkUserIsAllowed } from '../../../../../utils/rightUtils'
import AccessDenied from '../../../../../Layout/AccessDenied'

export const CreateParticipant = (props) => {
  const {
    setResetFormStatus,
    rights = {},
    rightsLoading,
    noSiteAccess = false,
  } = props
  const params = useParams()
  const navigate = useNavigate()
  const { id = null } = params
  const { moduleAccess, permittedActions, superRights } = rights
  const actionsAllowed = {
    userCanCreateParticipant: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
  }
  const { userCanCreateParticipant } = actionsAllowed
  const [customFields, setCustomFields] = React.useState([])

  const pathTrail = [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${id}#participants`,
      label: 'Event Setup',
    },
    {
      to: ``,
      label: 'Add New Participant',
    },
  ]

  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }
  const resetForm = () => {
    setResetFormStatus(true)
  }
  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess && userCanCreateParticipant ? (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }} data-testid="create-participant-wrapper">
        <Breadcrumbs trail={pathTrail} />
        <Box sx={{ mt: 2, width: '100%', height: '87vh' }}>
          <FormHeading
            text="Create Participant"
            enableBack={true}
            btns={[
              {
                label: 'Add new input',
                type: 'select',
                cb: addNewInput,
                icon: 'Add',
                options: [
                  { label: 'Text input', value: 'text' },
                  { label: 'Date input', value: 'date' },
                  { label: 'Switch input', value: 'switch' },
                ],
              },
              { label: 'Reset', cb: resetForm, icon: 'Replay' },
            ]}
          />
          <Divider variant="middle" sx={{ m: 0 }} />
          <CreateParticipantForm
            customFields={customFields}
            setCustomFields={setCustomFields}
            setResetFormStatus={setResetFormStatus}
            onCancel={() => navigate(`/events/event-setup/${id}#participants`)}
            onSuccess={() => navigate(`/events/event-setup/${id}#participants`)}
            eventid={id}
          />
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <AccessDenied module="Participants" />
    </Box>
  )
}

CreateParticipant.propTypes = {}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Create',
      access: 'Create',
    },
  ],
}

export default withRights(CreateParticipant, moduleAttr)
