import noimage from './Image_not_available.png'
import iconAudio from './icon_audio.png'
import iconVideo from './icon_video.png'
import iconPDF from './icon_pdf.png'
import bgAudio from './bg_audio.png'
import bgVideo from './bg_video.png'
import bgFile from './bg_file.png'
import iconFile from './icon_file.png'
import topLeft from './top_left.svg'
import topCenter from './top_center.svg'
import topRight from './top_right.svg'
import middleLeft from './middle_left.svg'
import middleCenter from './middle_center.svg'
import middleRight from './middle_right.svg'
import bottomLeft from './bottom_left.svg'
import bottomCenter from './bottom_center.svg'
import bottomRight from './bottom_right.svg'
import powerOfPen from './power_of_pen.png'
import bannerIcon from './bannerIcon.png'
import carouselIcon from './carouselIcon.png'
import cloneIcon from './cloneIcon.png'
import gridIcon from './gridIcon.png'
import tabIcon from './tabIcon.png'
import textBlockIcon from './textBlockIcon.png'
import chat from './chat.png'
import accordionPostIcon from './accordion_post.png'
import imagePostIcon from './image_post.png'
import agendaPostIcon from './agenda_post.png'
import textBlockPostIcon from './text_block_post.png'
import videoPostIcon from './video_post.png'
import noEvents from './no_events.png'
import noSpeakers from './no_speakers.png'
import speakerImage from './speaker_image.png'
import emptyListImage from './empty_list.png'
import mentorshipImage from './no_mentorship.png'
import download from './download.png'
import ga from './ga.png'
export const NOPREVIEWIMAGE = noimage
export const ICONAUDIO = iconAudio
export const ICONVIDEO = iconVideo
export const ICONPDF = iconPDF
export const BGAUDIO = bgAudio
export const BGVIDEO = bgVideo
export const BGFILE = bgFile
export const ICONFILE = iconFile
export const TOP_LEFT = topLeft
export const TOP_CENTER = topCenter
export const TOP_RIGHT = topRight
export const MIDDLE_LEFT = middleLeft
export const MIDDLE_CENTER = middleCenter
export const MIDDLE_RIGHT = middleRight
export const BOTTOM_LEFT = bottomLeft
export const BOTTOM_CENTER = bottomCenter
export const BOTTOM_RIGHT = bottomRight
export const POWER_OF_PEN = powerOfPen
export const BANNERICON = bannerIcon
export const CAROUSELICON = carouselIcon
export const GRIDICON = gridIcon
export const CLONEICON = cloneIcon
export const TABICON = tabIcon
export const TEXTBLOCKICON = textBlockIcon
export const CHAT = chat
export const IMAGEPOSTICON = imagePostIcon
export const TEXtBLOCKPOSTICON = textBlockPostIcon
export const VIDEOPOSTICON = videoPostIcon
export const ACCORDIONPOSTICON = accordionPostIcon
export const NOEVENTS = noEvents
export const NOSPEAKERS = noSpeakers
export const SPEAKERIMAGE = speakerImage
export const EMPTYLISTIMAGE = emptyListImage
export const NOMENTORSHIP = mentorshipImage
export const GALLERYICON = imagePostIcon
export const DOWNLOADICON = download
export const AGENDAICON = agendaPostIcon
export const GAIMAGE = ga
