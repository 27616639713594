import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
const RestoreDialog = (props) => {
  const {
    open,
    close,
    restoreConfirmationAction,
    restoreCancelAction,
    restoreItemCount = 1,
  } = props
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => close(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {` Are you sure want to Restore ${restoreItemCount} item${
              restoreItemCount > 1 ? 's' : ''
            } ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ background: 'transparent', m: '0px 20px' }}
            onClick={restoreCancelAction}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ background: '#3E72DB', ':hover': { background: '#3E72DB' } }}
            onClick={restoreConfirmationAction}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default RestoreDialog
