import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import PostList from '../../../../Menu/Common/PostList'
import {
  Step,
  Stepper,
  StepLabel,
  Typography,
  Alert,
  Card,
  Popover,
  ThemeProvider,
} from '@mui/material'
import { useGetPostQuery } from '../../../../../api/post'
import { useSelector } from 'react-redux'
import ListLoader from '../../../../../components/ListLoader'
import Preview from '../../Main/Preview'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const LoadSection = ({
  section,
  setSelectedComponent,
  selectedComponent,
  sectionNumber,
}) => {
  const { componentWidths, components, sectionName } = section
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const selectTheComponent = (sectionNo, columnNo, component) => {
    setSelectedComponent(
      selectedComponent &&
        selectedComponent.sectionNo === sectionNo &&
        selectedComponent.columnNo === columnNo
        ? null
        : {
            sectionNo,
            columnNo,
            component,
          },
    )
  }

  return (
    <>
      <Typography variant="caption" sx={{ ml: 1 }}>
        Section name: {sectionName}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {componentWidths &&
          componentWidths.map((width, key) => {
            const cardSelected =
              selectedComponent &&
              selectedComponent.sectionNo === sectionNumber &&
              selectedComponent.columnNo === key
            return (
              <React.Fragment key={key}>
                {components[key] && (
                  <React.Fragment key={key}>
                    <Card
                      sx={(theme) => ({
                        width: `${width}%`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        boxShadow: cardSelected ? 4 : 1,
                        background: cardSelected
                          ? theme.palette.primary.main
                          : '#fff',
                        color: cardSelected ? '#fff' : 'default',
                        cursor: 'pointer',
                        '&:hover': {
                          boxShadow: 4,
                        },
                      })}
                      onClick={() =>
                        selectTheComponent(sectionNumber, key, components[key])
                      }
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      {components[key].name}
                    </Card>
                    <ThemeProvider>
                      <Popover
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                      >
                        <Box sx={{ p: 3 }}>
                          <Preview
                            component={components[key]}
                            width={width}
                            hideControls={true}
                            defaultVal={false}
                            defaultZoomVal={30}
                          />
                        </Box>
                      </Popover>
                    </ThemeProvider>
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          })}
      </Box>
    </>
  )
}

const ListPostSections = (props) => {
  const { selectedPost, setSelectedComponent, selectedComponent } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const {
    data: postData,
    isLoading: loadingPostData,
    error: loadError,
  } = useGetPostQuery(
    { id: selectedPost ? selectedPost.id : null, activeSite },
    {
      skip: !(selectedPost && selectedPost.id && activeSite),
      refetchOnMountOrArgChange: true,
    },
  )
  return (
    <>
      <Box sx={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Typography sx={{ mr: 2 }}>
            <strong>Selected post:</strong>{' '}
            {selectedPost ? selectedPost.name : ''}
          </Typography>
          <Typography>
            <strong>Selected component:</strong>{' '}
            {selectedComponent ? selectedComponent.component.name : 'none'}
          </Typography>
        </Box>
        {loadError && (
          <Alert severity="error">
            Unable to load post component!. Please try again later or report to
            product team
          </Alert>
        )}
        {loadingPostData && <ListLoader />}
        {postData && (
          <Box>
            {postData.layout &&
              postData.layout.map((section, sectionKey) => {
                return (
                  <LoadSection
                    key={sectionKey}
                    section={section}
                    setSelectedComponent={setSelectedComponent}
                    selectedComponent={selectedComponent}
                    sectionNumber={sectionKey}
                  />
                )
              })}
          </Box>
        )}
      </Box>
    </>
  )
}

export default function CloneComponentFromPost(props) {
  const { open, handleClose, handleAdd } = props
  const [openPostList, setOpenPostList] = React.useState(false)
  const handleClosePostList = () => {
    setOpenPostList(false)
  }

  const steps = [
    'Select a Post',
    'Select component from the post',
    'Add the component to current post',
  ]
  const [activeStep, setActiveStep] = React.useState(0)
  const [resetPostSelection, setResetPostSelection] = React.useState(false)
  const [selectedPost, setSelectedPost] = React.useState(null)
  const [selectedComponent, setSelectedComponent] = React.useState(null)

  const handleNext = () => {
    if (activeStep === 2) {
      handleClone()
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const selectPostFromList = () => {
    setResetPostSelection(true)
    setOpenPostList(true)
  }

  const selectPost = (postInfo) => {
    setActiveStep(1)
    setSelectedPost(postInfo)
    setOpenPostList(false)
  }

  const handleClone = () => {
    handleAdd(selectedComponent.component)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 800 }}>
          <h2 id="parent-modal-title">Clone a component from post</h2>
          <Box sx={{ pt: 3 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            <Box sx={{ p: 3, m: 1, border: '1px solid' }}>
              {activeStep === 0 && (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'none' }}
                  onClick={() => selectPostFromList()}
                >
                  Click to select the post
                </Button>
              )}
              {activeStep === 1 && (
                <ListPostSections
                  selectedPost={selectedPost}
                  selectedComponent={selectedComponent}
                  setSelectedComponent={setSelectedComponent}
                />
              )}
              {activeStep === 2 && (
                <>You have successfully choosen the component. click Finish</>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={{ textTransform: 'none', mr: 1 }}
                onClick={() => handleBack()}
                variant={'outlined'}
                disabled={activeStep === 0}
              >
                Back
              </Button>
              <Button
                sx={{ textTransform: 'none' }}
                variant={'contained'}
                onClick={() => handleNext()}
                disabled={
                  (activeStep === 0 && !selectedPost) ||
                  (activeStep === 1 && !selectedComponent)
                }
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
          <PostList
            open={openPostList}
            handleClose={handleClosePostList}
            addPost={selectPost}
            reset={resetPostSelection}
            setReset={setResetPostSelection}
            includeId={true}
          />
        </Box>
      </Modal>
    </div>
  )
}
