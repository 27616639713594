import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import Label from './Common/Label'

export const NoOfItems = (props) => {
  const {
    value,
    onChange,
    name = 'No of items',
    max = 5,
    defaultValue = 0,
    disabled = false,
  } = props
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box
        sx={{
          display: 'flex',
          width: '70%',
          justifyContent: 'left',
          overflowX: 'auto',
        }}
      >
        <ToggleButtonGroup
          value={value ? value : defaultValue}
          size={'small'}
          exclusive
          onChange={(_, val) => onChange(val)}
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              textTransform: 'none',
              lineHeight: 'normal',
            },
            '& .Mui-selected': {
              background: 'grey.light',
              fontWeight: 'bold',
            },
            textOverflow: 'scroll',
          }}
          disabled={disabled}
        >
          {[...Array(max + 1)].map((_, key) => {
            return (
              <ToggleButton key={key} value={key} aria-label="Default">
                {key}
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

export default NoOfItems
