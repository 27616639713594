import { Box, Button, IconButton, SvgIcon, Typography } from '@mui/material'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as mui from '@mui/icons-material'
import { MenuButton } from '../../../components/MenuButton'

export const FormHeading = (props) => {
  const { text = '', subText = '', btns = [], enableBack = true } = props
  const navigate = useNavigate()
  return (
    <Box
      data-testid="form-heading-wrapper"
      sx={{ display: 'flex', alignItems: 'center', p: 1, pr: 1.5, pl: 1.5 }}
    >
      {enableBack && (
        <IconButton data-testid="back-btn" onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ fontSize: '2rem' }} />
        </IconButton>
      )}
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'flex-end' }}>
        {text && (
          <Typography
            variant="h4"
            component={'h3'}
            data-testid="heading-elm"
            sx={{ fontWeight: 700, fontSize: 32, lineHeight: '40px' }}
          >
            {text}
          </Typography>
        )}
        {subText && (
          <Typography
            variant="caption"
            data-testid="caption-elm"
            sx={{
              fontWeight: 700,
              fontSize: 20,
              lineHeight: '30px',
              color: '#7D7D7D',
              pl: 0.8,
            }}
          >
            {subText}
          </Typography>
        )}
      </Box>
      {btns &&
        btns.length > 0 &&
        btns.map((btn, key) => {
          return (
            <Box key={key}>
              {btn.type === 'select' ? (
                <MenuButton
                  onClick={btn?.cb}
                  startIcon={<SvgIcon component={mui[btn?.icon]} />}
                  label={btn.label}
                  options={btn?.options}
                />
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<SvgIcon component={mui[btn?.icon]} />}
                  color="secondary"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                    mr: 1,
                  }}
                  onClick={btn?.cb}
                  key={key}
                  data-testid={`dynamic-btn-${key}`}
                >
                  {btn?.label}
                </Button>
              )}
            </Box>
          )
        })}
    </Box>
  )
}

FormHeading.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  btns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      cb: PropTypes.func.isRequired,
    }),
  ),
  enableBack: PropTypes.bool,
}

export default FormHeading
