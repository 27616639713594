import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { useGetParticipantHistoryQuery } from '../../../../../api/participant'
import { formatParticipantHistoryData } from '../Utils'
import { Markup } from 'interweave'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import BlockIcon from '@mui/icons-material/Block'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import { ProgressBar, Placeholder } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'

export const ParticipantHistory = (props) => {
  const { eventid, participantid } = props
  const { data: historyData, isLoading } = useGetParticipantHistoryQuery(
    { participantid, eventid },
    { skip: !participantid || !eventid, refetchOnMountOrArgChange: true },
  )
  const [historyFormatted, setHistoryFormatted] = React.useState([])

  React.useEffect(() => {
    if (historyData) {
      const formattedData = formatParticipantHistoryData(historyData)
      setHistoryFormatted(formattedData)
    }
  }, [historyData])

  const iconSelection = {
    APPROVED: { icon: <CheckCircleIcon />, color: '#008300' },
    REJECTED: { icon: <CancelIcon />, color: '#AE570F' },
    ON_HOLD: { icon: <HourglassBottomIcon />, color: '#E2BA0C' },
    BLOCKED: { icon: <BlockIcon />, color: '#D1F0DB' },
  }

  return (
    <Box sx={{ pt: 2, pb: 2 }}>
      {isLoading && (
        <>
          <ProgressBar indeterminate />
          <Placeholder
            style={{
              flexGrow: 1,
              marginLeft: 0,
              marginTop: 0,
              padding: 20,
            }}
          >
            <Placeholder.Rect height="80vh" width="100%" emphasized />
          </Placeholder>
        </>
      )}
      {historyFormatted && historyFormatted.length > 0 && (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 2,
            },
          }}
        >
          {historyFormatted.map((history, key) => {
            return (
              <TimelineItem key={key}>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot
                    sx={{
                      background: '#d3d3d3',
                      color: iconSelection?.[history?.status]
                        ? iconSelection?.[history?.status]?.color
                        : '#d3d3d3',
                    }}
                  >
                    {iconSelection?.[history?.status] ? (
                      iconSelection?.[history?.status]?.icon
                    ) : (
                      <ChangeHistoryIcon />
                    )}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <Typography variant="h6" component="span">
                    {history?.status}
                  </Typography>
                  <Typography>
                    <Markup content={history.message} />
                  </Typography>
                  <Typography variant="caption">
                    {moment(history?.time).fromNow()}
                  </Typography>
                  {history?.comments && (
                    <Typography
                      sx={{
                        border: '1px solid #d3d3d3',
                        p: 0.5,
                        pt: 1,
                        pb: 1,
                        fontSize: 12,
                        background: 'rgba(9, 109, 217, 0.1)',
                      }}
                    >
                      <strong>Comment: </strong>
                      {history?.comments}
                    </Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            )
          })}
        </Timeline>
      )}
    </Box>
  )
}

export default ParticipantHistory
