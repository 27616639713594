import * as React from 'react'
import Chip from '@mui/material/Chip'
import FormStyledInput from './FormStyledInput'
import { useField } from 'formik'
import { PropTypes } from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'

export const FormMultiSelect = (props) => {
  const { label = '', options = [], chipColor = 'success' } = props
  // eslint-disable-next-line no-unused-vars
  const [field, _state, { setValue, setTouched }] = useField(props?.field?.name)
  const [inputValue, setInputValue] = React.useState('')
  const { value = [] } = field

  const handleChange = (value) => {
    setTouched(true)
    setValue(value)
  }

  return (
    <FormStyledInput>
      <Autocomplete
        value={value}
        multiple
        size="small"
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={options}
        onChange={(_, newValue) => {
          handleChange(newValue)
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color={chipColor}
              label={option}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField size="small" {...params} label={label} />
        )}
      />
    </FormStyledInput>
  )
}

FormMultiSelect.propTypes = {
  field: PropTypes.shape({ name: PropTypes.string.isRequired }),
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
}

export default FormMultiSelect
