import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import {
  Box,
  Typography,
  ListItemAvatar,
  Avatar,
  Select,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import { MentionsInput, Mention } from 'react-mentions'
import { useGetUsersListQuery } from '../api/users'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'

export default function DialogConfirmation(props) {
  const {
    title = '',
    children,
    onConfirm,
    rejection = false,
    onReject,
    onClose,
    open,
    actionButtonLabel = 'Proceed',
    commentEnabled = false,
    assignment = false,
    currentStatus = '',
  } = props
  const [comment, setComment] = React.useState('')
  const [userData, setUsers] = React.useState([])
  const { data: users } = useGetUsersListQuery()
  const [assignedTo, setAssignedTo] = React.useState('')

  const getLabelValueByLanId = (lanId) => {
    if (lanId && userData) {
      const found = userData.find((a) => a.lanId === lanId)
      return found ? found.displayName : lanId
    }
    return ''
  }

  const renderUserSuggestion = (
    entry,
    _search,
    _highlightedDisplay,
    _index,
    focused,
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0.5,
          background: focused ? '#90caf9' : '#f6f9ff',
          borderBottom: '1px solid #d3d3d3',
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <PersonOutlineIcon />
          </Avatar>
        </ListItemAvatar>
        <div>
          <Typography
            sx={{ fontSize: 12, fontWeight: 'bold', width: '100%' }}
            variant="body1"
          >
            {entry.displayName}
          </Typography>
          <Typography sx={{ fontSize: 10 }} variant="body2">
            {entry.title}
          </Typography>
        </div>
      </Box>
    )
  }

  React.useEffect(() => {
    if (users) {
      setUsers(
        ...[
          users.map((user) => {
            return {
              ...user,
              displayName: user.displayName ? user.displayName : user.lastName,
              title: user.title ? user.title : 'No title',
            }
          }),
        ],
      )
    }
  }, [users])

  React.useEffect(() => {
    if (!open) {
      setAssignedTo('')
    }
  }, [open])

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {children}
          {assignment && (
            <Box sx={{ pl: 0, pt: 2, pb: 2 }}>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                fullWidth
              >
                <InputLabel id="grouped-select">Assinged to</InputLabel>
                <Select
                  defaultValue=""
                  id="grouped-select"
                  label="Assign to"
                  renderValue={(selected) => {
                    const label = getLabelValueByLanId(selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip size="medium" key={selected} label={label} />
                      </Box>
                    )
                  }}
                  value={assignedTo}
                  onChange={(e) => setAssignedTo(e.target.value)}
                  size="medium"
                >
                  {userData.map((approver, key) => {
                    return (
                      <MenuItem key={key} value={approver.lanId}>
                        {approver.displayName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          )}
          {commentEnabled && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  minHeight: 60,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    '& textarea': {
                      border: '2px solid #6f6f6f',
                      p: 1,
                      fontSize: '14px !important',
                      minHeight: 60,
                      '&:focus': {
                        border: '2px solid #d3d3d3',
                        outline: 'none',
                      },
                    },
                    width: '100%',
                    minHeight: 60,
                  }}
                >
                  <MentionsInput
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Comment"
                  >
                    <Mention
                      trigger="@"
                      data={userData}
                      renderSuggestion={renderUserSuggestion}
                    />
                  </MentionsInput>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            size={'small'}
            sx={{ textTransform: 'none' }}
            onClick={onClose}
          >
            Cancel
          </Button>
          {rejection && (
            <Button
              variant={'outlined'}
              sx={{ textTransform: 'none' }}
              onClick={() => onReject(comment)}
              autoFocus
              size={'small'}
              disabled={commentEnabled && !comment}
            >
              {'Reject'}
            </Button>
          )}
          <Button
            variant={'contained'}
            sx={{ textTransform: 'none' }}
            onClick={() =>
              onConfirm(
                assignment ? { assignedTo, comment, currentStatus } : comment,
              )
            }
            autoFocus
            size={'small'}
            disabled={
              (commentEnabled && !comment) || (assignment && !assignedTo)
            }
          >
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
