import {
  Delete,
  FormatListBulleted,
  GridView,
  Search,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

export const ModuleHead = (props) => {
  const {
    search,
    setSearch,
    listType = 'list',
    setListType,
    actionButtons = [],
    onDelete,
    disableDelete = false,
    allActionsDisabled = false,
    hideViewBtns = false,
    rights = {},
  } = props
  const { superRights } = rights
  return (
    <Box data-testid="module-head-wrapper">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          variant="outlined"
          placeholder="Search"
          value={search}
          name="search"
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            width: '20%',
            '& .MuiOutlinedInput-input': {
              padding: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
            'data-testid': 'search-box',
          }}
        />
        {!hideViewBtns && (
          <ToggleButtonGroup
            value={listType}
            exclusive
            onChange={(_e, newValue) => {
              setListType(newValue)
            }}
            aria-label="view"
          >
            <ToggleButton
              value="grid"
              aria-label="grid view"
              data-testid={'grid-btn'}
            >
              <GridView sx={{ fontSize: '1rem' }} />
            </ToggleButton>
            <ToggleButton
              value="list"
              aria-label="list view"
              data-testid={'list-btn'}
            >
              <FormatListBulleted sx={{ fontSize: '1rem' }} />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <IconButton
            onClick={onDelete}
            disabled={!superRights && (disableDelete || allActionsDisabled)}
            sx={{
              color: '#CC0000',
              borderRadius: 0,
              backgroundColor: '#F0F0F0',
            }}
          >
            <Delete />
          </IconButton>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: '#D3D3D3' }}
          />
          {actionButtons.length > 0 &&
            actionButtons.map((btn, key) => {
              return (
                <Button
                  key={key}
                  variant={btn?.variant ? btn?.variant : 'contained'}
                  data-testid={`action-btn-${key}`}
                  disabled={allActionsDisabled || btn.disabled}
                  sx={
                    btn.variant === 'outlined'
                      ? {
                          color: '#CC0000',
                          border: '1px solid #CC0000',
                          textTransform: 'none',
                          fontSize: '16px',
                          '&:hover': {
                            border: '1px solid #CC0000',
                          },
                        }
                      : {
                          backgroundColor: '#CC0000',
                          textTransform: 'none',
                          fontSize: '16px',
                          '&:hover': {
                            background: '#CC0000',
                          },
                        }
                  }
                  onClick={() => btn.cb()}
                >
                  {btn?.label}
                </Button>
              )
            })}
        </Box>
      </Box>
    </Box>
  )
}

const ActionButtons = PropTypes.shape({
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  cb: PropTypes.func.isRequired,
})
ModuleHead.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  listType: PropTypes.string,
  setListType: PropTypes.func.isRequired,
  actionButtons: PropTypes.arrayOf(ActionButtons),
}

export default ModuleHead
