import {
  Card,
  Box,
  Typography,
  LinearProgress,
  Grid,
  FormControlLabel,
  Switch,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useGetConfigurationBySlugQuery,
  useSaveConfigurationMutation,
} from '../../api/configuration'
import ListLoader from '../../components/ListLoader'
import { useSnackbar } from 'notistack'

const EventSettings = (props) => {
  const { allow } = props
  const [eventData, setEventData] = useState({})
  const [emailerConfig, setEmailerConfig] = useState({ id: null, data: {} })
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const [moduleTypeSaving, setModuleTypeSaving] = useState('')
  const [confId, setConfId] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'event',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const { data: siteEmailerConfig, isLoading: siteEmailerConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'emailer',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [
    saveConfiguration,
    {
      isLoading: saveConfigLoading,
      isSuccess: configurationSaved,
      isError: configurationSaveError,
    },
  ] = useSaveConfigurationMutation()

  const handleChange = (val, key, type) => {
    if (type === 'event') {
      setEventData({ ...eventData, [key]: val })
      setModuleTypeSaving('Event')
      saveConfiguration({
        configuration: {
          name: 'event',
          value: { ...eventData, [key]: val },
        },
        activeSite,
        id: confId,
      })
    }
    if (type === 'emailer') {
      setEmailerConfig({
        ...emailerConfig,
        data: { ...emailerConfig.data, [key]: val },
      })
      setModuleTypeSaving('Emailer')
      saveConfiguration({
        configuration: {
          name: 'emailer',
          value: { ...emailerConfig.data, [key]: val },
        },
        activeSite,
        id: emailerConfig.id,
      })
    }
  }

  useEffect(() => {
    if (configurationSaved || configurationSaveError) {
      enqueueSnackbar(
        configurationSaved
          ? (moduleTypeSaving === 'Event' && eventData?.enabled) ||
            (moduleTypeSaving === 'Emailer' && emailerConfig?.data?.enabled)
            ? `${moduleTypeSaving} module enabled successfully`
            : `${moduleTypeSaving} module disabled successfully`
          : 'Unable to save the module settings. Please try again!',
        {
          variant: configurationSaved ? 'success' : 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      )
      setModuleTypeSaving('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationSaved, configurationSaveError, enqueueSnackbar])

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      let found = false
      settings.map((conf) => {
        if (conf.name === 'event') {
          found = true
          setEventData(conf.value ? conf.value : {})
          setConfId(conf._id)
        }
        return true
      })
      if (!found) {
        setEventData({})
        setConfId(null)
      }
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteEmailerConfig
      let found = false
      settings.map((conf) => {
        if (conf.name === 'emailer') {
          found = true
          setEmailerConfig(
            conf.value
              ? { id: conf?._id, data: conf?.value }
              : { id: null, data: {} },
          )
        }
        return true
      })
      if (!found) {
        setEmailerConfig({ id: null, data: {} })
      }
    }
    if (!siteEmailerConfigLoading && siteEmailerConfig) {
      updateMenuData(siteEmailerConfig)
    }
  }, [siteEmailerConfig, siteEmailerConfigLoading])

  return (
    <>
      {!siteConfigLoading ? (
        <Card data-testid="setting-wrapper">
          {saveConfigLoading && <LinearProgress />}
          <Box
            sx={{
              background: '#E6E7EA',
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Module Settings</Typography>
            <Box sx={{ flexGrow: 1 }} />
            {/* <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                ml: 2,
                display: 'flex',
                backgroundColor: '#CC0000',
              }}
              size="small"
              startIcon={<SaveIcon />}
              disabled={!allow.edit || saveConfigLoading}
              onClick={saveSiteData}
              data-testid="save-btn"
            >
              Save
            </Button> */}
          </Box>
          <Grid
            sx={{
              p: 2,
            }}
          >
            <Grid item xs={12} sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography sx={{ flex: 1, fontWeight: 'bold' }}>
                  Do you want to turn on the Event Settings?
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!allow || saveConfigLoading}
                      checked={eventData?.enabled ? eventData?.enabled : false}
                      onChange={(e) => {
                        handleChange(e.target.checked, e.target.name, 'event')
                      }}
                      inputProps={{
                        'data-testid': 'event-switch',
                      }}
                      name="enabled"
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: '#5FBF5F',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                          {
                            backgroundColor: '#5FBF5F',
                          },
                      }}
                    />
                  }
                  label={eventData?.enabled ? 'On' : 'Off'}
                />
              </Box>
              <Typography variant="caption" sx={{ width: '100%' }}>
                When enabled, event management capabilities will be added to
                your project.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Typography sx={{ flex: 1, fontWeight: 'bold' }}>
                  Do you want to turn on the Email Builder settings?
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!allow || saveConfigLoading}
                      checked={
                        emailerConfig?.data?.enabled
                          ? emailerConfig?.data?.enabled
                          : false
                      }
                      onChange={(e) => {
                        handleChange(e.target.checked, e.target.name, 'emailer')
                      }}
                      inputProps={{
                        'data-testid': 'event-switch',
                      }}
                      name="enabled"
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: '#5FBF5F',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                          {
                            backgroundColor: '#5FBF5F',
                          },
                      }}
                    />
                  }
                  label={eventData?.enabled ? 'On' : 'Off'}
                />
              </Box>
              <Typography variant="caption" sx={{ width: '100%' }}>
                When enabled, emailer capabilities will be added to your
                project.
              </Typography>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <ListLoader />
      )}
      <p style={{ display: 'none' }} aria-hidden="true">
        {`switch-${JSON.stringify(eventData?.enabled)}`}
        {configurationSaved ? 'hh' : 'ds'}
      </p>
    </>
  )
}

export default EventSettings
