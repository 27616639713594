import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import moment from 'moment'

const CommentsList = (props) => {
  const {
    itemIndex,
    revision = '',
    page_data = {},
    post_data = {},
    type,
    createdAt,
    unread = [],
    handleCommentClick,
    selectedComment = {},
  } = props

  const { name, _id } = type ? (type === 'post' ? post_data : page_data) : ''

  const handleClick = (type, name, _id, revision) => {
    handleCommentClick({ type, name, _id, revision, unread })
  }

  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
          boxShadow: 'none',

          borderRadius: 0,
          background:
            selectedComment?.name === name && unread?.length > 0
              ? 'rgba(9, 109, 217, 0.2)'
              : unread?.length > 0
              ? 'rgba(9, 109, 217, 0.1)'
              : 'transparent',
        }}
        key={itemIndex}
        onClick={() => handleClick(type, name, _id, revision)}
      >
        <CardHeader
          sx={{
            '& .MuiCardHeader-avatar': {
              display: 'none',
            },
            '& .MuiCardHeader-action': {
              alignSelf: 'end',
            },
            '& .MuiCardHeader-content': {
              pr: 5,
            },
            '& .MuiCardHeader-title': {
              whiteSpace: 'noWrap',
              width: '250px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: '#000',
              fontSize: 12,
            },
            '& .MuiCardHeader-subheader': {
              color: '#000',
              fontSize: 12,
            },
            cursor: 'pointer',
          }}
          avatar={<></>}
          action={
            <div
              style={{
                borderRadius: '50%',
                width: 22,
                height: 22,
                padding: '1px',
                background: '#096DD9',
                color: '#fff',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: 'bold',
                visibility: unread && unread.length > 0 ? 'visible' : 'hidden',
              }}
            >
              {unread?.length}
            </div>
          }
          title={name}
          subheader={'New comment is added'}
        />
        <CardContent sx={{ pb: '10px !important', pt: '0 !important' }}>
          <Box>
            <Typography
              variant="caption"
              sx={{ color: '#5A5A5A', fontSize: 13 }}
            >
              {moment(createdAt).fromNow()}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Divider sx={{ borderColor: '#B5B5B5' }} />
    </>
  )
}

export default CommentsList
