import { Box, TextField, InputAdornment } from '@mui/material'
import React from 'react'
import { disableControlsBasedOnDevice } from '../../Helpers'
import { Label } from '../Label'
import { DataConnectivityIndicator } from '../DataConnector'

export const Text = (props) => {
  const {
    disabled = false,
    placeholder = 'Enter the text',
    inpType = 'text',
    endAdorment = null,
    value = '',
    onChange,
    slug = null,
    device = '',
    forDevices = [],
    enableLabel = false,
    name = '',
    helperText = '',
    min = 0,
    max = 100,
    noSlugChange = false,
    testid = '',
    path = '',
  } = props
  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )
  const inputProps = {
    ...(endAdorment
      ? {
          endAdornment: (
            <InputAdornment position="end">{endAdorment}</InputAdornment>
          ),
          'data-testid': testid ? testid : 'text-input',
        }
      : { 'data-testid': testid ? testid : 'text-input' }),
    style: { cursor: disabled ? 'not-allowed' : 'inherit' },
  }

  const typeProps = {
    ...(inpType === 'number'
      ? { type: 'number', InputProps: { ...inputProps, step: 1, min, max } }
      : {}),
    ...(inpType === 'float'
      ? { type: 'number', InputProps: { ...inputProps, step: 0.1, min, max } }
      : {}),
    ...(inpType === 'text'
      ? { multiline: true, type: 'text', InputProps: { ...inputProps } }
      : {}),
  }
  return (
    <Box>
      {enableLabel && (
        <Label name={name} disabled={disabledOrNot} helperText={helperText} />
      )}
      <DataConnectivityIndicator flag={`${path}.${slug.replace('*.', '')}`}>
        <Box sx={{ display: 'flex' }}>
          <TextField
            {...typeProps}
            value={inpType === 'number' ? parseInt(value) : value}
            onChange={(e) =>
              onChange(
                inpType === 'text'
                  ? e.target.value
                  : parseFloat(e.target.value),
                noSlugChange
                  ? slug
                  : `${slug}${
                      device && device !== 'desktop'
                        ? device.charAt(0).toUpperCase() + device.slice(1)
                        : ''
                    }`,
              )
            }
            fullWidth
            placeholder={placeholder}
            disabled={disabledOrNot}
            size="small"
            sx={{
              borderColor: '#fff',
            }}
          />
          {/* {inpType === 'text' && <DataConnector />} */}
        </Box>
      </DataConnectivityIndicator>
    </Box>
  )
}

export default Text
