import React from 'react'
import FormStyledInput from './FormStyledInput'
import { Input } from '@enterprise-ui/canvas-ui-react'
import { useField } from 'formik'

export const FormToggle = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [field, _state, { setValue, setTouched }] = useField(props.field.name)
  const {
    sx,
    form: { touched, errors },
  } = props
  return (
    <FormStyledInput sx={sx}>
      <Input.Toggle
        id={field.name}
        {...field}
        label={props.label}
        aria-label={props?.['aria-label']}
        data-testid={field.name}
        error={touched[field.name] && errors[field.name]}
        onChange={(event) => {
          setTouched(true)
          setValue(event.target.checked)
        }}
      />
    </FormStyledInput>
  )
}

export default FormToggle
