import {
  Box,
  Button,
  ButtonBase,
  Card,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import React from 'react'
import TransparentBg from '../../../../../../../assets/Image/TransparentBg.png'
import ChooseFromPallete from './ChooseFromPallete'
import { ChromePicker } from 'react-color'
import { Label } from '../Label'
import { disableControlsBasedOnDevice } from '../../Helpers'
import ColorPicker from 'react-best-gradient-color-picker'

export const ColorGradientPicker = (props) => {
  const {
    value = '',
    onChange,
    slug = '',
    disabled = false,
    gradientAllowed = false,
    enableLabel = false,
    name = '',
    forDevices = [],
    device = 'desktop',
    helperText = '',
    isFromTabular = false,
    placement = 'bottom-start',
  } = props
  const [paletteOpen, setPalleteOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const prevOpen = React.useRef(paletteOpen)
  React.useEffect(() => {
    if (prevOpen.current === true && paletteOpen === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = paletteOpen
  }, [paletteOpen])

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )

  return (
    <>
      {enableLabel && (
        <Box sx={{ flex: 1 }}>
          <Label name={name} disabled={disabledOrNot} helperText={helperText} />
        </Box>
      )}
      <Box
        data-testid="color-picker"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: !isFromTabular ? 'left' : 'center',
        }}
      >
        <Box ref={anchorRef}>
          <Typography
            sx={{
              fontSize: 13,
              color: disabledOrNot ? 'rgba(0, 0, 0, 30%)' : 'default',
              display: !isFromTabular ? 'block' : 'none',
            }}
            data-testid="label-color-picker"
          >
            Pick the color
          </Typography>
        </Box>

        <Card
          sx={{
            borderRadius: '50%',
            height: 28,
            width: 28,
            border: '1px solid #d3d3d3',
            ml: 1,
          }}
          data-testid={'color-card'}
        >
          <ButtonBase
            disabled={disabledOrNot}
            data-testid={'clickable-btn'}
            onClick={() => {
              setPalleteOpen(!paletteOpen)
            }}
          >
            <Box
              sx={{
                background: value ? value : `url(${TransparentBg})`,
                width: 28,
                height: 28,
                opacity: disabledOrNot ? 0.4 : 1,
              }}
            ></Box>
          </ButtonBase>
        </Card>
      </Box>
      <Popper
        open={paletteOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={placement}
        transition
        disablePortal
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            sx={{ zIndex: 2 }}
            style={{
              zIndex: 2,
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ zIndex: 1000 }}>
              <ClickAwayListener onClickAway={() => setPalleteOpen(false)}>
                <Box
                  sx={{
                    position: 'relative',
                    zIndex: 1000,
                    p: 1,
                    mt: 2,
                    border: '1px solid #d3d3d3',
                    width: gradientAllowed ? 350 : 270,
                    minHeight: 150,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ChooseFromPallete {...props} />
                  {gradientAllowed ? (
                    <ColorPicker
                      width={230}
                      value={value}
                      onChange={(val) => onChange(val, slug)}
                      hidePresets={true}
                      hideEyeDrop={true}
                      hideAdvancedSliders={true}
                    />
                  ) : (
                    <ChromePicker
                      color={value}
                      disabled={disabled}
                      onChangeComplete={(color, _) => {
                        onChange(color.hex, slug)
                      }}
                      disableAlpha
                      data-testid="picker"
                      gradientAllowed={gradientAllowed}
                    />
                  )}
                  <Divider sx={{ mt: 2, mb: 2, width: '100%' }} />
                  <Button
                    sx={{ textTransform: 'none' }}
                    variant="contained"
                    size="small"
                    onClick={() => setPalleteOpen(false)}
                  >
                    Close
                  </Button>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ColorGradientPicker
