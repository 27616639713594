import {
  Card,
  Box,
  Button,
  Typography,
  LinearProgress,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { useSelector } from 'react-redux'
import {
  useGetConfigurationBySlugQuery,
  useSaveConfigurationMutation,
} from '../../api/configuration'
import ListLoader from '../../components/ListLoader'

const SiteSettings = (props) => {
  const { allow } = props
  const [siteData, setSiteData] = useState({})
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const [confId, setConfId] = useState(null)

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'site',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [saveConfiguration, { isLoading: saveConfigLoading }] =
    useSaveConfigurationMutation()

  const handleChange = (val, key) => {
    setSiteData({ ...siteData, [key]: val })
  }

  const saveSiteData = () => {
    saveConfiguration({
      configuration: {
        name: 'site',
        value: siteData,
      },
      activeSite,
      id: confId,
    })
  }

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      let found = false
      settings.map((conf) => {
        if (conf.name === 'site') {
          found = true
          setSiteData(conf.value ? conf.value : {})
          setConfId(conf._id)
        }
        return true
      })
      if (!found) {
        setSiteData({})
        setConfId(null)
      }
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  return (
    <>
      {!siteConfigLoading ? (
        <Card>
          {saveConfigLoading && <LinearProgress />}
          <Box
            sx={{
              background: '#E6E7EA',
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Site Settings</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                ml: 2,
                display: 'flex',
                backgroundColor: '#CC0000',
              }}
              size="small"
              startIcon={<SaveIcon />}
              disabled={!allow.edit || saveConfigLoading}
              onClick={saveSiteData}
            >
              Save
            </Button>
          </Box>
          <Grid
            sx={{
              p: 2,
            }}
          >
            <Grid item xs={6} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Site URL"
                type="url"
                variant="standard"
                placeholder="https://domain.target.com"
                onChange={(e) => handleChange(e.target.value, 'siteUrl')}
                value={siteData.siteUrl ? siteData.siteUrl : ''}
                helperText="This site URL will be used for onsite preview. Before adding the URL make sure the site is integrated using Stencil client."
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              p: 2,
            }}
          >
            <Grid item xs={6} sx={{ p: 2 }}>
              <TextField
                multiline
                rows={4}
                fullWidth
                label="Site Footer Verbiage"
                type="text"
                variant="standard"
                placeholder="Enter the text"
                onChange={(e) =>
                  handleChange(e.target.value, 'footer_verbiage')
                }
                value={siteData.footer_verbiage ? siteData.footer_verbiage : ''}
                helperText="This text will be displayed at the bottom of the footer"
              />
            </Grid>
          </Grid>
          <Grid
            sx={{
              p: 2,
            }}
          >
            <Grid item xs={6} sx={{ p: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) =>
                      handleChange(
                        e.target.checked,
                        'subscriptionReportEnabled',
                      )
                    }
                    checked={siteData.subscriptionReportEnabled ? true : false}
                  />
                }
                label="Enable Subscription Report"
              />
              <FormHelperText>
                Check this option to enable the reports for subscriptions to
                your site
              </FormHelperText>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <ListLoader />
      )}
    </>
  )
}

export default SiteSettings
