import React from 'react'
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Grid,
} from '@mui/material'
import Label from './Common/Label'

export const Enum = (props) => {
  const {
    value,
    onChange,
    name = 'Type',
    allowedValues = [],
    defaultValue,
    helperText = '',
    disabled = false,
    custom = false,
  } = props
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} helperText={helperText} />
      <Grid
        container
        sx={{
          display: 'flex',
          width: '70%',
          justifyContent: 'left',
        }}
      >
        <Grid item sx={{ width: '100%', overflowX: 'auto' }}>
          <ToggleButtonGroup
            value={value ? value : defaultValue}
            size={'small'}
            exclusive
            onChange={(_, val) => onChange(val)}
            sx={{
              '& .MuiToggleButtonGroup-grouped': {
                textTransform: 'none',
                lineHeight: 'normal',
              },
              '& .Mui-selected': {
                background: 'grey.light',
                fontWeight: 'bold',
              },
              textOverflow: 'scroll',
            }}
            disabled={disabled}
          >
            {allowedValues &&
              allowedValues.length > 0 &&
              allowedValues.map((option, key) => {
                return (
                  <ToggleButton
                    key={key}
                    value={typeof option === 'string' ? option : option.value}
                  >
                    {typeof option === 'string' ? option : option.label}
                  </ToggleButton>
                )
              })}
          </ToggleButtonGroup>
        </Grid>
        {custom && (
          <Grid item sx={{ width: '100%' }}>
            <TextField
              value={value ? value : defaultValue}
              onChange={(e) => onChange(e.target.value)}
              multiline
              fullWidth
              size="small"
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Enum
