/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import {
  Tabs,
  Tab,
  Grid,
  LinearProgress,
  Card,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Popper,
  Paper,
  Grow,
  Divider,
  ClickAwayListener,
  Button,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { useGetPaletteColorsQueryQuery } from '../../../../../../../api/configuration'
import ColorPicker from 'react-best-gradient-color-picker'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import TransparentBg from '../../../../../../../assets/Image/TransparentBg.png'
import { MoreHoriz } from '@mui/icons-material'
import { Label } from '../../Common'
import { disableControlsBasedOnDevice } from '../../Helpers'
import { ChromePicker } from 'react-color'
export const ButtonTextColor = (props) => {
  const {
    value = {},
    onChange,
    name = 'Color',
    helperText = '',
    disabled = false,
    device = '',
    forDevices = [],
    enableLabel = false,
  } = props

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )

  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [selectedTab, setSelectedTab] = React.useState(0)
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const { data: paletteData, isLoading: paletteDataLoading } =
    useGetPaletteColorsQueryQuery(
      {
        slug: 'palette',
        activeSite,
      },
      {
        skip: !activeSite,
      },
    )

  const [colorState, setColorState] = React.useState('normal')
  const [selectedAttr, setSelectedAttr] = React.useState('color')
  const [paletteOpen, setPalleteOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const prevOpen = React.useRef(paletteOpen)

  React.useEffect(() => {
    if (colorState) {
      switch (selectedTab) {
        case 0:
          setSelectedAttr(colorState === 'normal' ? 'color' : 'hoverColor')
          break

        default:
          setSelectedAttr(
            colorState === 'normal' ? 'background' : 'hoverBackground',
          )
          break
      }
    }
  }, [colorState, selectedTab])

  const handleFormat = (_, value) => {
    setColorState(value)
  }

  const onChangeColor = (color) => {
    onChange(color, selectedAttr)
  }

  return (
    <Box>
      {enableLabel && (
        <Box sx={{ flex: 1 }}>
          <Label name={name} disabled={disabledOrNot} helperText={helperText} />
        </Box>
      )}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="button text/background color"
            variant="fullWidth"
            data-testid="button-color-type"
          >
            <Tab
              size={'small'}
              label="Text Color"
              sx={{ textTransform: 'none' }}
              disabled={disabledOrNot}
              data-testid="tab-text-color"
            />
            <Tab
              label="Background Color"
              sx={{ textTransform: 'none' }}
              disabled={disabledOrNot}
              data-testid="tab-background-color"
            />
          </Tabs>
        </Box>

        {(selectedTab === 0 || selectedTab === 1) && (
          <Box sx={{ p: 1 }}>
            <>
              <ToggleButtonGroup
                value={colorState}
                size={'small'}
                onChange={handleFormat}
                aria-label="text position"
                disabled={disabledOrNot}
                fullWidth
                exclusive
                sx={{
                  '& .MuiToggleButtonGroup-grouped': {
                    textTransform: 'none',
                    lineHeight: 'normal',
                    borderRadius: 0,
                  },
                  '& .Mui-selected': {
                    background: 'grey.light',
                    fontWeight: 'bold',
                    borderRadius: 0,
                  },
                  overflow: 'auto',
                  mt: 1,
                  mb: 1,
                }}
                data-testid="top-position-comp"
              >
                <ToggleButton
                  value="normal"
                  aria-label="btn-normal"
                  data-testid="btn-normal"
                  fullWidth
                  size={'small'}
                  sx={{ maxHeight: 35 }}
                >
                  <Typography>Normal</Typography>
                </ToggleButton>
                <ToggleButton
                  value="hover"
                  aria-label="btn-hover"
                  data-testid="btn-hover"
                  fullWidth
                  size={'small'}
                  sx={{ maxHeight: 35 }}
                >
                  <Typography>Hover</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
              <Grid container spacing={2}>
                {paletteDataLoading && (
                  <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                    <LinearProgress />
                  </Grid>
                )}
                {!paletteDataLoading &&
                paletteData &&
                paletteData.length > 0 ? (
                  <Fragment>
                    {paletteData.map((color, index) => {
                      return selectedTab === 0 ? (
                        !color.includes('linear-gradient') && (
                          <Grid item key={index}>
                            <Card
                              raised
                              onClick={() => {
                                if (!disabledOrNot) {
                                  onChangeColor(color)
                                }
                              }}
                              sx={{
                                position: 'relative',
                                background: color,
                                border:
                                  value?.[selectedAttr] === color
                                    ? '3px solid #263238'
                                    : '3px solid white',
                                p: 2.5,
                                cursor: disabledOrNot
                                  ? 'not-allowed'
                                  : 'pointer',
                                borderRadius: '50%',
                                overflow: 'visible',
                              }}
                              data-testid="color-picker-card"
                            >
                              {value?.[selectedAttr] === color && (
                                <CheckBoxIcon
                                  sx={{
                                    position: 'absolute',
                                    fontSize: '1.3rem',
                                    top: 10,
                                    right: 10,
                                    color: '#263238',
                                  }}
                                />
                              )}
                            </Card>
                          </Grid>
                        )
                      ) : (
                        <Grid item key={index}>
                          <Card
                            raised
                            onClick={() => {
                              if (!disabledOrNot) {
                                onChangeColor(color)
                              }
                            }}
                            sx={{
                              position: 'relative',
                              background: color,
                              border:
                                value?.[selectedAttr] === color
                                  ? '3px solid #263238'
                                  : '3px solid white',
                              p: 2.5,
                              cursor: disabledOrNot ? 'not-allowed' : 'pointer',
                              borderRadius: '50%',
                              overflow: 'visible',
                            }}
                            data-testid="color-picker-card"
                          >
                            {value?.[selectedAttr] === color && (
                              <CheckBoxIcon
                                sx={{
                                  position: 'absolute',
                                  fontSize: '1.3rem',
                                  top: 10,
                                  right: 10,
                                  color: '#263238',
                                }}
                              />
                            )}
                          </Card>
                        </Grid>
                      )
                    })}
                  </Fragment>
                ) : (
                  <Typography variant="caption">
                    No palettes added yet!
                  </Typography>
                )}
                <Grid item>
                  <Card
                    raised
                    onClick={() => {
                      if (!disabledOrNot) {
                        onChangeColor('transparent')
                      }
                    }}
                    sx={{
                      backgroundImage: `url(${TransparentBg})`,
                      cursor: disabledOrNot ? 'not-allowed' : 'pointer',
                      p: 2.5,
                      borderRadius: '50%',
                      overflow: 'visible',
                      position: 'relative',
                      border:
                        value === 'transparent'
                          ? '3px solid #263238'
                          : '3px solid white',
                    }}
                    data-testid="color-picker-card"
                  >
                    {value === 'transparent' && (
                      <CheckBoxIcon
                        sx={{
                          position: 'absolute',
                          fontSize: '1.3rem',
                          top: 10,
                          right: 10,
                          color: 'green',
                        }}
                      />
                    )}
                  </Card>
                </Grid>
                <Grid ref={anchorRef} item>
                  <Card
                    raised
                    onClick={() => {
                      if (!disabledOrNot) {
                        setPalleteOpen(!paletteOpen)
                      }
                    }}
                    disabled={disabledOrNot}
                    sx={{
                      background: 'white',
                      cursor: disabledOrNot ? 'not-allowed' : 'pointer',
                      p: 2.5,
                      borderRadius: '50%',
                      overflow: 'visible',
                      position: 'relative',
                      border:
                        value === 'transparent'
                          ? '3px solid #263238'
                          : '3px solid white',
                    }}
                  >
                    <MoreHoriz
                      sx={{
                        position: 'absolute',
                        fontSize: '1.3rem',
                        top: 10,
                        right: 10,
                        color: 'black',
                      }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </>
          </Box>
        )}

        <Popper
          open={paletteOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{ zIndex: 2 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              sx={{ zIndex: 2 }}
              style={{
                zIndex: 2,
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={{ zIndex: 1000 }}>
                <ClickAwayListener onClickAway={() => setPalleteOpen(false)}>
                  <Box
                    sx={{
                      position: 'relative',
                      zIndex: 1000,
                      p: 1,
                      mt: 2,
                      border: '1px solid #d3d3d3',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {selectedTab === 1 ? (
                      <ColorPicker
                        width={230}
                        value={value?.[selectedAttr]}
                        onChange={onChangeColor}
                        hidePresets={true}
                        hideEyeDrop={true}
                        hideAdvancedSliders={true}
                      />
                    ) : (
                      <ChromePicker
                        color={value}
                        disabled={disabled}
                        onChangeComplete={(color, _) => {
                          onChangeColor(color.hex)
                        }}
                        disableAlpha
                        data-testid="picker"
                        gradientAllowed={false}
                      />
                    )}
                    <Divider sx={{ mt: 2, mb: 2, width: '100%' }} />
                    <Button
                      sx={{ textTransform: 'none' }}
                      variant="outlined"
                      size="small"
                      onClick={() => setPalleteOpen(false)}
                    >
                      Close
                    </Button>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  )
}

export default ButtonTextColor
