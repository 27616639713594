import {
  Box,
  Chip,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DangerousIcon from '@mui/icons-material/Dangerous'
import EngineeringIcon from '@mui/icons-material/Engineering'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const upColor = '#4caf50'
const downColor = '#f44336'
const startColor = '#e6ee9c'

const clusterHealth = (serverGroups) => {
  if (!serverGroups || serverGroups.length === 0) {
    return { status: 'empty', color: '#e0e0e0' }
  }
  const instanceFailures = serverGroups.filter(
    (group) => group?.instanceCounts?.down > 0,
  )
  const instanceStarting = serverGroups.filter(
    (group) => group?.instanceCounts?.starting > 0,
  )
  return instanceFailures.length > 0
    ? { status: 'down', color: downColor }
    : instanceStarting.length > 0
    ? { status: 'starting', color: startColor }
    : { status: 'up', color: upColor }
}

export default function HealthIndicator(props) {
  const { clusters = [], env = 'dev', application = 'stencil' } = props
  return (
    <>
      {clusters.length > 0 &&
        clusters.map((cluster, index) => {
          const { name = '', resources = {}, environment = '' } = cluster
          const { serverGroups = [] } = resources
          const health = clusterHealth(serverGroups)
          return (
            <Grid
              item
              key={index}
              sx={{
                boxShadow: 8,
                // border: '1px solid #d3d3d3',
                width: '47.5%',
                m: 1,
                display: 'flex',
                background: health.color,
                p: 1,
              }}
            >
              {serverGroups.length > 0 && (
                <Table size="small" sx={{ width: '100%', background: '#fff' }}>
                  <TableHead
                    sx={(theme) => ({
                      background: health.color,
                      color: theme.palette.getContrastText(health.color),
                    })}
                  >
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align={'center'}
                        sx={{ position: 'relative' }}
                      >
                        <Box sx={{ position: 'absolute', right: 0 }}>
                          <Tooltip title="logs">
                            <Link
                              sx={{ mr: 1 }}
                              target="_blank"
                              href={`https://tapi-tagg.prod.platform.target.com/api/applications/${application}/clusters/${name}/${environment}/logs`}
                            >
                              <OpenInNewIcon fontSize="small" />
                            </Link>
                          </Tooltip>
                          <Tooltip title={`cluster is ${health.status}`}>
                            {health.status === 'up' ? (
                              <CheckCircleIcon fontSize="small" />
                            ) : health.status === 'down' ? (
                              <DangerousIcon />
                            ) : (
                              <EngineeringIcon />
                            )}
                          </Tooltip>
                        </Box>
                        <Typography
                          variant="caption"
                          component={'h3'}
                          sx={(theme) => ({
                            color: theme.palette.getContrastText(health.color),
                          })}
                        >
                          <Chip
                            sx={{ fontWeight: 'bold', mr: 1 }}
                            size="small"
                            label={env}
                            variant="filled"
                          />
                          <strong>
                            <Link
                              href={`https://${name}.${environment}.target.com`}
                              target="_blank"
                              rel="noreferrer"
                              sx={{}}
                            >
                              {name}
                            </Link>
                          </strong>{' '}
                          cluster status{' '}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow
                      sx={{
                        '& th': { fontSize: 9.3, padding: 0.2 },
                        background: '#d3d3d3',
                      }}
                    >
                      <TableCell align="center">Server Group</TableCell>
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center">Up</TableCell>
                      <TableCell align="center">Down</TableCell>
                      <TableCell align="center">Starting</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serverGroups.map((serverGroup, index) => {
                      const { instanceCounts = {} } = serverGroup
                      const {
                        down = 0,
                        up = 0,
                        total = 0,
                        starting = 0,
                      } = instanceCounts
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            '& th': { fontSize: 9.3, padding: 0.2 },
                          }}
                        >
                          <TableCell align="center" sx={{ fontSize: 9.3 }}>
                            {serverGroup.name}
                          </TableCell>
                          <TableCell>{total}</TableCell>
                          <TableCell>
                            <Box
                              sx={(theme) => ({
                                borderRadius: '50%',
                                p: 0.1,
                                width: 25,
                                height: 25,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                background: upColor,
                                color: theme.palette.getContrastText(upColor),
                              })}
                            >
                              {up}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={(theme) => ({
                                borderRadius: '50%',
                                p: 0.1,
                                width: 25,
                                height: 25,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                background: downColor,
                                color: theme.palette.getContrastText(downColor),
                              })}
                            >
                              {down}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={(theme) => ({
                                borderRadius: '50%',
                                p: 0.1,
                                width: 25,
                                height: 25,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                background: startColor,
                                color:
                                  theme.palette.getContrastText(startColor),
                              })}
                            >
                              {starting}
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              )}
            </Grid>
          )
        })}
    </>
  )
}
