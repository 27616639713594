/**
 * @param  {string} action
 * @param  {array} actionsAllowed
 * @param  {boolean} isSuper
 * @param  {string} [overrideModuleName=null]
 */
const checkUserIsAllowed = (
  action,
  actionsAllowed,
  isSuper,
  overrideModuleName = null,
) => {
  if (isSuper) {
    return true
  }

  const specificAction =
    actionsAllowed &&
    actionsAllowed.filter((listedAction) =>
      overrideModuleName
        ? listedAction.overrideModuleName.toLowerCase() ===
            overrideModuleName.toLowerCase() &&
          listedAction.slug.toLowerCase() === action.toLowerCase()
        : listedAction.slug.toLowerCase() === action.toLowerCase(),
    )

  return specificAction &&
    specificAction.length > 0 &&
    specificAction[0].allowed
    ? true
    : false
}

/**
 * @param {object} userInfo
 * @return {boolean} returns true when user is part of product team
 */
const isProductTeamUser = (userInfo) => {
  return (
    userInfo &&
    userInfo.memberOf &&
    userInfo.memberOf.includes('APP-STE-ADMIN-PROD')
  )
}

export { checkUserIsAllowed, isProductTeamUser }
