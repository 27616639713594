import React from 'react'
import { DataGrid, enUS } from '@mui/x-data-grid'
import { useGetAllEventsQuery } from '../../../../../../../../api/event'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

const EventList = (props) => {
  const { selectedEvent, setSelectedEvent } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: events } = useGetAllEventsQuery(activeSite, {
    skip: !activeSite,
    refetchOnMountOrArgChange: true,
  })

  const columns = [
    {
      field: 'name',
      headerName: 'Event (Pick any one)',
      flex: 1,
      sortable: false,
    },
  ]

  return (
    <Box
      sx={{
        width: 600,
        height: 300,
        '& .MuiTablePagination-selectLabel': {
          mb: 0,
        },
        backgroundColor: '#FFF',
      }}
    >
      {events && (
        <DataGrid
          rows={events}
          columns={columns}
          rowHeight={50}
          disableColumnFilter
          disableColumnMenu
          sx={{
            '& .MuiDataGrid-columnHeadersInner': {
              backgroundColor: '#E6E7EA',
              color: '#333',
              padding: '0px -5px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: '#333333',
              fontSize: 14,
              fontFamily: 'Helvetica for Target',
              fontWeight: '500',
              wordWrap: 'break-word',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              padding: '0px 1px !important',
            },
          }}
          onSelectionModelChange={(newSelectionModel, ...rest) => {
            setSelectedEvent(newSelectionModel)
          }}
          selectionModel={selectedEvent}
          localeText={enUS.components.MuiDataGrid.defaultProps.localeText}
          keepNonExistentRowsSelected
        />
      )}
    </Box>
  )
}

export default EventList
