import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Preview from '../Main/Preview'
import DesktopMacIcon from '@mui/icons-material/DesktopMac'
import TabletIcon from '@mui/icons-material/Tablet'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const LoadSection = (section) => {
  const { componentWidths, components, device } = section
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {componentWidths.map((width, key) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {components[key] && (
              <Preview
                component={components[key]}
                width={width}
                hideControls={true}
                defaultVal={device}
                p={2}
              />
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default function PreviewPost(props) {
  const { open, handleClose, layout } = props
  const [device, onChangeDevice] = React.useState(false)

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', overflowY: 'hidden' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
            </Typography>
            <ToggleButtonGroup
              value={device}
              exclusive
              aria-label="text alignment"
              size={'small'}
              sx={{
                '& .MuiToggleButtonGroup-grouped': {
                  color: 'inherit',
                },
                '& .Mui-selected': {
                  background: '#000',
                },
              }}
            >
              <ToggleButton
                onClick={(_, val) => onChangeDevice(val)}
                value={false}
                aria-label="Desktop preview"
              >
                <DesktopMacIcon />
              </ToggleButton>
              <ToggleButton
                onClick={(_, val) => onChangeDevice(val)}
                value={'sm'}
                aria-label="Tablet preview"
              >
                <TabletIcon />
              </ToggleButton>
              <ToggleButton
                onClick={(_, val) => onChangeDevice(val)}
                value={'xs'}
                aria-label="Mobile preview"
              >
                <PhoneAndroidIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgb(120, 123, 124)',
          }}
        >
          <Box
            sx={{
              p: 0,
              overflowY: 'auto',
              background: '#787b7c',
              width: '65%',
            }}
          >
            {layout && layout.length === 0 ? (
              <p>Add some sections to see the preview</p>
            ) : (
              <Box className="article-body">
                {layout.map((section, key) => {
                  return <LoadSection key={key} {...section} device={device} />
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  )
}
