import React, { Fragment } from 'react'
import {
  LinearProgress,
  Grid,
  Card,
  Stack,
  Alert,
  ClickAwayListener,
  Button,
  IconButton,
  Paper,
  Popper,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import SendIcon from '@mui/icons-material/Send'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react'
import { MentionsInput, Mention } from 'react-mentions'
import moment from 'moment'
import { Markup } from 'interweave'
import CloseIcon from '@mui/icons-material/Close'

const CommentsPanel = (props) => {
  const {
    comments = [],
    userData = [],
    saveInProgress,
    pageChangesAllowed,
    addCommentAllowed,
    addComment,
    currentComment = '',
    onChangeComment,
    onEmojiClick,
    id,
    onClose,
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)

  const findMentionsAndMakeBold = (text) => {
    const regex = new RegExp(/@(\[[A-Za-z0-9\s.]+\])(\([0-9a-z]+\))/gm)
    let updatedText = text
    let m
    while ((m = regex.exec(text)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }
      const fullMatch = m[0]
      const name = m[1]
      updatedText = updatedText.replace(
        fullMatch,
        `<strong style="background:#ebedff;padding-left:5px;padding-right:5px;font-size:14px">${name
          .replace('[', '')
          .replace(']', '')}</strong>`,
      )
    }
    return updatedText
  }

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const renderUserSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused,
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0.5,
          background: focused ? '#90caf9' : '#f6f9ff',
          borderBottom: '1px solid #d3d3d3',
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <PersonOutlineIcon />
          </Avatar>
        </ListItemAvatar>
        <div>
          <Typography
            sx={{ fontSize: 12, fontWeight: 'bold', width: '100%' }}
            variant="body1"
          >
            {entry?.displayName}
          </Typography>
          <Typography sx={{ fontSize: 10 }} variant="body2">
            {entry?.title ? entry?.title : 'Unknown'}
          </Typography>
        </div>
      </Box>
    )
  }

  return (
    <>
      <List sx={{ pb: 0 }}>
        <ListItem>
          <ListItemText
            primary={`Comments`}
            primaryTypographyProps={{ fontSize: 20 }}
          ></ListItemText>
        </ListItem>
        <Divider sx={{ bgcolor: '#B5B5B5' }} />
      </List>
      <Grid
        container
        sx={{
          p: 2,
          bgcolor: '#f1f1f1',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Grid item xs={12}>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Box
              sx={{
                display: 'flex',
                minHeight: 100,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  '& textarea': {
                    border: '2px solid #6f6f6f',
                    p: 1,
                    fontSize: '14px !important',
                    minHeight: 60,
                    '&:focus': {
                      border: '2px solid #d3d3d3',
                      outline: 'none',
                    },
                  },
                  width: '100%',
                  minHeight: 100,
                }}
              >
                <Typography sx={{ display: 'inline-block' }}>
                  Post comment
                </Typography>
                <MentionsInput
                  value={currentComment}
                  onChange={onChangeComment}
                  placeholder="Enter your message"
                  disabled={!pageChangesAllowed && !addCommentAllowed}
                >
                  <Mention
                    trigger="@"
                    data={userData}
                    renderSuggestion={renderUserSuggestion}
                  />
                </MentionsInput>
              </Box>
              <IconButton
                aria-label="Add Emoticon"
                onClick={handleClick}
                edge="end"
                sx={{ height: 40 }}
                disabled={!pageChangesAllowed && !addCommentAllowed}
              >
                <InsertEmoticonIcon />
              </IconButton>
              <Paper>
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  placement={'bottom'}
                >
                  <Picker
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                    skinTone={SKIN_TONE_NEUTRAL}
                    groupNames={{ smileys_people: 'PEOPLE' }}
                    native
                  />
                </Popper>
              </Paper>
            </Box>
          </ClickAwayListener>
        </Grid>
        <Grid item>
          <Button
            endIcon={<SendIcon />}
            variant="contained"
            size="small"
            onClick={addComment}
            sx={{ textTransform: 'none', mt: 1 }}
            disabled={
              saveInProgress || (!pageChangesAllowed && !addCommentAllowed)
            }
          >
            Add
          </Button>
        </Grid>
      </Grid>
      {saveInProgress && <LinearProgress />}
      <Box sx={{ mb: 10 }}>
        {comments && comments.length > 0 ? (
          <>
            {comments &&
              comments.map((cComment, key) => {
                const { created_by, updatedAt, comment = '' } = cComment
                return (
                  <Fragment key={key}>
                    <Card
                      sx={{
                        mb: 1,
                        p: 1,
                        boxShadow: 0,
                        borderBottom: '1px solid',
                        borderRadius: 0,
                      }}
                      key={key}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{ alignItems: 'center' }}
                      >
                        <Avatar sx={{ bgcolor: 'primary' }}>
                          {created_by && created_by.displayName ? (
                            created_by.displayName.charAt(0)
                          ) : (
                            <PersonOutlineIcon />
                          )}
                        </Avatar>
                        <Box sx={{ display: 'grid' }}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {created_by && created_by.displayName}
                          </Typography>
                          <Markup content={findMentionsAndMakeBold(comment)} />
                          <Typography variant="caption">
                            {moment(updatedAt).fromNow()}
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>
                  </Fragment>
                )
              })}
          </>
        ) : (
          <>
            <Alert severity="info">No comments added yet</Alert>
          </>
        )}
      </Box>
      <Box>
        <IconButton
          type="submit"
          aria-label="close menu"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '12px',
            right: '5px',
            float: 'right',
          }}
        >
          <CloseIcon
            sx={{
              color: 'rgb(0,0,0,0.87)',
              fontSize: '2rem',
            }}
          ></CloseIcon>
        </IconButton>
      </Box>
    </>
  )
}

export default CommentsPanel
