import React from 'react'
import { Alert, AlertTitle } from '@mui/material'

export const AccessDenied = (props) => {
  const { module, type = 'error' } = props
  return (
    <Alert severity={type} sx={{ m: 2 }}>
      <AlertTitle>
        <strong>Access Denied</strong>
      </AlertTitle>
      You don't have privilege to access {module}!
      <br />
      <strong>Please contact your site administrator to get this access</strong>
    </Alert>
  )
}

export default AccessDenied
