import React from 'react'
import { Box, TextField, InputAdornment } from '@mui/material'
import Label from './Common/Label'

export const Number = (props) => {
  const {
    value,
    onChange,
    name = 'Text',
    type = 'number',
    defaultValue = '',
    endAdorment = null,
    min = 0,
    max = 100000,
    float = false,
    disabled = false,
  } = props

  const inputProps = {
    ...(endAdorment
      ? {
          endAdornment: (
            <InputAdornment position="end">{endAdorment}</InputAdornment>
          ),
          step: float ? '0.1' : '1',
        }
      : { step: float ? '0.1' : '1' }),
  }

  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'center' }}>
        <TextField
          value={value ? value : defaultValue}
          onChange={(e) =>
            onChange(float ? e.target.value : parseInt(e.target.value))
          }
          fullWidth
          type={type}
          size="small"
          min={min}
          max={max}
          InputProps={inputProps}
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}

export default Number
