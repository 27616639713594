import { Box, Grid } from '@mui/material'
import React, { useReducer } from 'react'
import MetricsCard from './MetricsCard'
import { useGetEventMetricsByYearQuery } from '../../../api/newDashboard'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const initialParticipantsCount = {
  external: {
    approved_attended: 0,
    rejected: 0,
    ytr_approved: 0,
    on_hold: 0,
    pending_approval: 0,
    blocked: 0,
    ytr_registered_manual_approved: 0,
    ytr_registered_auto_approved: 0,
    registered_auto_approved: 0,
    registered_hr_approved: 0,
    manual_entry: 0,
    blocked_attended: 0,
    on_hold_attended: 0,
    rejected_attended: 0,
    walkin: 0,
    ytr_attended: 0,
    ytr: 0,
  },
  internal: {
    approved_attended: 0,
    rejected: 0,
    ytr_approved: 0,
    on_hold: 0,
    pending_approval: 0,
    blocked: 0,
    ytr_registered_manual_approved: 0,
    ytr_registered_auto_approved: 0,
    registered_auto_approved: 0,
    registered_hr_approved: 0,
    manual_entry: 0,
    blocked_attended: 0,
    on_hold_attended: 0,
    rejected_attended: 0,
    walkin: 0,
    ytr_attended: 0,
    ytr: 0,
  },
}

const participantReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH': {
      const participantCount = {
        approved_attended: 0,
        rejected: 0,
        ytr_approved: 0,
        on_hold: 0,
        pending_approval: 0,
        blocked: 0,
        ytr_registered_manual_approved: 0,
        ytr_registered_auto_approved: 0,
        registered_auto_approved: 0,
        registered_hr_approved: 0,
        manual_entry: 0,
        blocked_attended: 0,
        on_hold_attended: 0,
        rejected_attended: 0,
        walkin: 0,
        ytr_attended: 0,
        ytr: 0,
      }
      const dataArray = []
      action?.value.forEach((item) => {
        const { internal, external } = Object.keys(item)
          .filter((key) => ['internal', 'external'].includes(key))
          .reduce((newObj, key) => {
            newObj[key] = item[key]
            return newObj
          }, {})
        dataArray.push(internal)
        dataArray.push(external)
      })
      Object.keys(participantCount).forEach((key) => {
        participantCount[key] = dataArray
          .map((item) => item[key])
          .reduce((prev, next) => prev + next)
      })
      return participantCount
    }
    default:
      return state
  }
}

const EventDashboardParticipantsCard = (props) => {
  const { eventId } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [eventData, dispatch] = useReducer(
    participantReducer,
    initialParticipantsCount,
  )

  const { data: metricsData, isFetching: isMetricsLoading } =
    useGetEventMetricsByYearQuery(
      {
        eventId: [eventId],
      },
      { skip: !(userInfo.lanId && activeSite._id) },
    )

  useEffect(() => {
    if (metricsData !== undefined) {
      dispatch({ type: 'FETCH', value: metricsData })
    }
  }, [metricsData])

  return (
    <Grid item xs>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
        }}
      >
        <MetricsCard
          title="Approved Participants"
          value={
            eventData.ytr_registered_auto_approved +
            eventData.ytr_registered_manual_approved +
            eventData.registered_auto_approved +
            eventData.registered_hr_approved +
            eventData.ytr_approved +
            eventData.manual_entry
          }
          color="#27633B"
          isLoading={isMetricsLoading}
        />
        <MetricsCard
          title="Rejected Participants"
          value={eventData.rejected}
          color="#CC0000"
          isLoading={isMetricsLoading}
        />
        <MetricsCard
          title="YTR Participants"
          value={eventData.ytr}
          color="#4318FF"
          isLoading={isMetricsLoading}
        />
        <MetricsCard
          title="On Hold Participants"
          value={eventData.on_hold}
          color="#EDB508"
          isLoading={isMetricsLoading}
        />
        <MetricsCard
          title="Pending Participants"
          value={eventData.pending_approval}
          color="#D56C15"
          isLoading={isMetricsLoading}
        />
      </Box>
    </Grid>
  )
}

export default EventDashboardParticipantsCard
