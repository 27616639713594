import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
const DeleteDialog = (props) => {
  const {
    open,
    close,
    deleteConfirmationAction,
    deleteCancelAction,
    deleteItemCount = 0,
    nonDeleteFiles = [],
  } = props
  const nonDeleteFilesCount = deleteItemCount - nonDeleteFiles.length
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => close(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {nonDeleteFiles.length > 0 && (
          <DialogTitle id="alert-dialog-title">
            <strong>{`Are you sure you want to delete ${deleteItemCount} item${
              deleteItemCount > 1 ? 's' : ''
            } ?`}</strong>
          </DialogTitle>
        )}
        <DialogContent sx={{ m: '10px 0px' }}>
          {nonDeleteFiles.length > 0 ? (
            <DialogContentText id="alert-dialog-description">
              <>
                <Typography>{`The selected media file${
                  deleteItemCount > 1 ? 's are' : ' is'
                } being used on an active page and cannot be deleted.`}</Typography>
                {nonDeleteFiles.map((e) => {
                  return (
                    <Typography sx={{ m: '10px 0px', color: '#CC0000' }}>
                      {`File Name: ${e.name}`}
                    </Typography>
                  )
                })}
              </>
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {` Are you sure want to delete ${deleteItemCount} item${
                deleteItemCount > 1 ? 's' : ''
              }? Permanently deleted items cannot be recovered.`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent:
              nonDeleteFiles.length > 0 && nonDeleteFilesCount !== 0
                ? 'space-around'
                : 'flex-end',
          }}
        >
          {nonDeleteFilesCount !== 0 && nonDeleteFiles.length > 0 && (
            <Typography
              variant="body1"
              marginRight={nonDeleteFiles.length > 0 ? 10 : 0}
            >
              <strong>Do you want to proceed?</strong>
            </Typography>
          )}
          <Box sx={{ display: 'contents' }}>
            <Button
              variant="contained"
              sx={{
                background: '#F7F7F7',
                color: 'black',
                ':hover': { backgroundColor: '#F7F7F7' },
                m: '0px 20px',
              }}
              onClick={deleteCancelAction}
              autoFocus
            >
              Cancel
            </Button>
            {nonDeleteFilesCount !== 0 && (
              <Button
                variant="contained"
                sx={{
                  m: '10px 0px',
                  background: '#3E72DB',
                  ':hover': { background: '#3E72DB' },
                }}
                onClick={deleteConfirmationAction}
              >
                Confirm
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteDialog
