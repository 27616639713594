import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Box,
  List,
  Divider,
  Grid,
  LinearProgress,
  TablePagination,
} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import CommentsItem from './CommentsItem'
import CommentsList from './CommentsList'
import {
  useGetCommentsQuery,
  useGetCommentsByIdQuery,
  useReadCommentsMutation,
} from '../api/comments'
import { scrollIntoView } from 'seamless-scroll-polyfill'

const Comments = (props) => {
  const { onClose, commentsCount } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [selectedComment, setSelectedComment] = useState({})
  const [commentsByPage, setCommentsByPage] = useState([])
  const [pageVal, setPageVal] = React.useState(0)
  const [perpage, setPerPage] = React.useState(8)

  const [commentsList, setCommentsList] = React.useState([])

  const commentItemClass = document.getElementsByClassName(
    'MuiFormControl-root',
  )[0]

  const handleCommentClick = (item) => {
    setSelectedComment(item)
    markCommentsRead({ activeSite, item })
    if (commentItemClass) {
      scrollIntoView(commentItemClass, {
        block: 'end',
        behavior: 'smooth',
      })
    }
  }

  const { data: commentsData } = useGetCommentsQuery(
    { activeSite, pageVal: pageVal + 1, perpage },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeSite,
    },
  )

  const [markCommentsRead, { isLoading: saveInProgress }] =
    useReadCommentsMutation()

  const { total } = commentsData ? commentsData : {}
  const {
    page_data = {},
    post_data = {},
    type,
    revision = '',
  } = commentsData?.data?.[0] ? commentsData.data[0] : {}
  const { name, _id } = type ? (type === 'post' ? post_data : page_data) : {}

  const { data: commentsById, isLoading } = useGetCommentsByIdQuery(
    { ...selectedComment },
    {
      refetchOnMountOrArgChange: true,
      skip: !selectedComment._id,
    },
  )

  useEffect(() => {
    if (commentsData) {
      setCommentsList(commentsData)
      setSelectedComment({ type, name, _id, revision })
    }

    return () => {
      setCommentsList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsData])

  useEffect(() => {
    if (commentsById) {
      setCommentsByPage(commentsById)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsById])

  const handleChangePage = (event, newPage) => {
    setPageVal(newPage)
    setPerPage(8)
  }

  useEffect(() => {
    if (commentsByPage) {
      if (commentItemClass) {
        scrollIntoView(commentItemClass, {
          block: 'end',
          behavior: 'smooth',
        })
      }
    }
  }, [commentItemClass, commentsByPage])

  return (
    <Box
      role="presentation"
      sx={{ height: 'calc(100vh - 64px)', overflow: 'scroll', minWidth: 665 }}
    >
      <List sx={{ pb: 0 }}>
        <ListItem>
          <ListItemButton sx={{ p: 0 }}>
            <ListItemText
              primary={`Comments(${commentsCount})`}
              primaryTypographyProps={{ fontSize: 20 }}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider sx={{ bgcolor: '#B5B5B5' }} />
      </List>

      <Divider orientation="vertical" flexItem />

      <Grid container sx={{ height: '87vh' }}>
        {saveInProgress && <LinearProgress />}
        <Grid item md={6} lg={6}>
          {commentsList &&
            commentsList?.data?.map((item, itemIndex) => {
              return (
                <CommentsList
                  key={itemIndex}
                  {...item}
                  selectedComment={selectedComment}
                  handleCommentClick={handleCommentClick}
                ></CommentsList>
              )
            })}
          {commentsList && commentsList?.data?.length > 0 ? (
            <TablePagination
              component="div"
              count={total}
              page={pageVal}
              onPageChange={handleChangePage}
              rowsPerPage={perpage}
              sx={{
                '& .MuiTablePagination-selectLabel': {
                  display: 'none',
                },
                '& .MuiTablePagination-select': {
                  display: 'none',
                },
                '& .MuiTablePagination-selectIcon': {
                  display: 'none',
                },
                '& .MuiTablePagination-spacer': {
                  display: 'none',
                },
                '& .MuiInputBase-root': {
                  display: 'none',
                },
              }}
            />
          ) : (
            ''
          )}
        </Grid>
        <Box sx={{ position: 'relative' }}>
          <Divider
            orientation="vertical"
            style={{
              position: 'absolute',
              left: '50%',
              height: '100%',
              backgroundColor: '#B5B5B5',
            }}
          />
        </Box>

        {isLoading && <LinearProgress />}
        <Grid item md={6} lg={6} id="commentsItem">
          <CommentsItem
            selectedComment={selectedComment}
            commentsByPage={commentsByPage}
            onClose={onClose}
          ></CommentsItem>
        </Grid>
      </Grid>
      <Box>
        <IconButton
          type="submit"
          aria-label="close menu"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '12px',
            right: '5px',
            float: 'right',
          }}
        >
          <CloseIcon
            sx={{
              color: 'rgb(0,0,0,0.87)',
              fontSize: '2rem',
            }}
          ></CloseIcon>
        </IconButton>
      </Box>
    </Box>
  )
}

export default Comments
