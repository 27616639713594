// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiConfig from '../apiConfig'

const { api } = apiConfig

export const commentsApi = createApi({
  reducerPath: 'comments',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const identityToken = getState().session.identityToken
      const lanId = getState().session.userInfo.lanId
      const bearer = getState().session.userInfo.accessToken
      // If we have a token set in state, let's assume that we should be passing it.
      if (bearer && lanId) {
        // headers.set('authorization', bearer)
        headers.set('lanid', lanId)
        headers.set('authorization', bearer)
        headers.set('x-api-key', api.apiKey)
        headers.set('x-id-token', identityToken)
      }
      return headers
    },
  }),
  tagTypes: ['Comments'],
  endpoints: (builder) => ({
    getComments: builder.query({
      query: ({ activeSite, pageVal, perpage }) => ({
        url: `/notifications/comments`,
        params: {
          site: activeSite._id,
          page: pageVal,
          perpage: perpage,
        },
      }),
      providesTags: ['Comments'],
    }),
    getCommentsById: builder.query({
      query: ({ _id }) => ({
        url: `/comments/${_id}`,
        params: {
          comment_type: 'user',
        },
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'Comments', id: arg.id }]
      },
    }),
    addComment: builder.mutation({
      query: ({ _id, type, comment, revision, users }) => ({
        url: `/comments/save`,
        method: 'POST',
        body: {
          id: _id,
          type,
          comment,
          users,
          revision,
          comment_type: 'user',
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Comments', id: arg.id },
      ],
    }),
    readComments: builder.mutation({
      query: ({ activeSite, item }) => ({
        url: `/notifications/comments/markread`,
        method: 'POST',
        body: {
          site: activeSite._id,
          ids: item.unread,
        },
      }),
      invalidatesTags: ['Comments'],
    }),
  }),
})

export const {
  useGetCommentsQuery,
  useGetCommentsByIdQuery,
  useAddCommentMutation,
  useReadCommentsMutation,
} = commentsApi
