import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import Label from './Common/Label'

export const Alignment = (props) => {
  const {
    name = 'Alignment',
    value,
    onChange,
    allowedValues = ['left', 'center', 'right'],
    defaultValue = 'left',
    helperText = '',
    disabled = false,
  } = props
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} helperText={helperText} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        <ToggleButtonGroup
          value={value ? value : defaultValue}
          size="small"
          exclusive
          onChange={(_, alignment) => onChange(alignment)}
          aria-label="color"
          disabled={disabled}
        >
          {allowedValues.includes('left') && (
            <ToggleButton value="left" aria-label="left aligned">
              <FormatAlignLeftIcon />
            </ToggleButton>
          )}
          {allowedValues.includes('center') && (
            <ToggleButton value="center" aria-label="centered">
              <FormatAlignCenterIcon />
            </ToggleButton>
          )}
          {allowedValues.includes('right') && (
            <ToggleButton value="right" aria-label="right aligned">
              <FormatAlignRightIcon />
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

export default Alignment
