import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MoreIcon from '@mui/icons-material/MoreVert'
import LogoutIcon from '@mui/icons-material/Logout'
import CssBaseline from '@mui/material/CssBaseline'
import LogoWhite from '../assets/Logo/Stencil_Logo-White.svg'
import { useSelector, useDispatch } from 'react-redux'
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  ListItemAvatar,
  Tooltip,
} from '@mui/material'
import { setActiveSite } from '../api/tenant'
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Zoom,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PublicIcon from '@mui/icons-material/Public'
import { enableSiteAccess } from './../api/session'
import ScienceIcon from '@mui/icons-material/Science'
import GolfCourseIcon from '@mui/icons-material/GolfCourse'
import AlertBlock from '../components/AlertBlock'
import Divider from '@mui/material/Divider'
import CommentIcon from '@mui/icons-material/Comment'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Notifications from './Notifications'
import Comments from './Comments'
import {
  useGetNotificationsQuery,
  useGetDashboardDataQuery,
} from '../api/dashboard'
import { useGetCommentsQuery } from '../api/comments'
import dashboardObject from '../Settings/dashboardSettings.json'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Buffer } from 'buffer'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const SiteSelectionDialog = (props) => {
  const { onClose, activeSite, open, sites } = props
  const [activeState, setActiveState] = React.useState({
    selected: null,
    msg: 'You are trying to switch between projects. Make sure you have saved you progress in the current project. When you switch projects unsaved data will be lost.',
  })

  const handleClose = () => {
    onClose(activeSite)
  }

  const handleListItemClick = (value) => {
    setActiveState({
      ...activeState,
      selected: activeSite?._id === value._id ? null : value,
    })
  }

  React.useEffect(() => {
    setActiveState({ ...activeState, selected: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Select a project</DialogTitle>
      <List sx={{ pt: 0, pb: 0 }}>
        {sites &&
          sites.length > 0 &&
          sites.map((site) => (
            <ListItem
              button
              onClick={() => handleListItemClick(site)}
              key={site._id}
              selected={site._id === activeSite?._id}
              divider
            >
              <ListItemAvatar>
                {site.isPlayground ? <ScienceIcon /> : <PublicIcon />}
              </ListItemAvatar>
              <ListItemText primary={site.siteName} />
              {site.isPlayground && (
                <Chip
                  label="Demo project"
                  icon={<GolfCourseIcon />}
                  size="small"
                  color="success"
                  sx={{ mr: 3 }}
                />
              )}
              <Typography variant="caption">{site.description}</Typography>
            </ListItem>
          ))}
      </List>
      {activeState.selected && (
        <Alert
          severity="warning"
          sx={{ display: 'flex', alignItems: 'center', m: 2 }}
        >
          <AlertTitle>
            <strong>Warning!</strong>
          </AlertTitle>
          {activeState.msg}
          <br />
          <strong>Selected project: {activeState.selected?.siteName}</strong>
          <br />
          <Button
            size="small"
            variant="contained"
            sx={{ textTransform: 'none', mr: 1, mt: 1 }}
            onClick={() => onClose(activeState.selected)}
          >
            Proceed, switch the project
          </Button>
          <Button
            size="small"
            variant="outlined"
            sx={{ textTransform: 'none', mt: 1 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Alert>
      )}
    </Dialog>
  )
}

export default function Header(props) {
  const { logout } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const userInfo = useSelector((state) => state.session.userInfo)
  const sites = useSelector((state) => state.tenant.sites)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const drawerOpened = useSelector((state) => state.session.sideBarOpen)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [siteSelectDialogOpen, setSiteSelectDialog] = React.useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const initialStateIcons = { notifications: false, comments: false }
  const [iconClicked, setIconClicked] = React.useState(initialStateIcons)
  const [notificationsCount, setNotificationsCount] = React.useState(0)
  const [commentsCount, setCommentsCount] = React.useState(0)
  const [dashboardData, setDashboardData] = useState(dashboardObject)
  const readStatus = 'all'
  const [cookies, setCookie] = useCookies(['active_site'])

  useEffect(() => {
    if (cookies && cookies?.active_site) {
      const activeSiteCookie = Buffer.from(
        cookies?.active_site,
        'Base64',
      ).toString('utf-8')
      if (activeSiteCookie) {
        setTimeout(() => {
          dispatch(setActiveSite(JSON.parse(activeSiteCookie)))
          dispatch(enableSiteAccess())
        }, 1000)
      }
    }
  }, [cookies, dispatch])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  function useWindowFocus() {
    const [focused, setFocus] = useState(true)
    const handleFocus = () => setFocus(true)
    const handleBlur = () => setFocus(false)

    useEffect(() => {
      window.addEventListener('focus', handleFocus)
      window.addEventListener('blur', handleBlur)
      return () => {
        window.removeEventListener('focus', handleFocus)
        window.removeEventListener('blur', handleBlur)
      }
    })

    return focused
  }

  const focused = useWindowFocus()

  const { data: notifications } = useGetNotificationsQuery(
    { activeSite, read_status: readStatus },
    {
      skip: !activeSite || !focused,
      pollingInterval: location.pathname === '/' ? 10000 : 0,
    },
  )

  const { data: commentsData } = useGetCommentsQuery(
    { activeSite },
    {
      pollingInterval: location.pathname === '/' ? 10000 : 0,
      skip: !activeSite || !focused,
    },
  )

  const { data: dashboardValues, error: dashboardError } =
    useGetDashboardDataQuery(
      { activeSite },
      {
        skip: !activeSite,
      },
    )

  useEffect(() => {
    if (dashboardValues) {
      setDashboardData(dashboardValues)
    }

    if (dashboardError) {
      setDashboardData(dashboardObject)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardValues])

  useEffect(() => {
    if (notifications) {
      setNotificationsCount(notifications.unread)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  useEffect(() => {
    if (commentsData) {
      var unreadData = 0
      unreadData = commentsData.data.reduce(function (a, b) {
        return a + b.unread.length
      }, 0)
      setCommentsCount(unreadData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsData])

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}>
        <IconButton
          size="small"
          aria-label="Logout"
          aria-haspopup="true"
          color="inherit"
        >
          <LogoutIcon />
        </IconButton>
        <Typography variant="inherit">Logout</Typography>
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={logout}>
        <IconButton
          size="small"
          aria-label="Logout"
          aria-haspopup="true"
          color="inherit"
        >
          <LogoutIcon />
        </IconButton>
        <Typography variant="inherit">Logout</Typography>
      </MenuItem>
    </Menu>
  )

  const openSiteSelection = () => {
    setSiteSelectDialog(true)
  }

  const selectSite = (val) => {
    if (val && val._id !== activeSite?._id) {
      dispatch(setActiveSite(val))
      dispatch(enableSiteAccess())
      setCookie(
        'active_site',
        Buffer.from(JSON.stringify(val), 'utf-8').toString('Base64'),
      )
    }
    setSiteSelectDialog(false)
  }

  const handleMenuHeader = (menuName) => {
    var icon = {
      ...initialStateIcons,
      [menuName]: !iconClicked[menuName],
    }
    setIconClicked(icon)
  }

  const iconBadgeStyle = (countIcon) => {
    return {
      borderRadius: '50%',
      width: countIcon <= 99 ? 18 : 20,
      height: countIcon <= 99 ? 18 : 20,
      padding: '2px',
      background: '#fff',
      color: '#000',
      border: '1px solid #303F9F',
      textAlign: 'center',
      fontSize: '9px',
      position: 'absolute',
      top: '2px',
      right: '-4px',
      fontWeight: 'bold',
    }
  }

  const iconStyle = {
    mx: 1,
    color: '#FFFFFF',
    px: '5px',
    py: 1,
    borderRadius: 0,
  }

  return (
    <>
      <Box key={'key-1'} sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box key={'key-2'} sx={{ flexGrow: 2 }}>
          <AppBar key={'key-3'} position="static">
            <Toolbar
              key={'key-4'}
              sx={{
                background: '#CC0000',
                paddingLeft: '20px !important',
                paddingRight: '5px !important',
                height: '56px !important',
                minHeight: '56px !important',
              }}
            >
              {drawerOpened ? <Box sx={{ padding: '0 80px' }}></Box> : null}
              <>
                <LogoWhite style={{}} />
              </>
              {sites && sites.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={openSiteSelection}
                  sx={{
                    borderRadius: 0.5,
                    textTransform: 'none',
                    marginLeft: 5,
                    borderColor: '#FFFFFF',
                    color: '#FFFFFF',
                  }}
                  endIcon={<ArrowDropDownIcon />}
                >
                  <b>Project : &nbsp;</b>
                  {activeSite ? activeSite?.siteName : 'No project selected'}
                </Button>
              )}
              <Box sx={{ flexGrow: 1 }} />
              {activeSite?.isPlayground && (
                <AlertBlock
                  severity="warning"
                  variant="standard"
                  size="small"
                  sx={{ mr: 10, flexGrow: 1 }}
                >
                  <strong>Demo project: </strong> Currently you are on a demo
                  project.
                  <Typography variant="caption" sx={{ ml: 1 }}>
                    The data in this project will reset in a month.
                  </Typography>
                </AlertBlock>
              )}
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex', mx: '10px' },
                }}
              >
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: '#FFFFFF', mx: '11px' }}
                />
                <Tooltip title={'Comments'}>
                  <IconButton
                    aria-label={`comments-count-${commentsCount}`}
                    aria-controls="comments-open"
                    aria-haspopup="true"
                    sx={{
                      ...iconStyle,
                      borderBottom: iconClicked['comments']
                        ? '4px solid #FFFFFF'
                        : '',
                    }}
                    size="small"
                    onClick={() => handleMenuHeader('comments')}
                  >
                    <CommentIcon />
                    <div style={iconBadgeStyle(commentsCount)}>
                      {commentsCount >= 99 ? (
                        <span style={{ position: 'relative' }}>99+</span>
                      ) : (
                        commentsCount
                      )}
                    </div>
                  </IconButton>
                </Tooltip>
                <Tooltip title={'Notifications'}>
                  <IconButton
                    aria-label={`notifications-count-${notificationsCount}`}
                    aria-controls="notifications-open"
                    aria-haspopup="true"
                    size="small"
                    sx={{
                      ...iconStyle,
                      borderBottom: iconClicked['notifications']
                        ? '4px solid #FFFFFF'
                        : '',
                    }}
                    onClick={() => handleMenuHeader('notifications')}
                  >
                    {dashboardData.notifications ||
                    dashboardData.notifications === undefined ? (
                      <>
                        <NotificationsIcon />
                        <div style={iconBadgeStyle(notificationsCount)}>
                          {notificationsCount >= 99 ? (
                            <span style={{ position: 'relative' }}>99+</span>
                          ) : (
                            notificationsCount
                          )}
                        </div>
                      </>
                    ) : (
                      <NotificationsOffIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
              <Tooltip title="Account">
                <IconButton
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  size="large"
                  sx={iconStyle}
                  onClick={handleProfileMenuOpen}
                >
                  <AccountCircle />
                  <Typography
                    sx={{ textTransform: 'none', color: 'inherit', ml: 1 }}
                  >
                    {userInfo.fullName}
                  </Typography>
                  {userInfo.company && (
                    <Typography
                      variant="caption"
                      sx={{ textTransform: 'none', color: 'inherit', ml: 1 }}
                    >
                      {userInfo?.company}
                    </Typography>
                  )}
                </IconButton>
              </Tooltip>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none', flexDirection: 'column' },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
          <SiteSelectionDialog
            open={siteSelectDialogOpen}
            onClose={selectSite}
            activeSite={activeSite}
            sites={sites}
          />
        </Box>
      </Box>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['notifications']}
        onClose={() => handleMenuHeader('notifications')}
        onOpen={() => handleMenuHeader('notifications')}
        PaperProps={{
          sx: {
            marginTop: '65px',
          },
        }}
      >
        <Notifications
          dashboardData={dashboardData}
          setDashboardData={setDashboardData}
          onClose={() => handleMenuHeader('notifications')}
        ></Notifications>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['comments']}
        onClose={() => handleMenuHeader('comments')}
        onOpen={() => handleMenuHeader('comments')}
        PaperProps={{
          sx: {
            width: '40%',
            marginTop: '65px',
          },
        }}
      >
        <Comments
          onClose={() => handleMenuHeader('comments')}
          commentsCount={commentsCount}
        ></Comments>
      </SwipeableDrawer>
    </>
  )
}
