import * as React from 'react'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Speakers from '../EventModules/Speakers/Speaker'
import { Participants, Mentorship } from '../EventModules'
import Workshop from '../EventModules/Workshop/Workshop'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Reports } from '../EventModules/Reports'
import Notifications from '../EventModules/Notifications/Notifications'

const hashMap = {
  speakers: 'Speakers',
  workshop: 'Workshop',
  mentorship: 'Mentorship',
  participants: 'Participants',
  reports: 'Reports',
  notifications: 'Notifications',
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const tabLabel = {
  textTransform: 'none',
  fontSize: '18px',
}
export const EventModuleTabs = (props) => {
  const { event = {}, editDisabled = false, rights = {} } = props
  const [value, setValue] = React.useState('Participants')
  const location = useLocation()
  const navigate = useNavigate()
  const { activities = [] } = event

  const handleChange = (e, newValue) => {
    const key = getKeyByValue(hashMap, newValue)
    navigate(`#${key}`)
  }

  useEffect(() => {
    const { hash } = location
    if (hash && value !== hashMap?.[hash.replace('#', '')]) {
      setValue(hashMap?.[hash.replace('#', '')])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              background: '#F0F0F0',
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-around',
              },
              '& .Mui-selected': {
                color: '#CC0000 !important',
                fontWeight: 'bold',
              },
              '& .MuiTabs-indicator': {
                height: 4,
                backgroundColor: '#CC0000',
              },
            }}
          >
            <Tab
              value="Participants"
              label="Participants"
              {...a11yProps(3)}
              sx={tabLabel}
            />
            <Tab
              value="Speakers"
              label="Speakers"
              {...a11yProps(0)}
              sx={tabLabel}
            />
            {activities.includes('Workshop') && (
              <Tab
                value="Workshop"
                label="Workshop"
                {...a11yProps(1)}
                sx={tabLabel}
              />
            )}
            {activities.includes('Mentorship') && (
              <Tab
                value="Mentorship"
                label="Mentorship"
                {...a11yProps(2)}
                sx={tabLabel}
              />
            )}
            <Tab
              value="Reports"
              label="Reports"
              {...a11yProps(5)}
              sx={tabLabel}
            />
            <Tab
              value="Notifications"
              label="Notifications"
              {...a11yProps(6)}
              sx={tabLabel}
            />
          </TabList>
        </Box>
        <TabPanel value={'Speakers'} sx={{ p: 0, pt: 2 }}>
          <Speakers event={event} editDisabled={editDisabled} rights={rights} />
        </TabPanel>
        <TabPanel value={'Participants'} sx={{ p: 0, pt: 2 }}>
          <Participants event={event} editDisabled={editDisabled} />
        </TabPanel>
        {activities.includes('Workshop') && (
          <TabPanel value={'Workshop'} sx={{ p: 0, py: 2 }}>
            <Workshop
              event={event}
              editDisabled={editDisabled}
              rights={rights}
            />
          </TabPanel>
        )}
        {activities.includes('Mentorship') && (
          <TabPanel value={'Mentorship'} sx={{ p: 0, py: 2 }}>
            <Mentorship
              event={event}
              editDisabled={editDisabled}
              rights={rights}
            />
          </TabPanel>
        )}
        <TabPanel value={'Notifications'} sx={{ p: 0, pt: 2 }}>
          <Notifications />
        </TabPanel>
        <TabPanel value={'Reports'} sx={{ p: 0, pt: 2 }}>
          <Reports event={event} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default EventModuleTabs
