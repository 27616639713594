import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import HourglassFullIcon from '@mui/icons-material/HourglassFull'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DraftsIcon from '@mui/icons-material/Drafts'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import {
  BANNERICON,
  CAROUSELICON,
  GRIDICON,
  CLONEICON,
  TABICON,
  TEXTBLOCKICON,
  TEXtBLOCKPOSTICON,
  IMAGEPOSTICON,
  VIDEOPOSTICON,
  ACCORDIONPOSTICON,
  GALLERYICON,
  AGENDAICON,
} from '../assets/Image'

export const fileSizeReadableFormat = (size) => {
  let i = Math.floor(Math.log(size) / Math.log(1024))
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  )
}

export const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .slice(0, 100)
}

export const snakeCaseify = (string) => {
  return string
    .trim()
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')
    .replace(/__+/g, '_')
    .replace(/^_+/, '')
    .replace(/_+$/, '')
}

export const getStatusColorCode = (status) => {
  switch (status) {
    case 'approved':
      return '#2e7d32'
    case 'pending_approval':
      return '#4527a0'
    case 'draft':
      return '#9e9e9e'
    default:
      return '#263238'
  }
}

export const getDashboradTileColorCode = (status) => {
  switch (status) {
    case 'approved':
      return {
        tileBackground: '#80CBC4',
        iconComponent: CheckCircleIcon,
        iconColor: '#80CBC4',
        iconBackground: 'rgba(128, 203, 196, 0.1)',
        iconBackgroundOnClick: '#97DFD8',
        status: 'approved',
      }
    case 'pending_approval':
      return {
        tileBackground: '#FFAB40',
        iconComponent: HourglassFullIcon,
        iconColor: '#FFAB40',
        iconBackground: 'rgba(255, 171, 64, 0.1)',
        iconBackgroundOnClick: '#FFBF6D',
        status: 'pending_approval',
      }
    case 'draft':
      return {
        tileBackground: '#B5B5B5',
        iconComponent: DraftsIcon,
        iconColor: '#B5B5B5',
        iconBackground: 'rgba(181, 181, 181, 0.1)',
        iconBackgroundOnClick: '#C9C9C9',
        status: 'draft',
      }
    case 'published':
      return {
        tileBackground: '#39BC39',
        iconComponent: PublicIcon,
        iconColor: '#008300',
        iconBackground: 'rgba(0, 131, 0, 0.1)',
        iconBackgroundOnClick: '#79D379',
        status: 'published',
      }
    case 'unpublished':
      return {
        tileBackground: '#FF772F',
        iconComponent: PublicOffIcon,
        iconColor: '#F05E16',
        iconBackground: 'rgba(240, 94, 22, 0.1)',
        iconBackgroundOnClick: '#FFA87D',
        status: 'unpublished',
      }
    case 'scheduledPublish':
      return {
        tileBackground: '#9AB431',
        iconComponent: CalendarTodayIcon,
        iconColor: '#9AB431',
        iconBackground: 'rgba(154, 180, 49, 0.1);',
        iconBackgroundOnClick: '#AFC557',
        status: 'scheduledPublish',
      }
    case 'rejected':
      return {
        tileBackground: '#FF5E5E',
        iconComponent: CancelIcon,
        iconColor: '#FE6262',
        iconBackground: 'rgba(204, 0, 0, 0.1)',
        iconBackgroundOnClick: '#FF8787',
        status: 'rejected',
      }
    case 'edited':
      return {
        tileBackground: '#30343033',
        iconComponent: EditIcon,
        iconColor: 'rgba(0, 0, 0, 0.54)',
        iconBackground: 'rgba(51, 52, 53, 0.2)',
        iconBackgroundOnClick: '#333435',
        status: 'edited',
      }

    default:
      return {
        tileBackground: '#669AFF',
        iconComponent: AssignmentIndIcon,
        iconColor: '#3E72DB',
        iconBackground: 'rgba(101, 142, 226, 0.1)',
        iconBackgroundOnClick: '#86AEFF',
        status: '',
      }
  }
}

export const getSize = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const formatDate = (
  timestamp,
  formatOption = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
) => {
  const tsToDate = new Date(timestamp).toLocaleDateString('en-IN', formatOption)
  return tsToDate
}

export const expireDate = (deletedDate) => {
  var today = new Date()
  var endDate = new Date(deletedDate)
  endDate.setDate(endDate.getDate() + 30)
  const diffTime = Math.abs(endDate.getTime() - today.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const getComponentIcons = (slug) => {
  switch (slug) {
    case 'text-block':
      return TEXTBLOCKICON
    case 'banner':
      return BANNERICON
    case 'grid':
      return GRIDICON
    case 'carousel':
      return CAROUSELICON
    case 'tab':
      return TABICON
    case 'clone':
      return CLONEICON
    case 'text':
      return TEXtBLOCKPOSTICON
    case 'image':
      return IMAGEPOSTICON
    case 'video':
      return VIDEOPOSTICON
    case 'accordion':
      return ACCORDIONPOSTICON
    case 'articleBanner':
      return BANNERICON
    case 'gridWrapper':
      return GRIDICON
    case 'clonePost':
      return CLONEICON
    case 'clonePage':
      return CLONEICON
    case 'gallery':
      return GALLERYICON
    case 'agenda':
      return AGENDAICON
    default:
      return ''
  }
}

export const downloadCSV = (data, outputFileName) => {
  var csvRows = []
  const fileType = 'text/csv;charset=utf-8;'
  if (data && data.length > 0) {
    const headers = Object.keys(data[0])
    csvRows.push(headers.join(','))
    for (const row of data) {
      const values = headers.map((header) => {
        const val = row[header]
        return `"${val}"`
      })
      csvRows.push(values.join(','))
    }
    csvRows = csvRows.join('\n')

    const blob = new Blob([csvRows], { type: fileType })
    const a = document.createElement('a')
    a.download = outputFileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }
}
