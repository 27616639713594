import React, { useMemo } from 'react'
// import FieldsLoader from './FieldsLoader'
import FieldsLoaderUpdated from './FieldsLoaderUpdated'
import { checkDynamicDataExistsFurther } from '../AttributeModifiers/Common/DataConnector/Utils'

export default function GroupLoader(props) {
  const {
    elements,
    values,
    onChangeAttr,
    multi,
    position,
    editAllowed = false,
    device = '',
    dynamic = false,
    setFieldMap,
    path = '',
  } = props

  return useMemo(() => {
    const onChangeVal = (slug, val, multi, position) => {
      onChangeAttr(slug, val, multi, position)
    }
    return (
      <>
        {elements &&
          elements.length > 0 &&
          elements.map((element, key) => {
            const dataConnected = checkDynamicDataExistsFurther(
              values,
              element.element.slug,
              path,
            )
            return (
              <React.Fragment key={key}>
                <FieldsLoaderUpdated
                  group={element}
                  values={
                    !dynamic
                      ? values && values[element.element.slug]
                        ? multi
                          ? values[element.element.slug][position]
                          : values[element.element.slug]
                        : multi
                        ? []
                        : {}
                      : dataConnected &&
                        values?.[`${element.element.slug}_dynamic_settings`]
                      ? values?.[`${element.element.slug}_dynamic_settings`]
                      : values?.[`${element.element.slug}`]
                      ? values?.[`${element.element.slug}`]
                      : {}
                  }
                  onChange={(slug, val) => {
                    onChangeVal(
                      `${element.element.slug}${
                        dynamic && dataConnected ? '_dynamic_settings' : ''
                      }${multi ? '.' + position : ''}.${slug}`,
                      val,
                    )
                    console.log(
                      `${element.element.slug}${
                        dynamic && dataConnected ? '_dynamic_settings' : ''
                      }${multi ? '.' + position : ''}`,
                    )
                  }}
                  groupNo={multi && !dynamic ? position + 1 : null}
                  editAllowed={editAllowed}
                  device={device}
                  dynamic={dynamic}
                  setFieldMap={setFieldMap}
                  path={
                    !path
                      ? element.element.slug
                      : `${path}.${element.element.slug}`
                  }
                />
              </React.Fragment>
            )
          })}
      </>
    )
  }, [
    elements,
    onChangeAttr,
    dynamic,
    values,
    multi,
    position,
    editAllowed,
    device,
    setFieldMap,
    path,
  ])
}
