import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import withRights from '../../../components/withRights'
import Grow from '@mui/material/Grow'
import ServiceHealthCard from '../MetricComponents/ServiceHealthCard'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import { useGetClusterHealthQuery } from '../../../api/product'
import { ArrowDownward } from '@mui/icons-material'

const TechMetrics = (props) => {
  const [clusterInfoUI, setClusterInfoUI] = React.useState({
    dev: [],
    prod: [],
  })
  const [clusterInfoAPI, setClusterInfoAPI] = React.useState({
    dev: [],
    prod: [],
  })

  const { data: healthMetricUI, refetch } = useGetClusterHealthQuery({
    application: 'stencil',
  })

  const { data: healthMetricAPI, refetchAPI } = useGetClusterHealthQuery({
    application: 'cmsapi',
  })

  useEffect(() => {
    if (healthMetricUI && healthMetricUI.body) {
      const { data = [] } = healthMetricUI.body
      const devClusters = data.filter((cl) => cl.environment === 'dev')
      const prodClusters = data.filter((cl) => cl.environment === 'prod')
      setClusterInfoUI({ prod: prodClusters, dev: devClusters })
    }
  }, [healthMetricUI])

  useEffect(() => {
    if (healthMetricAPI && healthMetricAPI.body) {
      const { data = [] } = healthMetricAPI.body
      const devClusters = data.filter((cl) => cl.environment === 'dev')
      const prodClusters = data.filter((cl) => cl.environment === 'prod')
      setClusterInfoAPI({ prod: prodClusters, dev: devClusters })
    }
  }, [healthMetricAPI])

  const metricData = [
    {
      name: 'UI cluster health',
      type: 'card',
      backgroundColor: '#11192a',
      icon: <MonitorHeartIcon />,
      healthStat: clusterInfoUI,
      services: [],
      refreshOption: true,
      refresh: refetch,
    },
    {
      name: 'API cluster health',
      type: 'card',
      icon: <MonitorHeartIcon />,
      backgroundColor: '#11192a',
      healthStat: clusterInfoAPI,
      services: [],
      refreshOption: true,
      refresh: refetchAPI,
    },
  ]
  return (
    <>
      <Grow in={true}>
        <Box>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              sx={{ borderBottom: '1px solid #CCCCCC' }}
              aria-controls="tech-metrics-controls"
              id="tech-metrics-panel"
              data-testid="tech-metrics-panel"
            >
              <Typography variant="h5" component="h4" sx={{ fontWeight: 500 }}>
                Tech Metrics
              </Typography>
              <Divider />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container sx={{ mt: 2 }} spacing={3}>
                {metricData
                  .filter((m) => m.type === 'card')
                  .map((metric, key) => {
                    return (
                      <Grid item key={key}>
                        <ServiceHealthCard {...metric} pos={key} />
                      </Grid>
                    )
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grow>
    </>
  )
}

const moduleAttr = {
  name: 'TechMetrics',
  actions: [],
}

export default withRights(TechMetrics, moduleAttr)
