import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
  Typography,
} from '@mui/material'
import { BUSINESS_OVERVIEW_TABLE_COLUMNS } from '../dashboardConstants'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E6E7EA',
    color: '#333333',
    fontWeight: 'bold',
  },
}))

const BusinessOverviewTableCard = (props) => {
  const { tableData } = props
  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px', height: '100%' }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {BUSINESS_OVERVIEW_TABLE_COLUMNS.map((colDetails) => (
                <StyledTableCell key={colDetails.key}>
                  {colDetails.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {BUSINESS_OVERVIEW_TABLE_COLUMNS.map(({ key }) => (
                  <TableCell
                    key={key}
                    component="th"
                    scope="row"
                    sx={{
                      minWidth: 100,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {row[key]}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default BusinessOverviewTableCard
