import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  LinearProgress,
  Chip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  useGetAllSpeakersQuery,
  useAddSpeakerMutation,
} from '../../../../api/event'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import _ from 'lodash'

const getCurrentYear = new Date().getFullYear() // current year
const listOfYears = Array.from(
  { length: 5 },
  (_, i) => getCurrentYear - i,
).reverse()

const INITIAL_FILTER_VALUES = {
  search: '',
  year: [getCurrentYear],
}

const AddExistingSpeakers = (props) => {
  const { onClose } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  // const [selectedYear, setSelectedYear] = React.useState('2023')
  const [checked, setChecked] = React.useState([])
  const [speakersList, setSpeakersList] = useState([])
  const [speakersToAdd, setSpeakersToAdd] = useState([])
  let { id } = useParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [filters, setFilters] = useState(INITIAL_FILTER_VALUES)

  const { data: speakersData, isFetching: speakersFetching } =
    useGetAllSpeakersQuery(
      { filters, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  const [
    addNewSpeaker,
    { isLoading: addSpeakerLoading, isSuccess: addSpeakerSuccess },
  ] = useAddSpeakerMutation()

  useEffect(() => {
    if (speakersData) {
      setSpeakersList(speakersData.data)
    }
  }, [speakersData])

  useEffect(() => {
    if (addSpeakerSuccess) {
      enqueueSnackbar('Speaker Added successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: (key) => (
          <>
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </>
        ),
      })
      onClose()
      setChecked([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, addSpeakerSuccess])

  // const handleChange = (event) => {
  //   setSelectedYear(event.target.value)
  // }

  const handleToggle = (e, speaker) => {
    if (e.target.checked) {
      setChecked([...checked, speaker._id])
      let list = [...speakersToAdd]
      list.push(speaker._id)
      setSpeakersToAdd(list)
    } else {
      setChecked(checked.filter((item) => item !== speaker._id))
    }
  }

  const onSelectAll = () => {
    const speakersSelected = speakersList.map((item) => item._id)
    setChecked(speakersSelected)
  }

  const onDeselectAll = () => {
    setChecked([])
  }

  const onSave = () => {
    addNewSpeaker({
      ids: speakersToAdd,
      eventid: id,
      activeSite,
    })
  }

  const onHandleFilters = (target) => {
    const { name, value } = target
    setFilters({ ...filters, [name]: value })
  }

  return (
    <Box>
      <List sx={{ height: '27vh' }}>
        <ListItem>
          <ListItemText
            primary={`Add Existing Speakers`}
            primaryTypographyProps={{ fontSize: 20, fontWeight: 'bold' }}
          ></ListItemText>
        </ListItem>
        <Divider sx={{ bgcolor: '#E6E6E6' }} />
        <ListItem>
          <ListItemText
            sx={{ pb: 0 }}
            primary={`Selected speakers will be added to this event`}
            primaryTypographyProps={{ fontSize: 14, color: '#888' }}
          ></ListItemText>
        </ListItem>
        <ListItem sx={{ justifyContent: 'flex-end', gap: '20px', pt: 0 }}>
          <ListItemText
            sx={{ flex: 'none', cursor: 'pointer' }}
            primary={`Select all`}
            primaryTypographyProps={{
              fontSize: 14,
              color: '#CC0000',
            }}
            onClick={() => onSelectAll()}
          ></ListItemText>
          <ListItemText sx={{ flex: 'none', cursor: 'pointer' }}>
            <Button
              type="text"
              sx={{
                textTransform: 'none',
                color:
                  checked.length === speakersList.length
                    ? '#CC0000'
                    : '#8B8F98',
              }}
              disabled={checked.length !== speakersList.length}
              onClick={() => onDeselectAll()}
            >
              Deselect all
            </Button>
          </ListItemText>
        </ListItem>
        <Divider sx={{ bgcolor: '#E6E6E6' }} />
        <ListItem>
          <TextField
            id="standard-bare"
            variant="outlined"
            placeholder="Search"
            value={filters.search}
            name="search"
            onChange={(e) => onHandleFilters(e.target)}
            sx={{
              width: '70%',
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
          <FormControl sx={{ alignItems: 'flex-end', flexGrow: 1 }}>
            <Select
              id="demo-simple-select"
              value={filters.year}
              name="year"
              onChange={(e) => onHandleFilters(e.target)}
              sx={{
                '& .MuiOutlinedInput-input': { padding: 1 },
                backgroundColor: '#F0F2F4',
              }}
            >
              {listOfYears &&
                listOfYears.length > 0 &&
                listOfYears.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem sx={{ justifyContent: 'flex-end', gap: '20px' }}>
          <ListItemText
            primary={
              <Box>
                <span>Total</span>{' '}
                {/* <Chip
                  style={{
                    background: '#E6E6E6',
                    color: '#3D70D6',
                    // padding: 6,
                    borderRadius: '10px',
                  }}
                >
                  {speakersList?.length}
                </Chip> */}
                <Chip label={speakersList?.length} />
              </Box>
            }
            primaryTypographyProps={{ fontSize: 16, color: '#676B74' }}
          ></ListItemText>
          <ListItemText
            sx={{ flex: 'none' }}
            primary={`Select Speaker`}
            primaryTypographyProps={{ fontSize: 16, color: '##676B74' }}
          ></ListItemText>
        </ListItem>
        <Divider sx={{ bgcolor: '#616161' }} />
      </List>
      <List
        dense
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          height: '73vh',
          overflow: 'scroll',
          mt: 4,
        }}
      >
        {(speakersFetching || addSpeakerLoading) && <LinearProgress />}
        {speakersList &&
          speakersList?.length > 0 &&
          speakersList?.map((speaker, index) => {
            const labelId = `checkbox-list-secondary-label-${index}`
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={(e) => handleToggle(e, speaker)}
                    checked={
                      !_.some(speaker.events, { event: id }) &&
                      checked.includes(speaker._id)
                    }
                    inputProps={{ 'aria-labelledby': labelId }}
                    color="success"
                    disabled={_.some(speaker.events, { event: id })}
                  />
                }
                disablePadding
              >
                <ListItemButton sx={{ mb: 1, gap: '12px' }}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: '60px', height: '60px' }}
                      alt={`Avatar n°${index + 1}`}
                      src={speaker?.meta?.engagement_image}
                    />
                  </ListItemAvatar>
                  {/* <ListItemText id={labelId} primary={`Line item ${value + 1}`} /> */}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 16, fontWeight: 600 }}
                    >
                      {speaker.user.name}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: 14 }}>
                      {speaker.user.company}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: 14 }}>
                      {speaker?.meta?.bio_link}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            )
          })}
      </List>
      <Box
        sx={{
          backgroundColor: '#F6F7F8',
          py: 2,
          position: 'absolute',
          bottom: 0,
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            sx={{
              width: 140,
              color: '#CC0000',
              border: '1px solid #CC0000',
              textTransform: 'none',
              fontSize: '16px',
              m: 2,
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              m: 2,
              width: 140,
              backgroundColor: '#CC0000',
              textTransform: 'none',
              fontSize: '16px',
            }}
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box>
        <IconButton
          type="submit"
          aria-label="close menu"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '12px',
            right: '5px',
            float: 'right',
          }}
        >
          <CloseIcon
            sx={{
              color: 'rgb(0,0,0,0.87)',
              fontSize: '2rem',
            }}
          ></CloseIcon>
        </IconButton>
      </Box>
    </Box>
  )
}

export default AddExistingSpeakers
