import { TextField } from '@mui/material'
import React from 'react'
import FormStyledInput from './FormStyledInput'
import { Link as RLink } from 'react-router-dom'

export const FormTextField = ({
  field,
  form: { touched, errors, values },
  sx,
  disableFields = false,
  openLink = false,
  showMins = false,
  ...props
}) => (
  <FormStyledInput sx={sx}>
    <TextField
      size={'small'}
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputProps: {
          min: 0,
        },
        'data-testid': field.name,
        endAdornment:
          openLink && values[field.name] !== '' ? (
            <RLink
              to={values[field.name]}
              target="_blank"
              style={{ textDecoration: 'underline', color: '#096DD9' }}
            >
              Show
            </RLink>
          ) : showMins ? (
            <span>Mins</span>
          ) : (
            ''
          ),
      }}
      disabled={disableFields}
      error={touched?.[field.name] && errors?.[field.name] !== undefined}
      color={
        touched?.[field.name] && errors?.[field.name] !== undefined
          ? 'warning'
          : 'primary'
      }
      {...field}
      {...props}
      helperText={
        touched?.[field.name] && errors?.[field.name] !== undefined
          ? errors[field.name]
          : ''
      }
    />
  </FormStyledInput>
)

export default FormTextField
