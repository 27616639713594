import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

export default function AlertBlock(props) {
  const {
    title = '',
    children,
    severity = 'info',
    sx = {},
    variant = 'standard',
    size = 'medium',
  } = props
  return (
    <Alert
      severity={severity}
      variant={variant}
      size={size}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  )
}
