import React from 'react'
import { useSelector } from 'react-redux'

export const withRights = (WrappedComponent, moduleAttr) => {
  const checkRightExist = (moduleName, listOfActions, permissions, isSuper) => {
    return listOfActions.map((action) => {
      const actionModuleName = action.overrideModuleName || moduleName
      action.allowed =
        isSuper ||
        permissions.some(
          (right) =>
            right.toLowerCase() ===
              `${actionModuleName}.${action.slug}`.toLowerCase() ||
            right.toLowerCase() === `${actionModuleName}.*`.toLowerCase(),
        )
      return action
    })
  }

  const checkModuleAccessExist = (moduleAttr, permissions) => {
    const { rights } = permissions
    if (!rights) {
      return false
    }
    const moduleList = rights
      .map((right) => right.split('.')[0])
      .filter((item, i, ar) => ar.indexOf(item) === i)

    const isSuper =
      moduleAttr && rights && rights.includes(`${moduleAttr.name}.*`)

    const rightProps = {
      moduleAccess: moduleAttr && moduleList.includes(moduleAttr.name),
      superRights: isSuper,
      permittedActions:
        moduleAttr &&
        checkRightExist(moduleAttr.name, moduleAttr.actions, rights, isSuper),
    }

    return rightProps
  }
  const Wrapped = (props) => {
    const permissions = useSelector((state) => state.tenant.permissions)
    const noSiteAccess = useSelector(
      (state) => state.session.profile.noSiteAccess,
    )
    const permissionsFetchingCompleted = useSelector(
      (state) => state.tenant.permissionsFetchingCompleted,
    )
    return (
      <>
        <WrappedComponent
          {...props}
          {...{
            rights: checkModuleAccessExist(moduleAttr, permissions),
            rightsLoading: !permissionsFetchingCompleted,
            noSiteAccess,
          }}
        />
      </>
    )
  }

  return Wrapped
}

export default withRights
