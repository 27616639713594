import React, { useState } from 'react'
import withRights from '../../../components/withRights'
import AccessDenied from '../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../utils/rightUtils'
import { Box, Grid } from '@mui/material'
import Loader from '../../../components/Loader'
// import LeftArea from './LeftArea/LeftArea'
import Main from './Main/Main'
import RightArea from './RightArea/RightArea'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useGetPostQuery, useUpdatePostMutation } from '../../../api/post'
// import {
//   useGetUsedMediaQuery,
//   useSaveUsedMediaMutation,
// } from '../../../api/page'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TopLayout from './TopLayout/TopLayout'

const PostEditor = (props) => {
  const { rights, rightsLoading } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)

  let { id } = useParams()

  const [editedLayout, setLayout] = useState([])
  const [name, setPostName] = useState('')
  const [activity, setActivity] = useState([])
  const [clickedSection, setClickedSection] = useState(null)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [selectedRowForUpdate, setSelectedRowForUpdate] = useState(null)
  const [sectionExpanded, setSectionExpanded] = useState(false)

  const selectComponentForEdit = (row, col, reset) => {
    setSelectedComponent(reset ? null : { row, col })
  }

  const {
    data: postData,
    isLoading: loadingPostData,
    isFetching: statusChangeRefetch,
    refetch: reFetchPost,
  } = useGetPostQuery(
    { id, activeSite },
    {
      refetchOnMountOrArgChange: true,
      skip: !(id && activeSite),
    },
  )

  React.useEffect(() => {
    setTimeout(() => {
      setClickedSection(null)
    }, 3000)
  }, [clickedSection])

  React.useEffect(() => {
    if (postData) {
      setLayout(postData.layout)
      setPostName(postData.title)
      setActivity([...postData.activity].reverse())
    }
  }, [postData])

  const [
    updatePostLayout,
    { isSuccess: updateLayoutSuccess, isLoading: updateInProgress },
  ] = useUpdatePostMutation()

  const onUpdatePostLayout = () => {
    const payload = {
      layout: editedLayout,
      lanId: userInfo.lanId,
      site: postData.site,
    }
    updatePostLayout({ id: postData._id, payload, activeSite })
  }

  const postActions = {
    postChangesAllowed: checkUserIsAllowed(
      'Edit',
      permittedActions,
      superRights,
    ),
    addCommentAllowed: checkUserIsAllowed(
      'Review',
      permittedActions,
      superRights,
    ),
    previewAllowed: checkUserIsAllowed(
      'Preview',
      permittedActions,
      superRights,
    ),
  }

  return (
    <Box sx={{ width: 'calc(100vw - 58px)', padding: 0 }}>
      {rightsLoading || loadingPostData ? (
        <Loader />
      ) : moduleAccess &&
        (postActions.postChangesAllowed || postActions.addCommentAllowed) ? (
        <>
          {activeSite._id === postData.site ? (
            <Box>
              <TopLayout
                id={id}
                status={postData.status}
                layout={editedLayout}
                updatePostLayout={onUpdatePostLayout}
                updateLayoutSuccess={updateLayoutSuccess}
                updateInProgress={updateInProgress}
                postData={postData}
                slug={postData ? postData.slug : null}
                refetchPost={reFetchPost}
                loadingPostData={statusChangeRefetch}
                postActions={postActions}
                postName={name}
                activity={activity}
              ></TopLayout>

              <Grid container>
                {/* <Grid item sx={{ width: '20%' }}>
              <LeftArea
                layout={editedLayout}
                setLayout={setLayout}
                selectComponentForEdit={selectComponentForEdit}
                postName={name}
                clickedSection={clickedSection}
              />
            </Grid> */}
                <Grid item sx={{ width: '75%' }}>
                  <DndProvider backend={HTML5Backend}>
                    <Main
                      id={id}
                      status={postData.status}
                      layout={editedLayout}
                      setLayout={setLayout}
                      selectComponentForEdit={selectComponentForEdit}
                      onSectionClick={setClickedSection}
                      selectedComponent={selectedComponent}
                      updatePostLayout={onUpdatePostLayout}
                      updateLayoutSuccess={updateLayoutSuccess}
                      updateInProgress={updateInProgress}
                      postData={postData}
                      slug={postData ? postData.slug : null}
                      refetchPost={reFetchPost}
                      loadingPostData={statusChangeRefetch}
                      postActions={postActions}
                      postName={name}
                      setSelectedRowForUpdate={setSelectedRowForUpdate}
                      setSectionExpanded={setSectionExpanded}
                    />
                  </DndProvider>
                </Grid>
                <Grid item sx={{ width: '25%' }}>
                  <RightArea
                    id={id}
                    setLayout={setLayout}
                    selectedComponent={selectedComponent}
                    layout={editedLayout}
                    activity={activity}
                    postActions={postActions}
                    postName={name}
                    clickedSection={clickedSection}
                    selectedRowForUpdate={selectedRowForUpdate}
                    sectionExpanded={sectionExpanded}
                    setSectionExpanded={setSectionExpanded}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <AccessDenied module="Posts" />
          )}
        </>
      ) : (
        <AccessDenied module="Posts" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Post',
  actions: [
    {
      slug: 'Edit',
      access: 'Edit',
    },
  ],
}

export default withRights(PostEditor, moduleAttr)
