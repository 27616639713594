import { FIELDS_NOT_FOR_DISPLAY } from './DataConnectorConstants'
import _ from 'lodash'

export const generateColumnsFromData = (record) => {
  if (!record) {
    return null
  }
  const keys = Object.keys(record).filter(
    (key) => !FIELDS_NOT_FOR_DISPLAY.includes(key),
  )
  const columns = keys.map((key, index) => {
    return {
      field: key,
      headerName: _.startCase(key),
      sortable: false,
      width: 125,
      minWidth: 150,
      maxWidth: 200,
    }
  })
  return columns
}

export const checkDynamicDataExistInValues = (values, slug) => {
  if (!values || !slug) {
    return 0
  }
  return (
    Object.keys(values).includes(`${slug}_dynamic`) &&
    values?.[`${slug}_dynamic`]?.dynamicDataEnabled
  )
}

export const checkDynamicDataExistsFurther = (values, slug, path) => {
  if (!values || !slug) {
    return 0
  }
  const enabled =
    Object.keys(values).includes(`${slug}_dynamic`) &&
    values?.[`${slug}_dynamic`]?.dynamicDataEnabled
  // console.log(enabled, slug, path)
  return enabled
}
