import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiConfig from '../apiConfig'

const { api } = apiConfig

export const workshopApi = createApi({
  reducerPath: 'workshop',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.host}events`,
    prepareHeaders: (headers, { getState }) => {
      const identityToken = getState().session.identityToken
      const lanId = getState().session.userInfo.lanId
      const bearer = getState().session.userInfo.accessToken
      // If we have a token set in state, let's assume that we should be passing it.
      if (bearer && lanId) {
        headers.set('lanid', lanId)
        headers.set('authorization', bearer)
        headers.set('x-api-key', api.apiKey)
        headers.set('x-id-token', identityToken)
      }
      return headers
    },
  }),
  tagTypes: ['EventWorkshop'],
  endpoints: (builder) => ({
    newWorskshop: builder.mutation({
      query: ({ workshopData, eventid }) => ({
        url: `/${eventid}/workshop`,
        method: 'POST',
        body: {
          ...workshopData,
        },
      }),
      invalidatesTags: ['EventWorkshop'],
    }),
    linkSpeakersToWorskhop: builder.mutation({
      query: ({ speakerData, eventid, workshop_id }) => ({
        url: `/${eventid}/${workshop_id}/addspeaker`,
        method: 'POST',
        body: {
          ...speakerData,
        },
      }),
      // invalidatesTags: ['Speakers'],
    }),
    removeSpeakersFromWorshop: builder.mutation({
      query: ({ ids, eventid, workshopid, activeSite }) => ({
        url: `/${eventid}/speaker/remove`,
        method: 'POST',
        body: {
          ids,
          program_id: workshopid,
          site: activeSite._id,
        },
      }),
      // invalidatesTags: ['Speakers'],
    }),
    listWorkshops: builder.query({
      query: ({ eventid, activeSite, filters }) => ({
        url: `/${eventid}/workshops`,
        method: 'GET',
        params: {
          ...filters,
          siteid: activeSite._id,
        },
      }),
      providesTags: ['Workshops'],
    }),
    listSpeakersByWorkshops: builder.query({
      query: ({ eventid, workshop_id, activeSite }) => ({
        url: `/${eventid}/${workshop_id}/listspeakers`,
        method: 'GET',
        params: {
          siteid: activeSite._id,
        },
      }),
      providesTags: ['Speakers'],
    }),
    getWorkshopById: builder.query({
      query: ({ eventId, workshopId }) => {
        return {
          url: `/${eventId}/workshop/${workshopId}`,
          method: 'GET',
        }
      },
      invalidatesTags: ['Workshops'],
    }),
    updateWorkshop: builder.mutation({
      query: ({ workshopData, eventId, workshopId }) => ({
        url: `/${eventId}/workshop/${workshopId}`,
        method: 'POST',
        body: {
          ...workshopData,
        },
      }),
      invalidatesTags: ['Workshops'],
    }),
    downloadWorskhop: builder.query({
      query: ({ eventid, activeSite }) => {
        return {
          url: `/${eventid}/exportworkshops`,
          method: 'GET',
          params: {
            siteid: activeSite._id,
          },
        }
      },
      invalidatesTags: ['Workshops'],
    }),
    getParticipants: builder.query({
      query: ({ eventid, workshop_id, activeSite }) => ({
        url: `/participants/list`,
        method: 'GET',
        params: {
          eventid: eventid,
          workshopid: workshop_id,
          siteid: activeSite._id,
          status: 'CONFIRMED,WAITING_LIST',
        },
      }),
      providesTags: ['Participants'],
    }),
    cancelBookingWorkshop: builder.mutation({
      query: ({ eventId, workshopId, memberId, activeSite }) => ({
        url: `/${eventId}/workshops/${workshopId}/cancel`,
        method: 'POST',
        params: {
          siteid: activeSite._id,
        },
        body: {
          member: memberId,
        },
      }),
      invalidatesTags: ['Participants'],
    }),
    cancelWaitingListWorkshop: builder.mutation({
      query: ({ eventId, workshopId, memberId, activeSite }) => ({
        url: `/${eventId}/workshops/${workshopId}/cancelinterest`,
        method: 'POST',
        params: {
          siteid: activeSite._id,
        },
        body: {
          member: memberId,
        },
      }),
      invalidatesTags: ['Participants', 'Workshops'],
    }),
  }),
})

export const {
  useNewWorskshopMutation,
  useLinkSpeakersToWorskhopMutation,
  useRemoveSpeakersFromWorshopMutation,
  useListWorkshopsQuery,
  useListSpeakersByWorkshopsQuery,
  useGetWorkshopByIdQuery,
  useUpdateWorkshopMutation,
  useDownloadWorskhopQuery,
  useGetParticipantsQuery,
  useCancelBookingWorkshopMutation,
  useCancelWaitingListWorkshopMutation,
} = workshopApi
