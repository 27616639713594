import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Alert, Box, Divider, Grid, Typography } from '@mui/material'
import ReviewParticipantForm from './ReviewParticipantForm'
import { FormHeading } from '../../../FormComponents'
import { useParams, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { useGetParticipantQuery } from '../../../../../api/participant'
import { Placeholder, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import ParticipantHistory from './ParticipantHistory'
import { Loader, withRights } from '../../../../../components'
import { checkUserIsAllowed } from '../../../../../utils/rightUtils'
import AccessDenied from '../../../../../Layout/AccessDenied'

export const ReviewParticipant = (props) => {
  const params = useParams()
  const { eventid = null, participantid = null } = params
  const {
    setResetFormStatus,
    rights = {},
    rightsLoading,
    noSiteAccess = false,
  } = props
  const navigate = useNavigate()
  const { moduleAccess, permittedActions, superRights } = rights
  const actionsAllowed = {
    userCanReviewParticipant: checkUserIsAllowed(
      'review',
      permittedActions,
      superRights,
    ),
  }
  const { userCanReviewParticipant } = actionsAllowed
  const [customFields, setCustomFields] = React.useState([])
  const [participant, setParticipant] = React.useState({})

  const {
    data: participantData,
    isLoading: participantIsLoading,
    error: loadError,
  } = useGetParticipantQuery(
    { id: participantid, eventid },
    {
      skip: !participantid,
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (participantData) {
      const { member = {}, participant = {}, status = {} } = participantData
      const participantInfo = {
        ...participant,
        ...member,
        status: status?.status,
      }
      setParticipant(participantInfo)
    }
  }, [participantData])

  const pathTrail = [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${eventid}#participants`,
      label: 'Event Setup',
    },
    {
      to: ``,
      label: 'Review Participant',
    },
  ]

  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess && userCanReviewParticipant ? (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }} data-testid="create-participant-wrapper">
        <Breadcrumbs trail={pathTrail} />
        <Box sx={{ mt: 2, width: '100%', height: '87vh' }}>
          {!loadError ? (
            participantIsLoading ? (
              <>
                <ProgressBar indeterminate />
                <Placeholder
                  style={{
                    flexGrow: 1,
                    marginLeft: 0,
                    marginTop: 0,
                    padding: 20,
                  }}
                >
                  <Placeholder.Rect height="80vh" width="100%" emphasized />
                </Placeholder>
              </>
            ) : (
              <>
                <FormHeading
                  text="Review Participant"
                  enableBack={true}
                  btns={[]}
                />
                <Divider variant="middle" sx={{ m: 0 }} />
                <ReviewParticipantForm
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  setResetFormStatus={setResetFormStatus}
                  initialValues={participant}
                  onCancel={() =>
                    navigate(`/events/event-setup/${eventid}#participants`)
                  }
                  onSuccess={() =>
                    navigate(`/events/event-setup/${eventid}#participants`)
                  }
                  eventid={eventid}
                >
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}
                    >
                      Approval History
                    </Typography>
                    <Box
                      sx={{
                        border: '2px dashed #B5B5B5',
                        p: 2,
                        minHeight: 600,
                      }}
                    >
                      <ParticipantHistory
                        participantid={participantid}
                        eventid={eventid}
                      />
                    </Box>
                  </Grid>
                </ReviewParticipantForm>
              </>
            )
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Alert severity="warning">
                Unable to load the participant details! Please try again by
                refreshing the page. if issue persists reach out to product team
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <AccessDenied module="Participants" />
    </Box>
  )
}

ReviewParticipant.propTypes = {}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Review',
      access: 'Review',
    },
  ],
}

export default withRights(ReviewParticipant, moduleAttr)
