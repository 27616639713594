import { Box, Divider, Button, LinearProgress, Alert } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { FormHeading } from '../../../FormComponents'
import FormForEdit from './FormForEdit'
import FormForEditingMentors from './FormForEditingMentors'
import { useParams, useNavigate } from 'react-router-dom'
import { useListSpeakersByWorkshopsQuery } from '../../../../../api/workshop'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  useGetEventQuery,
  useListSpeakerByEventQuery,
} from '../../../../../api/event'

const pathTrail = (id) => {
  return [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${id}`,
      label: 'Event Setup',
    },
    {
      to: '',
      label: 'Edit Mentorship',
    },
  ]
}

/**
 *
 * TODO: Test case needs to be added
 */
export const EditMentorship = (props) => {
  const [customFields, setCustomFields] = React.useState([])
  const [resetFormNow, setResetFormStatus] = React.useState(false)
  const [disableFields, setDisableFields] = React.useState(true)
  const [mentorsToAdd, setMentorsToAdd] = React.useState([])
  const [mentorshipData, setMentorshipData] = React.useState([])
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [mentorsForDropDown, setMentorsForDropDown] = React.useState([])
  const [mentorsListData, setMentorsListData] = React.useState([])
  const [eventData, setEventData] = React.useState(null)
  const [speakersSavedList, setSpeakersSavedList] = React.useState([])
  let { eventId, mentorshipId } = useParams()
  const navigate = useNavigate()
  const resetRef = useRef()
  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }

  const { data: event, error: eventLoadError } = useGetEventQuery(eventId, {
    skip: !eventId,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (event) {
      setEventData(event)
    }
  }, [event])

  useEffect(() => {
    if (resetFormNow) {
      resetRef.current.click()
      setResetFormStatus(false)
    }
  }, [resetFormNow, resetRef, setResetFormStatus])

  const resetForm = () => {
    setResetFormStatus(true)
  }

  const { data: mentorshipDetails, isLoading: mentorshipIsLoading } =
    useListSpeakersByWorkshopsQuery(
      { eventid: eventId, workshop_id: mentorshipId, activeSite },
      {
        skip: !mentorshipId || !activeSite,
        refetchOnMountOrArgChange: true,
      },
    )

  useEffect(() => {
    if (mentorshipDetails) {
      setMentorshipData({
        ...mentorshipDetails.mentorship,
      })
      let speakerData = []
      speakerData = mentorshipDetails.data?.map((speaker) => {
        let item = {}
        item.name = [speaker.member]
        item.designation = speaker?.speaker_meta?.designation
        item.company = speaker?.member_data?.company
        item.email = speaker?.member_data?.email
        item.sessions = speaker?.sessions.map((item) => {
          return {
            ...item,
            start_time: moment(item.start_time, ['h:mm']).toDate(),
            end_time: moment(item.end_time, ['h:mm']).toDate(),
          }
        })
        item.engagement_image = speaker?.speaker_meta?.engagement_image
        item.description = speaker?.speaker_meta?.bio
        item.id = speaker.member
        item.confirmed = speaker.confirmed

        return item
      })

      if (speakerData.length === 0) {
        let temp = []
        temp.push({})
        //temp.push(`mentor${speakerData.length + 1}`)
        setMentorsToAdd(temp)
      } else {
        setMentorsToAdd(speakerData)
        setSpeakersSavedList(speakerData)
      }
      if (mentorshipDetails?.mentorship?.customFields) {
        setCustomFields([...mentorshipDetails.mentorship.customFields])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorshipDetails])

  const { data: mentorsList, isLoading: mentorsLoading } =
    useListSpeakerByEventQuery(
      { eventid: eventId, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  var mentorObj = {}

  useEffect(() => {
    if (mentorsList) {
      let data = mentorsList.data.map(
        (item) => (item = { name: item.user.name, id: item.user._id }),
      )
      mentorsList.data.map((item) => {
        return (mentorObj[item.user._id] = item)
      })

      setMentorsListData(mentorObj)
      setMentorsForDropDown(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorsList])

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }}>
        <Breadcrumbs trail={pathTrail(eventId)} />
        <Box sx={{ mt: 2, width: '100%', background: '#F6F7F8' }}>
          <FormHeading
            text="Edit Mentorship"
            btns={[
              {
                label: 'Add new input',
                type: 'select',
                cb: addNewInput,
                icon: 'Add',
                options: [
                  { label: 'Text input', value: 'text' },
                  { label: 'Date input', value: 'date' },
                  { label: 'Switch input', value: 'switch' },
                ],
              },
              { label: 'Reset', cb: resetForm, icon: 'Replay' },
            ]}
          />
          <Divider variant="middle" sx={{ m: 0.5 }} />
          {!eventLoadError ? (
            <>
              <Box container sx={{ display: 'flex', background: '#F6F7F8' }}>
                {mentorshipIsLoading && <LinearProgress></LinearProgress>}
                <FormForEdit
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  setDisableFields={setDisableFields}
                  eventId={eventId}
                  initialValues={mentorshipData}
                  mentorshipId={mentorshipId}
                  event={eventData}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <FormForEditingMentors
                    disableFields={disableFields}
                    mentorsToAdd={mentorsToAdd}
                    setMentorsToAdd={setMentorsToAdd}
                    eventId={eventId}
                    mentorshipId={mentorshipId}
                    mentorsForDropDown={mentorsForDropDown}
                    mentorsListData={mentorsListData}
                    mentorsLoading={mentorsLoading}
                    mentorshipData={mentorshipData}
                    speakersSavedList={speakersSavedList}
                    setSpeakersSavedList={setSpeakersSavedList}
                  ></FormForEditingMentors>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  bottom: 0,
                  background: '#fff',
                  p: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                  }}
                  color="secondary"
                  type="button"
                  className="btn btn-primary btn-block mt-4"
                  onClick={() => navigate(-1)}
                >
                  Done
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                    mr: 1,
                  }}
                  className="btn btn-primary btn-block mt-4"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="reset"
                  sx={{
                    display: 'none',
                  }}
                  ref={resetRef}
                >
                  reset
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Alert severity="error">
                Unable to load event information! try reload the pageagain
              </Alert>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EditMentorship
