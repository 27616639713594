import {
  Paper,
  Typography,
  Stack,
  Grid,
  Box,
  Skeleton,
  IconButton,
  Select,
  SvgIcon,
  MenuItem,
} from '@mui/material'
import React, { useReducer } from 'react'
import { useGetEventMetricsByYearQuery } from '../../../api/newDashboard'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { DOWNLOADICON } from '../../../assets/Image'
import moment from 'moment'
import { downloadCSV } from '../../../utils/helper'

const generateChartDataByYear = (metrics) => {
  const chartData = []
  Array.isArray(metrics) &&
    metrics?.forEach((metric) => {
      chartData.push({
        Date: metric?.date,
        New_Participants: metric?.new,
        YTR: metric?.ytr,
        Count: metric?.count,
      })
    })
  return chartData
}

const initialParticipantsCount = {
  internal: 0,
  external: 0,
}

const participantReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH': {
      const dataArray = []
      action?.value.forEach((item, i) => {
        const { registered_by_date } = Object.keys(item)
          .filter((key) => ['registered_by_date'].includes(key))
          .reduce((newObj, key) => {
            newObj[key] = item[key]
            return newObj
          }, {})
        dataArray.push(...registered_by_date)
      })
      return dataArray
    }

    default:
      return state
  }
}

const onTrackByRegistration = (data, selectedYear, selectedMonth) => {
  const filteredArray = data?.filter(
    (i) =>
      i?.date?.indexOf(selectedYear) > -1 &&
      (selectedMonth === 'All' ||
        i?.date
          ?.split('-')[1]
          ?.indexOf(
            `${
              getKeyByValue(month, selectedMonth) > 9
                ? getKeyByValue(month, selectedMonth)
                : `0${getKeyByValue(month, selectedMonth)}`
            }`,
          ) > -1),
  )
  return filteredArray.length > 0
    ? filteredArray.reduce((acc, item) => {
        return (acc = acc + item?.count)
      }, 0)
    : 0
}

const month = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

const EventDashboardRegistrationCount = (props) => {
  const { allYearData } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { isLoading } = props
  const [selectedYear, setSelectedYear] = useState(moment().year()) // TODO this should be change with All year
  const [selectedMonth, setSelectedMonth] = useState('All') // TODO this should be change with All year
  const [eventData, dispatch] = useReducer(
    participantReducer,
    initialParticipantsCount,
  )

  const { data: metricsData } = useGetEventMetricsByYearQuery(
    {
      eventId:
        // selectedYear === 'All Year'
        //   ? [...Object.values(allYearData).sort().reverse()]
        //   :
        [allYearData[selectedYear]],
    },
    {
      skip: !(userInfo.lanId && activeSite._id),
    },
  )

  useEffect(() => {
    if (metricsData !== undefined) {
      dispatch({ type: 'FETCH', value: metricsData, allYearData: allYearData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsData])
  return (
    <Grid item xs>
      <Paper
        sx={{
          borderRadius: '20px',
          padding: '20px',
          height: '100%',
          minHeight: '400px',
          maxHeight: '400px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Stack direction="row" gap={1}>
              <IconButton
                onClick={() => {
                  downloadCSV(
                    generateChartDataByYear(eventData),
                    `registration_count_${selectedYear}`,
                  )
                }}
              >
                <img
                  src={DOWNLOADICON}
                  alt="download-report"
                  height="33px"
                  width="33px"
                />
              </IconButton>
              <div>
                <Typography fontWeight={700} color="#2B3674" fontSize={'26px'}>
                  {Array.isArray(eventData) &&
                    eventData.length > 0 &&
                    onTrackByRegistration(
                      eventData,
                      selectedYear,
                      selectedMonth,
                    )}
                </Typography>
                <Typography fontWeight={400} color="#A3AED0" fontSize={'10px'}>
                  <b>{`Total Registration on ${
                    selectedMonth === 'All' ? '' : selectedMonth
                  }'${selectedYear}`}</b>
                </Typography>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <div
              style={{
                display: 'flex',
                overflowX: 'scroll',
                padding: '5px 0',
                gap: '5px',
              }}
            ></div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ display: 'flex' }}>
              <Box display="flex" justifyContent="flex-end" mr={'5px'}>
                <Select
                  sx={{ width: 'auto', height: '30px' }}
                  value={selectedYear}
                  onChange={(event) => {
                    setSelectedYear(event.target.value)
                  }}
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <SvgIcon color="primary" fontSize="small">
                          <CalendarTodayIcon />
                        </SvgIcon>
                        {value}
                      </Box>
                    )
                  }}
                >
                  {[...Object.keys(allYearData).sort().reverse()].map(
                    (year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Select
                  sx={{ width: 'auto', height: '30px', minWidth: 150 }}
                  value={selectedMonth}
                  onChange={(event) => {
                    setSelectedMonth(event.target.value)
                  }}
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <SvgIcon color="primary" fontSize="small">
                          <CalendarTodayIcon />
                        </SvgIcon>
                        {value}
                      </Box>
                    )
                  }}
                >
                  {['All', ...Object.values(month)].map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Box mt={2}>
              {Array.isArray(eventData) &&
                eventData?.filter(
                  (i) =>
                    i?.date?.indexOf(selectedYear) > -1 &&
                    (selectedMonth === 'All' ||
                      i?.date
                        ?.split('-')[1]
                        ?.indexOf(
                          `${
                            getKeyByValue(month, selectedMonth) > 9
                              ? getKeyByValue(month, selectedMonth)
                              : `0${getKeyByValue(month, selectedMonth)}`
                          }`,
                        ) > -1),
                ).length !== 0 && (
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ pb: '10px', pl: '10px' }}
                  >
                    <CheckCircleRoundedIcon sx={{ color: '#05CD99' }} />
                    <Typography fontWeight="bold" color="#05CD99">
                      On track
                    </Typography>
                  </Stack>
                )}
              <Box
                sx={{
                  height: 180,
                  overflowY: 'scroll',
                  paddingRight: '15px',
                }}
              >
                {Array.isArray(eventData) &&
                  eventData
                    ?.filter(
                      (i) =>
                        i?.date?.indexOf(selectedYear) > -1 &&
                        (selectedMonth === 'All' ||
                          i?.date
                            ?.split('-')[1]
                            ?.indexOf(
                              `${
                                getKeyByValue(month, selectedMonth) > 9
                                  ? getKeyByValue(month, selectedMonth)
                                  : `0${getKeyByValue(month, selectedMonth)}`
                              }`,
                            ) > -1),
                    )
                    .map((item, index) => (
                      <Stack
                        key={`ws-${index}`}
                        direction="row"
                        gap={1}
                        alignItems="center"
                        sx={{ pl: '15px' }}
                      >
                        <CircleRoundedIcon
                          sx={{ color: '#188686', fontSize: '8px' }}
                        />
                        <Typography
                          fontSize="12px"
                          color="rgb(163, 174, 208)"
                          flexGrow={1}
                          noWrap
                          sx={{
                            display: 'inline-block',
                            width: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden !important',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item?.date}
                        </Typography>
                        <Typography
                          fontSize="18px"
                          fontWeight="bold"
                          color="#2B3674"
                        >
                          {isLoading ? (
                            <Skeleton width={30} />
                          ) : eventData ? (
                            item?.count
                          ) : (
                            '0'
                          )}
                        </Typography>
                      </Stack>
                    ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
              {/* TODO render different bar chart for all year data */}
              {/* {selectedYear === 'All Year' ? (
                <BarChart
                  width={500}
                  height={300}
                  data={generateChartDataAllYear(metricsData, allYearData)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey={'year'} />
                  <YAxis />
                  <Tooltip cursor={{ fill: 'transparent' }} />
                  {currentYearWorkshops.map((ws) => {
                    return (
                      <>
                        <Bar
                          dataKey={`${ws?.REGISTERED}`}
                          name={`${ws?.name}`}
                          key={`${ws?.name}`}
                          barSize={30}
                          fill={'#4646B9'}
                          label={{ position: 'top' }}
                        />
                        <Bar
                          dataKey={`${ws.APPROVED}`}
                          name={`${ws?.name}`}
                          key={`${ws?.name}`}
                          barSize={30}
                          fill={'#FACE1E'}
                          label={{ position: 'top' }}
                        />
                      </>
                    )
                  })}
                </BarChart>
              ) :  */}
              {Array.isArray(eventData) &&
              eventData?.filter(
                (i) =>
                  i?.date?.indexOf(selectedYear) > -1 &&
                  (selectedMonth === 'All' ||
                    i?.date
                      ?.split('-')[1]
                      ?.indexOf(
                        `${
                          getKeyByValue(month, selectedMonth) > 9
                            ? getKeyByValue(month, selectedMonth)
                            : `0${getKeyByValue(month, selectedMonth)}`
                        }`,
                      ) > -1),
              ).length === 0 ? (
                <div style={{ display: 'flex', alignContent: 'center' }}>
                  No Data :(
                </div>
              ) : (
                <BarChart
                  width={500}
                  height={300}
                  data={
                    Array.isArray(eventData)
                      ? eventData?.filter(
                          (i) =>
                            i?.date?.indexOf(selectedYear) > -1 &&
                            (selectedMonth === 'All' ||
                              i?.date
                                ?.split('-')[1]
                                ?.indexOf(
                                  `${
                                    getKeyByValue(month, selectedMonth) > 9
                                      ? getKeyByValue(month, selectedMonth)
                                      : `0${getKeyByValue(
                                          month,
                                          selectedMonth,
                                        )}`
                                  }`,
                                ) > -1),
                        )
                      : []
                  }
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey={'date'}
                    // label={
                    //   <Label axisType="yAxis">{`Re (${selectedYear})`}</Label>
                    // }
                    height={40}
                    angle={-40}
                    interval={0}
                    fontSize={10}
                    dy={10}
                  />
                  <YAxis
                    label={
                      <Label axisType="xAxis" angle={270}>
                        Count
                      </Label>
                    }
                  />
                  <Tooltip cursor={{ fill: 'transparent' }} />
                  <Bar
                    dataKey={'new'}
                    barSize={30}
                    fill={'#4646B9'}
                    stackId={'a'}
                    label={{ position: 'right', fill: '#fff' }}
                  />

                  <Bar
                    dataKey={'ytr'}
                    barSize={30}
                    fill={'#FACE1E'}
                    stackId={'a'}
                    label={{ position: 'top', fill: '#000' }}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default EventDashboardRegistrationCount
