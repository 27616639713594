import React from 'react'
import { Box, Typography, Divider, LinearProgress } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { getDashboradTileColorCode } from '../utils/helper'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { formatDate } from '../utils/helper'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import {
  useReadNotificationsMutation,
  useDeleteNotificationsMutation,
  useUnreadNotificationsMutation,
} from '../api/dashboard'

const NotificationItem = (props) => {
  const {
    status,
    page_data,
    post_data,
    createdAt,
    updated_by,
    revision,
    type,
    page,
    read,
    _id,
    onClose,
  } = props
  const IconComponent = getDashboradTileColorCode(status).iconComponent
  const userId = useSelector((state) => state?.session?.profile?._id)
  const activeSite = useSelector((state) => state.tenant.activeSite)

  const navigate = useNavigate()

  const [readNotifications, { isLoading: saveInProgress }] =
    useReadNotificationsMutation()

  const [unreadNotifications, { isLoading: unreadNotificationProgress }] =
    useUnreadNotificationsMutation()

  const [deleteNotifications, { isLoading: deleteNotificationsProgress }] =
    useDeleteNotificationsMutation()

  const action = () => {
    var editUrl =
      type === 'page'
        ? revision
          ? `/pages/layout/${revision}`
          : `/pages/update/${page_data._id}`
        : `/posts/design/${post_data._id}`
    navigate(editUrl)
  }

  const notificationAction = () => {
    var editUrl =
      type === 'page'
        ? revision
          ? `/pages/layout/${revision}`
          : `/pages/update/${page_data._id}`
        : `/posts/design/${post_data._id}`
    navigate(editUrl)
    readNotifications({ _id, activeSite })
    onClose()
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e, _id, item) => {
    if (item !== undefined) {
      if (item === 'delete') {
        deleteNotifications({ _id, activeSite })
      } else if (item === 'markUnread') {
        unreadNotifications({ _id, activeSite })
      } else {
        readNotifications({ _id, activeSite })
      }
    }

    setAnchorEl(null)
  }

  const subHeadingContent = (status) => {
    if (status === 'edited') {
      return userId === updated_by?._id
        ? 'you edited the page'
        : `${updated_by?.displayName} edited the page`
    } else if (status === 'published') {
      return userId === updated_by?._id
        ? 'you published the page'
        : `${updated_by?.displayName} published the page`
    } else if (status === 'unpublished') {
      return userId === updated_by?._id
        ? 'you unpublished the page'
        : `${updated_by?.displayName} unpublished the page`
    } else {
      return status
    }
  }

  const subHeadingContentNotification = (status) => {
    switch (status) {
      case 'pending_approval':
        return {
          subHeader: 'requires approval from you',
          authorPerson: 'Requested by',
        }
      case 'approved':
        return {
          subHeader: 'has been approved',
          authorPerson: 'Assigned by',
        }
      case 'edited':
        return {
          subHeader: 'New comment is added',
          authorPerson: 'by',
        }
      case 'published':
        return {
          subHeader: 'is now published',
          authorPerson: 'Published by',
        }

      case 'rejected':
        return {
          subHeader: 'has been rejected',
          authorPerson: 'by',
        }

      case 'draft':
        return {
          subHeader: 'Your new page is created',
          authorPerson: 'by',
        }

      default:
        return {
          subHeader: 'Your new page is created',
          authorPerson: 'by',
        }
    }
  }
  return (
    <>
      {saveInProgress ||
        unreadNotificationProgress ||
        (deleteNotificationsProgress && <LinearProgress />)}
      <Card
        sx={{
          maxWidth: 345,
          overflow: 'initial',
          background:
            page === 'notification'
              ? !read
                ? 'rgba(9, 109, 217, 0.1)'
                : ''
              : '',
          '& .MuiCardContent-root': {
            px: 1,
          },
        }}
      >
        <CardHeader
          sx={{
            px: 1,
            '& .MuiCardHeader-content ': {
              px: 1,
            },
            '& .MuiCardHeader-subheader': {
              color: '#303F9F',
            },
            '& .MuiCardHeader-title': {
              whiteSpace: 'noWrap',
              width: '230px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            alignItems: 'flex-start',
          }}
          avatar={
            <Avatar
              sx={{
                background: getDashboradTileColorCode(status).iconBackground,
              }}
              variant="square"
            >
              <IconComponent
                style={{
                  color: getDashboradTileColorCode(status).iconColor,
                }}
              ></IconComponent>
            </Avatar>
          }
          action={
            page === 'notification' ? (
              <>
                <IconButton aria-label="settings" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e) => handleClose(e)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {read ? (
                    <MenuItem
                      onClick={(e) => handleClose(e, _id, 'markUnread')}
                    >
                      Mark Unread{read}
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={(e) => handleClose(e, _id, 'markRead')}>
                      Mark Read
                    </MenuItem>
                  )}
                  <MenuItem onClick={(e) => handleClose(e, _id, 'delete')}>
                    Delete
                  </MenuItem>
                </Menu>
              </>
            ) : (
              ''
            )
          }
          title={type === 'page' ? page_data?.name : post_data?.title}
          subheader={
            <>
              <Typography
                noWrap={true}
                sx={{
                  fontFamily: 'Helvetica For Target',
                  fontSize: '12px',
                }}
              >
                {page === 'notification'
                  ? subHeadingContentNotification(status).subHeader
                  : subHeadingContent(status)}
              </Typography>
              <Box
                onClick={page === 'notification' ? notificationAction : action}
                sx={{ cursor: 'pointer' }}
              >
                <span>Go to the {type === 'page' ? 'page' : 'post'}</span>
                <IconButton
                  disableTouchRipple
                  disableFocusRipple
                  sx={{
                    color: '#303F9F',
                    ml: 1,
                    ':focus': { border: '2px dashed #666666' },
                  }}
                >
                  <ArrowForwardIcon></ArrowForwardIcon>
                </IconButton>
              </Box>
            </>
          }
        />
        <CardContent sx={{ pb: '10px !important', pt: '0 !important' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="caption"
              sx={{
                color: '#5A5A5A',
                fontSize: 12,
                marginRight: '10px',
                alignSelf: 'end',
              }}
            >
              {`${formatDate(createdAt)}`}
            </Typography>
            {/* <Typography
              variant="caption"
              sx={{ color: '#5A5A5A', fontSize: 12, textAlign: 'end', flex: 1 }}
            >
              {page === 'notification'
                ? `${subHeadingContentNotification(status).authorPerson}:${
                    updated_by?.displayName
                  }`
                : `Assigned by: ${updated_by?.displayName}`}
            </Typography> */}
          </Box>
        </CardContent>
      </Card>
      <Divider />
    </>
  )
}

export default NotificationItem
