import React, { useMemo } from 'react'
import FieldsLoader from '../../../../Pages/PageEditor/RightArea/EditComponent/FieldsLoaderUpdated'

export default function GroupLoader(props) {
  const {
    elements,
    values,
    onChangeAttr,
    multi,
    position,
    editAllowed = false,
    device = '',
  } = props

  return useMemo(() => {
    const onChangeVal = (slug, val, multi, position) => {
      onChangeAttr(slug, val, multi, position)
    }
    return (
      <>
        {elements &&
          elements.length > 0 &&
          elements.map((element, key) => {
            return (
              <React.Fragment key={key}>
                <FieldsLoader
                  group={element}
                  values={
                    values && values[element.element.slug]
                      ? multi
                        ? values[element.element.slug][position]
                        : values[element.element.slug]
                      : multi
                      ? []
                      : {}
                  }
                  onChange={(slug, val) =>
                    onChangeVal(
                      `${element.element.slug}${
                        multi ? '.' + position : ''
                      }.${slug}`,
                      val,
                    )
                  }
                  groupNo={multi ? position + 1 : null}
                  editAllowed={editAllowed}
                  device={device}
                />
              </React.Fragment>
            )
          })}
      </>
    )
  }, [elements, onChangeAttr, values, multi, position, editAllowed, device])
}
