import {
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material'

import CheckIcon from '../../../assets/Image/check_icon.svg'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E6E7EA',
    color: '#333333',
    fontWeight: 'bold',
  },
}))

const MobileUsersCard = (props) => {
  const { data } = props
  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
        <CheckIcon style={{ margin: '2px' }} />
        <h4 style={{ flex: '1 1 100%', margin: '0 10px' }}>Mobile Users</h4>
      </div>
      <Grid container spacing={2}>
        {data &&
          ['ios', 'android'].map((os_name) => (
            <Grid item key={os_name} sm={6}>
              <TableContainer sx={{ maxHeight: 350 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        {os_name === 'ios' ? 'IOS' : 'Android'}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontSize: '20px' }}>
                        {data[`${os_name}_count`] || 0}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontSize: '20px' }}>
                        {data[`${os_name}_percentage`] || 0}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[`${os_name}_details`]?.map((row) => (
                      <TableRow
                        key={row.device_name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ minWidth: 100 }}
                        >
                          {row.device_name}
                        </TableCell>
                        <TableCell sx={{ minWidth: 100 }}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Chip
                              label={`${row.device_percentage}%`}
                              size="small"
                            />
                            <LinearProgress
                              variant="determinate"
                              value={row.device_percentage}
                              sx={{
                                marginLeft: '5px',
                                flex: '1 1 100%',
                                height: '7px',
                                borderRadius: '7px',
                                backgroundColor: '#F7F7F7',
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: '#3E72DB',
                                  borderRadius: '7px',
                                },
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell sx={{ minWidth: 50 }}>
                          {row.device_count}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))}
      </Grid>
    </Paper>
  )
}

export default MobileUsersCard
