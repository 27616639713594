import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { pokemonApi } from './api/pokemon'
import { profileApi } from './api/profile'
import { usersMock } from './api/usersMock'
import { usersApi } from './api/users'
import { roleApi } from './api/role'
import { sessionSlice } from './api/session'
import { tenantSlice } from './api/tenant'
import { pageApi } from './api/page'
import { componentApi } from './api/component'
import { mediaApi } from './api/mediaLib'
import { menuApi } from './api/menu'
import { postApi } from './api/post'
import { taxonomyApi } from './api/taxonomy'
import { configurationApi } from './api/configuration'
import { dashboardApi } from './api/dashboard'
import { newDashboardApi } from './api/newDashboard'
import { reportsApi } from './api/reports'
import { commentsApi } from './api/comments'
import { productApi } from './api/product'
import { eventApi } from './api/event'
import { participantApi } from './api/participant'
import { workshopApi } from './api/workshop'
import { mentorshipapi } from './api/mentorship'
import { dataConnectApi } from './api/dataconnect'
import { axiosMiddleware } from './utils/reduxInterceptor'
import { DynamicContent } from 'enterprise-stencil'
import { emailerApi } from './api/emailer'
import { pageLayoutSlice } from './api/pageLayout'
// import userReducer from './Modules/Users/UsersSlice'

const rootReducer = {
  [pokemonApi.reducerPath]: pokemonApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [usersMock.reducerPath]: usersMock.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [tenantSlice.name]: tenantSlice.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [pageApi.reducerPath]: pageApi.reducer,
  [componentApi.reducerPath]: componentApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [menuApi.reducerPath]: menuApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [taxonomyApi.reducerPath]: taxonomyApi.reducer,
  [configurationApi.reducerPath]: configurationApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [newDashboardApi.reducerPath]: newDashboardApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [participantApi.reducerPath]: participantApi.reducer,
  [workshopApi.reducerPath]: workshopApi.reducer,
  [mentorshipapi.reducerPath]: mentorshipapi.reducer,
  [dataConnectApi.reducerPath]: dataConnectApi.reducer,
  [DynamicContent.reducerPath]: DynamicContent.reducer,
  [emailerApi.reducerPath]: emailerApi.reducer,
  [pageLayoutSlice.name]: pageLayoutSlice.reducer,
}

const middlewares = [
  pokemonApi.middleware,
  usersMock.middleware,
  profileApi.middleware,
  roleApi.middleware,
  usersApi.middleware,
  pageApi.middleware,
  componentApi.middleware,
  mediaApi.middleware,
  menuApi.middleware,
  postApi.middleware,
  taxonomyApi.middleware,
  configurationApi.middleware,
  dashboardApi.middleware,
  newDashboardApi.middleware,
  reportsApi.middleware,
  commentsApi.middleware,
  productApi.middleware,
  eventApi.middleware,
  participantApi.middleware,
  workshopApi.middleware,
  mentorshipapi.middleware,
  dataConnectApi.middleware,
  DynamicContent.middleware,
  emailerApi.middleware,
  axiosMiddleware,
]

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
})

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    preloadedState,
  })
}

setupListeners(store.dispatch)
