import React, { useEffect } from 'react'
import { DataGrid, enUS } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { useGetDataQuery } from '../../../../../../../../api/dataconnect'
import { generateColumnsFromData } from '../Utils'
import _ from 'lodash'

const ModelDataList = (props) => {
  const {
    selectedRecords,
    setSelectedRecords,
    selectedModule,
    model,
    dataset,
    availableColumns = [],
    setAvailableColumns,
    selection = 'multiple',
    program = '',
    stopAllSelection = false,
  } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [modelData, setModelData] = React.useState([])
  const { data: records } = useGetDataQuery(
    { activeSite, model, dataset, selectedModule, program },
    {
      skip: !activeSite || !model || !dataset || !selectedModule,
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (records?.data && records.data.length > 0) {
      const columnsIdentified = generateColumnsFromData(records.data?.[0])
      setModelData(records.data)
      if (selection === 'multiple') {
        setSelectedRecords(
          stopAllSelection
            ? selectedRecords
            : records.data.map((record) => record.id),
        )
      }
      if (!_.isEqual(columnsIdentified, availableColumns)) {
        setAvailableColumns(columnsIdentified)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records, setAvailableColumns, setSelectedRecords, selection])

  return (
    <Box
      sx={{
        width: '100%',
        height: '40vh',
        '& .MuiTablePagination-selectLabel': {
          mb: 0,
        },
        backgroundColor: '#FFF',
      }}
    >
      {modelData && modelData.length > 0 && (
        <>
          <DataGrid
            rows={modelData}
            columns={availableColumns}
            rowHeight={50}
            disableColumnFilter
            disableColumnMenu
            sx={{
              '& .MuiDataGrid-columnHeadersInner': {
                backgroundColor: '#E6E7EA',
                color: '#333',
                padding: '0px -5px',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '500',
                wordWrap: 'break-word',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                padding: '0px 1px !important',
              },
            }}
            checkboxSelection={selection === 'multiple' ? true : false}
            onSelectionModelChange={(newSelectionModel) => {
              if (!_.isEqual(selectedRecords, newSelectionModel)) {
                setSelectedRecords(newSelectionModel)
              }
            }}
            selectionModel={selectedRecords}
            localeText={enUS.components.MuiDataGrid.defaultProps.localeText}
          />
        </>
      )}
    </Box>
  )
}

export default ModelDataList
