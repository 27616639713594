import React from 'react'
import { Box, Typography, Avatar, Divider, Chip } from '@mui/material'

import { ViewPopup } from '../../Common/ViewPopup'
import moment from 'moment'
import ListingParticipants from './ListingParticipants'

const ListingSpeakers = (props) => {
  const { mentorsData } = props
  const [open, setOpen] = React.useState(false)

  //   const onReadMore = (speaker, index) => {
  //     setOpen(true)
  //     setSpeakerForView(speaker)
  //   }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box data-testid="listing-speakers-wrapper">
        {mentorsData &&
          mentorsData.data?.length > 0 &&
          mentorsData.data.map((speaker, index) => {
            const { speaker_meta, member_data } = speaker
            const slotDataVal = speaker.sessions.reduce(
              (accumulator, object) => {
                return accumulator + object.slotData
              },
              0,
            )
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    p: 2,
                    cursor: 'pointer',
                    gap: 3,
                  }}
                >
                  <Avatar
                    alt="speaker_image"
                    src={speaker_meta?.engagement_image}
                    sx={{ height: 79, width: 79, alignSelf: 'center' }}
                  ></Avatar>
                  <Box>
                    <Typography variant="h6">
                      <strong>{member_data?.name}</strong>{' '}
                      <span style={{ fontSize: '16px' }}>
                        {speaker_meta?.designation}
                      </span>
                      <span style={{ fontSize: '16px' }}>
                        {' '}
                        - {member_data?.company}
                      </span>
                      {slotDataVal === speaker.confirmed ? (
                        <Chip sx={{ ml: 2 }} label="Booked" color="success" />
                      ) : (
                        slotDataVal > speaker.confirmed && (
                          <Chip
                            sx={{
                              ml: 2,
                              backgroundColor: '#E2BA0C',
                              color: '#fff',
                            }}
                            label={`${
                              slotDataVal - speaker.confirmed
                            } Slots Free`}
                          />
                        )
                      )}
                    </Typography>
                    {/* <Typography> {member_data?.company}</Typography> */}
                    {speaker?.sessions &&
                      speaker?.sessions.map((session, index) => {
                        return (
                          <Box key={index}>
                            <Typography variant="body1" sx={{ pt: 1.5 }}>
                              Start time from{' '}
                              {moment(session?.start_time, ['h:mm']).format(
                                'hh:mm A',
                              )}{' '}
                              -{' '}
                              {moment(session?.end_time, ['h:mm']).format(
                                'hh:mm A',
                              )}
                              {','} <span>{session?.slotData} slots,</span>
                              <span style={{ color: '#3E72DB' }}>
                                {' '}
                                <strong>Each {session?.time_slot} mins </strong>
                              </span>
                              {speaker.waiting_list > 0 && (
                                <span style={{ color: '#FF4141' }}>
                                  {' '}
                                  <strong>❤️ {speaker.waiting_list}</strong>
                                </span>
                              )}
                            </Typography>
                            <Typography variant="body1">
                              {/* <span style={{ color: '#3E72DB' }}>
                          <strong>1 Participant</strong>
                        </span> */}
                            </Typography>
                          </Box>
                        )
                      })}
                    {/* <Typography variant="body1">
                      {speaker_meta?.bio}
                      <span
                        style={{
                          color: '#CC0000',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        {' '}
                        +MORE
                      </span>
                    </Typography> */}
                  </Box>
                  <Box sx={{ p: 1 }}></Box>
                </Box>
                <Box>
                  <Divider></Divider>
                  {speaker.participants && speaker.participants.length > 0 && (
                    <ListingParticipants
                      participantsbyMentorsData={speaker.participants}
                    ></ListingParticipants>
                  )}
                </Box>
              </>
            )
          })}
        <ViewPopup
          open={open}
          onClose={handleClose}
          name=""
          company=""
          designation=""
          bio=""
          image=""
        />
      </Box>
    </>
  )
}

export default ListingSpeakers
