import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import SvgIcon from '@mui/material/SvgIcon'
import { TextField, InputAdornment, Button } from '@mui/material'
import * as mui from '@mui/icons-material'

function selectNode(node) {
  // Clear any current selection
  const selection = window.getSelection()
  selection.removeAllRanges()

  // Select code
  const range = document.createRange()
  range.selectNodeContents(node)
  selection.addRange(range)
}

const StyledIcon = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  color: theme.palette.text.secondary,
  margin: '0 4px',
  '& > div': {
    display: 'flex',
  },
  '& > div > *': {
    flexGrow: 1,
    fontSize: '.6rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: 0,
  },
}))

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
  boxSizing: 'content-box',
  cursor: 'pointer',
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.shortest,
  }),
  padding: theme.spacing(2),
  margin: theme.spacing(0.5, 0),
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
}))

const Icons = React.memo(function Icons(props) {
  const { icons, onSelect, selected } = props

  const handleLabelClick = (event) => {
    selectNode(event.currentTarget)
  }

  return (
    <div>
      {icons.map((icon) => {
        return (
          <StyledIcon key={icon.importName} sx={{}}>
            <StyledSvgIcon
              component={icon.Component}
              fontSize="large"
              tabIndex={-1}
              onClick={() => onSelect(icon.importName)}
              title={icon.importName}
              selected={selected === icon.importName}
              sx={{
                color: selected === icon.importName ? '#0049ff' : 'default',
                background: selected === icon.importName ? '#fff' : 'default',
              }}
            />
            <div>
              <div onClick={handleLabelClick}>{icon.importName}</div>
            </div>
          </StyledIcon>
        )
      })}
    </div>
  )
})

Icons.propTypes = {
  handleOpenClick: PropTypes.func.isRequired,
  icons: PropTypes.array.isRequired,
}

const allIconsMap = {}
Object.keys(mui)
  .sort()
  .map((importName) => {
    let theme
    if (importName.indexOf('Outlined') !== -1) {
      theme = 'Outlined'
    } else if (importName.indexOf('TwoTone') !== -1) {
      theme = 'Two tone'
    } else if (importName.indexOf('Rounded') !== -1) {
      theme = 'Rounded'
    } else if (importName.indexOf('Sharp') !== -1) {
      theme = 'Sharp'
    } else {
      theme = 'Filled'
    }

    const name = importName.replace(/(Outlined|TwoTone|Rounded|Sharp)$/, '')

    const icon = {
      importName,
      name,
      theme,
      Component: mui[importName],
    }
    allIconsMap[importName] = icon
    return icon
  })

function SearchIcons(props) {
  const { keyword = '', onSelect, selected } = props
  const regex = new RegExp(keyword, 'i')
  const filled = Object.keys(allIconsMap)
    .map((key) => allIconsMap[key])
    .filter((val) => val.theme === 'Filled')
    .filter((val) => regex.test(val.name))

  return (
    <Grid container sx={{ minHeight: 500 }}>
      <Grid item xs={12} sm={12}>
        <Icons icons={filled} onSelect={onSelect} selected={selected} />
      </Grid>
    </Grid>
  )
}

function SelectIcon(props) {
  const { open, handleClose, confirmSelection } = props
  const [keyword, setKeyword] = React.useState('')
  const [selected, setSelected] = React.useState(null)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  }

  React.useEffect(() => {
    setSelected(null)
  }, [open])

  const onSelect = (selectedIcon) => {
    setSelected(selectedIcon)
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: '56%' }}>
        <Box sx={{ display: 'flex', p: 2, alignItems: 'center' }}>
          <Typography variant="h3" sx={{ fontSize: 25 }}>
            Select icon
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <TextField
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            size="small"
            placeholder="Type to search"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <mui.SearchRounded />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ height: '65vh', overflow: 'auto', background: '#E7EBF0' }}>
          <SearchIcons
            keyword={keyword}
            onSelect={onSelect}
            selected={selected}
          />
        </Box>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={!selected}
            sx={{ textTransform: 'none', mr: 1 }}
            variant="contained"
            onClick={() => confirmSelection(selected)}
          >
            Select
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default SelectIcon
