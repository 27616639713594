/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import merge from 'lodash/merge'

const MEDIA_BASE_URL = 'https://gfc.target.com/'

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    authorizationUrl:
      'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl:
      'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    clientId: 'enterprise_cms_npe_im',
  },
  api: {
    host: 'https://cmsapi.dev.target.com/',
    apiKey: 'ecbac8b7592a44764821a88808bc617698be5b39',
    // host: 'http://localhost:10000/',
  },
  analytics: {
    appName: 'stencil',
    apiKey: '711dd77f93e90099e30e516ff92590565c87bbac',
    url: 'https://stgapi-internal.target.com/internal_app_analytics/v3',
  },
  baseUrl: MEDIA_BASE_URL,
}

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .vela.yml
  dev: {
    auth: {
      clientId: 'enterprise_cms_npe_im',
    },
    media: {
      host: `${MEDIA_BASE_URL}stencil/`,
    },
  },
  devPOL: {
    isPOL: true,
    auth: {
      clientId: 'enterprise_cms_npe_im',
      authorizationUrl:
        'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.oauth.iam.perf.partnersonline.com/login/responses/logoff.html',
    },
    media: {
      host: `${MEDIA_BASE_URL}stencil/`,
    },
  },
  stg: {
    auth: {
      clientId: 'enterprise_cms_npe_im',
    },
    api: {
      host: 'https://cmsapi-stage.dev.target.com/',
    },
    media: {
      host: `${MEDIA_BASE_URL}stencil/`,
    },
  },
  prod: {
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl:
        'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'enterprise_cms_prod_im',
    },
    api: {
      host: 'https://cmsapi.prod.target.com/',
      apiKey: 'd8736230ea549457b3682c52be7f738753802f0a',
    },
    media: {
      host: `${MEDIA_BASE_URL}stencil/`,
    },
    analytics: {
      appName: 'stencil',
      apiKey: 'ANALYTICS_PROD_KEY',
      url: 'https://api-internal.target.com/internal_app_analytics/v3',
    },
  },
  prodPOL: {
    isPOL: true,
    auth: {
      clientId: 'enterprise_cms_prod_im',
      authorizationUrl:
        'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl:
        'https://logonservices.oauth.iam.partnersonline.com/login/responses/logoff.html',
    },
    api: {
      host: 'https://cmsapi.prod.target.com/',
      apiKey: 'd8736230ea549457b3682c52be7f738753802f0a',
    },
    media: {
      host: `${MEDIA_BASE_URL}stencil/`,
    },
    analytics: {
      appName: 'stencil',
      apiKey: 'ANALYTICS_PROD_KEY',
      url: 'https://api-internal.target.com/internal_app_analytics/v3',
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config)

export default apiConfig

// The following code block is purely for demonstration purposes and can be removed.
if (process.env.REACT_APP_ENV === 'dev') {
  // console.log(`${appEnv} ENV apiConfig:`, apiConfig)
}
