import { Box, Divider, LinearProgress, Button, Alert } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { FormHeading } from '../../../FormComponents'
import FormForEdit from './FormForEdit'
import FormForEditingSpeaker from './FormForEditingSpeaker'
import { useListSpeakersByWorkshopsQuery } from '../../../../../api/workshop'
import {
  useListSpeakerByEventQuery,
  useGetEventQuery,
} from '../../../../../api/event'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { useParams, useNavigate } from 'react-router-dom'

const pathTrail = (id) => {
  return [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${id}`,
      label: 'Event Setup',
    },
    {
      to: '',
      label: 'Edit Worskhop',
    },
  ]
}

/**
 *
 * TODO: Test case needs to be added
 */
export const EditWorkshop = (props) => {
  const [customFields, setCustomFields] = React.useState([])
  const [resetFormNow, setResetFormStatus] = React.useState(false)
  const [speakersToAdd, setSpeakersToAdd] = React.useState([])
  const [workshopData, setWorkshopData] = React.useState({})
  const [speakerForDropDown, setSpeakerForDropDown] = React.useState([])
  const [speakerListData, setSpeakerListData] = React.useState([])
  const [eventData, setEventData] = React.useState(null)
  const [speakersSavedList, setSpeakersSavedList] = React.useState([])
  const activeSite = useSelector((state) => state.tenant.activeSite)
  let { eventId, workshopId } = useParams()
  const navigate = useNavigate()
  const resetRef = useRef()
  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }

  const { data: event, error: eventLoadError } = useGetEventQuery(eventId, {
    skip: !eventId,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (event) {
      setEventData(event)
    }
  }, [event])

  useEffect(() => {
    if (resetFormNow) {
      resetRef.current.click()
      setResetFormStatus(false)
    }
  }, [resetFormNow, resetRef, setResetFormStatus])

  const resetForm = () => {
    setResetFormStatus(true)
  }

  const { data: workshopDetails, isLoading: workshopIsLoading } =
    useListSpeakersByWorkshopsQuery(
      { eventid: eventId, workshop_id: workshopId, activeSite },
      {
        skip: !workshopId || !activeSite,
        refetchOnMountOrArgChange: true,
      },
    )

  useEffect(() => {
    if (workshopDetails) {
      setWorkshopData({
        ...workshopDetails.workshop,
      })

      let speakerData = workshopDetails.data.map((speaker) => {
        let item = {}
        item.name = [speaker.member]
        item.designation = speaker?.speaker_meta?.designation
        item.company = speaker?.member_data?.company
        item.email = speaker?.member_data?.email
        item.start_time = moment(speaker?.start_time, ['h:mm']).toDate()
        item.end_time = moment(speaker?.end_time, ['h:mm']).toDate()
        item.engagement_image = speaker?.speaker_meta?.engagement_image
        item.description = speaker?.speaker_meta?.bio
        item.id = speaker.member

        return item
      })

      if (speakerData.length === 0) {
        let temp = []
        temp.push({})
        // temp.push(`speaker${speakerData.length + 1}`)
        setSpeakersToAdd(temp)
      } else {
        setSpeakersToAdd(speakerData)
        setSpeakersSavedList(speakerData)
      }

      if (workshopDetails?.workshop?.customFields) {
        setCustomFields([...workshopDetails.workshop.customFields])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshopDetails])

  const { data: speakersList, isFetching: speakersFetching } =
    useListSpeakerByEventQuery(
      { eventid: eventId, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  var speakerObj = {}
  useEffect(() => {
    if (speakersList) {
      let data = speakersList.data.map(
        (item) => (item = { name: item.user.name, id: item.user._id }),
      )
      speakersList.data.map((item) => {
        return (speakerObj[item.user._id] = item)
      })
      setSpeakerListData(speakerObj)
      setSpeakerForDropDown(data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speakersList])

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }}>
        <Breadcrumbs trail={pathTrail(eventId)} />
        <Box sx={{ mt: 2, width: '100%', background: '#F6F7F8' }}>
          <FormHeading
            text="Edit Worskhop"
            btns={[
              {
                label: 'Add new input',
                type: 'select',
                cb: addNewInput,
                icon: 'Add',
                options: [
                  { label: 'Text input', value: 'text' },
                  { label: 'Date input', value: 'date' },
                  { label: 'Switch input', value: 'switch' },
                ],
              },
              { label: 'Reset', cb: resetForm, icon: 'Replay' },
            ]}
          />
          <Divider variant="middle" sx={{ m: 0.5 }} />
          {!eventLoadError ? (
            <>
              <Box container sx={{ display: 'flex', background: '#F6F7F8' }}>
                {workshopIsLoading && <LinearProgress></LinearProgress>}
                <FormForEdit
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  reset={resetFormNow}
                  setResetFormStatus={setResetFormStatus}
                  initialValues={workshopData}
                  workshopId={workshopId}
                  event={eventData}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <FormForEditingSpeaker
                    speakersToAdd={speakersToAdd}
                    setSpeakersToAdd={setSpeakersToAdd}
                    workshopId={workshopId}
                    eventId={eventId}
                    speakerForDropDown={speakerForDropDown}
                    speakerListData={speakerListData}
                    speakersFetching={speakersFetching}
                    workshopData={workshopData}
                    speakersSavedList={speakersSavedList}
                    setSpeakersSavedList={setSpeakersSavedList}
                  ></FormForEditingSpeaker>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  bottom: 0,
                  background: '#fff',
                  p: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                  }}
                  color="secondary"
                  type="button"
                  className="btn btn-primary btn-block mt-4"
                  onClick={() => navigate(-1)}
                >
                  Done
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                    mr: 1,
                  }}
                  className="btn btn-primary btn-block mt-4"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="reset"
                  sx={{
                    display: 'none',
                  }}
                  ref={resetRef}
                >
                  reset
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Alert severity="error">
                Unable to load event information! try reload the pageagain
              </Alert>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EditWorkshop
