import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Chip,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
} from '@mui/material'
import WebIcon from '@mui/icons-material/Web'
import LinkIcon from '@mui/icons-material/Link'

export default function ViewMenuData(props) {
  const { open = false, handleClose, data = {} } = props
  const { name = '', slug, type = 'page', children = [] } = data
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {name}
          <Chip
            sx={{ marginLeft: 2 }}
            size={'small'}
            icon={type === 'page' ? <WebIcon /> : <LinkIcon />}
            label={slug}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            subheader={<ListSubheader>Menu Details</ListSubheader>}
          >
            <ListItem>
              <ListItemText primary="Title" secondary={name} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Slug" secondary={slug} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Type" secondary={type} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Sub-Menu Existence"
                secondary={children.length > 0 ? 'Yes' : 'No'}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
