import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiConfig from '../apiConfig'

const { api } = apiConfig

export const dataConnectApi = createApi({
  reducerPath: 'dataconnect',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.host}content`,
    prepareHeaders: (headers, { getState }) => {
      const identityToken = getState().session.identityToken
      const lanId = getState().session.userInfo.lanId
      const bearer = getState().session.userInfo.accessToken
      // If we have a token set in state, let's assume that we should be passing it.
      if (bearer && lanId) {
        headers.set('lanid', lanId)
        headers.set('authorization', bearer)
        headers.set('x-api-key', api.apiKey)
        headers.set('x-id-token', identityToken)
      }
      return headers
    },
  }),
  tagTypes: ['DataSet'],
  endpoints: (builder) => ({
    getData: builder.query({
      query: ({ dataset, model, selectedModule, activeSite, program }) => {
        return {
          url: `/${dataset}/${selectedModule}/${model}?site=${activeSite._id}`,
          method: 'GET',
          params: program
            ? {
                program,
              }
            : {},
        }
      },
      providesTags: ['DataSet'],
    }),
    getDataByParams: builder.query({
      query: ({ dataset, model, selectedModule, activeSite, ids, fields }) => {
        return {
          url: `/${dataset}/${selectedModule}/${model}?site=${activeSite._id}`,
          method: 'GET',
          params: {
            ids,
            fields,
          },
        }
      },
    }),
  }),
})

export const { useGetDataQuery, useGetDataByParamsQuery } = dataConnectApi
