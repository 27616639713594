import { createSlice } from '@reduxjs/toolkit'
import { pageApi } from './page'
import { getValueByPath } from '../utils/dataParser'

const initialState = {
  layout: [],
  queue: [],
  visualEditDisabled: false,
}

export const pageLayoutSlice = createSlice({
  name: 'pageLayout',
  initialState,
  reducers: {
    addChanges: (state, action) => {
      const { slug = '', value = null, sectionNumber, compPos } = action.payload
      if (slug) {
        console.log(
          compPos,
          state.layout?.[sectionNumber].components?.[compPos],
          getValueByPath(state.layout?.[sectionNumber], slug),
          'original',
          value,
        )
      }
      state.queue = [...state.queue, action.payload]
    },
    disableVisualEdit: (state, action) => {
      state.visualEditDisabled = true
    },
    enableVisualEdit: (state, action) => {
      state.visualEditDisabled = false
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pageApi.endpoints.getRevision.matchFulfilled,
      (state, { payload }) => {
        state.layout = payload?.layout
      },
    )
  },
})

// Action creators are generated for each case reducer function
export const { addChanges, enableVisualEdit, disableVisualEdit } =
  pageLayoutSlice.actions

export default pageLayoutSlice.reducer
