import { Chip, Divider, Paper } from '@mui/material'

import CheckIcon from '../../../assets/Image/check_icon.svg'
import FileIcon from '../../../assets/Image/file_icon.svg'

const ImpactCard = (props) => {
  const { title, units, data } = props
  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
        <CheckIcon style={{ margin: '2px' }} />
        <h4 style={{ flex: '1 1 100%', margin: '0 10px' }}>{title}</h4>
      </div>
      <Divider />
      <div style={{ overflow: 'scroll', maxHeight: '300px' }}>
        {data?.map(({ name, value }, index) => {
          const color = value < 50 ? '#29CC39' : '#CC0000'
          return (
            <div
              key={index}
              style={{
                display: 'grid',
                gridTemplateColumns: '56px auto 1fr',
                gridTemplateRows: '56px',
                alignItems: 'center',
                columnGap: '10px',
                marginTop: '10px',
              }}
            >
              <div
                style={{
                  height: '56px',
                  width: '56px',
                  borderRadius: '50%',
                  background: `${color}22`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FileIcon style={{ fill: color, opacity: 1 }} />
              </div>
              <div>
                <Chip
                  style={{
                    fontWeight: 'bold',
                    fontSize: '10px',
                    color: '#6B7A99',
                  }}
                  label={`${value}% ${units ? units : ''}`}
                />
              </div>
              <div>
                <Chip
                  style={{
                    fontWeight: 'bold',
                    fontSize: '10px',
                    color: '#6B7A99',
                  }}
                  label={name}
                />
              </div>
            </div>
          )
        })}
      </div>
    </Paper>
  )
}

export default ImpactCard
