import * as React from 'react'
import { Dialog } from '@enterprise-ui/canvas-ui-react'
import { PropTypes } from 'prop-types'

export const ConfirmationDialog = (props) => {
  const { open = false, setOpen, onApprove, heading = '', testid = '' } = props
  return (
    <Dialog
      headingText={heading}
      approveButtonText="Yes"
      onApprove={() => {
        onApprove()
      }}
      onRefuse={() => {
        setOpen(false)
      }}
      refuseButtonText="No thanks"
      isVisible={open}
      data-testid={testid}
    />
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
}

export default ConfirmationDialog
