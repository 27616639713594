import React from 'react'
import * as mui from '@mui/icons-material'
import { SvgIcon, Box } from '@mui/material'

export const IconSelector = (props) => {
  const { name = 'ReportProblem' } = props
  return (
    <Box>
      <SvgIcon fontSize="small" data-testid="mui-icon" component={mui[name]} />
    </Box>
  )
}

export default IconSelector
