// import { PersonAdd } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Autocomplete,
  TextField,
  Chip,
  Button,
  Grow,
  Alert,
  AlertTitle,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import {
  useGetUsersListQuery,
  useGetUserMapQuery,
  useSaveUserMapMutation,
} from '../../api/users'
import { useSelector } from 'react-redux'
import withRights from '../../components/withRights'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import HTMLTooltip from '../../Layout/HtmlTooltip'
import InfoIcon from '@mui/icons-material/Info'
import ListLoader from '../../components/ListLoader'

const userMapHelper = (userMap) => {
  if (!userMap) {
    return {}
  }
  const updatedUsermap = {}
  userMap.map((roleWithUser) => {
    updatedUsermap[roleWithUser.role] = roleWithUser.users.map((user) => {
      return {
        ...user,
        exist: true,
      }
    })
    return 0
  })
  return updatedUsermap
}

const apiUpdateHelper = (
  savingData, // array for single role save / object for save all
  roleId = null,
  changes = null, // Changes deteceted for the roles
  saveAll = false,
) => {
  const apiData = {}
  //Action for individual role save
  if (roleId) {
    const users = savingData.map((user) => {
      return user.id ? user.id : user._id
    })
    apiData[roleId] = users
  } else if (saveAll) {
    Object.keys(savingData).map((roleId) => {
      if (changes && changes.length > 0 && changes.includes(roleId)) {
        const users = savingData[roleId].map((user) => {
          return user.id ? user.id : user._id
        })
        apiData[roleId] = users
      }
      return 0
    })
  }
  return apiData
}

const UserList = (props) => {
  const { rights } = props
  const { superRights, permittedActions } = rights
  const {
    roles,
    changesDetected,
    setChangesDetected,
    resetData,
    saveAll,
    savingInProgress,
    setStatus,
    setMsg,
    setSaveAll,
  } = props
  const [opened, setOpened] = React.useState([])
  const [availableUsers, setAvailableUsers] = React.useState([])
  const [assignedUsers, setAssignedUsers] = React.useState({})
  const [currentRole, setCurrentRole] = React.useState(null)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const { data: users, isLoading: userListLoading } = useGetUsersListQuery(
    {},
    {
      skip: !activeSite,
    },
  )
  const [
    saveUserMap,
    { isLoading: isUpdating, error: userSaveError, isSuccess: userSaveSuccess },
  ] = useSaveUserMapMutation()
  const {
    data: usermap,
    isLoading: userMapLoading,
    error: userMapLoadError,
  } = useGetUserMapQuery(activeSite?._id, { skip: !activeSite })

  // eslint-disable-next-line no-unused-vars
  const [existingDataDeleted, setExistDataDeletion] = React.useState({}) // this value for removing records from mapping while save

  useEffect(() => {
    if (usermap) {
      setAssignedUsers(userMapHelper(usermap))
    }
    if (resetData) {
      setAssignedUsers(userMapHelper(usermap))
    }
  }, [usermap, resetData])

  useEffect(() => {
    if (users) {
      setAvailableUsers(users)
    }
  }, [users])

  useEffect(() => {
    savingInProgress(isUpdating)
  }, [isUpdating, savingInProgress])

  useEffect(() => {
    if (userSaveSuccess) {
      setMsg('Save successful')
      setStatus('success')
    }
    if (userSaveError) {
      setMsg('Unable to save! please try again')
      setStatus('error')
    }
  }, [userSaveSuccess, userSaveError, setMsg, setStatus])

  useEffect(() => {
    if (currentRole === 'all') {
      setChangesDetected([])
      setCurrentRole(null)
    }
    if (currentRole && currentRole !== 'all') {
      setChangesDetected(changesDetected.filter((val) => val !== currentRole))
      setCurrentRole(null)
    }
  }, [changesDetected, currentRole, setChangesDetected, userSaveSuccess])

  useEffect(() => {
    if (saveAll && !isUpdating) {
      const apiData = apiUpdateHelper(
        assignedUsers,
        null,
        changesDetected,
        true,
      )
      saveUserMap({ map: apiData, site: activeSite._id })
      setSaveAll(false)
      setCurrentRole('all')
    }
  }, [
    saveAll,
    assignedUsers,
    changesDetected,
    saveUserMap,
    activeSite,
    isUpdating,
    setSaveAll,
  ])

  const addUserToRole = (val, roleId, reason, detail) => {
    // To handle existing users removal : Delete user from role
    if (
      reason === 'removeOption' &&
      detail.option.exist &&
      (!existingDataDeleted[roleId] ||
        !existingDataDeleted[roleId].includes(detail.option._id))
    ) {
      setExistDataDeletion({
        ...existingDataDeleted,
        [roleId]: [
          ...(existingDataDeleted[roleId] ? existingDataDeleted[roleId] : []),
          detail.option._id,
        ],
      })
    }

    // To handle existing users removal and add again to same role
    if (
      reason === 'selectOption' &&
      existingDataDeleted[roleId] &&
      existingDataDeleted[roleId].includes(detail.option.id)
    ) {
      val = val.map((iVal) => {
        const updated = { ...iVal }
        if (updated.lanId === detail.option.lanId) {
          updated.exist = true
          updated._id = iVal.id
        }
        return updated
      })

      setExistDataDeletion({
        ...existingDataDeleted,
        [roleId]: existingDataDeleted[roleId].filter(
          (cUser) => cUser !== detail.option.id,
        ),
      })
    }

    // Assign the users to unsaved state for ajax call
    setAssignedUsers({
      ...assignedUsers,
      [roleId]: val,
    })
    if (!changesDetected.includes(roleId)) {
      setChangesDetected([...changesDetected, roleId])
    }
  }

  const toggleUserAdd = (key) => {
    setOpened(
      !opened.includes(key)
        ? [...opened, key]
        : opened.filter((val) => val !== key),
    )
  }

  const resetUsers = (roleId) => {
    setOpened(opened.filter((val) => val !== roleId))
    const roleMap = usermap.find((user) => user.role === roleId)
    setAssignedUsers({
      ...assignedUsers,
      [roleId]: roleMap ? roleMap.users : [],
    })
    setChangesDetected(changesDetected.filter((val) => val !== roleId))
  }

  const saveUser = (roleId) => {
    const apiData = apiUpdateHelper(assignedUsers[roleId], roleId)
    saveUserMap({ map: apiData, site: activeSite._id })
    setOpened(opened.filter((val) => val !== roleId))
    setCurrentRole(roleId)
  }

  const generateUserHelpText = (actions, roleName) => {
    const uniqueModules = []
    actions.map((action) => {
      const currentModule = action.split('.')[0]
      const currentAction = action.split('.')[1].replace('*', 'All')
      const index = uniqueModules.findIndex((obj) => obj.name === currentModule)
      if (index < 0) {
        uniqueModules.push({ name: currentModule, actions: [currentAction] })
      } else {
        uniqueModules[index].actions = [
          ...(uniqueModules[index] ? uniqueModules[index].actions : []),
          currentAction,
        ]
      }
      return 0
    })

    return (
      <>
        <Typography color="inherit">
          <strong>{roleName}</strong> Rights
        </Typography>
        <em>{'The rights were listed based on modules'}</em>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Module</strong>
                </TableCell>
                <TableCell>
                  <strong>Rights</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uniqueModules &&
                uniqueModules.length > 0 &&
                uniqueModules.map((module, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{module.name}</TableCell>
                      <TableCell>
                        {module.actions &&
                          module.actions.map((action, key) => (
                            <Chip
                              size="small"
                              variant="filled"
                              color="primary"
                              key={key}
                              sx={{ mr: 1, mb: 1 }}
                              label={action}
                            />
                          ))}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <React.Fragment>
      {!userMapLoadError ? (
        <React.Fragment>
          {!userMapLoading ? (
            <Box sx={{ paddingTop: 2 }}>
              {roles &&
                roles.map((role) => {
                  return (
                    <Card
                      key={role.id}
                      sx={{
                        marginBottom: 1,
                        boxShadow: 2,
                        paddingTop: 0.1,
                        paddingBottom: 0.1,
                        display: 'flex',
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CardContent
                          sx={{
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          {role.roleName}
                        </CardContent>
                        <HTMLTooltip
                          content={generateUserHelpText(
                            role.accessList,
                            role.roleName,
                          )}
                        >
                          <IconButton sx={{ borderRadius: 0 }}>
                            <InfoIcon />
                          </IconButton>
                        </HTMLTooltip>
                      </Box>
                      {opened.includes(role.id) ? (
                        <Box sx={{ flexGrow: 1 }}>
                          <CardContent>
                            <Autocomplete
                              size="small"
                              multiple
                              id="tags-outlined"
                              options={availableUsers}
                              getOptionLabel={(option) => option.displayName}
                              defaultValue={[]}
                              loading={userListLoading}
                              filterSelectedOptions
                              disableCloseOnSelect
                              disableClearable
                              data-testid={
                                'autocomplete-user-selection-' + role.id
                              }
                              onClose={() => {
                                toggleUserAdd(role.id)
                              }}
                              sx={{ color: 'primary' }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Type to select users"
                                />
                              )}
                              value={
                                assignedUsers[role.id]
                                  ? assignedUsers[role.id]
                                  : []
                              }
                              onChange={(_, val, reason, detail) => {
                                addUserToRole(val, role.id, reason, detail)
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                  console.log(option, userInfo.lanId)
                                  return (
                                    <Chip
                                      key={index}
                                      size="small"
                                      color="primary"
                                      variant="outlined"
                                      label={option.displayName}
                                      sx={{
                                        padding: 1,
                                        py: 2.3,
                                        fontWeight: 'bold',
                                      }}
                                      {...getTagProps({ index })}
                                      disabled={option.lanId === userInfo.lanId}
                                    />
                                  )
                                })
                              }
                              isOptionEqualToValue={(option, value) => {
                                return option.lanId === value.lanId
                              }}
                            />
                          </CardContent>
                        </Box>
                      ) : (
                        <Box sx={{ flexGrow: 1 }}>
                          <CardContent>
                            {assignedUsers &&
                              assignedUsers[role.id] &&
                              assignedUsers[role.id].map((option, index) => (
                                <Chip
                                  size="small"
                                  color="primary"
                                  label={option.displayName}
                                  key={index}
                                  variant="outlined"
                                  sx={{
                                    padding: 1,
                                    py: 2.3,
                                    fontWeight: 'bold',
                                    margin: 0.3,
                                    marginTop: 1,
                                    marginBottom: 1,
                                  }}
                                  // onDelete={() => deleteUserFromRole(role.id, option)}
                                />
                              ))}
                          </CardContent>
                        </Box>
                      )}
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CardActions
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          {changesDetected.includes(role.id) && (
                            <>
                              <Grow in={true}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Button
                                    variant={'outlined'}
                                    size={'small'}
                                    sx={{
                                      textTransform: 'none',
                                      borderRadius: 0,
                                    }}
                                    onClick={() => saveUser(role.id)}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    variant={'outlined'}
                                    color="secondary"
                                    size={'small'}
                                    sx={{
                                      textTransform: 'none',
                                      marginLeft: 1,
                                      marginRight: 1,
                                      borderRadius: 0,
                                    }}
                                    onClick={() => resetUsers(role.id)}
                                  >
                                    Reset
                                  </Button>
                                </Box>
                              </Grow>
                            </>
                          )}
                          {checkUserIsAllowed(
                            'update',
                            permittedActions,
                            superRights,
                          ) &&
                            !opened.includes(role.id) && (
                              <IconButton
                                onClick={() => toggleUserAdd(role.id)}
                                data-testid={'button-user-manage-' + role.id}
                              >
                                <ManageAccountsIcon color="primary" />
                              </IconButton>
                            )}
                        </CardActions>
                      </Box>
                    </Card>
                  )
                })}
            </Box>
          ) : (
            <Box sx={{ height: '65vh' }}>
              <ListLoader numberOfBlocks={5} />
            </Box>
          )}
        </React.Fragment>
      ) : (
        <Alert severity="error">
          <AlertTitle>
            <strong>Error</strong>
          </AlertTitle>
          Unable to retrive the user list! Please try again. if issue persists
          please contact <strong>stencil team</strong>
          <br />
          <strong>
            {`server returned status code ${userMapLoadError.originalStatus}`}
          </strong>
        </Alert>
      )}
    </React.Fragment>
  )
}

const moduleAttr = {
  name: 'User',
  actions: [
    {
      slug: 'read',
      access: 'read',
    },
    {
      slug: 'update',
      access: 'update',
    },
  ],
}

export default withRights(UserList, moduleAttr)
