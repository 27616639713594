import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiConfig from '../apiConfig'

const { api } = apiConfig

export const mentorshipapi = createApi({
  reducerPath: 'mentorship',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.host}events`,
    prepareHeaders: (headers, { getState }) => {
      const identityToken = getState().session.identityToken
      const lanId = getState().session.userInfo.lanId
      const bearer = getState().session.userInfo.accessToken
      // If we have a token set in state, let's assume that we should be passing it.
      if (bearer && lanId) {
        headers.set('lanid', lanId)
        headers.set('authorization', bearer)
        headers.set('x-api-key', api.apiKey)
        headers.set('x-id-token', identityToken)
      }
      return headers
    },
  }),
  tagTypes: ['EventMentorship'],
  endpoints: (builder) => ({
    newMentorship: builder.mutation({
      query: ({ mentorshipData, eventid }) => ({
        url: `/${eventid}/mentorship`,
        method: 'POST',
        body: {
          ...mentorshipData,
        },
      }),
      invalidatesTags: ['EventMentorship'],
    }),
    linkMentorsToMentorship: builder.mutation({
      query: ({ mentorData, eventid, mentorship_id }) => ({
        url: `/${eventid}/${mentorship_id}/addspeaker`,
        method: 'POST',
        body: {
          ...mentorData,
        },
      }),
      invalidatesTags: ['Mentors'],
    }),
    removeMentorsFromMentorship: builder.mutation({
      query: ({ ids, eventid, mentorship_id, activeSite }) => ({
        url: `/${eventid}/speaker/remove`,
        method: 'POST',
        body: {
          ids,
          program_id: mentorship_id,
          site: activeSite._id,
        },
      }),
      invalidatesTags: ['Mentors'],
    }),
    listMentorships: builder.query({
      query: ({ eventid, activeSite, filters }) => ({
        url: `/${eventid}/mentorships`,
        method: 'GET',
        params: {
          ...filters,
          siteid: activeSite._id,
        },
      }),
      providesTags: ['Mentorships'],
    }),
    listMentorsByMentorships: builder.query({
      query: ({ eventid, mentorship_id, activeSite }) => ({
        url: `/${eventid}/${mentorship_id}/listspeakers`,
        method: 'GET',
        params: {
          siteid: activeSite._id,
        },
      }),
      providesTags: ['Speakers'],
    }),
    updateMentorship: builder.mutation({
      query: ({ mentorshipData, eventId, mentorshipId }) => ({
        url: `/${eventId}/mentorship/${mentorshipId}`,
        method: 'POST',
        body: {
          ...mentorshipData,
        },
      }),
      invalidatesTags: ['Mentorships'],
    }),
    generateSlots: builder.mutation({
      query: ({ sessionData, eventId }) => ({
        url: `/${eventId}/generateslots`,
        method: 'POST',
        body: {
          sessions: sessionData,
        },
      }),
      invalidatesTags: ['Mentorships'],
    }),
    getParticipants: builder.query({
      query: ({ eventid, mentorship_id, activeSite }) => ({
        url: `/${eventid}/${mentorship_id}/partcipants`,
        method: 'GET',
        params: {
          siteid: activeSite._id,
        },
      }),
      providesTags: ['Participants'],
    }),
    getParticipantsByMentors: builder.query({
      query: ({ eventid, mentorship_id, speaker_id, activeSite }) => ({
        url: `/${eventid}/${mentorship_id}/partcipants/${speaker_id}`,
        method: 'GET',
        params: {
          siteid: activeSite._id,
        },
      }),
      invalidatesTags: ['Mentorships'],
    }),
    downloadMentorship: builder.query({
      query: ({ eventid, activeSite }) => {
        return {
          url: `/${eventid}/exportmentorships`,
          method: 'GET',
          params: {
            siteid: activeSite._id,
          },
        }
      },
      invalidatesTags: ['Mentorships'],
    }),
    cancelMentorship: builder.mutation({
      query: ({
        eventId,
        mentorshipId,
        speakerid,
        slotid,
        activeSite,
        memberId,
      }) => ({
        url: `/${eventId}/mentorships/${mentorshipId}/cancel`,
        method: 'POST',
        params: {
          siteid: activeSite._id,
        },
        body: {
          speakerid: speakerid,
          slotid: slotid,
          member: memberId,
        },
      }),
      invalidatesTags: ['Participants'],
    }),
    cancelInQueueMentorship: builder.mutation({
      query: ({
        eventId,
        mentorshipId,
        speakerid,
        slotid,
        activeSite,
        memberId,
      }) => ({
        url: `/${eventId}/mentorships/${mentorshipId}/cancelinterest`,
        method: 'POST',
        params: {
          siteid: activeSite._id,
        },
        body: {
          speakerid: speakerid,
          slotid: slotid,
          member: memberId,
        },
      }),
      invalidatesTags: ['Participants'],
    }),
  }),
})

export const {
  useNewMentorshipMutation,
  useLinkMentorsToMentorshipMutation,
  useRemoveMentorsFromMentorshipMutation,
  useListMentorshipsQuery,
  useListMentorsByMentorshipsQuery,
  useUpdateMentorshipMutation,
  useGenerateSlotsMutation,
  useGetParticipantsQuery,
  useGetParticipantsByMentorsQuery,
  useDownloadMentorshipQuery,
  useCancelMentorshipMutation,
  useCancelInQueueMentorshipMutation,
} = mentorshipapi
