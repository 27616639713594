import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function DeleteConfirmation(taxonomy) {
  const { onConfirm, onClose, open } = taxonomy

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure want to delete this component?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: 'none' }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'contained'}
            sx={{ textTransform: 'none' }}
            onClick={onConfirm}
            autoFocus
          >
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
