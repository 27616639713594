import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material'

import CheckIcon from '../../../assets/Image/check_icon.svg'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E6E7EA',
    color: '#333333',
  },
}))

const SearchKeywordsCard = (props) => {
  const { title, count, data } = props
  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
        <CheckIcon style={{ margin: '2px' }} />
        <h4 style={{ flex: '1 1 100%', margin: '0 10px' }}>{title}</h4>
        <h2>{count}</h2>
      </div>
      <TableContainer sx={{ maxHeight: 350 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Key Word</StyledTableCell>
              <StyledTableCell>No of Users</StyledTableCell>
              <StyledTableCell> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.keyword}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                  {row.keyword}
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>{row.count}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <LinearProgress
                    variant="determinate"
                    value={row.percentage}
                    sx={{
                      flex: '1 1 100%',
                      height: '7px',
                      borderRadius: '7px',
                      backgroundColor: '#F7F7F7',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#3E72DB',
                        borderRadius: '7px',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default SearchKeywordsCard
