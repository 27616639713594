import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import Label from './Common/Label'

export const ButtonSize = (props) => {
  const { value, onChange, name = 'Button size', disabled = false } = props
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        <ToggleButtonGroup
          value={value}
          size={'small'}
          exclusive
          onChange={(_, val) => onChange(val)}
          disabled={disabled}
        >
          <ToggleButton value="small" aria-label="small">
            S
          </ToggleButton>
          <ToggleButton value="normal" aria-label="medium">
            M
          </ToggleButton>
          <ToggleButton value="large" aria-label="large">
            L
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

export default ButtonSize
