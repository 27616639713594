import React from 'react'
import { Box, Popper, IconButton, Card, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

export default function Label(props) {
  const { name, helperText } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box
      sx={{
        display: 'flex',
        width: '30%',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{ fontSize: 12, fontWeight: 600, color: '#253237' }}
        >
          {name}
        </Typography>
        {helperText && (
          <IconButton
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            size="small"
          >
            <InfoIcon />
          </IconButton>
        )}
      </Box>
      <Popper
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <Card sx={{ p: 1, width: 300 }}>{helperText}</Card>
      </Popper>
    </Box>
  )
}
