import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  List,
  Divider,
  TablePagination,
  LinearProgress,
  Typography,
} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import ButtonGroup from '@mui/material/ButtonGroup'
import { notificationsFilter } from '../utils/constant'
// import FilterAltIcon from '@mui/icons-material/FilterAlt'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import NotificationItem from './NotificationItem'
// import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
// import NotificationsIcon from '@mui/icons-material/Notifications'
import { useSelector } from 'react-redux'
import {
  useGetNotificationsQuery,
  usePostDashboardDataMutation,
} from '../api/dashboard'

const Notifications = (props) => {
  const { onClose, dashboardData } = props
  const [buttonSelected, setButtonSelected] = useState('All')
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [pageVal, setPageVal] = React.useState(0)
  const [perpage, setPerPage] = React.useState(10)
  const [readStatus, setReadStatus] = React.useState('all')
  const [status, setStatus] = React.useState('')
  const [notificationList, setNotificationList] = useState([])

  const setSelectedButton = (item) => {
    setButtonSelected(item)
    setPageVal(0)
    if (item.toLowerCase() === 'approved') {
      setReadStatus('all')
      setStatus(item.toLowerCase())
    } else {
      setReadStatus(item.toLowerCase())
      setStatus('')
    }
  }

  const { data: notifications, isLoading: loadingNotificationsData } =
    useGetNotificationsQuery(
      {
        activeSite,
        pageVal: pageVal + 1,
        perpage,
        read_status: readStatus,
        status: status,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [saveDashboardData] = usePostDashboardDataMutation()

  useEffect(() => {
    if (notifications) {
      setNotificationList(notifications)
    }

    return () => {
      setNotificationList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  const { data, total, unread } = notificationList ? notificationList : {}

  const handleChangePage = (event, newPage) => {
    setPageVal(newPage)
    setPerPage(10)
  }

  var tempObj = { ...dashboardData }
  const toggleNotifications = () => {
    if (tempObj['notifications'] === undefined) {
      tempObj['notifications'] = false
    } else {
      tempObj['notifications'] = !tempObj['notifications']
    }
    saveDashboardData({ settings: tempObj, activeSite })
  }

  return (
    <>
      {loadingNotificationsData ? (
        <LinearProgress />
      ) : (
        <Box
          role="presentation"
          sx={{ overflow: 'scroll', height: 'calc(100vh - 64px)' }}
        >
          <List sx={{ pb: 0 }}>
            <ListItem>
              <ListItemButton sx={{ p: 0 }}>
                <ListItemText
                  primary={
                    dashboardData.notifications
                      ? `Notifications(${unread})`
                      : 'Notifications'
                  }
                  primaryTypographyProps={{ fontSize: 20 }}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem sx={{ px: 1 }}>
              {dashboardData.notifications ||
              dashboardData.notifications === undefined ? (
                <ButtonGroup
                  variant="outlined"
                  size="small"
                  aria-label="outlined primary button group"
                >
                  {notificationsFilter.map((item, itemIndex) => {
                    return (
                      <Button
                        key={itemIndex}
                        sx={{
                          px: 1,
                          backgroundColor:
                            buttonSelected === item ? '#303F9F' : '#fff',
                          color: buttonSelected === item ? '#fff' : '#000',
                          ':hover': {
                            backgroundColor:
                              buttonSelected === item ? '#303F9F' : '#fff',
                          },
                        }}
                        onClick={() => setSelectedButton(item)}
                      >
                        {item}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              ) : (
                <Typography variant="body1">
                  Please unmute to receive notifications
                </Typography>
              )}
              {/* <IconButton type="submit" aria-label="filter icon" sx={{ pl: 2 }}>
                <FilterAltIcon></FilterAltIcon>
              </IconButton> */}
              {/* <IconButton type="submit" aria-label="mute icon">
                <VolumeOffIcon></VolumeOffIcon>
              </IconButton> */}
              <IconButton
                type="submit"
                aria-label="mute icon"
                onClick={toggleNotifications}
              >
                {dashboardData.notifications ||
                dashboardData.notifications === undefined ? (
                  <VolumeUpIcon></VolumeUpIcon>
                ) : (
                  <VolumeOffIcon></VolumeOffIcon>
                )}
              </IconButton>
            </ListItem>
            <Divider />
          </List>
          <IconButton
            type="submit"
            aria-label="close menu"
            onClick={onClose}
            sx={{
              position: 'relative',
              top: '-108px',
              right: '5px',
              float: 'right',
            }}
          >
            <CloseIcon
              sx={{
                color: 'rgb(0,0,0,0.87)',
                fontSize: '2rem',
              }}
            ></CloseIcon>
          </IconButton>
          {dashboardData.notifications ||
          dashboardData.notifications === undefined
            ? data?.map((item, index) => {
                return (
                  <NotificationItem
                    {...item}
                    onClose={onClose}
                    key={index}
                    page="notification"
                  ></NotificationItem>
                )
              })
            : ''}
          {dashboardData.notifications ? (
            <TablePagination
              component="div"
              count={total}
              page={pageVal}
              onPageChange={handleChangePage}
              rowsPerPage={perpage}
              sx={{
                '& .MuiTablePagination-selectLabel': {
                  display: 'none',
                },
                '& .MuiTablePagination-select': {
                  display: 'none',
                },
                '& .MuiTablePagination-selectIcon': {
                  display: 'none',
                },
              }}
            />
          ) : (
            ''
          )}
        </Box>
      )}
    </>
  )
}

export default Notifications
