import React from 'react'
import {
  Box,
  // FormControl,
  TextField,
  // Select,
  // MenuItem,
  InputAdornment,
  IconButton,
  Typography,
  ButtonGroup,
  Avatar,
  Button,
  SwipeableDrawer,
  LinearProgress,
} from '@mui/material'

import {
  SearchSharp,
  CloudDownloadOutlined,
  BorderColorOutlined,
} from '@mui/icons-material'
import OpenDailog from '../../Common/OpenDailog/OpenDailog'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  useDownloadMentorshipQuery,
  useCancelMentorshipMutation,
  useCancelInQueueMentorshipMutation,
} from '../../../../../api/mentorship'
import { getExperienceLabel } from '../../Utils/Helper'

const buttonGroupstyle = {
  fontSize: 14,
  textTransform: 'none',
  padding: '10px',
  fontWeight: '600',
}

const ListingMentorship = (props) => {
  const navigate = useNavigate()
  const {
    mentorshipListData,
    setMentorshipId,
    mentorshipId,
    setFilters,
    filters,
    eventId,
    mentorshipFetching,
    editDisabled = false,
    allParticipantsData,
    mentorsData,
    actionsAllowed = {},
  } = props
  // const [status, setStatus] = React.useState('active')
  const [openParticipantsDailog, setOpenParticipantsDailog] =
    React.useState(false)

  const [dialogData, setDialogData] = React.useState([])
  const [dialogTitle, setDialogTitle] = React.useState('Approved Participants')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)
  const [mentorshipExcelData, setMentorshipExcelData] = React.useState([])
  const activeSite = useSelector((state) => state.tenant.activeSite)

  const { data: mentorshipExportExcelData } = useDownloadMentorshipQuery(
    {
      eventid: eventId,
      activeSite,
    },
    { skip: !activeSite },
  )

  const [cancelMentorshipBooking, { isLoading: cancelBookingLoading }] =
    useCancelMentorshipMutation()

  const [cancelInQueueMentorship, { isLoading: cancelInQueueLoading }] =
    useCancelInQueueMentorshipMutation()

  const fileType = 'text/csv;charset=utf-8;'
  const fileName = 'MentorshipData'

  const { userCanEditMentorship, userCanDownloadMentorshio } = actionsAllowed
  React.useEffect(() => {
    if (allParticipantsData) {
      if (dialogTitle === 'Approved Participants') {
        setDialogData(
          allParticipantsData.filter((item) => item.status === 'CONFIRMED'),
        )
      } else if (dialogTitle === 'Interested participants') {
        setDialogData(
          allParticipantsData.filter((item) => item.status === 'WAITING_LIST'),
        )
      } else {
        setDialogData(mentorsData.data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allParticipantsData, mentorsData])
  const openParticipants = (status, mentorshipId) => {
    setOpenParticipantsDailog(true)
    setMentorshipId(mentorshipId)

    if (status === 'approved') {
      setDialogData(
        allParticipantsData.filter((item) => item.status === 'CONFIRMED'),
      )
      setDialogTitle('Approved Participants')
    } else if (status === 'inqueue') {
      setDialogData(
        allParticipantsData.filter((item) => item.status === 'WAITING_LIST'),
      )
      setDialogTitle('Interested participants')
    } else {
      setDialogData(mentorsData.data)
      setDialogTitle('Total Mentors')
    }
  }

  React.useEffect(() => {
    if (mentorshipExportExcelData) {
      setMentorshipExcelData(mentorshipExportExcelData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorshipExportExcelData])

  const handleClose = () => {
    setOpenParticipantsDailog(false)
  }

  const formatDate = (dateValue) => {
    let dateObj = new Date(dateValue)
    return moment(dateObj).format('ddd MMM-DD-YYYY')
  }

  const onHandleFilters = (e) => {
    const { name, value } = e.target
    setFilters({ ...filters, [name]: value })
  }

  const onEditClick = (mentorship_id) => {
    navigate(`/events/mentorship/edit/${eventId}/${mentorship_id}`)
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleCancel = (memberId, speakerid, slotid) => {
    setAnchorEl(null)
    if (dialogTitle === 'Interested participants') {
      cancelInQueueMentorship({
        eventId: eventId,
        mentorshipId: mentorshipId,
        memberId: memberId,
        speakerid: speakerid,
        slotid: slotid,
        activeSite,
      })
    } else {
      cancelMentorshipBooking({
        eventId: eventId,
        mentorshipId: mentorshipId,
        memberId: memberId,
        speakerid: speakerid,
        slotid: slotid,
        activeSite,
      })
    }
  }

  const convertDate = (dateString) => {
    let dateObj = new Date(dateString)
    return `${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`
  }

  const getSessionTimings = (timeData) => {
    if (timeData) {
      let slotValues = timeData.split('-')
      let startValue = moment(slotValues[0], ['h:mm']).toDate()
      let startTime = moment(startValue).format('HH:mm A')
      let endValue = moment(slotValues[1], ['h:mm']).toDate()
      let endTime = moment(endValue).format('HH:mm A')

      return startTime + '-' + endTime
    }
  }

  const exportToCSV = () => {
    var csvRows = []
    var obj = {}
    var data = []
    var emptyInterest = []
    mentorshipExcelData.map((item) => {
      item.speakers?.map((speaker) => {
        if (speaker?.participants && speaker?.participants.length !== 0) {
          return speaker.participants?.map((participant) => {
            const slot = getSessionTimings(participant.slot)
            obj = {}
            obj['Mentorship Name'] = item.name
            obj['Description'] = item.description
            obj['Mentorship Start date'] = moment
              .utc(item?.start_date)
              .format('MM/DD/YYYY')
            obj['Venue'] = item.venue ? item.venue : ''
            obj['Zoom link'] = item.zoom_link ? item.zoom_link : ''
            obj['Speaker Name'] = speaker?.user?.name
            obj['Designation'] = speaker?.meta?.designation
            obj['Company'] = speaker?.user?.company
            obj['Mentorship Session Start Time'] = moment(item.start_time, [
              'h:mm',
            ]).format('hh:mm A')
            obj['Mentorship Session End Time'] = moment(item.end_time, [
              'h:mm',
            ]).format('hh:mm A')
            obj['Participant Booked Slot'] = slot ? slot : ''
            obj[`Approved participant`] =
              participant.status === 'CONFIRMED' && participant?.user?.email
                ? participant.user.email
                : ''
            obj[`Interested participants`] =
              participant.status === 'WAITING_LIST' && participant?.user?.email
                ? participant.user.email
                : ''
            obj[`Name`] = participant?.user?.name
            obj[`Mobile`] = participant?.user?.mobile
            obj['Participant Company'] = participant?.user?.company
            obj['Participant Designation'] = participant?.meta?.designation
              ? participant?.meta?.designation
              : ''
            obj['Exp'] = `Exp ${getExperienceLabel({
              ...participant?.meta?.exp,
              experience: participant?.meta?.experience,
            })}`

            data.push(obj)
            return obj
          })
        } else {
          obj = {}
          obj['Mentorship Name'] = item.name
          obj['Description'] = item.description
          obj['Mentorship Start date'] = moment
            .utc(item?.start_date)
            .format('MM/DD/YYYY')
          obj['Venue'] = item.venue ? item.venue : ''
          obj['Zoom link'] = item.zoom_link ? item.zoom_link : ''
          obj['Speaker Name'] = speaker?.user?.name
          obj['Designation'] = speaker?.meta?.designation
          obj['Company'] = speaker?.user?.company
          obj['Mentorship Session Start Time'] = moment(item.start_time, [
            'h:mm',
          ]).format('hh:mm A')
          obj['Mentorship Session End Time'] = moment(item.end_time, [
            'h:mm',
          ]).format('hh:mm A')
          obj['Participant Booked Slot'] = ''
          obj[`Approved participant`] = ''
          obj[`Interested participants`] = ''
          obj[`Name`] = ''
          obj[`Mobile`] = ''
          obj['Participant Company'] = ''
          obj['Participant Designation'] = ''
          obj['Exp'] = ''
          emptyInterest.push(obj)
          return obj
        }
      })
      return item
    })
    data = [...data, ...emptyInterest]
    if (data && data.length > 0) {
      const headers = Object.keys(data[0])
      csvRows.push(headers.join(','))
      for (const row of data) {
        const values = headers.map((header) => {
          const val = row[header]
          return `"${val}"`
        })
        csvRows.push(values.join(','))
      }
      csvRows = csvRows.join('\n')

      const blob = new Blob([csvRows], { type: fileType })
      const a = document.createElement('a')
      a.download = fileName
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  return (
    <>
      {mentorshipFetching && <LinearProgress></LinearProgress>}
      <Box
        sx={{ display: 'flex', background: '#F6F7F8', p: 2 }}
        data-testid="listing-mentorship-wrapper"
      >
        <TextField
          size="small"
          value={filters?.search}
          name="search"
          sx={{ mr: 1, width: '65%' }}
          placeholder={'Search'}
          onChange={(e) => onHandleFilters(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchSharp />
              </InputAdornment>
            ),
            'data-testid': 'search-field',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            gap: '20px',
          }}
        >
          {/* <FormControl
            sx={{ minWidth: 90, minHeight: 40, justifyContent: 'center' }}
          >
            <Select
              id="demo-simple-select"
              value={status}
              size="small"
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: 0.5,
                },
              }}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={'active'}>Active</MenuItem>
              <MenuItem value={'past'}>Past</MenuItem>
            </Select>
          </FormControl> */}
          <IconButton
            sx={{ background: '#D6D6D6', borderRadius: '4px' }}
            onClick={() => exportToCSV()}
            disabled={!userCanDownloadMentorshio}
          >
            <CloudDownloadOutlined></CloudDownloadOutlined>
          </IconButton>
        </Box>
      </Box>
      {filters?.search !== '' && mentorshipListData?.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          No Records Found
        </Box>
      )}

      <Box sx={{ overflow: 'scroll', height: '70vh' }}>
        {mentorshipListData &&
          mentorshipListData.length > 0 &&
          mentorshipListData.map((mentorship, index) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 12px',
                  }}
                >
                  {formatDate(mentorship.start_date) !==
                  formatDate(mentorshipListData[index - 1]?.start_date) ? (
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {formatDate(mentorship.start_date)}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'bold' }}
                    ></Typography>
                  )}

                  {/* <Typography variant="body1" sx={{ color: '#3E72DB' }}>
                    2 Mentorships{' '}
                  </Typography> */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    background:
                      mentorshipId === mentorship?._id ? '#EFEFF1' : '#fff',
                    py: 2,
                    px: 1,
                    cursor: 'pointer',
                  }}
                  data-testid="mentorship-click"
                  onClick={() => setMentorshipId(mentorship._id)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '20px',
                    }}
                  >
                    <Avatar
                      alt="speaker_image"
                      src={mentorship.mentorship_image}
                      sx={{ height: 70, width: 70 }}
                    ></Avatar>

                    <Box sx={{ textAlign: 'left' }}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 20, fontWeight: 600 }}
                      >
                        {mentorship.name}
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: 18 }}>
                        Starts from {convertDate(mentorship.start_date)}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 14, color: '#7D7D7D' }}
                      >
                        From{' '}
                        {moment(mentorship.start_time, ['h:mm']).format(
                          'hh:mm A',
                        )}{' '}
                        to {''}
                        {moment(mentorship.end_time, ['h:mm']).format(
                          'hh:mm A',
                        )}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        border: '1px dashed #096DD9',
                        px: 1,
                        color: '#096DD9',
                      }}
                    >
                      {mentorship.mode}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '20px',
                    }}
                  >
                    <IconButton
                      disabled={editDisabled || !userCanEditMentorship}
                      sx={{
                        background: '#D6D6D6',
                        borderRadius: '4px',
                        p: 0.5,
                      }}
                      onClick={() => onEditClick(mentorship._id)}
                    >
                      <BorderColorOutlined
                        sx={{ fontSize: '1.2rem' }}
                      ></BorderColorOutlined>
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    background:
                      mentorshipId === mentorship?._id ? '#EFEFF1' : '#fff',
                    textAlign: 'left',
                    pl: 12,
                    pr: 4,
                    pb: 3,
                  }}
                >
                  {' '}
                  <Typography variant="body1" sx={{ fontSize: 14 }}>
                    {mentorship.description}
                    {/* <span
                      style={{
                        color: '#CC0000',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                    >
                      {' '}
                      +MORE
                    </span> */}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: 'center',
                    boxShadow: '0px 0px 3px 0px #888888',
                  }}
                >
                  <ButtonGroup
                    variant="text"
                    aria-label="text button group"
                    sx={{ py: 1 }}
                  >
                    <Button
                      variant="text"
                      sx={buttonGroupstyle}
                      color="success"
                      onClick={() =>
                        openParticipants('mentors', mentorship._id)
                      }
                    >
                      Total mentors {mentorship.speakers}
                    </Button>
                    <Button
                      sx={buttonGroupstyle}
                      color="info"
                      onClick={() =>
                        openParticipants('approved', mentorship._id)
                      }
                    >
                      {' '}
                      Approved participants {mentorship.confirmed}
                    </Button>
                    <Button
                      onClick={() =>
                        openParticipants('inqueue', mentorship._id)
                      }
                      variant="text"
                      sx={buttonGroupstyle}
                      color="error"
                    >
                      Interested participants {mentorship.waiting_list}
                    </Button>
                  </ButtonGroup>
                </Box>
              </>
            )
          })}
      </Box>

      <SwipeableDrawer
        anchor={'right'}
        open={openParticipantsDailog}
        onClose={handleClose}
        onOpen={handleClose}
        PaperProps={{
          sx: {
            width: '25%',
            // marginTop: '65px',
          },
        }}
      >
        <OpenDailog
          handleClose={handleClose}
          listData={dialogData}
          dialogTitle={dialogTitle}
          anchorEl={anchorEl}
          menuOpen={menuOpen}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          handleCancel={handleCancel}
          cancelBookingLoading={cancelBookingLoading}
          cancelInQueueLoading={cancelInQueueLoading}
          page={'mentorship'}
        ></OpenDailog>
      </SwipeableDrawer>
    </>
  )
}

export default ListingMentorship
