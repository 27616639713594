import React from 'react'
import {
  Pagination,
  Alert,
  Chip,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import PendingIcon from '@mui/icons-material/Pending'
import BrushIcon from '@mui/icons-material/Brush'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CancelIcon from '@mui/icons-material/Cancel'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'

const ActivityPanel = (props) => {
  const { activity = [], onClose } = props
  const [activityPage, setActivityPage] = React.useState(0)

  const paginateActivty = (pageValue) => {
    setActivityPage(pageValue - 1)
  }
  return (
    <>
      {activity.length > 0 ? (
        <>
          <Box sx={{ mt: 1 }}>
            <List sx={{ pb: 0 }}>
              <ListItem>
                <ListItemText
                  primary={`Activity`}
                  primaryTypographyProps={{ fontSize: 20 }}
                ></ListItemText>
              </ListItem>
              <Divider sx={{ bgcolor: '#B5B5B5' }} />
            </List>
            <Pagination
              count={Math.ceil(activity.length / 10)}
              color="primary"
              sx={{ mb: 2 }}
              onChange={(_, pageValue) => paginateActivty(pageValue)}
              showFirstButton
              showLastButton
            />
            <Timeline align="left">
              {activity
                .slice(activityPage * 10, activityPage * 10 + 10)
                .map((action, key) => {
                  return (
                    <TimelineItem key={key}>
                      <TimelineOppositeContent
                        sx={{
                          flex: 0.4,
                          fontSize: 12,
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        color="textSecondary"
                      >
                        {moment(action.updatedAt).fromNow()}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot
                          sx={{
                            color: '#fff',
                            background: (theme) => theme.palette.primary.main,
                          }}
                        >
                          {action.status === 'draft' ? (
                            <BrushIcon />
                          ) : action.status === 'pending_approval' ? (
                            <PendingIcon />
                          ) : action.status === 'approved' ? (
                            <CheckBoxIcon />
                          ) : action.status === 'rejected' ? (
                            <CancelIcon />
                          ) : action.status === 'published' ? (
                            <PublishedWithChangesIcon />
                          ) : (
                            <MoreHorizIcon />
                          )}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        Page status changed into{' '}
                        <Chip
                          size="small"
                          label={action.status.replace('_', ' ')}
                        />{' '}
                        by{' '}
                        <Chip
                          size="small"
                          label={action.updated_by.displayName}
                        />{' '}
                      </TimelineContent>
                    </TimelineItem>
                  )
                })}
            </Timeline>
          </Box>
          <Box>
            <IconButton
              type="submit"
              aria-label="close menu"
              onClick={onClose}
              sx={{
                position: 'absolute',
                top: '12px',
                right: '5px',
                float: 'right',
              }}
            >
              <CloseIcon
                sx={{
                  color: 'rgb(0,0,0,0.87)',
                  fontSize: '2rem',
                }}
              ></CloseIcon>
            </IconButton>
          </Box>
        </>
      ) : (
        <Alert severity="info">No status changes detected for this page</Alert>
      )}
    </>
  )
}

export default ActivityPanel
