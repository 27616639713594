import React from 'react'
import Listing from './Listing'
import ListingSpeakers from './ListingSpeakers'
import { Grid } from '@mui/material'

const List = (props) => {
  const {
    workshopListData,
    workshopFetching,
    speakersFetching,
    setWorkshopId,
    workshopSpeakerData,
    setFilters,
    filters,
    workshopId,
    eventId,
    exportToCSV,
    editDisabled = false,
    partcipantsListData,
    partcipantsFetching,
    actionsAllowed = {},
  } = props

  return (
    <Grid
      container
      sx={{ backgroundColor: '#F6F7F8' }}
      data-testid="workshop-list-wrapper"
    >
      <Grid
        item
        xs={4}
        sx={{ backgroundColor: '#fff', boxShadow: '0px 0px 3px 0px #888888' }}
      >
        <Listing
          eventId={eventId}
          workshopListData={workshopListData}
          setWorkshopId={setWorkshopId}
          workshopId={workshopId}
          setFilters={setFilters}
          filters={filters}
          workshopFetching={workshopFetching}
          exportToCSV={exportToCSV}
          editDisabled={editDisabled}
          partcipantsListData={partcipantsListData}
          partcipantsFetching={partcipantsFetching}
          speakersData={workshopSpeakerData}
          actionsAllowed={actionsAllowed}
        ></Listing>
      </Grid>
      <Grid item xs={0.1}></Grid>
      <Grid
        item
        xs={7.9}
        sx={{ backgroundColor: '#fff', boxShadow: '0px 0px 3px 0px #888888' }}
      >
        {workshopListData && workshopListData.length > 0 && (
          <ListingSpeakers
            speakersData={workshopSpeakerData}
            speakersFetching={speakersFetching}
          ></ListingSpeakers>
        )}
      </Grid>
    </Grid>
  )
}

export default List
