import { Assessment, Group } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const Reports = (props) => {
  const { event = {} } = props
  const { id = null } = event
  const navigate = useNavigate()
  const cardAction = (key) => {
    switch (key) {
      case 'emailer':
        navigate(`/events/reports/emailer/${id}`)
        break
      case 'participants':
        navigate(`/events/reports/participant-report/${id}`)
        break
      case 'invenue':
        navigate(`/events/reports/invenue-report/${id}`)
        break
      default:
        break
    }
  }
  return (
    <Box>
      <Grid container sx={{ p: 2 }} spacing={3}>
        <Grid item>
          <Card sx={{ width: 345, height: 165 }} elevation={3}>
            <CardActionArea onClick={() => cardAction('emailer')}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Assessment sx={{ color: '#cc0000', fontSize: 50 }} />
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Email Reports
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  This report helps to track and finding the emailer status.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ width: 345, height: 165 }} elevation={3}>
            <CardActionArea onClick={() => cardAction('participants')}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Group sx={{ color: '#cc0000', fontSize: 50 }} />
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Participant Reports
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Download the participant reports using different filters
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item>
          <Card sx={{ width: 345, height: 165 }} elevation={3}>
            <CardActionArea onClick={() => cardAction('invenue')}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Group sx={{ color: '#cc0000', fontSize: 50 }} />
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  Invenue Reports
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Download the invenue reports using different filters
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Reports
