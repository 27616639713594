import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import defaulteventthumb from './EventAssets/thumbnail-for-event.png'
import { useNavigate } from 'react-router-dom'
import { Archive, Edit } from '@mui/icons-material'
import moment from 'moment'
import ConfirmationDialog from './ConfirmationDialog'

const EventsCardList = (props) => {
  const {
    id = '',
    name = '',
    selected = false,
    start_date = '',
    end_date = '',
    total_registrations = 0,
    approved_participants = 0,
    rejected_participants = 0,
    thumbnail_image = '',
    type = 'hybrid',
    archiveEvent,
    status,
    editDisabled = false,
    userCanDeleteEvent = false,
    userCanEditEvent = false,
  } = props

  const [dialogOpenForConfirm, setDialogOpenForConfirm] = React.useState(false)

  const navigate = useNavigate()
  return (
    <div data-testid={`list-${id}`}>
      <Card
        sx={{
          borderRadius: 0,
          p: 1,
          position: 'relative',
          border: selected
            ? '1px solid rgba(105, 137, 252, 0.54)'
            : '1px solid #d3d3d3',
          filter: selected
            ? 'drop-shadow(0px 6px 6px rgba(105, 137, 252, 0.54))'
            : '',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip title="Click to configure event information">
          <CardActionArea
            onClick={() => navigate(`/events/event-setup/${id}`)}
            sx={{
              border: '1px dotted #d3d3d3',
              '&:hover': {
                border: '1px dotted #096DD9',
                boxShadow: 3,
              },
              m: 0,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardMedia
                image={thumbnail_image ? thumbnail_image : defaulteventthumb}
                sx={{
                  width: 58,
                  height: 58,
                  backgroundSize: 'contain',
                  backgroundColor: '#fff',
                  ml: 1,
                  minHeight: 78,
                }}
              />
              <Box sx={{ p: 0, pl: 2, pb: 0, display: 'flex', width: '100%' }}>
                <Box
                  sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    minWidth: 300,
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    sx={{ fontSize: 24, fontWeight: 600 }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: '#7D7D7D',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <strong>{moment(start_date).format('DD-MMM-YYYY')}</strong>
                    {' to '}
                    <strong>{moment(end_date).format('DD-MMM-YYYY')}</strong>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Chip
                    variant="outlined"
                    label={type}
                    sx={{
                      color: '#096DD9',
                      border: '1px dashed #096DD9',
                      borderRadius: 0,
                      fontWeight: 600,
                      width: 100,
                      ml: 2,
                    }}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      color: '#262626',
                      pt: 0.5,
                    }}
                  >
                    Total Registered{' '}
                    {total_registrations ? total_registrations : 'NA'}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      color: '#CC0000',
                      pt: 0.5,
                    }}
                  >
                    Approved Participants{' '}
                    {approved_participants ? approved_participants : 'NA'}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      color: '#FF0000',
                      pt: 0.5,
                    }}
                  >
                    Rejected Participants{' '}
                    {rejected_participants ? rejected_participants : 'NA'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardActionArea>
        </Tooltip>
        {!editDisabled && (
          <Box sx={{ m: 1, display: 'flex' }}>
            <Tooltip title="Edit">
              <IconButton
                size={'small'}
                onClick={() => navigate(`/events/edit/${id}`)}
                data-testid={`list-edit-${id}`}
                disabled={!userCanEditEvent}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            {status !== 'archived' && (
              <Tooltip title="Archive the event">
                <IconButton
                  sx={{ color: '#FF0000', ml: 0.5 }}
                  size={'small'}
                  onClick={() => setDialogOpenForConfirm(true)}
                  data-testid={`list-archive-${id}`}
                  disabled={!userCanDeleteEvent}
                >
                  <Archive />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Card>
      <ConfirmationDialog
        open={dialogOpenForConfirm}
        setOpen={setDialogOpenForConfirm}
        onApprove={() => {
          archiveEvent(id)
          setDialogOpenForConfirm(false)
        }}
        heading={`Do you really want to archive the event ${name}?`}
        testid={`dialog-${id}`}
      />
    </div>
  )
}

export default EventsCardList
