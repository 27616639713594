import { Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

function GridList(props) {
  const { data, children } = props
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container>
        {data.map((value, index) => {
          return index % 2 !== 0 ? (
            <Grid item xs={3} key={`grid-odd-${index}`}>
              {children(value, index)}
            </Grid>
          ) : (
            <Grid item xs={3} key={`grid-even-${index}`}>
              {children(value, index)}
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

GridList.propTypes = {
  data: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
}

export default GridList
