import React, { useState } from 'react'
import {
  IconButton,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import MediaLibrary from '../containers/MediaLibrary/MediaLibrary'
import PropTypes from 'prop-types'
import apiConfig from '../apiConfig'
import { useDispatch, useSelector } from 'react-redux'
import { persistMediaList } from '../api/session'
import CloudUpload from '../assets/Image/CloudUpload.svg'

const MediaPicker = (props) => {
  const { dataCallBack, value, disabled = false, isMediaOnly = false } = props
  const { media } = apiConfig
  const BASEURL = media.host
  const [isOpen, setOpen] = useState(isMediaOnly)
  const [pickedItem, setPickedItem] = React.useState(null)
  const [tempPicked, setTempPicked] = React.useState(null)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const dispatch = useDispatch()
  const mediaArr = useSelector((state) => state.session.mediaList)

  React.useEffect(() => {
    value && setPickedItem(value)
    return () => {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setPickedItem(value ? value : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  return (
    <React.Fragment>
      {!isMediaOnly && (
        <IconButton
          sx={{
            borderRadius: 1,
            border: '3px dashed #d6d6d6 !important',
            width: '130px',
            height: '130px',
            my: '10px',
          }}
          disabled={disabled}
          onClick={() => pickedItem === null && handleOpen()}
        >
          <Stack direction={'column'} spacing={2} sx={{ alignItems: 'center' }}>
            {pickedItem !== null ? (
              <Stack direction={'column'} sx={{ alignItems: 'center' }}>
                {pickedItem &&
                (String(pickedItem).substring(
                  pickedItem.lastIndexOf('.') + 1,
                ) === 'png' ||
                  String(pickedItem).substring(
                    pickedItem.lastIndexOf('.') + 1,
                  ) === 'jpeg' ||
                  String(pickedItem).substring(
                    pickedItem.lastIndexOf('.') + 1,
                  ) === 'jpg') ? (
                  <img
                    src={pickedItem}
                    style={{
                      height: 120,
                      backgroundColor: 'lightgrey',
                      backgroundSize: 'cover',
                      borderRadius: 5,
                      borderWidth: 1,
                      maxWidth: 120,
                      zIndex: 0,
                      position: 'relative',
                    }}
                    alt=""
                  />
                ) : (
                  <div
                    id="media-picker-btn"
                    style={{
                      overflow: 'scroll',
                      textOverflow: 'ellipsis',
                      width: '70%',
                      maxWidth: 120,
                      height: 120,
                      scrollbarWidth: 'thin',
                    }}
                  >
                    <Typography
                      gutterBottom
                      component="span"
                      variant="body2"
                      sx={{ wordWrap: 'break-word' }}
                    >
                      {String(pickedItem).substring(
                        pickedItem.lastIndexOf('/') + 1,
                      )}
                    </Typography>
                  </div>
                )}
                <IconButton
                  sx={{
                    color: 'black',
                    zIndex: 1,
                    position: 'absolute',
                    left: 95,
                    top: -7,
                  }}
                  onClick={() => {
                    let imagePath = pickedItem.replace(BASEURL, '')
                    let tempArray = [...mediaArr]
                    tempArray.splice(
                      tempArray.findIndex((e) => imagePath === e.path),
                      1,
                    )
                    dispatch(persistMediaList(tempArray))
                    dataCallBack('')
                    setPickedItem(null)
                    handleClose()
                  }}
                  disabled={disabled}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Stack>
            ) : (
              <>
                <CloudUpload />
                <Typography
                  sx={{
                    color: '#333333',
                    fontSize: 12,
                    fontFamily: 'Helvetica for Target',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    mt: '0px !important',
                  }}
                >
                  Upload Here
                </Typography>
              </>
            )}
          </Stack>
        </IconButton>
      )}
      <Dialog
        open={isOpen}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth={'xl'}
        onBackdropClic={undefined}
      >
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
          >
            <>
              <MediaLibrary
                isFromModal={true}
                callBack={(value) => {
                  setTempPicked(value)
                }}
              />
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              position: 'relative',
              right: 70,
              color: '#CC0000',
              borderColor: '#CC0000',
            }}
            onClick={() => {
              dataCallBack(null, null)
              setPickedItem(null)
              handleClose()
            }}
            variant="outlined"
          >
            cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#CC0000',
              ':hover': { backgroundColor: '#CC0000' },
              position: 'relative',
              right: 50,
              width: '90px',
            }}
            onClick={() => {
              dataCallBack(
                tempPicked ? BASEURL + tempPicked.path : '',
                tempPicked,
              )
              setPickedItem(tempPicked ? BASEURL + tempPicked.path : null)
              handleClose()
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

MediaPicker.propTypes = {
  dataCallBack: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default MediaPicker
