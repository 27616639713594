import React, { useState, useEffect } from 'react'
import {
  Typography,
  MenuItem,
  IconButton,
  Box,
  Button,
  Tooltip,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Pagination,
  TextField,
  InputAdornment,
  Link,
  Divider,
  Card,
  CardContent,
} from '@mui/material'
import Select from '@mui/material/Select'
import withRights from '../../components/withRights'
import AccessDenied from '../../Layout/AccessDenied'
import ManageDataGrid from '../../components/ManageDataGrid'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate, useLocation } from 'react-router-dom'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import Loader from '../../components/Loader'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import {
  useGetPagesQuery,
  useGetRevisionQuery,
  useGetRevisionDataQuery,
} from '../../api/page'
import { useSelector } from 'react-redux'
import FormControl from '@mui/material/FormControl'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  getGridStringOperators,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridOverlay,
} from '@mui/x-data-grid'
import PreviewFullscreen from './PageEditor/FullScreenPreview/PreviewFullscreen'
import {
  useSendForApprovalMutation,
  useAddCommentMutation,
  useGeneratePreviewMutation,
  useGetUsedPageMediaDataQuery,
} from '../../api/page'
import { useGetConfigurationBySlugQuery } from '../../api/configuration'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import DialogConfirmation from '../../components/DialogConfirmation'
import ModeIcon from '@mui/icons-material/Mode'
import SummarizeIcon from '@mui/icons-material/Summarize'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { findLinksInJson } from '../../utils/dataParser'
import GppGoodIcon from '@mui/icons-material/GppGood'
import InfoIcon from '@mui/icons-material/Info'
import LinkIcon from '@mui/icons-material/Link'
import SplitButton from '../../components/SplitButton'
import SearchIcon from '@mui/icons-material/Search'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import RestoreIcon from '@mui/icons-material/Restore'
import ShowVersions from './Versions/ShowVersions'
import {
  currentVersionEditable,
  getLatestVersionFromPage,
  getLatestVersionIdFromPage,
  getStatusOfThePageVersion,
  isThatPublishedPage,
  versionAssignedToThisUser,
} from './pageUtils'
import HtmlTooltip from '../../components/HtmlTooltip'
import LockIcon from '@mui/icons-material/Lock'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import { getSize } from '../../utils/helper'
import { Edit } from '@mui/icons-material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import EditIcon from '../../assets/Image/edit.svg'
import { useSnackbar } from 'notistack'
import { STATUS_TEXT } from '../../utils/constant'

export const LoadActionButtons = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedRows = [], actionsAllowed = {} } = props
  const [openFullScreenPreview, setFullscreenPreview] = useState(false)
  const [selectedLayoutForPreview, setSelectedLayoutForPreview] = useState([])
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const {
    userCanCreatePage = false,
    userCanApprove = false,
    userCanPreviewPage = false,
    userCanPublish = false,
    userCanEditPage = false,
    userCanAssign = false,
    userCanViewRevisions = false,
  } = actionsAllowed
  const rowsSelected = selectedRows.length > 0 ? true : false
  const [selectedAction, setSelectedAction] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [siteData, setSiteData] = useState({})
  const [addCommentToPage] = useAddCommentMutation()
  const [displayVersions, setVersionDisplay] = useState(false)
  const [selectedVersionId, setSelectedVersionId] = useState(null)
  const [layoutData, setLayoutData] = useState([])
  const [approveButtonFlag, setApproveButtonFlag] = useState(false)
  const [
    changingPageStatus,
    {
      isLoading: statusChangeInProgress,
      isSuccess: actionDone,
      isError: actionFailed,
    },
  ] = useSendForApprovalMutation()
  const { enqueueSnackbar } = useSnackbar()

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'site',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [
    generatePreview,
    {
      data: previewData,
      isSuccess: previewGenerated,
      isLoading: previewGenerationInProgress,
    },
  ] = useGeneratePreviewMutation()

  const {
    data: selectedVersionForPreview,
    isFetching: previewDataFetchInProgress,
    isLoading: previewDataFetchFirstInProgress,
    isSuccess: previewDataFetchSuccess,
    refetch: reFetchPage,
  } = useGetRevisionQuery(
    { id: selectedVersionId, activeSite },
    {
      refetchOnMountOrArgChange: true,
      skip: !(selectedVersionId && activeSite),
    },
  )

  const { data: selectedLayout } = useGetRevisionDataQuery(
    { selectedRows: selectedRows, activeSite },
    {
      skip: !(selectedRows && activeSite && approveButtonFlag),
    },
  )

  useEffect(() => {
    if (selectedLayout && selectedLayout.length > 0) {
      setLayoutData(selectedLayout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayout])

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      if (settings.length === 0) {
        setSiteData({})
      } else {
        settings.map((conf) => {
          if (conf.name === 'site') {
            setSiteData(conf.value ? conf.value : {})
          }
          return true
        })
      }
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  useEffect(() => {
    if (selectedVersionForPreview) {
      setSelectedLayoutForPreview(
        selectedVersionForPreview.layout
          ? selectedVersionForPreview.layout
          : [],
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previewDataFetchInProgress,
    previewDataFetchSuccess,
    previewDataFetchFirstInProgress,
  ])

  const tooltipMsgs = {
    create: 'Click to create a new page',
    preview: 'Click to preview the page',
    revert: `Click to revoke the approval${selectedRows.length > 1 ? 's' : ''}`,
    approve: `Click to approve the page${selectedRows.length > 1 ? 's' : ''}`,
    reject: `Click to reject the page${selectedRows.length > 1 ? 's' : ''}`,
    publish: `Click to take page${selectedRows.length > 1 ? 's' : ''} live`,
    unpublish: `Click to unpublish selected page${
      selectedRows.length > 1 ? 's' : ''
    }`,
    wip: 'Click to change the status to Work In Progress',
    assign: `Click to reassign the page${selectedRows.length > 1 ? 's' : ''}`,
    revisions: 'Click to review version history',
  }

  const previewThePage = () => {
    if (selectedRows.length === 1) {
      const selectedVersionId = getLatestVersionIdFromPage(selectedRows[0])
      setFullscreenPreview(true)
      setSelectedLayoutForPreview(null)
      setSelectedVersionId(selectedVersionId)
      reFetchPage()
    }
  }

  const showVersionsList = () => {
    setVersionDisplay(true)
  }

  const selectedPagesAssignedToMe = (pagesList, lanId) => {
    let assignedToMe = true
    if (pagesList && pagesList.length > 0) {
      pagesList.map((page) => {
        if (
          page.assignedto &&
          page.assignedto[0] &&
          page.assignedto[0].lanId &&
          page.assignedto[0].lanId.toLowerCase() !== lanId.toLowerCase()
        ) {
          assignedToMe = false
        }
        return true
      })
    }
    return assignedToMe
  }

  const selectedPagesAssignedByMe = (pagesList, lanId) => {
    let assignedByMe = true
    if (pagesList && pagesList.length > 0) {
      pagesList.map((page) => {
        if (
          page.assignedby &&
          page.assignedby[0] &&
          page.assignedby[0].lanId &&
          page.assignedby[0].lanId.toLowerCase() !== lanId.toLowerCase()
        ) {
          assignedByMe = false
        }
        return true
      })
    }
    return assignedByMe
  }

  const SelectedItems = (props) => {
    const { forAssign = false } = props
    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (_, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    }

    const { data: savedMediaList } = useGetUsedPageMediaDataQuery(
      selectedRows,
      {
        refetchOnMountOrArgChange: true,
        skip: !activeSite || !selectedRows,
      },
    )

    const MediaListBulider = (props) => {
      const { input } = props
      let data = input ? [...input.data] : []
      let sortedMedia = data.sort((e1, e2) =>
        e1.size < e2.size ? 1 : e1.size > e2.size ? -1 : 0,
      )
      return (
        <List>
          {sortedMedia?.map((e, key) => {
            return (
              <ListItemButton
                key={key}
                sx={() => ({
                  border: '1px solid #d3d3d3',
                  pt: 0,
                  pb: 0,
                })}
                disableRipple
              >
                <ListItemText
                  primary={e?.name}
                  primaryTypographyProps={{
                    style: {
                      fontSize: 16,
                      width: '80%',
                    },
                  }}
                />
                <Typography variant="body2">{getSize(e?.size)}</Typography>
              </ListItemButton>
            )
          })}
        </List>
      )
    }

    return (
      <>
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          Selected Pages
        </Typography>
        <List>
          {selectedRows &&
            selectedRows.map((selected, key) => {
              const links = findLinksInJson(
                layoutData && layoutData.length > 0
                  ? layoutData[key]?.layout
                  : [],
              )
              let mediaUsed = savedMediaList
                ? savedMediaList.filter(
                    (item) => selected._id === item?.content,
                  )
                : []
              mediaUsed = mediaUsed[0] ?? undefined
              return (
                <React.Fragment key={key}>
                  <Card sx={{ mb: 1 }}>
                    <CardContent sx={{ padding: '10px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          margin: '10px 0px 10px 0px',
                        }}
                      >
                        <SummarizeIcon sx={{ mr: 1 }} />
                        <Typography
                          sx={{
                            width: '33%',
                            flexShrink: 0,
                            fontWeight: 'bold',
                          }}
                        >
                          {selected.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {selected.assignedby &&
                          selected.assignedby[0] &&
                          !forAssign
                            ? `Requested by : ${selected.assignedby[0].displayName}`
                            : ''}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                      </Box>
                      {(savedMediaList && savedMediaList?.length > 0) ||
                      links.length !== 0 ? (
                        <>
                          {links.length !== 0 ? (
                            <>
                              <Accordion
                                expanded={expanded === selected._id}
                                onChange={handleChange(selected._id)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <div style={{ width: '100%' }}>
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        mt: 1,
                                      }}
                                    >
                                      {selected.status.status ===
                                        'pending_approval' && (
                                        <>
                                          <InfoIcon
                                            color="warning"
                                            sx={{ mr: 1, fontSize: 20 }}
                                          />
                                          <Typography variant="caption">
                                            {
                                              'There are some external links found in the page. Please validate before approval'
                                            }
                                          </Typography>
                                        </>
                                      )}
                                    </Box>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography sx={{ fontWeight: 'bold' }}>
                                    External links:{' '}
                                  </Typography>
                                  <List>
                                    {links.map((link, key) => {
                                      return (
                                        <ListItemButton
                                          key={key}
                                          sx={() => ({
                                            border: '1px solid #d3d3d3',
                                            pt: 0,
                                            pb: 0,
                                          })}
                                          onClick={() => window.open(link)}
                                        >
                                          <ListItemIcon>
                                            <LinkIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={link} />
                                        </ListItemButton>
                                      )
                                    })}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : (
                            selected.status.status === 'pending_approval' && (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: 1,
                                  ml: 2,
                                }}
                              >
                                <>
                                  <GppGoodIcon
                                    color="success"
                                    sx={{ mr: 1, fontSize: 20 }}
                                  />
                                  <Typography variant="caption">
                                    {
                                      "No external links found on this page. It's safe to publish."
                                    }
                                  </Typography>
                                </>
                              </Box>
                            )
                          )}

                          {mediaUsed?.data?.length > 0 ? (
                            <Accordion
                              expanded={expanded === `media-${selected._id}`}
                              onChange={handleChange(`media-${selected._id}`)}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div style={{ width: '100%' }}>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      mt: 1,
                                    }}
                                  >
                                    <>
                                      {mediaUsed?.data?.reduce(
                                        (acc, item) => acc + item.size,
                                        0,
                                      ) >= 1000000 ? (
                                        <InfoIcon
                                          color="warning"
                                          sx={{ mr: 1, fontSize: 20 }}
                                        />
                                      ) : (
                                        <GppGoodIcon
                                          color="success"
                                          sx={{ mr: 1, fontSize: 20 }}
                                        />
                                      )}
                                      {
                                        <Typography variant="caption">
                                          {`Total page size- ${getSize(
                                            mediaUsed?.data?.reduce(
                                              (acc, item) => acc + item.size,
                                              0,
                                            ),
                                          )}`}
                                        </Typography>
                                      }
                                    </>
                                  </Box>
                                </div>
                              </AccordionSummary>
                              {
                                <AccordionDetails>
                                  <>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                      Media List:{' '}
                                    </Typography>
                                    <MediaListBulider input={mediaUsed} />
                                  </>
                                </AccordionDetails>
                              }
                            </Accordion>
                          ) : (
                            <div style={{ width: '100%' }}>
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  mt: 1,
                                  mb: 1,
                                  ml: 2,
                                }}
                              >
                                {selected.status.status ===
                                  'pending_approval' && (
                                  <>
                                    <GppGoodIcon
                                      color="success"
                                      sx={{ mr: 1, fontSize: 20 }}
                                    />

                                    <Typography variant="caption">
                                      {`This page has not any media `}
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  </Card>
                </React.Fragment>
              )
            })}
        </List>
      </>
    )
  }

  const approveAllPages = (comment) => {
    selectedRows.map((page) => {
      addCommentToPage({
        id: page._id,
        revision: getLatestVersionIdFromPage(page),
        comment:
          comment + '. Page status changed into <strong>Approved</strong>',
      })
      const payload = {
        id: getLatestVersionIdFromPage(page),
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'approved',
      }
      return changingPageStatus(payload)
    })
    !actionFailed &&
      actionDone &&
      enqueueSnackbar(`Page${selectedRows.length > 1 ? 's' : ''} approved`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const rejectAllPages = (comment) => {
    selectedRows.map((page) => {
      addCommentToPage({
        id: page._id,
        revision: getLatestVersionIdFromPage(page),
        comment:
          comment + '. Page status changed into <strong>Rejected</strong>',
      })
      const payload = {
        id: getLatestVersionIdFromPage(page),
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'rejected',
      }
      return changingPageStatus(payload)
    })
    actionDone &&
      enqueueSnackbar(`Page${selectedRows.length > 1 ? 's' : ''} rejected`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const revertApprovalOrRejection = (comment) => {
    selectedRows.map((page) => {
      addCommentToPage({
        id: page._id,
        revision: getLatestVersionIdFromPage(page),
        comment:
          comment + '. Page status changed into <strong>Pending</strong>',
      })
      const payload = {
        id: getLatestVersionIdFromPage(page),
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'pending_approval',
      }
      return changingPageStatus(payload)
    })
    actionDone &&
      enqueueSnackbar(`Page${selectedRows.length > 1 ? 's' : ''} reverted`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const publishThePage = (comment) => {
    selectedRows.map((page) => {
      addCommentToPage({
        id: page._id,
        revision: getLatestVersionIdFromPage(page),
        comment:
          comment + '. Page status changed into <strong>Published</strong>',
      })
      const payload = {
        id: getLatestVersionIdFromPage(page),
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'published',
      }
      return changingPageStatus(payload)
    })
    actionDone &&
      enqueueSnackbar(
        `Page${selectedRows.length > 1 ? 's' : ''} published successfully`,
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      )
    setSelectedAction('')
    setDialogOpen(false)
  }

  const unPublishThePage = (comment) => {
    selectedRows.map((page) => {
      addCommentToPage({
        id: page._id,
        revision: getLatestVersionIdFromPage(page),
        comment:
          comment + '. Page status changed into <strong>Un published</strong>',
      })
      const payload = {
        id: getLatestVersionIdFromPage(page),
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'unpublished',
      }
      return changingPageStatus(payload)
    })
    actionDone &&
      enqueueSnackbar(
        `Page${selectedRows.length > 1 ? 's' : ''} unpublished successfully`,
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      )
    setSelectedAction('')
    setDialogOpen(false)
  }

  const changeWIP = () => {
    selectedRows.map((page) => {
      const payload = {
        id: getLatestVersionIdFromPage(page),
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'draft',
      }
      return changingPageStatus(payload)
    })
    actionDone &&
      enqueueSnackbar(`Status changed to WIP`, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const assignTo = ({ comment, assignedTo, currentStatus }) => {
    selectedRows.map((page) => {
      addCommentToPage({
        id: page._id,
        revision: getLatestVersionIdFromPage(page),
        comment: comment + '. <strong>New assignment</strong>',
      })
      const payload = {
        id: getLatestVersionIdFromPage(page),
        assignedto: assignedTo,
        site: activeSite._id,
        status: currentStatus,
      }
      return changingPageStatus(payload)
    })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const actionsList = {
    approve: {
      cb: approveAllPages,
      secondCb: rejectAllPages,
      rejection: true,
      actionButtonLabel: 'Approve',
      title: `Are you sure you want to approve the change${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      commentEnabled: true,
    },
    reject: {
      cb: rejectAllPages,
      actionButtonLabel: 'Reject',
      title: `Are you sure you want to reject the selected page${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      commentEnabled: true,
    },
    revertApproval: {
      cb: revertApprovalOrRejection,
      actionButtonLabel: 'Revert',
      title: `Are you sure you want to revert back the approvals from the selected page${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      commentEnabled: true,
    },
    revertRejection: {
      cb: revertApprovalOrRejection,
      actionButtonLabel: 'Revert',
      title: `Are you sure you want to revert back the rejections from the selected page${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      commentEnabled: true,
    },
    publish: {
      cb: publishThePage,
      actionButtonLabel: 'Publish',
      title: `Are you sure you want to publish the selected page${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      commentEnabled: true,
    },
    unpublish: {
      cb: unPublishThePage,
      actionButtonLabel: 'Unpublish',
      title: `Are you sure you want to unpublish the selected page${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      commentEnabled: true,
    },
    wip: {
      cb: changeWIP,
      actionButtonLabel: 'Change to WIP',
      title: `Are you sure you want to change the page${
        selectedRows.length > 1 ? 's' : ''
      } status to work in progress?`,
    },
    assign: {
      cb: assignTo,
      actionButtonLabel: 'Assign',
      title: `Are you sure you want to reassign the page${
        selectedRows.length > 1 ? 's' : ''
      }?`,
      assignment: true,
      commentEnabled: true,
    },
  }

  const initiateAction = (action) => {
    setSelectedAction(action)
    setDialogOpen(true)
  }

  const failSafe = () => {}

  const onSitePreview = () => {
    if (siteData && siteData.siteUrl && previewGenerated) {
      window.open(
        `${siteData.siteUrl}${
          selectedRows[0].slug ? selectedRows[0].slug : ''
        }?preview=${previewData ? previewData.token : ''}`,
      )
    }
  }

  const onApproveClick = () => {
    setApproveButtonFlag(true)
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {rowsSelected && (
          <>
            {selectedRows.length === 1 && (
              <>
                <Box sx={{ mr: 1 }}>
                  <SplitButton
                    disabled={!userCanPreviewPage}
                    loading={previewDataFetchInProgress}
                    options={[
                      { label: 'Preview on Stencil', value: 'previewInCMS' },
                      {
                        label: 'Preview on Site',
                        value: 'previewOnSite',
                        onSelectCb: true,
                        cb: () => {
                          if (
                            (!previewGenerated ||
                              !previewData ||
                              previewData.page !== selectedRows[0]._id) &&
                            !previewGenerationInProgress
                          ) {
                            generatePreview({
                              id: selectedRows[0]._id,
                              revision: getLatestVersionIdFromPage(
                                selectedRows[0],
                              ),
                            })
                          }
                        },
                      },
                    ]}
                    onClick={(val) => {
                      if (val.value === 'previewInCMS') {
                        previewThePage()
                      }
                      if (val.value === 'previewOnSite') {
                        onSitePreview()
                      }
                    }}
                  />
                </Box>
              </>
            )}
            {selectedRows.length === 1 && (
              <>
                <Box sx={{ mr: 1 }}>
                  <Button
                    variant={'outlined'}
                    color="primary"
                    onClick={showVersionsList}
                    startIcon={<RestoreIcon />}
                    disabled={!(userCanPublish || userCanViewRevisions)}
                    sx={{
                      textTransform: 'none',
                      mr: 1,
                      border: '1px solid',
                      padding: '4px 10px',
                      ':hover': { borderStyle: 'dashed' },
                      ':focus': { borderStyle: 'dashed' },
                    }}
                    size="small"
                  >
                    Version History
                  </Button>
                </Box>
              </>
            )}
            {selectedPagesAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'pending_approval' && (
                  <Tooltip title={tooltipMsgs.approve}>
                    <Button
                      variant={'outlined'}
                      color="primary"
                      onClick={() => {
                        initiateAction('approve')
                        onApproveClick()
                      }}
                      startIcon={<DoneAllIcon />}
                      disabled={!userCanApprove || statusChangeInProgress}
                      sx={{
                        textTransform: 'none',
                        mr: 1,
                        border: '1px solid',
                        padding: '4px 10px',
                        ':hover': { borderStyle: 'dashed' },
                        ':focus': { borderStyle: 'dashed' },
                      }}
                      size="small"
                    >
                      Approve {selectedRows.length > 1 ? 'all' : ''}
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPagesAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'pending_approval' && (
                  <Tooltip title={tooltipMsgs.reject}>
                    <Button
                      variant={'outlined'}
                      color="secondary"
                      onClick={() => initiateAction('reject')}
                      startIcon={<DoNotDisturbOnIcon />}
                      disabled={!userCanApprove || statusChangeInProgress}
                      sx={{
                        textTransform: 'none',
                        mr: 1,
                        border: '1px solid',
                        padding: '4px 10px',
                        ':hover': { borderStyle: 'dashed' },
                        ':focus': { borderStyle: 'dashed' },
                      }}
                      size="small"
                    >
                      Reject {selectedRows.length > 1 ? 'all' : ''}
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPagesAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {(selectedRows[0].status.status === 'approved' ||
                  selectedRows[0].status.status === 'unpublished') && (
                  <Tooltip title={tooltipMsgs.publish}>
                    <span>
                      <Button
                        variant={'contained'}
                        color="primary"
                        onClick={() => initiateAction('publish')}
                        startIcon={<PublicIcon />}
                        disabled={!userCanApprove || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid #263238',
                          padding: '4px 10px',
                          ':hover': {
                            borderStyle: 'dashed',
                            borderColor: '#fff',
                          },
                          ':focus': {
                            borderStyle: 'dashed',
                            borderColor: '#fff',
                          },
                        }}
                        size="small"
                      >
                        Publish page{selectedRows.length > 1 ? 's' : ''}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            <>
              {selectedRows[0].status.status === 'published' && (
                <Tooltip title={tooltipMsgs.unpublish}>
                  <span>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => initiateAction('unpublish')}
                      startIcon={<PublicOffIcon />}
                      disabled={!userCanPublish || statusChangeInProgress}
                      sx={{
                        textTransform: 'none',
                        mr: 1,
                        border: '1px solid #b71c1c',
                        padding: '4px 10px',
                      }}
                      size="small"
                    >
                      Unpublish page{selectedRows.length > 1 ? 's' : ''}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </>
            {selectedPagesAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'approved' && (
                  <Tooltip title={tooltipMsgs.revert}>
                    <span>
                      <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => initiateAction('revertApproval')}
                        startIcon={<SettingsBackupRestoreIcon />}
                        disabled={!userCanApprove || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid',
                          padding: '4px 10px',
                          ':hover': { borderStyle: 'dashed' },
                          ':focus': { borderStyle: 'dashed' },
                        }}
                        size="small"
                      >
                        Revert approval{selectedRows.length > 1 ? 's' : ''}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPagesAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'rejected' && (
                  <Tooltip title={tooltipMsgs.revert}>
                    <span>
                      <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => initiateAction('revertRejection')}
                        startIcon={<SettingsBackupRestoreIcon />}
                        disabled={!userCanApprove || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid',
                          padding: '4px 10px',
                          ':hover': { borderStyle: 'dashed' },
                          ':focus': { borderStyle: 'dashed' },
                        }}
                        size="small"
                      >
                        Revert rejection{selectedRows.length > 1 ? 's' : ''}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPagesAssignedByMe(selectedRows, userInfo.lanId) && (
              <>
                {(selectedRows[0].status.status === 'rejected' ||
                  selectedRows[0].status.status === 'pending_approval') && (
                  <Tooltip title={tooltipMsgs.wip}>
                    <span>
                      <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => initiateAction('wip')}
                        startIcon={<ModeIcon />}
                        disabled={!userCanEditPage || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid',
                          padding: '4px 10px',
                          ':hover': { borderStyle: 'dashed' },
                          ':focus': { borderStyle: 'dashed' },
                        }}
                        size="small"
                      >
                        Change to WIP
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {userCanAssign && (
              <>
                {(selectedRows[0].status.status === 'draft' ||
                  selectedRows[0].status.status === 'rejected' ||
                  selectedRows[0].status.status === 'pending_approval') && (
                  <Tooltip title={tooltipMsgs.assign}>
                    <span>
                      <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => initiateAction('assign')}
                        startIcon={<AssignmentIndIcon />}
                        disabled={!userCanAssign || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid',
                          padding: '4px 10px',
                          ':hover': { borderStyle: 'dashed' },
                          ':focus': { borderStyle: 'dashed' },
                        }}
                        size="small"
                      >
                        Reassign Page{selectedRows.length > 1 ? 's' : ''}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
        {location.pathname !== '/' ? (
          <Tooltip title={tooltipMsgs.create}>
            <span>
              <Button
                variant={'contained'}
                color="primary"
                onClick={() => navigate('/pages/create')}
                startIcon={<AddIcon />}
                disabled={!userCanCreatePage}
                disableRipple
                disableFocusRipple
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#fff',
                  border: '1px solid #CC0000',
                  color: '#CC0000',
                  ':hover': {
                    backgroundColor: '#CC0000',
                    color: '#fff',
                  },
                  ':focus': { border: '2px dashed #666666' },
                }}
                size="small"
              >
                New Page
              </Button>
            </span>
          </Tooltip>
        ) : (
          ''
        )}
      </Box>
      <PreviewFullscreen
        loading={previewDataFetchInProgress}
        open={openFullScreenPreview}
        handleClose={() => setFullscreenPreview(false)}
        layout={selectedLayoutForPreview}
      />
      <DialogConfirmation
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          setApproveButtonFlag(false)
        }}
        title={selectedAction ? actionsList[selectedAction].title : ''}
        onConfirm={selectedAction ? actionsList[selectedAction].cb : failSafe}
        onReject={
          selectedAction && actionsList[selectedAction].rejection
            ? actionsList[selectedAction].secondCb
            : failSafe
        }
        rejection={selectedAction && actionsList[selectedAction].rejection}
        actionButtonLabel={
          selectedAction ? actionsList[selectedAction].actionButtonLabel : null
        }
        commentEnabled={
          actionsList[selectedAction] &&
          actionsList[selectedAction].commentEnabled
            ? true
            : false
        }
        assignment={
          actionsList[selectedAction] && actionsList[selectedAction].assignment
            ? true
            : false
        }
        currentStatus={selectedRows[0] ? selectedRows[0].status.status : ''}
      >
        <SelectedItems
          forAssign={
            actionsList[selectedAction]
              ? actionsList[selectedAction].assignment
              : false
          }
        />
      </DialogConfirmation>
      <ShowVersions
        open={displayVersions}
        close={setVersionDisplay}
        page={
          rowsSelected && selectedRows.length === 1
            ? selectedRows[0]?._id
            : null
        }
        userCanRollback={userCanPublish}
        userCanEdit={userCanEditPage}
        data={rowsSelected && selectedRows.length === 1 ? selectedRows[0] : {}}
        siteData={siteData}
      />
    </>
  )
}

const Pages = (props) => {
  const {
    rights,
    rightsLoading,
    inDialog = false,
    dialogProps = {},
    noSiteAccess = false,
  } = props
  const { title = '', status = '', assigned_to_me = false } = dialogProps
  const { moduleAccess, permittedActions, superRights } = rights
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = React.useState([])
  const [pagesList, setPagesList] = useState([])
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchFilter, setSearchFilter] = React.useState({})
  const [siteData, setSiteData] = useState({})
  const [selectionModel, setSelectionModel] = React.useState([])

  const [pageCategory, setPageCategory] = useState('')
  const [searchBarFocused, setSearchBarFocused] = React.useState(false)

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  })

  const {
    data: pagesData,
    isLoading: pageListLoading,
    isFetching: refetchIsInprogress,
  } = useGetPagesQuery(
    {
      userInfo,
      activeSite,
      pageNo: rowsState.page + 1,
      filter: searchFilter,
      status: inDialog ? status : pageCategory,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'site',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'site') {
          setSiteData(conf.value ? conf.value : {})
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'site') {
          setSiteData(conf.value ? conf.value : {})
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  useEffect(() => {
    if (status) {
      setSearchFilter({ status })
    }
  }, [status])

  useEffect(() => {
    if (assigned_to_me) {
      setSearchFilter({ assigned_to_me })
    }
  }, [assigned_to_me])

  useEffect(() => {
    if (pagesData && pagesData.pages) {
      const updated = pagesData.pages.map((cPage) => {
        const revision = cPage.revisions[cPage.revisions.length - 1]
        return {
          ...cPage,
          status: revision?.status ? revision.status : {},
          assigned_to: revision?.status ? revision.status?.assigned_to : {},
        }
      })
      setPagesList([...updated])
      setSelectedRows([])
      setSelectionModel([])
      setIsLoading(false)
    }
  }, [pagesData])

  const actionsAllowed = {
    userCanCreatePage: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
    userCanEditPage: checkUserIsAllowed('edit', permittedActions, superRights),
    userCanPreviewPage: checkUserIsAllowed(
      'preview',
      permittedActions,
      superRights,
    ),
    userCanPublish: checkUserIsAllowed(
      'publish',
      permittedActions,
      superRights,
    ),
    userCanApprove: checkUserIsAllowed(
      'approve',
      permittedActions,
      superRights,
    ),
    userCanReview: checkUserIsAllowed('review', permittedActions, superRights),
    userCanAssign: checkUserIsAllowed('assign', permittedActions, superRights),
    userCanViewRevisions: checkUserIsAllowed(
      'revisions',
      permittedActions,
      superRights,
    ),
  }

  const navigateTheUserToEdit = (pageId, versionId) => {
    if (pageId && versionId) {
      return navigate(`/pages/layout/${versionId}`)
    }
  }

  const pageEditInit = (selectedPageForEdit) => {
    const { revisions = [], _id = '' } = selectedPageForEdit
    const latestVersionFound = revisions.slice(-1).pop()
    const { id = '' } = latestVersionFound
    return navigateTheUserToEdit(_id, id)
  }

  const getStatusInfo = (status, type = 'label') => {
    return STATUS_TEXT[status]
      ? STATUS_TEXT[status][type]
      : type === 'label'
      ? status
        ? status.replace('_', ' ')
        : 'unknown'
      : 'primary'
  }

  function CustomToolbar() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 10px',
          backgroundColor: '#F6F7F8',
        }}
      >
        <div
          style={{ display: inDialog ? 'none' : 'flex', alignItems: 'center' }}
        >
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                '& .MuiSelect-select': {
                  display: 'inline !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  ':hover': { border: '1px dashed red' },
                  ':focus': { border: '1px dashed red' },
                },
                fontWeight: 'bolder',
                border: 'none !important',
                '& #demo-simple-select': {
                  paddingRight: '20px !important',
                },
              }}
              value={pageCategory}
              onChange={(e) => {
                setPageCategory(e.target.value)
              }}
              displayEmpty
            >
              {Object.values(STATUS_TEXT).map((status) => (
                <MenuItem value={status.value}>{status.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <span
            style={{
              color: '#3D70D6',
              fontSize: '16px',
              backgroundColor: '#E6E6E6',
              width: '59px',
              height: '36px',
              borderRadius: '36px',
              display: 'inline-block',
              textAlign: 'center',
              verticalAlign: 'middle',
              padding: '5px 9px',
              fontWeight: 'bolder',
            }}
          >
            {pagesData && pagesData.total}
          </span>
        </div>
        {inDialog && <Box></Box>}
        <GridToolbarContainer
          sx={{
            '& button': {
              fontSize: 0,
            },
          }}
        >
          <Tooltip title="Use this to filter out the columns available">
            <GridToolbarColumnsButton
              startIcon={<CalendarViewMonthIcon />}
              sx={{
                color: '#CC0000',
                border: '2px solid #DEDEDE',
                borderRadius: '4px',
                minWidth: '36px',
                width: '36px',
                height: '36px !important',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '4px 9px !important',
              }}
            />
          </Tooltip>
          <Tooltip title="Use this to edit the height of each row on this list">
            <GridToolbarDensitySelector
              sx={{
                color: '#CC0000',
                border: '2px solid #DEDEDE',
                borderRadius: '4px',
                minWidth: '36px',
                width: '36px',
                height: '36px !important',
                margin: ' 0 4px',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '4px 9px !important',
              }}
              startIcon={<ViewColumnIcon />}
            />
          </Tooltip>

          <GridToolbarFilterButton
            startIcon={<FilterAltIcon />}
            sx={{
              color: '#CC0000',
              border: '2px solid #DEDEDE',
              borderRadius: '4px',
              minWidth: '36px',
              width: '36px',
              height: '36px !important',
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '0px 6px !important',
            }}
          />
        </GridToolbarContainer>
      </Box>
    )
  }

  function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <Box sx={{ display: 'flex' }}>
        <Pagination
          count={pageCount}
          page={page + 1}
          onChange={(_event, value) => apiRef.current.setPage(value - 1)}
          showFirstButton
          showLastButton
          color="primary"
          boundaryCount={2}
        />
      </Box>
    )
  }
  function NoRowComponent() {
    return (
      <GridOverlay
        style={{
          zIndex: 1,
          pointerEvents: 'all',
          mt: '0px !important',
        }}
      >
        <Box>
          <Typography sx={{ color: 'black' }}>No Rows Found</Typography>
        </Box>
      </GridOverlay>
    )
  }

  const customFilters = getGridStringOperators().filter(
    (operator) =>
      operator.value === 'contains' ||
      operator.value === 'equals' ||
      operator.value === 'startsWith' ||
      operator.value === 'endsWith',
  )
  // in order to make column as non-filterable should use this following params **filterable: false**
  const columns = [
    {
      field: 'name',
      headerName: 'Page Name',
      width: 300,
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params) => {
        const { revisions } = params.row
        const publishedPage = isThatPublishedPage(revisions)
        return (
          <>
            <HtmlTooltip
              title={
                <Box sx={{ p: 1, textAlign: 'left' }}>
                  <Typography variant="body2" color="inherit" sx={{ mb: 1 }}>
                    {params.value}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  {publishedPage ? (
                    <Typography variant="caption" color="inherit">
                      This page is <strong>available</strong> for public
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="inherit">
                      This page is <strong>not available</strong> for public
                    </Typography>
                  )}
                </Box>
              }
              arrow
            >
              <Box sx={{ display: 'flex', width: '100%' }}>
                {isThatPublishedPage(revisions) ? (
                  <>
                    <PublicIcon
                      sx={{ fontSize: 20, color: '#008300', mr: 1 }}
                    />
                  </>
                ) : (
                  ''
                )}
                <Typography
                  sx={{
                    color: '#333333',
                    fontSize: 14,
                    fontFamily: 'Helvetica for Target',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {params.value}
                </Typography>
              </Box>
            </HtmlTooltip>
          </>
        )
      },
    },
    {
      field: 'slug',
      headerName: 'URL',
      width: 250,
      flex: 1,
      headerAlign: 'left',
      filterOperators: customFilters,
      renderCell: (params) => {
        return (
          <HtmlTooltip
            title={
              <Box sx={{ p: 1, textAlign: 'left' }}>
                <Typography variant="body2" color="inherit">
                  {params.value}
                </Typography>
              </Box>
            }
            arrow
          >
            <Link
              sx={(theme) => ({
                color:
                  theme.palette[getStatusInfo(params.value.status, 'color')]
                    .main,
                fontWeight: 'bold',
                cursor: 'pointer',
              })}
              target="__blank"
              src={`${siteData.siteUrl}${params.value}`}
              onClick={() => window.open(`${siteData.siteUrl}${params.value}`)}
            >
              <Typography
                sx={{
                  color: '#333333',
                  fontSize: 14,
                  fontFamily: 'Helvetica for Target',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                {params.value}
              </Typography>
            </Link>
          </HtmlTooltip>
        )
      },
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      headerAlign: 'left',
      width: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box flexDirection={'column'}>
            <Typography
              sx={{
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {params.value.displayName}
            </Typography>
            <Typography
              sx={{
                color: '#676B74',
                fontSize: 12,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {new Date(params.row.createdAt).toLocaleString()}
            </Typography>
          </Box>
        )
      },
      filterOperators: customFilters,
    },
    {
      field: 'updated_by',
      headerName: 'Modified By',
      headerAlign: 'left',
      width: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box flexDirection={'column'}>
            <Typography
              sx={{
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {params.value.displayName}
            </Typography>
            <Typography
              sx={{
                color: '#676B74',
                fontSize: 12,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {new Date(params.row.updatedAt).toLocaleString()}
            </Typography>
          </Box>
        )
      },
      filterOperators: customFilters,
    },
    {
      field: 'assigned_to',
      headerName: 'Assigned To',
      headerAlign: 'left',
      width: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box flexDirection={'column'}>
            <Typography
              sx={{
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {params.value.displayName ? params.value.displayName : 'NA'}
            </Typography>
          </Box>
        )
      },
      filterOperators: customFilters,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      type: 'singleSelect',
      width: 200,
      flex: 1,
      align: 'left',
      filterOperators: customFilters,
      renderCell: (params) => {
        return (
          <>
            {searchFilter.status &&
            searchFilter.status !== params.value.status ? (
              <>
                <Box
                  sx={(theme) => ({
                    color:
                      theme.palette[getStatusInfo('published', 'color')].main,
                    fontWeight: 'bold',
                    display: 'flex',
                  })}
                >
                  <Button
                    sx={(theme) => ({
                      backgroundColor: `${getStatusInfo(
                        'published',
                        'bgcolor',
                      )}`,
                      color:
                        theme.palette[getStatusInfo('published', 'color')].main,
                      fontWeight: 'bold',
                      display: 'flex',
                      minWidth: 100,
                      height: 35,
                    })}
                  >
                    {getStatusInfo('published')}
                  </Button>
                  <Edit sx={{ ml: 1 }} fontSize="small" />
                </Box>
              </>
            ) : (
              <Box
                sx={(theme) => ({
                  color:
                    theme.palette[getStatusInfo(params.value.status, 'color')]
                      .main,
                  fontWeight: 'bold',
                })}
              >
                <Button
                  sx={(theme) => ({
                    backgroundColor: `${getStatusInfo(
                      params.value.status,
                      'bgcolor',
                    )}`,
                    textTransform: 'none',
                    color:
                      theme.palette[getStatusInfo(params.value.status, 'color')]
                        .main,
                    fontWeight: 'bold',
                    display: 'flex',
                    minWidth: 100,
                    height: 35,
                  })}
                >
                  {getStatusInfo(params.value.status)}
                </Button>
              </Box>
            )}
          </>
        )
      },
    },
    {
      field: '_id',
      headerName: 'Actions',
      headerAlign: 'left',
      type: 'actions',
      width: 200,
      align: 'left',
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params) => {
        const latestVersion = getLatestVersionFromPage(params.row)
        const versionAssignedToMe = versionAssignedToThisUser(
          latestVersion,
          userInfo.lanId,
        )
        const statusOfVersion = getStatusOfThePageVersion(latestVersion)
        const editableVersion = currentVersionEditable(statusOfVersion)
        return (
          <Box
            key={params.id}
            sx={{
              margin: '0px -10px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Tooltip
              title={
                versionAssignedToMe
                  ? 'Edit Page Layout'
                  : 'This page is locked. Editing options are not available.'
              }
              placement="left"
            >
              <span>
                <IconButton
                  disabled={
                    (!actionsAllowed.userCanEditPage &&
                      !actionsAllowed.userCanReview) ||
                    !(versionAssignedToMe && editableVersion)
                  }
                  onClick={(event) => {
                    pageEditInit(params.row)
                  }}
                >
                  {versionAssignedToMe ? (
                    <AppRegistrationIcon sx={{ fontSize: 20 }} />
                  ) : (
                    <LockIcon sx={{ fontSize: 20 }} />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={'View Page Layout'} placement="left">
              <span>
                <IconButton
                  disabled={
                    (!actionsAllowed.userCanEditPage &&
                      !actionsAllowed.userCanReview) ||
                    (versionAssignedToMe && editableVersion)
                  }
                  onClick={(event) => {
                    pageEditInit(params.row)
                  }}
                >
                  <VisibilityIcon sx={{ color: '#CC0000', fontSize: 20 }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={'Edit Page Information'} placement="left">
              <IconButton
                disabled={!actionsAllowed.userCanEditPage}
                onClick={() => navigate(`/pages/update/${params.id}`)}
                sx={{ width: '35px', height: '35px' }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]
  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: 'name',
        operatorValue: 'contains',
        value: '',
      },
    ],
  })

  return (
    <Box sx={{ width: inDialog ? '100%' : 'calc(100vw - 58px)' }}>
      {(rightsLoading && !noSiteAccess) || pageListLoading ? (
        <Loader />
      ) : moduleAccess ? (
        <>
          <Box sx={{ display: 'flex', background: '#fff', px: 3, pt: '15px' }}>
            <Box>
              <Typography
                variant={'h5'}
                component={'h3'}
                sx={{
                  fontFamily: 'Helvetica For Target',
                  fontWeight: 'Bold',
                  marginBottom: 1,
                  color: '#3d3d3d',
                  mr: 2,
                }}
              >
                {inDialog ? title : 'Pages'}
              </Typography>
            </Box>
            <TextField
              value={searchFilter?.search_term ?? ''}
              sx={{
                ml: 2,
                justifyContent: 'center',
                width:
                  searchBarFocused || searchFilter?.search_term?.length > 0
                    ? '30vw'
                    : '25vw',
              }}
              placeholder="Search…"
              variant="outlined"
              size="small"
              inputProps={{ 'aria-label': 'search' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchFilter({
                  ...searchFilter,
                  search_term: e.target.value.replace(/^[ \t]+$/gm, ''),
                })
              }}
              onFocus={() => {
                setSearchBarFocused(true)
              }}
              onBlur={() => {
                setSearchBarFocused(false)
              }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box>
              <LoadActionButtons
                {...{
                  permittedActions,
                  superRights,
                  selectedRows,
                  actionsAllowed,
                }}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2 }}>
            {refetchIsInprogress && <LinearProgress color="inherit" />}
            <ManageDataGrid
              getRowId={(row) => {
                return row._id
              }}
              sx={{
                borderRadius: 0,
                height: '80vh',
              }}
              rowCount={pagesData && pagesData.total}
              loading={isLoading}
              {...rowsState}
              paginationMode="server"
              rowsPerPageOptions={[5, 10, 20, 30]}
              pagination
              onPageChange={(page) => {
                setIsLoading(true)
                setRowsState((prev) => ({ ...prev, page }))
              }}
              onPageSizeChange={(pageSize) =>
                setRowsState((prev) => ({ ...prev, pageSize }))
              }
              rows={pagesList}
              getRowClassName={(params) => {
                const latestVersion = getLatestVersionFromPage(params.row)
                const versionAssignedToMe = versionAssignedToThisUser(
                  latestVersion,
                  userInfo.lanId,
                )
                const statusOfVersion = getStatusOfThePageVersion(latestVersion)
                const editableVersion = currentVersionEditable(statusOfVersion)
                const greyedRecord =
                  versionAssignedToMe && editableVersion
                    ? 'assigned-to-me'
                    : 'not-assigned-only-view'
                return `status-coder-${
                  params.row.status.status
                    ? params.row.status.status
                    : 'unknown'
                } ${greyedRecord}`
              }}
              isRowSelectable={(params) =>
                selectedRows.length === 0 ||
                (selectedRows.length > 0 &&
                  selectedRows[0].status.status === params.row.status.status)
              }
              columns={columns}
              checkboxSelection={true}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids)
                const selectedRows = pagesList.filter((row) =>
                  selectedIDs.has(row._id),
                )
                setSelectedRows(selectedRows)
                setSelectionModel(ids)
              }}
              selectionModel={selectionModel}
              components={{
                Toolbar: CustomToolbar,
                Pagination: CustomPagination,
                NoRowsOverlay: NoRowComponent,
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                var filterKey = newFilterModel.items[0].columnField
                var filterValue = newFilterModel.items[0].value
                setFilterModel(newFilterModel)
                setSearchFilter({ [filterKey]: filterValue })
              }}
            />
          </Box>
        </>
      ) : (
        <AccessDenied module="Pages" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Page',
  actions: [
    {
      slug: 'Read',
      access: 'Read',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Create',
      access: 'Create',
    },
    {
      slug: 'Publish',
      access: 'Publish',
    },
    {
      slug: 'Review',
      access: 'Review',
    },
    {
      slug: 'Preview',
      access: 'Preview',
    },
    {
      slug: 'Assign',
      access: 'Assign',
    },
    {
      slug: 'Revisions',
      access: 'Revisions',
    },
    {
      slug: 'Approve',
      access: 'Approve',
    },
  ],
}

export default withRights(Pages, moduleAttr)
