import { Box, Grid, IconButton, Modal } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { Close } from '@mui/icons-material'

const MyCarousel = (props) => {
  const { items, open, close, itemPicked, itemName } = props

  const mediaPreview = (mediaType) => {
    switch (mediaType) {
      case 'image':
        return (
          <Modal
            open={open}
            sx={{
              maxHeight: '100vh',
              overflowX: 'clip',
              ':focus': { border: '3px dashed #666666' },
            }}
          >
            <Grid container>
              <Grid
                item
                sm={11.5}
                md={11.5}
                lg={11}
                xl={11}
                height={'100vh'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  maxWidth: '700px',
                }}
              >
                <div
                  tabIndex={0}
                  style={{ focus: { border: 'thin solid black' } }}
                >
                  <Box
                    component={'img'}
                    alt={itemName}
                    src={items[0]}
                    sx={{
                      objectFit: 'cover',
                      ':focus': { border: '3px dashed #666666' },
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                sm={0.5}
                md={0.5}
                lg={1}
                xl={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'start',
                }}
              >
                <IconButton
                  disableFocusRipple
                  aria-label="close"
                  onClick={() => close(false)}
                  sx={{
                    padding: '2%',
                    '&.MuiButtonBase-root:hover': {
                      color: 'white',
                    },
                    '&.MuiButtonBase-root': {
                      color: '#07090c',
                    },
                    ':focus': { border: '2px dashed #666666' },
                  }}
                >
                  <Close fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Modal>
        )
      default:
        return <></>
    }
  }
  return <div>{mediaPreview(itemPicked.type.split('/')[0])}</div>
}

MyCarousel.propTypes = {
  items: PropTypes.array.isRequired,
}

export default MyCarousel
