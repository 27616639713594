export const NameError = 'Name is a required field'
export const NoOfSeatsError = 'No. of seats is a required field'
export const VenueError = 'Venue is a required field'
export const ZoomLinkError = 'Zoom Link is a required field'
export const DescriptionError = 'Description is a required field'
export const CompanyError = 'Company is a required field'
export const DesignationError = 'Designation is a required field'
export const EmailError = 'Email ID is a required field'
export const BioError = 'Bio is a required field'
export const YearError = 'Year is a required field'
export const ThumbnailImageError = 'Thumbnail Image is a required field'
export const ThumbnailTitleError = 'Thumbnail title is a required field'
export const PriorityError = 'Priority is a required field'
