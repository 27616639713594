import React from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Collapse,
  Icon,
  TextField,
  Typography,
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import styled from '@emotion/styled'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <Icon {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))

export const DateFilters = (props) => {
  const {
    title = '',
    icon,
    value = {},
    onChange,
    bodyText = '',
    defaultExpanded = true,
  } = props
  const { gte = null, lt = null } = value
  const [expanded, setExpanded] = React.useState(defaultExpanded)
  return (
    <>
      <StyledBadge
        color="primary"
        badgeContent={value?.length ? value.length : 0}
        invisible={!value}
        sx={{ width: '100%' }}
      >
        <Card
          data-testid="chip-filter-wrapper"
          sx={{
            m: 1,
            borderRadius: 3,
            width: '100%',
            pb: 0,
          }}
        >
          <CardActionArea onClick={() => setExpanded(!expanded)}>
            <CardActions
              disableSpacing
              sx={{ background: '#F7F7F7', p: 1, pl: 1, pr: 1 }}
            >
              <Box
                sx={{
                  color: '#666666',
                  '& .MuiSvgIcon-root': { fontSize: 18 },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {icon && icon}
                <Typography
                  data-testid="filter-title"
                  sx={{
                    ml: 1,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#333333',
                  }}
                >
                  {title}
                </Typography>
              </Box>
              <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                aria-label="show more"
                size="small"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          </CardActionArea>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent
              sx={{
                p: 1,
                '&:last-child': { pb: 1 },
              }}
            >
              {bodyText && (
                <Typography sx={{ fontSize: 14, color: '#333333' }}>
                  {bodyText}
                </Typography>
              )}
              <Box sx={{ display: 'flex', gap: 3, m: 1 }}>
                <DatePicker
                  label="From"
                  value={gte}
                  onChange={(newValue) => {
                    onChange({
                      ...value,
                      gte: newValue,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" size="small" {...params} />
                  )}
                />
                <DatePicker
                  label="To"
                  value={lt}
                  onChange={(newValue) => {
                    onChange({
                      ...value,
                      lt: newValue,
                    })
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" size="small" {...params} />
                  )}
                />
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      </StyledBadge>
    </>
  )
}

DateFilters.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.any),
}

export default DateFilters
