import React from 'react'
import { Menu, MenuItem, ListItemIcon } from '@mui/material'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const MenuItems = (props) => {
  const {
    anchorEl,
    handleClose,
    open = false,
    selectedNode,
    setPosition,
    openPageAdd,
    openLinkAdd,
  } = props
  const { type = '' } = selectedNode
  const handleMenuClick = (position) => {
    setPosition(position)
    if (type === 'page') {
      openPageAdd()
    }
    if (type === 'link') {
      openLinkAdd()
    }
    handleClose()
  }
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      disableScrollLock={true}
    >
      <MenuItem onClick={() => handleMenuClick('inside')}>
        <ListItemIcon>
          <SubdirectoryArrowRightIcon fontSize="small" />
        </ListItemIcon>
        Add {type} inside this menu
      </MenuItem>
      <MenuItem onClick={() => handleMenuClick('level')}>
        <ListItemIcon>
          <NavigateNextIcon fontSize="small" />
        </ListItemIcon>
        Add {type} at same level as this menu
      </MenuItem>
    </Menu>
  )
}

export default MenuItems
