import React from 'react'
import { Box, Paper, InputBase, MenuItem, TextField } from '@mui/material'

export const UnitControl = (props) => {
  const {
    name = '',
    disabled = false,
    value = 0,
    type = 'm',
    onChange,
    slug = '',
    device = '',
  } = props
  const [selectedPoint, setSelectedPoint] = React.useState('%')
  const [valueFormatted, setValue] = React.useState()

  const formatValue = (received, type) => {
    if (!received) {
      return type === 'point' ? '%' : 0
    }
    const pxPresence =
      typeof received === 'string' && received.indexOf('px') > -1
    const percentPresence =
      typeof received === 'string' && received.indexOf('%') > -1
    let formatted = received
    let pointSystem = ''

    if (pxPresence) {
      // setSelectedPoint('px')
      pointSystem = 'px'
      formatted = formatted.replace('px', '')
    }
    if (percentPresence) {
      // setSelectedPoint('%')
      pointSystem = '%'
      formatted = formatted.replace('%', '')
    }
    return type === 'point' ? pointSystem : parseFloat(formatted)
  }

  React.useEffect(() => {
    setValue(formatValue(value, 'val'))
  }, [value])

  React.useEffect(() => {
    setSelectedPoint(formatValue(value, 'point'))
  }, [value])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent:
          type === 'm'
            ? name === 'ml'
              ? 'flex-start'
              : name === 'mr'
              ? 'flex-end'
              : 'center'
            : name === 'pl'
            ? 'flex-start'
            : name === 'pr'
            ? 'flex-end'
            : 'center',
        minHeight: 30,
      }}
    >
      <Paper
        component="form"
        sx={{
          p: 0,
          display: 'flex',
          alignItems: 'center',
          maxWidth: 120,
          border: '1px solid #d3d3d3',
          borderRadius: 0,
          boxShadow: 1,
          height: 35,
          position: 'relative',
        }}
      >
        <InputBase
          sx={{ ml: 1, mr: 1, p: 0, fontSize: 13 }}
          size="small"
          placeholder="Enter size"
          type="number"
          endAdornment={<></>}
          disabled={disabled}
          value={valueFormatted}
          onChange={(e) => {
            let updatedValue = null
            if (!selectedPoint) {
              updatedValue = parseFloat(e.target.value)
            } else {
              updatedValue = `${e.target.value}${selectedPoint}`
            }
            onChange(
              updatedValue,
              `${slug}${
                device && device !== 'desktop'
                  ? device.charAt(0).toUpperCase() + device.slice(1)
                  : ''
              }`,
            )
          }}
        />
        <TextField
          size="small"
          sx={{
            minWidth: 60,
            fontSize: 11,

            '& .MuiOutlinedInput-root': {
              borderRadius: 0,
              fontWeight: 'bold',
              fontSize: 11,
            },
            '& .MuiSvgIcon-root': { fontSize: 20 },
          }}
          select
          variant="outlined"
          value={selectedPoint}
          onChange={(e) => {
            let point = e.target.value
            setSelectedPoint(point)
            onChange(
              point ? `${valueFormatted}${point}` : valueFormatted,
              `${slug}${
                device && device !== 'desktop'
                  ? device.charAt(0).toUpperCase() + device.slice(1)
                  : ''
              }`,
            )
          }}
        >
          <MenuItem value="" sx={{ fontSize: 10 }}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={'%'} sx={{ fontSize: 10 }}>
            %
          </MenuItem>
          <MenuItem value={'px'} sx={{ fontSize: 10 }}>
            px
          </MenuItem>
        </TextField>
      </Paper>
    </Box>
  )
}

export default UnitControl
