import React from 'react'
import {
  Box,
  // FormControl,
  TextField,
  // Select,
  // MenuItem,
  InputAdornment,
  IconButton,
  Typography,
  ButtonGroup,
  Avatar,
  Button,
  SwipeableDrawer,
  LinearProgress,
} from '@mui/material'

import {
  SearchSharp,
  CloudDownloadOutlined,
  BorderColorOutlined,
} from '@mui/icons-material'
import OpenDailog from '../../Common/OpenDailog/OpenDailog'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  useCancelBookingWorkshopMutation,
  useCancelWaitingListWorkshopMutation,
} from '../../../../../api/workshop'

const buttonGroupstyle = {
  fontSize: 14,
  textTransform: 'none',
  padding: '10px',
  fontWeight: '600',
}

const Listing = (props) => {
  const navigate = useNavigate()
  const {
    workshopListData,
    setWorkshopId,
    workshopId,
    setFilters,
    filters,
    eventId,
    workshopFetching,
    exportToCSV,
    editDisabled = false,
    partcipantsListData,
    partcipantsFetching,
    speakersData,
    actionsAllowed = {},
  } = props
  // const [status, setStatus] = React.useState('active')
  const [openParticipantsDailog, setOpenParticipantsDailog] =
    React.useState(false)
  const activeSite = useSelector((state) => state.tenant.activeSite)

  const [dialogData, setDialogData] = React.useState([])
  const [dialogTitle, setDialogTitle] = React.useState('Approved Participants')

  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)

  const [cancelWorkshopBooking, { isLoading: cancelBookingLoading }] =
    useCancelBookingWorkshopMutation()

  const [cancelInQueueBooking, { isLoading: cancelInQueueLoading }] =
    useCancelWaitingListWorkshopMutation()

  const { userCanEditWorkshop, userCanDownloadWorkshop } = actionsAllowed

  React.useEffect(() => {
    if (partcipantsListData) {
      if (dialogTitle === 'Approved Participants') {
        setDialogData(
          partcipantsListData.filter((item) => item.status === 'CONFIRMED'),
        )
      } else if (dialogTitle === 'Interested participants') {
        setDialogData(
          partcipantsListData.filter((item) => item.status === 'WAITING_LIST'),
        )
      } else {
        setDialogData(speakersData.data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partcipantsListData, speakersData])
  const openParticipants = (status, workshopId) => {
    setOpenParticipantsDailog(true)
    setWorkshopId(workshopId)

    if (status === 'approved') {
      setDialogData(
        partcipantsListData.filter((item) => item.status === 'CONFIRMED'),
      )
      setDialogTitle('Approved Participants')
    } else if (status === 'inqueue') {
      setDialogData(
        partcipantsListData.filter((item) => item.status === 'WAITING_LIST'),
      )
      setDialogTitle('Interested participants')
    } else {
      setDialogData(speakersData.data)
      setDialogTitle('Total Mentors')
    }
  }

  const handleClose = () => {
    setOpenParticipantsDailog(false)
  }

  const formatDate = (dateValue) => {
    let dateObj = new Date(dateValue)
    return moment(dateObj).format('ddd MMM-DD-YYYY')
  }

  const convertDate = (dateValue) => {
    let dateObj = new Date(dateValue)
    return moment(dateObj).format('MM/DD/YYYY')
  }

  const onHandleFilters = (e) => {
    const { name, value } = e.target
    setFilters({ ...filters, [name]: value })
  }

  const onEditClick = (workshop_id) => {
    navigate(`/events/workshop/edit/${eventId}/${workshop_id}`)
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleCancel = (memberId) => {
    setAnchorEl(null)
    if (dialogTitle === 'Interested participants') {
      cancelInQueueBooking({
        eventId: eventId,
        workshopId: workshopId,
        memberId: memberId,
        activeSite,
      })
    } else {
      cancelWorkshopBooking({
        eventId: eventId,
        workshopId: workshopId,
        memberId: memberId,
        activeSite,
      })
    }
  }

  return (
    <>
      {(workshopFetching || partcipantsFetching) && (
        <LinearProgress></LinearProgress>
      )}
      <Box
        sx={{ display: 'flex', background: '#F6F7F8', p: 2 }}
        data-testid="listing-workshop-wrapper"
      >
        <TextField
          size="small"
          value={filters?.search}
          name="search"
          sx={{ mr: 1, width: '65%' }}
          placeholder={'Search'}
          onChange={(e) => onHandleFilters(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchSharp />
              </InputAdornment>
            ),
            'data-testid': 'search-field',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            gap: '20px',
          }}
        >
          {/* <FormControl
            sx={{ minWidth: 90, minHeight: 40, justifyContent: 'center' }}
          >
            <Select
              id="demo-simple-select"
              value={status}
              size="small"
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: 0.5,
                },
              }}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={'active'}>Active</MenuItem>
              <MenuItem value={'past'}>Past</MenuItem>
            </Select>
          </FormControl> */}
          <IconButton
            sx={{ background: '#D6D6D6', borderRadius: '4px' }}
            onClick={() => exportToCSV()}
            disabled={!userCanDownloadWorkshop}
          >
            <CloudDownloadOutlined></CloudDownloadOutlined>
          </IconButton>
        </Box>
      </Box>
      {filters?.search !== '' && workshopListData?.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          No Records Found
        </Box>
      )}

      <Box sx={{ overflow: 'scroll', height: '70vh' }}>
        {workshopListData &&
          workshopListData.length > 0 &&
          workshopListData.map((workshop, index) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 12px',
                  }}
                >
                  {formatDate(workshop.start_date) !==
                  formatDate(workshopListData[index - 1]?.start_date) ? (
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {formatDate(workshop.start_date)}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 'bold' }}
                    ></Typography>
                  )}

                  {/* <Typography variant="body1" sx={{ color: '#3E72DB' }}>
                    2 Worskshops{' '}
                  </Typography> */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    background:
                      workshopId === workshop?._id ? '#EFEFF1' : '#fff',
                    py: 2,
                    px: 1,
                    cursor: 'pointer',
                  }}
                  data-testid="workshop-click"
                  onClick={() => setWorkshopId(workshop._id)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '20px',
                    }}
                  >
                    <Avatar
                      alt="speaker_image"
                      src={workshop.worskhop_image}
                      sx={{ height: 70, width: 70 }}
                    ></Avatar>

                    <Box sx={{ textAlign: 'left' }}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 20, fontWeight: 600 }}
                      >
                        {workshop.name}
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: 18 }}>
                        Starts from {convertDate(workshop.start_date)}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 14, color: '#7D7D7D' }}
                      >
                        From{' '}
                        {moment(workshop.start_time, ['h:mm']).format(
                          'hh:mm A',
                        )}{' '}
                        to {''}
                        {moment(workshop.end_time, ['h:mm']).format('hh:mm A')}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        border: '1px dashed #096DD9',
                        px: 1,
                        color: '#096DD9',
                      }}
                    >
                      {workshop.mode}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '20px',
                    }}
                  >
                    <IconButton
                      disabled={editDisabled || !userCanEditWorkshop}
                      sx={{
                        background: '#D6D6D6',
                        borderRadius: '4px',
                        p: 0.5,
                      }}
                      onClick={() => onEditClick(workshop._id)}
                    >
                      <BorderColorOutlined
                        sx={{ fontSize: '1.2rem' }}
                      ></BorderColorOutlined>
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    background:
                      workshopId === workshop?._id ? '#EFEFF1' : '#fff',
                    textAlign: 'left',
                    pl: 12,
                    pr: 4,
                    pb: 3,
                  }}
                >
                  {' '}
                  <Typography variant="body1" sx={{ fontSize: 14 }}>
                    {workshop.description}
                    {/* <span
                      style={{
                        color: '#CC0000',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                    >
                      {' '}
                      +MORE
                    </span> */}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: 'center',
                    boxShadow: '0px 0px 3px 0px #888888',
                  }}
                >
                  <ButtonGroup
                    variant="text"
                    aria-label="text button group"
                    sx={{ py: 1 }}
                  >
                    <Button
                      variant="text"
                      sx={buttonGroupstyle}
                      color="success"
                      onClick={() => openParticipants('mentors', workshop._id)}
                    >
                      Total speakers {workshop.speakers}
                    </Button>
                    <Button
                      sx={buttonGroupstyle}
                      color="info"
                      onClick={() => openParticipants('approved', workshop._id)}
                    >
                      {' '}
                      Approved participants {workshop.confirmed}
                    </Button>
                    <Button
                      variant="text"
                      sx={buttonGroupstyle}
                      color="error"
                      onClick={() => openParticipants('inqueue', workshop._id)}
                    >
                      Interested participants {workshop.waiting_list}
                    </Button>
                  </ButtonGroup>
                </Box>
              </>
            )
          })}
      </Box>

      <SwipeableDrawer
        anchor={'right'}
        open={openParticipantsDailog}
        onClose={handleClose}
        onOpen={handleClose}
        PaperProps={{
          sx: {
            width: '25%',
            // marginTop: '65px',
          },
        }}
      >
        <OpenDailog
          handleClose={handleClose}
          listData={dialogData}
          dialogTitle={dialogTitle}
          anchorEl={anchorEl}
          menuOpen={menuOpen}
          cancelBookingLoading={cancelBookingLoading}
          cancelInQueueLoading={cancelInQueueLoading}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          handleCancel={handleCancel}
          page={'workshop'}
        ></OpenDailog>
      </SwipeableDrawer>
    </>
  )
}

export default Listing
