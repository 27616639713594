import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Button,
  Chip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
  Avatar,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material'
import { Check } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { useGetApproversListQuery } from '../../../../api/users'
import PreviewIcon from '@mui/icons-material/Preview'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import { useGetConfigurationBySlugQuery } from '../../../../api/configuration'
import {
  useGetUsedMediaQuery,
  useSaveUsedMediaMutation,
} from '../../../../api/page'
import {
  useGeneratePreviewMutation,
  useSendForApprovalMutation,
} from '../../../../api/post'
import {
  useGetCommentsQuery,
  useAddCommentMutation,
} from '../../../../api/post'
import { useGetUsersListQuery } from '../../../../api/users'
import { persistMediaList } from '../../../../api/session'
import UpdateIcon from '@mui/icons-material/Update'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import PreviewPost from '../FullScreenPreview/PreviewPost'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import ActivityPanel from '../../../Pages/PageEditor/TopLayout/ActivityPanel'
import CommentsPanel from '../../../Pages/PageEditor/TopLayout/CommentsPanel'
import AssetsPanel from '../../../Pages/PageEditor/TopLayout/AssetsPanel'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
const TopLayout = (props) => {
  const {
    layout,
    updatePostLayout,
    updateInProgress,
    updateLayoutSuccess,
    status = {},
    postActions = {},
    refetchPost,
    id,
    loadingPostData,
    slug,
    postName,
    postData,
    activity,
  } = props

  const { postChangesAllowed = false, addCommentAllowed = false } = postActions

  const [openFullScreenPreview, setFullscreenPreview] = useState(false)
  const [initChangeStatus, changeStatus] = useState(false)
  const [approversList, setApproversList] = useState([])
  const [selectedApprover, setSelectedApprover] = useState()
  const [siteData, setSiteData] = useState({})
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const mediaArr = useSelector((state) => state.session.mediaList)
  const dispatch = useDispatch()
  const [loadingPreviewButton, setLoadingPreviewButton] = useState(false)
  const initialStateIcons = {
    activityIcon: false,
    commentsIcon: false,
    assetsIcon: false,
  }
  const [iconClicked, setIconClicked] = React.useState(initialStateIcons)
  const { data: approversListData } = useGetApproversListQuery(activeSite, {
    refetchOnMountOrArgChange: true,
    skip: !activeSite,
  })

  const [comments, setComments] = React.useState([])
  const [userData, setUsers] = React.useState([])
  const [currentComment, setCurrentComment] = React.useState('')
  const { data: users } = useGetUsersListQuery()

  const { data: commentsData } = useGetCommentsQuery(
    { id, activeSite },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeSite || !id,
    },
  )

  const [addCommentToPost, { isLoading: saveInProgress }] =
    useAddCommentMutation()

  useEffect(() => {
    if (commentsData) {
      setComments([...commentsData].reverse())
    }
  }, [commentsData])

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'site',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const { assigned_to = {} } = status

  const [
    changingPageStatus,
    { isSuccess: statusChangeDone, isLoading: statusChangeInProgress },
  ] = useSendForApprovalMutation()

  const [
    generatePreview,
    {
      data: previewData,
      isSuccess: previewGenerated,
      isLoading: previewGenerationInProgress,
    },
  ] = useGeneratePreviewMutation()

  useEffect(() => {
    if (approversListData && approversListData.length > 0) {
      setApproversList(approversListData)
    }
  }, [approversListData])

  useEffect(() => {
    if (statusChangeDone) {
      refetchPost()
      changeStatus(false)
    }
  }, [refetchPost, statusChangeDone, changeStatus])

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'site') {
          setSiteData(conf.value ? conf.value : {})
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedApprover(value)
  }

  const getLabelValueByLanId = (lanId) => {
    if (lanId && approversList) {
      const found = approversList.find((a) => a.lanId === lanId)
      return found ? found.name : lanId
    }
    return ''
  }

  const sendForApproval = () => {
    const payload = {
      id,
      assignedto: selectedApprover,
      site: activeSite._id,
      status: 'pending_approval',
    }
    changingPageStatus(payload)
  }

  const backToWIP = () => {
    const payload = {
      id,
      assignedto: userInfo.lanId,
      site: activeSite._id,
      status: 'draft',
    }
    changingPageStatus(payload)
  }

  const isChangeStatusLoading = () => {
    return statusChangeInProgress || loadingPostData
  }

  const onSitePreview = () => {
    if (siteData && siteData.siteUrl && previewGenerated) {
      window.open(
        // TODO : Needs to enable when preview token implemented
        `${siteData.siteUrl}${slug ? slug : ''}?preview=${
          previewData ? previewData.token : ''
        }`,
        // `${siteData.siteUrl}${slug ? slug : ''}`
      )
    }
  }

  useEffect(() => {
    if (previewGenerationInProgress) {
      setLoadingPreviewButton(true)
    }
    if (!previewGenerationInProgress && previewGenerated) {
      setLoadingPreviewButton(false)
      onSitePreview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewGenerated, previewGenerationInProgress])

  const [saveMedia] = useSaveUsedMediaMutation()
  const doPersistSavedMedia = async (body) => {
    await saveMedia(body).then((res) => {})
  }

  const { data: savedMediaList } = useGetUsedMediaQuery(
    { pageId: postData?._id, revisionId: null },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeSite,
    },
  )

  useEffect(() => {
    //save the usedmedia data
    if (updateLayoutSuccess) {
      const usedMediaIds =
        mediaArr?.length > 0
          ? mediaArr.map((media) => {
              return media?.id || media?._id
            })
          : []
      const body = {
        content: postData?._id,
        revision: null,
        data: usedMediaIds,
      }
      doPersistSavedMedia(body)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLayoutSuccess])

  useEffect(() => {
    if (savedMediaList && savedMediaList?.length > 0) {
      const data = savedMediaList[0].data
      let sortedArray = [...data]
      let sortedMedia = sortedArray.sort((e1, e2) =>
        e1.size < e2.size ? 1 : e1.size > e2.size ? -1 : 0,
      )
      dispatch(persistMediaList(sortedMedia))
    } else {
      dispatch(persistMediaList([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedMediaList])

  useEffect(() => {
    //save the usedmedia data
    if (updateLayoutSuccess) {
      const usedMediaIds =
        mediaArr?.length > 0
          ? mediaArr.map((media) => {
              return media?.id || media?._id
            })
          : []
      const body = {
        content: postData?._id,
        revision: null,
        data: usedMediaIds,
      }
      doPersistSavedMedia(body)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLayoutSuccess])

  const handleMenuHeader = (iconName) => {
    var icon = {
      ...initialStateIcons,
      [iconName]: !iconClicked[iconName],
    }
    setIconClicked(icon)
  }

  useEffect(() => {
    if (users) {
      setUsers(
        ...[
          users
            .filter((u) => u.title)
            .map((user) => {
              return {
                ...user,
                display: user.displayName,
                title: user.title ? user.title : 'Unknown',
              }
            }),
        ],
      )
    }
  }, [users])
  const onChangeComment = (e) => {
    setCurrentComment(e.target.value)
  }

  const addComment = () => {
    if (currentComment) {
      let users = []
      const regexTest = new RegExp(/\(([(a-zA-Z0-9)]+)\)/g)
      const str = currentComment
      let m

      while ((m = regexTest.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regexTest.lastIndex) {
          regexTest.lastIndex++
        }
        users.push(m[1])
      }

      addCommentToPost({
        id,
        comment: currentComment,
        users: users,
        comment_type: 'user',
      })
    }
  }

  const onEmojiClick = (event, emojiObject) => {
    setCurrentComment(currentComment + emojiObject.emoji)
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', margin: 2, gap: '16px', alignItems: 'center' }}
      >
        <Typography variant="body1">{postName}</Typography>
        <Box
          sx={{
            // background: '#2196F3',
            textAlign: 'center',
          }}
        >
          <Box>
            <Chip
              label={
                status.status === 'draft'
                  ? 'WIP'
                  : status.status === 'pending_approval'
                  ? 'Pending approval'
                  : status.status === 'approved'
                  ? 'Approved'
                  : status.status === 'published'
                  ? 'Published'
                  : status.status === 'rejected'
                  ? 'Rejected'
                  : status.status === 'archived'
                  ? 'Archived'
                  : status.status
              }
              color={
                status.status === 'draft'
                  ? 'info'
                  : status.status === 'pending_approval'
                  ? 'warning'
                  : status.status === 'rejected'
                  ? 'error'
                  : 'success'
              }
              sx={(theme) => {
                return {
                  backgroundColor: theme.palette[status.status].main,
                  height: 26,
                }
              }}
            />
          </Box>

          {/* <Typography variant="body1" sx={{ color: '#fff' }}>
          WIP
        </Typography> */}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <Tooltip title="Preview on Site">
            <IconButton
              aria-label="site-preview"
              onClick={() => {
                if (
                  (!previewGenerated ||
                    !previewData ||
                    previewData.page !== id) &&
                  !previewGenerationInProgress
                ) {
                  generatePreview({ id })
                }
              }}
              sx={{ border: '1px solid #E0E0E0', borderRadius: 0, padding: 1 }}
              variant="outlined"
            >
              <OpenInBrowserIcon fontSize="medium"></OpenInBrowserIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Preview on Stencil">
            <IconButton
              aria-label="cms-preview"
              onClick={() => {
                setFullscreenPreview(true)
              }}
              sx={{ border: '1px solid #E0E0E0', borderRadius: 0, padding: 1 }}
              variant="outlined"
            >
              <PreviewIcon fontSize="medium"></PreviewIcon>
            </IconButton>
          </Tooltip>
          {loadingPreviewButton && <CircularProgress size={30} sx={{ m: 1 }} />}
        </Box>
        <Tooltip title="Assets">
          <IconButton
            aria-label="activity-icon"
            onClick={() => handleMenuHeader('assetsIcon')}
            sx={{ borderRadius: 0, padding: 1 }}
          >
            <CollectionsBookmarkIcon
              sx={{ color: '#CC0000' }}
              fontSize="medium"
            ></CollectionsBookmarkIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Activity">
          <IconButton
            aria-label="activity-icon"
            onClick={() => handleMenuHeader('activityIcon')}
            sx={{ borderRadius: 0, padding: 1 }}
          >
            <UpdateIcon
              sx={{ color: '#CC0000' }}
              fontSize="medium"
            ></UpdateIcon>
          </IconButton>
        </Tooltip>

        <Box sx={{ cursor: 'pointer' }}>
          <Tooltip title="Chat">
            <IconButton
              aria-label="activity-icon"
              onClick={() => handleMenuHeader('commentsIcon')}
              sx={{ borderRadius: 0, padding: 1 }}
            >
              <ChatBubbleOutlineIcon
                sx={{ color: '#CC0000' }}
                fontSize="medium"
              ></ChatBubbleOutlineIcon>
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          {initChangeStatus ? (
            <>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="grouped-select">Approver</InputLabel>
                <Select
                  defaultValue=""
                  id="grouped-select"
                  label="Approver"
                  renderValue={(selected) => {
                    const label = getLabelValueByLanId(selected)
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip size="small" key={selected} label={label} />
                      </Box>
                    )
                  }}
                  value={selectedApprover}
                  onChange={handleChange}
                  size="small"
                  sx={{ maxWidth: 300 }}
                >
                  {approversList.map((approver, key) => {
                    return (
                      <MenuItem key={key} value={approver.lanId}>
                        {approver.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <Button
                sx={{
                  textTransform: 'none',
                  ml: 1,
                  backgroundColor: '#CC0000',
                  ':hover': {
                    backgroundColor: '#CC0000',
                    borderColor: '#CC0000',
                  },
                }}
                variant="contained"
                size={'small'}
                onClick={sendForApproval}
                disabled={!selectedApprover || statusChangeInProgress}
              >
                Send
              </Button>
              <Button
                sx={{ textTransform: 'none', ml: 1 }}
                onClick={() => changeStatus(false)}
                size={'small'}
                variant="outlined"
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              {status.status === 'draft' && !isChangeStatusLoading() && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => changeStatus(true)}
                  disabled={!postChangesAllowed}
                  sx={{
                    textTransform: 'none',
                    borderColor: '#CC0000',
                    color: '#CC0000',
                    py: 0.5,
                    px: 1,
                    fontSize: 14,
                    borderRadius: '12px',
                    background: 'transparent',
                    ':hover': {
                      backgroundColor: 'transparent',
                      borderColor: '#CC0000',
                    },
                  }}
                >
                  Send for Approval
                </Button>
              )}
              {(status.status === 'pending_approval' ||
                status.status === 'rejected') &&
                !isChangeStatusLoading() && (
                  <>
                    <Button
                      sx={{ textTransform: 'none', ml: 2 }}
                      variant="outlined"
                      size="small"
                      onClick={() => backToWIP()}
                    >
                      Back to WIP
                    </Button>
                    <Typography variant="caption" sx={{ ml: 1 }}>
                      Assigned to <strong>{assigned_to.displayName}</strong>
                    </Typography>
                  </>
                )}
            </>
          )}
        </Box>
        <Button
          variant="outlined"
          size="small"
          onClick={updatePostLayout}
          disabled={updateInProgress || !postChangesAllowed}
          sx={{
            textTransform: 'none',
            backgroundColor:
              updateInProgress || !postChangesAllowed ? 'lightgray' : '#CC0000',
            borderColor: '#CC0000',
            color: '#fff',
            py: 0.5,
            px: 2,
            fontSize: 14,
            borderRadius: '12px',
            ':hover': {
              backgroundColor: '#CC0000',
            },
          }}
        >
          Save
        </Button>
        <Box>
          {updateInProgress && <CircularProgress size={30} sx={{ ml: 1 }} />}
          {updateLayoutSuccess && (
            <Avatar
              size={'small'}
              sx={{ bgcolor: '#4caf50', width: 30, height: 30, ml: 1 }}
            >
              <Check />
            </Avatar>
          )}
        </Box>
      </Box>
      <PreviewPost
        open={openFullScreenPreview}
        handleClose={() => setFullscreenPreview(false)}
        layout={layout}
      />
      <Box sx={{ minHeight: 10 }}>
        {isChangeStatusLoading() && <LinearProgress color="inherit" />}
      </Box>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['activityIcon']}
        onClose={() => handleMenuHeader('activityIcon')}
        onOpen={() => handleMenuHeader('activityIcon')}
        PaperProps={{
          sx: {
            width: '25%',
            marginTop: '65px',
          },
        }}
      >
        <ActivityPanel
          activity={activity}
          onClose={() => handleMenuHeader('activityIcon')}
        ></ActivityPanel>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['assetsIcon']}
        onClose={() => handleMenuHeader('assetsIcon')}
        onOpen={() => handleMenuHeader('assetsIcon')}
        PaperProps={{
          sx: {
            width: '25%',
            marginTop: '65px',
          },
        }}
      >
        <AssetsPanel
          onClose={() => handleMenuHeader('assetsIcon')}
        ></AssetsPanel>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor={'right'}
        open={iconClicked['commentsIcon']}
        onClose={() => handleMenuHeader('commentsIcon')}
        onOpen={() => handleMenuHeader('commentsIcon')}
        PaperProps={{
          sx: {
            width: '25%',
            marginTop: '65px',
          },
        }}
      >
        <CommentsPanel
          comments={comments}
          saveInProgress={saveInProgress}
          userData={userData}
          pageChangesAllowed={postChangesAllowed}
          addCommentAllowed={addCommentAllowed}
          addComment={addComment}
          currentComment={currentComment}
          onChangeComment={onChangeComment}
          onEmojiClick={onEmojiClick}
          id={id}
          onClose={() => handleMenuHeader('commentsIcon')}
        ></CommentsPanel>
      </SwipeableDrawer>
    </>
  )
}

export default TopLayout
