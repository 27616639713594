export const DATASET_REGISTRY = {
  events: {
    models: [
      { display: 'All Speakers', model: 'speakers', enabled: true },
      {
        display: 'Workshops',
        model: 'workshops',
        enabled: true,
        selection: 'single',
      },
      {
        display: 'Mentorships',
        model: 'mentorships',
        enabled: true,
        selection: 'single',
      },
      { display: 'Others', model: 'others', enabled: false },
    ],
    display: 'Events',
    enabled: true,
  },
  others: {
    models: [],
    display: 'Others',
    enabled: false,
  },
}

export const FIELDS_NOT_FOR_DISPLAY = ['_id', 'id']

export const DYNAMIC_FIELDS = {
  sections: [
    { field: 'sections.section.heading.text', display: 'Heading' },
    { field: 'sections.section.subHeading.text', display: 'Sub Heading' },
    { field: 'sections.section.body.text', display: 'Body' },
    { field: 'sections.section.cardMedia.cardImage', display: 'Card Image' },
    { field: 'sections.section.cardButton.button.url', display: 'Card Link' },
    {
      field: 'sections.section.cardButton.button.video',
      display: 'Video Link',
    },
  ],
  slides: [
    { field: 'slides.slide.heading.text', display: 'Heading' },
    { field: 'slides.slide.body.text', display: 'Body' },
    { field: 'slides.slide.slideMedia.mediaSrc', display: 'Image' },
    { field: 'slides.slide.cardButton.button.url', display: 'Card Link' },
  ],
}

export const dynamicFieldSupportAvailability = (meta) => {
  return Object.keys(DYNAMIC_FIELDS).includes(meta?.slug)
}
