import { Box } from '@mui/material'
import React from 'react'
import SunEditor from 'suneditor-react'
import _ from 'lodash'
import 'suneditor/dist/css/suneditor.min.css'

export const RichText = (props) => {
  const {
    disabled = false,
    placeholder = 'Enter the text',
    value = '',
    onChange,
    slug = null,
  } = props

  const uniqueId = _.uniqueId('bound-')

  const handleChange = (text) => {
    onChange(text, slug)
  }

  return (
    <Box
      id={uniqueId}
      sx={{
        display: 'flex',
        justifyContent: 'left',
        color: '#c4c4c4',
        border: '1px solid',
        borderRadius: 1,
      }}
      data-testid="richtext-wrapper"
    >
      <SunEditor
        defaultValue={value}
        onChange={handleChange}
        placeholder={placeholder}
        disable={disabled}
      />
    </Box>
  )
}

export default RichText
