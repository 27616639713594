import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  FormControl,
  Paper,
  Radio,
  Grid,
  FormControlLabel,
  Button,
  TextField,
  Alert,
  AlertTitle,
  Autocomplete,
  Chip,
  Tooltip,
  LinearProgress,
  Switch,
  IconButton,
} from '@mui/material'
import ArrowBackIcon from '../../../assets/Image/Back.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { createFilterOptions } from '@mui/material/Autocomplete'
import withRights from '../../../components/withRights'
import AccessDenied from '../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../utils/rightUtils'
import Loader from '../../../components/Loader'
import { useGetPageQuery, useUpdatePageMutation } from '../../../api/page'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  useGetCategoriesQuery,
  useGetTagsQuery,
  useNewCategoryMutation,
  useNewTagMutation,
} from '../../../api/taxonomy'
import MediaPicker from '../../../components/MediaPicker'
import { useSnackbar } from 'notistack'
import ReactQuill from 'react-quill'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

const filter = createFilterOptions()

const UpdatePage = (props) => {
  const { rights, rightsLoading } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const activeSite = useSelector((state) => state.tenant.activeSite)
  let { id } = useParams()
  const {
    data: pageData,
    isLoading: loadingPageData,
    error: loadError,
  } = useGetPageQuery(
    { id, activeSite },
    {
      skip: !(id && activeSite),
      refetchOnMountOrArgChange: true,
    },
  )
  const [
    updatePageData,
    { isSuccess: editSuccess, isLoading: editInProgress, error: editError },
  ] = useUpdatePageMutation()
  const userInfo = useSelector((state) => state.session.userInfo)
  const { enqueueSnackbar } = useSnackbar()
  const [newPage, setNewPage] = useState({
    name: '',
    slug: '',
    template: 'default',
    lanId: userInfo.lanId,
    active: 1,
    status: 'draft',
    category: null,
    sharable: false,
    tags: [],
    seo: {
      title: '',
      keywords: '',
      description: '',
      image: '',
      alt_text: '',
      img_title: '',
      footer_notes: '',
    },
  })
  const {
    title = '',
    keywords = '',
    alt_text = '',
    img_title = '',
    description = '',
    image = '',
    footer_notes = '',
  } = newPage.seo
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const navigate = useNavigate()

  const [isReset, setIsReset] = React.useState(false)

  const handleClose = () => {
    setIsReset(false)
  }

  const handleClearForm = () => {
    setNewPage({
      name: '',
      description: '',
      slug: '',
      template: 'default',
      lanId: userInfo.lanId,
      active: 1,
      status: 'draft',
      category: null,
      sharable: false,
      tags: [],
      seo: {
        title: '',
        keywords: '',
        image: '',
        description: '',
        img_title: '',
        alt_text: '',
        footer_notes: '',
      },
    })
    handleClose()
  }

  useEffect(() => {
    if (pageData) {
      setNewPage({
        ...newPage,
        ...pageData,
        category: pageData.category
          ? { value: pageData.category.id, label: pageData.category.name }
          : null,
        tags:
          pageData.tags && pageData.tags.length > 0
            ? pageData.tags.map((tag) => {
                return {
                  value: tag.id,
                  label: tag.name,
                }
              })
            : [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData])
  const handleChange = (val, attr) => {
    if (attr === 'slug' && val !== '' && val[0] !== '/') {
      val = `/${val}`
    }
    setNewPage({
      ...newPage,
      [attr]: val,
    })
  }

  const handleSeoChanges = (val, attr) => {
    setNewPage({
      ...newPage,
      seo: {
        ...newPage.seo,
        [attr]: val,
      },
    })
  }

  const [isValidationPass, updateValidationStatus] = useState(true)

  const checkValidation = () => {
    var validationStatus =
      newPage.name.trim().length < 3 ||
      !newPage.name.match(/^[a-zA-Z0-9-\s()$:,'&@#%^.*]*$/) ||
      (!newPage.slug.trim().match(/^\/[a-zA-Z][a-zA-Z0-9-/]*$/gi) &&
        !newPage.slug === '/')
        ? false
        : true
    updateValidationStatus(validationStatus)
    return validationStatus
  }

  const editPage = () => {
    if (checkValidation()) {
      updatePageData({
        id,
        ...newPage,
        lanId: userInfo.lanId,
        category: newPage.category ? newPage.category.value : null,
        tags:
          newPage.tags && newPage.tags.length > 0
            ? newPage.tags.map((tag) => tag.value)
            : [],
      })
    }
  }

  const [
    addNewCategory,
    {
      isLoading: catCreationInProgress,
      isSuccess: catCreated,
      data: newlyCreatedCat,
    },
  ] = useNewCategoryMutation()

  const [
    addNewTag,
    {
      isLoading: tagCreationInProgress,
      isSuccess: tagCreated,
      data: newlyCreatedTag,
    },
  ] = useNewTagMutation()

  const { data: categoriesList } = useGetCategoriesQuery(
    {
      filter: {},
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  const { data: tagsList } = useGetTagsQuery(
    {
      filter: {},
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  useEffect(() => {
    if (categoriesList) {
      setCategories([
        ...categoriesList.map((cat) => {
          return { label: cat.name, value: cat.id }
        }),
      ])
    }
  }, [categoriesList])

  useEffect(() => {
    if (tagsList) {
      setTags([
        ...tagsList.map((tag) => {
          return { label: tag.name, value: tag.id }
        }),
      ])
    }
  }, [tagsList])

  useEffect(() => {
    if (editSuccess) {
      enqueueSnackbar('Page edited successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      navigate(`/pages/`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSuccess, navigate])

  useEffect(() => {
    if (catCreated && newlyCreatedCat) {
      setNewPage({
        ...newPage,
        category: { label: newlyCreatedCat.name, value: newlyCreatedCat.id },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catCreated, newlyCreatedCat])

  useEffect(() => {
    if (tagCreated && newlyCreatedTag) {
      setNewPage({
        ...newPage,
        tags: [
          ...newPage.tags,
          { label: newlyCreatedTag.name, value: newlyCreatedTag.id },
        ],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagCreated, newlyCreatedTag])

  const formFieldStyles = {
    pb: 1,
    ' .MuiFormLabel-root': {
      color: '#333333',
      fontSize: 14,
      fontFamily: 'Helvetica for Target',
      fontWeight: '400',
      wordWrap: 'break-word',
    },
  }

  const inputWrapper = {
    pr: 1,
  }

  const inputField = {
    m: 1,
    marginBottom: 2,
  }

  return (
    <Box>
      {rightsLoading || loadingPageData ? (
        <Loader />
      ) : moduleAccess &&
        checkUserIsAllowed('edit', permittedActions, superRights) ? (
        <>
          {!loadError ? (
            <>
              {/* Header */}
              <Box sx={{ display: 'flex', px: '15px' }}>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        navigate('/pages/')
                      }}
                      sx={{
                        borderRadius: '10%',
                        padding: '5px 0px !important',
                      }}
                      disableElevation
                      disableRipple
                    >
                      <ArrowBackIcon style={{ marginRight: '10px' }} />
                    </IconButton>
                    <Typography
                      variant={'h5'}
                      component={'h3'}
                      sx={{
                        color: '#3D3D3D',
                        fontSize: 32,
                        fontFamily: 'Helvetica for Target',
                        fontWeight: '700',
                        wordWrap: 'break-word',
                      }}
                    >
                      Update Your Page
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* Body */}
              <Box
                sx={{
                  width: 'calc(100vw - 58px)',
                  padding: '0px 15px',
                  height: '80vh',
                  overflow: 'auto',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    columnGap: '10px',
                    background: '#F6F7F8',
                    flexDirection: 'row',
                  }}
                >
                  {/* Left side pane */}
                  <Box
                    sx={{
                      padding: '10px 20px',
                      backgroundColor: '#F6F7F8',
                      width: '100%',
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        backgroundColor: '#F6F7F8',
                        width: '100%',
                      }}
                    >
                      <Grid item xs={12}>
                        <Tooltip
                          title="Basic information's about the page"
                          placement="bottom-start"
                          disableHoverListener={true}
                        >
                          <Typography
                            sx={{
                              ...inputField,
                              marginBottom: 0,
                              marginTop: 0.5,
                              marginLeft: 4.5,
                              color: '#474747',
                              fontSize: 20,
                              fontFamily: 'Helvetica for Target',
                              fontWeight: '700',
                              wordWrap: 'break-word',
                            }}
                          >
                            Basic Information
                          </Typography>
                        </Tooltip>
                        {/* <Dividr /> */}
                      </Grid>

                      <Box
                        sx={{
                          width: '100%',
                          marginLeft: '18px',
                          border: '2px dashed #d6d6d6 !important',
                          mt: '10px',
                          px: '15px !important',
                          py: '9px !important',
                          height: '50%',
                        }}
                      >
                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControl
                            fullWidth
                            sx={{
                              paddingLeft: '8px',
                              marginBottom: '18px',
                              '& .MuiInputBase-formControl': {
                                backgroundColor: '#FFF !important',
                                top: '9px',
                              },
                            }}
                          >
                            <TextField
                              id="name"
                              value={newPage.name}
                              onChange={(event) =>
                                handleChange(event.target.value, 'name')
                              }
                              label="Page Name/Title"
                              sx={{
                                borderRadius: '4px',
                                borderColor: '#888',
                                '& .MuiFormHelperText-root': {
                                  marginTop: '10px',
                                  marginLeft: '2px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  padding: '6.5px 14px',
                                },
                                ...formFieldStyles,
                              }}
                              placeholder="Enter your page name"
                              required
                              variant="outlined"
                              error={
                                !isValidationPass &&
                                (newPage.name.length < 3 ||
                                  !newPage.name.match(
                                    /^[a-zA-Z0-9-\s()$:,'&@#%^.*]*$/,
                                  ))
                                  ? true
                                  : false
                              }
                              helperText={
                                !isValidationPass &&
                                (newPage.name.length < 3 ||
                                  !newPage.name.match(
                                    /^[a-zA-Z0-9-\s()$:,'&@#%^.*]*$/,
                                  ))
                                  ? 'Name should be valid'
                                  : 'Page name will be used to identify and search the pages in Stencil'
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControl
                            fullWidth
                            sx={{
                              paddingLeft: '8px',
                              marginBottom: '22px',
                              '& .MuiInputBase-formControl': {
                                backgroundColor: '#FFF !important',
                                top: '9px',
                              },
                            }}
                          >
                            <TextField
                              id="description"
                              value={newPage.description}
                              onChange={(event) =>
                                handleChange(event.target.value, 'description')
                              }
                              sx={{
                                borderRadius: '4px',
                                borderColor: '#888',
                                '& .MuiFormHelperText-root': {
                                  marginTop: '10px',
                                  marginLeft: '2px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  padding: '6.5px 14px',
                                },
                                ...formFieldStyles,
                              }}
                              label="Page Description"
                              placeholder="Enter the page short description"
                              required
                              variant="outlined"
                              width="100%"
                              helperText={
                                'Page description will be displayed on search listing/other page listing'
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControl
                            fullWidth
                            sx={{
                              paddingLeft: '8px',
                              marginBottom: '22px',
                              '& .MuiInputBase-formControl': {
                                backgroundColor: '#FFF !important',
                                top: '9px',
                              },
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              value={newPage.slug}
                              onChange={(event) =>
                                handleChange(event.target.value, 'slug')
                              }
                              sx={{
                                borderRadius: '4px',
                                borderColor: '#888',
                                '& .MuiFormHelperText-root': {
                                  marginTop: '10px',
                                  marginLeft: '2px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  padding: '6.5px 14px',
                                },
                                ...formFieldStyles,
                              }}
                              label="URL"
                              placeholder="Enter the URL path eg: /careers"
                              variant="outlined"
                              width="100%"
                              required
                              error={
                                !isValidationPass &&
                                !newPage.slug
                                  .trim()
                                  .match(/^\/[a-zA-Z][a-zA-Z0-9-/]*$/gi) &&
                                !newPage.slug === '/'
                                  ? true
                                  : editError?.data?.error !== undefined
                                  ? true
                                  : false
                              }
                              helperText={
                                !isValidationPass &&
                                !newPage.slug
                                  .trim()
                                  .match(/^\/[a-zA-Z][a-zA-Z0-9-/]*$/gi)
                                  ? 'URL shoule be valid'
                                  : editError?.data?.error !== undefined
                                  ? 'This URL is not available. The page already exists.'
                                  : "Enter the URL path of this page eg: /careers. Make sure the path is prefixed with '/'"
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControlLabel
                            labelPlacement="start"
                            sx={{
                              ...inputField,
                              width: '100%',
                              marginBottom: '22px',
                              justifyContent: 'space-between',
                              '& .MuiFormControlLabel-label': {
                                minWidth: 230,
                              },
                            }}
                            control={
                              <Switch
                                checked={newPage.sharable}
                                onChange={(event) =>
                                  handleChange(event.target.checked, 'sharable')
                                }
                                name="sharable"
                              />
                            }
                            label={`${
                              newPage.sharable ? 'Disable' : 'Enable'
                            } sharing options`}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        backgroundColor: '#F6F7F8',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '100%',
                          m: '12px 09px',
                          flexDirection: 'column',
                        }}
                      >
                        <Tooltip
                          title="Type of page template"
                          placement="bottom-start"
                          disableHoverListener={true}
                        >
                          <Typography
                            sx={{
                              color: '#474747',
                              fontSize: 20,
                              fontFamily: 'Helvetica for Target',
                              fontWeight: '700',
                              wordWrap: 'break-word',
                              marginLeft: 4.5,
                              my: '8px',
                            }}
                          >
                            Choose Page Template
                          </Typography>
                        </Tooltip>
                        <Box>
                          <Grid
                            container
                            sx={{ m: 1 }}
                            style={{
                              display: 'flex',
                              columnGap: '40px',
                              border: '2px dashed #d6d6d6',
                              padding: '46px 24px',
                              paddingLeft: 0,
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                            spacing={3}
                          >
                            <Grid
                              item
                              xs={4}
                              sx={{ paddingTop: '0px !important' }}
                            >
                              <Paper
                                elevation={1}
                                sx={{
                                  textAlign: 'center',
                                  paddingLeft: 0,
                                  paddingTop: '0px',
                                  cursor: 'pointer',
                                  borderRadius: '0 0px 4px 4px',
                                  display: 'flex',
                                  flexDirection: 'column-reverse',
                                }}
                                onClick={() =>
                                  handleChange('default', 'template')
                                }
                              >
                                <Paper
                                  elevation={0}
                                  sx={(theme) => ({
                                    height: 140,
                                    paddingLeft: '0px',
                                    background:
                                      newPage.template === 'default'
                                        ? '#c00000'
                                        : '#CED1D6',
                                    borderRadius: '0 0px 4px 4px',
                                    '&:hover': {
                                      background: '#c00000',
                                    },
                                  })}
                                />
                                <FormControlLabel
                                  sx={{
                                    paddingLeft: '8px',

                                    '& .MuiInputBase-formControl': {
                                      backgroundColor: '#FFF !important',
                                      top: '9px',
                                    },
                                  }}
                                  checked={newPage.template === 'default'}
                                  onChange={(event) =>
                                    handleChange(event.target.value, 'template')
                                  }
                                  value="default"
                                  control={
                                    <Radio
                                      sx={{
                                        color: '#C00000 !important',
                                      }}
                                    />
                                  }
                                  label="Default"
                                />
                              </Paper>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ paddingTop: '0px !important' }}
                            >
                              <Paper
                                elevation={3}
                                sx={{
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  borderRadius: '0 0px 4px 4px',
                                  display: 'flex',
                                  flexDirection: 'column-reverse',
                                }}
                                onClick={() =>
                                  handleChange('fullwidth', 'template')
                                }
                              >
                                <Paper
                                  elevation={0}
                                  sx={(theme) => ({
                                    height: 140,
                                    width: '100%',
                                    borderRadius: '0 0px 4px 4px',
                                    background:
                                      newPage.template === 'fullwidth'
                                        ? theme.palette.primary.main
                                        : '#CED1D6',
                                    '&:hover': {
                                      background: '#c00000',
                                    },
                                  })}
                                />
                                <FormControlLabel
                                  sx={{
                                    paddingLeft: '8px',
                                    '& .MuiInputBase-formControl': {
                                      backgroundColor: '#FFF !important',
                                      top: '9px',
                                    },
                                  }}
                                  checked={newPage.template === 'fullwidth'}
                                  onChange={(event) =>
                                    handleChange(event.target.value, 'template')
                                  }
                                  value="fullwidth"
                                  control={
                                    <Radio
                                      sx={{
                                        color: '#C00000 !important',
                                      }}
                                    />
                                  }
                                  label="Full Width"
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={4}>
                              <Paper
                                elevation={3}
                                sx={{
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  borderRadius: '0 0px 4px 4px',
                                  display: 'flex',
                                  position: 'relative',
                                  flexDirection: 'column-reverse',
                                }}
                                onClick={() =>
                                  handleChange('leftsidenav', 'template')
                                }
                              >
                                <Box
                                  sx={(theme) => ({
                                    display: 'flex',
                                    borderRadius: '0 0px 4px 4px',
                                    background:
                                      newPage.template === 'leftsidenav'
                                        ? theme.palette.primary.main
                                        : '#CED1D6',
                                    '&:hover': {
                                      background: '#c00000',
                                    },
                                  })}
                                >
                                  <Paper
                                    elevation={0}
                                    sx={{
                                      height: 140,
                                      width: '20%',
                                      background: 'transparent',

                                      borderRight: '6px solid #fff',
                                    }}
                                  />
                                  <Paper
                                    elevation={0}
                                    sx={{
                                      height: 140,
                                      width: '80%',
                                      background: 'transparent',
                                      borderRadius: 0,
                                    }}
                                  />
                                </Box>
                                <FormControlLabel
                                  sx={{
                                    paddingLeft: '8px',
                                    '& .MuiInputBase-formControl': {
                                      backgroundColor: '#FFF !important',
                                      top: '9px',
                                    },
                                  }}
                                  checked={newPage.template === 'leftsidenav'}
                                  onChange={(event) =>
                                    handleChange(event.target.value, 'template')
                                  }
                                  value="leftsidenav"
                                  control={
                                    <Radio
                                      sx={{
                                        color: '#C00000 !important',
                                      }}
                                    />
                                  }
                                  label="With Left Sidebar"
                                />
                                <ArrowBackIcon
                                  style={{
                                    position: 'absolute',
                                    top: '54%',
                                    left: '4px',
                                  }}
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={4}>
                              <Paper
                                elevation={3}
                                sx={{
                                  textAlign: 'center',
                                  // p: 1,
                                  cursor: 'pointer',
                                  borderRadius: '0 0px 4px 4px',
                                  display: 'flex',
                                  flexDirection: 'column-reverse',
                                  position: 'relative',
                                }}
                                onClick={() =>
                                  handleChange('rightsidenav', 'template')
                                }
                              >
                                <Box
                                  sx={(theme) => ({
                                    display: 'flex',
                                    borderRadius: '0 0px 4px 4px',
                                    background:
                                      newPage.template === 'rightsidenav'
                                        ? theme.palette.primary.main
                                        : '#CED1D6',
                                    '&:hover': {
                                      background: '#c00000',
                                    },
                                  })}
                                >
                                  <Paper
                                    elevation={0}
                                    sx={{
                                      height: 140,
                                      width: '80%',
                                      background: 'transparent',
                                      borderRadius: 0,
                                      borderRight: '6px solid #fff',
                                    }}
                                  />
                                  <Paper
                                    elevation={0}
                                    sx={{
                                      height: 140,
                                      width: '20%',
                                      background: 'transparent',
                                      borderRadius: 0,
                                    }}
                                  />
                                </Box>
                                <FormControlLabel
                                  sx={{
                                    paddingLeft: '8px',
                                    '& .MuiInputBase-formControl': {
                                      backgroundColor: '#FFF !important',
                                      top: '9px',
                                    },
                                  }}
                                  checked={newPage.template === 'rightsidenav'}
                                  onChange={(event) =>
                                    handleChange(event.target.value, 'template')
                                  }
                                  value="rightsidenav"
                                  control={
                                    <Radio
                                      sx={{
                                        color: '#C00000 !important',
                                      }}
                                    />
                                  }
                                  label="With Right Sidebar"
                                />
                                <ArrowForwardIcon
                                  style={{
                                    position: 'absolute',
                                    top: '54%',
                                    right: '6px',
                                  }}
                                />
                              </Paper>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                  {/* Right side pane */}
                  <Box
                    style={{
                      padding: '10px',
                      backgroundColor: '#F6F7F8',
                      width: '100%',
                    }}
                    className="rightPanel"
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        backgroundColor: '#F6F7F8',
                        width: '100%',
                      }}
                    >
                      <Grid item xs={12}>
                        <Tooltip
                          title="This section is OPTIONAL. SEO attributes help improve page’s search performance"
                          placement="bottom-start"
                        >
                          <Typography
                            sx={{
                              ...inputField,
                              marginBottom: 0,
                              marginTop: 0.5,
                              marginLeft: 4.5,
                              color: '#474747',
                              fontSize: 20,
                              fontFamily: 'Helvetica for Target',
                              fontWeight: '700',
                              wordWrap: 'break-word',
                            }}
                          >
                            SEO Information
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Box
                        sx={{
                          width: '100%',
                          marginLeft: '12px',
                          border: '2px dashed #d6d6d6 !important',
                          px: '20px !important',
                          mt: '10px',
                          overflow: 'scroll',
                        }}
                      >
                        <Grid item xs={12} sm={12}>
                          <Box sx={inputField}>
                            <Autocomplete
                              disablePortal
                              variant="outlined"
                              disabled={catCreationInProgress}
                              options={categories}
                              value={
                                newPage.category && newPage.category.label
                                  ? newPage.category
                                  : null
                              }
                              freeSolo
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  padding: 0,
                                },
                                '& .MuiInputBase-formControl': {
                                  background: '#fff',
                                  top: '9px',
                                },
                              }}
                              onChange={(_, newValue) => {
                                let value = {}
                                if (typeof newValue === 'string') {
                                  value.title = newValue
                                  value.value = newValue
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  value = newValue
                                } else {
                                  value = newValue ? newValue : ''
                                }
                                if (newValue && newValue.action) {
                                  // Initiate the new category action
                                  addNewCategory({
                                    category: {
                                      name: newValue.inputValue,
                                      type: 'category',
                                    },
                                    activeSite,
                                  })
                                } else {
                                  setNewPage({
                                    ...newPage,
                                    category: !value.label
                                      ? null
                                      : { ...value },
                                  })
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params)

                                const { inputValue } = params
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label,
                                )
                                if (
                                  inputValue !== '' &&
                                  !isExisting &&
                                  superRights
                                ) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add "${inputValue}"`,
                                    action: 'create',
                                  })
                                }

                                return filtered
                              }}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue
                                }
                                // Regular option
                                return option.label
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.label}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    borderRadius: '4px',
                                    borderColor: '#888',
                                    '& .MuiFormHelperText-root': {
                                      marginTop: '10px',
                                      marginLeft: '2px',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                      padding: '6.5px 14px',
                                    },
                                    ...formFieldStyles,
                                  }}
                                  label="Category"
                                  variant={'outlined'}
                                  fullWidth
                                  helperText="Input page category (This value will be used for sorting, searching and more)"
                                />
                              )}
                            />
                          </Box>
                          {catCreationInProgress && (
                            <LinearProgress sx={{ ml: 3, mr: 3 }} />
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <Box sx={inputField}>
                            <Autocomplete
                              multiple
                              sx={{
                                marginBottom: '20px !important',
                                '& .MuiOutlinedInput-root': {
                                  padding: 0,
                                },
                                '& .MuiInputBase-formControl': {
                                  background: '#fff',
                                  top: '9px',
                                },
                              }}
                              value={newPage.tags ? newPage.tags : null}
                              options={tags}
                              disabled={tagCreationInProgress}
                              onChange={(_, newValue, reason) => {
                                const foundCreation = newValue.filter(
                                  (val) => val.action === 'create',
                                )
                                if (foundCreation && foundCreation.length > 0) {
                                  addNewTag({
                                    tag: {
                                      name: foundCreation[0].inputValue,
                                      type: 'tag',
                                    },
                                    activeSite,
                                  })
                                } else {
                                  setNewPage({
                                    ...newPage,
                                    tags: [...newValue],
                                  })
                                }
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.label}</li>
                              )}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    label={option.label}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              style={{}}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={'Tags'}
                                  sx={{
                                    borderRadius: '4px',
                                    borderColor: '#888',

                                    '& .MuiFormHelperText-root': {
                                      marginTop: '10px',
                                      marginLeft: '2px',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                      padding: '6.5px 14px',
                                    },
                                    ...formFieldStyles,
                                  }}
                                  helperText="Input page tags (This values will be used for sorting, searching and more). # key is not allowed in tags"
                                  placeholder={
                                    'Press enter for adding new tags'
                                  }
                                  variant={'outlined'}
                                  onKeyPress={(e) => {
                                    if (e.key === '#') {
                                      e.preventDefault()
                                    }
                                  }}
                                />
                              )}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params)

                                const { inputValue } = params
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label,
                                )
                                if (
                                  inputValue !== '' &&
                                  !isExisting &&
                                  superRights
                                ) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add "${inputValue}"`,
                                    action: 'create',
                                  })
                                }

                                return filtered
                              }}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue
                                }
                                // Regular option
                                return option.label
                              }}
                            />
                          </Box>
                          {tagCreationInProgress && (
                            <LinearProgress sx={{ ml: 3, mr: 3 }} />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControl
                            fullWidth
                            sx={{
                              marginBottom: '20.5px',
                              paddingLeft: '8px',
                              '& .MuiInputBase-formControl': {
                                backgroundColor: '#FFF !important',
                                top: '9px',
                              },
                            }}
                          >
                            <TextField
                              sx={{
                                borderRadius: '4px',
                                borderColor: '#888',
                                '& .MuiFormHelperText-root': {
                                  marginTop: '10px',
                                  marginLeft: '2px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  padding: '6.5px 14px',
                                },
                                ...formFieldStyles,
                              }}
                              id="title"
                              value={title}
                              onChange={(event) =>
                                handleSeoChanges(event.target.value, 'title')
                              }
                              label="Meta Title (H1)"
                              placeholder="Enter the meta title"
                              variant="outlined"
                              helperText="This title is the first element that appears in a search listing of the page"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControl
                            fullWidth
                            sx={{
                              marginBottom: '18.5px',
                              paddingLeft: '8px',
                              '& .MuiInputBase-formControl': {
                                backgroundColor: '#FFF !important',
                                top: '9px',
                              },
                            }}
                          >
                            <TextField
                              sx={{
                                borderRadius: '4px',
                                borderColor: '#888',
                                '& .MuiFormHelperText-root': {
                                  marginTop: '10px',
                                  marginLeft: '2px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  padding: '6.5px 14px',
                                },
                                ...formFieldStyles,
                              }}
                              id="keywords"
                              value={keywords}
                              onChange={(event) =>
                                handleSeoChanges(event.target.value, 'keywords')
                              }
                              label="Meta Keywords"
                              placeholder="Enter the meta keywords"
                              helperText="Keywords will be used to identify and search for pages in Stencil"
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControl
                            fullWidth
                            sx={{
                              marginBottom: '20.5px',
                              paddingLeft: '8px',
                              '& .MuiInputBase-formControl': {
                                backgroundColor: '#FFF !important',
                                top: '9px',
                              },
                            }}
                          >
                            <TextField
                              sx={{
                                borderRadius: '4px',
                                borderColor: '#888',
                                '& .MuiFormHelperText-root': {
                                  marginTop: '10px',
                                  marginLeft: '2px',
                                },
                                '& .MuiOutlinedInput-input': {
                                  padding: '6.5px 14px',
                                },
                                ...formFieldStyles,
                              }}
                              id="description"
                              value={description}
                              onChange={(event) =>
                                handleSeoChanges(
                                  event.target.value,
                                  'description',
                                )
                              }
                              label="Meta Description"
                              placeholder="Enter the meta description"
                              variant="outlined"
                              helperText="Key phrases will be used to identify and search for pages in Stencil"
                            />
                          </FormControl>
                        </Grid>
                        <Typography
                          sx={{
                            marginTop: '20px',
                            marginBottom: '7px',
                            ml: '7px',
                          }}
                        >
                          Meta Image
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            ml: '7px',
                            '& > button ': {
                              width: '193px',
                              height: '216px',
                              background: '#f0f0f0',
                              border: '2px dashed #d6d6d6',
                              '& div ': {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              },
                            },
                          }}
                        >
                          <MediaPicker
                            dataCallBack={(file) =>
                              handleSeoChanges(file, 'image')
                            }
                            value={image}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginLeft: '30px',
                            }}
                          >
                            <Grid item xs={12} sm={12} sx={inputWrapper}>
                              <FormControl
                                fullWidth
                                sx={{
                                  paddingLeft: '8px',
                                  '& .MuiInputBase-formControl': {
                                    backgroundColor: '#FFF !important',
                                    top: '9px',
                                  },
                                }}
                              >
                                <TextField
                                  id="alt_text"
                                  value={alt_text}
                                  onChange={(event) =>
                                    handleSeoChanges(
                                      event.target.value,
                                      'alt_text',
                                    )
                                  }
                                  sx={{
                                    borderRadius: '4px',
                                    borderColor: '#888',
                                    width: '336px',
                                    '& .MuiFormHelperText-root': {
                                      marginTop: '10px',
                                      marginLeft: '2px',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                      padding: '6.5px 14px',
                                    },
                                    ...formFieldStyles,
                                  }}
                                  label="Alt text"
                                  placeholder="Type here"
                                  required
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={inputWrapper}>
                              <FormControl
                                fullWidth
                                sx={{
                                  paddingLeft: '8px',
                                  '& .MuiInputBase-formControl': {
                                    backgroundColor: '#FFF !important',
                                    top: '9px',
                                  },
                                }}
                              >
                                <TextField
                                  id="img_title"
                                  value={img_title}
                                  onChange={(event) =>
                                    handleSeoChanges(
                                      event.target.value,
                                      'img_title',
                                    )
                                  }
                                  sx={{
                                    borderRadius: '4px',
                                    borderColor: '#888',
                                    width: '336px',
                                    '& .MuiFormHelperText-root': {
                                      marginTop: '10px',
                                      marginLeft: '2px',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                      padding: '6.5px 14px',
                                    },
                                    ...formFieldStyles,
                                  }}
                                  label="Title"
                                  placeholder="Type here"
                                  required
                                  variant="outlined"
                                />
                              </FormControl>
                            </Grid>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={12} sx={inputWrapper}>
                          <FormControl
                            fullWidth
                            sx={{
                              paddingLeft: '8px',
                              '& .MuiInputBase-formControl': {
                                backgroundColor: '#FFF !important',
                                top: '9px',
                              },
                            }}
                          >
                            <Typography
                              sx={{ marginTop: '25px', marginBottom: '7px' }}
                            >
                              Page Footer Notes
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: '#fff',
                                display: 'flex',
                                width: '615px',
                                height: '83px',
                                justifyContent: 'left',
                                color: '#c4c4c4',
                                border: '1px solid #888',
                                borderRadius: '4px',
                                marginBottom: '38px',
                                '& .MuiBox-root': { borderRadius: '4px' },
                                '& .quill': {
                                  width: '100%',
                                  borderRadius: 4,
                                },
                              }}
                            >
                              <ReactQuill
                                value={footer_notes}
                                onChange={(newValue, _, source) => {
                                  if (source === 'user') {
                                    handleSeoChanges(newValue, 'footer_notes')
                                  }
                                }}
                                placeholder={
                                  'Enter the footer notes of the page.'
                                }
                                theme="bubble"
                                modules={{
                                  toolbar: [
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strike',
                                    'blockquote',
                                    'link',
                                    { list: 'ordered' },
                                    { list: 'bullet' },
                                    { indent: '-1' },
                                    { indent: '+1' },
                                    { script: 'super' },
                                    { script: 'sub' },
                                  ],
                                }}
                                formats={[
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'script',
                                ]}
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                  <div>
                    <Dialog
                      open={isReset}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {'Are you sure want to clear?'}
                      </DialogTitle>
                      <DialogActions>
                        <Button onClick={handleClearForm}>Yes</Button>
                        <Button onClick={handleClose} autoFocus>
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </Box>

              {/* Footer */}
              <Box
                sx={{
                  m: 2,
                  float: 'right',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 1,
                    width: '145px',
                    height: '45px',
                    borderColor: '#c00000',
                    marginRight: '4%',
                    p: 1,
                    color: '#c00000',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    navigate('/pages/')
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    newPage.name.trim() === '' || newPage.slug.trim() === ''
                      ? true
                      : false || editInProgress
                  }
                  sx={{
                    borderRadius: 1,
                    textTransform: 'none',
                    width: '145px',
                    height: '45px',
                    p: 1,
                    backgroundColor: '#c00000',
                  }}
                  onClick={editPage}
                >
                  Save
                </Button>
              </Box>
            </>
          ) : (
            <Alert severity={'error'}>
              <AlertTitle>
                <strong>Unable to retrieve page</strong>
              </AlertTitle>
              The page does't exist
            </Alert>
          )}
        </>
      ) : (
        <AccessDenied module="Pages" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Page',
  actions: [
    {
      slug: 'edit',
      access: 'edit',
    },
  ],
}

export default withRights(UpdatePage, moduleAttr)
