import React from 'react'
import { Box } from '@mui/material'
import MediaPicker from '../../../../../../../components/MediaPicker'
import { useDispatch, useSelector } from 'react-redux'
import { persistMediaList } from '../../../../../../../api/session'
import { DataConnectivityIndicator } from '../DataConnector'
import { Label } from '../Label'

export const Media = (props) => {
  const {
    name = 'Media',
    onChange,
    value,
    disabled = false,
    slug = '',
    noSlugChange = false,
    device = '',
    path = '',
  } = props
  const dispatch = useDispatch()
  const mediaArr = useSelector((state) => state.session.mediaList)

  const dataCallBack = (response, obj) => {
    if (obj) {
      const updatedMediaList = [...mediaArr, obj]
      let sortedMedia = updatedMediaList.sort((e1, e2) =>
        e1.size < e2.size ? 1 : e1.size > e2.size ? -1 : 0,
      )
      dispatch(persistMediaList(sortedMedia))
    }
    onChange(
      response && response,
      noSlugChange
        ? slug
        : `${slug}${
            device && device !== 'desktop'
              ? device.charAt(0).toUpperCase() + device.slice(1)
              : ''
          }`,
    )
  }
  return (
    <Box>
      <Label name={name} />
      <Box sx={{ display: 'flex', m: 1 }}>
        <DataConnectivityIndicator flag={`${path}.${slug}`}>
          <Box sx={{ display: 'flex', width: '80%', justifyContent: 'center' }}>
            <MediaPicker
              dataCallBack={dataCallBack}
              value={value}
              fileNameshrink={true}
              disabled={disabled}
            />
          </Box>
        </DataConnectivityIndicator>
      </Box>
    </Box>
  )
}

export default Media
