import { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import {
  Box,
  Button,
  Select,
  MenuItem,
  Stack,
  IconButton,
  Drawer,
  SvgIcon,
} from '@mui/material'
import { CalendarToday, DashboardCustomize } from '@mui/icons-material'

import MetricsCard from './Widgets/MetricsCard'
import PagesAndPostsTable from './Widgets/PagesAndPostsTable'
import CustomizeUserDashboard from './CustomizeUserDashboard'
import SiteActivityList from './SiteActivityList'
import { Loader } from '../../components'
import {
  INITIAL_USERS_WEBSITE_DASHBOARD_STATE,
  USERS_DASHBOARD_METRICS,
} from './dashboardConstants'
import {
  useGetUserDashboardConfigQuery,
  useGetUserDashboardMetricsQuery,
  useUpdateUserDashboardConfigMutation,
} from '../../api/newDashboard'

const metricsReducer = (state, action) => {
  switch (action.type) {
    case 'updateMetricsValue': {
      const { page, post } = action.value
      return {
        ...state,
        my_assignments: {
          ...state.my_assignments,
          value: page.assigned_to_me + post.assigned_to_me,
        },
        pending_requests: {
          ...state.pending_requests,
          value: page.pending_approval + post.pending_approval,
        },
        approved_requests: {
          ...state.approved_requests,
          value: page.approved + post.approved,
        },
        drafts: {
          ...state.drafts,
          value: page.draft + post.draft,
        },
        published: {
          ...state.published,
          value: page.published + post.published,
        },
        unpublished: {
          ...state.unpublished,
          value: page.unpublished + post.unpublished,
        },
        rejected_requests: {
          ...state.rejected_requests,
          value: page.rejected + post.rejected,
        },
      }
    }
    case 'updateMetricsVisibility': {
      const updatedState = {}
      action.value.forEach((item) => {
        updatedState[item.key] = {
          ...state[item.key],
          isVisible: item.isVisible,
        }
      })
      return updatedState
    }
    default:
      return { ...state }
  }
}

const UserWebsiteDashboard = (props) => {
  const [selectedCard, setSelectedCard] = useState(
    USERS_DASHBOARD_METRICS[0].key,
  )
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [metrics, dispatchMetrics] = useReducer(
    metricsReducer,
    INITIAL_USERS_WEBSITE_DASHBOARD_STATE.metrics,
  )
  const [isCustomizeDrawerOpen, setIsCustomizeDrawerOpen] = useState(false)
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: metricsData, isFetching: isMetricsLoading } =
    useGetUserDashboardMetricsQuery({
      year: selectedYear,
      site: activeSite._id,
    })
  const { data: dashbaordConfig, isLoading: isConfigLoading } =
    useGetUserDashboardConfigQuery({
      site: activeSite._id,
    })
  const [updateConfig, { isError }] = useUpdateUserDashboardConfigMutation()

  useEffect(() => {
    if (metricsData) {
      dispatchMetrics({ type: 'updateMetricsValue', value: metricsData })
    }
  }, [metricsData])
  useEffect(() => {
    if (dashbaordConfig) {
      dispatchMetrics({
        type: 'updateMetricsVisibility',
        value: dashbaordConfig,
      })
      const isSelectedMetricVisible = dashbaordConfig.find(
        (metricDetails) => metricDetails.key === selectedCard,
      ).isVisible
      if (!isSelectedMetricVisible) {
        const newMetricToSelect = dashbaordConfig.find(
          (metricDetails) => metricDetails.isVisible,
        ).key
        setSelectedCard(newMetricToSelect)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashbaordConfig])
  useEffect(() => {
    if (isError) {
      enqueueSnackbar('Failed to save customization', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }, [isError, enqueueSnackbar])

  return (
    <Box padding={2} height="calc(100% - 120px)">
      {isConfigLoading ? (
        <Loader />
      ) : (
        <>
          <Stack
            padding={2}
            gap="20px"
            sx={{ backgroundColor: '#F6F7F8', minHeight: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h3>
                {selectedYear === moment().year()
                  ? 'Current year'
                  : selectedYear}{' '}
                status
              </h3>
              <Stack direction="row" gap={2}>
                <IconButton
                  color="secondary"
                  onClick={() => setIsCustomizeDrawerOpen(true)}
                >
                  <DashboardCustomize />
                </IconButton>
                <Select
                  sx={{ background: '#FFFFFF' }}
                  size="small"
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <SvgIcon style={{ color: '#5C5C5C' }} fontSize="small">
                          <CalendarToday />
                        </SvgIcon>
                        {value}
                      </Box>
                    )
                  }}
                  value={selectedYear}
                  onChange={(event) => {
                    setSelectedYear(event.target.value)
                  }}
                >
                  {Array.from({ length: 5 }, (_, i) => moment().year() - i).map(
                    (year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ),
                  )}
                </Select>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsActivityDrawerOpen(true)}
                >
                  Activity
                </Button>
              </Stack>
            </div>
            <Stack direction="row" flexWrap="wrap" gap="20px">
              {USERS_DASHBOARD_METRICS.map((metricDetails) =>
                metrics[metricDetails.key].isVisible ? (
                  <Box
                    maxHeight={150}
                    maxWidth={300}
                    key={metricDetails.key}
                    sx={{
                      flex: '1 0 200px',
                    }}
                  >
                    <MetricsCard
                      title={metricDetails.title}
                      value={metrics[metricDetails.key].value}
                      color={metricDetails.color}
                      isLoading={isMetricsLoading}
                      isSelected={selectedCard === metricDetails.key}
                      onSelect={() => setSelectedCard(metricDetails.key)}
                    />
                  </Box>
                ) : (
                  <></>
                ),
              )}
            </Stack>
            <PagesAndPostsTable
              selectedYear={selectedYear}
              site={activeSite._id}
              selectedCard={selectedCard}
              actionsAllowed={props.actionsAllowed}
            />
          </Stack>
          <Drawer
            anchor="right"
            open={isCustomizeDrawerOpen}
            onClose={() => setIsCustomizeDrawerOpen(false)}
          >
            <CustomizeUserDashboard
              metricsState={Object.keys(metrics).map((key) => ({
                key: key,
                isVisible: metrics[key].isVisible,
              }))}
              onClose={() => setIsCustomizeDrawerOpen(false)}
              onSave={(value) => {
                setIsCustomizeDrawerOpen(false)
                updateConfig({
                  settings: {
                    metrics: value,
                  },
                  site: activeSite._id,
                })
              }}
            />
          </Drawer>
          <Drawer
            anchor="right"
            open={isActivityDrawerOpen}
            onClose={() => setIsActivityDrawerOpen(false)}
          >
            <SiteActivityList onClose={() => setIsActivityDrawerOpen(false)} />
          </Drawer>
        </>
      )}
    </Box>
  )
}

export default UserWebsiteDashboard
