import React from 'react'
import { Typography, Box, Tabs, Tab } from '@mui/material'
import withRights from '../../components/withRights'
import AccessDenied from '../../Layout/AccessDenied'
import Loader from '../../components/Loader'
import PropTypes from 'prop-types'
import Tags from './Tags'
import Categories from './Categories'
import Typographies from './Typographies'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import ColorPalette from './ColorPalette'
import SiteSettings from './SiteSettings'
import EventSettings from './EventSettings'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const Settings = (props) => {
  const { rights, rightsLoading, noSiteAccess = false } = props
  const { moduleAccess, superRights, permittedActions } = rights
  const [typeOfMenu, setTypeOfMenu] = React.useState('categories')
  const handleChange = (_, newValue) => {
    setTypeOfMenu(newValue)
  }

  const allow = {
    add: checkUserIsAllowed('add', permittedActions, superRights),
    edit: checkUserIsAllowed('edit', permittedActions, superRights),
    delete: checkUserIsAllowed('delete', permittedActions, superRights),
    publish: checkUserIsAllowed('publish', permittedActions, superRights),
  }

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      {rightsLoading && !noSiteAccess ? (
        <Loader />
      ) : moduleAccess ? (
        <>
          <Box sx={{ display: 'flex', background: '#FFFFFF', px: 2, py: 1 }}>
            <Box>
              <Typography
                variant={'h5'}
                component={'h3'}
                sx={{
                  fontFamily: 'Helvetica For Target',
                  fontWeight: 'Bold',
                  marginBottom: 0,
                  color: '#3D3D3D',
                  mr: 2,
                }}
              >
                Settings
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              height: '85vh',
              boxShadow: 5,
              mx: 2,
            }}
          >
            <Box sx={{ width: '100%', height: '100%' }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  backgroundColor: '#E6E7EA',
                }}
              >
                <Tabs
                  value={typeOfMenu}
                  onChange={handleChange}
                  sx={{
                    '& .MuiTabs-indicator': { backgroundColor: '#CC0000' },
                  }}
                >
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="categories"
                    label="Category Management"
                  />
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="tags"
                    label="Tags Management"
                  />
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="typography"
                    label="Typography Management"
                  />
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="color_tool"
                    label="Color Palette"
                  />
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="site"
                    label="Site Settings"
                  />
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="event"
                    label="Module Settings"
                  />
                </Tabs>
              </Box>
              <Box sx={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
                <TabPanel value={typeOfMenu} index={'categories'}>
                  <Categories allow={allow} />
                </TabPanel>
                <TabPanel value={typeOfMenu} index={'tags'}>
                  <Tags allow={allow} />
                </TabPanel>
                <TabPanel value={typeOfMenu} index={'typography'}>
                  <Typographies allow={allow} />
                </TabPanel>
                <TabPanel value={typeOfMenu} index={'color_tool'}>
                  <ColorPalette allow={allow} />
                </TabPanel>
                <TabPanel value={typeOfMenu} index={'site'}>
                  <SiteSettings allow={allow} />
                </TabPanel>
                <TabPanel value={typeOfMenu} index={'event'}>
                  <EventSettings allow={allow} />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <AccessDenied module="Settings" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Settings',
  actions: [
    {
      slug: 'add',
      access: 'add',
    },
    {
      slug: 'edit',
      access: 'edit',
    },
    {
      slug: 'delete',
      access: 'delete',
    },
    {
      slug: 'publish',
      access: 'publish',
    },
  ],
}

export default withRights(Settings, moduleAttr)
