import React, { useState, useRef } from 'react'
import {
  Card,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  CardMedia,
  TextField,
} from '@mui/material'
import { objectMerge } from '../../../../../utils/dataParser'
import ReactQuill from 'react-quill'
// import OpenForm from './ComponentFields/OpenForm'
import { Delete, Edit, MoveDown, MoveUp } from '@mui/icons-material'
import DeleteConfirmation from '../../../../../components/DeleteConfirmation'
import { ComponentLoader } from 'enterprise-stencil'
import { useGetArticleComponentsQuery } from '../../../../../api/component'
import { getComponentIcons } from '../../../../../utils/helper'
import CloneComponent from '../SectionsList/CloneComponent'
import CloneComponentFromPost from '../SectionsList/CloneComponentFromPost'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import EditIcon from '@mui/icons-material/Edit'
import { useAnalytics } from '@praxis/component-analytics'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

// const renderDataPoint = () => {}

export default function Section(props) {
  const {
    section,
    sectionNumber,
    setSection,
    // componentWidths,
    // components,
    select,
    onSectionClick,
    selectedComponent,
    setComponentAttr,
    onDelete,
    moveSection,
    // sectionName,
    postChangesAllowed,
  } = props

  const [deleteConfirm, initDeleteConfirm] = useState(false)
  const [selectedForDelete, setSelectedForDelete] = useState(null)
  const [componentListData, setComponentListData] = useState([])
  const [openCloneOption, setOpenCloneOption] = React.useState(false)
  const [openClonePostOption, setOpenClonePostOption] = React.useState(false)
  const [sectionNameEdit, setSectionNameEdit] = useState(false)
  const sectionNameRef = useRef(null)

  const { trackEvent } = useAnalytics()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  let { id: postID } = useParams()

  const { data: componentList, isLoading: componentListLoading } =
    useGetArticleComponentsQuery()

  React.useEffect(() => {
    if (componentList) {
      setComponentListData(componentList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentList])

  const onChangeAttr = (elementName, value, multi, position) => {
    const updated = objectMerge(
      section.components.attributes,
      elementName,
      value,
    )
    setComponentAttr(updated)
  }

  // const [showDataForm, toggleDataForm] = useState(false)

  // const [componentData, setComponentData] = useState({})

  // const openDataForm = (compData) => {
  //   setComponentData(compData)
  //   toggleDataForm(true)
  // }

  // const updateComponentData = (updatedAttributes) => {
  //   setComponentAttr(updatedAttributes)
  //   toggleDataForm(false)
  //   setComponentData({})
  // }

  const onDeleteClick = (secNo) => {
    setSelectedForDelete(secNo)
    initDeleteConfirm(true)
  }

  const deleteSection = () => {
    onDelete(selectedForDelete)
    setSelectedForDelete(null)
    initDeleteConfirm(false)
  }

  const renderEditor = (type, key) => {
    const {
      text = '',
      // videoSrc = '',
      // image = '',
      // objectFit = 'cover',
      // height,
    } = section.components[key].attributes
    switch (type) {
      case 'text':
        return (
          <ReactQuill
            value={text}
            onChange={(newValue, _, source) => {
              if (source === 'user') {
                onChangeAttr('text', newValue)
              }
            }}
            theme="bubble"
            bounds=".inputs"
            modules={{
              toolbar: [
                { header: [3, false] },
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                { list: 'ordered' },
                { list: 'bullet' },
                { script: 'sub' },
                { script: 'super' },
                { indent: '-1' },
                { indent: '+1' },
                'link',
                { color: [] },
                { background: [] },
              ],
            }}
            formats={[
              'header',
              'align',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'script',
              'bullet',
              'indent',
              'link',
              'color',
              'background',
            ]}
            placeholder="Add some content here!"
            style={{ width: '100%' }}
            className={'post-editor'}
          />
        )
      default:
        return (
          <div className="article-body">
            <ComponentLoader {...section.components[key]} preview={true} />
          </div>
        )
    }
  }

  const showHtml = (type, key) => {
    const { text = '' } = section.components[key].attributes
    switch (type) {
      case 'text':
        return (
          <ReactQuill
            value={text}
            theme="bubble"
            bounds=".inputs"
            readOnly={true}
            className={'post-editor'}
          />
        )
      default:
        return (
          <div className="article-body">
            <ComponentLoader {...section.components[key]} preview={true} />
          </div>
        )
    }
  }

  const addNewComponentToSection = (newComponent, column, isCloned) => {
    if (process.env.REACT_APP_ENV === 'prod') {
      trackEvent({
        customMetrics: {
          metric1: activeSite._id,
          metric2: '',
          metric3: postID,
        },
        customInteraction: {
          key: newComponent.name,
          value: null,
        },
        event: {
          name: 'add_new_component',
          type: 'click',
        },
      })
    }
    const updatedComp = [...section.components.slice(0, column)]
    if (column === 2 && section.components.length === 1) {
      updatedComp.push({})
    }
    updatedComp.push({
      ...newComponent,
      attributes: isCloned ? newComponent.attributes : {},
    })
    if (column === 1 && section.components.slice(column + 1).length > 0) {
      updatedComp.push(...section.components.slice(column + 1))
    }
    const updated = {
      ...section,
      components: updatedComp,
    }
    setSection(updated)
    // setComponentSelection([
    //   ...section.componentSelection.filter((val) => val !== column),
    // ])
  }

  const initClone = () => {
    setOpenCloneOption(true)
  }

  const initClonePost = () => {
    setOpenClonePostOption(true)
  }

  const relativeArticleComp = {
    grid: 'gridWrapper',
    banner: 'articleBanner',
  }

  const cloneTheCopy = (component, key) => {
    const newComp = {
      ...component,
      slug: relativeArticleComp[component.slug]
        ? relativeArticleComp[component.slug]
        : component.slug,
    }
    addNewComponentToSection(newComp, key, true)
    setOpenCloneOption(false)
  }

  const handleNameChange = (val) => {
    const updatedData = { ...section, sectionName: val }
    // setSectionName(val)
    //setSectionData(updatedData)
    setSection(updatedData)
  }

  const setSectionName = () => {
    setSectionNameEdit(false)
  }

  return (
    <Box>
      {!sectionNameEdit ? (
        <>
          <Box sx={{ display: 'flex' }}>
            <Chip
              size={'small'}
              label={section.sectionName}
              sx={{
                // position: 'absolute',
                // right: 0,
                // top: 0,
                borderRadius: 0,
                fontWeight: 'bold',
                // fontSize: 10,
              }}
            />
            <IconButton
              sx={{
                background: 'rgba(0, 0, 0, 0.08)',
                borderRadius: 0,
                px: 1,
                py: 0,
              }}
              disabled={!postChangesAllowed}
              onClick={() => setSectionNameEdit(true)}
            >
              <EditIcon fontSize="small" sx={{ fontSize: '1rem' }}></EditIcon>
            </IconButton>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex' }}>
            <TextField
              ref={sectionNameRef}
              size="small"
              variant="outlined"
              onChange={(e) => handleNameChange(e.target.value)}
              placeholder="Enter section name"
              sx={{ mb: 1 }}
              value={section.sectionName}
              disabled={!postChangesAllowed}
            />
            <IconButton
              sx={{
                border: 'none',
                borderRadius: 0,
                px: 1,
                py: 0,
              }}
              onClick={() => setSectionName()}
              disabled={!postChangesAllowed}
            >
              <DoneOutlineIcon
                fontSize="small"
                sx={{ fontSize: '1rem' }}
              ></DoneOutlineIcon>
            </IconButton>
          </Box>
        </>
      )}
      <Card
        sx={{
          borderRadius: 0,
          marginBottom: 1,
          position: 'relative',
          background: '#fff',
          overflow: 'visible',
          display: 'flex',
        }}
        elevation={8}
        onClick={() => onSectionClick(sectionNumber - 1)}
      >
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            {section.componentWidths.map((currentWidth, key) => {
              return (
                <React.Fragment key={key}>
                  {section.components[key] && section.components[key].name ? (
                    <>
                      <Card
                        key={key}
                        sx={{
                          width: `${currentWidth}%`,
                          borderRadius: 0,
                          m: 1,
                          p: 1,
                          border:
                            selectedComponent && selectedComponent.col === key
                              ? '2px solid'
                              : '2px dotted',
                          overflow: 'visible',
                        }}
                        elevation={
                          selectedComponent && selectedComponent.col === key
                            ? 8
                            : 0
                        }
                        onClick={() => select(sectionNumber - 1, key)}
                      >
                        <Box
                          sx={{
                            display: 'block',
                            height: '100%',
                          }}
                          className="inputs"
                        >
                          {selectedComponent && selectedComponent.col === key
                            ? renderEditor(section.components[key].slug, key)
                            : showHtml(section.components[key].slug, key)}
                        </Box>
                      </Card>
                      <Box
                        sx={{
                          background: '#FFF',
                          display: 'block',
                          width: 40,
                        }}
                      >
                        <Tooltip title="Edit section" placement="right">
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => select(sectionNumber - 1, key)}
                            >
                              <Edit />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete section" placement="right">
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => onDeleteClick(sectionNumber)}
                            >
                              <Delete />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Move up" placement="right">
                          <span>
                            <IconButton
                              size="small"
                              disabled={sectionNumber === 1}
                              onClick={() =>
                                moveSection(
                                  sectionNumber - 1,
                                  sectionNumber - 2,
                                )
                              }
                            >
                              <MoveUp />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Move down" placement="right">
                          <span>
                            <IconButton
                              size="small"
                              onClick={() =>
                                moveSection(sectionNumber - 1, sectionNumber)
                              }
                            >
                              <MoveDown />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </>
                  ) : (
                    <Card
                      variant="outlined"
                      sx={{
                        //  width: `${currentWidth}%`,
                        borderRadius: 0,
                        m: 1,
                        p: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%',
                      }}
                    >
                      <Typography variant="h6" sx={{ color: '#CC0000' }}>
                        Select Component
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          borderRadius: '4px',
                        }}
                      >
                        {!componentListLoading &&
                          componentListData.length > 0 &&
                          componentListData.map((component, index) => {
                            return (
                              <Box
                                key={index}
                                sx={{
                                  padding: '20px',
                                  backgroundColor: '#F8FBFF',
                                  cursor: 'pointer',
                                  border: '1px solid #F0F0F0',
                                  '&:hover': {
                                    filter: 'brightness(50%)',
                                  },
                                }}
                                onClick={() =>
                                  addNewComponentToSection(
                                    component,
                                    key,
                                    false,
                                  )
                                }
                              >
                                <Tooltip title={component.name}>
                                  <CardMedia
                                    component="img"
                                    image={getComponentIcons(component.slug)}
                                    alt={component.name}
                                  />
                                </Tooltip>
                              </Box>
                            )
                          })}
                        {!componentListLoading &&
                          componentListData.length > 0 && (
                            <>
                              <Box
                                sx={{
                                  padding: '20px',
                                  backgroundColor: '#F8FBFF',
                                  border: '1px solid #F0F0F0',
                                  '&:hover': {
                                    filter: 'brightness(50%)',
                                    cursor: 'pointer',
                                  },
                                }}
                                onClick={initClone}
                              >
                                <Tooltip title="Clone component from page">
                                  <CardMedia
                                    component="img"
                                    image={getComponentIcons('clone')}
                                    alt={'clone'}
                                  />
                                </Tooltip>
                              </Box>
                              <Box
                                sx={{
                                  padding: '20px',
                                  backgroundColor: '#F8FBFF',
                                  border: '1px solid #F0F0F0',
                                  '&:hover': {
                                    filter: 'brightness(50%)',
                                    cursor: 'pointer',
                                  },
                                }}
                                onClick={initClonePost}
                              >
                                <Tooltip title="Clone component from post">
                                  <CardMedia
                                    component="img"
                                    image={getComponentIcons('clone')}
                                    alt={'clone'}
                                  />
                                </Tooltip>
                              </Box>
                            </>
                          )}
                        <CloneComponent
                          open={openCloneOption}
                          handleClose={() => setOpenCloneOption(false)}
                          handleAdd={(component) =>
                            cloneTheCopy(component, key)
                          }
                          allowed={['grid', 'banner']}
                        />
                        <CloneComponentFromPost
                          open={openClonePostOption}
                          handleClose={() => setOpenClonePostOption(false)}
                          handleAdd={(component) =>
                            cloneTheCopy(component, key)
                          }
                        />
                      </Box>
                    </Card>
                  )}
                </React.Fragment>
              )
            })}
          </Box>
        </Box>
      </Card>
      {/* <OpenForm
        open={showDataForm}
        name={componentData.name}
        data={componentData.attributes}
        handleClose={() => toggleDataForm(false)}
        save={updateComponentData}
      /> */}
      <DeleteConfirmation
        onConfirm={deleteSection}
        onClose={() => initDeleteConfirm(false)}
        open={deleteConfirm}
      />
    </Box>
  )
}
