import { useEffect, useState } from 'react'
import { useListWorkshopsQuery } from '../../../../api/workshop'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useListMentorshipsQuery } from '../../../../api/mentorship'
const initial_values = {
  search: '',
}
const EngagementsList = ({ notification, setNotification }) => {
  const params = useParams()
  const { id: eventId } = params
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [workshopListData, setWorkshopListData] = useState([])
  const [mentorshipListData, setMentorshipListData] = useState([])

  const { data: workshopData, isFetching: workshopFetching } =
    useListWorkshopsQuery(
      { eventid: eventId, filters: initial_values, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  useEffect(() => {
    if (workshopData) {
      setWorkshopListData(workshopData.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshopData])

  const { data: mentorshipData, isFetching: mentorshipFetching } =
    useListMentorshipsQuery(
      { eventid: eventId, filters: initial_values, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )
  useEffect(() => {
    if (mentorshipData) {
      setMentorshipListData(mentorshipData.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorshipData])

  const engagements = mentorshipData &&
    workshopData && [...workshopListData, ...mentorshipListData]

  return (
    <>
      {mentorshipFetching || workshopFetching ? (
        <CircularProgress />
      ) : (
        <Grid>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Choose the Engagement
            </FormLabel>
            {engagements &&
              engagements.map((engagement, index) => (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={notification.engagementId}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      engagementId: e.target.value,
                      engagementName: engagement.name,
                    })
                  }
                >
                  {notification.page !== '/rating' ? (
                    <FormControlLabel
                      value={engagement._id}
                      control={<Radio required />}
                      label={
                        engagement._secondary_name_text +
                        ' - ' +
                        engagement.name
                      }
                    />
                  ) : (
                    engagement.enable_feedback && (
                      <FormControlLabel
                        value={engagement._id}
                        control={<Radio required />}
                        label={
                          engagement._secondary_name_text +
                          ' - ' +
                          engagement.name
                        }
                      />
                    )
                  )}
                </RadioGroup>
              ))}
          </FormControl>
        </Grid>
      )}
    </>
  )
}

export default EngagementsList
