const updateObjectValueByPath = ({ arrayPath, data, value }) => {
  const key = arrayPath[0]
  if (Array.isArray(data) && data !== null) {
    if (arrayPath.length > 1) {
      if (data.length < key + 1) {
        const extra = [...Array(key + 1 - data.length)].map(() => {
          return {}
        })
        data = [...data, ...extra]
      }
      return data.map((el, index) => {
        if (index === key) {
          return updateObjectValueByPath({
            arrayPath: arrayPath.slice(1, arrayPath.length),
            data: el,
            value,
          })
        }

        return el
      }, [])
    }

    return [...data, value]
  }

  if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
    if (arrayPath.length > 1) {
      return {
        ...data,
        [key]: updateObjectValueByPath({
          arrayPath: arrayPath.slice(1, arrayPath.length),
          data:
            data[key] === undefined
              ? arrayPath[1] !== undefined && typeof arrayPath[1] === 'number'
                ? []
                : {}
              : data[key],
          value,
        }),
      }
    }

    return { ...data, [key]: value }
  }

  return data
}

const isInteger = (str) => {
  var n = Math.floor(Number(str))
  return n !== Infinity && String(n) === str && n >= 0
}

export const objectMerge = (obj, keyPath, value) => {
  let data = { ...obj }
  let arrayPath = keyPath
    .split('.')
    .map((key) => (isInteger(key) ? parseInt(key) : key))

  return updateObjectValueByPath({ arrayPath, value, data })
}

export const getValueByPath = (obj, pathString) => {
  if (!obj) {
    return undefined
  }
  for (
    var i = 0, path = pathString.split('.'), len = path.length;
    i < len;
    i++
  ) {
    obj = obj ? obj[path[i]] : undefined
  }
  return obj
}

export const findLinksInJson = (data) => {
  if (!data) {
    return []
  }
  const contentString = JSON.stringify(data).replaceAll('\\', '')
  const links = []
  const regexp = new RegExp(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/,
    'gim',
  )
  let match
  while ((match = regexp.exec(contentString)) !== null) {
    if (!match[0].includes('gfc.target.com')) {
      links.push(match[0])
    }
  }
  return links
}
