import typo from './typo.json'
export default function _findFontSize(typoVariant, sizeVariant, device) {
  var fontsizeVal = {
    sizeVal: '',
  }
  typo.forEach((variant) => {
    if (variant.slug === typoVariant) {
      variant.fontSize.forEach((sizes) => {
        if (sizes.name === sizeVariant) {
          sizes.values.forEach((fontSize) => {
            if (fontSize.name === device) {
              fontsizeVal.sizeVal = fontSize.range.value
                ? fontSize.range.value
                : fontSize.range.default
            }
          })
        }
      })
    }
  })
  return fontsizeVal
}

export const statusColors = {
  primary: {
    main: '#263238',
  },
  secondary: {
    main: '#b71c1c',
  },
  draft: {
    main: '#9E9E9E',
  },
  pending: {
    main: '#ff9800',
    light: '#ffe0b2',
  },
  pending_approval: {
    main: '#FFAB40',
    light: '#ffe0b2',
  },
  approved: {
    main: '#80CBC4',
    light: '#dcedc8',
  },
  published: {
    main: '#008300',
    light: '#c8e6c9',
  },
  rejected: {
    main: '#f44336',
    light: '#ffcdd2',
  },
  unpublished: {
    main: '#F05E16',
    light: '#d7ccc8',
  },
  archived: {
    main: '#795548',
    light: '#d7ccc8',
  },
  scheduled_publish: {
    main: '#9AB431',
    light: '#c8e6c9',
  },
  unknown: {
    main: 'black',
    light: 'grey',
  },
}

export const primaryActionBtnStyle = {
  textTransform: 'none',
  backgroundColor: '#fff',
  border: '1px solid #CC0000',
  color: '#CC0000',
  ':hover': {
    backgroundColor: '#CC0000',
    color: '#fff',
  },
  ':focus': { border: '2px dashed #666666' },
  mr: 4,
}
