import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import {
  Box,
  Button,
  Card,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { ArrowBack, Download, Refresh } from '@mui/icons-material'
import { useGetParticipantReportsQuery } from '../../../../../api/participant'
import { grey } from '@mui/material/colors'
import ListLoader from '../../../../../components/ListLoader'
import _ from 'lodash'

const statusFlags = [
  {
    flag: 'ytr_registered_auto_approved',
    label: 'Registered and Auto Approved',
  },
  {
    flag: 'registered_hr_approved',
    label: 'Reviewed and Approved by HR',
  },
  {
    flag: 'app_logged_in',
    label: 'No of Mobile App Logins',
    skip: true,
  },
  {
    flag: 'pending_approval',
    label: 'Pending HR Approval',
  },
  {
    flag: 'rejected',
    label: 'Rejected Participants',
  },
  {
    flag: 'manual_entry',
    label: 'Directly added via Stencil',
  },
  {
    flag: 'on_hold',
    label: 'On Hold',
  },
  {
    flag: 'ytr_approved',
    label: 'Manually Approved Without Registration',
  },
  {
    flag: 'registered_auto_approved',
    label: 'Approved Participants who were not imported',
  },
  {
    flag: 'ytr_registered_manual_approved',
    label: 'Registered and Manually Approved',
  },
  {
    flag: 'blocked',
    label: 'Blocked Participants',
  },
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[900],
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const ParticipantReport = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { eventId = null } = params
  const [participantsReport, setParticipantReport] = useState(null)
  const [downloadableReport, setDownloadableReport] = useState(null)
  const [filters, setFilters] = useState({
    company: 'non-target',
    fresh: true,
  })
  const pathTrail = [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${eventId}#reports`,
      label: 'Reports',
    },
    {
      to: ``,
      label: 'Participant Reports',
    },
  ]

  const {
    data: participantsData,
    isFetching: participantsDataLoading,
    refetch: refetchReport,
  } = useGetParticipantReportsQuery(
    {
      eventid: eventId,
      activeSite,
      filters,
    },
    { skip: !eventId || !activeSite, refetchOnMountOrArgChange: true },
  )

  const getRecordInfo = (type, status, data, mobileLoginData) => {
    const records = []
    data.map((p) => {
      const participantData = {
        Email: p?.user?.email,
        Name: p?.user?.name,
        'Registered At': p?.registered_at ? p?.registered_at : '',
        'Approved At': p?.approved_at ? p?.approved_at : '',
        'Auto Approved?': p?.auto_approved ? 'Yes' : 'No',
        'Internal / External': type,
        Status: status?.label,
        'New Participant': [
          'registered_auto_approved',
          'ytr_registered_auto_approved',
        ].includes(status?.flag)
          ? 'Yes'
          : 'No',
        'Mobile App Login Detected':
          mobileLoginData && _.find(mobileLoginData, { member: p?.member })
            ? 'Yes'
            : 'No',
      }
      records.push(participantData)
      return 0
    })
    return records
  }

  useEffect(() => {
    setFilters(filters)
  }, [filters])

  useEffect(() => {
    const generateDownloadableReport = (data) => {
      let generated = []
      const internal = data?.internal
      const external = data?.external
      statusFlags.map((status) => {
        if (!status?.skip) {
          if (external?.[status.flag] && external?.[status.flag].length > 0) {
            const ex_records = getRecordInfo(
              'external',
              status,
              external?.[status.flag],
              external?.app_logged_in,
            )
            if (ex_records.length > 0) {
              generated = [...generated, ...ex_records]
            }
          }
          if (internal?.[status.flag] && internal?.[status.flag].length > 0) {
            const in_records = getRecordInfo(
              'internal',
              status,
              internal?.[status.flag],
              internal?.app_logged_in,
            )
            if (in_records.length > 0) {
              generated = [...generated, ...in_records]
            }
          }
        }
        return 0
      })
      setDownloadableReport(generated)
    }

    if (participantsData) {
      setParticipantReport(participantsData?.response)
      generateDownloadableReport(participantsData?.response)
    }
  }, [participantsData])

  const exportToCSV = () => {
    var csvRows = []
    const headers = Object.keys(downloadableReport[0])
    csvRows.push(headers.join(','))
    for (const row of downloadableReport) {
      const values = headers.map((header) => {
        const val = row[header]
        return `"${val}"`
      })
      csvRows.push(values.join(','))
    }
    csvRows = csvRows.join('\n')

    const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' })
    const a = document.createElement('a')
    a.download = 'participants.csv'
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }} data-testid="create-participant-wrapper">
        <Breadcrumbs trail={pathTrail} maxItems={4} />
        <Box sx={{ mt: 2, width: '100%', height: '87vh' }}>
          <Card>
            <Box
              sx={{
                background: '#E6E7EA',
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Participant Reports
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box>
                <Button
                  sx={{ textTransform: 'none' }}
                  variant="outlined"
                  startIcon={<Refresh />}
                  size="small"
                  onClick={refetchReport}
                >
                  Refetch
                </Button>
                <Button
                  disabled={
                    participantsDataLoading || !downloadableReport?.length > 0
                  }
                  variant="outlined"
                  sx={{ textTransform: 'none', ml: 1 }}
                  size="small"
                  startIcon={<Download />}
                  onClick={() => {
                    exportToCSV()
                  }}
                >
                  Download Report
                </Button>
                <Button
                  sx={{ textTransform: 'none', ml: 1 }}
                  variant="outlined"
                  size="small"
                  startIcon={<ArrowBack />}
                  onClick={() => navigate(-1)}
                >
                  Back to Event
                </Button>
              </Box>
            </Box>
            <Paper sx={{ p: 4 }}>
              <Box sx={{ mt: 1 }}>
                {participantsDataLoading ? (
                  <ListLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">
                            Status
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            External
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Internal
                          </StyledTableCell>
                          <StyledTableCell align="left">Total</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participantsReport &&
                          statusFlags.map((status) => {
                            const internalCount =
                              participantsReport?.internal?.[status.flag]
                            const externalCount =
                              participantsReport?.external?.[status.flag]
                            const total =
                              parseInt(internalCount) + parseInt(externalCount)
                            return (
                              <React.Fragment key={status.flag}>
                                <StyledTableRow>
                                  <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                    {status.label}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={externalCount ? externalCount : 0}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={internalCount ? internalCount : 0}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={total ? total : 0}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Paper>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export default ParticipantReport
