import React, { useEffect } from 'react'
import Header from './Header'
import Routes from './Routes'
import SideBar from './SideBar'
import { Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useGetProfileQuery, useGetRightsQuery } from '../api/profile'
import { toggleSideBar } from '../api/session'
import apiConfig from '../apiConfig'
import { useCreateNewUserMutation } from '../api/users'

export default function Layout(props) {
  const { toggleDarkMode, logout } = props
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.session.userInfo)
  const profile = useSelector((state) => state.session.profile)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const drawerOpened = useSelector((state) => state.session.sideBarOpen)

  const currentRoles =
    profile?.usermap && activeSite
      ? {
          roles: [
            ...profile.usermap
              .filter((map) => map.site === activeSite._id)
              .map((map) => map.role),
          ],
          siteId: activeSite._id,
        }
      : {}

  const { data: profileData, refetch: refetchProfile } = useGetProfileQuery(
    userInfo?.lanId,
    {
      skip: !userInfo.lanId,
    },
  )
  useGetRightsQuery(
    {
      ...currentRoles,
    },
    {
      skip: !(profile && profile.usermap && activeSite),
      refetchOnMountOrArgChange: true,
    },
  )
  const [createNewUser, { data: createdUser }] = useCreateNewUserMutation()

  const sideBarClose = () => {
    if (drawerOpened) {
      dispatch(toggleSideBar())
    }
  }

  useEffect(() => {
    if (profileData && profileData.length === 0 && apiConfig.isPOL) {
      const newVendorUser = {
        lanId: userInfo.lanId,
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        displayName: userInfo.fullName,
        isActive: true,
        cn: userInfo.lanId,
        title: 'Vendor',
      }
      createNewUser(newVendorUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData])

  useEffect(() => {
    if (createdUser) {
      refetchProfile()
    }
  }, [createdUser, refetchProfile])

  return (
    <React.Fragment>
      <Header toggleDarkMode={toggleDarkMode} logout={logout} />
      <main>
        <Grid container wrap="nowrap">
          <Grid
            item
            sx={{
              height: 'calc(100vh - 64px)',
              borderRight: '1px solid #d3d3d3',
            }}
          >
            <SideBar />
          </Grid>
          <Grid
            item
            xs="auto"
            onClick={sideBarClose}
            sx={{
              height: 'calc(100vh - 64px)',
              overflowY: 'scroll',
              marginLeft: drawerOpened ? 15 : 0,
              overflowX: 'hidden',
            }}
          >
            <Routes />
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  )
}
