import React from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Label } from '../../Common'
import { disableControlsBasedOnDevice } from '../../Helpers'

export const Stepper = (props) => {
  const {
    onChange,
    name = 'No of sections',
    max = 5,
    slug = '',
    disabled = false,
    helperText = '',
    device = 'desktop',
    defaultValue = 0,
    value = defaultValue ? defaultValue : 0,
    forDevices = [],
    noSlugChange = false,
  } = props

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )
  let incNum = (e, val) => {
    if (val < max) {
      onChange(
        parseInt(val + 1),
        noSlugChange
          ? slug
          : `${slug}${
              device && device !== 'desktop'
                ? device.charAt(0).toUpperCase() + device.slice(1)
                : ''
            }`,
      )
    }
  }
  let decNum = (e, val) => {
    if (val > 0) {
      onChange(
        parseInt(val - 1),
        noSlugChange
          ? slug
          : `${slug}${
              device && device !== 'desktop'
                ? device.charAt(0).toUpperCase() + device.slice(1)
                : ''
            }`,
      )
    }
  }
  let handleChange = (e) => {
    onChange(
      parseInt(e.target.value),
      noSlugChange
        ? slug
        : `${slug}${
            device && device !== 'desktop'
              ? device.charAt(0).toUpperCase() + device.slice(1)
              : ''
          }`,
    )
  }
  return (
    <Box data-testid="stepper-wrapper" sx={{ px: '4px', py: '4px' }}>
      <Label
        name={`${name} (Max ${max})`}
        disabled={disableControlsBasedOnDevice(device, forDevices, disabled)}
        helperText={helperText}
      />
      <Box sx={{ display: 'flex' }}>
        <Box>
          <Button
            size={'small'}
            data-testid="button-decrement"
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.38)',
              borderRadius: '4px 0px 0px 4px',
              padding: '7px',
              minWidth: '40px',
              height: '60%',
            }}
            onClick={(e) => decNum(e, value)}
            disabled={disabledOrNot}
          >
            -1
          </Button>
        </Box>
        <TextField
          type="number"
          variant="outlined"
          data-testid="stepper-value"
          InputProps={{
            inputProps: {
              max: max,
              min: 0,
            },
          }}
          value={value ? value : defaultValue}
          onChange={(e) => handleChange(e)}
          disabled={disabledOrNot}
          sx={{
            width: '20%',

            '& .MuiOutlinedInput-root': {
              height: '60%',
              borderRadius: 0,
              '& .MuiOutlinedInput-input': {
                textAlign: 'right',
              },
            },
          }}
        />

        <Box>
          <Button
            size={'small'}
            data-testid="button-increment"
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.38)',
              borderRadius: '0px 4px 4px 0px',
              padding: '7px',
              minWidth: '40px',
              height: '60%',
            }}
            onClick={(e) => incNum(e, value)}
            disabled={disabledOrNot}
          >
            +1
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Stepper
