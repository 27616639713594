import React, { useState, useEffect } from 'react'
import {
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Button,
  Tooltip,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  TextField,
  InputAdornment,
  Link,
  Card,
  CardContent,
} from '@mui/material'
import withRights from '../../components/withRights'
import AccessDenied from '../../Layout/AccessDenied'
import ManageDataGrid from '../../components/ManageDataGrid'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate, useLocation } from 'react-router-dom'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import Loader from '../../components/Loader'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { useGetPostsQuery } from '../../api/post'
import { useSelector } from 'react-redux'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  getGridStringOperators,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridOverlay,
} from '@mui/x-data-grid'
import PreviewFullscreen from './PostEditor/FullScreenPreview/PreviewPost'
import {
  useSendForApprovalMutation,
  useAddCommentMutation,
  useGeneratePreviewMutation,
  useNewPostMutation,
} from '../../api/post'
import { useGetConfigurationBySlugQuery } from '../../api/configuration'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import DialogConfirmation from '../../components/DialogConfirmation'
import ModeIcon from '@mui/icons-material/Mode'
import SummarizeIcon from '@mui/icons-material/Summarize'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { findLinksInJson } from '../../utils/dataParser'
import GppGoodIcon from '@mui/icons-material/GppGood'
import InfoIcon from '@mui/icons-material/Info'
import LinkIcon from '@mui/icons-material/Link'
import SplitButton from '../../components/SplitButton'
import { useSnackbar } from 'notistack'
import SearchIcon from '@mui/icons-material/Search'
import { useGetUsedPostMediaDataQuery } from '../../api/page'
import { getSize } from '../../utils/helper'

import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import EditIcon from '../../assets/Image/edit.svg'
import { STATUS_TEXT } from '../../utils/constant'

export const LoadActionButtons = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedRows = [], actionsAllowed = {} } = props
  const [openFullScreenPreview, setFullscreenPreview] = useState(false)
  const [selectedLayoutForPreview, setSelectedLayoutForPreview] = useState([])
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const {
    userCanCreatePost = false,
    userCanApprove = false,
    userCanPreviewPost = false,
    userCanPublish = false,
    userCanEditPost = false,
  } = actionsAllowed
  const rowsSelected = selectedRows.length > 0 ? true : false
  const [selectedAction, setSelectedAction] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [siteData, setSiteData] = useState({})
  const [addCommentToPost] = useAddCommentMutation()

  const [changingPostStatus, { isLoading: statusChangeInProgress }] =
    useSendForApprovalMutation()

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'site',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [
    generatePreview,
    {
      data: previewData,
      isSuccess: previewGenerated,
      isLoading: previewGenerationInProgress,
    },
  ] = useGeneratePreviewMutation()

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'site') {
          setSiteData(conf.value ? conf.value : {})
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  const tooltipMsgs = {
    create: 'Click to create a new post',
    preview: 'Click to preview the post',
    revert: 'Click to revoke the approval',
    approve: 'Click to approve the post',
    reject: 'Click to reject the post',
    publish: 'Click to take post live',
    unpublish: 'Click to unpublish selected post(s)',
    wip: 'Click to change the status to work in progress',
  }

  const previewThePost = () => {
    if (selectedRows.length === 1) {
      setSelectedLayoutForPreview(
        selectedRows[0].layout ? selectedRows[0].layout : [],
      )
      setFullscreenPreview(true)
    }
  }

  const selectedPostsAssignedToMe = (postsList, lanId) => {
    let assignedToMe = true
    if (postsList && postsList.length > 0) {
      postsList.map((post) => {
        if (
          post.assignedto &&
          post.assignedto[0] &&
          post.assignedto[0].lanId &&
          post.assignedto[0].lanId.toLowerCase() !== lanId.toLowerCase()
        ) {
          assignedToMe = false
        }
        return true
      })
    }
    return assignedToMe
  }

  const selectedPostsAssignedByMe = (postsList, lanId) => {
    let assignedByMe = true
    if (postsList && postsList.length > 0) {
      postsList.map((post) => {
        if (
          post.assignedby &&
          post.assignedby[0] &&
          post.assignedby[0].lanId &&
          post.assignedby[0].lanId.toLowerCase() !== lanId.toLowerCase()
        ) {
          assignedByMe = false
        }
        return true
      })
    }
    return assignedByMe
  }

  const SelectedItems = () => {
    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (_, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    }

    const { data: savedMediaList } = useGetUsedPostMediaDataQuery(
      selectedRows,
      {
        refetchOnMountOrArgChange: true,
        skip: !activeSite || !selectedRows,
      },
    )

    const MediaListBulider = (props) => {
      const { input } = props
      let data = input ? [...input.data] : []
      let sortedMedia = data.sort((e1, e2) =>
        e1.size < e2.size ? 1 : e1.size > e2.size ? -1 : 0,
      )
      return (
        <List>
          {sortedMedia?.map((e, key) => {
            return (
              <ListItemButton
                key={key}
                sx={() => ({
                  border: '1px solid #d3d3d3',
                  pt: 0,
                  pb: 0,
                })}
                disableRipple
              >
                <ListItemText
                  primary={e?.name}
                  primaryTypographyProps={{
                    style: {
                      fontSize: 16,
                      width: '80%',
                    },
                  }}
                />
                <Typography variant="body2">{getSize(e?.size)}</Typography>
              </ListItemButton>
            )
          })}
        </List>
      )
    }

    return (
      <>
        <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
          Selected posts
        </Typography>
        <List>
          {selectedRows &&
            selectedRows.map((selected, key) => {
              const links = findLinksInJson(selected && selected.layout)
              let mediaUsed = savedMediaList
                ? savedMediaList.filter(
                    (item) => selected._id === item?.content,
                  )
                : []
              mediaUsed = mediaUsed[0] ?? undefined
              return (
                <React.Fragment key={key}>
                  <Card sx={{ mb: 1 }}>
                    <CardContent sx={{ padding: '10px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          margin: '10px 0px 10px 0px',
                        }}
                      >
                        <SummarizeIcon sx={{ mr: 1 }} />
                        <Typography
                          sx={{
                            width: '33%',
                            flexShrink: 0,
                            fontWeight: 'bold',
                          }}
                        >
                          {selected?.title}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {selected.assignedby && selected.assignedby[0]
                            ? `Requested by : ${selected.assignedby[0].displayName}`
                            : ''}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                      </Box>
                      {(savedMediaList && savedMediaList?.length > 0) ||
                      links.length !== 0 ? (
                        <>
                          {links.length !== 0 ? (
                            <>
                              <Accordion
                                expanded={expanded === selected._id}
                                onChange={handleChange(selected._id)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <div style={{ width: '100%' }}>
                                    <Box
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        mt: 1,
                                      }}
                                    >
                                      {selected.status.status ===
                                        'pending_approval' && (
                                        <>
                                          <InfoIcon
                                            color="warning"
                                            sx={{ mr: 1, fontSize: 20 }}
                                          />
                                          <Typography variant="caption">
                                            {
                                              'There are some external links found in the post. Please validate before approval'
                                            }
                                          </Typography>
                                        </>
                                      )}
                                    </Box>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography sx={{ fontWeight: 'bold' }}>
                                    External links :{' '}
                                  </Typography>
                                  <List>
                                    {links.map((link, key) => {
                                      return (
                                        <ListItemButton
                                          key={key}
                                          sx={() => ({
                                            border: '1px solid #d3d3d3',
                                            pt: 0,
                                            pb: 0,
                                          })}
                                          onClick={() => window.open(link)}
                                        >
                                          <ListItemIcon>
                                            <LinkIcon />
                                          </ListItemIcon>
                                          <ListItemText primary={link} />
                                        </ListItemButton>
                                      )
                                    })}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : (
                            selected.status.status === 'pending_approval' && (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: 1,
                                  ml: 2,
                                }}
                              >
                                <>
                                  <GppGoodIcon
                                    color="success"
                                    sx={{ mr: 1, fontSize: 20 }}
                                  />
                                  <Typography variant="caption">
                                    {
                                      'No external links found in this post. Its safe to publish'
                                    }
                                  </Typography>
                                </>
                              </Box>
                            )
                          )}

                          {mediaUsed?.data?.length > 0 ? (
                            <Accordion
                              expanded={expanded === `media-${selected._id}`}
                              onChange={handleChange(`media-${selected._id}`)}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div style={{ width: '100%' }}>
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      mt: 1,
                                    }}
                                  >
                                    <>
                                      {mediaUsed?.data?.reduce(
                                        (acc, item) => acc + item.size,
                                        0,
                                      ) >= 1000000 ? (
                                        <InfoIcon
                                          color="warning"
                                          sx={{ mr: 1, fontSize: 20 }}
                                        />
                                      ) : (
                                        <GppGoodIcon
                                          color="success"
                                          sx={{ mr: 1, fontSize: 20 }}
                                        />
                                      )}
                                      {
                                        <Typography variant="caption">
                                          {`Total page size - ${getSize(
                                            mediaUsed?.data?.reduce(
                                              (acc, item) => acc + item.size,
                                              0,
                                            ),
                                          )}`}
                                        </Typography>
                                      }
                                    </>
                                  </Box>
                                </div>
                              </AccordionSummary>
                              {
                                <AccordionDetails>
                                  <>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                      Media List :{' '}
                                    </Typography>
                                    <MediaListBulider input={mediaUsed} />
                                  </>
                                </AccordionDetails>
                              }
                            </Accordion>
                          ) : (
                            <div style={{ width: '100%' }}>
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  mt: 1,
                                  mb: 1,
                                  ml: 2,
                                }}
                              >
                                {selected.status.status ===
                                  'pending_approval' && (
                                  <>
                                    <GppGoodIcon
                                      color="success"
                                      sx={{ mr: 1, fontSize: 20 }}
                                    />

                                    <Typography variant="caption">
                                      {`This post has not any media `}
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  </Card>
                </React.Fragment>
              )
            })}
        </List>
      </>
    )
  }

  const approveAllPosts = (comment) => {
    selectedRows.map((post) => {
      addCommentToPost({
        id: post._id,
        comment:
          comment + '. Post status changed into <strong>Approved</strong>',
      })
      const payload = {
        id: post._id,
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'approved',
      }
      return changingPostStatus(payload)
    })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const rejectAllPosts = (comment) => {
    selectedRows.map((post) => {
      addCommentToPost({
        id: post._id,
        comment:
          comment + '. Post status changed into <strong>Rejected</strong>',
      })
      const payload = {
        id: post._id,
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'rejected',
      }
      return changingPostStatus(payload)
    })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const revertApprovalOrRejection = (comment) => {
    selectedRows.map((post) => {
      addCommentToPost({
        id: post._id,
        comment:
          comment + '. Post status changed into <strong>Pending</strong>',
      })
      const payload = {
        id: post._id,
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'pending_approval',
      }
      return changingPostStatus(payload)
    })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const publishThePost = (comment) => {
    selectedRows.map((post) => {
      addCommentToPost({
        id: post._id,
        comment:
          comment + '. Post status changed into <strong>Published</strong>',
      })
      const payload = {
        id: post._id,
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'published',
      }
      return changingPostStatus(payload)
    })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const unPublishThePost = (comment) => {
    selectedRows.map((post) => {
      addCommentToPost({
        id: post._id,
        comment:
          comment + '. Post status changed into <strong>Un published</strong>',
      })
      const payload = {
        id: post._id,
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'unpublished',
      }
      return changingPostStatus(payload)
    })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const changeWIP = () => {
    selectedRows.map((post) => {
      const payload = {
        id: post._id,
        assignedto: userInfo.lanId,
        site: activeSite._id,
        status: 'draft',
      }
      return changingPostStatus(payload)
    })
    setSelectedAction('')
    setDialogOpen(false)
  }

  const actionsList = {
    approve: {
      cb: approveAllPosts,
      secondCb: rejectAllPosts,
      rejection: true,
      actionButtonLabel: 'Approve',
      title: 'Are you sure want to approve the changes ?',
      commentEnabled: true,
    },
    reject: {
      cb: rejectAllPosts,
      actionButtonLabel: 'Reject',
      title: 'Are you sure want to reject the selected posts ?',
      commentEnabled: true,
    },
    revertApproval: {
      cb: revertApprovalOrRejection,
      actionButtonLabel: 'Revert',
      title:
        'Are you sure want to revert back the approvals from the selected posts ?',
      commentEnabled: true,
    },
    revertRejection: {
      cb: revertApprovalOrRejection,
      actionButtonLabel: 'Revert',
      title:
        'Are you sure want to revert back the rejections from the selected posts ?',
      commentEnabled: true,
    },
    publish: {
      cb: publishThePost,
      actionButtonLabel: 'Publish',
      title: 'Are you sure want to publish the selected posts ?',
      commentEnabled: true,
    },
    unpublish: {
      cb: unPublishThePost,
      actionButtonLabel: 'Unpublish',
      title: 'Are you sure want to unpublish the selected posts ?',
      commentEnabled: true,
    },
    wip: {
      cb: changeWIP,
      actionButtonLabel: 'Change to WIP',
      title: 'Are you sure want to change the post to work in progress ?',
    },
  }

  const initiateAction = (action) => {
    setSelectedAction(action)
    setDialogOpen(true)
  }

  const failSafe = () => {}

  const onSitePreview = () => {
    if (siteData && siteData.siteUrl && previewGenerated) {
      window.open(
        `${siteData.siteUrl}${
          selectedRows[0].slug ? selectedRows[0].slug : ''
        }?preview=${previewData ? previewData.token : ''}`,
      )
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {rowsSelected && (
          <>
            {selectedRows.length === 1 && (
              <>
                <Box sx={{ mr: 1 }}>
                  <SplitButton
                    disabled={!userCanPreviewPost}
                    options={[
                      { label: 'Preview on Stencil', value: 'previewInCMS' },
                      {
                        label: 'Preview on Site',
                        value: 'previewOnSite',
                        onSelectCb: true,
                        cb: () => {
                          if (
                            (!previewGenerated ||
                              !previewData ||
                              previewData.post !== selectedRows[0]._id) &&
                            !previewGenerationInProgress
                          ) {
                            generatePreview({ id: selectedRows[0]._id })
                          }
                        },
                      },
                    ]}
                    onClick={(val) => {
                      if (val.value === 'previewInCMS') {
                        previewThePost()
                      }
                      if (val.value === 'previewOnSite') {
                        onSitePreview()
                      }
                    }}
                  />
                </Box>
              </>
            )}
            {selectedPostsAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'pending_approval' && (
                  <Tooltip title={tooltipMsgs.approve}>
                    <Button
                      variant={'outlined'}
                      color="primary"
                      onClick={() => initiateAction('approve')}
                      startIcon={<DoneAllIcon />}
                      disabled={!userCanApprove || statusChangeInProgress}
                      sx={{
                        textTransform: 'none',
                        mr: 1,
                        border: '1px solid',
                        padding: '4px 10px',
                        ':hover': { borderStyle: 'dashed' },
                        ':focus': { borderStyle: 'dashed' },
                      }}
                      size="small"
                    >
                      Approve {selectedRows.length > 1 ? 'all' : ''}
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPostsAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'pending_approval' && (
                  <Tooltip title={tooltipMsgs.reject}>
                    <Button
                      variant={'outlined'}
                      color="secondary"
                      onClick={() => initiateAction('reject')}
                      startIcon={<DoNotDisturbOnIcon />}
                      disabled={!userCanApprove || statusChangeInProgress}
                      sx={{
                        textTransform: 'none',
                        mr: 1,
                        border: '1px solid',
                        padding: '4px 10px',
                        ':hover': { borderStyle: 'dashed' },
                        ':focus': { borderStyle: 'dashed' },
                      }}
                      size="small"
                    >
                      Reject {selectedRows.length > 1 ? 'all' : ''}
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPostsAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {(selectedRows[0].status.status === 'approved' ||
                  selectedRows[0].status.status === 'unpublished') && (
                  <Tooltip title={tooltipMsgs.publish}>
                    <span>
                      <Button
                        variant={'contained'}
                        color="primary"
                        onClick={() => initiateAction('publish')}
                        startIcon={<PublicIcon />}
                        disabled={!userCanApprove || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid #263238',
                          padding: '4px 10px',
                          ':hover': {
                            borderStyle: 'dashed',
                            borderColor: '#fff',
                          },
                          ':focus': {
                            borderStyle: 'dashed',
                            borderColor: '#fff',
                          },
                        }}
                        size="small"
                      >
                        Publish post{selectedRows.length > 1 ? 's' : ''}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            <>
              {selectedRows[0].status.status === 'published' && (
                <Tooltip title={tooltipMsgs.unpublish}>
                  <span>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      onClick={() => initiateAction('unpublish')}
                      startIcon={<PublicOffIcon />}
                      disabled={!userCanPublish || statusChangeInProgress}
                      sx={{
                        textTransform: 'none',
                        mr: 1,
                        border: '1px solid #b71c1c',
                        padding: '4px 10px',
                      }}
                      size="small"
                    >
                      Unpublish Post{selectedRows.length > 1 ? 's' : ''}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </>
            {selectedPostsAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'approved' && (
                  <Tooltip title={tooltipMsgs.revert}>
                    <span>
                      <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => initiateAction('revertApproval')}
                        startIcon={<SettingsBackupRestoreIcon />}
                        disabled={!userCanApprove || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid',
                          padding: '4px 10px',
                          ':hover': { borderStyle: 'dashed' },
                          ':focus': { borderStyle: 'dashed' },
                        }}
                        size="small"
                      >
                        Revert approval{selectedRows.length > 1 ? 's' : ''}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPostsAssignedToMe(selectedRows, userInfo.lanId) && (
              <>
                {selectedRows[0].status.status === 'rejected' && (
                  <Tooltip title={tooltipMsgs.revert}>
                    <span>
                      <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => initiateAction('revertRejection')}
                        startIcon={<SettingsBackupRestoreIcon />}
                        disabled={!userCanApprove || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid',
                          padding: '4px 10px',
                          ':hover': { borderStyle: 'dashed' },
                          ':focus': { borderStyle: 'dashed' },
                        }}
                        size="small"
                      >
                        Revert rejection{selectedRows.length > 1 ? 's' : ''}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {selectedPostsAssignedByMe(selectedRows, userInfo.lanId) && (
              <>
                {(selectedRows[0].status.status === 'rejected' ||
                  selectedRows[0].status.status === 'pending_approval') && (
                  <Tooltip title={tooltipMsgs.wip}>
                    <span>
                      <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => initiateAction('wip')}
                        startIcon={<ModeIcon />}
                        disabled={!userCanEditPost || statusChangeInProgress}
                        sx={{
                          textTransform: 'none',
                          mr: 1,
                          border: '1px solid',
                          padding: '4px 10px',
                          ':hover': { borderStyle: 'dashed' },
                          ':focus': { borderStyle: 'dashed' },
                        }}
                        size="small"
                      >
                        Change to WIP
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
        {location.pathname !== '/' ? (
          <Tooltip title={tooltipMsgs.create}>
            <span>
              <Button
                variant={'contained'}
                color="primary"
                onClick={() => navigate('/posts/create')}
                startIcon={<AddIcon />}
                disabled={!userCanCreatePost}
                disableRipple
                disableFocusRipple
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#fff',
                  border: '1px solid #CC0000',
                  color: '#CC0000',
                  ':hover': {
                    backgroundColor: '#CC0000',
                    color: '#fff',
                  },
                  ':focus': { border: '2px dashed #666666' },
                }}
                size="small"
              >
                Create Post
              </Button>
            </span>
          </Tooltip>
        ) : (
          ''
        )}
      </Box>
      <PreviewFullscreen
        open={openFullScreenPreview}
        handleClose={() => setFullscreenPreview(false)}
        layout={selectedLayoutForPreview}
      />
      <DialogConfirmation
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={selectedAction ? actionsList[selectedAction].title : ''}
        onConfirm={selectedAction ? actionsList[selectedAction].cb : failSafe}
        onReject={
          selectedAction && actionsList[selectedAction].rejection
            ? actionsList[selectedAction].secondCb
            : failSafe
        }
        rejection={selectedAction && actionsList[selectedAction].rejection}
        actionButtonLabel={
          selectedAction ? actionsList[selectedAction].actionButtonLabel : null
        }
        commentEnabled={
          actionsList[selectedAction] &&
          actionsList[selectedAction].commentEnabled
            ? true
            : false
        }
      >
        <SelectedItems />
      </DialogConfirmation>
    </>
  )
}

const Posts = (props) => {
  const {
    rights,
    rightsLoading,
    inDialog = false,
    dialogProps = {},
    noSiteAccess = false,
  } = props
  const { title = '', status = '', assigned_to_me = false } = dialogProps
  const { moduleAccess, permittedActions, superRights } = rights
  const [actionOpened, setActionOpened] = useState()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = React.useState([])
  const [postsList, setPostsList] = useState([])
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchFilter, setSearchFilter] = React.useState({})
  const [cloneDialogOpen, setCloneDialogOpen] = React.useState(false)
  const [selectedPostForClone, setSelectedPostForClone] = React.useState(null)
  const [siteData, setSiteData] = useState({})
  const [searchBarFocused, setSearchBarFocused] = React.useState(false)
  const [pageCategory, setPageCategory] = useState('')

  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 10,
  })

  const { enqueueSnackbar } = useSnackbar()

  const [
    cloneFromAnotherPost,
    {
      isError: cloneFailed,
      isLoading: cloneInProgress,
      data: newlyCreatedPost,
    },
  ] = useNewPostMutation()

  const {
    data: postsData,
    isLoading: postListLoading,
    isFetching: refetchIsInprogress,
  } = useGetPostsQuery(
    {
      userInfo,
      activeSite,
      pageNo: rowsState.page + 1,
      filter: searchFilter,
      status: inDialog ? status : pageCategory,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  const clonePost = (params) => {
    setSelectedPostForClone(params)
    setCloneDialogOpen(true)
  }

  const confirmClonePost = () => {
    const {
      layout = [],
      category,
      tags = [],
      title = '',
      feature_data = {},
      author = {},
      slug = '',
    } = selectedPostForClone
    const newPost = {
      title: `${title} copy`,
      status: 'draft',
      slug: `${slug}-copy`,
      feature_data,
      category: category ? category._id : null,
      tags: tags && tags.length > 0 ? tags.map((tag) => tag._id) : [],
      author,
      layout,
    }
    cloneFromAnotherPost({ post: newPost, activeSite })
  }

  const rejectClonePost = () => {
    setCloneDialogOpen(false)
    setSelectedPostForClone(null)
  }

  useEffect(() => {
    if (status) {
      setSearchFilter({ status })
    }
  }, [status])

  useEffect(() => {
    if (assigned_to_me) {
      setSearchFilter({ assigned_to_me })
    }
  }, [assigned_to_me])

  useEffect(() => {
    if (postsData && postsData.posts) {
      setPostsList([...postsData.posts])
      setIsLoading(false)
    }
  }, [postsData])

  useEffect(() => {
    if (newlyCreatedPost) {
      setSelectedPostForClone(null)
      setCloneDialogOpen(false)
      const action = (snackbarId) => (
        <>
          <button
            onClick={() => {
              navigate(`/posts/edit/${newlyCreatedPost.id}`)
            }}
          >
            Edit
          </button>
        </>
      )
      enqueueSnackbar('Your cloned post is ready!. Want to edit ?', {
        action,
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyCreatedPost])

  useEffect(() => {
    if (cloneFailed) {
      enqueueSnackbar(
        'Problem occurred while cloning this post! Check for any recently cloned post',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloneFailed])

  const { data: siteConfig, isLoading: siteConfigLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'site',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'site') {
          setSiteData(conf.value ? conf.value : {})
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = siteConfig
      settings.map((conf) => {
        if (conf.name === 'site') {
          setSiteData(conf.value ? conf.value : {})
        }
        return true
      })
    }
    if (!siteConfigLoading && siteConfig) {
      updateMenuData(siteConfig)
    }
  }, [siteConfig, siteConfigLoading])

  const actionsAllowed = {
    userCanCreatePost: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
    userCanEditPost: checkUserIsAllowed('edit', permittedActions, superRights),
    userCanPreviewPost: checkUserIsAllowed(
      'preview',
      permittedActions,
      superRights,
    ),
    userCanPublish: checkUserIsAllowed(
      'publish',
      permittedActions,
      superRights,
    ),
    userCanApprove: checkUserIsAllowed(
      'approve',
      permittedActions,
      superRights,
    ),
    userCanReview: checkUserIsAllowed('review', permittedActions, superRights),
  }

  const getStatusInfo = (status, type = 'label') => {
    return STATUS_TEXT[status]
      ? STATUS_TEXT[status][type]
      : type === 'label'
      ? status.replace('_', ' ')
      : 'primary'
  }

  function CustomToolbar() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 10px',
          backgroundColor: '#F6F7F8',
        }}
      >
        <div
          style={{ display: inDialog ? 'none' : 'flex', alignItems: 'center' }}
        >
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                '& .MuiSelect-select': {
                  display: 'inline !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  ':hover': { border: '1px dashed black' },
                  ':focus': { border: '1px dashed black' },
                },
                fontWeight: 'bolder',

                border: 'none !important',
                '& #demo-simple-select': {
                  paddingRight: '20px !important',
                },
              }}
              value={pageCategory}
              onChange={(e) => {
                console.log(e.target)
                setPageCategory(e.target.value)
              }}
              displayEmpty
            >
              {Object.values(STATUS_TEXT).map((status) => (
                <MenuItem value={status.value}>{status.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <span
            style={{
              color: '#3D70D6',
              fontSize: '16px',
              backgroundColor: '#E6E6E6',
              width: '59px',
              height: '36px',
              borderRadius: '36px',
              display: 'inline-block',
              textAlign: 'center',
              verticalAlign: 'middle',
              padding: '5px 9px',
              fontWeight: 'bolder',
            }}
          >
            {postsData && postsData.total}
          </span>
        </div>
        {inDialog && <Box></Box>}
        <GridToolbarContainer
          sx={{
            '& button': {
              fontSize: 0,
            },
          }}
        >
          <Tooltip title="Use this to filter out the columns available">
            <GridToolbarColumnsButton
              startIcon={<CalendarViewMonthIcon />}
              sx={{
                color: '#CC0000',
                border: '2px solid #DEDEDE',
                borderRadius: '4px',
                minWidth: '36px',
                width: '36px',
                height: '36px !important',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '4px 9px !important',
              }}
            />
          </Tooltip>
          <Tooltip title="Use this to edit the height of each row on this list">
            <GridToolbarDensitySelector
              sx={{
                color: '#CC0000',
                border: '2px solid #DEDEDE',
                borderRadius: '4px',
                minWidth: '36px',
                width: '36px',
                height: '36px !important',
                margin: ' 0 4px',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '4px 9px !important',
              }}
              startIcon={<ViewColumnIcon />}
            />
          </Tooltip>
          <GridToolbarFilterButton
            startIcon={<FilterAltIcon />}
            sx={{
              color: '#CC0000',
              border: '2px solid #DEDEDE',
              borderRadius: '4px',
              minWidth: '36px',
              width: '36px',
              height: '36px !important',
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '0px 6px !important',
            }}
          />
        </GridToolbarContainer>
      </Box>
    )
  }

  function CustomPagination() {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
      <Box sx={{ display: 'flex' }}>
        <Pagination
          count={pageCount}
          page={page + 1}
          onChange={(_event, value) => apiRef.current.setPage(value - 1)}
          showFirstButton
          showLastButton
          color="primary"
          boundaryCount={2}
        />
      </Box>
    )
  }

  function NoRowComponent() {
    return (
      <GridOverlay
        style={{
          zIndex: 1,
          pointerEvents: 'all',
          mt: '0px !important',
        }}
      >
        <Box>
          <Typography sx={{ color: 'black' }}>No Rows Found</Typography>
        </Box>
      </GridOverlay>
    )
  }

  const customFilters = getGridStringOperators().filter(
    (operator) =>
      operator.value === 'contains' ||
      operator.value === 'equals' ||
      operator.value === 'startsWith' ||
      operator.value === 'endsWith',
  )
  // in order to make column as non-filterable should use this following params **filterable: false**
  const columns = [
    {
      field: 'title',
      headerName: 'Post Title',
      width: 300,
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              color: '#333333',
              fontSize: 14,
              fontFamily: 'Helvetica for Target',
              fontWeight: '400',
              wordWrap: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.value}
          </Typography>
        )
      },
    },
    {
      field: 'slug',
      headerName: 'URL',
      headerAlign: 'left',
      width: 250,
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params) => {
        return (
          <Link
            sx={(theme) => ({
              color:
                theme.palette[getStatusInfo(params.value.status, 'color')].main,
              fontWeight: 'bold',
              cursor: 'pointer',
            })}
            target="__blank"
            src={`${siteData.siteUrl}${params.value}`}
            onClick={() => window.open(`${siteData.siteUrl}${params.value}`)}
          >
            <Typography
              sx={{
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {params.value}
            </Typography>
          </Link>
        )
      },
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      headerAlign: 'left',
      width: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box flexDirection={'column'}>
            <Typography
              sx={{
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {params.value.displayName}
            </Typography>
            <Typography
              sx={{
                color: '#676B74',
                fontSize: 12,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {new Date(params.row.createdAt).toLocaleString()}
            </Typography>
          </Box>
        )
      },
      filterOperators: customFilters,
    },
    {
      field: 'updated_by',
      headerName: 'Modified By',
      headerAlign: 'left',
      width: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box flexDirection={'column'}>
            <Typography
              sx={{
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {params.value.displayName}
            </Typography>
            <Typography
              sx={{
                color: '#676B74',
                fontSize: 12,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {new Date(params.row.updatedAt).toLocaleString()}
            </Typography>
          </Box>
        )
      },
      filterOperators: customFilters,
    },
    {
      field: 'assignedto',
      headerName: 'Assigned To',
      headerAlign: 'left',
      width: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box flexDirection={'column'}>
            <Typography
              sx={{
                color: '#333333',
                fontSize: 14,
                fontFamily: 'Helvetica for Target',
                fontWeight: '400',
                wordWrap: 'break-word',
              }}
            >
              {params.value.length > 0 ? params.value[0].displayName : 'NA'}
            </Typography>
          </Box>
        )
      },
      filterOperators: customFilters,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      type: 'singleSelect',
      width: 200,
      flex: 1,
      align: 'left',
      filterOperators: customFilters,
      renderCell: (params) => {
        return (
          <Box
            sx={(theme) => ({
              color:
                theme.palette[getStatusInfo(params.value.status, 'color')].main,
              fontWeight: 'bold',
            })}
          >
            <Button
              sx={(theme) => ({
                backgroundColor: `${getStatusInfo(
                  params.value.status,
                  'bgcolor',
                )}`,
                textTransform: 'none',
                color:
                  theme.palette[getStatusInfo(params.value.status, 'color')]
                    .main,
                fontWeight: 'bold',
                display: 'flex',
                minWidth: 100,
                height: 35,
              })}
            >
              {getStatusInfo(params.value.status)}
            </Button>
          </Box>
        )
      },
    },
    {
      field: '_id',
      headerName: 'Actions',
      headerAlign: 'left',
      type: 'actions',
      width: 200,
      flex: 1,
      align: 'left',
      filterOperators: customFilters,
      renderCell: (params) => {
        return (
          <Box key={params.id} sx={{ margin: '0px -5px' }}>
            <Tooltip title="Edit Post Layout" placement="left">
              <span>
                <IconButton
                  size="small"
                  disabled={
                    !actionsAllowed.userCanEditPost &&
                    !actionsAllowed.userCanReview
                  }
                  onClick={(event) => {
                    navigate(`/posts/design/${params.id}`)
                  }}
                  sx={{ width: '30px', height: '30px' }}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={'More Options'} placement="left">
              <IconButton
                size="small"
                aria-haspopup="true"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget)
                  setActionOpened(actionOpened === params.id ? null : params.id)
                }}
              >
                <MoreVertIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
            <Menu
              key={`edit-menu-${params.id}`}
              open={actionOpened && actionOpened === params.id ? true : false}
              onClose={() => {
                setActionOpened(null)
                setAnchorEl(null)
              }}
              id={params.id}
              anchorEl={anchorEl}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                key={`edit-${params.id}`}
                onClick={() => navigate(`/posts/edit/${params.id}`)}
                disabled={!actionsAllowed.userCanEditPost}
              >
                Edit Post
              </MenuItem>
              <MenuItem
                key={`clone-${params.id}`}
                onClick={() => clonePost(params.row)}
                disabled={!actionsAllowed.userCanEditPost}
              >
                Clone Post
              </MenuItem>
            </Menu>
          </Box>
        )
      },
    },
  ]
  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: 'title',
        operatorValue: 'contains',
        value: '',
      },
    ],
  })

  return (
    <Box sx={{ width: inDialog ? '100%' : 'calc(100vw - 58px)' }}>
      {(rightsLoading && !noSiteAccess) || postListLoading ? (
        <Loader />
      ) : moduleAccess ? (
        <>
          <Box sx={{ display: 'flex', background: '#FFF', px: 3, pt: '15px' }}>
            <Box>
              <Typography
                variant={'h5'}
                component={'h3'}
                sx={{
                  fontFamily: 'Helvetica For Target',
                  fontWeight: 'Bold',
                  marginBottom: 1,
                  color: '#3d3d3d',
                  mr: 2,
                }}
              >
                {inDialog ? title : 'Posts'}
              </Typography>
            </Box>
            <TextField
              value={searchFilter?.search_term ?? ''}
              sx={{
                ml: 2,
                justifyContent: 'center',
                width:
                  searchBarFocused || searchFilter?.search_term?.length > 0
                    ? '30vw'
                    : '25vw',
              }}
              placeholder="Search…"
              variant="outlined"
              size="small"
              inputProps={{ 'aria-label': 'search' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchFilter({
                  ...searchFilter,
                  search_term: e.target.value.replace(/^[ \t]+$/gm, ''),
                })
              }}
              onFocus={() => {
                setSearchBarFocused(true)
              }}
              onBlur={() => {
                setSearchBarFocused(false)
              }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box>
              <LoadActionButtons
                {...{
                  permittedActions,
                  superRights,
                  selectedRows,
                  actionsAllowed,
                }}
              />
            </Box>
          </Box>

          <Box sx={{ p: 2 }}>
            {refetchIsInprogress && <LinearProgress color="inherit" />}
            <ManageDataGrid
              getRowId={(row) => {
                return row._id
              }}
              sx={{ borderRadius: 0, mt: 1, height: '80vh' }}
              rowCount={postsData && postsData.total}
              loading={isLoading}
              rowsPerPageOptions={[5]}
              pagination
              {...rowsState}
              paginationMode="server"
              onPageChange={(page) => {
                setIsLoading(true)
                setRowsState((prev) => ({ ...prev, page }))
              }}
              onPageSizeChange={(pageSize) =>
                setRowsState((prev) => ({ ...prev, pageSize }))
              }
              rows={postsData && postsData.posts}
              getRowClassName={(params) =>
                `status-coder-${params.row.status.status}`
              }
              isRowSelectable={(params) =>
                selectedRows.length === 0 ||
                (selectedRows.length > 0 &&
                  selectedRows[0].status.status === params.row.status.status)
              }
              columns={columns}
              checkboxSelection={true}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids)
                const selectedRows = postsList.filter((row) =>
                  selectedIDs.has(row._id),
                )
                setSelectedRows(selectedRows)
              }}
              components={{
                Toolbar: CustomToolbar,
                Pagination: CustomPagination,
                NoRowsOverlay: NoRowComponent,
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                var filterKey = newFilterModel.items[0].columnField
                var filterValue = newFilterModel.items[0].value
                setFilterModel(newFilterModel)
                setSearchFilter({ [filterKey]: filterValue })
              }}
            />
          </Box>
        </>
      ) : (
        <AccessDenied module="Posts" />
      )}
      <DialogConfirmation
        open={cloneDialogOpen}
        onClose={() => setCloneDialogOpen(false)}
        title={'Do you really want to clone this page'}
        onConfirm={confirmClonePost}
        onReject={rejectClonePost}
        actionButtonLabel={'Clone post'}
        commentEnabled={false}
      >
        {cloneInProgress && (
          <>
            <Typography>Cloning in progress...</Typography>
            <LinearProgress />
          </>
        )}
        {selectedPostForClone && (
          <Table>
            <TableHead>
              <TableCell sx={{ fontWeight: 'bold' }}>Attributes</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                Value (Original)
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Value (Cloned)</TableCell>
            </TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
              <TableCell>{selectedPostForClone.title}</TableCell>
              <TableCell>{selectedPostForClone.title + ' copy'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>URL</TableCell>
              <TableCell>{selectedPostForClone.slug}</TableCell>
              <TableCell>{selectedPostForClone.slug + '-copy'}</TableCell>
            </TableRow>
          </Table>
        )}
      </DialogConfirmation>
    </Box>
  )
}

const moduleAttr = {
  name: 'Post',
  actions: [
    {
      slug: 'Read',
      access: 'Read',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Create',
      access: 'Create',
    },
    {
      slug: 'Publish',
      access: 'Publish',
    },
    {
      slug: 'Review',
      access: 'Review',
    },
    {
      slug: 'Preview',
      access: 'Preview',
    },
    {
      slug: 'Approve',
      access: 'Approve',
    },
  ],
}

export default withRights(Posts, moduleAttr)
