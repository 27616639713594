import { getSize } from '../../utils/helper'

export const PRODUCT_OVERVIEW_TABLES = [
  {
    key: 'pages_created',
    title: 'Pages Created',
    columns: [
      { title: 'Name', key: 'name' },
      { title: 'Description', key: 'description' },
      { title: 'Slug', key: 'slug' },
    ],
  },
  {
    key: 'published_pages',
    title: 'Pages Published',
    columns: [
      { title: 'Name', key: 'name' },
      { title: 'Description', key: 'description' },
      { title: 'URL', key: 'slug' },
    ],
  },
  {
    key: 'posts_created',
    title: 'Posts Created',
    columns: [
      { title: 'Site', key: 'site' },
      { title: 'Title', key: 'title' },
      { title: 'Status', key: 'status' },
    ],
  },
  {
    key: 'users',
    title: 'Users Onboarded',
    columns: [
      {
        title: 'Name',
        key: 'name',
        getValue: (row) => (row.firstName + ' ' + row.lastName).trim(),
      },
      { title: 'Email', key: 'email' },
      { title: 'Lan ID', key: 'lanId' },
    ],
  },
  {
    key: 'active_users',
    title: 'Active Users',
    columns: [
      {
        title: 'Name',
        key: 'name',
        getValue: (row) => (row.firstName + ' ' + row.lastName).trim(),
      },
      { title: 'Email', key: 'email' },
      {
        title: 'Activity',
        key: 'activity',
        getValue: (row) => {
          const { low, medium, high } = Object.values(row.activity)[0]
          if (high) {
            return 'High'
          } else if (medium) {
            return 'Medium'
          } else if (low) {
            return 'Low'
          } else {
            return ''
          }
        },
      },
    ],
  },
  {
    key: 'media',
    title: 'Files Uploaded',
    columns: [
      { title: 'Name', key: 'name' },
      {
        title: 'Tags',
        key: 'tags',
        getValue: (row) => row.tags?.filter(Boolean).join(', '),
      },
      {
        title: 'Size',
        key: 'size',
        getValue: (row) => getSize(row.size),
      },
    ],
  },
]

export const PRODUCT_OVERVIEW_CHARTS = [
  {
    displayName: 'Pages Created',
    key: 'pages_created',
    color: '#CC0000',
  },
  {
    displayName: 'Pages Published',
    key: 'pages_published',
    color: '#256694',
  },
  {
    displayName: 'Posts Created',
    key: 'posts_created',
    color: '#188294',
  },
  {
    displayName: 'Files Uploaded',
    key: 'files_uploaded',
    color: '#3F9276',
  },
]

export const BUSINESS_OVERVIEW_TABLE_COLUMNS = [
  { title: 'Page', key: 'page' },
  { title: 'Page load time', key: 'page_load_time' },
  { title: 'Avg.Page load time', key: 'avg_page_load_time' },
  { title: 'Active users', key: 'active_users' },
  { title: 'Avg.Page Edit time', key: 'avg_page_edit_time' },
]

export const INITIAL_ADMIN_DASHBOARD_STATE = {
  product_overview: {
    metrics: {
      pages_created: {
        count: null,
        timeSeries: [],
      },
      pages_published: {
        count: null,
        timeSeries: [],
      },
      posts_created: {
        count: null,
        timeSeries: [],
      },
      users_onboarded: {
        count: null,
        timeSeries: [],
      },
      active_users: {
        count: null,
        timeSeries: [],
      },
      files_uploaded: {
        count: null,
        timeSeries: [],
      },
    },
    frequently_used_components: {
      count: null,
      data: [],
    },
    frequently_used_attributes: {
      count: null,
      data: [],
    },
    button_clicks: {
      total: null,
      max: null,
      min: null,
    },
    components_are_flexible: {
      total: null,
      easy: null,
      moderate: null,
      hard: null,
    },
    page_load_time: {
      avg: null,
      web: null,
      mob: null,
      tab: null,
    },
    new_features_inpact: {
      data: [],
    },
  },
  business_overview: {
    metrics: {
      page_views: null,
      active_users: null,
      pages_published: null,
      avg_page_load_time: null,
      avg_time_on_page: null,
      avg_pages_per_session: null,
      avg_page_creation_time: null,
      avg_page_edit_time: null,
      bounce_rate: null,
    },
    page_details: [],
    page_load_time: {
      avg: null,
      web: null,
      mob: null,
      tab: null,
    },
    top_browsers: {
      count: null,
      data: [],
    },
    sources: {
      count: null,
      data: [],
    },
    broken_link_records: {
      data: [],
    },
    search_keywords: {
      count: null,
      data: [],
    },
    mobile_users: {
      ios_count: null,
      android_count: null,
      ios_percentage: null,
      android_percentage: null,
      ios_details: [],
      android_details: [],
    },
  },
}

export const ADMIN_DASHBOARD_METRICS = {
  product_overview: [
    {
      key: 'pages_created',
      title: 'Pages created',
      color: '#D56C15',
    },
    {
      key: 'pages_published',
      title: 'Pages published',
      color: '#CC0000',
    },
    {
      key: 'posts_created',
      title: 'Posts created',
      color: '#2951A3',
    },
    {
      key: 'users_onboarded',
      title: 'Users onboarded',
      color: '#EDB508',
    },
    {
      key: 'active_users',
      title: 'Active users',
      color: '#4318FF',
    },
    {
      key: 'files_uploaded',
      title: 'Files uploaded',
      color: '#EDB508',
    },
  ],
}

export const INITIAL_USERS_WEBSITE_DASHBOARD_STATE = {
  metrics: {
    my_assignments: {
      isVisible: true,
      value: null,
    },
    pending_requests: {
      isVisible: true,
      value: null,
    },
    approved_requests: {
      isVisible: true,
      value: null,
    },
    drafts: {
      isVisible: true,
      value: null,
    },
    published: {
      isVisible: true,
      value: null,
    },
    unpublished: {
      isVisible: true,
      value: null,
    },
    rejected_requests: {
      isVisible: true,
      value: null,
    },
  },
}

export const USERS_DASHBOARD_METRICS = [
  {
    key: 'my_assignments',
    title: 'My Assignments',
    color: '#27633B',
  },
  {
    key: 'pending_requests',
    title: 'Pending Requests',
    color: '#CC0000',
  },
  {
    key: 'approved_requests',
    title: 'Approved Requests',
    color: '#4318FF',
  },
  {
    key: 'drafts',
    title: 'Drafts',
    color: '#EDB508',
  },
  {
    key: 'published',
    title: 'Published',
    color: '#D56C15',
  },
  {
    key: 'unpublished',
    title: 'Unpublished',
    color: '#2951A3',
  },
  {
    key: 'rejected_requests',
    title: 'Rejected Requests',
    color: '#EDB508',
  },
]
