import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Alert, Box, Divider } from '@mui/material'
import EditParticipantForm from './EditParticipantForm'
import { FormHeading } from '../../../FormComponents'
import { useParams, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { useGetParticipantQuery } from '../../../../../api/participant'
import { Placeholder, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { Loader, withRights } from '../../../../../components'
import { checkUserIsAllowed } from '../../../../../utils/rightUtils'
import AccessDenied from '../../../../../Layout/AccessDenied'

export const EditParticipant = (props) => {
  const params = useParams()
  const { eventid = null, participantid = null } = params
  const {
    setResetFormStatus,
    rights = {},
    rightsLoading,
    noSiteAccess = false,
  } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const actionsAllowed = {
    userCanEditParticipant: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
    ),
  }
  const { userCanEditParticipant } = actionsAllowed
  const navigate = useNavigate()
  const [customFields, setCustomFields] = React.useState([])
  const [participant, setParticipant] = React.useState({})

  const {
    data: participantData,
    isLoading: participantIsLoading,
    error: loadError,
  } = useGetParticipantQuery(
    { id: participantid, eventid },
    {
      skip: !participantid,
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (participantData) {
      const { member = {}, participant = {} } = participantData
      const participantInfo = { ...participant, ...member }
      setParticipant(participantInfo)
    }
  }, [participantData])

  const pathTrail = [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${eventid}#participants`,
      label: 'Event Setup',
    },
    {
      to: ``,
      label: 'Edit Participant',
    },
  ]

  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }
  const resetForm = () => {
    setResetFormStatus(true)
  }
  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess && userCanEditParticipant ? (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }} data-testid="create-participant-wrapper">
        <Breadcrumbs trail={pathTrail} />
        <Box sx={{ mt: 2, width: '100%', height: '87vh' }}>
          {!loadError ? (
            participantIsLoading ? (
              <>
                <ProgressBar indeterminate />
                <Placeholder
                  style={{
                    flexGrow: 1,
                    marginLeft: 0,
                    marginTop: 0,
                    padding: 20,
                  }}
                >
                  <Placeholder.Rect height="80vh" width="100%" emphasized />
                </Placeholder>
              </>
            ) : (
              <>
                <FormHeading
                  text="Edit Participant"
                  enableBack={true}
                  btns={[
                    {
                      label: 'Add new input',
                      type: 'select',
                      cb: addNewInput,
                      icon: 'Add',
                      options: [
                        { label: 'Text input', value: 'text' },
                        { label: 'Date input', value: 'date' },
                        { label: 'Switch input', value: 'switch' },
                      ],
                    },
                    { label: 'Reset', cb: resetForm, icon: 'Replay' },
                  ]}
                />
                <Divider variant="middle" sx={{ m: 0 }} />
                <EditParticipantForm
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  setResetFormStatus={setResetFormStatus}
                  initialValues={participant}
                  onCancel={() =>
                    navigate(`/events/event-setup/${eventid}#participants`)
                  }
                  onSuccess={() =>
                    navigate(`/events/event-setup/${eventid}#participants`)
                  }
                  eventid={eventid}
                />
              </>
            )
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Alert severity="warning">
                Unable to load the participant details! Please try again by
                refreshing the page. if issue persists reach out to product team
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <AccessDenied module="Participants" />
    </Box>
  )
}

EditParticipant.propTypes = {}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Edit',
      access: 'Edit',
    },
  ],
}

export default withRights(EditParticipant, moduleAttr)
