/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Paper,
  Grid,
  Skeleton,
  Chip,
  Link,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import withRights from '../../../components/withRights'
import AccessDenied from '../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../utils/rightUtils'
import Loader from '../../../components/Loader'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdatePostMutation, useGetPostQuery } from '../../../api/post'
import {
  useGetCategoriesQuery,
  useGetTagsQuery,
  useNewCategoryMutation,
  useNewTagMutation,
} from '../../../api/taxonomy'
import { useSelector } from 'react-redux'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { slugify } from '../../../utils/helper'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutlined'
import ImageIcon from '@mui/icons-material/Image'
import MediaPicker from '../../../components/MediaPicker'
import ReactQuill from 'react-quill'
import ArrowBackIcon from '../../../assets/Image/Back.svg'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { useSnackbar } from 'notistack'

const filter = createFilterOptions()

const UpdatePost = (props) => {
  const { rights, rightsLoading } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  let { id } = useParams()
  const {
    data: postData,
    isLoading: loadingPageData,
    error: loadError,
  } = useGetPostQuery(
    { id, activeSite },
    {
      skip: !(id && activeSite),
      refetchOnMountOrArgChange: true,
    },
  )
  const [postAttr, setPostAttr] = useState({
    title: '',
    long_title: '',
    breadcrumb_title: '',
    content: [],
    slug: '',
    category: null,
    tags: [],
    feature_type: 'image',
    feature_data: {
      image: '',
      imageThumbnail: '',
      videoSrc: '',
      videoCaption: '',
      videoTranscript: '',
      videoPoster: '',
      videoPosterThumbnail: '',
      video_title: '',
      imageTitle: '',
      imageAltText: '',
      thumbnailAltTitle: '',
      thumbnailAltText: '',
      videoSrcTitle: '',
      videoSrcAltText: '',
      videoCaptionTitle: '',
      videoTranscriptTitle: '',
      videoPosterAltText: '',
      videoPosterTitle: '',
      videoPosterThumbnailAltText: '',
      videoPosterThumbnailTitle: '',
    },
    status: 'draft',
    lanId: userInfo.lanId,
    author: {
      name: '',
      designation: '',
      location: '',
      socialLink: '',
    },
    read_time: {
      text: '',
    },
    seo: {
      title: '',
      keywords: '',
      description: '',
      image: '',
      footer_notes: '',
    },
  })
  const author = postAttr.author
  const [submitted, setSubmitted] = useState(false)
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const formRef = React.useRef()
  const [isReset, setIsReset] = React.useState(false)

  const handleClose = () => {
    setIsReset(false)
  }

  const handleClearForm = () => {
    setPostAttr({
      title: '',
      long_title: '',
      breadcrumb_title: '',
      content: [],
      slug: '',
      category: null,
      tags: [],
      feature_type: 'image',
      feature_data: {
        image: '',
        imageThumbnail: '',
        videoSrc: '',
        videoCaption: '',
        videoTranscript: '',
        videoPoster: '',
        videoPosterThumbnail: '',
        video_title: '',
        imageTitle: '',
        imageAltText: '',
        thumbnailAltTitle: '',
        thumbnailAltText: '',
        videoSrcTitle: '',
        videoSrcAltText: '',
        videoCaptionTitle: '',
        videoTranscriptTitle: '',
        videoPosterAltText: '',
        videoPosterTitle: '',
        videoPosterThumbnailAltText: '',
        videoPosterThumbnailTitle: '',
      },
      status: 'draft',
      lanId: userInfo.lanId,
      author: {
        name: '',
        designation: '',
        location: '',
        socialLink: '',
        photo: '',
      },
      read_time: {
        text: '',
      },
      seo: {
        title: '',
        keywords: '',
        description: '',
        image: '',
        footer_notes: '',
      },
    })
    handleClose()
  }

  const [updatePost, { isSuccess: updateSuccess, isLoading: inProgress }] =
    useUpdatePostMutation()

  const [
    addNewCategory,
    {
      isLoading: catCreationInProgress,
      isSuccess: catCreated,
      data: newlyCreatedCat,
    },
  ] = useNewCategoryMutation()

  const [
    addNewTag,
    {
      isLoading: tagCreationInProgress,
      isSuccess: tagCreated,
      data: newlyCreatedTag,
    },
  ] = useNewTagMutation()

  const { data: categoriesList, isLoading: categoriesLoading } =
    useGetCategoriesQuery(
      {
        filter: {},
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const { data: tagsList, isLoading: tagsLoading } = useGetTagsQuery(
    {
      filter: {},
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  React.useEffect(() => {
    if (postData) {
      setPostAttr({
        ...postAttr,
        ...postData,
        category: postData.category
          ? { value: postData.category.id, label: postData.category.name }
          : null,
        tags:
          postData.tags && postData.tags.length > 0
            ? postData.tags.map((tag) => {
                return {
                  value: tag.id,
                  label: tag.name,
                }
              })
            : [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData])

  useEffect(() => {
    if (categoriesList) {
      setCategories([
        ...categoriesList.map((cat) => {
          return { label: cat.name, value: cat.id }
        }),
      ])
    }
  }, [categoriesList])

  useEffect(() => {
    if (tagsList) {
      setTags([
        ...tagsList.map((tag) => {
          return { label: tag.name, value: tag.id }
        }),
      ])
    }
  }, [tagsList])

  useEffect(() => {
    if (catCreated && newlyCreatedCat) {
      setPostAttr({
        ...postAttr,
        category: { label: newlyCreatedCat.name, value: newlyCreatedCat.id },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catCreated, newlyCreatedCat])

  useEffect(() => {
    if (tagCreated && newlyCreatedTag) {
      setPostAttr({
        ...postAttr,
        tags: [
          ...postAttr.tags,
          { label: newlyCreatedTag.name, value: newlyCreatedTag.id },
        ],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagCreated, newlyCreatedTag])

  const onInputChange = (field, value) => {
    let slug = postAttr.slug
    if (field === 'title') {
      slug = `/blog/${slugify(value)}`
    }
    setPostAttr({
      ...postAttr,
      [field]: value,
      ...(field === 'title' ? { slug } : {}),
    })
  }

  const handleSubmit = () => {
    setSubmitted(true)
    const updated = {
      ...postAttr,
      category: postAttr.category ? postAttr.category.value : null,
      tags:
        postAttr.tags && postAttr.tags.length > 0
          ? postAttr.tags.map((tag) => tag.value)
          : [],
    }
    updatePost({ id: postAttr._id, payload: updated, activeSite })
  }

  const preventDefault = (event) => event.preventDefault()

  React.useEffect(() => {
    if (updateSuccess) {
      enqueueSnackbar('Post edited successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setSubmitted(false)
      navigate(`/posts`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, navigate])

  const formFieldStyles = {
    pb: 2,
    '& .MuiInputLabel-root.MuiInputLabel-formControl': {
      color: '#333333',
      fontSize: 14,
      fontFamily: 'Helvetica for Target',
      fontWeight: '400',
      wordWrap: 'break-word',
    },
  }
  const inputWrapper = {
    pr: 1,
  }

  const inputField = {
    m: 1,
    marginBottom: 2,
  }
  const labelStyle = {
    color: '#333333',
    fontSize: 12,
    fontFamily: 'Helvetica for Target',
    fontWeight: '400',
    wordWrap: 'break-word',
  }

  return (
    <>
      {rightsLoading ? (
        <Loader />
      ) : moduleAccess &&
        checkUserIsAllowed('create', permittedActions, superRights) ? (
        <>
          <Box sx={{ display: 'flex', px: '15px' }}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={(e) => {
                    navigate('/posts/')
                  }}
                  sx={{ borderRadius: '10%', padding: '5px 0px !important' }}
                  disableElevation
                  disableRipple
                >
                  <ArrowBackIcon style={{ marginRight: '10px' }} />
                </IconButton>
                <Typography
                  variant={'h5'}
                  component={'h3'}
                  sx={{
                    color: '#3D3D3D',
                    fontSize: 32,
                    fontFamily: 'Helvetica for Target',
                    fontWeight: '700',
                    wordWrap: 'break-word',
                  }}
                >
                  Update your post
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: 'calc(100vw - 58px)',
              padding: '10px 15px',
              height: '80vh',
              overflow: 'auto',
            }}
          >
            <>
              <Paper
                sx={{
                  borderRadius: 0,
                  p: 0,
                  boxShadow: 0,
                }}
              >
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: 'flex',
                      columnGap: '10px',
                      background: '#F6F7F8',
                      flexDirection: 'row',
                    }}
                  >
                    {/* Left Side Panel */}
                    <Box
                      sx={{
                        padding: '10px 20px',
                        backgroundColor: '#F6F7F8',
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          backgroundColor: '#F6F7F8',
                          width: '100%',
                        }}
                      >
                        <Grid item xs={12}>
                          <Tooltip
                            title="Basic information's about the page"
                            placement="bottom-start"
                          >
                            <Typography
                              sx={{
                                ...inputField,
                                marginBottom: 0,
                                marginTop: 2,
                                marginLeft: 3,
                                color: '#474747',
                                fontSize: 20,
                                fontFamily: 'Helvetica for Target',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                              }}
                            >
                              Basic Information
                            </Typography>
                          </Tooltip>
                        </Grid>

                        <Box
                          sx={{
                            width: '100%',
                            marginLeft: '16px',
                            border: '2px dashed #d6d6d6 !important',
                            padding: '15px !important',
                            mt: '10px',
                            height: '500px',
                          }}
                        >
                          <Grid item xs={12} sm={12} sx={inputWrapper}>
                            <Box>
                              <TextValidator
                                label="Post title *"
                                helperText="Please input the post title"
                                variant="outlined"
                                onChange={(event) =>
                                  onInputChange('title', event.target.value)
                                }
                                value={postAttr.title}
                                validators={[]}
                                errorMessages={['post title is required!']}
                                fullWidth
                                sx={formFieldStyles}
                                size={'small'}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} sx={inputWrapper}>
                            <Box>
                              <TextValidator
                                label="Long title *"
                                helperText="Please input the long title of the post"
                                variant="outlined"
                                onChange={(event) =>
                                  onInputChange(
                                    'long_title',
                                    event.target.value,
                                  )
                                }
                                value={postAttr?.long_title}
                                validators={[]}
                                errorMessages={['Long title is required!']}
                                fullWidth
                                sx={formFieldStyles}
                                size={'small'}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} sx={inputWrapper}>
                            <Box>
                              <TextValidator
                                label="Custom Title (Optional)"
                                helperText="Input custom, breadcrumb title. Leave empty if this field is not used on client end"
                                value={postAttr.breadcrumb_title}
                                variant="outlined"
                                validators={[]}
                                onChange={(event) =>
                                  onInputChange(
                                    'breadcrumb_title',
                                    event.target.value,
                                  )
                                }
                                fullWidth
                                sx={formFieldStyles}
                                size={'small'}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} sx={inputWrapper}>
                            <Box>
                              <TextValidator
                                label="Post URL *"
                                value={postAttr.slug}
                                fullWidth
                                onChange={(event) =>
                                  onInputChange('slug', event.target.value)
                                }
                                variant="outlined"
                                sx={formFieldStyles}
                                size={'small'}
                              />
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        sx={{
                          backgroundColor: '#F6F7F8',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'start',
                            m: '10px 35px',
                          }}
                        >
                          <FormLabel
                            component="legend"
                            sx={{
                              width: '50%',
                              color: '#474747',
                              fontSize: 20,
                              fontFamily: 'Helvetica for Target',
                              fontWeight: '700',
                              wordWrap: 'break-word',
                              flex: 4,
                            }}
                          >
                            Feature Media Type
                          </FormLabel>
                          <RadioGroup
                            name="Media type"
                            value={postAttr.feature_type}
                            onChange={(event) =>
                              onInputChange('feature_type', event.target.value)
                            }
                            sx={{
                              padding: 1,
                              display: 'flex',
                              flexDirection: 'column-reverse',
                              height: 75,
                              alignItems: 'center',
                              flex: 1,
                            }}
                          >
                            <FormControlLabel
                              value="image"
                              control={<Radio />}
                              label="Image"
                              sx={{
                                color: '#2B2D31',
                                fontSize: 12,
                                fontFamily: 'Helvetica for Target',
                                fontWeight: '500',
                                wordWrap: 'break-word',
                              }}
                            />
                            <FormControlLabel
                              value="video"
                              control={<Radio />}
                              label="Video"
                              sx={{
                                color: '#2B2D31',
                                fontSize: 12,
                                fontFamily: 'Helvetica for Target',
                                fontWeight: '500',
                                wordWrap: 'break-word',
                              }}
                            />
                          </RadioGroup>
                        </Box>

                        <Box
                          sx={{
                            width: '100%',
                            marginLeft: '16px',
                            border: '2px dashed #d6d6d6 !important',
                            padding: '15px !important',
                            height: '600px',
                          }}
                        >
                          <Box>
                            {postAttr.feature_type === 'video' ? (
                              <Box>
                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '20px',
                                    background: '#F6F7F8',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pt: 2,
                                      pb: 2,
                                      width: '250px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography sx={{ ...labelStyle }}>
                                      Featured Video
                                    </Typography>
                                    <MediaPicker
                                      dataCallBack={(file) =>
                                        onInputChange('feature_data', {
                                          ...postAttr.feature_data,
                                          videoSrc: file,
                                        })
                                      }
                                      value={postAttr.feature_data.videoSrc}
                                    />

                                    <Typography
                                      variant="caption"
                                      sx={{ ...labelStyle }}
                                    >
                                      Upload the featured video file for this
                                      post
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Alt Text *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data
                                            .videoSrcAltText ?? ''
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image alt text is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoSrcAltText: event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Title *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data.videoSrcTitle ??
                                          ''
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image title is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoSrcTitle: event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                  </Box>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '20px',
                                    background: '#F6F7F8',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pt: 2,
                                      pb: 2,
                                      width: '250px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography sx={{ ...labelStyle }}>
                                      Caption File
                                    </Typography>
                                    <MediaPicker
                                      dataCallBack={(file) =>
                                        onInputChange('feature_data', {
                                          ...postAttr.feature_data,
                                          videoCaption: file,
                                        })
                                      }
                                      value={postAttr.feature_data.videoCaption}
                                    />

                                    <Typography
                                      variant="caption"
                                      sx={{ ...labelStyle }}
                                    >
                                      Upload the .vtt file for caption
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Title *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data
                                            .videoCaptionTitle ?? ''
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image title is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoCaptionTitle:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                  </Box>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '20px',
                                    background: '#F6F7F8',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pt: 2,
                                      pb: 2,
                                      width: '250px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography sx={{ ...labelStyle }}>
                                      Transcript File
                                    </Typography>
                                    <MediaPicker
                                      dataCallBack={(file) =>
                                        onInputChange('feature_data', {
                                          ...postAttr.feature_data,
                                          videoTranscript: file,
                                        })
                                      }
                                      value={
                                        postAttr.feature_data.videoTranscript
                                      }
                                    />

                                    <Typography
                                      variant="caption"
                                      sx={{ ...labelStyle }}
                                    >
                                      Upload the .pdf file for transcript
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Title *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data
                                            .videoTranscriptTitle
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image title is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoTranscriptTitle:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                  </Box>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '20px',
                                    background: '#F6F7F8',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pt: 2,
                                      pb: 2,
                                      width: '250px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography sx={{ ...labelStyle }}>
                                      Video Poster Image
                                    </Typography>
                                    <MediaPicker
                                      dataCallBack={(file) =>
                                        onInputChange('feature_data', {
                                          ...postAttr.feature_data,
                                          videoPoster: file,
                                        })
                                      }
                                      value={postAttr.feature_data.videoPoster}
                                    />

                                    <Typography
                                      variant="caption"
                                      sx={{ ...labelStyle }}
                                    >
                                      Upload the image file for poster
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Alt Text *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data
                                            .videoPosterAltText
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image title is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoPosterAltText:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Title *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data.videoPosterTitle
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image title is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoPosterTitle:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                  </Box>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '20px',
                                    background: '#F6F7F8',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pt: 2,
                                      pb: 2,
                                      width: '250px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography sx={{ ...labelStyle }}>
                                      Video Poster Thumbnail (mobile)
                                    </Typography>
                                    <MediaPicker
                                      dataCallBack={(file) =>
                                        onInputChange('feature_data', {
                                          ...postAttr.feature_data,
                                          videoPosterThumbnail: file,
                                        })
                                      }
                                      value={
                                        postAttr.feature_data
                                          .videoPosterThumbnail
                                      }
                                    />

                                    <Typography
                                      variant="caption"
                                      sx={{ ...labelStyle }}
                                    >
                                      Upload the image file for thumbnail
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Alt Text *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data
                                            .videoPosterThumbnailAltText
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image title is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoPosterThumbnailAltText:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Title *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data
                                            .videoPosterThumbnailTitle
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image title is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            videoPosterThumbnailTitle:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                  </Box>
                                </div>
                                <Box sx={{ pt: 2, pb: 2 }}>
                                  <TextValidator
                                    label="Video title *"
                                    helperText="Please input the video title"
                                    onChange={(event) =>
                                      onInputChange('feature_data', {
                                        ...postAttr.feature_data,
                                        video_title: event.target.value,
                                      })
                                    }
                                    value={postAttr.feature_data.video_title}
                                    fullWidth
                                    sx={formFieldStyles}
                                    variant="outlined"
                                    size={'small'}
                                  />
                                </Box>
                              </Box>
                            ) : (
                              <Box>
                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '20px',
                                    background: '#F6F7F8',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pt: 2,
                                      pb: 2,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '250px',
                                    }}
                                  >
                                    <Typography sx={{ ...labelStyle }}>
                                      Featured Image
                                    </Typography>
                                    <MediaPicker
                                      dataCallBack={(file) =>
                                        onInputChange('feature_data', {
                                          ...postAttr.feature_data,
                                          image: file,
                                        })
                                      }
                                      value={postAttr.feature_data.image}
                                    />
                                    <Typography
                                      variant="caption"
                                      sx={{ ...labelStyle }}
                                    >
                                      Upload the featured image for this post
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Alt Text *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data.imageAltText
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image alt text is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            imageAltText: event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Title *"
                                        sx={formFieldStyles}
                                        value={postAttr.feature_data.imageTitle}
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image alt text is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            imageTitle: event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                  </Box>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    columnGap: '20px',
                                    background: '#F6F7F8',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      pt: 2,
                                      pb: 2,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      width: '250px',
                                    }}
                                  >
                                    <Typography sx={{ ...labelStyle }}>
                                      Featured Image Thumbnail
                                    </Typography>
                                    <MediaPicker
                                      dataCallBack={(file) =>
                                        onInputChange('feature_data', {
                                          ...postAttr.feature_data,
                                          imageThumbnail: file,
                                        })
                                      }
                                      value={
                                        postAttr.feature_data.imageThumbnail
                                      }
                                    />
                                    <Typography
                                      variant="caption"
                                      sx={{ ...labelStyle }}
                                    >
                                      Upload the featured thumbnail for this
                                      post
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Alt Text *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data.thumbnailAltText
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image thumbnail alt text is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            thumbnailAltText:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                    <Box width={'100%'}>
                                      <TextValidator
                                        label="Title *"
                                        sx={formFieldStyles}
                                        value={
                                          postAttr.feature_data
                                            .thumbnailAltTitle
                                        }
                                        validators={[]}
                                        errorMessages={[
                                          'Featured image thumbnail alt text is required',
                                        ]}
                                        onChange={(event) =>
                                          onInputChange('feature_data', {
                                            ...postAttr.feature_data,
                                            thumbnailAltTitle:
                                              event.target.value,
                                          })
                                        }
                                        fullWidth
                                        variant={'outlined'}
                                        size={'small'}
                                      />
                                    </Box>
                                  </Box>
                                </div>
                              </Box>
                            )}
                          </Box>
                          <Box>
                            <Autocomplete
                              disablePortal
                              disabled={catCreationInProgress}
                              options={categories}
                              value={
                                postAttr.category && postAttr.category.label
                                  ? postAttr.category
                                  : null
                              }
                              freeSolo
                              sx={{}}
                              onChange={(_, newValue) => {
                                let value = {}
                                if (typeof newValue === 'string') {
                                  value.title = newValue
                                  value.value = newValue
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  value = newValue
                                } else {
                                  value = newValue ? newValue : ''
                                }
                                if (newValue && newValue.action) {
                                  // Initiate the new category action
                                  addNewCategory({
                                    category: {
                                      name: newValue.inputValue,
                                      type: 'category',
                                    },
                                    activeSite,
                                  })
                                } else {
                                  setPostAttr({
                                    ...postAttr,
                                    category: { ...value },
                                  })
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params)

                                const { inputValue } = params
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label,
                                )
                                if (
                                  inputValue !== '' &&
                                  !isExisting &&
                                  superRights
                                ) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add "${inputValue}"`,
                                    action: 'create',
                                  })
                                }

                                return filtered
                              }}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue
                                }
                                // Regular option
                                return option.label
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.label}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Category"
                                  variant={'outlined'}
                                  fullWidth
                                  sx={formFieldStyles}
                                  size="small"
                                />
                              )}
                            />
                          </Box>
                          <Box>
                            <Autocomplete
                              multiple
                              value={postAttr.tags ? postAttr.tags : null}
                              options={tags}
                              disabled={tagCreationInProgress}
                              onChange={(_, newValue, reason) => {
                                const foundCreation = newValue.filter(
                                  (val) => val.action === 'create',
                                )
                                if (foundCreation && foundCreation.length > 0) {
                                  addNewTag({
                                    tag: {
                                      name: foundCreation[0].inputValue,
                                      type: 'tag',
                                    },
                                    activeSite,
                                  })
                                } else {
                                  setPostAttr({
                                    ...postAttr,
                                    tags: [...newValue],
                                  })
                                }
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.label}</li>
                              )}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    label={option.label}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              style={{}}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={'Tags'}
                                  helperText="Input post tags (This value will be used for sorting, searching and more). # key is not allowed in tags"
                                  placeholder={
                                    'Press enter for adding new tags'
                                  }
                                  sx={formFieldStyles}
                                  variant={'outlined'}
                                  onKeyPress={(e) => {
                                    if (e.key === '#') {
                                      e.preventDefault()
                                    }
                                  }}
                                  size="small"
                                />
                              )}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params)

                                const { inputValue } = params
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label,
                                )
                                if (
                                  inputValue !== '' &&
                                  !isExisting &&
                                  superRights
                                ) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add "${inputValue}"`,
                                    action: 'create',
                                  })
                                }

                                return filtered
                              }}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue
                                }
                                // Regular option
                                return option.label
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Box>

                    {/* Right Side Panel */}
                    <Box
                      sx={{
                        padding: '10px 20px',
                        backgroundColor: '#F6F7F8',
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        sx={{
                          backgroundColor: '#F6F7F8',
                          width: '100%',
                        }}
                      >
                        <Grid item xs={12}>
                          <Tooltip
                            title="This section is OPTIONAL. Enter basic information about the author"
                            placement="bottom-start"
                          >
                            <Typography
                              sx={{
                                ...inputField,
                                marginBottom: 0,
                                marginTop: 2,
                                marginLeft: 3,
                                color: '#474747',
                                fontSize: 20,
                                fontFamily: 'Helvetica for Target',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                              }}
                            >
                              Author Information
                            </Typography>
                          </Tooltip>
                          {/* <Dividr /> */}
                        </Grid>
                        <Box
                          sx={{
                            width: '100%',
                            marginLeft: '12px',
                            border: '2px dashed #d6d6d6 !important',
                            padding: '20px !important',
                            mt: '10px',
                            height: '500px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              columnGap: '20px',
                              background: '#F6F7F8',
                              flexDirection: 'row',
                            }}
                          >
                            {/* Light side element */}
                            <Box width={'100%'} flexGrow={1}>
                              <Box>
                                <TextValidator
                                  label="Name"
                                  sx={formFieldStyles}
                                  helperText="Input author name's for this post"
                                  value={author.name}
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('author', {
                                      ...author,
                                      name: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>

                              <Box>
                                <TextValidator
                                  label="Social Media Handle"
                                  sx={formFieldStyles}
                                  helperText="Input author's social media handle link"
                                  value={author.socialLink}
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('author', {
                                      ...author,
                                      socialLink: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>
                              <Box>
                                <Typography>Author's Picture</Typography>
                                <MediaPicker
                                  dataCallBack={(file) =>
                                    onInputChange('author', {
                                      ...author,
                                      photo: file,
                                    })
                                  }
                                  value={author.photo}
                                />
                                <div>
                                  <Typography variant="caption">
                                    Upload author’s picture in .png OR .jpg
                                    format
                                  </Typography>
                                </div>
                              </Box>
                            </Box>
                            {/* Right side element */}
                            <Box width={'100%'}>
                              <Box>
                                <TextValidator
                                  label="Designation"
                                  sx={formFieldStyles}
                                  helperText="Input author's designation"
                                  value={author.designation}
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('author', {
                                      ...author,
                                      designation: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>
                              <Box>
                                <TextValidator
                                  label="Location"
                                  sx={formFieldStyles}
                                  helperText="Input author's location"
                                  value={author.location}
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('author', {
                                      ...author,
                                      location: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>
                              <Box>
                                <TextValidator
                                  label="Reading Time"
                                  sx={formFieldStyles}
                                  helperText="Input reading time of this article"
                                  value={
                                    postAttr.read_time
                                      ? postAttr.read_time.text
                                      : ''
                                  }
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('read_time', {
                                      text: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>
                            </Box>
                          </div>
                        </Box>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        sx={{
                          backgroundColor: '#F6F7F8',
                          width: '100%',
                        }}
                      >
                        <Grid item xs={12}>
                          <Tooltip
                            title="This section is OPTIONAL. SEO attributes help improve post’s search performance"
                            placement="bottom-start"
                          >
                            <Typography
                              sx={{
                                ...inputField,
                                marginBottom: 0,
                                marginTop: '35px',
                                marginLeft: 3,
                                color: '#474747',
                                fontSize: 20,
                                fontFamily: 'Helvetica for Target',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                              }}
                            >
                              SEO Information
                            </Typography>
                          </Tooltip>
                          {/* <Dividr /> */}
                        </Grid>
                        <Box
                          sx={{
                            width: '100%',
                            marginLeft: '12px',
                            border: '2px dashed #d6d6d6 !important',
                            padding: '20px !important',
                            mt: '20px',
                            height: '600px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              columnGap: '20px',
                              background: '#F6F7F8',
                              flexDirection: 'row',
                            }}
                          >
                            {/* Light side element */}
                            <Box width={'100%'} flexGrow={1}>
                              <Box>
                                <TextValidator
                                  label="Page Title"
                                  sx={formFieldStyles}
                                  helperText="This title is the first element that appears in a search listing of the post"
                                  value={postAttr.seo ? postAttr.seo.title : ''}
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('seo', {
                                      ...postAttr.seo,
                                      title: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>
                              <Box>
                                <TextValidator
                                  label="Keywords"
                                  sx={formFieldStyles}
                                  helperText="Keywords will be used to identify and search for posts in CMS"
                                  value={
                                    postAttr.seo ? postAttr.seo.keywords : ''
                                  }
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('seo', {
                                      ...postAttr.seo,
                                      keywords: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>
                            </Box>
                            {/* Right side element */}
                            <Box width={'100%'}>
                              <Box>
                                <TextValidator
                                  label="Post Description"
                                  sx={formFieldStyles}
                                  helperText="Key phrases will be used to identify and search for posts in CMS"
                                  value={
                                    postAttr.seo ? postAttr.seo.description : ''
                                  }
                                  validators={[]}
                                  onChange={(event) =>
                                    onInputChange('seo', {
                                      ...postAttr.seo,
                                      description: event.target.value,
                                    })
                                  }
                                  fullWidth
                                  variant={'outlined'}
                                  size={'small'}
                                />
                              </Box>
                            </Box>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              columnGap: '20px',
                              background: '#F6F7F8',
                              flexDirection: 'row',
                            }}
                          >
                            <Box width={'100%'} flexGrow={1}>
                              {' '}
                              <Box>
                                <Typography sx={{ ...labelStyle }}>
                                  Image
                                </Typography>
                                <MediaPicker
                                  dataCallBack={(file) =>
                                    onInputChange('seo', {
                                      ...postAttr.seo,
                                      image: file,
                                    })
                                  }
                                  value={postAttr.seo ? postAttr.seo.image : ''}
                                />
                                <div>
                                  <Typography
                                    variant="caption"
                                    sx={{ ...labelStyle, lineHeight: 0 }}
                                  >
                                    Upload meta image. This image will be
                                    displayed when sharing this post
                                  </Typography>
                                </div>
                              </Box>
                            </Box>
                            <Box width={'100%'}>
                              <Box>
                                <Typography sx={{ ...labelStyle }}>
                                  Post Footer Notes
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    color: '#c4c4c4',
                                    border: '2px solid',
                                    borderRadius: 2,
                                    '& .quill': {
                                      width: '100%',
                                      maxWidth: '300px',
                                    },
                                    height: '166px',
                                    mt: '10px',
                                  }}
                                >
                                  <ReactQuill
                                    value={
                                      postAttr.seo && postAttr.seo.footer_notes
                                        ? postAttr.seo.footer_notes
                                        : ''
                                    }
                                    onChange={(newValue, _, source) => {
                                      if (source === 'user') {
                                        onInputChange('seo', {
                                          ...postAttr.seo,
                                          footer_notes: newValue,
                                        })
                                      }
                                    }}
                                    placeholder={
                                      'Enter the footer notes of the post'
                                    }
                                    theme="bubble"
                                    modules={{
                                      toolbar: [
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strike',
                                        'blockquote',
                                        'link',
                                        { list: 'ordered' },
                                        { list: 'bullet' },
                                        { indent: '-1' },
                                        { indent: '+1' },
                                        { script: 'super' },
                                        { script: 'sub' },
                                      ],
                                    }}
                                    formats={[
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote',
                                      'list',
                                      'bullet',
                                      'indent',
                                      'link',
                                      'script',
                                    ]}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </div>
                        </Box>
                      </Grid>
                    </Box>
                  </div>
                  {/* </Grid> */}
                  {/* <Grid item md={6}>
                  <Box
                    sx={{
                      padding: 3,
                      background: '#f6f6f6',
                      position: 'sticky',
                      top: 0,
                      minHeight: '90vh',
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ color: '#333333', marginBottom: 2 }}
                    >
                      {postAttr.title ? (
                        postAttr.title
                      ) : (
                        <Skeleton width={'50%'} animation={false} />
                      )}
                    </Typography>
                    {postAttr.category ? (
                      <Chip
                        label={postAttr.category.label}
                        sx={{ borderRadius: 0, color: '#cc0000' }}
                      />
                    ) : (
                      <Skeleton
                        variant="rect"
                        animation={false}
                        width={'20%'}
                        height={30}
                        style={{ marginTop: 0 }}
                      />
                    )}
                    <Skeleton
                      variant="rect"
                      animation={false}
                      width={'100%'}
                      height={200}
                      style={{ marginTop: 10 }}
                      sx={{ mt: 2, textAlign: 'center', pt: 2 }}
                    >
                      {postAttr.feature_type === 'image' ? (
                        <ImageIcon
                          sx={{
                            color: '#a5a5a5',
                            fontSize: 150,
                            visibility: 'visible',
                          }}
                        />
                      ) : (
                        <PlayCircleOutlineIcon
                          sx={{
                            color: '#a5a5a5',
                            fontSize: 150,
                            visibility: 'visible',
                          }}
                        />
                      )}
                    </Skeleton>
                    <Skeleton
                      variant="text"
                      animation={false}
                      style={{ marginTop: 10 }}
                    />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} width={'60%'} />
                    <Skeleton
                      variant="text"
                      animation={false}
                      style={{ marginTop: 10 }}
                    />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} />
                    <Skeleton variant="text" animation={false} width={'40%'} />
                    {postAttr.tags && postAttr.tags.length > 0 ? (
                      postAttr.tags.map((tag, index) => {
                        return (
                          <Link
                            key={index}
                            href="#"
                            onClick={preventDefault}
                            sx={{
                              position: 'relative',
                              top: 8,
                              marginRight: 12,
                              color: '#cc0000',
                            }}
                          >
                            {tag.label}
                          </Link>
                        )
                      })
                    ) : (
                      <Skeleton
                        variant="rect"
                        animation={false}
                        width={'20%'}
                        height={30}
                        style={{ marginTop: 15 }}
                      />
                    )}
                  </Box>
                </Grid> */}
                  {/* </Grid> */}
                </ValidatorForm>
              </Paper>

              <div>
                <Dialog
                  open={isReset}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Are you sure want to clear?'}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClearForm}>Yes</Button>
                    <Button onClick={handleClose} autoFocus>
                      No
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          </Box>
          <Box
            sx={{
              m: 2,
              float: 'right',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: 1,
                borderColor: '#c00000',
                marginRight: '4%',
                p: 1,
                color: '#c00000',
                width: '120px',
                height: '40px',
              }}
              onClick={() => {
                navigate('/posts/')
              }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={
                postAttr.title.trim() === '' ||
                postAttr.long_title.trim() === '' ||
                postAttr.slug.trim() === '' ||
                (postAttr.feature_type === 'image' &&
                  postAttr.feature_data?.imageTitle?.trim() === '') ||
                (postAttr.feature_type === 'image' &&
                  postAttr.feature_data?.imageAltText?.trim() === '') ||
                (postAttr.feature_type === 'image' &&
                  postAttr.feature_data?.thumbnailAltText?.trim() === '') ||
                (postAttr.feature_type === 'image' &&
                  postAttr.feature_data?.thumbnailAltTitle?.trim() === '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoSrcAltText?.trim() === '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoSrcTitle?.trim() === '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoCaptionTitle?.trim() === '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoTranscriptTitle?.trim() === '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoPosterAltText?.trim() === '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoPosterTitle?.trim() === '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoPosterThumbnailAltText?.trim() ===
                    '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.videoPosterThumbnailTitle?.trim() ===
                    '') ||
                (postAttr.feature_type === 'video' &&
                  postAttr.feature_data?.video_title?.trim() === '')
                  ? true
                  : false
              }
              type="submit"
              sx={{
                borderRadius: 1,
                p: 1,
                backgroundColor: '#c00000',
                width: '120px',
                height: '40px',
              }}
              size="small"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </>
      ) : (
        <AccessDenied module="Posts" />
      )}
    </>
  )
}

const moduleAttr = {
  name: 'Post',
  actions: [
    {
      slug: 'edit',
      access: 'edit',
    },
  ],
}

export default withRights(UpdatePost, moduleAttr)
