import React, { useEffect, useRef } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  FormDateField,
  FormFieldLoader,
  FormSelect,
  FormTextField,
} from '../../../FormComponents'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { Placeholder, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { useSnackbar } from 'notistack'
import { Check, Delete, Edit } from '@mui/icons-material'
import _ from 'lodash'
import { snakeCaseify } from '../../../../../utils/helper'
import { useNewParticipantMutation } from '../../../../../api/participant'

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
const participantSchema = yup.object().shape({
  name: yup.string().required(`Participant's name is a required field`),
  company: yup.string().required('Company name is a required field'),
  designation: yup.string().required('Designation is a required field'),
  mobile: yup
    .string()
    .matches(phoneRegExp, 'Contact number is not valid')
    .required('Contact number is a required field'),
  email: yup
    .string()
    .email('Email ID is not valid')
    .required('Email ID is a required field'),
  linkedin_profile_url: yup
    .string()
    .required('LinkedIn profile URL is a required field'),
  experience: yup.number().required('Experience is a required field'),
  source: yup.string().required('Source is a required field'),
  confirmed: yup.bool(),
})

export const CreateParticipantForm = (props) => {
  const {
    customFields,
    setCustomFields,
    reset,
    setResetFormStatus,
    eventid = null,
    onSuccess,
    onCancel,
  } = props
  const [addNewParticipant, { data: created, isLoading, isSuccess, error }] =
    useNewParticipantMutation()
  const { enqueueSnackbar } = useSnackbar()
  const resetRef = useRef()
  const [editFields, setEditFields] = React.useState([])
  const [customNames, setCustomNames] = React.useState({})
  const [clearFields, setClearFields] = React.useState([])
  const [status, setStatus] = React.useState('pending')

  useEffect(() => {
    if (reset) {
      resetRef.current.click()
      setResetFormStatus(false)
    }
  }, [reset, resetRef, setResetFormStatus])

  useEffect(() => {
    if (isSuccess && created) {
      enqueueSnackbar('Participant created successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      onSuccess()
    }
    if (error) {
      enqueueSnackbar('Participant created failed! Please try again', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }, [enqueueSnackbar, isSuccess, created, error, onSuccess])

  const addName = (key, value, setValues, values) => {
    if (customFields[key].name !== customNames?.[key]) {
      setClearFields([...clearFields, customFields[key].name])
    }
    setCustomFields([
      ...customFields.slice(0, key),
      {
        ...customFields[key],
        name: customNames?.[key],
        slug: `_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`,
      },
      ...customFields.slice(key + 1),
    ])
    setEditFields([...editFields.filter((val) => val !== key)])
    setValues(
      {
        ...values,
        [`_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`]:
          value,
      },
      value,
    )
  }

  const deleteCustomField = (key) => {
    setCustomFields([
      ...customFields.slice(0, key),
      ...customFields.slice(key + 1),
    ])
  }

  const filterUnusedCustomFields = (values) => {
    const customFieldSlugs = _.map(customFields, 'slug')
    const shouldBeRemoved = Object.keys(values).filter(
      (key) => key.charAt(0) === '_' && !customFieldSlugs.includes(key),
    )
    return _.omit(values, shouldBeRemoved)
  }

  return (
    <Formik
      initialValues={{
        name: '',
        company: '',
        designation: '',
        mobile: '',
        email: '',
        experience: null,
        linkedin_profile_url: '',
        source: '',
      }}
      validationSchema={participantSchema}
      onSubmit={(values) => {
        const filteredValues = filterUnusedCustomFields(values)
        addNewParticipant({
          participant: {
            ...filteredValues,
            status,
            customFields,
          },
          eventid,
        })
      }}
    >
      {(props) => (
        <>
          {isLoading && (
            <Box sx={{ p: 2, pl: 3, pr: 3 }}>
              <ProgressBar indeterminate />
            </Box>
          )}
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Form className="formik-form">
              <Box
                sx={{
                  flexGrow: 1,
                  p: 1,
                  pl: 3,
                  height: '75vh',
                  overflow: 'auto',
                  background: '#F6F7F8',
                  pt: 2,
                }}
              >
                <Grid container spacing={1}>
                  {/* Left Section */}
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}
                    >
                      Participant's Info
                    </Typography>
                    <Box
                      sx={{
                        border: '2px dashed #B5B5B5',
                        p: 2,
                        minHeight: 600,
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                        }}
                      >
                        <Grid item xs={12}>
                          <Field
                            type="text"
                            name="name"
                            label="Participant's Name*"
                            placeholder="Enter name"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            type="text"
                            name="company"
                            label="Company Name*"
                            placeholder="Enter company"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            type="text"
                            name="designation"
                            label="Designation*"
                            placeholder="Enter designation"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            type="tel"
                            name="mobile"
                            label="Contact No*"
                            placeholder="Enter your contact No"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            type="text"
                            name="email"
                            label="Email ID*"
                            placeholder="Enter your email id"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            type="url"
                            name="linkedin_profile_url"
                            label="LinkedIn Profile URL*"
                            placeholder="Enter your email id"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{}}>
                          <Field
                            name="experience"
                            label="Experience (in years)*"
                            autoComplete="off"
                            component={FormSelect}
                            options={_.range(35).map((val) => {
                              return {
                                disabled: false,
                                label: `${val}`,
                                value: val,
                              }
                            })}
                            style={{
                              height: 40,
                              background: 'transparent',
                              borderRadius: 4,
                              marginTop: 2,
                            }}
                            id="experience"
                            type="select"
                          />
                        </Grid>
                        <Grid item xs={6} sx={{}}>
                          <Field
                            name="source"
                            label="How did you hear about this event?*"
                            autoComplete="off"
                            component={FormSelect}
                            options={[
                              {
                                disabled: false,
                                label: 'LinkedIn',
                                value: 'LinkedIn',
                              },
                              {
                                disabled: false,
                                label: 'Social Media',
                                value: 'Social Media',
                              },
                              {
                                disabled: false,
                                label: 'Through a friend',
                                value: 'Friend',
                              },
                              {
                                disabled: false,
                                label: 'Search Engine (Google, Bing..etc)',
                                value: 'Search Engine',
                              },
                              {
                                disabled: false,
                                label: 'Other',
                                value: 'Other',
                              },
                            ]}
                            style={{
                              height: 40,
                              background: 'transparent',
                              borderRadius: 4,
                              marginTop: 2,
                            }}
                            id="source"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  {customFields && customFields.length > 0 && (
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h4"
                        sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}
                      >
                        Custom Fields
                      </Typography>
                      <Box
                        sx={{
                          border: '2px dashed #B5B5B5',
                          p: 2,
                          minHeight: 630,
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            display: 'flex',
                          }}
                        >
                          {customFields.map((customField, key) => {
                            return (
                              <Grid item xs={12} key={key}>
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {editFields.includes(key) ? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <TextField
                                        sx={{ border: 0, ml: 1 }}
                                        size="small"
                                        placeholder="Enter field name"
                                        value={customNames?.[key]}
                                        onChange={(e) =>
                                          setCustomNames({
                                            ...customNames,
                                            [key]: e.target.value,
                                          })
                                        }
                                      />
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          addName(
                                            key,
                                            props.values?.[
                                              `_${snakeCaseify(
                                                customField.name,
                                              )}_${customField.type}`
                                            ],
                                            props.setValues,
                                            props.values,
                                          )
                                        }}
                                      >
                                        <Check />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <Button
                                      sx={{ textTransform: 'none' }}
                                      size="small"
                                      onClick={() =>
                                        setEditFields([...editFields, key])
                                      }
                                    >
                                      <FormLabel sx={{ ml: 1 }}>
                                        {customField.name
                                          ? customField.name
                                          : 'Add name for the field'}
                                      </FormLabel>
                                      <Edit sx={{ fontSize: 16, ml: 1 }} />
                                    </Button>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 2,
                                  }}
                                >
                                  {customField?.name ? (
                                    <>
                                      {customField.type === 'date' ? (
                                        <Field
                                          name={`_${snakeCaseify(
                                            customField.name,
                                          )}_${customField.type}`}
                                          type="date"
                                          placeholder="Select"
                                        >
                                          {({ field, form, meta }) => (
                                            <FormDateField
                                              {...field}
                                              {...form}
                                              sx={{ p: 1 }}
                                              onChange={(value) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  moment(value).toDate(),
                                                )
                                              }}
                                            />
                                          )}
                                        </Field>
                                      ) : (
                                        <Field
                                          autoComplete="off"
                                          name={`_${snakeCaseify(
                                            customField.name,
                                          )}_${customField.type}`}
                                          aria-label={customField.name}
                                          disabled={!customField.name}
                                          component={FormFieldLoader}
                                          sx={{ p: 1, pb: 1 }}
                                          type={customField.type}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Placeholder
                                      style={{
                                        flexGrow: 1,
                                        marginLeft: 8,
                                        marginTop: 3,
                                      }}
                                    >
                                      <Placeholder.Rect
                                        height="50px"
                                        emphasized
                                      />
                                    </Placeholder>
                                  )}
                                  <IconButton
                                    size="small"
                                    sx={{ height: 36, width: 36 }}
                                    onClick={() => deleteCustomField(key)}
                                  >
                                    <Delete sx={{ fontSize: 20 }} />
                                  </IconButton>
                                </Box>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box
                sx={{
                  background: '#fff',
                  p: 2,
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                    mr: 1,
                  }}
                  className="btn btn-primary btn-block mt-4"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                    mr: 1,
                  }}
                  color="secondary"
                  type="submit"
                  className="btn btn-primary btn-block mt-4"
                  onClick={() => setStatus('APPROVED')}
                >
                  Approve
                </Button>
                <Button
                  type="reset"
                  sx={{
                    display: 'none',
                  }}
                  ref={resetRef}
                >
                  reset
                </Button>
              </Box>
            </Form>
          </Box>
        </>
      )}
    </Formik>
  )
}

export default CreateParticipantForm
