import { useState } from 'react'
import {
  Paper,
  Typography,
  Chip,
  Stack,
  Grid,
  Box,
  Skeleton,
} from '@mui/material'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

import { Timeline, CircleRounded } from '@mui/icons-material'

import { PRODUCT_OVERVIEW_CHARTS as CHARTS } from '../dashboardConstants'

const generateChartData = (metricsData) => {
  const chartData = []
  Object.keys(metricsData).forEach((key) => {
    metricsData[key].timeSeries.forEach((monthDetails) => {
      const monthIndex = chartData.findIndex(
        (item) => item.month === monthDetails.month,
      )
      if (monthIndex === -1) {
        chartData.push({
          month: monthDetails.month,
          [key]: monthDetails.value,
        })
      } else {
        chartData[monthIndex] = {
          ...chartData[monthIndex],
          [key]: monthDetails.value,
        }
      }
    })
  })
  return chartData
}

const CustomizedAxisTick = (props) => {
  const { x, y, payload, data, selectedChart } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={15}
        dy={12}
        textAnchor="end"
        fill="#666"
        opacity={
          (selectedChart === 'All'
            ? Object.values(
                data.map((item) => {
                  const { month, ...others } = item
                  return others
                })[payload.index],
              ).reduce((acc, val) => {
                return acc + val
              }, 0)
            : data.map((item) => {
                const { month, ...others } = item
                return others
              })[payload.index][
                CHARTS.find((chart) => chart.displayName === selectedChart).key
              ]) > 0
            ? 1
            : 0.2
        }
      >
        {payload.value}
      </text>
    </g>
  )
}

const ProductOverviewChartCard = (props) => {
  const { isLoading, metrics } = props
  const [selectedChart, setSelectedChart] = useState('All')

  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px', height: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack direction="row" gap={1}>
            <Timeline sx={{ color: '#05CD99' }} />
            <Typography fontWeight="bold">Timeline</Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <div
            style={{
              display: 'flex',
              overflowX: 'scroll',
              padding: '5px 0',
              gap: '5px',
            }}
          >
            {['All', ...CHARTS.map((item) => item.displayName)].map(
              (tableName, index) => (
                <Chip
                  key={tableName}
                  label={tableName}
                  size="small"
                  variant={tableName === selectedChart ? 'filled' : 'outlined'}
                  sx={{
                    color: tableName === selectedChart ? '#3D70D6' : '#474747',
                  }}
                  onClick={() => setSelectedChart(tableName)}
                />
              ),
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <Box mt={2}>
            {CHARTS.map((chart) => (
              <Stack
                key={chart.key}
                direction="row"
                gap={1}
                alignItems="center"
              >
                <CircleRounded
                  fontSize="8px"
                  sx={{ color: `${chart.color}CA` }}
                />
                <Typography
                  fontSize="12px"
                  color="rgb(163, 174, 208)"
                  flexGrow={1}
                  noWrap
                >
                  {chart.displayName}
                </Typography>
                <Typography fontSize="14px" fontWeight="bold" color="#2B3674">
                  {isLoading ? (
                    <Skeleton width={30} />
                  ) : metrics ? (
                    metrics[chart.key]?.count
                  ) : (
                    ''
                  )}
                </Typography>
              </Stack>
            ))}
          </Box>
        </Grid>
        <Grid item xs={8}>
          <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
            <BarChart data={metrics ? generateChartData(metrics) : []}>
              <XAxis
                dataKey="month"
                tick={
                  <CustomizedAxisTick
                    data={metrics ? generateChartData(metrics) : []}
                    selectedChart={selectedChart}
                  />
                }
              />
              <YAxis />
              <Tooltip />
              {selectedChart === 'All' ? (
                CHARTS.map((chart) => (
                  <Bar
                    key={chart.key}
                    dataKey={chart.key}
                    stackId="a"
                    name={chart.displayName}
                    barSize={30}
                    fill={`${chart.color}CA`}
                  />
                ))
              ) : (
                <Bar
                  key={
                    CHARTS.find((chart) => chart.displayName === selectedChart)
                      .key
                  }
                  dataKey={
                    CHARTS.find((chart) => chart.displayName === selectedChart)
                      .key
                  }
                  name={
                    CHARTS.find((chart) => chart.displayName === selectedChart)
                      .displayName
                  }
                  barSize={30}
                  fill={`${
                    CHARTS.find((chart) => chart.displayName === selectedChart)
                      .color
                  }CA`}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ProductOverviewChartCard
