import React from 'react'
import { Typography, Box, Tabs, Tab } from '@mui/material'
import withRights from '../../components/withRights'
import AccessDenied from '../../Layout/AccessDenied'
import Loader from '../../components/Loader'
import PropTypes from 'prop-types'
import HeaderTree from './HeaderTree'
import { useSelector } from 'react-redux'
import { useGetMenuBySlugQuery } from '../../api/menu'
import FooterTree from './FooterTree'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 6,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const MenuManagement = (props) => {
  const { rights, rightsLoading, noSiteAccess = false } = props
  const { moduleAccess, superRights, permittedActions } = rights
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [typeOfMenu, setTypeOfMenu] = React.useState('header')
  const [localMenuData, setLocalMenuData] = React.useState({})
  const handleChange = (_, newValue) => {
    setTypeOfMenu(newValue)
  }

  const { data: menuData, isLoading: menuDataIsLoading } =
    useGetMenuBySlugQuery(
      {
        slug: typeOfMenu,
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  React.useEffect(() => {
    const updateMenuData = () => {
      const updatedMenu = { ...localMenuData }
      const { settings = [] } = menuData
      settings.map((cMenu) => {
        updatedMenu[cMenu.name] = cMenu
        return true
      })
      setLocalMenuData(updatedMenu)
    }
    if (!menuDataIsLoading && menuData) {
      updateMenuData(menuData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData])

  const onChangeMenu = (val, key) => {
    setLocalMenuData({
      ...localMenuData,
      [key]: {
        ...localMenuData[key],
        value: val,
      },
    })
  }

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      {rightsLoading && !noSiteAccess ? (
        <Loader />
      ) : moduleAccess ? (
        <>
          <Box sx={{ display: 'flex', background: '#FFFFFF', px: 2, py: 1 }}>
            <Box>
              <Typography
                variant={'h5'}
                component={'h3'}
                sx={{
                  fontFamily: 'Helvetica For Target',
                  fontWeight: 'Bold',
                  marginBottom: 0,
                  color: '#3D3D3D',
                  mr: 2,
                }}
              >
                Menu Management
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
          <Box sx={{ height: '80vh', boxShadow: 5, mx: 2 }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={typeOfMenu}
                  onChange={handleChange}
                  sx={{
                    '& .MuiTabs-indicator': { backgroundColor: '#CC0000' },
                  }}
                >
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="header"
                    label="Header"
                  />
                  <Tab
                    sx={{ textTransform: 'none' }}
                    value="footer"
                    label="Footer"
                  />
                </Tabs>
              </Box>
              <TabPanel value={typeOfMenu} index={'header'}>
                <HeaderTree
                  superUser={superRights}
                  permittedActions={permittedActions}
                  data={localMenuData['header'] ? localMenuData['header'] : []}
                  onChange={(val) => onChangeMenu(val, 'header')}
                  loading={menuDataIsLoading}
                />
              </TabPanel>
              <TabPanel value={typeOfMenu} index={'footer'}>
                <FooterTree
                  superUser={superRights}
                  permittedActions={permittedActions}
                  data={localMenuData['footer'] ? localMenuData['footer'] : []}
                  onChange={(val) => onChangeMenu(val, 'footer')}
                  loading={menuDataIsLoading}
                />
              </TabPanel>
            </Box>
          </Box>
        </>
      ) : (
        <AccessDenied module="Menu" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Menu',
  actions: [
    {
      slug: 'Read',
      access: 'read',
    },
    {
      slug: 'Edit',
      access: 'edit',
    },
  ],
}

export default withRights(MenuManagement, moduleAttr)
