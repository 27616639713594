export const getStatusColors = (status) => {
  const flag = status.toLowerCase()
  switch (flag) {
    case 'approved':
      return '#D1F0DB'
    case 'confirmed':
      return '#e6ee9c'
    case 'pending':
      return '#FFE4BF'
    case 'rejected':
      return '#FDAAAA'
    case 'ytr':
      return '#CFEDFF'
    // case 'attended':
    //   return '#c8e6c9'
    default:
      return '#d3d3d3'
  }
}
