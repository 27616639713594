import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Pagination,
  Typography,
} from '@mui/material'
import {
  useGetEmailTemplatesQuery,
  useGetEmailerReportsOfEventQuery,
} from '../../../../../api/emailer'
import { useSelector } from 'react-redux'
import ListLoader from '../../../../../components/ListLoader'
import { ArrowBack, Assessment, Download, Refresh } from '@mui/icons-material'
import { LABEL_OF_EMAILER, EMAIL_STATUS_LABELS } from '../../Participants/Utils'
import moment from 'moment'

export const EmailerReport = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { eventId = null } = params
  const [currentPage, setCurrentPage] = useState(1)
  const [currentRecords, setCurrentRecords] = useState([])
  const [emailerLabel, setEmailerLabel] = useState(LABEL_OF_EMAILER)
  const pathTrail = [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${eventId}#reports`,
      label: 'Reports',
    },
    {
      to: ``,
      label: 'Email Reports',
    },
  ]

  const {
    data: emailReports,
    isFetching: emailReportsLoading,
    refetch: refetchReport,
  } = useGetEmailerReportsOfEventQuery(
    {
      eventid: eventId,
      activeSite,
    },
    { skip: !activeSite, refetchOnMountOrArgChange: true },
  )

  const { data: templates } = useGetEmailTemplatesQuery(
    { activeSite },
    { skip: !activeSite, refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    if (templates && templates.length > 0) {
      setEmailerLabel(
        ...templates.map((template) => {
          return { [template.name]: template?.subject }
        }),
      )
    }
  }, [templates])

  useEffect(() => {
    if (emailReports && emailReports.length > 0) {
      setCurrentRecords([
        ...emailReports.slice((currentPage - 1) * 10, currentPage * 10),
      ])
    }
  }, [currentPage, emailReports])

  const exportToCSV = (reports) => {
    var csvRows = []
    const data = []

    if (reports) {
      reports.map((report) => {
        console.log(report)
        const obj = {
          ...report.member,
        }
        data.push(obj)
        return 0
      })
      const headers = Object.keys(data[0])
      csvRows.push(headers.join(','))
      for (const row of data) {
        const values = headers.map((header) => {
          const val = row[header]
          return `"${val}"`
        })
        csvRows.push(values.join(','))
      }
      csvRows = csvRows.join('\n')

      const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' })
      const a = document.createElement('a')
      a.download = 'failed_delivery.csv'
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }
  }

  const chipStyles = {
    ml: 1,
  }
  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }} data-testid="create-participant-wrapper">
        <Breadcrumbs trail={pathTrail} maxItems={4} />
        <Box sx={{ mt: 2, width: '100%', height: '87vh' }}>
          {!emailReportsLoading ? (
            <Card>
              <Box
                sx={{
                  background: '#E6E7EA',
                  p: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  Email Delivery Report
                </Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box>
                  <Button
                    sx={{ textTransform: 'none' }}
                    variant="outlined"
                    startIcon={<Refresh />}
                    size="small"
                    onClick={refetchReport}
                  >
                    Refetch
                  </Button>
                  <Button
                    sx={{ textTransform: 'none', ml: 1 }}
                    variant="outlined"
                    size="small"
                    startIcon={<ArrowBack />}
                    onClick={() => navigate(-1)}
                  >
                    Back to Event
                  </Button>
                </Box>
              </Box>
              <List
                dense={false}
                subheader={<ListSubheader></ListSubheader>}
                sx={{ p: 2 }}
              >
                {currentRecords && currentRecords.length > 0 ? (
                  currentRecords.map((report, key) => (
                    <React.Fragment key={key}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <Assessment />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            emailerLabel?.[report?.type]
                              ? emailerLabel?.[report?.type]
                              : report?.type
                          }
                          secondary={
                            <>
                              <strong>
                                {moment(report?.createdAt).fromNow()}
                              </strong>{' '}
                              by{' '}
                              <strong>{report?.created_by?.displayName}</strong>
                            </>
                          }
                        />
                        <Box sx={{ width: '25%' }}>
                          <Grid container alignItems={'center'}>
                            <Grid item xs={6}>
                              <Chip
                                size={'small'}
                                sx={chipStyles}
                                label={
                                  <>
                                    Status{' '}
                                    <strong>
                                      {EMAIL_STATUS_LABELS?.[report?.status]}
                                    </strong>
                                  </>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Chip
                                size={'small'}
                                color="success"
                                sx={chipStyles}
                                variant="outlined"
                                label={
                                  <>
                                    Sent{' '}
                                    <strong>{report?.success_count}</strong>
                                  </>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Chip
                                size={'small'}
                                color="primary"
                                variant="outlined"
                                sx={chipStyles}
                                label={
                                  <>
                                    Total <strong>{report?.total}</strong>
                                  </>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Chip
                                size={'small'}
                                color="error"
                                sx={chipStyles}
                                variant="outlined"
                                onClick={() => {
                                  if (
                                    report?.failed_count > 0 &&
                                    report?.failed_report.length > 0
                                  ) {
                                    exportToCSV(report.failed_report)
                                  }
                                }}
                                icon={
                                  report?.failed_count > 0 ? (
                                    <Download />
                                  ) : (
                                    <></>
                                  )
                                }
                                label={
                                  <>
                                    Failed{' '}
                                    <strong>{report?.failed_count}</strong>
                                  </>
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </ListItem>
                      <Divider variant="fullWidth" component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <Typography variant="caption">
                      No data available!
                    </Typography>
                  </ListItem>
                )}
              </List>
              {emailReports && emailReports.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 3,
                  }}
                >
                  <Pagination
                    count={Math.ceil(emailReports.length / 10)}
                    variant="outlined"
                    color="primary"
                    page={currentPage}
                    onChange={(_, page) => setCurrentPage(page)}
                  />
                </Box>
              )}
            </Card>
          ) : (
            <ListLoader />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EmailerReport
