import { Box, Card, Grid, LinearProgress, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useGetPaletteColorsQueryQuery } from '../../../../../../../api/configuration'
import TransparentBg from '../../../../../../../assets/Image/TransparentBg.png'
import { useSelector } from 'react-redux'
import CheckIcon from '@mui/icons-material/Check'

export const ChooseFromPallete = (props) => {
  const {
    value,
    onChange,
    slug = '',
    disabled = false,
    gradientAllowed = false,
  } = props
  const [palleteSolidColors, setSolidPalleteColors] = React.useState([])
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: palleteData, isLoading: palleteDataLoading } =
    useGetPaletteColorsQueryQuery(
      {
        slug: 'palette',
        activeSite,
      },
      {
        skip: !activeSite,
      },
    )
  React.useEffect(() => {
    if (palleteData) {
      const solidColors = gradientAllowed
        ? palleteData
        : palleteData.filter((color) => !color.includes('linear-gradient'))
      setSolidPalleteColors([...solidColors])
    }
  }, [palleteData, setSolidPalleteColors, gradientAllowed])
  return (
    <Box data-testid="pallete-wrapper">
      <Box sx={{ p: 1, mb: 1 }}>
        <Grid container spacing={2}>
          {palleteDataLoading && (
            <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
              <LinearProgress />
            </Grid>
          )}
          {!palleteDataLoading && palleteSolidColors.length > 0 ? (
            <Fragment>
              {palleteSolidColors.map((color, index) => {
                return (
                  <Grid item key={index}>
                    <Card
                      onClick={() => {
                        if (!disabled) {
                          onChange(color, slug)
                        }
                      }}
                      sx={{
                        position: 'relative',
                        background: color,
                        boxShadow: 3,
                        height: 28,
                        width: 28,
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        borderRadius: '50%',
                        overflow: 'visible',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {value === color && (
                        <CheckIcon
                          sx={(theme) => ({
                            fontSize: '1rem',
                            color: color.includes('linear')
                              ? 'black'
                              : theme.palette.getContrastText(color),
                          })}
                        />
                      )}
                    </Card>
                  </Grid>
                )
              })}
            </Fragment>
          ) : (
            <Typography variant="caption">No palettes added yet!</Typography>
          )}
          <Grid item>
            <Card
              raised
              onClick={() => {
                if (!disabled) {
                  onChange('transparent', slug)
                }
              }}
              sx={{
                backgroundImage: `url(${TransparentBg})`,
                cursor: disabled ? 'not-allowed' : 'pointer',
                width: 28,
                height: 28,
                borderRadius: '50%',
                overflow: 'visible',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {value === 'transparent' && (
                <CheckIcon
                  sx={(theme) => ({
                    fontSize: '1rem',
                    color: theme.palette.getContrastText('#ffffff'),
                  })}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ChooseFromPallete
