import React from 'react'
import {
  List,
  ListItem,
  Typography,
  ListItemText,
  Divider,
  Alert,
  Box,
  IconButton,
} from '@mui/material'
import { getSize } from '../../../../utils/helper'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'

const AssetsPanel = (props) => {
  const { onClose } = props
  const savedMediaList = useSelector((state) => state.session.mediaList)

  return (
    <List>
      <ListItem>
        <ListItemText
          primary={`Media Assets`}
          primaryTypographyProps={{ fontSize: 20 }}
          secondary={`Total media size is - ${getSize(
            savedMediaList &&
              savedMediaList.length > 0 &&
              savedMediaList.reduce((acc, item) => acc + item.size, 0),
          )}`}
          secondaryTypographyProps={{
            fontSize: 12,
            fontStyle: 'italic',
            fontWeight: 'bolder',
          }}
        ></ListItemText>
      </ListItem>
      <Divider sx={{ bgcolor: '#B5B5B5' }} />
      {/* TODO this should be show if the page has a custom component  */}
      <Alert severity="error">
        If the page has a custom component with media in it, then these will not
        populate in the Media Assets list. Hence, the total media size of the
        page may vary.
      </Alert>

      {savedMediaList && savedMediaList.length > 0 ? (
        savedMediaList?.map((e, index) => {
          return (
            <>
              <ListItem
                secondaryAction={
                  <Typography variant="body2">{getSize(e.size)}</Typography>
                }
              >
                <div>
                  <ListItemText
                    primary={e?.name}
                    primaryTypographyProps={{
                      style: {
                        fontSize: 14,
                        width: '80%',
                      },
                    }}
                  />
                </div>
              </ListItem>
              <Divider variant="fullWidth" />
            </>
          )
        })
      ) : (
        <Alert severity="info">No media!</Alert>
      )}
      <Box>
        <IconButton
          type="submit"
          aria-label="close menu"
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '12px',
            right: '5px',
            float: 'right',
          }}
        >
          <CloseIcon
            sx={{
              color: 'rgb(0,0,0,0.87)',
              fontSize: '2rem',
            }}
          ></CloseIcon>
        </IconButton>
      </Box>
    </List>
  )
}

export default AssetsPanel
