import { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { USERS_DASHBOARD_METRICS } from './dashboardConstants'

const CustomizeUserDashboard = (props) => {
  const { metricsState, onClose, onSave } = props

  const [metricsVisibility, setMetricsVisibility] = useState(metricsState || [])

  const isChecked = (key) => {
    if (key === 'all') {
      return metricsVisibility.every((item) => item.isVisible) ? true : false
    } else {
      return metricsVisibility.find((item) => item.key === key).isVisible
    }
  }
  const handleCheck = (key, value) => {
    if (key === 'all') {
      setMetricsVisibility(
        metricsVisibility.map((item) => ({ ...item, isVisible: value })),
      )
    } else {
      setMetricsVisibility(
        metricsVisibility.map((item) =>
          item.key === key ? { ...item, isVisible: value } : item,
        ),
      )
    }
  }

  return (
    <Stack direction="column" width={400} height="100%">
      <Stack
        direction="row"
        height={60}
        alignItems="center"
        px={2}
        sx={{
          flexShrink: 0,
          background: '#F6F7F8',
        }}
      >
        <Typography variant="h6" fontWeight="bold" color="#1A1A1A" flex={1}>
          Customize Your Dashboard
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Box px={2} py={5} flex={1}>
        {[{ key: 'all', title: 'All' }, ...USERS_DASHBOARD_METRICS].map(
          (metricDetails) => (
            <Stack direction="row" height={50} alignItems="center">
              <Typography fontWeight="bold" color="#1A1A1A" flex={1}>
                {metricDetails.title}
              </Typography>
              <Checkbox
                checked={isChecked(metricDetails.key)}
                onChange={(e) =>
                  handleCheck(metricDetails.key, e.target.checked)
                }
                sx={{
                  color: '#5FBF5F',
                  '&.Mui-checked': {
                    color: '#5FBF5F',
                  },
                }}
              />
            </Stack>
          ),
        )}
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          background: '#F6F7F8',
        }}
      >
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={metricsVisibility.every((item) => item.isVisible === false)}
          onClick={() => onSave(metricsVisibility)}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  )
}

export default CustomizeUserDashboard
