export const notificationsFilter = ['All', 'Unread', 'Read', 'Approved']

export const STATUS_TEXT = Object.freeze({
  all: {
    color: 'all',
    label: 'All',
    bgcolor: '#00A36C',
    name: 'All Posts',
    value: '',
  },
  approved: {
    color: 'approved',
    label: 'Approved',
    bgcolor: '#00A36C',
    name: 'Approved',
    value: 'approved',
  },
  draft: {
    color: 'draft',
    label: 'Draft',
    bgcolor: '#D0D4DD',
    name: 'Draft',
    value: 'draft',
  },
  pending_approval: {
    color: 'pending',
    label: 'Pending Approval',
    bgcolor: '#FEF9C3',
    name: 'Pending Approval',
    value: 'pending_approval',
  },
  rejected: {
    color: 'rejected',
    label: 'Rejected',
    bgcolor: '#FF5733',
    name: 'Rejected',
    value: 'rejected',
  },
  published: {
    color: 'published',
    label: 'Published',
    bgcolor: '#D1F0DB',
    name: 'Published',
    value: 'published',
  },
  unpublished: {
    color: 'unpublished',
    label: 'Unpublished',
    bgcolor: '#FFC0CB',
    name: 'Unpublished',
    value: 'unpublished',
  },
  archived: {
    color: 'archived',
    label: 'archived',
    bgcolor: '#FF4',
    name: 'Archived',
    value: 'archived',
  },
})
