import {
  Card,
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider,
  Button,
  LinearProgress,
} from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import SaveIcon from '@mui/icons-material/Save'
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import defaultTypography from './DefaultTyphographies.json'
import {
  useGetConfigurationBySlugQuery,
  useSaveConfigurationMutation,
  usePublishConfigurationMutation,
} from '../../api/configuration'
import { useSelector } from 'react-redux'
import { objectMerge } from '../../utils/dataParser'
import ListLoader from '../../components/ListLoader'
import ConfirmationDialog from '../Menu/Common/ConfirmationDialog'

const Typographies = (props) => {
  const { allow } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const [localSettings, setLocalSettings] = useState(defaultTypography)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [confId, setConfId] = useState(null)
  const [expanded, setExpanded] = useState(0)

  const { data: typhoData, isLoading: typhoDataIsLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'typhographies',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [saveConfiguration, { isLoading: saveConfigLoading }] =
    useSaveConfigurationMutation()

  const [publishTheConf, { isLoading: publishInProgress }] =
    usePublishConfigurationMutation()

  useEffect(() => {
    const updateData = () => {
      const { settings = [] } = typhoData
      settings.map((conf) => {
        if (conf.name === 'typhographies') {
          setLocalSettings(conf.value ? conf.value : [])
          setConfId(conf._id)
        }
        return true
      })
    }
    if (!typhoDataIsLoading && typhoData) {
      updateData(typhoData)
    }
  }, [typhoData, typhoDataIsLoading])

  const handleChange = (newValue, masterIndex, path) => {
    const updated = [
      ...localSettings.slice(0, masterIndex),
      objectMerge(localSettings[masterIndex], path, newValue),
      ...localSettings.slice(masterIndex + 1),
    ]
    setLocalSettings(updated)
  }

  const saveTyphographyConfig = () => {
    saveConfiguration({
      configuration: {
        name: 'typhographies',
        value: localSettings,
      },
      activeSite,
      id: confId,
    })
  }

  const publishTyphographies = () => {
    if (confId) {
      console.log('Trying to publish')
      publishTheConf(confId)
      setConfirmDialog(false)
    }
  }

  return (
    <>
      {!typhoDataIsLoading ? (
        <Card>
          {(saveConfigLoading || publishInProgress) && <LinearProgress />}
          <Box
            sx={{
              background: '#E6E7EA',
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Typographies</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              sx={{ textTransform: 'none', ml: 2, backgroundColor: '#CC0000' }}
              size="small"
              startIcon={<SaveIcon />}
              disabled={!allow.edit || saveConfigLoading}
              onClick={saveTyphographyConfig}
            >
              Save Changes Locally
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: 'none',
                ml: 2,
                borderColor: '#CC0000',
                color: '#CC0000',
              }}
              size="small"
              startIcon={<PublicIcon />}
              disabled={!allow.publish}
              onClick={() => setConfirmDialog(true)}
            >
              Publish Changes
            </Button>
          </Box>
          {localSettings &&
            localSettings.map((item, masterIndex) => {
              return (
                <Accordion
                  key={masterIndex}
                  expanded={expanded === masterIndex}
                  onChange={() => setExpanded(masterIndex)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="typography-content"
                    id="typography-heading"
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {item.elementName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {masterIndex === expanded &&
                      item.fontSize &&
                      item.fontSize.map((size, sIndex) => {
                        return (
                          <Box
                            key={sIndex}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography>{size.name}</Typography>
                            {size.values &&
                              size.values.map((value, index) => {
                                return (
                                  <Box sx={{ width: 300 }} key={index}>
                                    <Typography>{value.name}</Typography>
                                    <Slider
                                      key={index}
                                      defaultValue={value.range.default}
                                      aria-label="xs"
                                      marks={true}
                                      valueLabelDisplay="on"
                                      value={
                                        value.range && value.range.value
                                          ? value.range.value
                                          : value.range.default
                                      }
                                      min={value.range.min}
                                      max={value.range.max}
                                      step={value.range.step}
                                      onChange={(_, newValue) =>
                                        handleChange(
                                          newValue,
                                          masterIndex,
                                          `fontSize.${sIndex}.values.${index}.range.value`,
                                        )
                                      }
                                    />
                                  </Box>
                                )
                              })}
                          </Box>
                        )
                      })}
                  </AccordionDetails>
                </Accordion>
              )
            })}
        </Card>
      ) : (
        <ListLoader />
      )}
      <ConfirmationDialog
        title={'Do you really want to publish the Typhography changes?'}
        message={
          'By clicking publish the font sizes of the site will be modified based on the values.'
        }
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onConfirm={publishTyphographies}
      />
    </>
  )
}

export default Typographies
