import { Box, Divider } from '@mui/material'
import React from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { FormHeading } from '../FormComponents'
import FormForCreate from './FormForCreate'
const pathTrail = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: '/events',
    label: 'Events',
  },
  {
    to: '',
    label: 'Create Event',
  },
]

/**
 *
 * TODO: Test case needs to be added
 */
export const CreateEvent = () => {
  const [customFields, setCustomFields] = React.useState([])
  const [resetFormNow, setResetFormStatus] = React.useState(false)
  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }

  const resetForm = () => {
    setResetFormStatus(true)
  }

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }}>
        <Breadcrumbs trail={pathTrail} />
        <Box
          sx={{ mt: 2, width: '100%', height: '87vh', background: '#F6F7F8' }}
        >
          <FormHeading
            text="Create New Event"
            btns={[
              {
                label: 'Add New Input',
                type: 'select',
                cb: addNewInput,
                icon: 'Add',
                options: [
                  { label: 'Text input', value: 'text' },
                  { label: 'Date input', value: 'date' },
                  { label: 'Switch input', value: 'switch' },
                ],
              },
              { label: 'Reset', cb: resetForm, icon: 'Replay' },
            ]}
          />
          <Divider variant="middle" sx={{ m: 0.5 }} />
          <FormForCreate
            customFields={customFields}
            setCustomFields={setCustomFields}
            reset={resetFormNow}
            setResetFormStatus={setResetFormStatus}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CreateEvent
