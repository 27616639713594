import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Typography } from '@mui/material'

const getLabels = (dataArr) => {
  if (!dataArr && !dataArr.length > 0) {
    return []
  }
  return dataArr[0] ? Object.keys(dataArr[0]) : []
}

export default function AdditionalDataTable(props) {
  const { background = '', data = null, title = '' } = props
  const labels = getLabels(data)
  return (
    <TableContainer component={Paper} sx={{ background }} elevation={3}>
      <Typography my={1} mx={2} sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
        {title}
      </Typography>
      {data && data.length > 0 ? (
        <Table sx={{ minWidth: 650, width: '100%' }} size="small">
          <TableHead sx={{ background: '#EEEEEE' }}>
            <TableRow>
              {labels &&
                labels.map((label, lkey) => {
                  return (
                    <TableCell
                      sx={{ fontWeight: 'bold' }}
                      key={lkey}
                      align="left"
                    >
                      {label}
                    </TableCell>
                  )
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((record, key) => (
              <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {labels &&
                  labels.map((label, dkey) => {
                    return (
                      <TableCell key={dkey} align="left">
                        {record?.[label]}
                      </TableCell>
                    )
                  })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography>No data!</Typography>
      )}
    </TableContainer>
  )
}
