import React from 'react'
import { useSelector } from 'react-redux'
import { useGetDataByParamsQuery } from '../../../../../../../../api/dataconnect'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { Edit } from '@mui/icons-material'
import { ModelDataList } from '../ModelDataList'

const grid = 16
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  width: 300,
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'grey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
})

export const FinalStage = (props) => {
  const {
    setSelectedRecords,
    selectedRecords,
    selectedModule,
    model,
    dataset,
    fieldsMap = {},
    setReadyForAssign,
    dynamicData = [],
    setDynamicData,
    existingConnection = {},
    removeConnection,
    availableColumns,
    setAvailableColumns,
    editOpen = false,
    setEditOpen,
  } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: finalData } = useGetDataByParamsQuery({
    activeSite,
    model,
    dataset,
    selectedModule,
    ids: selectedRecords,
    fields: Object.values(fieldsMap),
  })

  const sortData = (data, order) => {
    const sortedArr = _.sortBy(data, function (item) {
      return order.indexOf(item._id)
    })
    return sortedArr
  }

  React.useEffect(() => {
    if (finalData && finalData?.data) {
      let updated = finalData.data
      if (existingConnection?.dynamicDataEnabled) {
        updated = sortData(finalData.data, existingConnection?.order)
      }
      setDynamicData(updated)
      setReadyForAssign(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalData, setDynamicData, setReadyForAssign])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    }

    const items = reorder(
      dynamicData,
      result.source.index,
      result.destination.index,
    )
    setDynamicData(items)
  }

  const enableEdit = () => {
    setEditOpen(true)
  }

  return (
    <>
      {!editOpen ? (
        <>
          {existingConnection?.dynamicDataEnabled && (
            <Alert
              color="success"
              action={
                <Button
                  color="secondary"
                  sx={{ textTransform: 'none' }}
                  size="small"
                  onClick={removeConnection}
                >
                  Unlink Datasource
                </Button>
              }
            >
              Data connectivity to the component is active!
            </Alert>
          )}
          <Typography sx={{ fontSize: 17, fontWeight: 700 }}>
            {!existingConnection?.dynamicDataEnabled ? 'Finalize / ' : ''}{' '}
            Re-Arrange the data order
          </Typography>
          <Typography sx={{ fontSize: 14, mb: 2 }}>
            Drag and move the data card based on your requirement.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              maxHeight: '49vh',
              overflow: 'auto',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <Draggable
                      key={'extra'}
                      draggableId={'extra'}
                      isDragDisabled
                      index={dynamicData.length}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            ),
                            background: '#263238',
                            borderRadius: 10,
                          }}
                        >
                          <Card
                            sx={{
                              width: 200,
                              height: 200,
                              overflow: 'hidden',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: '#263238',
                              boxShadow: 0,
                            }}
                          >
                            <CardActionArea
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                              }}
                              onClick={() => enableEdit()}
                            >
                              <Edit sx={{ color: '#fff', fontSize: 30 }} />
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  fontWeight: 'bold',
                                  textOverflow: 'ellipsis',
                                  color: '#fff',
                                }}
                              >
                                Click to Edit Records
                              </Typography>
                            </CardActionArea>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                    {dynamicData.map((item, index) => {
                      const dataStack = Object.values(fieldsMap).map(
                        (field) => item?.[field],
                      )
                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <Box
                                sx={{
                                  width: 200,
                                  height: 200,
                                  overflow: 'hidden',
                                }}
                              >
                                {dataStack &&
                                  dataStack.map((dataPoint, key) => {
                                    return (
                                      <Typography
                                        key={key}
                                        sx={{
                                          fontSize: !key ? 15 : 13,
                                          textOverflow: 'ellipsis',
                                        }}
                                        color="text.secondary"
                                      >
                                        {dataPoint}
                                      </Typography>
                                    )
                                  })}
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </>
      ) : (
        <>
          <ModelDataList
            selectedRecords={selectedRecords}
            setSelectedRecords={(records) => {
              setSelectedRecords(records)
            }}
            selectedModule={selectedModule}
            model={'speakers'}
            program={''}
            dataset={dataset}
            selection={'multiple'}
            availableColumns={availableColumns}
            setAvailableColumns={setAvailableColumns}
            stopAllSelection={true}
          />
        </>
      )}
    </>
  )
}

export default FinalStage
