/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import { Loader, ManageDataGrid, withRights } from '../../../../../components'
import ListFilters from './ListFilters'
import moment from 'moment'
import { getStatusColors } from '../../Common'
import _ from 'lodash'
import AddTaskIcon from '@mui/icons-material/AddTask'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import { STATUS_OPTIONS, STATUS_LABEL } from '../Utils/Constants'
import { useSendEmailToPartipantsMutation } from '../../../../../api/emailer'
import { useSnackbar } from 'notistack'
import { ProgressBar } from '@enterprise-ui/canvas-ui-react'

export const ListParticipants = (props) => {
  const {
    participants = [],
    participantsCount = {},
    participantsCountLoading = false,
    filters,
    setFilters,
    participantsTotalCount = 0,
    loading = false,
    deleteInitiated = false,
    initiateDelete,
    selectedParticipants = [],
    setSelectedParticipants,
    onClickFilter,
    eventid = null,
    editDisabled = false,
    setFlagForAllSelection,
    allSelected,
    exportToCSV,
    downloadableDataIsLoading,
    actionsAllowed = {},
  } = props
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const {
    userCanSendEmail = false,
    userCanEditParticipant = false,
    userCanReview = false,
  } = actionsAllowed

  const chipFilters = [{ label: 'All', value: '' }, ...STATUS_OPTIONS]

  const updatedFilters = chipFilters.map((cf) =>
    participantsCount?.[cf.value.toLowerCase()]
      ? {
          ...cf,
          label: `${cf.label} ${participantsCount?.[cf.value.toLowerCase()]}`,
        }
      : { ...cf },
  )

  const [
    sendEmailer,
    {
      isSuccess: emailTriggered,
      isError: emailTriggerFailed,
      isLoading: emailIsTriggering,
    },
  ] = useSendEmailToPartipantsMutation()

  const triggerEmail = (data) => {
    if (data?.confirmation) {
      const payload = {
        emailer_type: data?.emailer,
        select_all: allSelected,
        participants: allSelected ? ['all'] : [...selectedParticipants],
        filters,
      }
      const emailData = {
        payload,
        eventid,
      }
      sendEmailer(emailData)
    }
  }

  useEffect(() => {
    if (allSelected) {
      setSelectedParticipants([...participants.map((p) => p?._id)])
    }
  }, [allSelected, participants, setSelectedParticipants])

  useEffect(() => {
    if (emailTriggered) {
      enqueueSnackbar('Emails successfully submitted to the queue', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }, [enqueueSnackbar, emailTriggered])

  useEffect(() => {
    if (emailTriggerFailed) {
      enqueueSnackbar('Unable to submit the emails!. Please try again', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  }, [enqueueSnackbar, emailTriggerFailed])

  const columns = [
    {
      field: 'user',
      headerName: `Participant's Details`,
      width: 250,
      valueGetter: (params) => params.row?.user?.name,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'block',
            width: 300,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 230,
      valueGetter: (params) => params.row?.user.email,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'block',
            width: 230,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'company',
      headerName: 'Company Name',
      width: 150,
      valueGetter: (params) => params.row?.user.company,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'block',
            width: 150,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'registered_at',
      headerName: 'Registered Date & Time',
      width: 200,
      valueGetter: (params) => {
        return params.row?.registered_at
          ? {
              date: moment(params.row?.registered_at).format(
                'MMM-DD-YYYY, ddd',
              ),
              time: moment(params.row?.registered_at).format('h:mm a'),
            }
          : {}
      },
      renderCell: (params) => (
        <Box
          sx={{
            display: 'block',
            width: 200,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant="body2" sx={{ color: '#333333', fontSize: 14 }}>
            {params.value?.date}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{ color: '#888888', fontSize: 12 }}
          >
            {params.value?.time}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'approved_at',
      headerName: 'Approved Date & Time',
      width: 200,
      valueGetter: (params) => {
        return params.row?.approved_at
          ? {
              date: moment(params.row?.approved_at).format('MMM-DD-YYYY, ddd'),
              time: moment(params.row?.approved_at).format('h:mm a'),
            }
          : {}
      },
      renderCell: (params) => (
        <Box
          sx={{
            display: 'block',
            width: 200,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant="body2" sx={{ color: '#333333', fontSize: 14 }}>
            {params.value?.date}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{ color: '#888888', fontSize: 12 }}
          >
            {params.value?.time}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'auto_approval',
      width: 150,
      headerName: 'Auto Approved',
      valueGetter: (params) => params.row?.auto_approved,
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
          {params.value ? 'Yes' : 'No'}
        </Box>
      ),
    },
    {
      field: 'attended',
      width: 150,
      headerName: 'Attended',
      valueGetter: (params) => params.row?.attended,
      renderCell: (params) => (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
          {params.value ? 'Yes' : 'No'}
        </Box>
      ),
    },
    {
      field: 'status',
      minWidth: 200,
      headerName: 'Approval Status',
      renderCell: (params) => (
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            color: getStatusColors(params.value),
          }}
        >
          <Chip
            label={
              STATUS_LABEL?.[params.value]
                ? STATUS_LABEL?.[params.value]
                : _.capitalize(params.value)
            }
            sx={{
              backgroundColor: getStatusColors(params.value),
              borderRadius: 1,
              fontSize: 12,
              fontWeight: 700,
              minWidth: 100,
            }}
          />
        </Box>
      ),
    },
    {
      field: 'actions',
      flex: 1,
      headerAlign: 'center',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Tooltip title="Edit Participant" sx={{ mr: 1 }}>
              <IconButton
                disabled={editDisabled || !userCanEditParticipant}
                onClick={() =>
                  navigate(
                    `/events/participants/edit/${eventid}/${params.row._id}`,
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Review Participant">
              <IconButton
                disabled={editDisabled || !userCanReview}
                onClick={() =>
                  navigate(
                    `/events/participants/review/${eventid}/${params.row._id}`,
                  )
                }
              >
                <AddTaskIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return (
    <Box data-testid="list-participant-wrapper" sx={{ pt: 1 }}>
      <ListFilters
        setFilters={setFilters}
        filters={filters}
        loading={participantsCountLoading}
        chipFilters={updatedFilters}
        selectedInfo={{
          count: allSelected
            ? participantsTotalCount
            : selectedParticipants.length,
        }}
        disabled={editDisabled}
        disableEmailSending={!userCanSendEmail}
        flags={{
          emailTriggered,
          emailIsTriggering,
          emailTriggerFailed,
        }}
        callbacks={{
          sendEmail: triggerEmail,
          onClickFilter: onClickFilter,
          clickDownload: exportToCSV,
        }}
        downloadableDataIsLoading={downloadableDataIsLoading}
      />
      <Box>{emailIsTriggering && <ProgressBar indeterminate />}</Box>
      <Box
        sx={{
          '& .MuiTablePagination-selectLabel': {
            mb: 0,
          },
        }}
      >
        <ManageDataGrid
          getRowId={(row) => {
            return row._id
          }}
          height={'65vh'}
          rows={participants}
          columns={columns}
          paginationMode="server"
          checkboxSelection={true}
          pagination
          page={filters?.page - 1}
          pageSize={filters?.perpage}
          rowsPerPageOptions={[15, 30, 50, 100]}
          rowCount={participantsTotalCount}
          onPageSizeChange={(newPageSize) =>
            setFilters({ ...filters, perpage: newPageSize })
          }
          loading={loading}
          onColumnHeaderClick={(params, event, details) => {
            if (params?.field === '__check__') {
              setFlagForAllSelection(event.target.checked)
            }
          }}
          onSelectionModelChange={(newRowSelectionModel, ...rest) => {
            setSelectedParticipants(newRowSelectionModel)
          }}
          selectionModel={selectedParticipants}
          onPageChange={(page) => {
            setFilters((prev) => ({ ...prev, page: page + 1 }))
          }}
        />
      </Box>
    </Box>
  )
}

ListParticipants.propTypes = {}

export default ListParticipants
