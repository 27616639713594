import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
  Typography,
  Chip,
  Stack,
  Skeleton,
} from '@mui/material'

import { PRODUCT_OVERVIEW_TABLES } from '../dashboardConstants'
import { useGetAdminProductMetricsListQuery } from '../../../api/newDashboard'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E6E7EA',
    color: '#333333',
    fontWeight: 'bold',
  },
}))

const ProductOverviewTableCard = (props) => {
  const { selectedYear } = props
  const [selectedTable, setSelectedTable] = useState(0)

  const activeSite = useSelector((state) => state.tenant.activeSite)

  const {
    data: metricsList,
    isFetching: isLoadingMetricsList,
    isError: isErrorMetricsList,
  } = useGetAdminProductMetricsListQuery({
    year: selectedYear,
    type: PRODUCT_OVERVIEW_TABLES[selectedTable].key,
    site: activeSite._id,
  })

  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px' }}>
      <Stack direction="row" gap="5px" overflow="scroll" py="5px">
        {PRODUCT_OVERVIEW_TABLES.map((tableDetails, index) => (
          <Chip
            key={tableDetails.key}
            label={tableDetails.title}
            size="small"
            variant={index === selectedTable ? 'filled' : 'outlined'}
            sx={{
              color: index === selectedTable ? '#3D70D6' : '#474747',
            }}
            onClick={() => setSelectedTable(index)}
          />
        ))}
      </Stack>
      <TableContainer sx={{ height: 320 }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {PRODUCT_OVERVIEW_TABLES[selectedTable].columns.map(
                (colDetails) => (
                  <StyledTableCell key={colDetails.key}>
                    {colDetails.title}
                  </StyledTableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isErrorMetricsList ? (
              <TableRow>
                <TableCell
                  colSpan={
                    PRODUCT_OVERVIEW_TABLES[selectedTable].columns.length
                  }
                  height={235}
                  align="center"
                >
                  Error Fetching Data
                </TableCell>
              </TableRow>
            ) : (
              (isLoadingMetricsList ? Array(100).fill() : metricsList).map(
                (row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {PRODUCT_OVERVIEW_TABLES[selectedTable].columns.map(
                      ({ key, getValue }) => (
                        <TableCell
                          key={key}
                          component="th"
                          scope="row"
                          sx={{
                            minWidth: 100,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {isLoadingMetricsList ? (
                              <Skeleton />
                            ) : getValue ? (
                              getValue(row)
                            ) : (
                              row[key]
                            )}
                          </Typography>
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                ),
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ProductOverviewTableCard
