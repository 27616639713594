import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Checkbox,
  Pagination,
  CircularProgress,
  Input,
  InputAdornment,
  Box,
} from '@mui/material'
import { useGetPostsQuery } from '../../../api/post'
import { useSelector } from 'react-redux'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'

export default function PostList(props) {
  const {
    open = false,
    handleClose,
    addPost,
    reset = false,
    setReset,
    includeId = false,
  } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [rowsState, setRowsState] = useState({
    post: 1,
    postSize: 10,
    postCount: 0,
  })
  const [searchFilter, setSearchFilter] = useState({ name: '' })
  const [postsList, setPostsList] = useState([])
  const [selectedPost, setSelectedPost] = useState(null)

  const { data: postsData, isLoading: postListLoading } = useGetPostsQuery(
    {
      userInfo,
      activeSite,
      postNo: rowsState.post,
      filter: searchFilter,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  useEffect(() => {
    if (postsData && postsData.posts) {
      setPostsList([...postsData.posts])
      setRowsState({
        ...rowsState,
        postCount: Math.ceil(postsData.total / rowsState.postSize),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsData])

  const togglePostSelection = (value) => {
    if (selectedPost && selectedPost.slug === value.slug) {
      setSelectedPost(null)
    } else {
      setSelectedPost(value)
    }
  }

  const addPostToNode = () => {
    const { name, slug, _id } = selectedPost
    const extra = includeId
      ? {
          id: _id,
        }
      : {}
    addPost({
      name,
      slug,
      ...extra,
      children: [],
    })
  }

  useEffect(() => {
    if (reset) {
      setReset(false)
      setRowsState({ ...rowsState, post: 1 })
      setSelectedPost(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, setReset])

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Select post to add</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <DialogContentText sx={{ width: '30%' }}>Posts</DialogContentText>
            <Box sx={{ flexGrow: 1 }} />
            <Input
              value={searchFilter.name}
              sx={{}}
              onChange={(e) => setSearchFilter({ name: e.target.value })}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <ManageSearchIcon />
                </InputAdornment>
              }
            />
          </Box>
          <List
            dense={true}
            sx={{ width: '100%', bgcolor: 'background.paper' }}
          >
            {postsList &&
              postsList.length > 0 &&
              postsList.map((post, index) => {
                const { title, slug } = post
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      key={index}
                      onClick={() => togglePostSelection(post)}
                      disabled={selectedPost && selectedPost.slug !== slug}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="end"
                          checked={selectedPost && selectedPost.slug === slug}
                        />
                      </ListItemIcon>
                      <ListItemText primary={title} secondary={slug} />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </React.Fragment>
                )
              })}
            {postListLoading && <CircularProgress />}
          </List>
          <Pagination
            count={rowsState.postCount}
            size="small"
            onChange={(_, post) => setRowsState({ ...rowsState, post })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selectedPost}
            sx={{ textTransform: 'none' }}
            onClick={addPostToNode}
            color={'primary'}
            variant="contained"
          >
            Add
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={handleClose}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
