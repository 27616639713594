import { Circle } from '@mui/icons-material'
import { Paper } from '@mui/material'
import { Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'

import CheckIcon from '../../../assets/Image/check_icon.svg'

const PieChartCard = (props) => {
  const { title, count, data } = props

  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckIcon style={{ margin: '2px' }} />
        <h4 style={{ flex: '1 1 100%', margin: '0 10px' }}>{title}</h4>
        <h2>{count}</h2>
      </div>
      <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
        <PieChart>
          <Pie data={data ? addColorsToData(data) : []} dataKey="value" />
          <Legend content={renderCusomizedLegend} />
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  )
}

const addColorsToData = (data) => {
  const COLORS = ['#008300', '#E2BA0C', '#E8EEFC']
  return data.map((item, index) => {
    if (item.fill) return item
    else return { ...item, fill: COLORS[index % COLORS.length] }
  })
}

const renderCusomizedLegend = ({ payload }) => {
  return (
    <div>
      {payload.map((entry, key) => {
        const { color, payload } = entry
        const { name, value } = payload

        return (
          <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
            <Circle fontSize="8px" style={{ color: color }} />
            <div
              style={{
                color: '#A3AED0',
                fontSize: '12px',
                flex: '1 1 100%',
                margin: '0 10px',
              }}
            >
              {name}
            </div>
            <h4>{value}</h4>
          </div>
        )
      })}
    </div>
  )
}

export default PieChartCard
