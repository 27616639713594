import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function ConfirmationDialog(props) {
  const { title = '', message = '', onConfirm, onClose, open } = props

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: 'none' }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'contained'}
            sx={{ textTransform: 'none' }}
            onClick={onConfirm}
            autoFocus
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
