import { Paper, Typography, Grid, Box, Button } from '@mui/material'
import React from 'react'
import { GAIMAGE } from '../../../assets/Image'

const EventDashboardAnalytics = (props) => {
  return (
    <Grid item xs>
      <Paper
        sx={{
          borderRadius: '20px',
          padding: '20px',
          height: '100%',
          minHeight: '400px',
          maxHeight: '400px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <img
            src={GAIMAGE}
            alt="download-report"
            height="65px"
            width="155px"
          />
          <Typography sx={{ fontSize: 24, fontWeight: 700, color: '#2B2D31' }}>
            To Check detailed Analytical View
          </Typography>
          <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#A3AED0' }}>
            Please tap the below view to Google Analytical View.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              disableFocusRipple
              variant={'contained'}
              onClick={() => {
                window.open('https://analytics.google.com/')
              }}
              sx={{
                textTransform: 'none',
                backgroundColor: '#CC0000',
                ':hover': { backgroundColor: '#CC0000' },
                ':focus': { border: '2px dashed #fff' },
                width: '150px',
                height: '40px',
              }}
              size="small"
            >
              View
            </Button>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default EventDashboardAnalytics
