import { Alert, Box, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { FormHeading } from '../FormComponents'
import FormForEdit from './FormForEdit'
import { useParams } from 'react-router-dom'
import { useGetEventQuery } from '../../../api/event'
import { Placeholder, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import moment from 'moment'

const pathTrail = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: '/events',
    label: 'Events',
  },
  {
    to: '',
    label: 'Edit Event',
  },
]

/**
 *
 * TODO: Test case needs to be added
 */
export const EditEvent = () => {
  const [customFields, setCustomFields] = React.useState([])
  const [resetFormNow, setResetFormStatus] = React.useState(false)
  const [eventData, setEventData] = React.useState({})
  let { id } = useParams()
  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }

  const {
    data: event,
    isLoading: eventIsLoading,
    error: eventLoadError,
  } = useGetEventQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (event) {
      setEventData({
        ...event,
        year: moment().set('year', event?.year),
      })
      if (event?.customFields) {
        setCustomFields([...event.customFields])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }}>
        <Breadcrumbs trail={pathTrail} />
        <Box
          sx={{ mt: 2, width: '100%', height: '87vh', background: '#F6F7F8' }}
        >
          {!eventLoadError ? (
            eventIsLoading ? (
              <>
                <ProgressBar indeterminate />
                <Placeholder
                  style={{
                    flexGrow: 1,
                    marginLeft: 0,
                    marginTop: 0,
                    padding: 20,
                  }}
                >
                  <Placeholder.Rect height="80vh" width="100%" emphasized />
                </Placeholder>
              </>
            ) : (
              <>
                <FormHeading
                  text="Edit Event"
                  btns={[
                    {
                      label: 'Add new input',
                      type: 'select',
                      cb: addNewInput,
                      icon: 'Add',
                      options: [
                        { label: 'Text input', value: 'text' },
                        { label: 'Date input', value: 'date' },
                        { label: 'Switch input', value: 'switch' },
                      ],
                    },
                  ]}
                />
                <Divider variant="middle" sx={{ m: 0.5 }} />
                <FormForEdit
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  reset={resetFormNow}
                  initialValues={eventData}
                  setResetFormStatus={setResetFormStatus}
                />
              </>
            )
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Alert severity="warning">
                Unable to load the event details! Please try again by refreshing
                the page. if issue persists reach out to product team
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EditEvent
