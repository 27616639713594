import { Paper } from '@mui/material'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import CheckIcon from '../../../assets/Image/check_icon.svg'

const BarChartCard = (props) => {
  const { title, count, data } = props
  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CheckIcon style={{ margin: '2px' }} />
        <h4 style={{ flex: '1 1 100%', margin: '0 10px' }}>{title}</h4>
        <h2>{count}</h2>
      </div>
      <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
        <BarChart data={data ? data : []}>
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="value" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default BarChartCard
