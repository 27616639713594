import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiConfig from '../apiConfig'

const { api } = apiConfig

export const participantApi = createApi({
  reducerPath: 'participant',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.host}events`,
    prepareHeaders: (headers, { getState }) => {
      const identityToken = getState().session.identityToken
      const lanId = getState().session.userInfo.lanId
      const bearer = getState().session.userInfo.accessToken
      // If we have a token set in state, let's assume that we should be passing it.
      if (bearer && lanId) {
        headers.set('lanid', lanId)
        headers.set('authorization', bearer)
        headers.set('x-api-key', api.apiKey)
        headers.set('x-id-token', identityToken)
      }
      return headers
    },
  }),
  tagTypes: ['EventParticipants'],
  endpoints: (builder) => ({
    newParticipant: builder.mutation({
      query: ({ participant, eventid }) => ({
        url: `/${eventid}/participants/create`,
        method: 'POST',
        body: {
          ...participant,
        },
      }),
      invalidatesTags: ['EventParticipants'],
    }),
    getParticipant: builder.query({
      query: ({ id, eventid }) => ({
        url: `/${eventid}/participant/${id}`,
        method: 'GET',
      }),
    }),
    editParticipant: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/participant/update/${id}`,
        method: 'POST',
        body: {
          ...payload,
        },
      }),
      invalidatesTags: ['EventParticipants'],
    }),
    getAllParticipants: builder.query({
      query: ({ filters, activeSite }) => ({
        url: `/participants/search`,
        method: 'GET',
        params: {
          ...filters,
          siteid: activeSite._id,
        },
      }),
      providesTags: ['EventParticipantsAll'],
    }),
    getParticipantsOfEvent: builder.query({
      query: ({ filters, activeSite, eventid, download = false }) => ({
        url: `/participants/list`,
        method: 'GET',
        params: {
          ...filters,
          eventid,
          siteid: activeSite._id,
          download,
        },
      }),
      providesTags: ['EventParticipants'],
    }),
    getParticipantsCountOfEvent: builder.query({
      query: ({ filters, activeSite, eventid }) => ({
        url: `/participants/count`,
        method: 'GET',
        params: {
          eventid,
          siteid: activeSite._id,
        },
      }),
      providesTags: ['EventParticipantsCount'],
    }),
    mapParticipants: builder.mutation({
      query: ({ payload, eventid }) => ({
        url: `/${eventid}/participants/clone`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['EventParticipants', 'EventParticipantsCount'],
    }),
    removeParticipant: builder.mutation({
      query: ({ participantIds, eventid }) => ({
        url: `/${eventid}/participants/remove`,
        method: 'DELETE',
        body: {
          eventid,
          p_ids: [...participantIds],
        },
      }),
      invalidatesTags: ['EventParticipants'],
    }),
    reviewParticipant: builder.mutation({
      query: ({ eventid, payload }) => ({
        url: `/${eventid}/participants/updatestatus`,
        method: 'POST',
        body: {
          ...payload,
        },
      }),
      invalidatesTags: ['EventParticipants'],
    }),
    getParticipantHistory: builder.query({
      query: ({ eventid, participantid }) => ({
        url: `/${eventid}/participant/${participantid}/audit`,
        method: 'GET',
      }),
    }),
    getParticipantReports: builder.query({
      query: ({ eventid, activeSite, filters }) => ({
        url: `/${eventid}/participants/fresh-registered-report`,
        params: { ...filters, site: activeSite._id },
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useNewParticipantMutation,
  useGetAllParticipantsQuery,
  useMapParticipantsMutation,
  useGetParticipantsOfEventQuery,
  useGetParticipantsCountOfEventQuery,
  useRemoveParticipantMutation,
  useGetParticipantQuery,
  useEditParticipantMutation,
  useReviewParticipantMutation,
  useGetParticipantHistoryQuery,
  useGetParticipantReportsQuery,
} = participantApi
