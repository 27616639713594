/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import {
  Box,
  Button,
  Card,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { ArrowBack, Refresh } from '@mui/icons-material'
import { useGetParticipantReportsQuery } from '../../../../../api/participant'
import { grey } from '@mui/material/colors'
import ListLoader from '../../../../../components/ListLoader'
import {
  useListMentorsByMentorshipsQuery,
  useListMentorshipsQuery,
} from '../../../../../api/mentorship'
import _ from 'lodash'

const statusFlags = [
  {
    flag: 'approved_attended',
    label: 'Approved & Attended',
  },
  {
    flag: 'walkin',
    label: 'Walkins',
  },
  {
    flag: 'rejected_attended',
    label: 'Rejected & Attended',
  },
  {
    flag: 'ytr_attended',
    label: 'YTR & Attended',
  },
  {
    flag: 'blocked_attended',
    label: 'Blocked & Attended',
  },
  {
    flag: 'on_hold_attended',
    label: 'On hold & Attended',
  },
]
const participantStatus = [
  'APPROVED',
  'REGISTERED',
  'ATTENDED',
  'ON_HOLD',
  'REJECTED',
]
const participantExpInYears = ['0-3', '3-7', '7-12', '12+']
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[900],
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const InvenueReports = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { eventId = null } = params
  const [participantsReport, setParticipantReport] = useState(null)
  const [mentorshipListData, setMentorshipListData] = useState({})
  const [mentorshipId, setMentorshipId] = useState('')
  const [mentorshipSpeakerData, setMentorshipSpeakerData] = useState([])

  const [filters, setFilters] = useState({
    company: 'non-target',
    fresh: true,
  })
  const pathTrail = [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${eventId}#reports`,
      label: 'Reports',
    },
    {
      to: ``,
      label: 'Invenue Reports',
    },
  ]

  const {
    data: participantsData,
    isFetching: participantsDataLoading,
    refetch: refetchReport,
  } = useGetParticipantReportsQuery(
    {
      eventid: eventId,
      activeSite,
      filters,
    },
    { skip: !eventId || !activeSite, refetchOnMountOrArgChange: true },
  )
  const {
    data: mentorshipData,
    // isFetching: mentorshipFetching,
    refetch: refetchMentorships,
  } = useListMentorshipsQuery(
    { eventid: eventId, filters, activeSite },
    { skip: !activeSite, refetchOnMountOrArgChange: true },
  )

  const {
    data: mentorsData,
    // isFetching: mentorsFetching,
    refetch: refetchMentors,
  } = useListMentorsByMentorshipsQuery(
    {
      eventid: eventId,
      mentorship_id: mentorshipId,
      activeSite,
    },
    { skip: !activeSite || !mentorshipId, refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    setFilters(filters)
  }, [filters])

  useEffect(() => {
    if (mentorsData) {
      setMentorshipSpeakerData(mentorsData.data)
    }
  }, [mentorsData])

  useEffect(() => {
    if (mentorshipData) {
      setMentorshipListData(mentorshipData.data[0])
      if (mentorshipData.data.length > 0) {
        setMentorshipId(mentorshipData?.data[0]?._id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorshipData])

  useEffect(() => {
    if (participantsData) {
      setParticipantReport(participantsData?.response)
      // generateDownloadableReport(participantsData?.response)
    }
  }, [participantsData])

  function refetchInvenueStats() {
    refetchReport()
    refetchMentors()
    refetchMentorships()
  }
  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }} data-testid="create-participant-wrapper">
        <Breadcrumbs trail={pathTrail} maxItems={4} />
        <Box sx={{ mt: 2, width: '100%', height: 'auto' }}>
          <Card>
            <Box
              sx={{
                background: '#E6E7EA',
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Invenue Reports
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box>
                <Button
                  sx={{ textTransform: 'none' }}
                  variant="outlined"
                  startIcon={<Refresh />}
                  size="small"
                  onClick={refetchInvenueStats}
                >
                  Refetch
                </Button>
                <Button
                  sx={{ textTransform: 'none', ml: 1 }}
                  variant="outlined"
                  size="small"
                  startIcon={<ArrowBack />}
                  onClick={() => navigate(-1)}
                >
                  Back to Event
                </Button>
              </Box>
            </Box>
            <Paper sx={{ p: 4 }}>
              <Box sx={{ mt: 1 }}>
                {participantsDataLoading ? (
                  <ListLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Status</StyledTableCell>
                          <StyledTableCell align="left">
                            External
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Internal
                          </StyledTableCell>
                          <StyledTableCell align="left">Total</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participantsReport &&
                          statusFlags.map((status) => {
                            const internalCount =
                              participantsReport?.internal?.[status.flag]
                            const externalCount =
                              participantsReport?.external?.[status.flag]
                            const total =
                              parseInt(internalCount) + parseInt(externalCount)
                            return (
                              <React.Fragment key={status.flag}>
                                <StyledTableRow>
                                  <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                    {status.label}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={externalCount ? externalCount : 0}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={internalCount ? internalCount : 0}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={total ? total : 0}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Paper>
          </Card>
        </Box>
        <Box sx={{ mt: 2, width: '100%', height: '87vh' }}>
          <Card>
            <Box
              sx={{
                background: '#E6E7EA',
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Workshop and Mentorship Reports
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
            </Box>
            <Paper sx={{ p: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Workshop details by Seat
              </Typography>
              <Box sx={{ mt: 1 }}>
                {participantsDataLoading ? (
                  <ListLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Workshop Name
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Total Seats
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Available Seats
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            External
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Internal
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Total Booked
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participantsReport &&
                          participantsReport.workshops.map((engagement) => {
                            let internalCount = 0
                            let externalCount = 0

                            engagement.participants.forEach((participant) => {
                              externalCount =
                                externalCount + participant.external.length
                              internalCount =
                                internalCount + participant.internal.length
                            })

                            const total =
                              parseInt(internalCount) + parseInt(externalCount)
                            const availableSeats =
                              engagement.workshop.no_of_seats -
                              (internalCount + externalCount)

                            return (
                              <React.Fragment key={engagement.flag}>
                                <StyledTableRow>
                                  <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                    {engagement.workshop?.name}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={engagement.workshop.no_of_seats}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={availableSeats}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={externalCount}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={internalCount}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={total}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Paper>

            <Paper sx={{ p: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Workshop details by Status
              </Typography>
              <Box sx={{ mt: 1 }}>
                {participantsDataLoading ? (
                  <ListLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Workshop Name
                          </StyledTableCell>
                          {participantStatus.map((status) => (
                            <StyledTableCell align="left">
                              {status}
                            </StyledTableCell>
                          ))}
                          <StyledTableCell align="left">TOTAL</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participantsReport &&
                          participantsReport.workshops.map((engagement) => {
                            let totalUsers = 0
                            const countObj = {}
                            engagement.participants.forEach((participant) => {
                              participantStatus.forEach((status) => {
                                // eslint-disable-next-line default-case
                                switch (participant.status) {
                                  case status: {
                                    countObj[status] =
                                      participant.external.length +
                                      participant.internal.length
                                    break
                                  }
                                }
                              })
                            })
                            Object.keys(countObj).forEach((count) => {
                              totalUsers = totalUsers + countObj[count]
                            })
                            return (
                              <React.Fragment key={engagement.flag}>
                                <StyledTableRow>
                                  <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                    {engagement.workshop?.name}
                                  </StyledTableCell>
                                  {participantStatus.map((status) => (
                                    <StyledTableCell>
                                      <Chip
                                        sx={{ fontWeight: 'bold' }}
                                        label={
                                          countObj[status]
                                            ? countObj[status]
                                            : 0
                                        }
                                      />
                                    </StyledTableCell>
                                  ))}
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={totalUsers}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Paper>

            <Paper sx={{ p: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Workshop details by year of experience
              </Typography>
              <Box sx={{ mt: 1 }}>
                {participantsDataLoading ? (
                  <ListLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Workshop Name
                          </StyledTableCell>
                          {participantExpInYears.map((year) => (
                            <StyledTableCell align="left">
                              {year}
                            </StyledTableCell>
                          ))}
                          <StyledTableCell align="left">TOTAL</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {participantsReport &&
                          participantsReport.workshops.map((engagement) => {
                            let totalUsers = 0
                            const countObj = {
                              '0-3': 0,
                              '3-7': 0,
                              '7-12': 0,
                              '12+': 0,
                            }
                            engagement.participants.forEach((participant) => {
                              if (participant.external.length > 0) {
                                participant.external.forEach((user) => {
                                  let expYear = 1
                                  expYear = user.meta.exp?.to
                                    ? user.meta.exp?.to
                                    : user.meta.exp?.from
                                  if (expYear <= 3) {
                                    countObj['0-3'] = countObj['0-3'] + 1
                                  } else if (expYear > 3 && expYear <= 7) {
                                    countObj['3-7'] = countObj['3-7'] + 1
                                  } else if (expYear > 7 && expYear <= 12) {
                                    countObj['7-12'] = countObj['7-12'] + 1
                                  } else {
                                    countObj['12+'] = countObj['12+'] + 1
                                  }
                                })
                              }
                              if (participant.internal.length > 0) {
                                participant.internal.forEach((user) => {
                                  let expYear = 1
                                  expYear = user.meta.exp?.to
                                    ? user.meta.exp?.to
                                    : user.meta.exp?.from

                                  if (expYear <= 3) {
                                    countObj['0-3'] = countObj['0-3'] + 1
                                  } else if (expYear > 3 && expYear <= 7) {
                                    countObj['3-7'] = countObj['3-7'] + 1
                                  } else if (expYear > 7 && expYear <= 12) {
                                    countObj['7-12'] = countObj['7-12'] + 1
                                  } else {
                                    countObj['12+'] = countObj['12+'] + 1
                                  }
                                })
                              }
                            })
                            Object.keys(countObj).forEach((count) => {
                              totalUsers = totalUsers + countObj[count]
                            })
                            return (
                              <React.Fragment key={engagement.flag}>
                                <StyledTableRow>
                                  <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                    {engagement.workshop?.name}
                                  </StyledTableCell>
                                  {participantExpInYears.map((year) => (
                                    <StyledTableCell>
                                      <Chip
                                        sx={{ fontWeight: 'bold' }}
                                        label={countObj[year]}
                                      />
                                    </StyledTableCell>
                                  ))}
                                  <StyledTableCell>
                                    <Chip
                                      sx={{ fontWeight: 'bold' }}
                                      label={totalUsers}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Paper>

            <Paper sx={{ p: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Mentorship details by seat
              </Typography>
              <Box sx={{ mt: 1 }}>
                {participantsDataLoading ? (
                  <ListLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Mentorship Name
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Available slots
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Booked slots
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Free slots
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <React.Fragment key={mentorshipListData?._id}>
                          <StyledTableRow>
                            <StyledTableCell sx={{ fontWeight: 'bold' }}>
                              {mentorshipListData?.name}
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: 'bold' }}>
                              {mentorshipListData?.no_of_seats}
                            </StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: 'bold' }}>
                              {mentorshipListData?.confirmed}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Chip
                                sx={{ fontWeight: 'bold' }}
                                label={
                                  mentorshipListData?.no_of_seats -
                                  mentorshipListData?.confirmed
                                }
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Paper>

            <Paper sx={{ p: 4 }}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Mentorship details by speaker
              </Typography>
              <Box sx={{ mt: 1 }}>
                {participantsDataLoading ? (
                  <ListLoader />
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Speaker Name
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Available slots
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Booked slots
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Free slots
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Watiing list
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.sortBy(mentorshipSpeakerData, [
                          function (o) {
                            return o.member_data?.name
                          },
                        ]).map((speaker) => {
                          return (
                            <React.Fragment key={mentorshipListData._id}>
                              <StyledTableRow>
                                <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                  {speaker.member_data?.name}
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                  {speaker.sessions[0].slotData}
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                  {speaker.confirmed}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Chip
                                    sx={{ fontWeight: 'bold' }}
                                    label={
                                      speaker.sessions[0].slotData -
                                      speaker.confirmed
                                    }
                                  />
                                </StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: 'bold' }}>
                                  {speaker.waiting_list}
                                </StyledTableCell>
                              </StyledTableRow>
                            </React.Fragment>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Paper>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export default InvenueReports
