export const INITIAL_FILTER_VALUES = {
  page: 1,
  status: [],
  search: '',
  perpage: 15,
}

export const STATUS_OPTIONS = [
  // { label: 'Attended', value: 'ATTENDED' },
  { label: 'On-Hold', value: 'ON_HOLD' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'YTR', value: 'YET_TO_REGISTER' },
  { label: 'Registered', value: 'REGISTERED' },
  { label: 'Blocked', value: 'BLOCKED' },
]

export const STATUS_LABEL = {
  // ATTENDED: 'Attended',
  ON_HOLD: 'On-Hold',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  YET_TO_REGISTER: 'YTR',
  REGISTERED: 'Registered',
  BLOCKED: 'Blocked',
}

export const ADVANCED_PARTICIPANTS_FILTERS = [
  {
    title: 'By Company',
    icon: 'Business',
    flag: 'company',
    options: [
      { label: 'Target', value: 'target' },
      { label: 'Non-Target', value: 'non-target' },
    ],
  },
  {
    title: 'By Status',
    icon: 'CheckCircle',
    flag: 'status',
    options: STATUS_OPTIONS,
  },
  {
    title: 'Registration Date',
    icon: 'EventAvailable',
    flag: 'registered',
    type: 'date_period',
  },
  {
    title: 'Approved Date',
    icon: 'EventAvailable',
    flag: 'approved',
    type: 'date_period',
  },
  {
    title: 'By Experience (in years)',
    icon: 'Work',
    flag: 'experience',
    options: [
      { label: '0-3 Exp', value: '0-3' },
      { label: '3-6 Exp', value: '3-6' },
      { label: '6-9 Exp', value: '6-9' },
      { label: '9-15 Exp', value: '9-15' },
      { label: '15+ Exp', value: '15-50' },
    ],
  },
  {
    title: 'App Download Status',
    icon: 'PhoneIphone',
    flag: 'app_downloaded',
    type: 'single',
    options: [
      { label: 'Downloaded', value: 'true' },
      { label: 'Pending Download', value: 'false' },
      { label: 'All', value: '' },
    ],
  },
]

export const LABEL_OF_EMAILER = {
  SAVE_THE_DATE_MAILER: 'Save the date mailer',
  NEW_YEAR_RESOLUTION_EMAILER: 'New year resolution emailer',
  REJECTION_EMAILER: 'Rejection Emailer',
  OTP_MAILER: 'OTP Mailer',
  REMINDER_TO_REGISTER_EMAILER: 'Reminder to register mailer',
  WHY_ATTEND_EMAILER: 'why attend mailer',
  DOWNLOAD_APP_EMAILER: 'Download app reminder mailer',
  SPEAKER_MAILER_1_APPROVED: 'Speaker mailer 1 (approved)',
  SPEAKER_MAILER_1_YTR: 'Speaker mailer 1 (ytr)',
  MENTORSHIP_YTR: 'Mentorship (YTR)',
  MENTORSHIP_APPROVED: 'Mentorship (Approved)',
  SPEAKER_MAILER_2_APPROVED: 'Speaker mailer 2 (approved)',
  SPEAKER_MAILER_2_YTR: 'Speaker mailer 2 (YTR)',
  TRIVIA_WINNER_EMAILER: 'Winner mailer (Trivia)',
  WORDSEARCH_WINNER_EMAILER: 'Winner mailer (Word Search)',
  SPEAKER_MAILER_3_YTR: 'Speaker mailer 3 (YTR)',
  SPEAKER_MAILER_3_APPROVED: 'Speaker mailer 3 (APPROVED)',
  FINAL_REMINDER_MAILER: 'Final reminder mailer (YTR)',
  SPEAKER_MAILER_4_APPROVED: 'Speaker mailer 4 Approved',
  SPEAKER_MAILER_4_YTR: 'Speaker mailer 4 (YTR)',
  AGENDA_MAILER_APPROVED: 'Agenda (Approved)',
  AGENDA_MAILER_YTR: 'Agenda (YTR)',
  SM_MAILER_APPROVED: 'SM template mailer (Approved)',
  MAKE_THE_MOST_EMAILER: 'Make the most of mailer (Approved)',
  QR_CODE_MAILER: 'See you soon mailer (Approved)',
  JIGSAW_WINNER_MAILER: 'Winner mailer (Jigsaw)',
  THANK_YOU_MAILER: 'Thank you mailer (Attended)',
}

export const EMAIL_STATUS_LABELS = {
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
}
