import React, { useEffect, useState } from 'react'
import SortableMenu from './Common/SortableMenu'
import ViewMenuData from './Common/ViewMenuData'
import PageList from './Common/PageList'
import AddLink from './Common/AddLink'
import {
  addNodeUnderParent,
  removeNodeAtPath,
  insertNode,
  changeNodeAtPath,
} from 'react-sortable-tree'
import MenuItems from './Common/MenuItems'
import {
  Box,
  FormControlLabel,
  Switch,
  Button,
  LinearProgress,
} from '@mui/material'
import { toggleExpandedForAll } from 'react-sortable-tree'
import _ from 'lodash'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import PublicIcon from '@mui/icons-material/Public'
import SaveIcon from '@mui/icons-material/Save'
import ConfirmationDialog from './Common/ConfirmationDialog'
import { useSaveMenuMutation, usePublishMenuMutation } from '../../api/menu'
import { useSelector } from 'react-redux'
import EditMenuData from './Common/EditMenuData'

export default function HeaderTree(props) {
  const { data, onChange, superUser, permittedActions, loading } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [selectedNode, setSelectedNode] = useState({})
  const [dataDialogOpen, setDataDialogOpen] = useState(false)
  const [editMenuOpen, setEditMenuOpen] = useState(false)
  const [selectedMenuForView, setSelectedMenuForView] = useState({})
  const [position, setPosition] = useState('')
  const [pageAddDialogOpen, setPageAddDialogOpen] = useState(false)
  const [linkAddDialogOpen, setLinkAddDialogOpen] = useState(false)
  const [resetAddDialog, setResetAddDialog] = useState(false)
  const [resetLinkDialog, setResetLinkDialog] = useState(false)
  const [menuData, setMenuData] = useState([])
  const [publishedMenu, setPublishedMenu] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [saveMenu, { isLoading: saveMenuLoading }] = useSaveMenuMutation()
  const [publishMenu, { isLoading: publishMenuLoading }] =
    usePublishMenuMutation()

  useEffect(() => {
    if (data) {
      setMenuData(data.value)
    }
  }, [data])

  useEffect(() => {
    if (publishedMenu) {
      setMenuData(
        toggleExpandedForAll({
          treeData: data.published_value,
          expanded: true,
        }),
      )
    } else {
      setMenuData(data.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedMenu])

  const handleClick = (event, node, type) => {
    setSelectedNode({
      selectedNode: node,
      type,
    })
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const deleteNode = (selected) => {
    let { path } = selected
    const updated = removeNodeAtPath({
      treeData: menuData,
      path: path,
      getNodeKey: ({ treeIndex: number }) => {
        return number
      },
      ignoreCollapsed: true,
    })
    setMenuData(updated)
  }

  const viewData = (node) => {
    setSelectedMenuForView(node)
    setDataDialogOpen(true)
  }

  const handleEdit = (node) => {
    setSelectedMenuForView(node)
    setEditMenuOpen(true)
  }

  const addMenuUnderParent = (selected, newNode) => {
    const { selectedNode } = selected
    let { path } = selectedNode
    const updated = addNodeUnderParent({
      treeData: menuData,
      parentKey: path[path.length - 1],
      expandParent: true,
      newNode: newNode,
      getNodeKey: ({ treeIndex }) => treeIndex,
    }).treeData
    setMenuData(updated)
    closePageAdd()
    closeLinkAdd()
  }

  const addMenuLevelToNode = (selected, newNode) => {
    const { selectedNode } = selected
    let { path } = selectedNode
    path.pop()
    const updated = addNodeUnderParent({
      treeData: menuData,
      parentKey: path[path.length - 1],
      expandParent: true,
      newNode: newNode,
      getNodeKey: ({ treeIndex }) => treeIndex,
    }).treeData
    setMenuData(updated)
    closePageAdd()
    closeLinkAdd()
  }

  const addFirstMenu = (data) => {
    const updated = insertNode({
      treeData: menuData,
      depth: 0,
      newNode: data,
    }).treeData
    setMenuData(updated)
    closePageAdd()
    closeLinkAdd()
  }

  const addPageOrLink = (data) => {
    switch (position) {
      case 'inside':
        addMenuUnderParent(selectedNode, data)
        break
      case 'level':
        addMenuLevelToNode(selectedNode, data)
        break
      default:
        addFirstMenu(data)
        break
    }
  }

  const closePageAdd = () => {
    setSelectedNode({})
    setResetAddDialog(true)
    setPageAddDialogOpen(false)
  }

  const closeLinkAdd = () => {
    setSelectedNode({})
    setResetLinkDialog(true)
    setLinkAddDialogOpen(false)
  }

  const addNew = (val) => {
    if (val === 'page') {
      setPageAddDialogOpen(true)
    }
    if (val === 'link') {
      setLinkAddDialogOpen(true)
    }
    setSelectedNode({})
  }

  const saveMenuLocally = () => {
    saveMenu({
      menu: {
        name: 'header',
        value: menuData,
      },
      activeSite,
      id: data._id ? data._id : null,
    })
  }

  const publishTheMenuAndSave = () => {
    if (data._id) {
      publishMenu(data._id)
      setConfirmDialog(false)
    }
  }

  const updateMenuData = (data, nodeData) => {
    const updated = changeNodeAtPath({
      treeData: menuData,
      path: nodeData.path,
      newNode: data,
      getNodeKey: ({ treeIndex: number }) => {
        return number
      },
      ignoreCollapsed: true,
    })
    setMenuData(updated)
    setEditMenuOpen(false)
  }

  return (
    <div
      style={{
        height: '65vh',
        padding: 2,
        pt: 0,
        overflow: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={publishedMenu}
                onChange={(_) => setPublishedMenu(!publishedMenu)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#5FBF5F',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#5FBF5F',
                  },
                }}
              />
            }
            label={
              publishedMenu ? 'Hide Published Menu' : 'Show Published Menu'
            }
          />
        </Box>
        <Button
          variant="contained"
          sx={{ textTransform: 'none', ml: 2, backgroundColor: '#CC0000' }}
          size="small"
          startIcon={<SaveIcon />}
          disabled={
            !checkUserIsAllowed('Edit', permittedActions, superUser) ||
            publishedMenu ||
            saveMenuLoading
          }
          onClick={saveMenuLocally}
        >
          Save Menu Locally
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            ml: 2,
            color: '#CC0000',
            borderColor: '#CC0000',
          }}
          size="small"
          startIcon={<PublicIcon />}
          disabled={
            !checkUserIsAllowed('Publish', permittedActions, superUser) ||
            publishedMenu ||
            _.isEqual(data.published_value, menuData) ||
            publishMenuLoading
          }
          onClick={() => setConfirmDialog(true)}
        >
          Publish Changes
        </Button>
      </Box>
      <Box sx={{ width: '100%' }}>
        {(publishMenuLoading || saveMenuLoading) && <LinearProgress />}
      </Box>
      <SortableMenu
        treeData={menuData}
        onChange={onChange}
        handleAddClick={handleClick}
        viewNode={viewData}
        handleEdit={handleEdit}
        deleteNode={deleteNode}
        addNew={addNew}
        superUser={superUser}
        permittedActions={permittedActions}
        readOnly={publishedMenu}
        loading={loading}
      />
      <MenuItems
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedNode={selectedNode}
        setPosition={setPosition}
        openPageAdd={() => setPageAddDialogOpen(true)}
        openLinkAdd={() => setLinkAddDialogOpen(true)}
      />
      <ViewMenuData
        open={dataDialogOpen}
        handleClose={() => setDataDialogOpen(false)}
        data={selectedMenuForView}
      />
      <EditMenuData
        open={editMenuOpen}
        handleClose={() => setEditMenuOpen(false)}
        data={selectedMenuForView}
        handleEdit={updateMenuData}
      />
      <PageList
        open={pageAddDialogOpen}
        handleClose={closePageAdd}
        addPage={addPageOrLink}
        reset={resetAddDialog}
        setReset={setResetAddDialog}
      />
      <AddLink
        open={linkAddDialogOpen}
        handleClose={closeLinkAdd}
        addLink={addPageOrLink}
        reset={resetLinkDialog}
        setReset={setResetLinkDialog}
      />
      <ConfirmationDialog
        title={'Do you really want to publish the menu to public ?'}
        message={
          'By clicking publish your data changes on the menu will be published to public site.'
        }
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onConfirm={publishTheMenuAndSave}
      />
    </div>
  )
}
