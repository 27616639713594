import React, { useEffect, useRef } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  FormDateField,
  FormFieldLoader,
  FormMediaField,
  FormMultiSelect,
  FormTextField,
} from '../FormComponents'
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useEditEventMutation } from '../../../api/event'
import { useSelector } from 'react-redux'
import { Placeholder, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { useSnackbar } from 'notistack'
import { Check, Delete, Edit } from '@mui/icons-material'
import { snakeCaseify } from '../../../utils/helper'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

const checkThumbnailExist = {
  is: (file) => file,
  then: (schema) => schema.required('Field is required'),
}

let eventSchema = yup.object().shape({
  type: yup.string().required(),
  name: yup
    .string()
    .typeError('This is a required field')
    .required('This is a required field'),
  year: yup
    .date()
    .typeError('Enter a valid year')
    .required('Enter a valid year'),
  start_date: yup
    .date()
    .typeError('Enter a valid date')
    .nullable()
    .required('Enter a valid date')
    .test(
      'is_same_year',
      'Start Date should be within the event year',
      (value, context) =>
        moment(value).isSame(moment(context.parent.year), 'year'),
    ),
  end_date: yup
    .date()
    .typeError('Enter a valid date')
    .nullable()
    .required('Enter a valid date')
    .min(yup.ref('start_date'), 'Event end date must be later than start date')
    .test(
      'is_same_year',
      'End Date should be within the event year',
      (value, context) =>
        moment(value).isSame(moment(context.parent.year), 'year'),
    ),
  reg_start_date: yup
    .date()
    .typeError('Enter a valid date')
    .optional()
    .nullable()
    .when('type', {
      is: 'online',
      then: (schema) => schema.optional(),
      otherwise: (schema) =>
        schema.max(
          yup.ref('start_date'),
          'Registration start date must be earlier than event start date',
        ),
    }),
  reg_end_date: yup
    .date()
    .typeError('Enter a valid date')
    .optional()
    .nullable()
    .when('type', {
      is: 'online',
      then: (schema) => schema.optional(),
      otherwise: (schema) =>
        schema.min(
          yup.ref('reg_start_date'),
          'Registration end date must be later than registration start date',
        ),
    })
    .when('type', {
      is: 'online',
      then: (schema) => schema.optional(),
      otherwise: (schema) =>
        schema.max(
          yup.ref('start_date'),
          'Registration end date must be later than event start date',
        ),
    }),
  description: yup.string().optional(),
  venue: yup.string().optional(),
  zoom_link: yup.string().optional(),
  start_time: yup.date().optional().nullable(),
  end_time: yup.date().optional().nullable(),
  activities: yup.array().optional(),
  display_participant_on_reg: yup.boolean().optional(),
  thumbnail_image: yup.string().optional(),
  thumbnail_alt_text: yup.string().when('thumbnail_image', checkThumbnailExist),
  thumbnail_title: yup.string().when('thumbnail_image', checkThumbnailExist),
  restrictions: yup.object().shape({
    workshop_bookings_per_person: yup
      .number()
      .required('Booking per person is required')
      .moreThan(0, 'Booking per person should be more than 0'),
    mentorship_bookings_per_person: yup
      .number()
      .required('Booking per person is required')
      .moreThan(0, 'Booking per person should be more than 0'),
  }),
})

const DEFAULT_VALUES = {
  name: '',
  type: 'hybrid',
  year: null,
  start_date: null,
  end_date: null,
  reg_start_date: null,
  reg_end_date: null,
  venue: '',
  zoom_link: '',
  start_time: null,
  end_time: null,
  description: '',
  display_participant_on_reg: false,
  display_reg_vs_approved: false,
  thumbnail_image: '',
  thumbnail_alt_text: '',
  thumbnail_title: '',
  activities: [],
  restrictions: {
    workshop_bookings_per_person: 1,
    mentorship_bookings_per_person: 1,
  },
}

export const FormForEdit = (props) => {
  const {
    customFields,
    setCustomFields,
    reset,
    setResetFormStatus,
    initialValues = {},
  } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [editEvent, { isLoading, isSuccess }] = useEditEventMutation()
  const { enqueueSnackbar } = useSnackbar()
  const resetRef = useRef()
  const [editFields, setEditFields] = React.useState([])
  const [customNames, setCustomNames] = React.useState({})
  const [clearFields, setClearFields] = React.useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (reset) {
      resetRef.current.click()
      setResetFormStatus(false)
    }
  }, [reset, resetRef, setResetFormStatus])

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Event edited successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      navigate('/events')
    }
  }, [enqueueSnackbar, isSuccess, navigate])

  const addName = (key, value, setValues, values) => {
    if (customFields[key].name !== customNames?.[key]) {
      setClearFields([...clearFields, customFields[key].name])
    }
    setCustomFields([
      ...customFields.slice(0, key),
      {
        ...customFields[key],
        name: customNames?.[key],
        slug: `_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`,
      },
      ...customFields.slice(key + 1),
    ])
    setEditFields([...editFields.filter((val) => val !== key)])
    setValues(
      {
        ...values,
        [`_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`]:
          value,
      },
      value,
    )
  }

  const deleteCustomField = (key) => {
    setCustomFields([
      ...customFields.slice(0, key),
      ...customFields.slice(key + 1),
    ])
  }

  const filterUnusedCustomFields = (values) => {
    const customFieldSlugs = _.map(customFields, 'slug')
    const shouldBeRemoved = Object.keys(values).filter(
      (key) => key.charAt(0) === '_' && !customFieldSlugs.includes(key),
    )
    return _.omit(values, shouldBeRemoved)
  }

  return (
    <>
      {Object.keys(initialValues).length > 0 && (
        <Formik
          initialValues={{
            ...DEFAULT_VALUES,
            ...initialValues,
          }}
          validationSchema={eventSchema}
          onSubmit={(values) => {
            const filteredValues = filterUnusedCustomFields(values)
            editEvent({
              event: {
                ...filteredValues,
                customFields,
                year:
                  typeof values?.year?.year === 'function'
                    ? values?.year.year()
                    : values?.year.getFullYear(),
              },
              activeSite,
            })
          }}
        >
          {(props) => (
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Form className="formik-form">
                <Box sx={{ flexGrow: 1, p: 1, pl: 3 }}>
                  {isLoading && <ProgressBar indeterminate />}
                  <Field name="type">
                    {({ field, form, meta }) => (
                      <RadioGroup
                        row
                        name="type"
                        {...field}
                        sx={{
                          '& .Mui-checked': {
                            color: '#CC0000',
                          },
                        }}
                      >
                        <FormControlLabel
                          value="hybrid"
                          control={<Radio size="small" />}
                          label="Hybrid"
                        />
                        <FormControlLabel
                          value="in_person"
                          control={<Radio size="small" />}
                          label="In-Person"
                        />
                        <FormControlLabel
                          value="online"
                          control={<Radio size="small" />}
                          label="Online"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    p: 1,
                    pl: 3,
                    height: '70vh',
                    overflow: 'auto',
                  }}
                >
                  <Grid container spacing={1}>
                    {/* Left Section */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h4"
                        sx={{ fontSize: 20, fontWeight: 700, mb: 1 }}
                      >
                        Event Basic info
                      </Typography>
                      <Box
                        sx={{
                          border: '2px dashed #B5B5B5',
                          p: 2,
                          minHeight: 600,
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Grid item xs={6}>
                            <Field
                              type="text"
                              name="name"
                              label="Event Name/ Tittle*"
                              placeholder="Enter name"
                              autoComplete="off"
                              component={FormTextField}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name="year"
                              views={['year']}
                              type="date"
                              placeholder="Select year"
                            >
                              {({ field, form, meta }) => (
                                <FormDateField
                                  {...field}
                                  {...form}
                                  label="Event Year"
                                  onChange={(value) => {
                                    form.setFieldValue(
                                      'year',
                                      moment(value).toDate(),
                                    )
                                  }}
                                  views={['year']}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name="start_date"
                              placeholder="Select start date"
                            >
                              {({ field, form, meta }) => (
                                <FormDateField
                                  {...field}
                                  {...form}
                                  label="Event Start date*"
                                  onChange={(value) => {
                                    form.setFieldValue(
                                      'start_date',
                                      moment(value).toDate(),
                                    )
                                  }}
                                  onBlur={() => {
                                    form.setFieldTouched('start_date', true)
                                  }}
                                  views={['day']}
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name="end_date"
                              placeholder="Select end date"
                            >
                              {({ field, form, meta }) => (
                                <FormDateField
                                  {...field}
                                  {...form}
                                  label="Event End date*"
                                  onChange={(value) => {
                                    form.setFieldValue(
                                      'end_date',
                                      moment(value).toDate(),
                                    )
                                  }}
                                  views={['day']}
                                />
                              )}
                            </Field>
                          </Grid>
                          {props.values.type !== 'online' && (
                            <Grid item xs={6}>
                              <Field
                                name="reg_start_date"
                                placeholder="Select registration start date"
                              >
                                {({ field, form, meta }) => (
                                  <FormDateField
                                    {...field}
                                    {...form}
                                    label="Registration Start date"
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        'reg_start_date',
                                        moment(value).toDate(),
                                      )
                                    }}
                                    views={['day']}
                                  />
                                )}
                              </Field>
                            </Grid>
                          )}
                          {props.values.type !== 'online' && (
                            <Grid item xs={6}>
                              <Field
                                name="reg_end_date"
                                placeholder="Select registration end date"
                              >
                                {({ field, form, meta }) => (
                                  <FormDateField
                                    {...field}
                                    {...form}
                                    label="Registration End date"
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        'reg_end_date',
                                        moment(value).toDate(),
                                      )
                                    }}
                                    views={['day']}
                                  />
                                )}
                              </Field>
                            </Grid>
                          )}
                          {props.values.type !== 'online' && (
                            <Grid item xs={6}>
                              <Field
                                type="text"
                                name="venue"
                                label="Event Venue"
                                placeholder="Enter venue"
                                autoComplete="off"
                                component={FormTextField}
                              />
                            </Grid>
                          )}
                          {props.values.type !== 'online' && (
                            <Grid item xs={6}>
                              <Field
                                type="number"
                                name="no_of_seats"
                                label="No. of  Seats"
                                placeholder="Enter number of seats"
                                autoComplete="off"
                                component={FormTextField}
                              />
                            </Grid>
                          )}
                          {props.values.type !== 'in_person' && (
                            <Grid item xs={12}>
                              <Field
                                type="url"
                                name="zoom_link"
                                label="Zoom Link"
                                placeholder="Paste your Zoom link url here"
                                autoComplete="off"
                                component={FormTextField}
                              />
                            </Grid>
                          )}
                          {props.values.type === 'online' && (
                            <Grid item xs={6}>
                              <Field
                                name="start_time"
                                placeholder="Select event start time"
                              >
                                {({ field, form, meta }) => (
                                  <FormDateField
                                    {...field}
                                    {...form}
                                    type="time"
                                    label="Event Start Time"
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        'start_time',
                                        moment(value).toDate(),
                                      )
                                    }}
                                  />
                                )}
                              </Field>
                            </Grid>
                          )}
                          {props.values.type === 'online' && (
                            <Grid item xs={6}>
                              <Field
                                name="end_time"
                                placeholder="Select event end time"
                              >
                                {({ field, form, meta }) => (
                                  <FormDateField
                                    {...field}
                                    {...form}
                                    type="time"
                                    label="Event End Time"
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        'end_time',
                                        moment(value).toDate(),
                                      )
                                    }}
                                  />
                                )}
                              </Field>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Field
                              type="text"
                              name="description"
                              label="Description"
                              placeholder="Enter description"
                              autoComplete="off"
                              multiline
                              rows={4}
                              component={FormTextField}
                            />
                          </Grid>
                          {props.values.activities.length > 0 && (
                            <Grid item xs={12} sx={{ p: 1 }}>
                              <Typography
                                variant="h4"
                                sx={{
                                  fontSize: 20,
                                  fontWeight: 700,
                                  mb: 3,
                                  ml: 3,
                                }}
                              >
                                Booking Restrictions
                              </Typography>
                              <Grid
                                container
                                sx={{
                                  display: 'flex',
                                }}
                              >
                                {props.values.activities.includes(
                                  'Workshop',
                                ) && (
                                  <Grid item xs={6}>
                                    <Field
                                      type="number"
                                      name="restrictions.workshop_bookings_per_person"
                                      label="Workshop Bookings per person"
                                      placeholder="Enter number of bookings"
                                      autoComplete="off"
                                      component={FormTextField}
                                    />
                                    {props.errors.restrictions &&
                                    props.touched.restrictions ? (
                                      <Box
                                        sx={{
                                          color: '#d32f2f',
                                          fontSize: '12px',
                                          mt: -2,
                                          ml: 3,
                                        }}
                                      >
                                        {
                                          props.errors.restrictions
                                            .workshop_bookings_per_person
                                        }
                                      </Box>
                                    ) : null}
                                  </Grid>
                                )}
                                {props.values.activities.includes(
                                  'Mentorship',
                                ) && (
                                  <Grid item xs={6}>
                                    <Field
                                      type="number"
                                      name="restrictions.mentorship_bookings_per_person"
                                      label="Mentorship Bookings per person"
                                      placeholder="Enter number of bookings"
                                      autoComplete="off"
                                      component={FormTextField}
                                    />
                                    {props.errors.restrictions &&
                                    props.touched.restrictions ? (
                                      <Box
                                        sx={{
                                          color: '#d32f2f',
                                          fontSize: '12px',
                                          mt: -2,
                                          ml: 3,
                                        }}
                                      >
                                        {
                                          props.errors.restrictions
                                            .mentorship_bookings_per_person
                                        }
                                      </Box>
                                    ) : null}
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                    {/* Right Section */}
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="h4"
                        sx={{ fontSize: 20, fontWeight: 700, mb: 1 }}
                      >
                        Event Content info
                      </Typography>
                      <Box
                        sx={{
                          border: '2px dashed #B5B5B5',
                          p: 2,
                          minHeight: 600,
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Grid item xs={12}>
                            <Field
                              name="activities"
                              label="Add Activities*"
                              placeholder="Select activities"
                              autoComplete="off"
                              component={FormMultiSelect}
                              chipColor={'success'}
                              options={['Workshop', 'Mentorship']}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ p: 2 }}>
                            <Grid
                              container
                              sx={{
                                border: '2px dashed #D6D6D6',
                                background: '#F0F0F0',
                              }}
                            >
                              <Grid item xs={12} md={4}>
                                <Field
                                  name="thumbnail_image"
                                  label="Upload (or) Drag Thumbnail Image Here"
                                  component={FormMediaField}
                                />
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                  }}
                                >
                                  <Field
                                    type="text"
                                    name="thumbnail_alt_text"
                                    label="Alt Text"
                                    placeholder="Enter alt text for the image"
                                    autoComplete="off"
                                    component={FormTextField}
                                  />
                                  <Field
                                    type="text"
                                    name="thumbnail_title"
                                    label="Title"
                                    placeholder="Enter title for the image"
                                    autoComplete="off"
                                    component={FormTextField}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {customFields && customFields.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="h4"
                          sx={{ fontSize: 20, fontWeight: 700, mb: 1 }}
                        >
                          Custom Fields
                        </Typography>
                        <Box
                          sx={{
                            border: '2px dashed #B5B5B5',
                            p: 2,
                            minHeight: 630,
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              display: 'flex',
                            }}
                          >
                            {customFields.map((customField, key) => {
                              return (
                                <Grid item xs={12} key={key}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {editFields.includes(key) ? (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <TextField
                                          sx={{ border: 0, ml: 1 }}
                                          size="small"
                                          placeholder="Enter field name"
                                          value={customNames?.[key]}
                                          onChange={(e) =>
                                            setCustomNames({
                                              ...customNames,
                                              [key]: e.target.value,
                                            })
                                          }
                                        />
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            addName(
                                              key,
                                              props.values?.[
                                                `_${snakeCaseify(
                                                  customField.name,
                                                )}_${customField.type}`
                                              ],
                                              props.setValues,
                                              props.values,
                                            )
                                          }}
                                        >
                                          <Check />
                                        </IconButton>
                                      </Box>
                                    ) : (
                                      <Button
                                        sx={{ textTransform: 'none' }}
                                        size="small"
                                        onClick={() =>
                                          setEditFields([...editFields, key])
                                        }
                                      >
                                        <FormLabel sx={{ ml: 1 }}>
                                          {customField.name
                                            ? customField.name
                                            : 'Add name for the field'}
                                        </FormLabel>
                                        <Edit sx={{ fontSize: 16, ml: 1 }} />
                                      </Button>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      mb: 2,
                                    }}
                                  >
                                    {customField?.name ? (
                                      <>
                                        {customField.type === 'date' ? (
                                          <Field
                                            name={`_${snakeCaseify(
                                              customField.name,
                                            )}_${customField.type}`}
                                            type="date"
                                            placeholder="Select"
                                          >
                                            {({ field, form, meta }) => (
                                              <FormDateField
                                                {...field}
                                                {...form}
                                                sx={{ p: 1 }}
                                                onChange={(value) => {
                                                  form.setFieldValue(
                                                    field.name,
                                                    moment(value).toDate(),
                                                  )
                                                }}
                                              />
                                            )}
                                          </Field>
                                        ) : (
                                          <Field
                                            autoComplete="off"
                                            name={`_${snakeCaseify(
                                              customField.name,
                                            )}_${customField.type}`}
                                            aria-label={customField.name}
                                            disabled={!customField.name}
                                            component={FormFieldLoader}
                                            sx={{ p: 1, pb: 1 }}
                                            type={customField.type}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <Placeholder
                                        style={{
                                          flexGrow: 1,
                                          marginLeft: 8,
                                          marginTop: 3,
                                        }}
                                      >
                                        <Placeholder.Rect
                                          height="50px"
                                          emphasized
                                        />
                                      </Placeholder>
                                    )}
                                    <IconButton
                                      size="small"
                                      sx={{ height: 36, width: 36 }}
                                      onClick={() => deleteCustomField(key)}
                                    >
                                      <Delete sx={{ fontSize: 20 }} />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    background: '#fff',
                    p: 1,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      height: 35,
                    }}
                    color="secondary"
                    type="submit"
                    className="btn btn-primary btn-block mt-4"
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      height: 35,
                      mr: 1,
                    }}
                    className="btn btn-primary btn-block mt-4"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="reset"
                    sx={{
                      display: 'none',
                    }}
                    ref={resetRef}
                  >
                    reset
                  </Button>
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      )}
    </>
  )
}

export default FormForEdit
