import { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fade,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import withRights from '../../../components/withRights'
import Grow from '@mui/material/Grow'
import MetricsCard from '../../Dashboard/Widgets/MetricsCard'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import PagesIcon from '@mui/icons-material/Pages'
import ArticleIcon from '@mui/icons-material/Article'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined'
import AdditionalDataTable from '../MetricComponents/AdditionalDataTable'
import FrequentlyUsedComponentsCard from '../../Dashboard/Widgets/FrequentlyUsedComponentsCard'
import { ArrowDownward } from '@mui/icons-material'

const ProductMetrics = (props) => {
  const { data = {} } = props
  const [selectedData, setSelectedData] = useState({
    key: '',
    data: null,
    title: '',
  })

  const metricData = [
    {
      name: 'tenantOnBoarded',
      bottomText: 'Tenants onboarded to Stencil',
      value: 'NA',
      type: 'card',
      additional: 'tenantInfo',
      color: '#27633B',
    },
    {
      name: 'usersOnBoarded',
      bottomText: 'Users onboarded to Stencil',
      value: 'NA',
      type: 'card',
      icon: <GroupAddIcon />,
      additional: 'usersCountByTenants',
      color: '#2951A3',
    },
    {
      name: 'pagesCreated',
      bottomText: 'Pages created',
      value: 'NA',
      type: 'card',
      icon: <PagesIcon />,
      additional: 'pagesByTenants',
      color: '#CC0000',
    },
    {
      name: 'pagesPublished',
      bottomText: 'Pages published so far',
      value: 'NA',
      type: 'card',
      icon: <PublishedWithChangesOutlinedIcon />,
      additional: 'pagesPublishByTenants',
      color: '#EDB508',
    },
    {
      name: 'postsCreated',
      bottomText: 'Posts created',
      value: 'NA',
      type: 'card',
      icon: <ArticleIcon />,
      additional: 'postsByTenants',
      color: '#4318FF',
    },
    {
      name: 'postsPublished',
      bottomText: 'Blog posts published so far',
      value: 'NA',
      type: 'card',
      icon: <PublishedWithChangesOutlinedIcon />,
      additional: 'postsPublishByTenants',
      color: '#2951A3',
    },
    {
      name: 'filesUploaded',
      bottomText: 'Files uploaded',
      value: 'NA',
      type: 'card',
      icon: <FileUploadIcon />,
      additional: 'filesCountByTenants',
      color: '#27633B',
    },
  ]

  return (
    <>
      <Grow in={true}>
        <Box>
          <Accordion elevation={0} defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              sx={{ borderBottom: '1px solid #CCCCCC' }}
              aria-controls="product-metrics-controls"
              id="product-metrics-panel"
              data-testid="product-metrics-panel"
            >
              <Typography
                variant="h5"
                component="h4"
                sx={{ fontWeight: 500 }}
                data-testid="prd-component-heading"
              >
                Product Metrics
              </Typography>
              <Divider />
            </AccordionSummary>
            <AccordionDetails>
              <Box my={2} p={2} sx={{ background: '#F6F7F8', borderRadius: 2 }}>
                <Stack direction="row" flexWrap="wrap" gap="10px">
                  {metricData.map((metricDetails) => (
                    <Box
                      maxHeight={150}
                      maxWidth={300}
                      key={metricDetails.name}
                      sx={{
                        flex: '1 0 200px',
                      }}
                    >
                      <MetricsCard
                        title={metricDetails.bottomText}
                        value={
                          data[metricDetails.name]
                            ? data[metricDetails.name]
                            : metricDetails.value
                        }
                        color={metricDetails.color}
                        // isLoading={isMetricsLoading}
                        isSelected={selectedData.key === metricDetails.name}
                        onSelect={() =>
                          setSelectedData(
                            selectedData.key === metricDetails.name
                              ? { key: '', data: null, background: '' }
                              : {
                                  key: metricDetails.name,
                                  data: data[metricDetails.additional]
                                    ? data[metricDetails.additional]
                                    : null,
                                  background: metricDetails.currentGradient,
                                  title: metricDetails.bottomText,
                                },
                          )
                        }
                      />
                    </Box>
                  ))}
                </Stack>
                {selectedData?.data && (
                  <Grow
                    in={selectedData?.data !== null}
                    timeout={{ enter: 1000, exit: 4000 }}
                  >
                    <Box
                      mt={2}
                      sx={{
                        p: 3,
                        display: 'flex',
                        background: '#FFFFFF',
                        borderRadius: 2,
                      }}
                    >
                      <Fade
                        in={selectedData?.data !== null}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(selectedData?.data ? { timeout: 1000 } : {})}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Box sx={{ width: '50%' }}>
                          <AdditionalDataTable {...selectedData} />
                        </Box>
                      </Fade>
                    </Box>
                  </Grow>
                )}
                <Grid container spacing={2} mt={0}>
                  <Grid item xs={12} sm={6}>
                    <FrequentlyUsedComponentsCard />
                  </Grid>
                  <Grid item xs={12} sm={6}></Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grow>
    </>
  )
}

const moduleAttr = {
  name: 'ProductMetrics',
  actions: [],
}

export default withRights(ProductMetrics, moduleAttr)
