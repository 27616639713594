import React from 'react'
import { Box, Paper, InputBase, MenuItem, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '2px solid #91b6f9',
    background: '#f6f6f6',
    padding: '20px',
    maxWidth: 500,
    borderRadius: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#91b6f9',
  },
}))

const formatValue = (received, type) => {
  if (!received) {
    return type === 'point' ? '%' : 0
  }
  const pxPresence = typeof received === 'string' && received.indexOf('px') > -1
  const percentPresence =
    typeof received === 'string' && received.indexOf('%') > -1
  let formatted = received
  let pointSystem = ''

  if (pxPresence) {
    // setSelectedPoint('px')
    pointSystem = 'px'
    formatted = formatted.replace('px', '')
  }
  if (percentPresence) {
    // setSelectedPoint('%')
    pointSystem = '%'
    formatted = formatted.replace('%', '')
  }
  return type === 'point' ? pointSystem : parseFloat(formatted)
}

export default function SpacingControl(props) {
  const {
    name = '',
    disabled = false,
    value = 0,
    type = 'm',
    onChange,
    slug = '',
    parentSlug = '',
    linked = 'unlinked',
    // allSides = [],
  } = props

  const [selectedPoint, setSelectedPoint] = React.useState('%')
  const [valueFormatted, setValue] = React.useState(0)

  React.useEffect(() => {
    setValue(formatValue(value, 'val'))
  }, [value])

  React.useEffect(() => {
    setSelectedPoint(formatValue(value, 'point'))
  }, [value])

  // const onChangeLinked = (value) => {
  //   if (allSides.length > 0) {
  //     allSides.map((side) => {
  //       console.log(`${parentSlug}.${side}.${slug.split('.')?.[1]}`)
  //       onChange(value, `${parentSlug}.${side}.${slug.split('.')?.[1]}`)
  //       // return 0
  //     })
  //   }
  // }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent:
          type === 'm'
            ? name === 'ml'
              ? 'flex-start'
              : name === 'mr'
              ? 'flex-end'
              : 'center'
            : name === 'pl'
            ? 'flex-start'
            : name === 'pr'
            ? 'flex-end'
            : 'center',
        minHeight: 30,
      }}
    >
      <Paper
        component="form"
        sx={{
          p: 0,
          display: 'flex',
          alignItems: 'center',
          maxWidth: 110,
          border: '1px solid #d3d3d3',
          borderRadius: 0,
          boxShadow: 1,
          height: 35,
          position: 'relative',
        }}
      >
        <InputBase
          sx={{ ml: 1, mr: 1, p: 0, fontSize: 13 }}
          size="small"
          placeholder="Enter size"
          type="number"
          endAdornment={<></>}
          disabled={disabled}
          value={valueFormatted}
          onChange={(e) => {
            let updatedValue = null
            if (!selectedPoint) {
              updatedValue = parseFloat(e.target.value)
            } else {
              updatedValue = `${e.target.value}${selectedPoint}`
            }
            if (linked !== 'linked') {
              onChange(updatedValue, `${parentSlug}.${slug}`)
            } else {
              // onChangeLinked(updatedValue, `${parentSlug}.${slug}`)
            }
          }}
        />
        <TextField
          size="small"
          sx={{
            minWidth: 60,
            fontSize: 11,

            '& .MuiOutlinedInput-root': {
              borderRadius: 0,
              fontWeight: 'bold',
              fontSize: 11,
            },
            '& .MuiSvgIcon-root': { fontSize: 20 },
          }}
          select
          variant="outlined"
          value={selectedPoint}
          onChange={(e) => {
            let point = e.target.value
            setSelectedPoint(point)
            if (linked !== 'linked') {
              onChange(
                point ? `${valueFormatted}${point}` : valueFormatted,
                `${parentSlug}.${slug}`,
              )
            } else {
              // onChangeLinked(
              //   point ? `${valueFormatted}${point}` : valueFormatted,
              //   `${parentSlug}.${slug}`
              // )
            }
          }}
          disabled={disabled}
        >
          <MenuItem value="" sx={{ fontSize: 10 }}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={'%'} sx={{ fontSize: 10 }}>
            %
          </MenuItem>
          <MenuItem value={'px'} sx={{ fontSize: 10 }}>
            px
          </MenuItem>
        </TextField>
      </Paper>
    </Box>
  )
}
