import List from '@mui/material/List'
// import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import HomeIcon from '@mui/icons-material/Home'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import ArticleIcon from '@mui/icons-material/Article'
import SettingsIcon from '@mui/icons-material/Settings'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import PagesIcon from '@mui/icons-material/Pages'
import { useSelector, useDispatch } from 'react-redux'
import { Box, ListItemButton, ListItemText, Tooltip } from '@mui/material'
import { toggleSideBar } from '../api/session'
import { PermMedia } from '@mui/icons-material'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { useGetConfigurationBySlugQuery } from '../api/configuration'
import idx from 'idx'
import { isProductTeamUser } from '../utils/rightUtils'
import LogoBlack from '../assets/Logo_Submark/Stencil_Logo-Black.svg'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
// import MailIcon from '@mui/icons-material/Mail'

const menus = [
  {
    name: 'Pages',
    path: '/pages',
    icon: <PagesIcon />,
  },
  {
    name: 'Posts',
    path: '/posts',
    icon: <ArticleIcon />,
  },
  {
    name: 'Users',
    path: '/users',
    icon: <AccountCircleIcon />,
  },
  {
    name: 'Media Library',
    path: '/medialibrary',
    icon: <PermMedia />,
  },
  {
    name: 'Menu Management',
    path: '/menu',
    icon: <MenuOpenIcon />,
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: <SettingsIcon />,
  },
]

function ListItemLink(props) {
  const { to, selected, ...other } = props

  return (
    <Box
      sx={{
        background: 'transparent',
        border: 1,
        borderStyle: selected ? 'solid' : 'none',
        borderColor: selected ? '#CC0000' : 'transparent',
      }}
    >
      <ListItemButton component={Link} to={to} {...other}>
        {props.children}
      </ListItemButton>
    </Box>
  )
}

ListItemLink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default function SideBar() {
  const drawerOpened = useSelector((state) => state.session.sideBarOpen)
  const noSiteAccess = useSelector(
    (state) => state.session.profile.noSiteAccess,
  )
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: siteConfig } = useGetConfigurationBySlugQuery(
    {
      slug: 'site',
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )
  const { data: eventConfig } = useGetConfigurationBySlugQuery(
    {
      slug: 'event',
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  // const { data: emailerConfig } = useGetConfigurationBySlugQuery(
  //   {
  //     slug: 'emailer',
  //     activeSite,
  //   },
  //   {
  //     skip: !(userInfo.lanId && activeSite),
  //   },
  // )
  const location = useLocation()
  const dispatch = useDispatch()
  const { pathname } = location

  const isProductUser = isProductTeamUser(userInfo)

  const sideBarClose = () => {
    if (drawerOpened) {
      dispatch(toggleSideBar())
    }
  }

  const currentMenus = [
    ...(isProductUser
      ? [
          {
            name: 'Product Dashboard',
            path: '/',
            icon: (
              <Box sx={{ width: 24, display: 'flex' }}>
                <LogoBlack />
              </Box>
            ),
          },
          {
            name: 'Tenant Dashboard',
            path: '/tenant-dashboard',
            icon: <HomeIcon />,
          },
        ]
      : [
          {
            name: 'Dashboard',
            path: '/',
            icon: <HomeIcon />,
          },
        ]),
    ...menus,
    ...(idx(siteConfig, (_) => _.settings[0].value.subscriptionReportEnabled)
      ? [
          {
            name: 'Reports',
            path: '/reports',
            icon: <AssessmentIcon />,
          },
        ]
      : []),
    ...(idx(eventConfig, (_) => _.settings[0].value.enabled)
      ? [
          {
            name: 'Events',
            path: '/events',
            icon: <EventAvailableIcon />,
          },
        ]
      : []),
    // ...(idx(emailerConfig, (_) => _.settings[0].value.enabled)
    //   ? [{ name: 'Emailer', path: '/emailer', icon: <MailIcon /> }]
    //   : []),
  ]

  return (
    <>
      <List
        sx={{
          width: drawerOpened ? 200 : 60,
          position: drawerOpened ? 'fixed' : 'relative',
          bgcolor: '#fff',
          height: 'calc(100vh - 55px)',
          zIndex: 100,
          boxShadow: 2,
          py: '0px !important',
        }}
        onClick={sideBarClose}
      >
        {currentMenus.map((menu, index) => {
          const selected =
            pathname === menu.path ||
            (pathname.indexOf(menu.path) > -1 && menu.path !== '/')
          return (
            <ListItemLink
              underline="hover"
              color="inherit"
              to={menu.path}
              key={menu.path}
              selected={selected}
              sx={{ height: 46 }}
              disabled={noSiteAccess}
            >
              <Tooltip key={index} title={menu.name} placement="right">
                <Box sx={{ display: 'flex' }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 2,
                      mr: 1,
                      color: selected ? '#CC0000' : '#5A5A5A',
                    }}
                  >
                    {menu.icon}
                  </ListItemIcon>
                  <ListItemText secondary={drawerOpened ? menu.name : ''} />
                </Box>
              </Tooltip>
            </ListItemLink>
          )
        })}
      </List>
      {drawerOpened && <Box sx={{ width: 50 }}></Box>}
    </>
  )
}
