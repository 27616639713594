import React, { useEffect } from 'react'
import {
  Box,
  List,
  Divider,
  //TextField,
  Popper,
  Paper,
  Chip,
  Typography,
  ClickAwayListener,
  Alert,
} from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react'
import SendIcon from '@mui/icons-material/Send'
import Avatar from '@mui/material/Avatar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import moment from 'moment'
import { Markup } from 'interweave'
import { useAddCommentMutation } from '../api/comments'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { MentionsInput, Mention } from 'react-mentions'
import { useGetUsersListQuery } from '../api/users'

const CommentsItem = (props) => {
  const { selectedComment = {}, commentsByPage = [], onClose } = props
  const { type, name, _id, revision = '' } = selectedComment
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [currentComment, setCurrentComment] = React.useState('')
  const [userData, setUsers] = React.useState([])

  var open = Boolean(anchorEl)

  const navigate = useNavigate()

  const id = open ? 'simple-popper' : undefined
  const userId = useSelector((state) => state?.session?.profile?._id)
  const { data: users } = useGetUsersListQuery()

  const domElement = document.getElementById('commentsItem')

  const [addCommentToPost, { isLoading: saveInProgress }] =
    useAddCommentMutation()

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const onEmojiClick = (event, emojiObject) => {
    setCurrentComment(currentComment + emojiObject.emoji)
  }

  const onChangeComment = (e) => {
    setCurrentComment(e.target.value)
  }

  const addComment = () => {
    const revision = commentsByPage
      ? commentsByPage[commentsByPage.length - 1].revision
      : ''
    let users = []
    if (currentComment) {
      const regexTest = new RegExp(/\(([(a-zA-Z0-9)]+)\)/g)
      const str = currentComment
      let m

      while ((m = regexTest.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regexTest.lastIndex) {
          regexTest.lastIndex++
        }

        users.push(m[1])
      }
      addCommentToPost({
        _id,
        type,
        comment: currentComment,
        users: users,
        revision,
      })
      setCurrentComment('')
    }
  }

  const clickAwayHandler = () => {
    setAnchorEl(null)
  }

  const formatDate = (date) => {
    return moment(date).format('Do MMM YYYY')
  }

  const onTitleClick = () => {
    var editUrl =
      type === 'page'
        ? revision
          ? `/pages/layout/${revision}`
          : `/pages/update/${_id}`
        : `/posts/design/${_id}`
    navigate(editUrl)
    onClose()
  }

  const findMentionsAndMakeBold = (text) => {
    const regex = new RegExp(/@(\[[A-Za-z0-9\s.]+\])(\([0-9a-z]+\))/gm)
    let updatedText = text
    let m
    while ((m = regex.exec(text)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }
      const fullMatch = m[0]
      const name = m[1]
      updatedText = updatedText.replace(
        fullMatch,
        `<strong style="background:#ebedff;padding-left:5px;padding-right:5px;font-size:14px">${name
          .replace('[', '')
          .replace(']', '')}</strong>`,
      )
    }
    return updatedText
  }

  const renderUserSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused,
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0.5,
          background: focused ? '#90caf9' : '#f6f9ff',
          borderBottom: '1px solid #d3d3d3',
          left: '100px',
          zIndex: '10000',
        }}
      >
        <div>
          <Typography
            sx={{ fontSize: 12, fontWeight: 'bold', width: '100%' }}
            variant="body1"
          >
            {entry.displayName}
          </Typography>
          <Typography sx={{ fontSize: 10 }} variant="body2">
            {entry.title}
          </Typography>
        </div>
      </Box>
    )
  }

  useEffect(() => {
    if (users) {
      setUsers(
        ...[
          users
            .filter((u) => u.title)
            .map((user) => {
              return {
                ...user,
                display: user.displayName,
                title: user.title ? user.title : 'Unknown',
              }
            }),
        ],
      )
    }
  }, [users])

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        flexDirection: 'column',
        height: '100%',
        display: 'flex',
      }}
    >
      <List sx={{ flex: 1, marginBottom: '37px' }}>
        <ListItem sx={{ py: 0 }}>
          <ListItemButton sx={{ p: 0 }} onClick={onTitleClick}>
            <ListItemText
              primary={name}
              primaryTypographyProps={{
                fontSize: 16,
                whiteSpace: 'noWrap',
                width: '265px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#096DD9',
              }}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider sx={{ borderColor: '#B5B5B5' }} />
      </List>

      <Box
        sx={{
          // position: 'absolute',
          // bottom: '52px',
          overflow: 'scroll',
          maxHeight: '729px',
          // width: '50%',
        }}
      >
        {commentsByPage && commentsByPage.length > 0 ? (
          <>
            {commentsByPage &&
              commentsByPage.map((item, itemIndex) => {
                return (
                  <Box key={itemIndex}>
                    {commentsByPage &&
                    formatDate(item.updatedAt) !==
                      formatDate(commentsByPage[itemIndex - 1]?.updatedAt) ? (
                      <Divider
                        sx={{
                          '&::before, &::after': {
                            borderColor: '#B5B5B5',
                          },
                          '& .MuiDivider-wrapper': {
                            padding: 0,
                          },
                          mb: 8,
                        }}
                      >
                        <Chip
                          label={formatDate(item.updatedAt)}
                          variant="outlined"
                          sx={{
                            color: '#5A5A5A',
                            fontSize: 12,
                            px: 8,
                            height: 24,
                          }}
                        />
                      </Divider>
                    ) : (
                      ''
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        my: '40px',
                        mr: userId === item.created_by.id ? '40px' : '10px',
                        ml: userId === item.created_by.id ? '10px' : '40px',
                        flexDirection:
                          userId === item.created_by.id ? 'row' : 'row-reverse',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mr: userId === item.created_by.id ? 2 : 0,
                          ml: userId === item.created_by.id ? 0 : 2,
                          alignSelf: 'flex-end',
                          width: 30,
                        }}
                      >
                        <AccountCircleIcon
                          sx={{ color: '#303F9F' }}
                        ></AccountCircleIcon>
                        <Typography
                          variant="caption"
                          sx={{
                            alignSelf:
                              userId === item.created_by.id
                                ? 'flex-start'
                                : 'flex-end',
                          }}
                        >
                          {item?.created_by?.displayName}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                          sx={{
                            backgroundColor: '#D9D9D9',
                            borderRadius:
                              userId === item.created_by.id
                                ? '10px 10px 10px 0'
                                : '10px 10px 0 10px',
                            width: '232px',
                            p: 1,
                            wordBreak: 'break-all',
                          }}
                        >
                          <Typography variant="body2" sx={{ fontSize: 12 }}>
                            <Markup
                              content={findMentionsAndMakeBold(item?.comment)}
                            />
                          </Typography>
                        </Box>
                        <Typography
                          variant="caption"
                          sx={{
                            alignSelf:
                              userId === item.created_by.id
                                ? 'flex-end'
                                : 'flex-start',
                          }}
                        >
                          {moment(item?.updatedAt).fromNow()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )
              })}{' '}
          </>
        ) : (
          <Alert severity="info">No comments added yet</Alert>
        )}

        <Divider
          sx={{ borderColor: '#B5B5B5', top: '-4px', position: 'relative' }}
        ></Divider>
      </Box>
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              '& textarea': {
                fontSize: '14px !important',
                border: 'none',
                height: '70px',
                ml: '8px !important',
                mt: '16px !important',
                '&:focus': {
                  border: 'none',
                  outline: 'none',
                },
              },
              width: '70%',
              minHeight: 70,
            }}
          >
            {/* <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& > fieldset': {
                      border: 'none',
                    },
                  },
                }}
                placeholder="Type something here ..."
                multiline
                rows={1}
                maxRows={4}
                value={currentComment}
                onChange={onChangeComment}
              /> */}
            <MentionsInput
              value={currentComment}
              onChange={onChangeComment}
              placeholder="Type something here ..."
              allowSpaceInQuery={true}
              suggestionsPortalHost={domElement}
              allowSuggestionsAboveCursor={true}
              forceSuggestionsAboveCursor={true}
              className="mentions"
            >
              <Mention
                trigger="@"
                data={userData}
                renderSuggestion={renderUserSuggestion}
                className="mentions__mention"
              />
            </MentionsInput>
          </Box>

          {/* <IconButton type="submit" aria-label="file attchement">
            <input hidden accept="/*" multiple type="file" />
            <AttachFileIcon></AttachFileIcon>
          </IconButton> */}

          <IconButton
            aria-label="Add Emoticon"
            onClick={handleClick}
            sx={{ borderRadius: 0 }}
            edge="end"
          >
            <InsertEmoticonIcon />
          </IconButton>

          <Paper sx={{ zIndex: 1 }}>
            <Popper
              style={{ zIndex: 3000 }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement={'bottom'}
            >
              <Picker
                onEmojiClick={onEmojiClick}
                disableAutoFocus={true}
                skinTone={SKIN_TONE_NEUTRAL}
                groupNames={{ smileys_people: 'PEOPLE' }}
                native
              />
            </Popper>
          </Paper>

          <Avatar
            sx={{
              background: '#096DD9',
              margin: 2,
            }}
          >
            <IconButton
              aria-label="Send Icon"
              edge="end"
              type="submit"
              sx={{ color: '#fff' }}
              onClick={addComment}
              disabled={saveInProgress}
            >
              <SendIcon />
            </IconButton>
          </Avatar>
        </Box>
      </ClickAwayListener>
    </Paper>
  )
}

export default CommentsItem
