export const STAGING_STATUS_LIST = [
  'draft',
  'pending_approval',
  'approved',
  'rejected',
]

/**
 * function to convert the string first letter to uppercase
 * @param {string} string
 * @returns {string} captitalized string
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * function to check the current page version assinged to the given user
 * @param {Object} versionInfo
 * @param {string} userLanId
 * @returns {boolean} True indicates the user has access to edit this version
 */
export const versionAssignedToThisUser = (versionInfo = {}, userLanId) => {
  const { status = {} } = versionInfo
  const { assigned_to = {} } = status
  return assigned_to.lanId === userLanId
}

/**
 *
 * @param {Object} version
 * @returns {string} version status
 */
export const getStatusOfThePageVersion = (version) => {
  return version?.status?.status
}

/**
 *
 * @param {string} status
 * @param {string} param
 * @returns {string} the color of status or label string
 */
export const getStatusColorOrLabel = (status, param = 'label') => {
  const statusInfo = {
    approved: { color: 'approved', label: 'Approved' },
    draft: { color: 'draft', label: 'Draft' },
    pending_approval: { color: 'pending', label: 'Pending approval' },
    rejected: { color: 'rejected', label: 'Rejected' },
    published: { color: 'published', label: 'Published' },
    unpublished: { color: 'unpublished', label: 'Un published' },
    archived: { color: '#795548', label: 'Archived' },
  }

  return statusInfo[status]?.[param]
}

/**
 *
 * @param {*} revisions
 * @returns {boolean} returns true when the page is already published
 */
export const isThatPublishedPage = (revisions) => {
  if (revisions && revisions.length > 0) {
    const published = revisions.filter(
      (revision) => revision?.status?.status === 'published',
    )
    return published.length > 0
  }
  return false
}

/**
 *
 * @param {*} revisions
 * @returns {boolean} returns true when the revisions list has draft version
 */
export const draftVersionExistOrNot = (revisions) => {
  if (revisions && revisions.length > 0) {
    const draft = revisions.filter((revision) =>
      STAGING_STATUS_LIST.includes(revision?.status?.status),
    )
    return draft.length > 0
  }
  return false
}

/**
 *
 * @param {Object} page
 * @returns {string} latest revision id
 */
export const getLatestVersionIdFromPage = (page) => {
  const { revisions = [] } = page
  const latestVersionFound = revisions.slice(-1).pop()
  const { id = '' } = latestVersionFound
  return id
}

/**
 *
 * @param {Object} page
 * @returns {Array} Page latest version layout
 */
export const getLatestVersionLayout = (page) => {
  const { revisions = [] } = page
  const latestVersionFound = revisions.slice(-1).pop()
  const { layout = [] } = latestVersionFound
  return layout
}

/**
 *
 * @param {string} version
 * @returns {boolean} returns true when the current version is editable
 */
export const currentVersionEditable = (version = '') => {
  const editbleStatusList = ['draft']
  return editbleStatusList.includes(version)
}

/**
 *
 * @param {Object} page
 * @returns {Object} latestVersion
 */
export const getLatestVersionFromPage = (page = {}) => {
  const { revisions = [] } = page
  return revisions.slice(-1).pop()
}

/**
 *
 * @param {string} typoVariant
 * @param {string} sizeVariant
 * @param {string} device
 * @param {Array[Object]} typo
 * @returns {number}
 */
export const getFontSizeByVariant = (
  typoVariant,
  sizeVariant,
  device,
  typo = [],
) => {
  let fontsizeVal = {
    sizeVal: '',
  }
  typo.forEach((variant) => {
    if (variant.slug === typoVariant) {
      variant.fontSize.forEach((sizes) => {
        if (sizes.name === sizeVariant) {
          sizes.values.forEach((fontSize) => {
            if (fontSize.name === device) {
              fontsizeVal.sizeVal = fontSize.range.value
                ? fontSize.range.value
                : fontSize.range.default
            }
          })
        }
      })
    }
  })
  return fontsizeVal
}
