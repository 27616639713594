import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AccessDenied from '../../Layout/AccessDenied'
import withRights from '../../components/withRights'
import Loader from '../../components/Loader'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate, useLocation } from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import EventListing from './EventComponents/EventListing'
import { useGetAllEventsQuery } from '../../api/event'
import { useSelector } from 'react-redux'
import { primaryActionBtnStyle } from '../../utils/themeHelper'
import { checkUserIsAllowed } from '../../utils/rightUtils'

const pathTrail = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: '',
    label: 'Events',
  },
]

const Event = (props) => {
  const { rights = {}, rightsLoading, noSiteAccess = false } = props
  const navigate = useNavigate()
  const location = useLocation()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [forcedTab, setForcedTab] = useState('')
  const { moduleAccess, permittedActions, superRights } = rights
  const actionsAllowed = {
    userCanCreateEvent: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
    userCanEditEvent: checkUserIsAllowed('edit', permittedActions, superRights),
    userCanDeleteEvent: checkUserIsAllowed(
      'delete',
      permittedActions,
      superRights,
    ),
  }
  const {
    data: events,
    isLoading: eventsListLoading,
    error: eventsListError,
  } = useGetAllEventsQuery(activeSite, {
    skip: !activeSite,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    const hash = location?.hash
    if (hash && ['#active', '#archived'].includes(hash)) {
      setForcedTab(hash.replace('#', ''))
    }
  }, [location])

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      {rightsLoading && !noSiteAccess ? (
        <Loader />
      ) : moduleAccess ? (
        <>
          <Box sx={{ p: 2, display: 'flex' }}>
            <Breadcrumbs trail={pathTrail} />
            <Box sx={{ flexGrow: 1 }}></Box>
            <Button
              variant="contained"
              size="small"
              sx={primaryActionBtnStyle}
              startIcon={<AddIcon />}
              disabled={!actionsAllowed?.userCanCreateEvent}
              onClick={() => navigate('/events/create')}
            >
              Create New Event
            </Button>
          </Box>
          <Box
            sx={{ background: '#FFF', minHeight: '85vh', mt: 2, p: 2, pt: 0 }}
          >
            <Box
              sx={{
                height: '80vh',
                overflowY: 'auto',
                boxShadow:
                  '0px 2px 4px -2px rgba(51, 51, 51, 0.16), 0px 4px 8px rgba(51, 51, 51, 0.08), 0px 0px 0px 1px rgba(51, 51, 51, 0.04)',
              }}
            >
              <EventListing
                events={events}
                isLoading={eventsListLoading}
                isError={eventsListError}
                forcedTab={forcedTab}
                actionsAllowed={actionsAllowed}
              />
            </Box>
          </Box>
        </>
      ) : (
        <AccessDenied module="Event" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Read',
      access: 'Read',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Delete',
      access: 'Delete',
    },
    {
      slug: 'Create',
      access: 'Create',
    },
  ],
}

export default withRights(Event, moduleAttr)
