import { useEffect, useState } from 'react'
import { Typography, Grid, Box } from '@mui/material'
import moment from 'moment'

// import { PRODUCT_OVERVIEW_CHARTS as CHARTS } from '../dashboardConstants'
import { useGetEventMetricsListQuery } from '../../api/newDashboard'
import { useSelector } from 'react-redux'
import EventDashboardParticipantsCard from './Widgets/EventDashboardParticipantsCard'
import EventDashboardParticipantsClassifications from './Widgets/EventDashboardParticipantsClassifications'
import EventDashboardWorkshop from './Widgets/EventDashboardWorkshop'
import EventDashboardByCompany from './Widgets/EventDashboardByCompany'
import EventDashboardAnalytics from './Widgets/EventDashboardAnalytics'
import EventDashboardRegistrationCount from './Widgets/EventDashboardRegistrationCount'

const EventOverviewDashboard = () => {
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [currentYearEventId, setCurrentYearEventId] = useState('')

  const { data: metricsData } = useGetEventMetricsListQuery(
    {
      site: activeSite._id,
    },
    { skip: !(userInfo.lanId && activeSite._id) },
  )

  useEffect(() => {
    if (metricsData !== undefined && moment().year() in metricsData) {
      let currentYearEvent = metricsData[moment().year()]
      setCurrentYearEventId(currentYearEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsData])

  return (
    <Box margin={2}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'space-between',
          marginBottom: '5px',
        }}
      >
        <Typography
          sx={{ color: '#cc0000', fontWeight: '700', fontSize: '20px' }}
        >
          Main Dashboard
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '250px',
          }}
        >
          {/* <Button
            variant={'contained'}
            color="primary"
            onClick={() => {}}
            disableRipple
            disableFocusRipple
            sx={{
              textTransform: 'none',
              backgroundColor: '#fff',
              border: '1px solid #CC0000',
              color: '#CC0000',
              ':hover': {
                backgroundColor: '#CC0000',
                color: '#fff',
              },
              ':focus': { border: '2px dashed #666666' },
            }}
            size="small"
          >
            Filters
          </Button>
          <Button
            disableFocusRipple
            variant={'contained'}
            onClick={() => {}}
            startIcon={<CloudDownload fontSize="large" />}
            // disabled={!userCanCreatePage}
            sx={{
              textTransform: 'none',
              backgroundColor: '#CC0000',
              ':hover': { backgroundColor: '#CC0000' },
              ':focus': { border: '2px dashed #fff' },
              width: '150px',
            }}
            size="small"
          >
            Download
          </Button> */}
        </Box>
      </Box>
      <Box padding={2} sx={{ backgroundColor: '#F6F7F8' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <h3>Current Year Participants Status</h3>
        </div>
        <Grid container spacing={2} direction={'column'} sx={{ pb: 5 }}>
          <Grid item xs>
            {currentYearEventId !== undefined && (
              <EventDashboardParticipantsCard eventId={currentYearEventId} />
            )}
          </Grid>
          <Grid container spacing={2} sx={{ px: 2, py: 2 }}>
            <Grid item xs={6}>
              {metricsData !== undefined && (
                <EventDashboardParticipantsClassifications
                  allYearData={metricsData}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {metricsData !== undefined && (
                <EventDashboardWorkshop allYearData={metricsData} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ px: 2, py: 2 }}>
            <Grid item xs={7}>
              {metricsData !== undefined && (
                <EventDashboardRegistrationCount allYearData={metricsData} />
              )}
            </Grid>
            <Grid item xs={5}>
              {metricsData !== undefined && (
                <EventDashboardByCompany allYearData={metricsData} />
              )}
            </Grid>

            <Grid item xs={3}>
              <EventDashboardAnalytics />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default EventOverviewDashboard
