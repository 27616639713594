import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Cable } from '@mui/icons-material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { EventList } from './EventList'
import { ModelDataList } from './ModelDataList'
import {
  DATASET_REGISTRY,
  DYNAMIC_FIELDS,
  checkDynamicDataExistInValues,
} from './Utils'
import { FinalStage } from './FinalStage'

const DataConnector = (props) => {
  const { values, meta, onChange, setFieldMapContext, disabled = false } = props
  const [open, setOpen] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)

  const [dataset, setDataset] = React.useState('')
  const [model, setModel] = React.useState('')
  const [selectedModule, setSelectedModule] = React.useState([])
  const [selectedRecords, setSelectedRecords] = React.useState([])
  const [selectedProgram, setSelectedProgram] = React.useState([])
  const [availableColumnsForMaster, setAvailableColumnsForMaster] =
    React.useState([])
  const [availableColumns, setAvailableColumns] = React.useState([])
  const [fieldsMap, setFieldMap] = React.useState({})
  const [dynamicData, setDynamicData] = React.useState([])
  const [readyForAssign, setReadyForAssign] = React.useState(false)
  const [dataConnected, setDataConnected] = React.useState(false)
  const [existingConnection, setExistingConnection] = React.useState({})
  const [editOpen, setEditOpen] = React.useState(false)

  React.useEffect(() => {
    if (
      checkDynamicDataExistInValues(values, meta?.elements?.[0]?.element?.slug)
    ) {
      setDataConnected(true)
      setExistingConnection(
        values?.[`${meta?.elements?.[0]?.element?.slug}_dynamic`]
          ? values?.[`${meta?.elements?.[0]?.element?.slug}_dynamic`]
          : {},
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  React.useEffect(() => {
    if (!open) {
      setActiveStep(0)
      setDataset('')
      setSelectedModule([])
      setAvailableColumns([])
      setFieldMap([])
      setDynamicData([])
      setReadyForAssign(false)
      setDataConnected(false)
      setExistingConnection({})
    }
  }, [open])

  React.useEffect(() => {
    if (dataConnected) {
      setDataset(existingConnection?.dataset ? existingConnection?.dataset : '')
      setSelectedModule(
        existingConnection?.selectedModule
          ? existingConnection?.selectedModule
          : '',
      )
      setSelectedRecords(
        existingConnection?.selectedRecords
          ? existingConnection?.selectedRecords
          : '',
      )
      setFieldMap(
        existingConnection?.fieldsMap ? existingConnection?.fieldsMap : [],
      )
      setActiveStep(3)
      setModel(existingConnection?.model ? existingConnection?.model : '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConnected])

  const nextDisabled = (step) => {
    switch (step) {
      case 0:
        return dataset === '' || selectedModule.length !== 1
      case 1:
        return selectedRecords.length === 0
      case 2:
        return Object.values(fieldsMap).filter((val) => val !== '').length === 0
      case 3:
        return !readyForAssign
      default:
        return true
    }
  }

  const handleClick = (event) => {
    setOpen(!open)
  }

  const steps = [
    'Choose Dataset',
    'Select Model',
    'Select Field',
    'Finalize Data',
  ]

  const handleNext = () => {
    if (activeStep === 3 && editOpen) {
      setEditOpen(false)
    } else if (activeStep === 3 && !editOpen) {
      const childSlug = meta?.elements?.[0]?.element?.slug
      onChange(`${childSlug}_dynamic`, {
        dynamicDataEnabled: true,
        dataset,
        model: 'speakers',
        selectedModule,
        selectedRecords,
        fieldsMap,
        order: dynamicData.map((record) => record._id),
      })
      setFieldMapContext({ map: fieldsMap, dataset, model })
      setOpen(false)
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const removeConnection = () => {
    setExistingConnection({})
    const childSlug = meta?.elements?.[0]?.element?.slug
    onChange(`${childSlug}_dynamic`, {
      dynamicDataEnabled: false,
    })
    setActiveStep(0)
    setDataset('')
    setSelectedModule([])
    setAvailableColumns([])
    setFieldMap([])
    setDynamicData([])
    setReadyForAssign(false)
    setDataConnected(false)
    setExistingConnection({})
  }

  return (
    <>
      <IconButton onClick={handleClick} size="small" disabled={disabled}>
        <Cable />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle sx={{ pb: 0 }}>Connect to Datasource</DialogTitle>
        <DialogContent sx={{ height: '65vh' }}>
          <DialogContentText sx={{ mb: 2, fontSize: 14 }}>
            After mapping the data source to the component fields the data will
            be pre populated automatically
          </DialogContentText>
          <Box noValidate>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    color="inherit"
                    sx={{ '& .MuiStepLabel-label': { fontSize: 12 } }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box>
              <React.Fragment>
                <Box
                  sx={{
                    minHeight: 300,
                    background: '#F0F2F4',
                    m: 1,
                    p: 2,
                  }}
                >
                  {activeStep === 0 && (
                    <>
                      <FormControl>
                        <FormLabel id="data-set-group-label">
                          Choose dataset
                        </FormLabel>
                        <RadioGroup
                          row
                          value={dataset}
                          name="data-set-group"
                          onChange={(e) => setDataset(e.target.value)}
                        >
                          {Object.keys(DATASET_REGISTRY).map((moduleName) => {
                            const { display = '', enabled = true } =
                              DATASET_REGISTRY?.[moduleName]
                                ? DATASET_REGISTRY[moduleName]
                                : {}
                            return (
                              <FormControlLabel
                                key={moduleName}
                                value={moduleName}
                                control={<Radio size="small" />}
                                label={display}
                                disabled={!enabled}
                              />
                            )
                          })}
                        </RadioGroup>
                      </FormControl>
                      {dataset === 'events' && (
                        <EventList
                          selectedEvent={selectedModule}
                          setSelectedEvent={setSelectedModule}
                        />
                      )}
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <FormControl>
                        <FormLabel id="data-set-group-label">
                          Choose Model
                        </FormLabel>
                        <RadioGroup
                          row
                          value={model}
                          name="data-set-group"
                          onChange={(e) => {
                            setModel(e.target.value)
                            setSelectedRecords([])
                          }}
                        >
                          {DATASET_REGISTRY?.[dataset]?.models &&
                            DATASET_REGISTRY?.[dataset]?.models.map(
                              (modelData) => {
                                const {
                                  display = '',
                                  enabled = true,
                                  model = '',
                                } = modelData
                                return (
                                  <FormControlLabel
                                    key={model}
                                    value={model}
                                    control={<Radio size="small" />}
                                    label={display}
                                    disabled={!enabled}
                                  />
                                )
                              },
                            )}
                        </RadioGroup>
                      </FormControl>
                      {dataset === 'events' &&
                        model &&
                        model === 'speakers' && (
                          <ModelDataList
                            selectedRecords={selectedRecords}
                            setSelectedRecords={setSelectedRecords}
                            selectedModule={selectedModule?.[0]}
                            model={model}
                            dataset={dataset}
                            selection={
                              model === 'speakers' ? 'multiple' : 'single'
                            }
                            availableColumns={availableColumns}
                            setAvailableColumns={setAvailableColumns}
                          />
                        )}
                      {dataset === 'events' &&
                        model &&
                        model !== 'speakers' && (
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                            >{`Select one program to proceed further`}</Typography>
                            <ModelDataList
                              selectedRecords={selectedProgram}
                              setSelectedRecords={setSelectedProgram}
                              selectedModule={selectedModule?.[0]}
                              model={model}
                              dataset={dataset}
                              selection={'single'}
                              availableColumns={availableColumnsForMaster}
                              setAvailableColumns={setAvailableColumnsForMaster}
                            />
                            <Box sx={{ mt: 2 }}>
                              <Typography
                                sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                              >{`Select Records`}</Typography>
                              {selectedProgram &&
                                selectedProgram.length > 0 && (
                                  <ModelDataList
                                    selectedRecords={selectedRecords}
                                    setSelectedRecords={setSelectedRecords}
                                    selectedModule={selectedModule?.[0]}
                                    model={'speakers'}
                                    program={selectedProgram}
                                    dataset={dataset}
                                    selection={'multiple'}
                                    availableColumns={availableColumns}
                                    setAvailableColumns={setAvailableColumns}
                                  />
                                )}
                            </Box>
                          </Box>
                        )}
                    </>
                  )}
                  {activeStep === 2 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <List
                        dense
                        sx={{
                          width: '60%',
                          bgcolor: 'background.paper',
                        }}
                      >
                        {DYNAMIC_FIELDS?.[meta?.slug] &&
                          DYNAMIC_FIELDS?.[meta?.slug].map((field, key) => {
                            return (
                              <ListItem sx={{ display: 'flex' }} key={key}>
                                <ListItemText
                                  sx={{ flexGrow: 1, flexBasis: 0 }}
                                  primary={`${field.display}`}
                                />
                                <Select
                                  size="small"
                                  sx={{ flexGrow: 1, flexBasis: 0 }}
                                  displayEmpty
                                  id="col-selector"
                                  value={
                                    fieldsMap?.[field.field]
                                      ? fieldsMap?.[field.field]
                                      : ''
                                  }
                                  inputProps={{
                                    'aria-label': 'Without label',
                                  }}
                                  onChange={(e) =>
                                    setFieldMap({
                                      ...fieldsMap,
                                      [field.field]: e.target.value,
                                    })
                                  }
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {availableColumns &&
                                    availableColumns.map((col) => {
                                      return (
                                        <MenuItem
                                          key={col.field}
                                          value={col.field}
                                        >
                                          {col.headerName}
                                        </MenuItem>
                                      )
                                    })}
                                </Select>
                              </ListItem>
                            )
                          })}
                      </List>
                    </Box>
                  )}
                  {activeStep === 3 && (
                    <Box>
                      <FinalStage
                        dataset={dataset}
                        model={'speakers'}
                        selectedModule={selectedModule?.[0]}
                        selectedRecords={selectedRecords}
                        setSelectedRecords={setSelectedRecords}
                        fieldsMap={fieldsMap}
                        values={values}
                        onChange={onChange}
                        readyForAssign={readyForAssign}
                        setReadyForAssign={setReadyForAssign}
                        dynamicData={dynamicData}
                        setDynamicData={setDynamicData}
                        existingConnection={existingConnection}
                        availableColumns={availableColumns}
                        selectedProgram={selectedProgram}
                        setAvailableColumns={setAvailableColumns}
                        removeConnection={removeConnection}
                        editOpen={editOpen}
                        setEditOpen={setEditOpen}
                      />
                    </Box>
                  )}
                </Box>
              </React.Fragment>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ width: '100%', pl: 2 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', pt: 2, flexGrow: 1 }}
          >
            <Button
              color="inherit"
              disabled={
                activeStep === 0 || existingConnection?.dynamicDataEnabled
              }
              onClick={handleBack}
              variant="outlined"
              sx={{ mr: 1, textTransform: 'none' }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              onClick={handleNext}
              variant="contained"
              sx={{ mr: 1, textTransform: 'none' }}
              disabled={nextDisabled(activeStep)}
            >
              {activeStep === 3
                ? editOpen
                  ? 'Order the records'
                  : 'Connect the data'
                : 'Next'}
            </Button>
            <Button
              sx={{ mr: 1, textTransform: 'none' }}
              onClick={() => setOpen(false)}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DataConnector
