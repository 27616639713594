import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import AddchartIcon from '@mui/icons-material/Addchart'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import HealthIndicator from './HealthIndicator'
import RefreshIcon from '@mui/icons-material/Refresh'

export default function ServiceHealthCard(props) {
  const {
    backgroundColor = '#000',
    minWidth = 800,
    maxWidth = 200,
    minHeightContent = 550,
    textAlign = 'left',
    icon = <AddchartIcon fontSize="large" />,
    healthStat = {},
    refreshOption = false,
    refresh,
    name = '',
  } = props

  const iconUpgrade = React.cloneElement(icon, { fontSize: 'large' })
  return (
    <Card
      className="pattern-cross-dots-sm"
      sx={{
        minWidth,
        maxWidth,
        p: 2,
        borderRadius: 2,
        boxShadow: 4,
        color: '#fff',
        backgroundColor,
      }}
    >
      <CardContent
        sx={{
          textAlign,
          backdropFilter: 'blur(2px)',
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 1,
          minHeight: minHeightContent,
          position: 'relative',
        }}
      >
        {iconUpgrade}
        <Typography
          sx={(theme) => ({
            color: theme.palette.getContrastText(backgroundColor),
            fontWeight: 'bold',
          })}
        >
          {name}
        </Typography>
        {refreshOption && (
          <Tooltip title="Refetch data">
            <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
              <IconButton onClick={refresh}>
                <RefreshIcon sx={{ color: '#fff' }} />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        {Object.keys(healthStat).length > 0 && (
          <Grid container columnSpacing={1}>
            {Object.keys(healthStat).map((value, key) => {
              return (
                <React.Fragment key={key}>
                  <HealthIndicator clusters={healthStat[value]} env={value} />
                </React.Fragment>
              )
            })}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
