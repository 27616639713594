import { TextField } from '@mui/material'
import React from 'react'
import FormStyledInput from './FormStyledInput'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { TimePicker } from '@mui/x-date-pickers'
import { getValueByPath } from '../../../utils/dataParser'

const startYear = dayjs('2023-01-01T00:00:00.000')

export const FormDateField = (props) => {
  const {
    type = 'date',
    touched,
    errors,
    sx = {},
    disableFields = false,
    ...rest
  } = props
  const { name = '' } = rest
  const errorMessage = getValueByPath(errors, name)
  const touchedField = getValueByPath(touched, name)
  const error = touchedField && errorMessage
  return (
    <FormStyledInput sx={sx}>
      {type === 'time' ? (
        <TimePicker
          disabled={disableFields}
          {...rest}
          renderInput={(params) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              onBlur={() => props.setFieldTouched(name, true)}
              error={error}
              color={error ? 'warning' : 'primary'}
              helperText={error ? getValueByPath(errors, name) : ''}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      ) : (
        <DatePicker
          {...rest}
          disablePast
          minDate={startYear}
          renderInput={(params) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              onBlur={() => props.setFieldTouched(name, true)}
              error={error}
              color={error ? 'warning' : 'primary'}
              helperText={error ? getValueByPath(errors, name) : ''}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => {
                e.preventDefault()
              }}
            />
          )}
        />
      )}
    </FormStyledInput>
  )
}

export default FormDateField
