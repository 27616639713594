import React from 'react'
import { Box, Button, Card, IconButton, SvgIcon } from '@mui/material'
import Label from '../Label/Label'
import SelectIcon from '../SelectIcon/SelectIcon'
import * as mui from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { disableControlsBasedOnDevice } from '../../Helpers'

export const IconSelect = (props) => {
  const {
    value,
    onChange,
    name = 'Select icon',
    disabled = false,
    device = '',
    forDevices = '',
    enableLabel = false,
    helperText = '',
    noSlugChange = false,
    slug = '',
  } = props
  const [open, setOpen] = React.useState(false)
  const confirmSelection = (value) => {
    onChange(
      value,
      noSlugChange
        ? slug
        : `${slug}${
            device && device !== 'desktop'
              ? device.charAt(0).toUpperCase() + device.slice(1)
              : ''
          }`,
    )
    setOpen(false)
  }

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )

  const deleteSelectedIcon = () => {
    onChange(
      '',
      noSlugChange
        ? slug
        : `${slug}${
            device && device !== 'desktop'
              ? device.charAt(0).toUpperCase() + device.slice(1)
              : ''
          }`,
    )
  }
  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      {enableLabel && (
        <Label name={name} disabled={disabledOrNot} helperText={helperText} />
      )}
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        {value ? (
          <Card sx={{ p: 2, alignItems: 'center', display: 'flex' }} data>
            <SvgIcon component={mui[value]} />
            <IconButton disabled={disabled}>
              <DeleteIcon onClick={() => deleteSelectedIcon()} />
            </IconButton>
          </Card>
        ) : (
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            size="small"
            onClick={() => setOpen(true)}
            disabled={disabledOrNot}
          >
            Select icon
          </Button>
        )}
        <SelectIcon
          open={open}
          handleClose={() => setOpen(false)}
          confirmSelection={confirmSelection}
        />
      </Box>
    </Box>
  )
}

export default IconSelect
