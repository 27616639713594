import React from 'react'
import {
  AttrButtonGroup,
  ColorGradientPicker,
  IconSelect,
  Media,
  Text,
  UnitControl,
} from './../Common'
import { Number } from './../Common'
import { Box } from '@mui/material'
import { ButtonTextColor } from '../Button'

const loadField = (props) => {
  const types = {
    textInp: Text,
    unit: UnitControl,
    number: Number,
    color: ColorGradientPicker,
    buttonGroup: AttrButtonGroup,
    btnGroup: AttrButtonGroup,
    icon: IconSelect,
    bcTcColor: ButtonTextColor,
    media: Media,
  }
  const Field = types[props.type]
  return <Field {...props}></Field>
}

export const FieldLoader = (props) => {
  return <Box sx={{ flexGrow: 1 }}>{loadField(props)}</Box>
}
