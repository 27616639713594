import React from 'react'
import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import Label from './Common/Label'

export const Format = (props) => {
  const { value, onChange, name = 'Format', disabled = false } = props
  const [formatState, setFormatState] = React.useState([])

  React.useEffect(() => {
    const formats = []
    if (value) {
      if (value.fontWeight && value.fontWeight === 'bold') {
        formats.push('bold')
      }
      if (value.fontStyle && value.fontStyle === 'italic') {
        formats.push('italic')
      }
      if (value.textDecoration && value.textDecoration === 'underline') {
        formats.push('underline')
      }
    }
    setFormatState(formats)
  }, [value])

  const handleFormat = (_, vals) => {
    const updated = {
      fontWeight: vals.includes('bold') ? 'bold' : 'normal',
      fontStyle: vals.includes('italic') ? 'italic' : 'normal',
      textDecoration: vals.includes('underline') ? 'underline' : 'none',
    }
    onChange(updated)
  }

  return (
    <Box sx={{ display: 'flex', m: 1 }}>
      <Label name={name} />
      <Box sx={{ display: 'flex', width: '70%', justifyContent: 'left' }}>
        <ToggleButtonGroup
          value={formatState}
          size={'small'}
          onChange={handleFormat}
          aria-label="text formatting"
          disabled={disabled}
        >
          <ToggleButton value="bold" aria-label="bold">
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton value="italic" aria-label="italic">
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton value="underline" aria-label="underline">
            <FormatUnderlinedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}
export default Format
