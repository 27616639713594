import React from 'react'
import { Box, Breadcrumbs as MBreadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'

export const Breadcrumbs = (props) => {
  const { trail = [], maxItems = 3 } = props
  return (
    <Box>
      <MBreadcrumbs
        maxItems={maxItems}
        separator="›"
        aria-label="breadcrumb"
        sx={{ fontSize: 13, '& p': { fontSize: 13, fontWeight: 500 } }}
      >
        {trail &&
          trail.map((path, key) => {
            return (
              <Link key={key} to={path.to}>
                {path.label}
              </Link>
            )
          })}
      </MBreadcrumbs>
    </Box>
  )
}

export default Breadcrumbs
