import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useEditfileMutation } from '../api/mediaLib'
import { FormControl } from '@mui/material'
import { useSelector } from 'react-redux'

const EditItem = (props) => {
  const FIELDREGX = /^[a-zA-Z0-9 _,-]{2,2000}\S$/
  const { open, close, data, callBack } = props
  const siteId = useSelector((state) => state.tenant.activeSite?._id)

  const [editInputBox, setEditInputBox] = React.useState({
    name: '',
    tag: '',
  })

  const [editFile] = useEditfileMutation()

  const doEditFile = async (req) => {
    await editFile(req).then((res) => {
      close()
      callBack('File updated successfully')
    })
  }

  const handleEditItemInputchange = (val, attr) => {
    setEditInputBox({
      ...editInputBox,
      [attr]: val,
    })
  }

  const tailorTags = (tags) => {
    var tagArray = []
    tags.split(',').forEach((element) => {
      tagArray.push(element)
    })
    return tagArray
  }
  const checkValidation = () => {
    if (
      editInputBox.name.match(FIELDREGX) &&
      editInputBox.tag.match(FIELDREGX)
    ) {
      return true
    }
    return false
  }

  React.useEffect(() => {
    setEditInputBox({
      name: data && data.name !== '' ? data.name.split('.')[0] : '',
      tag: data && data.tags.length > 0 ? data.tags.toString() : '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              variant="standard"
              onChange={(e) =>
                handleEditItemInputchange(e.target.value, 'name')
              }
              value={editInputBox.name}
              error={editInputBox.name.match(FIELDREGX) ? false : true}
              helperText={
                !editInputBox.name.match(FIELDREGX)
                  ? "This field can't be empty"
                  : ''
              }
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              autoFocus
              margin="dense"
              id="tag"
              label="Tags"
              type="text"
              variant="standard"
              value={editInputBox.tag}
              onChange={(e) => handleEditItemInputchange(e.target.value, 'tag')}
              error={editInputBox.tag.match(FIELDREGX) ? false : true}
              helperText={
                !editInputBox.tag.match(FIELDREGX)
                  ? "This field can't be empty"
                  : 'Use commas to add multiple tags'
              }
              required
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button
            variant="contained"
            disabled={
              editInputBox.name.match(FIELDREGX) &&
              editInputBox.tag.match(FIELDREGX)
                ? false
                : true
            }
            onClick={() => {
              if (checkValidation()) {
                doEditFile({
                  id: data && data._id,
                  body: {
                    name: `${editInputBox.name}.${data.name.split('.')[1]}`,
                    tags: tailorTags(editInputBox.tag && editInputBox.tag),
                    site: siteId,
                  },
                })
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditItem
