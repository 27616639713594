import React from 'react'
import { useGetPagesByMediaIdQuery } from '../../api/mediaLib'
import { useSelector } from 'react-redux'
import { IconButton, List, ListItem, Typography } from '@mui/material'
import CustomHtmlTooltip from '../../components/CustomHtmlTooltip'
import { Info } from '@mui/icons-material'
const ShowPagesListForEachMedia = (props) => {
  const { mediaId } = props

  return (
    <CustomHtmlTooltip
      placement="right"
      title={
        <>
          <TitleCard mediaId={mediaId?._id}></TitleCard>
        </>
      }
    >
      <IconButton
        disableFocusRipple
        aria-label={`${mediaId?.name.split('.')[0]}-copy`}
      >
        <Info />
      </IconButton>
    </CustomHtmlTooltip>
  )
}

const TitleCard = (props) => {
  const { mediaId } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: pageList, isFetching: fetching } = useGetPagesByMediaIdQuery(
    { mediaId },
    { skip: !(userInfo.lanId && activeSite) },
  )

  return (
    <>
      {fetching ? (
        <Typography variant="body1" sx={{ p: 2 }}>
          Loading...
        </Typography>
      ) : (
        <>
          <Typography variant="h6" sx={{ p: '2px 0px 0px 5px' }}>
            Media List
          </Typography>
          <List>
            {pageList && pageList.length > 0 ? (
              pageList.map((element, index) => {
                return (
                  <ListItem>
                    <Typography sx={{ p: '0px 0px 5px 0px' }}>
                      {index + 1}
                      {'. '}
                      {element?.page?.name || element?.post?.title}
                    </Typography>
                  </ListItem>
                )
              })
            ) : (
              <Typography variant="body1" sx={{ p: '2px 0px 5px 5px' }}>
                Media has not been linked
              </Typography>
            )}
          </List>
        </>
      )}
    </>
  )
}

export default ShowPagesListForEachMedia
