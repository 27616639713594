import { Box, Tabs, Tab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import { useGetEventQuery } from '../../../api/event'
import EventInfoCard from '../EventComponents/EventInfoCard'
import EventModuleTabs from './EventModuleTabs'
import { useNavigate } from 'react-router-dom'
import AlertBlock from '../../../components/AlertBlock'
import { Loader, withRights } from '../../../components'
import { checkUserIsAllowed } from '../../../utils/rightUtils'
import AccessDenied from '../../../Layout/AccessDenied'

const pathTrail = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: '/events',
    label: 'Events',
  },
  {
    to: '',
    label: 'Event Setup',
  },
]

export const ModuleSetup = (props) => {
  const { rights = {}, rightsLoading, noSiteAccess = false } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const actionsAllowed = {
    userCanEditEvent: checkUserIsAllowed('edit', permittedActions, superRights),
  }
  let { id } = useParams()
  const [currentEvent, setCurrentEvent] = useState(null)
  const navigate = useNavigate()
  const {
    data: event,
    // isLoading: eventIsLoading,
    error: eventLoadError,
  } = useGetEventQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (event) {
      setCurrentEvent({ ...event })
    }
  }, [event])

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      {rightsLoading && !noSiteAccess ? (
        <Loader />
      ) : moduleAccess ? (
        <Box data-testid="setup-wrapper">
          <Box sx={{ p: 2 }}>
            <Breadcrumbs trail={pathTrail} />
            {currentEvent && (
              <>
                <Tabs
                  sx={{
                    mt: 3,
                    mb: 0,
                    pl: 0,
                    pr: 0,
                    '& .MuiTabs-flexContainer': {
                      gap: '15px',
                    },
                    '& .Mui-selected': {
                      background: '#CC0000',
                      color: '#fff !important',
                    },
                    '& .MuiTabs-indicator': {
                      height: 0,
                    },
                  }}
                  value={currentEvent?.status ? currentEvent?.status : 'active'}
                >
                  <Tab
                    sx={{
                      background: '#E6E7EA',
                      textTransform: 'none',
                      minWidth: '148px',
                      minHeight: '44px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                    value="active"
                    label="Active"
                    onClick={() => navigate(`/events#active`)}
                  />
                  <Tab
                    sx={{
                      background: '#E6E7EA',
                      textTransform: 'none',
                      minWidth: '148px',
                      minHeight: '44px',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                    value="archived"
                    label="Past Events"
                    onClick={() => navigate(`/events#archived`)}
                  />
                </Tabs>
                <Box sx={{ mt: 1, width: '100%', height: '87vh' }}>
                  {event && (
                    <EventInfoCard
                      {...event}
                      editDisabled={
                        currentEvent.status === 'archived' ||
                        !actionsAllowed?.userCanEditEvent
                      }
                    />
                  )}
                  <Box sx={{ mt: 1 }}>
                    <EventModuleTabs
                      event={currentEvent}
                      editDisabled={currentEvent.status === 'archived'}
                      rights={rights}
                    />
                  </Box>
                </Box>
              </>
            )}
            {eventLoadError && (
              <AlertBlock severity="error" title="Load Error">
                <p>Unable to load the event</p>
              </AlertBlock>
            )}
          </Box>
        </Box>
      ) : (
        <AccessDenied module="Event" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Read',
      access: 'Read',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
  ],
}

export default withRights(ModuleSetup, moduleAttr)
