import { createSlice } from '@reduxjs/toolkit'
import { profileApi } from '../api/profile'
import { isUserOnlyHavingDemoAccess } from './apiUtils'

const initialState = {
  sites: [],
  activeSite: null,
  permissions: {},
  permissionsFetchingCompleted: false,
}

const findDefaultSite = (usermap, sites) => {
  if (!usermap) {
    return null
  }

  const defaultMap = usermap.find((map) => map.isDefault)
  return defaultMap
    ? sites.find((site) => site._id === defaultMap.site)
    : sites[0]
}

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setActiveSite: (state, action) => {
      state.activeSite = action.payload
    },
  },
  extraReducers: (builder) => {
    // console.log(profileApi.endpoints.getProfile)
    builder.addMatcher(
      profileApi.endpoints.getProfile.matchFulfilled,
      (state, { payload }) => {
        if (isUserOnlyHavingDemoAccess(payload)) {
          state.sites =
            payload && payload[0] ? payload[0].sites : initialState.sites
          state.activeSite = initialState.activeSite
        } else {
          state.sites =
            payload && payload[0] ? payload[0].sites : initialState.sites
          state.activeSite =
            payload && payload[0]
              ? findDefaultSite(payload[0].usermap, payload[0].sites)
              : initialState.activeSite
        }
      },
    )
    builder.addMatcher(
      profileApi.endpoints.getRights.matchFulfilled,
      (state, { payload }) => {
        state.permissions = payload
        state.permissionsFetchingCompleted = true
      },
    )
  },
})

// Action creators are generated for each case reducer function
export const { setActiveSite } = tenantSlice.actions

export default tenantSlice.reducer
