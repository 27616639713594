import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { FormHeading } from '../../../FormComponents'
import FormForCreate from './FormForCreate'
import { useParams } from 'react-router-dom'
import { Loader, withRights } from '../../../../../components'
import { checkUserIsAllowed } from '../../../../../utils/rightUtils'
import AccessDenied from '../../../../../Layout/AccessDenied'
import {
  useGetCategoriesQuery,
  useGetTagsQuery,
} from '../../../../../api/taxonomy'
import { useSelector } from 'react-redux'

const pathTrail = (id) => {
  return [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${id}`,
      label: 'Event Setup',
    },
    {
      to: '',
      label: 'Add new Speaker',
    },
  ]
}

/**
 *
 * TODO: Test case needs to be added
 */
export const CreateSpeaker = (props) => {
  const { rights = {}, rightsLoading, noSiteAccess = false } = props
  const [customFields, setCustomFields] = React.useState([])
  const [resetFormNow, setResetFormStatus] = React.useState(false)
  let { id } = useParams()
  const { moduleAccess, permittedActions, superRights } = rights
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const actionsAllowed = {
    userCanCreateSpeaker: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
  }
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const { userCanCreateSpeaker } = actionsAllowed

  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }

  const resetForm = () => {
    setResetFormStatus(true)
  }

  const { data: categoriesList } = useGetCategoriesQuery(
    {
      filters: {},
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  const { data: tagsList } = useGetTagsQuery(
    {
      filters: {},
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  useEffect(() => {
    if (categoriesList) {
      const cat = []
      categoriesList.map((c) => {
        cat.push({ label: c.name, value: c.name })
        return 0
      })
      setCategories(cat)
    }
  }, [categoriesList])

  useEffect(() => {
    if (tagsList) {
      const tagList = []
      tagsList.map((t) => {
        tagList.push({ label: t.name, value: t.name })
        return 0
      })
      setTags(tagList)
    }
  }, [tagsList])

  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess && userCanCreateSpeaker ? (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }}>
        <Breadcrumbs trail={pathTrail(id)} />
        <Box sx={{ mt: 2, width: '100%', background: '#F6F7F8' }}>
          <FormHeading
            text="Create New Speaker"
            btns={[
              {
                label: 'Add new input',
                type: 'select',
                cb: addNewInput,
                icon: 'Add',
                options: [
                  { label: 'Text input', value: 'text' },
                  { label: 'Date input', value: 'date' },
                  { label: 'Switch input', value: 'switch' },
                ],
              },
              { label: 'Reset', cb: resetForm, icon: 'Replay' },
            ]}
          />
          <Divider variant="middle" sx={{ m: 0.5 }} />
          <Box container sx={{ display: 'flex', background: '#F6F7F8' }}>
            <FormForCreate
              customFields={customFields}
              setCustomFields={setCustomFields}
              reset={resetFormNow}
              setResetFormStatus={setResetFormStatus}
              domains={categories}
              skills={tags}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <AccessDenied module="Speakers" />
    </Box>
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'Create',
      access: 'Create',
    },
  ],
}

export default withRights(CreateSpeaker, moduleAttr)
