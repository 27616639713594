import React, { useEffect } from 'react'
import { Card } from '@mui/material'
// import Preview from './Preview'
import SectionListForDesign from './DesignMode/SectionListForDesign'
import { useSelector, useDispatch } from 'react-redux'
import {
  useGetUsedMediaQuery,
  useSaveUsedMediaMutation,
} from '../../../../api/page'
import { persistMediaList } from '../../../../api/session'

export default function Main(props) {
  const {
    layout,
    setLayout,
    selectComponentForEdit,
    onSectionClick,
    selectedComponent,
    updateLayoutSuccess,
    postActions = {},
    setSelectedRowForUpdate,
    setSectionExpanded,
    postData,
  } = props

  const { postChangesAllowed = false } = postActions

  const activeSite = useSelector((state) => state.tenant.activeSite)
  const mediaArr = useSelector((state) => state.session.mediaList)
  const dispatch = useDispatch()

  const openSectionForUpdate = (sectionNo) => {
    const rowNo = sectionNo - 1
    setSelectedRowForUpdate(rowNo)
    setSectionExpanded(true)
  }

  const openForEdit = (len) => {
    onSectionClick(len)
    openSectionForUpdate(len)
  }

  const [saveMedia] = useSaveUsedMediaMutation()
  const doPersistSavedMedia = async (body) => {
    await saveMedia(body).then((res) => {})
  }

  const { data: savedMediaList } = useGetUsedMediaQuery(
    { pageId: postData?._id, revisionId: null },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeSite,
    },
  )

  useEffect(() => {
    //save the usedmedia data
    if (updateLayoutSuccess) {
      const usedMediaIds =
        mediaArr?.length > 0
          ? mediaArr.map((media) => {
              return media?.id || media?._id
            })
          : []
      const body = {
        content: postData?._id,
        revision: null,
        data: usedMediaIds,
      }
      doPersistSavedMedia(body)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLayoutSuccess])

  useEffect(() => {
    if (savedMediaList && savedMediaList?.length > 0) {
      const data = savedMediaList[0].data
      let sortedArray = [...data]
      let sortedMedia = sortedArray.sort((e1, e2) =>
        e1.size < e2.size ? 1 : e1.size > e2.size ? -1 : 0,
      )
      dispatch(persistMediaList(sortedMedia))
    } else {
      dispatch(persistMediaList([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedMediaList])

  return (
    <>
      <Card
        sx={{
          height: 'calc(100vh - 64px)',
          borderRadius: 0,
          background: '#F6F6F6',
          overflow: 'scroll',
          p: 1,
          pt: 3,
        }}
        elevation={0}
      >
        <SectionListForDesign
          sections={layout}
          setLayout={setLayout}
          selectedComponent={selectedComponent}
          selectComponentForEdit={selectComponentForEdit}
          onSectionClick={onSectionClick}
          openSection={openSectionForUpdate}
          openForEdit={openForEdit}
          postChangesAllowed={postChangesAllowed}
        />
      </Card>
    </>
  )
}
