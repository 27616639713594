import * as React from 'react'
import { DataGrid, enUS } from '@mui/x-data-grid'

export const ManageDataGrid = (props) => {
  return (
    <div style={{ width: '100%', height: '85vh' }}>
      <DataGrid
        {...props}
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnHeadersInner': {
            backgroundColor: '#E6E7EA',
            color: '#333',
            padding: '0px -5px',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#333333',
            fontSize: 14,
            fontFamily: 'Helvetica for Target',
            fontWeight: '500',
            wordWrap: 'break-word',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: '0px 1px !important',
          },
        }}
        localeText={enUS.components.MuiDataGrid.defaultProps.localeText}
        rowHeight={60}
      />
    </div>
  )
}

export default ManageDataGrid
