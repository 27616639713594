import { Box } from '@mui/material'
import React, { useCallback } from 'react'
import Section from './Section'
import update from 'immutability-helper'
import apiConfig from '../../../../../apiConfig'
import { useSelector, useDispatch } from 'react-redux'
import { persistMediaList } from '../../../../../api/session'
import NoSection from './NoSection'
import AddSection from './AddSection'

export default function SectionList(props) {
  const {
    sections,
    setLayout,
    selectComponentForEdit,
    onSectionClick,
    selectedComponent,
    pageChangesAllowed = false,
    loadingPageData,
  } = props

  const { baseUrl } = apiConfig
  const dispatch = useDispatch()
  const savedMediaList = useSelector((state) => state.session.mediaList)

  const newSection = {
    sectionName: 'New Section',
    componentWidths: [100],
    components: [],
  }

  const addNewSection = () => {
    const updated = [...sections, newSection]
    setLayout(updated)
  }

  const onChangeSectionData = (data, rownum) => {
    const updated = [
      ...sections.slice(0, rownum),
      data,
      ...sections.slice(rownum + 1),
    ]
    setLayout(updated)
  }

  const moveSection = useCallback((dragIndex, hoverIndex) => {
    setLayout((prevSections) =>
      update(prevSections, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSections[dragIndex]],
        ],
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function filterIt(data, searchKey) {
    const searchInObj = (obj, searchVal, result) => {
      for (var p in obj) {
        if (typeof obj[p] == 'string' && obj[p].includes(searchVal)) {
          let fileNameAlone = obj[p].substring(
            obj[p].lastIndexOf('/') + 1,
            obj[p].length,
          )
          fileNameAlone = fileNameAlone.substring(
            fileNameAlone.indexOf('_') + 1,
            fileNameAlone.length,
          )
          result.push(fileNameAlone)
        } else if (typeof obj[p] == 'object') {
          searchInObj(obj[p], searchVal, result)
        }
      }
      return obj
    }
    let result = []
    searchInObj(data, searchKey, result)
    return result
  }

  const onDelete = (compPos) => {
    const attributes = sections[compPos?.secNo - 1]?.components[0]?.attributes
    let deletedMediaList = filterIt(attributes, baseUrl)
    let finalArray = savedMediaList.filter((item) => {
      return !deletedMediaList.some((e) => {
        return (
          item.path.substring(item.path.indexOf('_') + 1).toLowerCase() ===
          e.toLowerCase()
        )
      })
    })
    dispatch(persistMediaList(finalArray))
    const updated = sections.map((section, row) => {
      if (row === compPos.row) {
        return { ...section, components: [] }
      } else {
        return section
      }
    })
    selectComponentForEdit(null, null, true)
    setLayout(updated)
  }

  const onDeleteSection = (sectionNumber) => {
    const updatedArray = [
      ...sections.slice(0, sectionNumber),
      ...sections.slice(sectionNumber + 1),
    ]
    setLayout(updatedArray)
  }

  const renderSection = (section, key) => {
    return (
      <Section
        key={key}
        section={section}
        sectionNumber={key + 1}
        moveSection={moveSection}
        select={selectComponentForEdit}
        onSectionClick={onSectionClick}
        onDelete={onDelete}
        setSection={(val) => onChangeSectionData(val, key)}
        pageChangesAllowed={pageChangesAllowed}
        selectedComponent={
          selectedComponent && selectedComponent.row === key
            ? selectedComponent
            : null
        }
        onDeleteSection={onDeleteSection}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  return (
    <Box sx={{ p: 1 }}>
      {sections && sections.length === 0 && !loadingPageData ? (
        <>
          <NoSection />
          <AddSection
            onClickAddSection={addNewSection}
            pageChangesAllowed={pageChangesAllowed}
          />
        </>
      ) : (
        <>
          <Box>
            {sections.map((section, key) => {
              return renderSection(section, key)
            })}
          </Box>
          <AddSection
            onClickAddSection={addNewSection}
            pageChangesAllowed={pageChangesAllowed}
          />
        </>
      )}
    </Box>
  )
}
