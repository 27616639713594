import {
  Paper,
  Typography,
  Stack,
  Grid,
  Box,
  Skeleton,
  IconButton,
  Select,
  SvgIcon,
  MenuItem,
} from '@mui/material'
import React, { useReducer } from 'react'
import { useGetEventMetricsByYearQuery } from '../../../api/newDashboard'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { DOWNLOADICON } from '../../../assets/Image'
import moment from 'moment'
import { downloadCSV } from '../../../utils/helper'

const generateChartDataByYear = (metrics) => {
  const chartData = []
  Array.isArray(metrics) &&
    metrics?.forEach((metric) => {
      chartData.push({
        name: metric?.workshop?.name,
        ...getParticipantCountsByStatus(metric?.participants),
        count: onTrackByWorkshop(metric?.participants),
      })
    })
  return chartData
}

const generateChartDataByYearDownload = (metrics) => {
  const chartData = []
  Array.isArray(metrics) &&
    metrics?.forEach((metric) => {
      chartData.push({
        name: metric?.workshop?.name,
        ...getParticipantCountsByStatusDownload(metric?.participants),
      })
    })
  return chartData
}

const getParticipantCountsByStatusDownload = (data) => {
  const participantData = {
    internal: 0,
    external: 0,
  }

  const initialData = [
    { status: 'APPROVED', internal: 0, external: 0 },
    { status: 'REGISTERED', internal: 0, external: 0 },
    { status: 'ATTENDED', internal: 0, external: 0 },
    { status: 'ON_HOLD', internal: 0, external: 0 },
    { status: 'REJECTED', internal: 0, external: 0 },
  ]
  const res = {}
  Array.isArray(data) &&
    data.forEach((item) => {
      let { internal, external } = Object.keys(item)
        .filter((k) => Object.keys(participantData).includes(k))
        .reduce((newObj, key) => {
          newObj[key] = item[key]
          return newObj
        }, {})
      res[item['status']] = internal + external
    })
  for (const intData of initialData) {
    let { internal, external } = Object.keys(intData)
      .filter((k) => Object.keys(participantData).includes(k))
      .reduce((newObj, key) => {
        newObj[key] = intData[key]
        return newObj
      }, {})
    if (!Object.keys(res).includes(intData?.status)) {
      res[intData['status']] = internal + external
    }
  }
  return res
}

// const generateDownloadableChartDataByYear = (metrics) => {
//   const chartData = []
//   Array.isArray(metrics) &&
//     metrics?.forEach((metric) => {
//       chartData.push({
//         name: metric?.workshop?.name,
//         ...getParticipantCountsByStatus(metric?.participants),
//       })
//     })
//   return chartData
// }

// const generateChartDataAllYear = (metrics, allYear) => {
//   console.log('🚀 ~ generateChartDataAllYear ~ metrics:', metrics)
//   const chartData = []
//   metrics?.forEach((metric) => {
//     let tempObj = {}
//     tempObj['year'] = Object.keys(allYear).find(
//       (key) => allYear[key] === metric?.eId,
//     )
//     const { workshops } = Object.keys(metric)
//       .filter((key) => ['workshops'].includes(key))
//       .reduce((newObj, key) => {
//         console.log('🚀 ~ .reduce ~ key:', key)
//         newObj[key] = metric[key]
//         console.log('🚀 ~ .reduce ~ newObj:', newObj)
//         return newObj
//       }, {})
//     const wsArray = []
//     workshops.forEach((ws) => {
//       let currentObj = {
//         name: ws?.workshop?.name,
//         ...getParticipantCountsByStatus(ws?.participants),
//       }
//       // currentObj['name'] = ws?.workshop?.name
//       // currentObj['count'] = onTrackByWorkshop(ws?.participants)
//       wsArray.push(currentObj)
//     })
//     tempObj['workshops'] = wsArray
//     console.log('🚀 ~ workshops.forEach ~ tempObj:', tempObj)
//     chartData.push(tempObj)
//   })
//   console.log('🚀 ~ generateChartDataAllYear ~ chartData:', chartData)
//   return chartData
// }

const initialParticipantsCount = {
  internal: 0,
  external: 0,
}

const participantReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH': {
      const dataArray = []
      action?.value.forEach((item, i) => {
        const { workshops } = Object.keys(item)
          .filter((key) => ['workshops'].includes(key))
          .reduce((newObj, key) => {
            newObj[key] = item[key]
            return newObj
          }, {})
        dataArray.push(...workshops)
      })
      return dataArray
    }
    default:
      return state
  }
}

const onTrackByWorkshop = (data) => {
  const participantData = {
    internal: 0,
    external: 0,
  }
  Object.keys(participantData).forEach((key) => {
    participantData[key] = data
      ?.map((i) => i[key])
      ?.reduce((prev, next) => prev + next, 0)
  })
  const { internal, external } = participantData
  let participantCount = internal + external
  return participantCount
}

const getParticipantCountsByStatus = (data) => {
  const participantData = {
    internal: 0,
    external: 0,
  }
  const res = {}
  Array.isArray(data) &&
    data.forEach((item) => {
      let { internal, external } = Object.keys(item)
        .filter((k) => Object.keys(participantData).includes(k))
        .reduce((newObj, key) => {
          newObj[key] = item[key]
          return newObj
        }, {})
      if (internal + external > 0) {
        res[item['status']] = internal + external
      }
    })
  return res
}

const EventDashboardWorkshop = (props) => {
  const { allYearData } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { isLoading } = props
  const [selectedYear, setSelectedYear] = useState(moment().year()) // TODO this should be change with All year
  const [eventData, dispatch] = useReducer(
    participantReducer,
    initialParticipantsCount,
  )

  const { data: metricsData } = useGetEventMetricsByYearQuery(
    {
      eventId:
        // selectedYear === 'All Year'
        //   ? [...Object.values(allYearData).sort().reverse()]
        //   :
        [allYearData[selectedYear]],
    },
    {
      skip: !(userInfo.lanId && activeSite._id),
    },
  )

  useEffect(() => {
    if (metricsData !== undefined) {
      dispatch({ type: 'FETCH', value: metricsData, allYearData: allYearData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsData])
  return (
    <Grid item xs>
      <Paper
        sx={{
          borderRadius: '20px',
          padding: '20px',
          height: '100%',
          minHeight: '400px',
          maxHeight: '400px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Stack direction="row" gap={1}>
              <IconButton
                onClick={() => {
                  downloadCSV(
                    generateChartDataByYearDownload(eventData),
                    `workshop_data_${selectedYear}`,
                  )
                }}
              >
                <img
                  src={DOWNLOADICON}
                  alt="download-report"
                  height="33px"
                  width="33px"
                />
              </IconButton>
              <div>
                <Typography fontWeight={700} color="#2B3674" fontSize={'26px'}>
                  {generateChartDataByYear(eventData).filter(
                    (e) => e?.count > 0,
                  ).length > 0
                    ? eventData?.length
                    : 0}
                </Typography>
                <Typography fontWeight={400} color="#A3AED0" fontSize={'10px'}>
                  Total Workshops
                </Typography>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <div
              style={{
                display: 'flex',
                overflowX: 'scroll',
                padding: '5px 0',
                gap: '5px',
              }}
            ></div>
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <Select
                sx={{ width: 'auto', height: '30px' }}
                value={selectedYear}
                onChange={(event) => {
                  setSelectedYear(event.target.value)
                }}
                renderValue={(value) => {
                  return (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <SvgIcon color="primary" fontSize="small">
                        <CalendarTodayIcon />
                      </SvgIcon>
                      {value}
                    </Box>
                  )
                }}
              >
                {[...Object.keys(allYearData).sort().reverse()].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            {generateChartDataByYear(eventData).length > 0 &&
              generateChartDataByYear(eventData).filter((e) => e?.count > 0)
                .length > 0 && (
                <Box mt={2}>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ pb: '10px', pl: '10px' }}
                  >
                    <CheckCircleRoundedIcon sx={{ color: '#05CD99' }} />
                    <Typography fontWeight="bold" color="#05CD99">
                      On track
                    </Typography>
                  </Stack>

                  <Box
                    sx={{
                      height: 180,
                      overflowY: 'scroll',
                      paddingRight: '15px',
                    }}
                  >
                    {Array.isArray(eventData) &&
                      eventData
                        ?.filter((i) => onTrackByWorkshop(i?.participants) > 0)
                        .map((ws) => (
                          <Stack
                            key={ws.workshop?._id}
                            direction="row"
                            gap={1}
                            alignItems="center"
                            sx={{ pl: '15px' }}
                          >
                            <CircleRoundedIcon
                              sx={{ color: '#188686', fontSize: '8px' }}
                            />
                            <Typography
                              fontSize="12px"
                              color="rgb(163, 174, 208)"
                              flexGrow={1}
                              noWrap
                              sx={{
                                display: 'inline-block',
                                width: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden !important',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {ws?.workshop?.name}
                            </Typography>
                            <Typography
                              fontSize="18px"
                              fontWeight="bold"
                              color="#2B3674"
                            >
                              {isLoading ? (
                                <Skeleton width={30} />
                              ) : eventData ? (
                                onTrackByWorkshop(ws?.participants)
                              ) : (
                                '0'
                              )}
                            </Typography>
                          </Stack>
                        ))}
                  </Box>
                </Box>
              )}
          </Grid>
          <Grid item xs={8}>
            <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
              {/* TODO render different bar chart for all year data */}
              {/* {selectedYear === 'All Year' ? (
                <BarChart
                  width={500}
                  height={300}
                  data={generateChartDataAllYear(metricsData, allYearData)}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey={'year'} />
                  <YAxis />
                  <Tooltip cursor={{ fill: 'transparent' }} />
                  {currentYearWorkshops.map((ws) => {
                    return (
                      <>
                        <Bar
                          dataKey={`${ws?.REGISTERED}`}
                          name={`${ws?.name}`}
                          key={`${ws?.name}`}
                          barSize={30}
                          fill={'#4646B9'}
                          label={{ position: 'top' }}
                        />
                        <Bar
                          dataKey={`${ws.APPROVED}`}
                          name={`${ws?.name}`}
                          key={`${ws?.name}`}
                          barSize={30}
                          fill={'#FACE1E'}
                          label={{ position: 'top' }}
                        />
                      </>
                    )
                  })}
                </BarChart>
              ) :  */}
              {generateChartDataByYear(eventData).filter((e) => e?.count > 0)
                .length === 0 ? (
                <div>No Data :(</div>
              ) : (
                <BarChart
                  width={500}
                  height={300}
                  data={generateChartDataByYear(eventData).filter(
                    (e) => e?.count > 0,
                  )}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey={'name'}
                    label={
                      <Label axisType="yAxis">{`workshops (${selectedYear})`}</Label>
                    }
                    tick={{ fontSize: 0 }}
                  />
                  <YAxis
                    label={
                      <Label axisType="xAxis" angle={270}>
                        Count
                      </Label>
                    }
                  />
                  <Tooltip cursor={{ fill: 'transparent' }} />
                  <Bar
                    dataKey={'APPROVED'}
                    barSize={30}
                    fill={'#FACE1E'}
                    label={{ position: 'top' }}
                  />

                  <Bar
                    dataKey={'REGISTERED'}
                    barSize={30}
                    fill={'#4646B9'}
                    label={{ position: 'top' }}
                  />
                </BarChart>
              )}
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default EventDashboardWorkshop
