import React, { useState, useEffect } from 'react'
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Box,
  Grid,
  LinearProgress,
  Checkbox,
  Button,
  IconButton,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { ViewPopup } from '../Common/ViewPopup'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
export const SpeakersList = (props) => {
  const {
    speakerListData,
    speakersLoading,
    setSpeakersToDelete,
    speakersToDelete,
    removeSpeakersLoading,
    removeSpeakerSuccess,
    eventId,
    editDisabled = false,
    actionsAllowed = {},
  } = props
  // const [openDailog, setOpenDialog] = useState(false)
  const navigate = useNavigate()
  const [speakerForView, setSpeakerForView] = useState({})
  const [open, setOpen] = React.useState(false)
  const [checked, setChecked] = React.useState([])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const onReadMore = (speaker, index) => {
    setOpen(true)
    setSpeakerForView(speaker)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggle = (e, speaker) => {
    var list = [...speakersToDelete]
    if (e.target.checked) {
      setChecked([...checked, speaker._id])
      list.push(speaker._id)
    } else {
      list.pop(speaker._id)
      setChecked(checked.filter((item) => item !== speaker._id))
    }
    setSpeakersToDelete(list)
  }

  const {
    userCanEditSpeaker = false,
    userCanDeleteSpeaker = false,
    userCanViewSpeaker = false,
  } = actionsAllowed

  useEffect(() => {
    if (removeSpeakerSuccess) {
      enqueueSnackbar('Speaker removed successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: (key) => (
          <>
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </>
        ),
      })
      setSpeakersToDelete([])
      setChecked([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, removeSpeakerSuccess, setSpeakersToDelete])

  return (
    <Box
      sx={{
        backgroundColor: '#F6F7F8',
        p: 2,
        mt: 2,
        minHeight: '70vh',
        display: 'flex',
      }}
    >
      <Grid container spacing={4}>
        {(speakersLoading || removeSpeakersLoading) && <LinearProgress />}
        {speakerListData &&
          speakerListData.length > 0 &&
          speakerListData.map((speaker, index) => {
            return (
              <Grid
                item
                xs={3}
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '20px',
                }}
              >
                <Checkbox
                  edge="end"
                  onChange={(e) => handleToggle(e, speaker)}
                  color="success"
                  checked={checked.includes(speaker._id)}
                  disabled={editDisabled || !userCanDeleteSpeaker}
                />
                <Card
                  sx={{
                    backgroundColor: '#F7F7F7',
                    height: 375,
                    width: 410,
                    position: 'relative',
                  }}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: '#fff',
                      borderRadius: '15px',
                      minHeight: '200px',
                      boxShadow:
                        '0px 0px 0px 1px rgba(51, 51, 51, 0.04), 0px 4px 8px 0px rgba(51, 51, 51, 0.08), 0px 2px 4px -2px rgba(51, 51, 51, 0.16)',
                    }}
                    avatar={
                      <Avatar
                        alt="speaker_image"
                        src={speaker?.meta?.engagement_image}
                        sx={{ height: 70, width: 70 }}
                      ></Avatar>
                    }
                    title={
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {speaker?.user?.name}
                      </Typography>
                    }
                    subheader={
                      <>
                        <Typography variant="body1">
                          <strong>{speaker.user.company} :</strong>{' '}
                          <span style={{ color: '#676B74' }}>
                            {speaker.meta?.designation}
                          </span>
                        </Typography>
                        <Typography variant="body1">
                          <strong>{speaker.meta?.year}</strong>
                        </Typography>
                      </>
                    }
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {speaker.meta?.bio}
                    </Typography>
                    {speaker.meta?.bio?.length > 25 ? (
                      <Button
                        type="text"
                        disabled={!userCanViewSpeaker}
                        onClick={() => onReadMore(speaker, index)}
                        sx={{
                          fontSize: 16,
                          textTransform: 'none',
                          p: 0,
                          color: '#CC0000',
                          cursor: 'pointer',
                          minWidth: 0,
                        }}
                      >
                        {' '}
                        more
                      </Button>
                    ) : (
                      ''
                    )}
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: 'none',
                        color: '#CC0000',
                        borderColor: '#CC0000',
                        fontSize: 16,
                        position: 'absolute',
                        right: 18,
                        bottom: 15,
                        padding: 0,
                        height: 25,
                        '&:hover': {
                          border: '1px solid #CC0000',
                        },
                      }}
                      disabled={editDisabled || !userCanEditSpeaker}
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={() =>
                        navigate(
                          `/events/speakers/edit/${eventId}/${speaker.user._id}`,
                        )
                      }
                    >
                      Edit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
      </Grid>
      <ViewPopup
        open={open}
        onClose={handleClose}
        name={speakerForView?.user?.name}
        company={speakerForView?.user?.company}
        designation={speakerForView?.meta?.designation}
        bio={speakerForView?.meta?.bio}
        image={speakerForView?.meta?.engagement_image}
      />
    </Box>
  )
}

export default SpeakersList
