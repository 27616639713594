import {
  Paper,
  Typography,
  Stack,
  Grid,
  Box,
  IconButton,
  Select,
  SvgIcon,
  MenuItem,
} from '@mui/material'
import React, { useReducer } from 'react'
import { useGetEventMetricsByYearQuery } from '../../../api/newDashboard'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { ResponsiveContainer, Cell, PieChart, Pie, Legend } from 'recharts'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { DOWNLOADICON } from '../../../assets/Image'
import moment from 'moment'
import { downloadCSV } from '../../../utils/helper'

const initialParticipantsCount = [
  {
    year: `${moment().year()}`,
    type: 'internal',
    count: 0,
  },
  {
    year: `${moment().year()}`,
    type: 'internal',
    count: 0,
  },
]

const participantReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH': {
      const dataArray = []
      action?.value.forEach((item) => {
        const { internal, external } = Object.keys(item)
          .filter((key) => ['internal', 'external'].includes(key))
          .reduce((newObj, key) => {
            newObj[key] = item[key]
            return newObj
          }, {})
        const internalCount = Object.values(internal).reduce(
          (prev, next) => prev + next,
          0,
        )
        const externalCount = Object.values(external).reduce(
          (prev, next) => prev + next,
          0,
        )
        const internalObj = {
          year: Object.keys(action.allYearData).find(
            (key) => action.allYearData[key] === item?.eId,
          ),
          type: 'internal',
          count: internalCount,
        }

        const externalObj = {
          year: Object.keys(action.allYearData).find(
            (key) => action.allYearData[key] === item?.eId,
          ),
          type: 'external',
          count: externalCount,
        }
        dataArray.push(internalObj)
        dataArray.push(externalObj)
      })
      return dataArray
    }

    default:
      return state
  }
}

const COLORS = ['#CC0000', '#94B2EF']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const EventDashboardByCompany = (props) => {
  const { allYearData } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)

  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [eventData, dispatch] = useReducer(
    participantReducer,
    initialParticipantsCount,
  )

  const { data: metricsData } = useGetEventMetricsByYearQuery(
    {
      eventId: [allYearData[selectedYear]],
    },
    {
      skip: !(userInfo.lanId && activeSite._id),
    },
  )

  useEffect(() => {
    if (metricsData !== undefined) {
      dispatch({ type: 'FETCH', value: metricsData, allYearData: allYearData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsData])
  return (
    <Grid item xs>
      <Paper
        sx={{
          borderRadius: '20px',
          padding: '20px',
          height: '100%',
          minHeight: '400px',
          maxHeight: '400px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Stack direction="row" gap={1}>
              <IconButton
                onClick={() => {
                  eventData.length > 0
                    ? downloadCSV(
                        eventData,
                        `participant_by_company_${selectedYear}`,
                      )
                    : console.log('no data found')
                }}
              >
                <img
                  src={DOWNLOADICON}
                  alt="download-report"
                  height="33px"
                  width="33px"
                />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={9}>
            <div
              style={{
                display: 'flex',
                overflowX: 'scroll',
                padding: '5px 0',
                gap: '5px',
              }}
            ></div>
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <Select
                sx={{ width: 'auto', height: '30px' }}
                value={selectedYear}
                onChange={(event) => {
                  setSelectedYear(event.target.value)
                }}
                renderValue={(value) => {
                  return (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <SvgIcon color="primary" fontSize="small">
                        <CalendarTodayIcon />
                      </SvgIcon>
                      {value}
                    </Box>
                  )
                }}
              >
                {[...Object.keys(allYearData).sort().reverse()].map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={7}>
              <Stack
                direction="column"
                gap={1}
                sx={{
                  pb: '10px',
                  pl: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography fontWeight="bold" color="#2B3674">
                  By Company
                </Typography>
                <Typography fontWeight="bold" color="#2B3674" variant="h4">
                  {eventData
                    .map((e) => e.count)
                    .reduce((prev, current) => prev + current, 0)}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <ResponsiveContainer width="100%" aspect={4.0 / 4.0}>
              <PieChart width={400} height={600}>
                <Pie
                  data={eventData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="count"
                  nameKey="type"
                >
                  {eventData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default EventDashboardByCompany
