import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import {
  Format,
  AttrButtonGroup,
  Label,
  ColorGradientPicker,
  TabularControl,
  Animation,
  RichText,
} from '../Common'
import { Grid, Paper } from '@mui/material'
import Text from '../Common/Text/Text'
import Spacing from '../Spacing/Spacing'
import { disableControlsBasedOnDevice } from '../Helpers'
import ConditionalRender from '../Helpers/ConditionalRender'
import { DataConnectivityIndicator } from '../Common/DataConnector'

// const TYPO_VARIANTS = ['heading', 'subHeading', 'body', 'caption']
const DEFAULT_SETTINGS = {
  text: {
    type: 'text',
    forDevices: [],
    helperText: "Used to decide the text's format, size and alignment",
  },
  fontSize: {
    options: {
      helperText:
        '5 variable font sizes are available. Each of the variant can be mapped to a style guide provided by the tenant and the changes will be done on the client UI side.',
      allowedValues: ['XS', 'S', 'M', 'L', 'XL'],
    },
  },
  format: {
    options: {
      allowedValues: ['bold', 'italic', 'underline'],
      helperText: 'Used to bold, italicize or underline text',
    },
  },
  alignment: {
    options: {
      enableIconBtn: true,
      helperText:
        'Alignment of text within the component can be done for specific devices',
      allowedValues: [
        { icon: 'FormatAlignLeft', value: 'left' },
        { icon: 'FormatAlignCenter', value: 'center' },
        { icon: 'FormatAlignRight', value: 'right' },
      ],
      disabledOptions: [],
      forDevices: ['desktop', 'tablet', 'mobile'],
    },
  },
  lineHeight: {
    options: {
      enableIconBtn: true,
      helperText:
        'Used to adjust the spacing between elements, like spacing between heading and subheading. Compressed is 0.5, expanded is 2 and default is 1. (REM Values)',
      allowedValues: [
        { icon: 'DensitySmall', value: 1 },
        { icon: 'DensityMedium', value: 'default' },
        { icon: 'DensityLarge', value: 2 },
      ],
      disabledOptions: [],
    },
  },
  textWrapping: {
    options: {
      helperText:
        'Used to maintain line break of a text element. When text wrap is applied, the font gradually decreases until it detects the next device view port.',
      allowedValues: [
        { label: 'Wrap On', value: true },
        { label: 'Wrap Off', value: false },
      ],
    },
  },
  divider: {
    helperText:
      'Use this for creating a divider line below an element. Use the border width option to increase the thickness of the divider. To align the divider for an element, use the divider width and offset options.',
    options: {
      allowedValues: [
        { label: 'No', value: false },
        { label: 'Yes', value: true },
      ],
      size: 'small',
    },
  },
  dividerControls: {
    helperText:
      'Use this for creating a divider line below an element. Use the border width option to increase the thickness of the divider. To align the divider for an element, use the divider width and offset options.',
    options: {
      when: [{ divider: { enabled: true } }],
      attrList: [
        {
          name: 'Thickness',
          slug: 'borderWidth',
          type: 'number',
          endAdorment: 'px',
          width: 122,
        },
        {
          name: 'Color',
          slug: 'borderColor',
          type: 'color',
        },
        {
          name: 'Border Width',
          slug: 'width',
          type: 'number',
          endAdorment: '%',
          width: 122,
          defaultValue: 100,
          forDevices: ['desktop', 'tablet', 'mobile'],
        },
        {
          name: 'Offset Top',
          slug: 'offsetTop',
          endAdorment: '%',
          width: 122,
          defaultValue: 0,
          type: 'number',
        },
        {
          name: 'Offset Left',
          slug: 'offsetLeft',
          endAdorment: '%',
          width: 122,
          defaultValue: 0,
          type: 'number',
        },
      ],
    },
  },
  color: {
    helperText:
      'Used to change text color within the component. Text color has to be solid. ',
  },
  animation: {
    helperText: 'Choose from multiple animation configuration options',
    options: {
      attrList: [
        {
          name: 'Slide',
          type: 'buttonGroup',
          slug: 'slide',
          allowedValues: [
            {
              label: 'Off',
              value: '',
            },
            {
              label: 'Left',
              value: 'slide_from_left',
            },
            {
              label: 'Right',
              value: 'slide_from_right',
            },
          ],
        },
        {
          name: 'Fade',
          type: 'buttonGroup',
          slug: 'fade',
          allowedValues: [
            {
              label: 'Off',
              value: '',
            },
            {
              label: 'In',
              value: 'fade_in',
            },
          ],
        },
        {
          name: 'Delay \n(in seconds)',
          type: 'number',
          slug: 'delay',
          defaultValue: 0,
        },
      ],
    },
  },
  animationDelay: true,
  margin: {
    helperText:
      'Use this to provide spacing between and around individual elements',
    forDevices: ['desktop', 'tablet', 'mobile'],
  },
  marginTablet: true,
  marginMobile: true,
}

export const Typo = (props) => {
  const {
    settings = { ...DEFAULT_SETTINGS },
    name = '',
    disabled = false,
    slug = '',
    onChange,
    value = {},
    device = '',
    variant = 'heading',
    path = '',
  } = props
  const commonProps = {
    onChange,
    parentSlug: slug,
    disabled,
    device,
  }

  const commonSectionStyles = { p: 1.5 }
  const plainSectionStyles = { ...commonSectionStyles }
  const filledSectionStyles = { ...commonSectionStyles, background: '#F6F6F6' }

  return (
    <Paper data-testid="typo-wrapper" sx={{ boxShadow: 4, borderRadius: 0 }}>
      <Box sx={plainSectionStyles}>
        <Label
          name={`${name} Settings`}
          helperText={`${settings?.text?.helperText}${
            variant === 'body' || variant === 'caption'
              ? ''
              : '. Selective words CANNOT be formatted.'
          }`}
          disabled={disabled}
        />
        <Box
          sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 0.5 }}
        >
          <Grid container>
            <Grid item xs={12}>
              <DataConnectivityIndicator flag={`${path}.text`}>
                <>
                  {variant && (variant === 'body' || variant === 'caption') ? (
                    <RichText
                      {...commonProps}
                      slug={`${slug}.text`}
                      value={value?.text}
                      disabled={disableControlsBasedOnDevice(
                        device,
                        settings?.text?.forDevices,
                        disabled,
                      )}
                      {...settings?.text}
                    />
                  ) : (
                    <Text
                      {...commonProps}
                      slug={`${slug}.text`}
                      value={value?.text}
                      disabled={disableControlsBasedOnDevice(
                        device,
                        settings?.text?.forDevices,
                        disabled,
                      )}
                      {...settings?.text}
                    />
                  )}
                </>
              </DataConnectivityIndicator>
            </Grid>
            <Grid
              item
              sx={{ width: '62.5%', borderRight: '3px solid #C7C7C7' }}
            >
              <AttrButtonGroup
                {...commonProps}
                {...settings?.fontSize?.options}
                slug={`${slug}.fontSize`}
                value={value?.fontSize}
                disabled={disableControlsBasedOnDevice(
                  device,
                  settings?.fontSize?.forDevices,
                  disabled,
                )}
              />
            </Grid>
            <Grid item sx={{ width: '37.5%' }}>
              <Format
                {...commonProps}
                {...settings?.format?.options}
                slug={`${slug}.format`}
                value={value?.format}
                disabled={disableControlsBasedOnDevice(
                  device,
                  settings?.format?.forDevices,
                  disabled,
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <AttrButtonGroup
                {...commonProps}
                {...settings?.alignment?.options}
                slug={`${slug}.alignment`}
                disabled={disableControlsBasedOnDevice(
                  device,
                  settings?.alignment?.options?.forDevices,
                  disabled,
                )}
                value={
                  device && device !== 'desktop'
                    ? value?.[
                        `alignment${
                          device.charAt(0).toUpperCase() + device.slice(1)
                        }`
                      ]
                    : value?.alignment
                }
              />
            </Grid>
            <Grid item xs={12}>
              <AttrButtonGroup
                {...commonProps}
                {...settings?.lineHeight?.options}
                slug={`${slug}.lineHeight`}
                value={value?.lineHeight}
                disabled={disableControlsBasedOnDevice(
                  device,
                  settings?.lineHeight?.forDevices,
                  disabled,
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <AttrButtonGroup
                {...commonProps}
                {...settings?.textWrapping?.options}
                slug={`${slug}.textWrap`}
                value={value?.textWrap}
                disabled={disableControlsBasedOnDevice(
                  device,
                  settings?.textWrapping?.forDevices,
                  disabled,
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={filledSectionStyles}>
        <Label
          name={`${name} Color`}
          helperText={settings?.color?.helperText}
          disabled={disabled}
        />
        <Box sx={{}}>
          <ColorGradientPicker
            {...commonProps}
            slug={`${slug}.color`}
            value={value?.color}
            disabled={disableControlsBasedOnDevice(
              device,
              settings?.color?.forDevices,
              disabled,
            )}
          />
        </Box>
      </Box>

      <Box sx={plainSectionStyles}>
        <Label
          name={`${name} Margin`}
          helperText={settings?.margin?.helperText}
          disabled={disabled}
        />
        <Box>
          <Spacing
            variant="p"
            {...commonProps}
            slug={`${slug}`}
            value={value}
            forDevices={settings?.margin?.forDevices}
          />
        </Box>
      </Box>
      <Box sx={filledSectionStyles}>
        <Label
          name={`Enable Divider`}
          helperText={settings?.divider?.helperText}
          disabled={disabled}
        />
        <Box>
          <AttrButtonGroup
            {...commonProps}
            {...settings?.divider?.options}
            slug={`${slug}.divider.enabled`}
            value={value?.divider?.enabled}
            disabled={disableControlsBasedOnDevice(
              device,
              settings?.divider?.forDevices,
              disabled,
            )}
          />
        </Box>
      </Box>
      <ConditionalRender {...settings?.dividerControls?.options} values={value}>
        <Box sx={plainSectionStyles}>
          <Label
            name={`Divider`}
            //helperText={settings?.dividerControls?.helperText}
            disabled={disabled}
          />
          <Box>
            <TabularControl
              {...commonProps}
              {...settings?.dividerControls?.options}
              slug={`${slug}.divider`}
              value={value?.divider}
              isFromTabular={true}
            />
          </Box>
        </Box>
      </ConditionalRender>
      <Box sx={filledSectionStyles}>
        <Label
          name={`Animation`}
          helperText={settings?.animation?.helperText}
          disabled={disabled}
        />
        <Box>
          <Animation
            {...commonProps}
            {...settings?.animation?.options}
            slug={`${slug}`}
            value={{
              animation: value?.animation,
              animationDelay: value?.animationDelay,
            }}
            disabled={disableControlsBasedOnDevice(
              device,
              settings?.animation?.forDevices,
              disabled,
            )}
          />
        </Box>
      </Box>
    </Paper>
  )
}

Typo.propTypes = {
  variant: PropTypes.string,
}

export default Typo
