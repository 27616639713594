import { Box, Divider, Button, Alert } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs'
import { FormHeading } from '../../../FormComponents'
import FormForCreate from './FormForCreate'
import FormForAddingSpeaker from './FormForAddingSpeaker'
import { useParams, useNavigate } from 'react-router-dom'
import { useGetEventQuery } from '../../../../../api/event'

const pathTrail = (id) => {
  return [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/events',
      label: 'Events',
    },
    {
      to: `/events/event-setup/${id}`,
      label: 'Event Setup',
    },
    {
      to: '',
      label: 'Add new Worskhop',
    },
  ]
}

/**
 *
 * TODO: Test case needs to be added
 */
export const CreateWorkshop = (props) => {
  const [customFields, setCustomFields] = React.useState([])
  const [resetFormNow, setResetFormStatus] = React.useState(false)
  const [disableFields, setDisableFields] = React.useState(true)
  const [worskhopId, setWorkshopId] = React.useState('')
  const [startTimeVal, setStartTimeVal] = React.useState('')
  const [endTimeVal, setEndTimeVal] = React.useState('')
  const [eventData, setEventData] = React.useState(null)
  const [speakersSavedList, setSpeakersSavedList] = React.useState([])
  let { id } = useParams()
  const navigate = useNavigate()
  const resetRef = useRef()
  const addNewInput = (inp) => {
    setCustomFields([
      ...customFields,
      { type: inp, slug: '', name: '', value: '' },
    ])
  }

  const { data: event, error: eventLoadError } = useGetEventQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (event) {
      setEventData(event)
    }
  }, [event])

  useEffect(() => {
    if (resetFormNow) {
      resetRef.current.click()
      setResetFormStatus(false)
    }
  }, [resetFormNow, resetRef, setResetFormStatus])

  const resetForm = () => {
    setResetFormStatus(true)
  }

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      <Box sx={{ p: 2 }}>
        <Breadcrumbs trail={pathTrail(id)} />
        <Box sx={{ mt: 2, width: '100%', background: '#F6F7F8' }}>
          <FormHeading
            text="Create New Worskhop"
            btns={[
              {
                label: 'Add new input',
                type: 'select',
                cb: addNewInput,
                icon: 'Add',
                options: [
                  { label: 'Text input', value: 'text' },
                  { label: 'Date input', value: 'date' },
                  { label: 'Switch input', value: 'switch' },
                ],
              },
              { label: 'Reset', cb: resetForm, icon: 'Replay' },
            ]}
          />
          <Divider variant="middle" sx={{ m: 0.5 }} />
          {!eventLoadError ? (
            <>
              <Box container sx={{ display: 'flex', background: '#F6F7F8' }}>
                <FormForCreate
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  reset={resetFormNow}
                  setResetFormStatus={setResetFormStatus}
                  setDisableFields={setDisableFields}
                  setWorkshopId={(value) => setWorkshopId(value)}
                  setStartTimeVal={setStartTimeVal}
                  setEndTimeVal={setEndTimeVal}
                  event={eventData}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                  }}
                >
                  <FormForAddingSpeaker
                    disableFields={disableFields}
                    worskhopId={worskhopId}
                    startTimeVal={startTimeVal}
                    endTimeVal={endTimeVal}
                    speakersSavedList={speakersSavedList}
                    setSpeakersSavedList={setSpeakersSavedList}
                  ></FormForAddingSpeaker>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  bottom: 0,
                  background: '#fff',
                  p: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                  }}
                  color="secondary"
                  type="button"
                  className="btn btn-primary btn-block mt-4"
                  onClick={() => navigate(-1)}
                >
                  Done
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    height: 35,
                    mr: 1,
                  }}
                  className="btn btn-primary btn-block mt-4"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="reset"
                  sx={{
                    display: 'none',
                  }}
                  ref={resetRef}
                >
                  reset
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Alert severity="error">
                Unable to load event information! try reload the pageagain
              </Alert>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CreateWorkshop
