import {
  Paper,
  Typography,
  Chip,
  Stack,
  Grid,
  Box,
  Skeleton,
  IconButton,
  Select,
  SvgIcon,
  MenuItem,
} from '@mui/material'
import React, { useReducer, useRef } from 'react'
import { useGetEventMetricsByYearQuery } from '../../../api/newDashboard'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { DOWNLOADICON } from '../../../assets/Image'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { downloadCSV } from '../../../utils/helper'

const CHARTS = [
  {
    displayName: 'Attended',
    key: 'attended',
    color: '#188686',
  },
  {
    displayName: 'Rejected',
    key: 'rejected',
    color: '#D56C15',
  },
  {
    displayName: 'Pending',
    key: 'pending',
    color: '#FACE1E',
  },
  {
    displayName: 'YTR',
    key: 'ytr',
    color: '#3D70D6',
  },
  {
    displayName: 'On-Hold',
    key: 'on_hold',
    color: '#2CCECE',
  },
  {
    displayName: 'Blocked',
    key: 'blocked',
    color: '#3F9276',
  },
  {
    displayName: 'Approved',
    key: 'approved',
    color: '#4646B9',
  },
  {
    displayName: 'Auto Approved',
    key: 'auto_approved',
    color: '#1EA9A9',
  },
]

const keyDisplayNameMap = {
  attended: 'Attended',
  rejected: 'Rejected',
  pending: 'Pending',
  ytr: 'YTR',
  on_hold: 'On-Hold',
  blocked: 'Blocked',
  approved: 'Approved',
  auto_approved: 'Auto Approved',
}

const getCountByStatus = (key, eventData) => {
  switch (key) {
    case 'attended':
      return (
        eventData.internal.approved_attended +
        eventData.internal.blocked_attended +
        eventData.internal.on_hold_attended +
        eventData.internal.rejected_attended +
        eventData.internal.walkin +
        eventData.internal.ytr_attended +
        eventData.external.approved_attended +
        eventData.external.blocked_attended +
        eventData.external.on_hold_attended +
        eventData.external.rejected_attended +
        eventData.external.walkin +
        eventData.external.ytr_attended
      )
    case 'rejected':
      return eventData.internal.rejected + eventData.external.rejected
    case 'pending':
      return (
        eventData.internal.pending_approval +
        eventData.external.pending_approval
      )
    case 'ytr':
      return eventData.internal.ytr + eventData.external.ytr
    case 'on_hold':
      return eventData.internal.on_hold + eventData.external.on_hold
    case 'blocked':
      return eventData.internal.blocked + eventData.external.blocked
    case 'approved':
      return (
        eventData.internal.ytr_registered_manual_approved +
        eventData.internal.registered_hr_approved +
        eventData.internal.ytr_approved +
        eventData.internal.manual_entry +
        eventData.external.ytr_registered_manual_approved +
        eventData.external.registered_hr_approved +
        eventData.external.ytr_approved +
        eventData.external.manual_entry
      )
    case 'auto_approved':
      return (
        eventData.internal.ytr_registered_auto_approved +
        eventData.internal.registered_auto_approved +
        eventData.external.ytr_registered_auto_approved +
        eventData.external.registered_auto_approved
      )
    default:
      return 0
  }
}
const getCountByInternal = (key, eventData) => {
  switch (key) {
    case 'attended':
      return (
        eventData.internal.approved_attended +
        eventData.internal.blocked_attended +
        eventData.internal.on_hold_attended +
        eventData.internal.rejected_attended +
        eventData.internal.walkin +
        eventData.internal.ytr_attended
      )
    case 'rejected':
      return eventData.internal.rejected
    case 'pending':
      return eventData.internal.pending_approval
    case 'ytr':
      return eventData.internal.ytr
    case 'on_hold':
      return eventData.internal.on_hold
    case 'blocked':
      return eventData.internal.blocked
    case 'approved':
      return (
        eventData.internal.ytr_registered_manual_approved +
        eventData.internal.registered_hr_approved +
        eventData.internal.ytr_approved +
        eventData.internal.manual_entry
      )
    case 'auto_approved':
      return (
        eventData.internal.ytr_registered_auto_approved +
        eventData.internal.registered_auto_approved
      )
    default:
      return 0
  }
}

const getCountByExternal = (key, eventData) => {
  switch (key) {
    case 'attended':
      return (
        eventData.external.approved_attended +
        eventData.external.blocked_attended +
        eventData.external.on_hold_attended +
        eventData.external.rejected_attended +
        eventData.external.walkin +
        eventData.external.ytr_attended
      )
    case 'rejected':
      return eventData.external.rejected
    case 'pending':
      return eventData.external.pending_approval
    case 'ytr':
      return eventData.external.ytr
    case 'on_hold':
      return eventData.external.on_hold
    case 'blocked':
      return eventData.external.blocked
    case 'approved':
      return (
        eventData.external.ytr_registered_manual_approved +
        eventData.external.registered_hr_approved +
        eventData.external.ytr_approved +
        eventData.external.manual_entry
      )
    case 'auto_approved':
      return (
        eventData.external.ytr_registered_auto_approved +
        eventData.external.registered_auto_approved
      )
    default:
      return 0
  }
}

const initialParticipantsCount = {
  external: {
    approved_attended: 0,
    rejected: 0,
    ytr_approved: 0,
    on_hold: 0,
    pending_approval: 0,
    blocked: 0,
    ytr_registered_manual_approved: 0,
    ytr_registered_auto_approved: 0,
    registered_auto_approved: 0,
    registered_hr_approved: 0,
    manual_entry: 0,
    blocked_attended: 0,
    on_hold_attended: 0,
    rejected_attended: 0,
    walkin: 0,
    ytr_attended: 0,
    ytr: 0,
  },
  internal: {
    approved_attended: 0,
    rejected: 0,
    ytr_approved: 0,
    on_hold: 0,
    pending_approval: 0,
    blocked: 0,
    ytr_registered_manual_approved: 0,
    ytr_registered_auto_approved: 0,
    registered_auto_approved: 0,
    registered_hr_approved: 0,
    manual_entry: 0,
    blocked_attended: 0,
    on_hold_attended: 0,
    rejected_attended: 0,
    walkin: 0,
    ytr_attended: 0,
    ytr: 0,
  },
}

const participantReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH': {
      const participantCount = {
        approved_attended: 0,
        rejected: 0,
        ytr_approved: 0,
        on_hold: 0,
        pending_approval: 0,
        blocked: 0,
        ytr_registered_manual_approved: 0,
        ytr_registered_auto_approved: 0,
        registered_auto_approved: 0,
        registered_hr_approved: 0,
        manual_entry: 0,
        blocked_attended: 0,
        on_hold_attended: 0,
        rejected_attended: 0,
        walkin: 0,
        ytr_attended: 0,
        ytr: 0,
      }
      const dataArray = []
      action?.value.forEach((item) => {
        const { internal, external } = Object.keys(item)
          .filter((key) => ['internal', 'external'].includes(key))
          .reduce((newObj, key) => {
            newObj[key] = item[key]
            return newObj
          }, {})
        dataArray.push(internal)
        dataArray.push(external)
      })
      Object.keys(participantCount).forEach((key) => {
        participantCount[key] = dataArray
          .map((item) => item[key])
          .reduce((prev, next) => prev + next)
      })
      return participantCount
    }
    default:
      return { ...state }
  }
}

const onTrackByCategory = (key, eventData) => {
  switch (key) {
    case 'attended':
      return (
        eventData.approved_attended +
        eventData.blocked_attended +
        eventData.on_hold_attended +
        eventData.rejected_attended +
        eventData.walkin +
        eventData.ytr_attended
      )
    case 'rejected':
      return eventData.rejected
    case 'pending':
      return eventData.pending_approval
    case 'ytr':
      return eventData.ytr
    case 'on_hold':
      return eventData.on_hold
    case 'blocked':
      return eventData.blocked
    case 'approved':
      return (
        eventData.ytr_registered_manual_approved +
        eventData.registered_hr_approved +
        eventData.ytr_approved +
        eventData.manual_entry
      )
    case 'auto_approved':
      return (
        eventData.ytr_registered_auto_approved +
        eventData.registered_auto_approved
      )
    default:
      return 0
  }
}

const EventDashboardParticipantsClassifications = (props) => {
  const { allYearData } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { isLoading } = props
  const [selectedChart, setSelectedChart] = useState(['All'])
  const [selectedYear, setSelectedYear] = useState('All Year') // TODO this should be change with All year
  const [eventData, dispatch] = useReducer(
    participantReducer,
    initialParticipantsCount,
  )
  const elementRef = useRef(null)
  const [arrowDisable, setArrowDisable] = useState(true)
  const [rightArrowDisable, setRightArrowDisable] = useState(false)
  const [chartArray, setChartArray] = useState([])

  const { data: metricsData } = useGetEventMetricsByYearQuery(
    {
      eventId:
        selectedYear === 'All Year'
          ? [...Object.values(allYearData).sort().reverse()]
          : [allYearData[selectedYear]],
    },
    {
      skip: !(userInfo.lanId && activeSite._id),
    },
  )

  useEffect(() => {
    if (metricsData !== undefined) {
      dispatch({ type: 'FETCH', value: metricsData })
    }
    if (selectedYear === 'All Year') {
      setChartArray(generateChartDataAllYear(metricsData, allYearData))
    } else {
      setChartArray(generateChartDataByYear(metricsData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsData, selectedYear])

  const handleHorizontalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      element.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true)
      } else {
        setArrowDisable(false)
      }
      if (
        Math.floor(element.scrollWidth - element.scrollLeft) <=
        element.offsetWidth
      ) {
        setRightArrowDisable(true)
      } else {
        setRightArrowDisable(false)
      }
    }, speed)
  }

  const generateChartDataByYear = (metrics) => {
    const chartData = []
    metrics?.forEach((metric) => {
      CHARTS.forEach((chart) => {
        if (getCountByStatus(chart.key, metric) > 0) {
          chartData.push({
            status: chart.key,
            count: getCountByStatus(chart.key, metric),
            displayName: chart.displayName,
            color: chart.color,
          })
        }
      })
    })
    return chartData
  }

  const generateDownloadableChartDataByYear = (metrics) => {
    const chartData = []
    metrics?.forEach((metric) => {
      CHARTS.forEach((chart) => {
        if (getCountByStatus(chart.key, metric) > 0) {
          chartData.push({
            Year: selectedYear,
            Status: chart.displayName,
            Internal: getCountByInternal(chart.key, metric),
            External: getCountByExternal(chart.key, metric),
            Count: getCountByStatus(chart.key, metric),
          })
        }
      })
    })
    return chartData
  }
  const generateDownloadableChartDataAllYear = (metrics, allYear) => {
    const chartData = []
    metrics?.forEach((metric) => {
      CHARTS.forEach((chart) => {
        if (getCountByStatus(chart.key, metric) > 0) {
          chartData.push({
            Year: Object.keys(allYear).find(
              (key) => allYear[key] === metric?.eId,
            ),
            Status: chart.displayName,
            Internal: getCountByInternal(chart.key, metric),
            External: getCountByExternal(chart.key, metric),
            Count: getCountByStatus(chart.key, metric),
          })
        }
      })
    })
    return chartData
  }

  const generateChartDataAllYear = (metrics, allYear) => {
    const chartData = []
    metrics?.forEach((metric) => {
      let tempObj = {}

      tempObj['year'] = Object.keys(allYear).find(
        (key) => allYear[key] === metric?.eId,
      )
      CHARTS.forEach((chart) => {
        if (getCountByStatus(chart.key, metric) > 0) {
          tempObj[chart.key] = {
            displayName: chart.displayName,
            count: getCountByStatus(chart.key, metric),
            color: chart.color,
          }
        }
      })
      chartData.push(tempObj)
    })
    return chartData
  }

  const isChipEnableOrDisable = (data, status) => {
    if (data?.length === 0) return false

    if (metricsData?.length > 1) {
      const tmpArray = []
      for (var d of data) {
        Object.values(d).forEach((e) => {
          tmpArray.push(e)
        })
      }
      if (tmpArray.find((e) => e?.displayName === status)) {
        return false
      } else {
        return true
      }
    } else {
      if (data.find((e) => e?.displayName === status)) {
        return false
      } else {
        return true
      }
    }
  }

  return (
    <Grid item xs>
      <Paper
        sx={{
          borderRadius: '20px',
          padding: '20px',
          height: '100%',
          minHeight: '400px',
          maxHeight: '400px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Stack direction="row" gap={1}>
              <IconButton
                onClick={() => {
                  downloadCSV(
                    selectedYear === 'All Year'
                      ? generateDownloadableChartDataAllYear(
                          metricsData,
                          allYearData,
                        )
                      : generateDownloadableChartDataByYear(metricsData),
                    `participant_data_${selectedYear}`,
                  )
                }}
              >
                <img
                  src={DOWNLOADICON}
                  alt="download-report"
                  height="33px"
                  width="33px"
                />
              </IconButton>
              <div>
                <Typography fontWeight={700} color="#2B3674" fontSize={'26px'}>
                  {eventData.approved_attended +
                    eventData.rejected +
                    eventData.ytr_approved +
                    eventData.on_hold +
                    eventData.pending_approval +
                    eventData.blocked +
                    eventData.ytr_registered_manual_approved +
                    eventData.ytr_registered_auto_approved +
                    eventData.registered_auto_approved +
                    eventData.registered_hr_approved +
                    eventData.manual_entry +
                    eventData.blocked_attended +
                    eventData.on_hold_attended +
                    eventData.rejected_attended +
                    eventData.walkin +
                    eventData.ytr_attended +
                    eventData.ytr}
                </Typography>
                <Typography fontWeight={400} color="#A3AED0" fontSize={'10px'}>
                  Total Participants
                </Typography>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton
                onClick={() => {
                  handleHorizontalScroll(elementRef.current, 25, 100, -10)
                }}
                disabled={arrowDisable}
                sx={{
                  color: '#CC0000',
                }}
              >
                <ArrowBackIos fontSize="medium" />
              </IconButton>
              <div
                style={{
                  display: 'flex',
                  overflowX: 'scroll',
                  padding: '5px 0',
                  gap: '5px',
                  paddingBottom: '10px',
                }}
                ref={elementRef}
              >
                {['All', ...CHARTS.map((item) => item.displayName)].map(
                  (tableName) => (
                    <Chip
                      key={tableName}
                      label={tableName}
                      size="medium"
                      variant={
                        selectedChart.includes(tableName)
                          ? 'filled'
                          : 'outlined'
                      }
                      sx={{
                        color: selectedChart.includes(tableName)
                          ? '#ffffff'
                          : '#474747',
                        backgroundColor: selectedChart.includes(tableName)
                          ? '#CC0000'
                          : 'transparent',
                        ':hover': {
                          backgroundColor: '#CC0000',
                        },
                      }}
                      onClick={() => {
                        if (selectedChart.includes(tableName)) {
                          if (selectedChart.length === 1) {
                            setSelectedChart(['All'])
                          } else {
                            setSelectedChart([
                              ...selectedChart.filter((e) => e !== tableName),
                            ])
                          }
                        } else {
                          if (
                            selectedChart.includes('All') ||
                            tableName === 'All'
                          ) {
                            setSelectedChart([tableName])
                          } else {
                            setSelectedChart([...selectedChart, tableName])
                          }
                        }
                      }}
                      disabled={isChipEnableOrDisable(chartArray, tableName)}
                    />
                  ),
                )}
              </div>
              <IconButton
                onClick={() => {
                  handleHorizontalScroll(elementRef.current, 25, 100, 10)
                }}
                disabled={rightArrowDisable}
                sx={{ color: '#CC0000' }}
              >
                <ArrowForwardIos fontSize="medium" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end">
              <Select
                sx={{ width: 'auto', height: '30px' }}
                value={selectedYear}
                onChange={(event) => {
                  setSelectedYear(event.target.value)
                  setSelectedChart(['All'])
                }}
                renderValue={(value) => {
                  return (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <SvgIcon color="primary" fontSize="small">
                        <CalendarTodayIcon />
                      </SvgIcon>
                      {value}
                    </Box>
                  )
                }}
              >
                {['All Year', ...Object.keys(allYearData).sort().reverse()].map(
                  (year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ),
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box mt={2}>
              <Stack direction="row" gap={1} sx={{ pb: '10px', pl: '10px' }}>
                <CheckCircleRoundedIcon sx={{ color: '#05CD99' }} />
                <Typography fontWeight="bold" color="#05CD99">
                  On track
                </Typography>
              </Stack>
              <Box
                sx={{ height: 180, overflowY: 'scroll', paddingRight: '15px' }}
              >
                {CHARTS?.filter(
                  (i) => onTrackByCategory(i?.key, eventData) > 0,
                )?.map((chart) => (
                  <Stack
                    key={chart.key}
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{ pl: '15px' }}
                  >
                    <CircleRoundedIcon
                      sx={{ color: `${chart.color}CA`, fontSize: '8px' }}
                    />
                    <Typography
                      fontSize="12px"
                      color="rgb(163, 174, 208)"
                      flexGrow={1}
                      noWrap
                    >
                      {chart.displayName}
                    </Typography>
                    <Typography
                      fontSize="18px"
                      fontWeight="bold"
                      color="#2B3674"
                    >
                      {isLoading ? (
                        <Skeleton width={30} />
                      ) : eventData ? (
                        onTrackByCategory(chart.key, eventData)
                      ) : (
                        '21459'
                      )}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
              {/* TODO render different bar chart for all year data */}
              {selectedYear === 'All Year' ? (
                <BarChart
                  width={500}
                  height={300}
                  data={
                    selectedChart.includes('All')
                      ? generateChartDataAllYear(metricsData, allYearData)
                      : generateChartDataAllYear(metricsData, allYearData).map(
                          (data) =>
                            Object.keys(data)
                              .filter((key) =>
                                ['year', ...selectedChart].includes(
                                  keyDisplayNameMap[key],
                                ),
                              )
                              .reduce((newObj, key) => {
                                newObj[key] = data[key]
                                newObj['year'] = data['year']
                                return newObj
                              }, {}),
                        )
                  }
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey={'year'} />
                  <YAxis />
                  <Tooltip cursor={{ fill: 'transparent' }} />
                  {CHARTS.map((chart) => {
                    return (
                      <>
                        <Bar
                          key={`${chart.key}`}
                          dataKey={`${chart.key}.count`}
                          barSize={30}
                          fill={`${chart.color}CA`}
                          label={{ position: 'top' }}
                        />
                      </>
                    )
                  })}
                </BarChart>
              ) : (
                // generateChartDataByYear(metricsData).length === 0 ||
                //   generateChartDataByYear(metricsData).filter((item) =>
                //     selectedChart.includes(item.displayName),
                //   ).length === 0 ? (
                //   <div
                //     style={{
                //       display: 'flex',
                //       justifyContent: 'center',
                //       alignItems: 'center',
                //     }}
                //   >
                //     No Data :(
                //   </div>
                // ) :
                <BarChart
                  width={500}
                  height={300}
                  data={
                    selectedChart.includes('All')
                      ? generateChartDataByYear(metricsData)
                      : generateChartDataByYear(metricsData).filter((item) =>
                          selectedChart.includes(item.displayName),
                        )
                  }
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis
                    dataKey={'displayName'}
                    height={40}
                    angle={-40}
                    interval={0}
                    fontSize={10}
                    dy={10}
                  />
                  <YAxis width={40} />
                  <Tooltip cursor={{ fill: 'transparent' }} />
                  <Bar
                    dataKey={'count'}
                    stackId={'a'}
                    barSize={30}
                    fill={'color'}
                    label={{ position: 'top' }}
                  >
                    {generateChartDataByYear(metricsData).map(
                      (entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ),
                    )}
                  </Bar>
                </BarChart>
              )}
              {/* TODO this is will show the year by year data */}
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default EventDashboardParticipantsClassifications
