import React, { useEffect, useRef } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  FormDateField,
  FormFieldLoader,
  FormMediaField,
  FormSelect,
  FormTextField,
  FormToggle,
} from '../../../FormComponents'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useNewSpeakerMutation } from '../../../../../api/event'
import { useSelector } from 'react-redux'
import { Placeholder, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { useSnackbar } from 'notistack'
import { Check, Delete, Edit, Close } from '@mui/icons-material'
import { snakeCaseify } from '../../../../../utils/helper'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import {
  NameError,
  CompanyError,
  DesignationError,
  BioError,
  ThumbnailImageError,
  ThumbnailTitleError,
  EmailError,
} from '../../Common/Utils/ErrorUtils'

// const checkThumbnailExist = {
//   is: (file) => file,
//   then: (schema) => schema.required('Field is required'),
// }

let speakerSchema = yup.object().shape({
  name: yup.string().required(NameError),
  company: yup.string().required(CompanyError),
  designation: yup.string().required(DesignationError),
  email: yup
    .string()
    .required(EmailError)
    .matches('[a-z0-9]+@[a-z]+.[a-z]{2,3}', 'Please enter a valid email ID'),
  website: yup
    .string()
    .optional()
    .matches(
      '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
      'Please enter a valid URL',
    ),
  linkedIn_profile: yup
    .string()
    .optional()
    .matches(
      'http(s)?://([w]+.)?linkedin.com/in/[A-z0-9_-]+/?',
      'Please enter a valid URL',
    ),
  bio: yup.string().required(BioError),
  // year: yup.date().typeError('Enter valid a year').required(YearError),
  contract_end_date: yup
    .date()
    .typeError('Enter valid a date')
    .nullable()
    .optional(),
  title: yup.string().required(ThumbnailTitleError),
  titleVideo: yup.string().optional(),
  show_on_website: yup.boolean().optional(),
  engagement_image: yup.string().required(ThumbnailImageError),
  engagement_video: yup.string().optional(),
  engagement_caption: yup.string().optional(),
  engagement_transcript: yup.string().optional(),
  domain: yup.string().optional(),
  softskill: yup.string().optional(),
})

export const FormForCreate = (props) => {
  const {
    customFields,
    setCustomFields,
    reset,
    setResetFormStatus,
    skills = [],
    domains = [],
  } = props
  console.log(domains, skills)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [addNewSpeaker, { isLoading, isSuccess }] = useNewSpeakerMutation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const resetRef = useRef()
  const [editFields, setEditFields] = React.useState([])
  const [customNames, setCustomNames] = React.useState({})
  const [clearFields, setClearFields] = React.useState([])
  const navigate = useNavigate()
  let { id } = useParams()

  useEffect(() => {
    if (reset) {
      resetRef.current.click()
      setResetFormStatus(false)
    }
  }, [reset, resetRef, setResetFormStatus])

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Speaker created successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: (key) => (
          <>
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Close></Close>
            </IconButton>
          </>
        ),
      })
      navigate(-1)
    }
  }, [enqueueSnackbar, isSuccess, navigate, closeSnackbar])

  const addName = (key, value, setValues, values) => {
    if (customFields[key].name !== customNames?.[key]) {
      setClearFields([...clearFields, customFields[key].name])
    }
    setCustomFields([
      ...customFields.slice(0, key),
      {
        ...customFields[key],
        name: customNames?.[key],
        slug: `_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`,
      },
      ...customFields.slice(key + 1),
    ])
    setEditFields([...editFields.filter((val) => val !== key)])
    setValues(
      {
        ...values,
        [`_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`]:
          value,
      },
      value,
    )
  }

  const deleteCustomField = (key) => {
    setCustomFields([
      ...customFields.slice(0, key),
      ...customFields.slice(key + 1),
    ])
  }

  const filterUnusedCustomFields = (values) => {
    const customFieldSlugs = _.map(customFields, 'slug')
    const shouldBeRemoved = Object.keys(values).filter(
      (key) => key.charAt(0) === '_' && !customFieldSlugs.includes(key),
    )
    return _.omit(values, shouldBeRemoved)
  }

  return (
    <Formik
      initialValues={{
        name: '',
        company: '',
        designation: '',
        email: '',
        website: '',
        linkedIn_profile: '',
        bio: '',
        // year: '',
        contract_end_date: null,
        show_on_website: '',
        title: '',
        engagement_image: '',
        engagement_video: '',
        titleVideo: '',
        engagement_caption: '',
        engagement_transcript: '',
      }}
      validationSchema={speakerSchema}
      onSubmit={(values) => {
        const filteredValues = filterUnusedCustomFields(values)
        addNewSpeaker({
          speakerdata: {
            ...filteredValues,
            // year: values?.year.getFullYear(),
            customFields,
          },
          eventid: id,
          activeSite,
        })
      }}
    >
      {(props) => (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Form className="formik-form">
            <Box
              sx={{
                flexGrow: 1,
                p: 1,
                pl: 3,
              }}
            >
              {isLoading && <ProgressBar indeterminate />}

              <Grid container spacing={1}>
                {/* Left Section */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    sx={{ fontSize: 20, fontWeight: 700, mt: 2, mb: 3 }}
                  >
                    Speaker Basic info
                  </Typography>
                  <Box
                    sx={{
                      border: '2px dashed #B5B5B5',
                      p: 2,
                      minHeight: 906,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="name"
                          label="Speaker Name*"
                          placeholder="Speaker Name"
                          autoComplete="off"
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="company"
                          label="Company*"
                          placeholder="Company"
                          autoComplete="off"
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="designation"
                          label="Designation*"
                          placeholder="Designation"
                          autoComplete="off"
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="email"
                          label="Email ID*"
                          placeholder="Email Id"
                          autoComplete="off"
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="website"
                          label="Website"
                          placeholder="Website"
                          autoComplete="off"
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="linkedIn_profile"
                          label="LinkedIn Profile"
                          placeholder="LinkedIn Profile"
                          autoComplete="off"
                          openLink={true}
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="bio"
                          label="Bio*"
                          placeholder="Bio"
                          autoComplete="off"
                          multiline
                          rows={4}
                          component={FormTextField}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Field
                          name="year"
                          views={['year']}
                          type="date"
                          placeholder="Select year"
                        >
                          {({ field, form, meta }) => (
                            <FormDateField
                              {...field}
                              {...form}
                              label="Event Year"
                              onChange={(value) => {
                                form.setFieldValue(
                                  'year',
                                  moment(value).toDate(),
                                )
                              }}
                              views={['year']}
                            />
                          )}
                        </Field>
                      </Grid> */}
                      <Grid item xs={6}>
                        <Field
                          name="contract_end_date"
                          placeholder="Contract End Date"
                        >
                          {({ field, form, meta }) => (
                            <FormDateField
                              {...field}
                              {...form}
                              label="Contract End Date*"
                              onChange={(value) => {
                                form.setFieldValue(
                                  'contract_end_date',
                                  moment(value).toDate(),
                                )
                              }}
                              views={['day']}
                            />
                          )}
                        </Field>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Field
                          name="priority"
                          label="Priority"
                          placeholder="Select Priority"
                          autoComplete="off"
                          component={FormSelect}
                          options={_.range(15).map((val) => {
                            return {
                              disabled: false,
                              label: `${val}`,
                              value: val,
                            }
                          })}
                          style={{
                            height: 40,
                            background: 'transparent',
                            borderRadius: 4,
                            marginTop: 2,
                          }}
                          id="priority"
                          type="select"
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <Field
                          sx={{ width: 'fit-content', px: 8, py: 3 }}
                          name="show_on_website"
                          label="Show on App"
                          component={FormToggle}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="domain"
                          name="domain"
                          label="Domain"
                          component={FormSelect}
                          options={domains}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          id="skillset"
                          name="skillset"
                          label="Skill"
                          component={FormSelect}
                          options={skills}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {/* Right Section */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    sx={{ fontSize: 20, fontWeight: 700, mt: 2, mb: 3 }}
                  >
                    Media info
                  </Typography>
                  <Box
                    sx={{
                      border: '2px dashed #B5B5B5',
                      p: 2,
                      minHeight: 300,
                      my: 1,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Grid item xs={12}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                          Engagement Image Thumbnail*
                        </span>
                        <Grid
                          container
                          sx={{
                            border: '2px dashed #D6D6D6',
                            background: '#F0F0F0',
                            mt: 1,
                          }}
                        >
                          <Grid item xs={12}>
                            <Field
                              name="engagement_image"
                              label="Drop file or click to upload"
                              maxHeight={200}
                              component={FormMediaField}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              type="text"
                              name="title"
                              label="Title"
                              placeholder="Type here"
                              autoComplete="off"
                              component={FormTextField}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      border: '2px dashed #B5B5B5',
                      p: 2,
                      minHeight: 300,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Grid item xs={12}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                          Engagement Video
                        </span>
                        <Grid
                          container
                          sx={{
                            mt: 1,
                            border: '2px dashed #D6D6D6',
                            background: '#F0F0F0',
                            justifyContent: 'center',
                          }}
                        >
                          <Grid item xs={12}>
                            <Field
                              name="engagement_video"
                              label="Drop file or click to upload"
                              maxHeight={200}
                              component={FormMediaField}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              type="text"
                              name="titleVideo"
                              label="Title"
                              placeholder="Type here"
                              autoComplete="off"
                              component={FormTextField}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Typography
                              variant="body1"
                              sx={{ color: 'rgba(0, 0, 0, 0.6)', pl: 2 }}
                            >
                              Closed Caption
                            </Typography>
                            <Field
                              name="engagement_caption"
                              label="Drop file or click to upload"
                              maxHeight={50}
                              component={FormMediaField}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Typography
                              sx={{ color: 'rgba(0, 0, 0, 0.6)', pl: 2 }}
                            >
                              Transcript
                            </Typography>
                            <Field
                              name="engagement_transcript"
                              label="Drop file or click to upload"
                              maxHeight={50}
                              component={FormMediaField}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                {customFields && customFields.length > 0 && (
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontSize: 20, fontWeight: 700, mb: 1 }}
                    >
                      Custom Fields
                    </Typography>
                    <Box
                      sx={{
                        border: '2px dashed #B5B5B5',
                        p: 2,
                        minHeight: 630,
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                        }}
                      >
                        {customFields.map((customField, key) => {
                          return (
                            <Grid item xs={12} key={key}>
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {editFields.includes(key) ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <TextField
                                      sx={{ border: 0, ml: 1 }}
                                      size="small"
                                      placeholder="Enter field name"
                                      value={customNames?.[key]}
                                      onChange={(e) =>
                                        setCustomNames({
                                          ...customNames,
                                          [key]: e.target.value,
                                        })
                                      }
                                    />
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        addName(
                                          key,
                                          props.values?.[
                                            `_${snakeCaseify(
                                              customField.name,
                                            )}_${customField.type}`
                                          ],
                                          props.setValues,
                                          props.values,
                                        )
                                      }}
                                    >
                                      <Check />
                                    </IconButton>
                                  </Box>
                                ) : (
                                  <Button
                                    sx={{ textTransform: 'none' }}
                                    size="small"
                                    onClick={() =>
                                      setEditFields([...editFields, key])
                                    }
                                  >
                                    <FormLabel sx={{ ml: 1 }}>
                                      {customField.name
                                        ? customField.name
                                        : 'Add name for the field'}
                                    </FormLabel>
                                    <Edit sx={{ fontSize: 16, ml: 1 }} />
                                  </Button>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: 2,
                                }}
                              >
                                {customField?.name ? (
                                  <>
                                    {customField.type === 'date' ? (
                                      <Field
                                        name={`_${snakeCaseify(
                                          customField.name,
                                        )}_${customField.type}`}
                                        type="date"
                                        placeholder="Select"
                                      >
                                        {({ field, form, meta }) => (
                                          <FormDateField
                                            {...field}
                                            {...form}
                                            sx={{ p: 1 }}
                                            onChange={(value) => {
                                              form.setFieldValue(
                                                field.name,
                                                moment(value).toDate(),
                                              )
                                            }}
                                          />
                                        )}
                                      </Field>
                                    ) : (
                                      <Field
                                        autoComplete="off"
                                        name={`_${snakeCaseify(
                                          customField.name,
                                        )}_${customField.type}`}
                                        aria-label={customField.name}
                                        disabled={!customField.name}
                                        component={FormFieldLoader}
                                        sx={{ p: 1, pb: 1 }}
                                        type={customField.type}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Placeholder
                                    style={{
                                      flexGrow: 1,
                                      marginLeft: 8,
                                      marginTop: 3,
                                    }}
                                  >
                                    <Placeholder.Rect
                                      height="50px"
                                      emphasized
                                    />
                                  </Placeholder>
                                )}
                                <IconButton
                                  size="small"
                                  sx={{ height: 36, width: 36 }}
                                  onClick={() => deleteCustomField(key)}
                                >
                                  <Delete sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Box>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box
              sx={{
                background: '#fff',
                p: 1,
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  height: 35,
                }}
                color="secondary"
                type="submit"
                className="btn btn-primary btn-block mt-4"
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  height: 35,
                  mr: 1,
                }}
                className="btn btn-primary btn-block mt-4"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="reset"
                sx={{
                  display: 'none',
                }}
                ref={resetRef}
              >
                reset
              </Button>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export default FormForCreate
