import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, InputAdornment, TextField } from '@mui/material'
import { SearchSharp } from '@mui/icons-material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

const Search = (props) => {
  const {
    term = '',
    setTerm,
    showReset = false,
    resetSearch,
    disableReset = false,
    placeholder = '',
  } = props
  return (
    <Box data-testid="search-wrapper" sx={{ p: 1, display: 'flex', mb: 1 }}>
      <TextField
        size="small"
        value={term}
        sx={{ flexGrow: 1, mr: 1, ml: 0.5 }}
        placeholder={placeholder}
        onChange={(e) => setTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchSharp />
            </InputAdornment>
          ),
          'data-testid': 'search-field',
        }}
      />
      {showReset && (
        <Button
          sx={{ ml: 0.5, mr: 0.5, textTransform: 'none' }}
          size="small"
          variant="contained"
          color="secondary"
          data-testid="reset-btn"
          startIcon={<RestartAltIcon />}
          onClick={resetSearch}
          disabled={disableReset}
        >
          Reset All
        </Button>
      )}
    </Box>
  )
}

Search.propTypes = {
  term: PropTypes.string,
  setTerm: PropTypes.func.isRequired,
  resetSearch: function (props, propName) {
    if (
      props['showReset'] === true &&
      (props[propName] === undefined || typeof props[propName] != 'function')
    ) {
      return new Error('Please provide a resetSearch function!')
    }
  },
  showReset: PropTypes.bool,
  disableReset: PropTypes.bool,
}

export default Search
