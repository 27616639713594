import { useEffect, useRef } from 'react'
import { usePageVisibility } from 'react-page-visibility'

const useTimer = () => {
  const isVisible = usePageVisibility()

  const startTime = useRef(0)
  const timeElapsed = useRef(0)

  useEffect(() => {
    if (isVisible) {
      startTime.current = Date.now()
    } else {
      timeElapsed.current += Date.now() - startTime.current
    }
  }, [isVisible])

  const startTimer = () => {
    startTime.current = Date.now()
  }
  const endTimer = () => {
    if (startTime.current) {
      timeElapsed.current += Date.now() - startTime.current
    } else {
      timeElapsed.current = 0
    }
    return timeElapsed.current
  }

  return [startTimer, endTimer, isVisible]
}

export default useTimer
