import React from 'react'
import Backdrop from '@mui/material/Backdrop'
// import CircularProgress from '@mui/material/CircularProgress'
import LogoWhite from '../assets/Logo_Submark/Stencil_Logo-White.svg'

export const Loader = () => {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <LogoWhite style={{ width: '80px' }} />
      </Backdrop>
    </div>
  )
}

export default Loader
