import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import NoEvents from '../EventModules/Common/NoEvents/NoEvents'
import EventsCardList from './EventsCardList'
import { useSnackbar } from 'notistack'
import { useArchiveEventMutation } from '../../../api/event'
import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { NOEVENTS } from '../../../assets/Image'
import { useNavigate } from 'react-router-dom'

function TabPanel(props) {
  const { children, value, current, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== current}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      {value === current && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  current: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const EventListing = (props) => {
  const { events = [], forcedTab, actionsAllowed = {} } = props
  const { userCanEditEvent = false, userCanDeleteEvent = false } =
    actionsAllowed
  const [value, setValue] = React.useState(forcedTab ? forcedTab : 'active')
  const [activeEvents, setActiveEvents] = React.useState([])
  const [pastEvents, setPastEvents] = React.useState([])
  const { enqueueSnackbar } = useSnackbar()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const navigate = useNavigate()

  React.useEffect(() => {
    if (events && events.length > 0) {
      const active = events.filter((ev) => ev.status === 'active')
      const past = events.filter((ev) => ev.status === 'archived')
      setActiveEvents(active)
      setPastEvents(past)
    }
  }, [events])

  const [archiveEvent, { isLoading, isSuccess, isError }] =
    useArchiveEventMutation()

  React.useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Event Archived Successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
    if (isError) {
      enqueueSnackbar('Unable to archive the event!. Plese try again', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  }, [enqueueSnackbar, isSuccess, isError])

  React.useEffect(() => {
    if (forcedTab) {
      setValue(forcedTab)
    }
  }, [forcedTab])

  const navigateToCreate = () => {
    navigate('/events/create')
  }
  return (
    <Box sx={{ width: '100%' }} data-testid="event-list-wrapper">
      <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="event tabs"
          data-testid="tabs"
          sx={{
            pl: 0,
            pr: 0,
            '& .MuiTabs-flexContainer': {
              gap: '15px',
            },
            '& .Mui-selected': {
              background: '#CC0000',
              color: '#fff !important',
            },
            '& .MuiTabs-indicator': {
              height: 0,
            },
          }}
        >
          <Tab
            sx={{
              background: '#E6E7EA',
              textTransform: 'none',
              minWidth: '148px',
              minHeight: '44px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            label="Active Events"
            data-testid="active-events-btn"
            value="active"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              background: '#E6E7EA',
              textTransform: 'none',
              minWidth: '148px',
              minHeight: '44px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
            data-testid="past-events-btn"
            label="Past Events"
            value="archived"
            current={value}
            {...a11yProps(1)}
          />
        </Tabs>
        {isLoading && <ProgressBar indeterminate />}
      </Box>
      <TabPanel value="active" current={value} data-testid="active-tab">
        {activeEvents && activeEvents.length > 0 ? (
          <>
            {activeEvents.map((ae, key) => {
              return (
                <EventsCardList
                  {...ae}
                  key={key}
                  archiveEvent={() => {
                    archiveEvent({ ...ae, status: 'archived' })
                  }}
                  userCanDeleteEvent={userCanDeleteEvent}
                  userCanEditEvent={userCanEditEvent}
                />
              )
            })}
          </>
        ) : (
          <NoEvents
            img={NOEVENTS}
            label={'Event'}
            btn={{ label: 'Create New Event', cb: navigateToCreate }}
            type={value}
          ></NoEvents>
        )}
      </TabPanel>
      <TabPanel value="archived" current={value} data-testid="archived-tab">
        {pastEvents && pastEvents.length > 0 ? (
          <>
            {pastEvents.map((pe, key) => {
              return (
                <EventsCardList
                  {...pe}
                  key={key}
                  editDisabled
                  archiveEvent={() =>
                    archiveEvent({ ...pe, status: 'archived' })
                  }
                />
              )
            })}
          </>
        ) : (
          <NoEvents
            img={NOEVENTS}
            label={'Event'}
            btn={{ label: 'Create New Event', cb: navigateToCreate }}
            type={value}
          ></NoEvents>
        )}
      </TabPanel>
    </Box>
  )
}

export default EventListing
