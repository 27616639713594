import { Box } from '@mui/material'
import React, { useCallback } from 'react'
import Section from './Section'
import update from 'immutability-helper'
import apiConfig from '../../../../../apiConfig'
import { useSelector, useDispatch } from 'react-redux'
import { persistMediaList } from '../../../../../api/session'
import NoSection from '../SectionsList/NoSection'
import AddSection from '../SectionsList/AddSection'

export default function SectionListForDesign(props) {
  const {
    sections,
    selectComponentForEdit,
    onSectionClick,
    selectedComponent,
    setLayout,
    openForEdit,
    postChangesAllowed,
  } = props
  const { baseUrl } = apiConfig
  const dispatch = useDispatch()
  const savedMediaList = useSelector((state) => state.session.mediaList)

  const updateAttributes = (attributes) => {
    const newComponentData = {
      ...sections[selectedComponent.row].components[selectedComponent.col],
      attributes,
    }

    const newSectionData = {
      ...sections[selectedComponent.row],
      components: [
        ...sections[selectedComponent.row].components.slice(
          0,
          selectedComponent.col,
        ),
        newComponentData,
        ...sections[selectedComponent.row].components.slice(
          selectedComponent.col + 1,
        ),
      ],
    }

    const newLayout = [
      ...sections.slice(0, selectedComponent.row),
      newSectionData,
      ...sections.slice(selectedComponent.row + 1),
    ]

    setLayout(newLayout)
  }

  const addNewSection = () => {
    setLayout(
      [
        ...sections,
        {
          sectionName: 'New Section',
          componentWidths: [100],
          components: [],
        },
      ],
      openForEdit(sections.length),
    )
  }

  const onChangeSectionData = (data, rownum) => {
    const updated = [
      ...sections.slice(0, rownum),
      data,
      ...sections.slice(rownum + 1),
    ]
    setLayout(updated)
  }

  function filterIt(data, searchKey) {
    const searchInObj = (obj, searchVal, result) => {
      for (var p in obj) {
        if (typeof obj[p] == 'string' && obj[p].includes(searchVal)) {
          let fileNameAlone = obj[p].substring(
            obj[p].lastIndexOf('/') + 1,
            obj[p].length,
          )
          fileNameAlone = fileNameAlone.substring(
            fileNameAlone.indexOf('_') + 1,
            fileNameAlone.length,
          )
          result.push(fileNameAlone)
        } else if (typeof obj[p] == 'object') {
          searchInObj(obj[p], searchVal, result)
        }
      }
      return obj
    }
    let result = []
    searchInObj(data, searchKey, result)
    return result
  }

  const deleteSpecificSection = (secNo) => {
    const attributes = sections[secNo - 1]?.components[0]?.attributes
    let deletedMediaList = filterIt(attributes, baseUrl)
    let finalArray = savedMediaList.filter((item) => {
      return !deletedMediaList.some((e) => {
        return (
          item.path.substring(item.path.indexOf('_') + 1).toLowerCase() ===
          e.toLowerCase()
        )
      })
    })
    dispatch(persistMediaList(finalArray))
    selectComponentForEdit(null, null, true)
    setLayout((prevSections) => [
      ...prevSections.slice(0, secNo - 1),
      ...sections.slice(secNo),
    ])
  }

  const moveSection = useCallback((dragIndex, hoverIndex) => {
    setLayout((prevSections) =>
      update(prevSections, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSections[dragIndex]],
        ],
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSection = (section, key, setComponentAttr) => {
    return (
      <Section
        key={key}
        section={section}
        sectionNumber={key + 1}
        select={selectComponentForEdit}
        onSectionClick={onSectionClick}
        setComponentAttr={setComponentAttr}
        onDelete={deleteSpecificSection}
        setSection={(val) => onChangeSectionData(val, key)}
        selectedComponent={
          selectedComponent && selectedComponent.row === key
            ? selectedComponent
            : null
        }
        // openSection={openSection}
        moveSection={moveSection}
        postChangesAllowed={postChangesAllowed}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  return (
    <Box sx={{ p: 2 }}>
      {sections && sections.length === 0 ? (
        <>
          <NoSection />
          <AddSection onClickAddSection={addNewSection} />
        </>
      ) : (
        <>
          <Box>
            {sections.map((section, key) => {
              return renderSection(section, key, updateAttributes)
            })}
          </Box>
          <AddSection
            onClickAddSection={addNewSection}
            postChangesAllowed={postChangesAllowed}
          />
        </>
      )}
    </Box>
  )
}
