import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f1f8e9',
    color: '#000',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #9ccc65',
    borderRadius: 5,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#9ccc65',
  },
}))

export default HtmlTooltip
