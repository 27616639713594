import React from 'react'
import { Box, Card } from '@mui/material'
import { useAnalytics } from '@praxis/component-analytics'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

//TODO Dynamic component Loading
// import textBlockComponent from './textBlock.json'
// import bannerComponent from './banner.json'
import GroupLoader from './GroupLoader'
import { objectMerge } from '../../../../../utils/dataParser'
import { useGetComponentBySlugQuery } from '../../../../../api/component'
import ListLoader from '../../../../../components/ListLoader'
import { disableVisualEdit } from '../../../../../api/pageLayout'
import { useDispatch } from 'react-redux'

const LoadComponentFieldsDynamic = (props) => {
  const {
    componentAttr,
    setComponentAttr,
    editAllowed = false,
    device = '',
    setFieldMap,
  } = props
  const { attributes, slug } = componentAttr
  const dispatch = useDispatch()

  const { trackEvent } = useAnalytics()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  let { id: pageID } = useParams()
  const onChangeAttr = (elementName, value, multi, position) => {
    if (process.env.REACT_APP_ENV === 'prod') {
      trackEvent({
        customMetrics: {
          metric1: activeSite._id,
          metric2: pageID,
          metric3: '',
        },
        customInteraction: {
          key: elementName,
          value: value,
        },
        event: {
          name: 'update_attribute',
          type: 'click',
        },
      })
    }
    dispatch(disableVisualEdit())
    const updated = objectMerge(attributes, elementName, value)
    setComponentAttr(updated)
  }

  const { data: componentData, isLoading: componentDataLoading } =
    useGetComponentBySlugQuery({ slug })

  return (
    <Box sx={{ width: '100%' }}>
      {componentDataLoading || componentData.slug !== slug ? (
        <ListLoader />
      ) : (
        <Card sx={{ borderRadius: 0 }}>
          {componentData && componentData.globalSettings && (
            <GroupLoader
              elements={componentData.globalSettings}
              values={attributes}
              onChangeAttr={onChangeAttr}
              editAllowed={editAllowed}
              device={device}
            />
          )}
          {componentData && componentData.elements && (
            <GroupLoader
              elements={componentData.elements}
              values={attributes}
              onChangeAttr={onChangeAttr}
              editAllowed={editAllowed}
              device={device}
              setFieldMap={setFieldMap}
              path={''}
            />
          )}
        </Card>
      )}
    </Box>
  )
}

export default LoadComponentFieldsDynamic
