import React from 'react'
import {
  Button,
  Typography,
  Alert,
  Snackbar,
  LinearProgress,
} from '@mui/material'
import Box from '@mui/material/Box'
import UserList from './UserList'
import { useSelector } from 'react-redux'
import { useGetRoleQuery } from '../../api/role'
import withRights from '../../components/withRights'
import AccessDenied from '../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import ListLoader from '../../components/ListLoader'
import Loader from '../../components/Loader'

const Users = (props) => {
  const { rights, rightsLoading, noSiteAccess = false } = props
  const { superRights, permittedActions, moduleAccess } = rights
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: roles, isLoading } = useGetRoleQuery(
    { activeSite, userInfo },
    {
      skip: !activeSite,
    },
  )
  const [changesDetected, setChangesDetected] = React.useState([])
  const [status, setStatus] = React.useState('')
  const [resetData, setResetData] = React.useState(false)
  const [saveAll, setSaveAll] = React.useState(false)
  const [saveInProgress, setSavingInProgress] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const resetGlobalUserData = () => {
    setResetData(true)
    setChangesDetected([])
    setTimeout(() => {
      setMsg('Reset on user roles completed ')
      setStatus('info')
    }, 1000)
  }

  const closeStatus = () => {
    setStatus('')
    setMsg('')
  }

  React.useEffect(() => {
    if (changesDetected && changesDetected.length > 0) {
      setResetData(false)
      setSaveAll(false)
    }
  }, [changesDetected])

  return (
    <Box sx={{ width: 'calc(100vw - 58px)' }}>
      {rightsLoading && !noSiteAccess ? (
        <Loader />
      ) : moduleAccess ? (
        <>
          <Box sx={{ display: 'flex', background: '#FFF', px: 2, py: 1 }}>
            <Box>
              <Typography
                variant={'h5'}
                component={'h3'}
                sx={{
                  fontFamily: 'Helvetica For Target',
                  fontWeight: 'Bold',
                  color: '#3D3D3D',
                  mr: 2,
                }}
              >
                User Management
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
              {status && (
                <Snackbar
                  open={status !== ''}
                  autoHideDuration={4000}
                  onClose={closeStatus}
                  vertical={'top'}
                  horizontal={'center'}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  sx={{
                    '& .MuiSnackbar-root': {
                      marginTop: 0,
                    },
                  }}
                >
                  <Alert severity={status} sx={{ width: '100%' }}>
                    {msg}
                  </Alert>
                </Snackbar>
              )}
            </Box>
            {checkUserIsAllowed('update', permittedActions, superRights) &&
              changesDetected.length > 0 && (
                <>
                  <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Button
                      variant={'contained'}
                      sx={{
                        textTransform: 'none',
                        height: 35,
                        backgroundColor: '#CC0000',
                      }}
                      onClick={() => setSaveAll(true)}
                    >
                      Save all
                    </Button>
                  </Box>
                  <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Button
                      variant={'outlined'}
                      color="secondary"
                      size={'medium'}
                      sx={{
                        textTransform: 'none',
                        marginLeft: 1,
                        marginRight: 1,
                        height: 35,
                      }}
                      onClick={resetGlobalUserData}
                    >
                      Reset
                    </Button>
                  </Box>
                </>
              )}
          </Box>
          {saveInProgress && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          {!isLoading ? (
            <Box sx={{ px: 2 }}>
              <UserList
                roles={roles}
                isLoading={isLoading}
                changesDetected={changesDetected}
                setChangesDetected={setChangesDetected}
                resetData={resetData}
                saveAll={saveAll}
                setSaveAll={setSaveAll}
                setStatus={setStatus}
                setMsg={setMsg}
                savingInProgress={setSavingInProgress}
              />
            </Box>
          ) : (
            <Box sx={{ height: '65vh' }}>
              <ListLoader numberOfBlocks={5} />
            </Box>
          )}
        </>
      ) : (
        <AccessDenied module="User Management" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'User',
  actions: [
    {
      slug: 'read',
      access: 'read',
    },
    {
      slug: 'update',
      access: 'update',
    },
  ],
}

export default withRights(Users, moduleAttr)
