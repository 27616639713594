import React from 'react'
import ListingMentorship from './ListingMentorship'
import ListingSpeakers from './ListingSpeakers'
import { Grid, Box, Typography, LinearProgress } from '@mui/material'
import moment from 'moment'

const List = (props) => {
  const {
    mentorshipListData,
    mentorshipFetching,
    mentorsFetching,
    participantsFetching,
    setMentorshipId,
    mentorshipSpeakerData,
    participantsbyMentorsData,
    allParticipantsData,
    setFilters,
    filters,
    mentorshipId,
    eventId,
    editDisabled = false,
    actionsAllowed = {},
  } = props

  const formatDate = (dateValue) => {
    let dateObj = new Date(dateValue)
    return moment(dateObj).format('ddd MMM-DD-YYYY')
  }
  return (
    <Grid
      container
      sx={{ backgroundColor: '#F6F7F8' }}
      data-testid="mentorship-list-wrapper"
    >
      <Grid
        item
        xs={4}
        sx={{ backgroundColor: '#fff', boxShadow: '0px 0px 2px 0px #888888' }}
      >
        <ListingMentorship
          eventId={eventId}
          mentorshipListData={mentorshipListData}
          setMentorshipId={setMentorshipId}
          mentorshipId={mentorshipId}
          setFilters={setFilters}
          filters={filters}
          mentorshipFetching={mentorshipFetching}
          editDisabled={editDisabled}
          allParticipantsData={allParticipantsData}
          mentorsData={mentorshipSpeakerData}
          actionsAllowed={actionsAllowed}
        ></ListingMentorship>
      </Grid>
      <Grid item xs={0.1}></Grid>
      <Grid
        item
        xs={7.9}
        sx={{ backgroundColor: '#fff', boxShadow: '0px 0px 3px 0px #888888' }}
      >
        <Box>
          {(mentorsFetching || participantsFetching) && (
            <LinearProgress></LinearProgress>
          )}
          {mentorshipSpeakerData && mentorshipSpeakerData.mentorship && (
            <Box
              sx={{
                borderBottom: '1px solid #E6E6E6',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: '#CC0000',
                  fontWeight: 'bold',
                  pt: 3,
                  pb: 2,
                  px: 3,
                }}
              >
                {mentorshipSpeakerData.mentorship.name} <span>-</span>{' '}
                <span style={{ fontSize: '16px', color: '#000' }}>
                  {formatDate(mentorshipSpeakerData.mentorship.start_date)}
                </span>
              </Typography>
            </Box>
          )}
          {mentorshipListData && mentorshipListData.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ListingSpeakers
                  mentorsData={mentorshipSpeakerData}
                  participantsbyMentorsData={participantsbyMentorsData}
                ></ListingSpeakers>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default List
