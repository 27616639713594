import React from 'react'
import FormStyledInput from './FormStyledInput'
import { Form } from '@enterprise-ui/canvas-ui-react'

export const FormSelect = ({
  field,
  form: { touched, errors, ...rest },
  sx,
  ...props
}) => (
  <FormStyledInput sx={sx}>
    <Form.Field
      error={touched?.[field.name] && errors?.[field.name] !== undefined}
      color={
        touched?.[field.name] && errors?.[field.name] !== undefined
          ? 'warning'
          : 'primary'
      }
      {...props}
      onUpdate={(_name, val) => {
        rest.setFieldValue(field.name, val)
      }}
      errorText={
        touched?.[field.name] && errors?.[field.name] !== undefined
          ? errors[field.name]
          : ''
      }
      type="select"
      value={field?.value}
    />
  </FormStyledInput>
)

export default FormSelect
