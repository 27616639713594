import {
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import React from 'react'
import defaulteventthumb from './EventAssets/thumbnail-for-event.png'
import moment from 'moment'
import styled from '@emotion/styled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Edit } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const EventInfoCard = (props) => {
  const {
    id,
    name = '',
    type = '',
    description = '',
    status = '',
    thumbnail_image = '',
    start_date,
    end_date,
    total_registrations = 0,
    pending_registrations = 0,
    editDisabled = false,
  } = props
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState(false)
  return (
    <Card
      data-testid="card-wrapper"
      sx={{
        display: 'flex',
        p: 2,
        pb: 2,
        borderBottomLeftRadius: expanded ? 20 : 10,
        borderBottomRightRadius: expanded ? 20 : 10,
        boxShadow: expanded ? '1px 5px 5px #aebcf1' : '1px 2px 2px #aebcf1',
        borderTop: '0.5px solid #ebebeb',
        position: 'relative',
        minHeight: 50,
      }}
    >
      <Collapse
        in={!expanded}
        timeout="auto"
        unmountOnExit
        mountOnEnter
        sx={{ width: '100%' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h3"
            component="h3"
            sx={{ fontSize: 24, fontWeight: 600 }}
            data-testid="event-name"
          >
            {name}
          </Typography>
          <Chip
            variant="outlined"
            label={type}
            size="small"
            data-testid="event-type"
            sx={{
              color: '#096DD9',
              border: '1px dashed #096DD9',
              borderRadius: 0,
              fontWeight: 600,
              width: 100,
              ml: 2,
            }}
          />
          <Button
            onClick={() => navigate(`/events/edit/${id}`)}
            variant="outlined"
            size="small"
            color="secondary"
            startIcon={<Edit size="small" sx={{}} />}
            sx={{
              textTransform: 'none',
              fontSize: 12,
              height: 20,
              width: 50,
              ml: 1.5,
            }}
            disabled={editDisabled}
          >
            Edit
          </Button>
        </Box>
      </Collapse>
      <ExpandMore
        sx={{ position: 'absolute', right: 0, top: 5 }}
        expand={expanded}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-label="expand"
        data-testid={'toggle-btn'}
      >
        <ExpandMoreIcon />
      </ExpandMore>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        mountOnEnter
        sx={{ width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            height: 150,
          }}
        >
          <Box sx={{ width: '10%' }}>
            <CardMedia
              data-testid="thumb-image"
              image={thumbnail_image ? thumbnail_image : defaulteventthumb}
              sx={{
                width: 120,
                height: 120,
                backgroundSize: 'contain',
                backgroundColor: '#F0F0F0',
                ml: 1,
                mr: 1,
                minHeight: 78,
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
                position: 'relative',
              }}
            >
              {status && (
                <Chip
                  data-testid="status-chip"
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    backgroundColor: '#12A14B',
                    color: '#fff',
                    fontWeight: 'bold',
                    width: '100%',
                    borderRadius: 0,
                  }}
                  variant="filled"
                  label={status}
                />
              )}
            </CardMedia>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                minWidth: 300,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ fontSize: 24, fontWeight: 600 }}
                  data-testid="event-name"
                >
                  {name}
                </Typography>
                <Chip
                  variant="outlined"
                  label={type}
                  size="small"
                  data-testid="event-type"
                  sx={{
                    color: '#096DD9',
                    border: '1px dashed #096DD9',
                    borderRadius: 0,
                    fontWeight: 600,
                    width: 100,
                    ml: 2,
                  }}
                />
                {status !== 'archived' && (
                  <Button
                    onClick={() => navigate(`/events/edit/${id}`)}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<Edit size="small" sx={{}} />}
                    sx={{
                      textTransform: 'none',
                      fontSize: 12,
                      height: 20,
                      width: 50,
                      ml: 1.5,
                    }}
                  >
                    Edit
                  </Button>
                )}
              </Box>
              <Typography
                data-testid="event-desc"
                sx={{
                  color: '#7D7D7D',
                  fontSize: 14,
                  fontWeight: 400,
                  mb: 1,
                }}
              >
                {description}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {pending_registrations !== null && (
                  <Typography
                    sx={{
                      mr: 3,
                      color: '#cc0000',
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                    data-testid="pending-reg"
                  >
                    {pending_registrations} pending approvals
                  </Typography>
                )}
                {total_registrations !== null && (
                  <Typography
                    sx={{
                      mr: 3,
                      color: '#262626',
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    {total_registrations} registrations done
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ width: '20%', display: 'flex', flexDirection: 'flex-end' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                Start
              </Typography>
              <Box
                sx={{
                  background: '#339955',
                  p: 1.5,
                  pb: 3,
                  pt: 3,
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                  border: '4px solid #EFEFF1',
                }}
              >
                {moment(start_date).format('DD-MMM-YYYY')}
              </Box>
            </Box>
            <Box sx={{ width: 60, display: 'flex', alignItems: 'center' }}>
              <Divider
                sx={{
                  width: '100%',
                  marginTop: 3,
                  borderWidth: 2,
                  borderColor: '#339955',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                End
              </Typography>
              <Box
                sx={{
                  background: '#E94141',
                  p: 1.5,
                  pb: 3,
                  pt: 3,
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                  border: '4px solid #EFEFF1',
                }}
              >
                {moment(end_date).format('DD-MMM-YYYY')}
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Card>
  )
}

export default EventInfoCard
