import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useGetCategoriesQuery,
  useNewCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} from '../../api/taxonomy'
import {
  List,
  ListItem,
  ListItemAvatar,
  IconButton,
  ListItemText,
  Typography,
  Avatar,
  Box,
  Pagination,
  ListSubheader,
  Divider,
  Card,
  TextField,
  InputAdornment,
  Button,
  LinearProgress,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import StyleIcon from '@mui/icons-material/Style'
import ListLoader from '../../components/ListLoader'
import SearchIcon from '@mui/icons-material/Search'
import EditTaxonomy from './EditTaxonomy'
import ConfirmationDialog from './DeleteConfirmation'
import { Edit } from '@mui/icons-material'

const Categories = (props) => {
  const { allow } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [filter, setSearchFilter] = React.useState({ name: '' })
  const [categories, setCategories] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState({})
  const [editOpened, setEditDialogOpen] = useState(false)
  const [newCategoryForm, openNewCategoryForm] = useState(false)
  const [currentRecords, setCurrentRecords] = useState([])
  const [selectedForDelete, setSelectedForDelete] = useState(null)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const { data: categoriesList, isLoading: categoriesLoading } =
    useGetCategoriesQuery(
      {
        filter,
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [addNew, { isLoading: inProgress }] = useNewCategoryMutation()

  const [update, { isLoading: updateInProgress }] = useUpdateCategoryMutation()

  const [deleteCategory, { isLoading: deleteInProgress }] =
    useDeleteCategoryMutation()

  useEffect(() => {
    setCurrentRecords([
      ...categories.slice((currentPage - 1) * 10, currentPage * 10),
    ])
  }, [currentPage, categories])

  useEffect(() => {
    if (categoriesList) {
      setCategories([...categoriesList])
    }
  }, [categoriesList])

  const editItem = (category) => {
    setSelectedCategory(category)
    setEditDialogOpen(true)
  }

  const onSave = (edited) => {
    update({ id: edited.id, name: edited.name, activeSite })
    setSelectedCategory({})
    setEditDialogOpen(false)
  }

  const onCloseEdit = () => {
    setSelectedCategory({})
    setEditDialogOpen(false)
  }

  const addNewCategory = (newCategory) => {
    addNew({ category: newCategory, activeSite })
    openNewCategoryForm(false)
  }

  const onCloseAdd = () => {
    openNewCategoryForm(false)
  }

  const onDeleteAction = (category) => {
    setSelectedForDelete(category)
    setDeleteConfirm(true)
  }

  const onConfirmDelete = () => {
    deleteCategory(selectedForDelete.id)
    setDeleteConfirm(false)
    setSelectedForDelete(null)
  }

  const onCancelDelete = () => {
    setDeleteConfirm(false)
    setSelectedForDelete(null)
  }

  return (
    <>
      {!categoriesLoading ? (
        <Card>
          {(inProgress || updateInProgress || deleteInProgress) && (
            <LinearProgress />
          )}
          <Box
            sx={{
              background: '#E6E7EA',
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              Available Categories
            </Typography>
            <Button
              variant="contained"
              sx={{ ml: 2, textTransform: 'none', backgroundColor: '#CC0000' }}
              size="small"
              onClick={() => openNewCategoryForm(true)}
              disabled={!allow.add}
            >
              Add New Category
            </Button>
            <Box sx={{ flexGrow: 1 }}></Box>
            <TextField
              sx={{}}
              value={filter.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchFilter({ name: e.target.value })}
              label="Search"
              variant="outlined"
              size="small"
            />
          </Box>
          <List
            dense={false}
            subheader={<ListSubheader></ListSubheader>}
            sx={{ p: 2 }}
          >
            {currentRecords && currentRecords.length > 0 ? (
              currentRecords.map((category) => (
                <React.Fragment key={category.id}>
                  <ListItem
                    secondaryAction={
                      <>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          disabled={!allow.edit}
                          onClick={() => editItem(category)}
                          sx={{ mr: 1 }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          disabled={!allow.delete}
                          onClick={() => onDeleteAction(category)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                    disabled={!allow.edit}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <StyleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={category.name} />
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <Typography variant="caption">
                  No categories available
                </Typography>
              </ListItem>
            )}
          </List>
          {categories && categories.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
              }}
            >
              <Pagination
                count={Math.ceil(categories.length / 10)}
                variant="outlined"
                color="primary"
                page={currentPage}
                onChange={(_, page) => setCurrentPage(page)}
              />
            </Box>
          )}
        </Card>
      ) : (
        <ListLoader />
      )}
      <EditTaxonomy
        open={editOpened}
        data={selectedCategory}
        save={onSave}
        title="Edit Category"
        handleClose={onCloseEdit}
      />
      <EditTaxonomy
        open={newCategoryForm}
        data={{ type: 'category' }}
        save={addNewCategory}
        title="Add New Category"
        handleClose={onCloseAdd}
      />
      <ConfirmationDialog
        open={deleteConfirm}
        onConfirm={onConfirmDelete}
        onClose={onCancelDelete}
      />
    </>
  )
}

export default Categories
