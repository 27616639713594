import React from 'react'
import { Route, Routes as DRoutes } from 'react-router-dom'
import StencilProtectedElement from './StencilProtectedElement'
import Users from '../containers/Users/Users'
// import Dashboard from '../containers/Dashboard/Dashboard'
import NewDashboard from '../containers/Dashboard/NewDashboard'
import Pages from '../containers/Pages/Pages'
import CreatePage from '../containers/Pages/Create/CreatePage'
import PageEditor from '../containers/Pages/PageEditor/PageEditor'
import UpdatePage from '../containers/Pages/Update/UpdatePage'
import MediaLibrary from '../containers/MediaLibrary/MediaLibrary'
import MenuManagement from '../containers/Menu/MenuManagement'
import CreatePost from '../containers/Post/Create/CreatePost'
import UpdatePost from '../containers/Post/UpdatePost/UpdatePost'
import PostEditor from '../containers/Post/PostEditor/PostEditor'
import Settings from '../containers/Settings/Settings'
import Reports from '../containers/Reports/Reports'
import { Box } from '@mui/system'
import DangerousIcon from '@mui/icons-material/Dangerous'
import { Typography } from '@mui/material'
import Posts from '../containers/Post/Posts'
import { useGetConfigurationBySlugQuery } from '../api/configuration'
import { useSelector } from 'react-redux'
import idx from 'idx'
import { isProductTeamUser } from '../utils/rightUtils'
import ProductDashboard from '../containers/ProductDashboard/ProductDashboard'
import Event from '../containers/Event/Event'
import { CreateEvent } from '../containers/Event/Create'
import { EditEvent } from '../containers/Event/Edit'
import { ModuleSetup } from '../containers/Event/Module'
import { CreateSpeaker } from '../containers/Event/EventModules/Speakers/Create'
import EditSpeaker from '../containers/Event/EventModules/Speakers/Edit/EditSpeaker'
import {
  CreateParticipant,
  EditParticipant,
  ReviewParticipant,
} from '../containers/Event/EventModules'

import { CreateMentorship } from '../containers/Event/EventModules/Mentorship/Create'
import { EditMentorship } from '../containers/Event/EventModules/Mentorship/Edit'

import { CreateWorkshop } from '../containers/Event/EventModules/Workshop/Create'
import { EditWorkshop } from '../containers/Event/EventModules/Workshop/Edit'
// import Emailer from '../containers/Emailer/Emailer'
import {
  EmailerReport,
  ParticipantReport,
} from '../containers/Event/EventModules/Reports'
// import { EmailBuilder } from '../containers/Emailer'
import { InvenueReports } from '../containers/Event/EventModules/Reports/Invenue'
// import EmailBuilderPOC from '../containers/Emailer/EmailBuilder/EmailBuilderPOC'

const UnAuthorizedRender = () => {
  return (
    <Box
      sx={{
        width: 'calc(100vw - 58px)',
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        minHeight: '68vh',
        flexDirection: 'column',
      }}
    >
      <DangerousIcon sx={{ fontSize: 100, color: '#ef5350' }} />
      <Typography>
        Oops! <code>APP-STNCL-USERS</code> entitlement is required to access
        this content!
      </Typography>
    </Box>
  )
}

const Routes = () => {
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { data: siteConfig } = useGetConfigurationBySlugQuery(
    {
      slug: 'site',
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )
  const { data: eventConfig } = useGetConfigurationBySlugQuery(
    {
      slug: 'event',
      activeSite,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  // const { data: emailerConfig } = useGetConfigurationBySlugQuery(
  //   {
  //     slug: 'emailer',
  //     activeSite,
  //   },
  //   {
  //     skip: !(userInfo.lanId && activeSite),
  //   },
  // )

  const isProductUser = isProductTeamUser(userInfo)

  return (
    <React.Fragment>
      <DRoutes>
        <Route
          exact
          path={isProductUser ? '/tenant-dashboard' : '/'}
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <NewDashboard />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/users"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <Users />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/pages"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <Pages />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/pages/create"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <CreatePage />{' '}
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/pages/layout/:id"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <PageEditor />{' '}
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/pages/update/:id"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <UpdatePage />{' '}
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/medialibrary"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <MediaLibrary />{' '}
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/menu"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <MenuManagement />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/posts"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <Posts />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/posts/create"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <CreatePost />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/posts/edit/:id"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <UpdatePost />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/posts/design/:id"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <PostEditor />
            </StencilProtectedElement>
          }
        />
        <Route
          exact
          path="/settings"
          element={
            <StencilProtectedElement
              allowed={['APP-STNCL-USERS']}
              renderUnauthorized={UnAuthorizedRender}
            >
              <Settings />
            </StencilProtectedElement>
          }
        />
        {idx(
          siteConfig,
          (_) => _.settings[0].value.subscriptionReportEnabled,
        ) && (
          <Route
            exact
            path="/reports"
            element={
              <StencilProtectedElement
                allowed={['APP-STNCL-USERS']}
                renderUnauthorized={UnAuthorizedRender}
              >
                <Reports />
              </StencilProtectedElement>
            }
          />
        )}
        {idx(eventConfig, (_) => _.settings[0].value.enabled) && (
          <>
            <Route
              exact
              path="/events"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <Event />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/create"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <CreateEvent />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/edit/:id"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <EditEvent />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/event-setup/:id"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <ModuleSetup />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/participants/create/:id"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <CreateParticipant />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/participants/edit/:eventid/:participantid"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <EditParticipant />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/participants/review/:eventid/:participantid"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <ReviewParticipant />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/speakers/create/:id"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <CreateSpeaker />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/speakers/edit/:id/:speakerId"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <EditSpeaker />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/workshop/create/:id"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <CreateWorkshop />
                </StencilProtectedElement>
              }
            ></Route>
            <Route
              exact
              path="/events/workshop/edit/:eventId/:workshopId"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <EditWorkshop />
                </StencilProtectedElement>
              }
            ></Route>
            <Route
              exact
              path="/events/mentorship/create/:id"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <CreateMentorship></CreateMentorship>
                </StencilProtectedElement>
              }
            ></Route>
            <Route
              exact
              path="/events/mentorship/edit/:eventId/:mentorshipId"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <EditMentorship></EditMentorship>
                </StencilProtectedElement>
              }
            ></Route>
            <Route
              exact
              path="/events/reports/emailer/:eventId"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <EmailerReport />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/reports/participant-report/:eventId"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <ParticipantReport />
                </StencilProtectedElement>
              }
            />
            <Route
              exact
              path="/events/reports/invenue-report/:eventId"
              element={
                <StencilProtectedElement
                  allowed={['APP-STNCL-USERS']}
                  renderUnauthorized={UnAuthorizedRender}
                >
                  <InvenueReports />
                </StencilProtectedElement>
              }
            />
          </>
        )}
        {/* {idx(
          emailerConfig,
          (_) =>
            _.settings[0].value.enabled && (
              <>
                <Route
                  exact
                  path="/emailer"
                  element={
                    <StencilProtectedElement
                      allowed={['APP-STNCL-USERS']}
                      renderUnauthorized={UnAuthorizedRender}
                    >
                      <Emailer />
                    </StencilProtectedElement>
                  }
                />
                <Route
                  exact
                  path="/emailer/build/:id"
                  element={
                    <StencilProtectedElement
                      allowed={['APP-STNCL-USERS']}
                      renderUnauthorized={UnAuthorizedRender}
                    >
                      <EmailBuilder />
                    </StencilProtectedElement>
                  }
                />
                <Route
                  exact
                  path="/emailer/buildpoc/:id"
                  element={
                    <StencilProtectedElement
                      allowed={['APP-STNCL-USERS']}
                      renderUnauthorized={UnAuthorizedRender}
                    >
                      <EmailBuilderPOC />
                    </StencilProtectedElement>
                  }
                />
              </>
            ),
        )} */}
        {isProductUser && (
          <Route
            exact
            path="/"
            element={
              <StencilProtectedElement
                allowed={['APP-STE-ADMIN-PROD']}
                renderUnauthorized={UnAuthorizedRender}
              >
                <ProductDashboard />
              </StencilProtectedElement>
            }
          />
        )}
      </DRoutes>
    </React.Fragment>
  )
}

export default Routes
