import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Checkbox,
  Pagination,
  CircularProgress,
  Input,
  InputAdornment,
  Box,
} from '@mui/material'
import { useGetPagesQuery } from '../../../api/page'
import { useSelector } from 'react-redux'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'

export default function PageList(props) {
  const {
    open = false,
    handleClose,
    addPage,
    reset = false,
    setReset,
    includeId = false,
    forClone = false,
  } = props
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [rowsState, setRowsState] = useState({
    page: 1,
    pageSize: 10,
    pageCount: 0,
  })
  const [searchFilter, setSearchFilter] = useState({ search_term: '' })
  const [pagesList, setPagesList] = useState([])
  const [selectedPage, setSelectedPage] = useState(null)

  const { data: pagesData, isLoading: pageListLoading } = useGetPagesQuery(
    {
      userInfo,
      activeSite,
      filter: searchFilter,
    },
    {
      skip: !(userInfo.lanId && activeSite),
    },
  )

  useEffect(() => {
    if (pagesData && pagesData.pages) {
      setPagesList([...pagesData.pages])
      setRowsState({
        ...rowsState,
        pageCount: Math.ceil(pagesData.total / rowsState.pageSize),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesData])

  const togglePageSelection = (value) => {
    if (selectedPage && selectedPage.slug === value.slug) {
      setSelectedPage(null)
    } else {
      setSelectedPage(value)
    }
  }

  const addPageToNode = () => {
    const { name, slug, _id } = selectedPage
    const extra = includeId
      ? {
          id: _id,
        }
      : {}
    addPage(
      forClone
        ? selectedPage
        : {
            name,
            slug,
            ...extra,
            children: [],
          },
    )
  }

  useEffect(() => {
    if (reset) {
      setReset(false)
      setRowsState({ ...rowsState, page: 1 })
      setSelectedPage(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, setReset])

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Select page to add</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <DialogContentText sx={{ width: '30%', color: '#000' }}>
              Pages
            </DialogContentText>
            <Box sx={{ flexGrow: 1 }} />
            <Input
              value={searchFilter.name}
              sx={{}}
              onChange={(e) => setSearchFilter({ search_term: e.target.value })}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <ManageSearchIcon />
                </InputAdornment>
              }
            />
          </Box>
          <List
            dense={true}
            sx={{ width: '100%', bgcolor: 'background.paper' }}
          >
            {pagesList &&
              pagesList.length > 0 &&
              pagesList.map((page, index) => {
                const { name, slug } = page
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      key={index}
                      onClick={() => togglePageSelection(page)}
                      disabled={selectedPage && selectedPage.slug !== slug}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="end"
                          checked={selectedPage && selectedPage.slug === slug}
                        />
                      </ListItemIcon>
                      <ListItemText primary={name} secondary={slug} />
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </React.Fragment>
                )
              })}
            {pageListLoading && <CircularProgress />}
          </List>
          <Pagination
            count={rowsState.pageCount}
            size="small"
            onChange={(_, page) => setRowsState({ ...rowsState, page })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!selectedPage}
            sx={{ textTransform: 'none' }}
            onClick={addPageToNode}
            color={'primary'}
            variant="contained"
          >
            Add
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={handleClose}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
