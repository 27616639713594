import React from 'react'
import { Box, Typography } from '@mui/material'
import { FieldLoader, disableControlsBasedOnDevice } from '../../Helpers'
import { Label } from '../Label'

export const Animation = (props) => {
  const {
    value = {},
    onChange,
    disabled = false,
    attrList,
    slug = '',
    device = '',
    forDevices = [],
    enableLabel = false,
    name = '',
    helperText = '',
  } = props
  const { animation = '', animationDelay = 0 } = value
  const [animationValues, setAnimationValues] = React.useState({
    fade_in: '',
    slide: '',
    delay: 0,
  })

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )

  const combinations = {
    '': { fade: '', slide: '' },
    fade_in: { fade: 'fade_in', slide: '' },
    slide_from_left: { fade: '', slide: 'slide_from_left' },
    slide_from_right: { fade: '', slide: 'slide_from_right' },
    slide_from_left_fade_in: {
      fade: 'fade_in',
      slide: 'slide_from_left',
    },
    slide_from_right_fade_in: {
      fade: 'fade_in',
      slide: 'slide_from_right',
    },
  }

  const onChangeAnimation = (value, key) => {
    if (key === 'delay') {
      onChange(value, `${slug}.animationDelay`)
    } else {
      const obj = { ...animationValues, [key]: value ? value : '' }
      const matched = Object.keys(combinations)
        .map((key) => {
          return combinations[key].fade === obj.fade &&
            combinations[key].slide === obj.slide
            ? key
            : null
        })
        .filter((val) => val)
      onChange(matched?.[0] ? matched?.[0] : '', `${slug}.animation`)
    }
  }

  React.useEffect(() => {
    setAnimationValues({ ...combinations[animation], delay: animationDelay })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Box data-testid="animation-attr">
      {enableLabel && (
        <Label
          data-testid="animation-attr-label"
          name={name}
          disabled={disabledOrNot}
          helperText={helperText}
        />
      )}
      {attrList &&
        attrList.map((attr, key) => {
          return (
            <Box
              key={key}
              sx={{ display: 'flex', width: '100%', minHeight: 35 }}
            >
              <Typography sx={{ fontSize: 13, width: '30%' }}>
                {attr.name}
              </Typography>
              <Box sx={{ width: '70%' }} data-testid={`field-${attr?.slug}`}>
                <FieldLoader
                  {...attr}
                  disabled={disabledOrNot}
                  value={animationValues?.[attr.slug]}
                  onChange={onChangeAnimation}
                  testid={`${attr.slug}-animation`}
                />
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

export default Animation
