import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Popover,
  Skeleton,
  Typography,
  Tooltip,
  // FormControlLabel,
  // FormControl,
  // RadioGroup,
  // FormLabel,
  // Radio,
} from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Input } from '@enterprise-ui/canvas-ui-react'
import { Send } from '@mui/icons-material'
import { useGetEmailTemplatesQuery } from '../../../../../api/emailer'
import { useSelector } from 'react-redux'

export const ListFilters = (props) => {
  const {
    chipFilters = [],
    filters = {},
    setFilters,
    selectedInfo = { count: 0 },
    callbacks = {},
    loading = false,
    disabled = false,
    downloadableDataIsLoading = false,
    flags = {},
    disableEmailSending = false,
  } = props
  const { status = [] } = filters
  const { emailIsTriggering } = flags
  const { sendEmail, onClickFilter, clickDownload } = callbacks
  const [emailer, setEmailer] = React.useState('')
  const [confirmation, setConfirmation] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChipClick = (filterToggled) => {
    const selected =
      (filterToggled.value === '' && status.length === 0) ||
      status.includes(filterToggled.value)
    setFilters({
      ...filters,
      status:
        filterToggled.value === ''
          ? []
          : selected
          ? filters.status.filter((val) => !val.includes(filterToggled.value))
          : [...filters.status, filterToggled.value],
    })
  }

  const { data: templates } = useGetEmailTemplatesQuery(
    { activeSite },
    { skip: !activeSite, refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    if (emailIsTriggering) {
      handleClose()
    }
  }, [emailIsTriggering])

  useEffect(() => {
    setEmailer('')
    setConfirmation(false)
  }, [open])
  return (
    <Box
      data-testid="filters-for-list"
      sx={{
        background: '#F6F7F8',
        height: 60,
        mt: 1,
        display: 'flex',
        alignItems: 'center',
        pl: 1,
        pr: 1,
      }}
    >
      {loading ? (
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          <Skeleton
            variant="rectangular"
            width={100}
            height={30}
            sx={{ mr: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width={100}
            height={30}
            sx={{ mr: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width={100}
            height={30}
            sx={{ mr: 2 }}
          />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          {chipFilters &&
            chipFilters.length > 0 &&
            chipFilters.map((chipFilter, key) => {
              const selected =
                (chipFilter.value === '' && status.length === 0) ||
                status.includes(chipFilter.value)
              return (
                <React.Fragment key={key}>
                  <Chip
                    label={chipFilter.label}
                    onClick={() => handleChipClick(chipFilter)}
                    variant={selected ? 'contained' : 'outlined'}
                    sx={{
                      mr: 1,
                      fontWeight: selected ? 700 : 500,
                      color: selected ? '#3D70D6' : '#474747',
                      fontSize: 14,
                    }}
                  />
                </React.Fragment>
              )
            })}
        </Box>
      )}
      {/* <Box>
        <FormControl sx={{ zoom: '80%', mr: 4 }}>
          <FormLabel id="radio-buttons-group-label">Attended</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue="all"
            name="radio-buttons-group"
            value={filters?.attended}
            row
            onChange={(e) =>
              setFilters({ ...filters, attended: e.target.value })
            }
          >
            <FormControlLabel
              value="true"
              control={<Radio size="small" />}
              label="True"
            />
            <FormControlLabel
              value="false"
              control={<Radio size="small" />}
              label="False"
            />
            <FormControlLabel
              value="all"
              control={<Radio size="small" />}
              label="Include All"
            />
          </RadioGroup>
        </FormControl>
      </Box> */}
      <Box>
        <IconButton sx={{ mr: 1 }} onClick={onClickFilter}>
          <FilterAltIcon />
        </IconButton>
        <Tooltip title="Download participants list">
          <IconButton
            sx={{ mr: 1 }}
            disabled={downloadableDataIsLoading}
            onClick={clickDownload}
          >
            <CloudDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Button
          id="send-email"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ textTransform: 'none', ml: 1, fontSize: 16, pl: 2, pr: 2 }}
          variant="outlined"
          color="secondary"
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          size={'small'}
          disabled={disabled || disableEmailSending}
        >
          Send Mailer
        </Button>
        <Popover
          id="email-menu"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{ p: 2, width: 250 }}>
            <Typography
              sx={{ fontWeight: 500, fontSize: 12, color: '#000000', mb: 1 }}
            >
              Selected Participants{' '}
              <Chip size="small" label={selectedInfo?.count} sx={{ ml: 1 }} />
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Input.Radio
              className="hc-fs-xs"
              id="email-type"
              value={emailer}
              disabled={selectedInfo?.count === 0}
              options={
                templates
                  ? templates.map((template) => {
                      return {
                        value: template?.name,
                        label: template?.subject,
                      }
                    })
                  : []
              }
              onUpdate={(_id, value) => {
                setEmailer(value)
              }}
            />
            <Divider sx={{ mt: 1 }} />
            <Input.Checkbox
              id="confirmation"
              label="I understand this action can't be reverted"
              className="hc-fs-s hc-lh-dense hc-pa-xs"
              highlight
              checked={confirmation}
              disabled={emailer === ''}
              onChange={(event) => {
                setConfirmation(event.target.checked)
              }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                sx={{ textTransform: 'none', height: 30 }}
                color="secondary"
                onClick={() => sendEmail({ emailer, confirmation })}
                disabled={!confirmation || !emailer}
                startIcon={<Send />}
              >
                Send
              </Button>
              <Button
                variant="outlined"
                sx={{ textTransform: 'none', height: 30, ml: 1 }}
                color="secondary"
                onClick={handleClose}
                disabled={!confirmation || !emailer}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  )
}

export default ListFilters
