import React, { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import NoRecordsWorkshop from '../Common/NoRecordsWorkshop/NoRecordsWorkshop'
import { NOMENTORSHIP } from '../../../../assets/Image'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { List } from './List'

import {
  useListMentorsByMentorshipsQuery,
  useListMentorshipsQuery,
  useGetParticipantsQuery,
} from '../../../../api/mentorship'
import { Loader, withRights } from '../../../../components'
import AccessDenied from '../../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../../utils/rightUtils'

const initial_values = {
  search: '',
}

const Mentorship = (props) => {
  const navigate = useNavigate()
  const {
    event = {},
    editDisabled = false,
    rights = {},
    rightsLoading,
    noSiteAccess = false,
  } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const { id = null } = event
  const [mentorshipListData, setMentorshipListData] = useState([])
  const [mentorshipId, setMentorshipId] = useState('')
  const [mentorshipSpeakerData, setMentorshipSpeakerData] = useState([])
  const [allParticipantsData, setAllParticipantsData] = useState([])
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [filters, setFilters] = useState(initial_values)

  const navigateToCreate = () => {
    navigate(`/events/mentorship/create/${id}`)
  }

  const { data: mentorshipData, isFetching: mentorshipFetching } =
    useListMentorshipsQuery(
      { eventid: id, filters, activeSite },
      { skip: !activeSite, refetchOnMountOrArgChange: true },
    )

  const { data: mentorsData, isFetching: mentorsFetching } =
    useListMentorsByMentorshipsQuery(
      {
        eventid: id,
        mentorship_id: mentorshipId,
        activeSite,
      },
      { skip: !activeSite || !mentorshipId, refetchOnMountOrArgChange: true },
    )

  const { data: participantsData, isFetching: participantsFetching } =
    useGetParticipantsQuery(
      {
        eventid: id,
        mentorship_id: mentorshipId,
        activeSite,
      },
      { skip: !activeSite || !mentorshipId, refetchOnMountOrArgChange: true },
    )

  useEffect(() => {
    if (mentorshipData) {
      setMentorshipListData(mentorshipData.data)
      if (mentorshipData.data.length > 0) {
        setMentorshipId(mentorshipData?.data[0]?._id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorshipData])

  useEffect(() => {
    if (mentorsData) {
      setMentorshipSpeakerData(mentorsData)
    }
  }, [mentorsData])

  useEffect(() => {
    if (participantsData) {
      setAllParticipantsData(participantsData.data)
    }
  }, [participantsData])

  const actionsAllowed = {
    userCanCreateMentorship: checkUserIsAllowed(
      'create',
      permittedActions,
      superRights,
    ),
    userCanEditMentorship: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
    ),
    userCanDownloadMentorshio: checkUserIsAllowed(
      'download',
      permittedActions,
      superRights,
    ),
    userCanViewMentorship: checkUserIsAllowed(
      'view',
      permittedActions,
      superRights,
    ),
  }

  return rightsLoading && !noSiteAccess ? (
    <Loader />
  ) : moduleAccess ? (
    <Box>
      <Box sx={{ textAlign: 'right', mb: 2 }}>
        <Button
          variant={'contained'}
          sx={{
            backgroundColor: '#CC0000',
            textTransform: 'none',
            fontSize: '16px',
            '&:hover': {
              background: '#CC0000',
            },
          }}
          disabled={editDisabled || !actionsAllowed.userCanCreateMentorship}
          onClick={() => navigateToCreate()}
        >
          Create a New Mentorship
        </Button>
      </Box>
      {mentorshipListData &&
      mentorshipListData.length === 0 &&
      !filters.search ? (
        <NoRecordsWorkshop
          label={'Mentorship'}
          image={NOMENTORSHIP}
          btn={{
            label: 'Create a New Mentorship',
            cb: navigateToCreate,
          }}
          navigateToCreateWorkshop={navigateToCreate}
          editDisabled={editDisabled}
          disableWorkshop={!actionsAllowed.userCanCreateMentorship}
          noContent={true}
        />
      ) : (
        <List
          eventId={id}
          mentorshipListData={mentorshipListData}
          mentorshipFetching={mentorshipFetching}
          mentorsFetching={mentorsFetching}
          participantsFetching={participantsFetching}
          setMentorshipId={setMentorshipId}
          mentorshipId={mentorshipId}
          mentorshipSpeakerData={mentorshipSpeakerData}
          allParticipantsData={allParticipantsData}
          setFilters={setFilters}
          filters={filters}
          editDisabled={editDisabled}
          actionsAllowed={actionsAllowed}
        />
      )}
    </Box>
  ) : (
    <AccessDenied module="Mentorship" />
  )
}

const moduleAttr = {
  name: 'Event',
  actions: [
    {
      slug: 'View',
      access: 'View',
    },
    {
      slug: 'Download',
      access: 'Download',
    },
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Create',
      access: 'Create',
    },
  ],
}
export default withRights(Mentorship, moduleAttr)
