import React, { useEffect } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  FormDateField,
  FormFieldLoader,
  FormMediaField,
  FormTextField,
  FormToggle,
} from '../../../FormComponents'
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import moment from 'moment'
import { useNewMentorshipMutation } from '../../../../../api/mentorship'
import { useSelector } from 'react-redux'
import { Placeholder } from '@enterprise-ui/canvas-ui-react'
import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { useSnackbar } from 'notistack'
import { Check, Delete, Edit, Close } from '@mui/icons-material'
import { snakeCaseify } from '../../../../../utils/helper'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import {
  NameError,
  NoOfSeatsError,
  VenueError,
  DescriptionError,
} from '../../Common/Utils/ErrorUtils'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const checkThumbnailExist = {
  is: (file) => file,
  then: (schema) => schema.required('Field is required'),
}

// const checkMode = {
//   is: (mode) => mode !== 'in_person',
//   then: (schema) => schema.required('Zoom link is required'),
// }

let mentorshipSchema = yup.object().shape({
  mode: yup.string().required(),
  name: yup.string().required(NameError),
  no_of_seats: yup.number().required(NoOfSeatsError),
  start_date: yup
    .date()
    .typeError('Enter a valid date')
    .nullable()
    .required('Start date is a required field'),
  venue: yup.string().required(VenueError),
  start_time: yup
    .date()
    .typeError('Enter valid time')
    .nullable()
    .required('Start time is a required field'),
  end_time: yup
    .date()
    .typeError('Enter a valid time')
    .nullable()
    .required('End time is a required field'),
  //zoom_link: yup.string().when('mode', checkMode),
  zoom_link: yup.string().optional(),
  description: yup.string().required(DescriptionError),
  show_on_website: yup.boolean(),
  thumbnail_title: yup.string().when('mentorship_image', checkThumbnailExist),
  thumbnail_alt_text: yup
    .string()
    .when('mentorship_image', checkThumbnailExist),
  mentorship_image: yup.string().optional(),
  restrictions: yup.object().shape({
    exclude_target_members: yup.boolean().optional(),
    booking_repeat_cohort: yup.boolean().optional(),
    booking_enabled: yup.boolean().optional(),
    booking_start_date: yup
      .date()
      .typeError('Enter a valid date')
      .nullable()
      .when('booking_enabled', {
        is: true,
        then: (schema) =>
          schema.required('Booking start date is a required field'),
      }),
    booking_end_date: yup
      .date()
      .typeError('Enter a valid date')
      .nullable()
      .when('booking_enabled', {
        is: true,
        then: (schema) =>
          schema.required('Booking end date is a required field'),
      }),
    bookings_per_person: yup
      .number()
      .required('Booking per person is required')
      .moreThan(0, 'Booking per person should be more than 0'),
    waiting_list_per_person_max: yup.number().default(6),
    waiting_list_per_person_min: yup.number().default(2),
    waiting_list_start_date: yup
      .date()
      .typeError('Enter a valid date')
      .nullable(),
    waiting_list_end_date: yup
      .date()
      .typeError('Enter a valid date')
      .nullable(),
  }),
})
const FormForCreate = (props) => {
  const {
    customFields,
    setCustomFields,
    setDisableFields,
    setMentorshipId,
    removeMentorsLoading,
    setStartTimeVal,
    setEndTimeVal,
    event = {},
  } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [addNewMentorship, { data: mentorshipResponse, isLoading, isSuccess }] =
    useNewMentorshipMutation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [editFields, setEditFields] = React.useState([])
  const [customNames, setCustomNames] = React.useState({})
  const [clearFields, setClearFields] = React.useState([])

  const navigate = useNavigate()
  let { id } = useParams()

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Mentorship created successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: (key) => (
          <>
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Close></Close>
            </IconButton>
          </>
        ),
      })
      setDisableFields(false)
      setMentorshipId(mentorshipResponse._id)
      setStartTimeVal(mentorshipResponse.start_time)
      setEndTimeVal(mentorshipResponse.end_time)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, isSuccess, navigate])

  const addName = (key, value, setValues, values) => {
    if (customFields[key].name !== customNames?.[key]) {
      setClearFields([...clearFields, customFields[key].name])
    }
    setCustomFields([
      ...customFields.slice(0, key),
      {
        ...customFields[key],
        name: customNames?.[key],
        slug: `_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`,
      },
      ...customFields.slice(key + 1),
    ])
    setEditFields([...editFields.filter((val) => val !== key)])
    setValues(
      {
        ...values,
        [`_${snakeCaseify(customNames?.[key])}_${customFields[key].type}`]:
          value,
      },
      value,
    )
  }

  const deleteCustomField = (key) => {
    setCustomFields([
      ...customFields.slice(0, key),
      ...customFields.slice(key + 1),
    ])
  }

  const filterUnusedCustomFields = (values) => {
    const customFieldSlugs = _.map(customFields, 'slug')
    const shouldBeRemoved = Object.keys(values).filter(
      (key) => key.charAt(0) === '_' && !customFieldSlugs.includes(key),
    )
    return _.omit(values, shouldBeRemoved)
  }

  const validateEndTime = (value, props) => {
    let error
    let startTime = moment(props.values.start_time, ['h:mm']).toDate()
    if (value) {
      if (value > startTime) {
        return (error = '')
      }
      error = 'End time cannot be same or earlier than start time'
      return error
    }
  }

  const validateMentorshipDate = (value, props) => {
    let error
    if (
      event?.start_date &&
      moment(value).isBefore(moment(event?.start_date))
    ) {
      error = `Mentorship date should be same as event start date or later. Event start date is ${moment(
        event?.start_date,
      ).format('MM/DD/yyyy')}`
    }
    return error
  }

  const validateBookingDate = (value, props) => {
    const { values = {} } = props
    let error = ''
    if (
      values?.start_date &&
      moment(values?.start_date).isBefore(moment(value))
    ) {
      error = 'Booking date should be earlier than mentorship date'
    }
    return error
  }

  const validateBookingEndDate = (value, props) => {
    const { values = {} } = props
    let error = ''
    if (
      values?.start_date &&
      moment(values?.start_date).isBefore(moment(value))
    ) {
      error = 'Booking date should be earlier than mentorship date'
    }
    if (
      values?.restrictions?.booking_start_date &&
      moment(values?.restrictions?.booking_start_date).isAfter(moment(value))
    ) {
      error = 'Booking end date should be later than start date'
    }
    return error
  }

  const validateInterestEndDate = (value, props) => {
    const { values = {} } = props
    let error = ''
    if (
      values?.restrictions?.waiting_list_start_date &&
      moment(values?.restrictions?.waiting_list_start_date).isAfter(
        moment(value),
      )
    ) {
      error = 'Interest end date should be later than start date'
    }
    return error
  }

  return (
    <Formik
      initialValues={{
        name: '',
        mode: 'hybrid',
        no_of_seats: '',
        start_date: null,
        venue: '',
        zoom_link: '',
        start_time: null,
        end_time: null,
        description: '',
        show_on_website: true,
        thumbnail_title: '',
        thumbnail_alt_text: '',
        mentorship_image: '',
        restrictions: {
          exclude_target_members: false,
          booking_repeat_cohort: false,
          booking_enabled: false,
          booking_start_date: '',
          booking_end_date: '',
          bookings_per_person: 1,
          waiting_list_per_person_max: 6,
          waiting_list_per_person_min: 2,
          waiting_list_start_date: null,
          waiting_list_end_date: null,
        },
      }}
      validationSchema={mentorshipSchema}
      onSubmit={(values) => {
        const filteredValues = filterUnusedCustomFields(values)
        addNewMentorship({
          mentorshipData: {
            ...filteredValues,
            start_time: moment(values.start_time).format('HH:mm'),
            end_time: moment(values.end_time).format('HH:mm'),
            customFields,
          },
          eventid: id,
          activeSite,
        })
      }}
    >
      {(props) => (
        <Box sx={{ display: 'flex', width: '50%' }}>
          <Form className="formik-form">
            <Box sx={{ flexGrow: 1, p: 1, pl: 3 }}>
              {(isLoading || removeMentorsLoading) && (
                <ProgressBar indeterminate />
              )}
              <Field name="mode">
                {({ field, form, meta }) => (
                  <RadioGroup
                    row
                    name="mode"
                    {...field}
                    sx={{
                      '& .Mui-checked': {
                        color: '#CC0000',
                      },
                    }}
                  >
                    <FormControlLabel
                      value="hybrid"
                      control={<Radio size="small" />}
                      label="Hybrid"
                    />
                    <FormControlLabel
                      value="in_person"
                      control={<Radio size="small" />}
                      label="In-Person"
                    />
                    <FormControlLabel
                      value="online"
                      control={<Radio size="small" />}
                      label="Online"
                    />
                  </RadioGroup>
                )}
              </Field>
            </Box>
            <Box
              sx={{
                p: 1,
                pl: 3,
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontSize: 20, fontWeight: 700, mb: 3 }}
              >
                Mentorship Basic Info
              </Typography>
              <Grid container sx={{ border: '2px dashed #B5B5B5', mt: 1 }}>
                {/* Left Section */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 1,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Grid item xs={6}>
                        <Field
                          type="text"
                          name="name"
                          label="Mentorship Name*"
                          placeholder="Enter Mentorship name"
                          autoComplete="off"
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          type="number"
                          name="no_of_seats"
                          label="No of Seats"
                          placeholder="Enter number of seats"
                          autoComplete="off"
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="start_date"
                          placeholder="Select date"
                          validate={(value) =>
                            validateMentorshipDate(value, props)
                          }
                        >
                          {({ field, form, meta }) => (
                            <FormDateField
                              {...field}
                              {...form}
                              label="Date*"
                              onChange={(value) => {
                                form
                                  .setFieldValue(
                                    'start_date',
                                    moment(value).toDate(),
                                  )
                                  .then(() => {
                                    form.setFieldTouched('start_date', true)
                                  })
                              }}
                              views={['day']}
                            />
                          )}
                        </Field>
                      </Grid>
                      {props.values.mode !== 'online' && (
                        <Grid item xs={6}>
                          <Field
                            type="text"
                            name="venue"
                            label="Event Venue"
                            placeholder="Enter venue"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <Field
                          name="start_time"
                          placeholder="Select event start time"
                        >
                          {({ field, form, meta }) => (
                            <FormDateField
                              {...field}
                              {...form}
                              type="time"
                              label="Mentorship Start Time"
                              onChange={(value) => {
                                form
                                  .setFieldValue(
                                    'start_time',
                                    moment(value).toDate(),
                                  )
                                  .then(() => {
                                    form.setFieldTouched('start_time', true)
                                  })
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="end_time"
                          placeholder="Select event end time"
                          validate={(value) => validateEndTime(value, props)}
                        >
                          {({ field, form, meta }) => (
                            <FormDateField
                              {...field}
                              {...form}
                              type="time"
                              label="Mentorship End Time"
                              onChange={(value) => {
                                form
                                  .setFieldValue(
                                    'end_time',
                                    moment(value).toDate(),
                                  )
                                  .then(() => {
                                    form.setFieldTouched('end_time', true)
                                  })
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      {props.values.mode !== 'in_person' && (
                        <Grid item xs={12}>
                          <Field
                            type="url"
                            name="zoom_link"
                            label="Zoom Link"
                            placeholder="Paste your Zoom link url here"
                            autoComplete="off"
                            component={FormTextField}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Field
                          type="text"
                          name="description"
                          label="Description"
                          placeholder="Enter description"
                          autoComplete="off"
                          multiline
                          rows={4}
                          component={FormTextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          sx={{ width: 'fit-content' }}
                          name="show_on_website"
                          label="Show on App"
                          component={FormToggle}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ p: 1 }}>
                  <Typography
                    variant="h4"
                    sx={{ fontSize: 20, fontWeight: 700, mb: 3, ml: 3 }}
                  >
                    Booking Restrictions
                  </Typography>
                  <Grid
                    container
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Grid item xs={12}>
                      <Field
                        sx={{ width: 'fit-content' }}
                        name="restrictions.exclude_target_members"
                        label="Target team members are excluded from this mentorship"
                        component={FormToggle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        sx={{ width: 'fit-content' }}
                        name="restrictions.booking_enabled"
                        label="Enable mentorship booking"
                        component={FormToggle}
                      />
                    </Grid>
                    {props.values.restrictions.booking_enabled && (
                      <Grid item xs={6}>
                        <Field
                          name="restrictions.booking_start_date"
                          placeholder="Select date"
                          validate={(value) =>
                            validateBookingDate(value, props)
                          }
                        >
                          {({ field, form, meta }) => (
                            <FormDateField
                              {...field}
                              {...form}
                              label="Start Date*"
                              onChange={(value) => {
                                form
                                  .setFieldValue(
                                    'restrictions.booking_start_date',
                                    moment(value).toDate(),
                                  )
                                  .then(() => {
                                    form.setFieldTouched(
                                      'restrictions.booking_start_date',
                                      true,
                                    )
                                  })
                              }}
                              views={['day']}
                            />
                          )}
                        </Field>
                      </Grid>
                    )}
                    {props.values.restrictions.booking_enabled === true && (
                      <Grid item xs={6}>
                        <Field
                          name="restrictions.booking_end_date"
                          placeholder="Select date"
                          validate={(value) =>
                            validateBookingEndDate(value, props)
                          }
                        >
                          {({ field, form, meta }) => (
                            <FormDateField
                              {...field}
                              {...form}
                              label="End Date*"
                              onChange={(value) => {
                                form
                                  .setFieldValue(
                                    'restrictions.booking_end_date',
                                    moment(value).toDate(),
                                  )
                                  .then(() => {
                                    form.setFieldTouched(
                                      'restrictions.booking_end_date',
                                      true,
                                    )
                                  })
                              }}
                              views={['day']}
                            />
                          )}
                        </Field>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Field
                        type="number"
                        name="restrictions.bookings_per_person"
                        label="Bookings per person"
                        placeholder="Enter number of bookings"
                        autoComplete="off"
                        component={FormTextField}
                      />
                      {props.errors.restrictions &&
                      props.touched.restrictions ? (
                        <Box
                          sx={{
                            color: '#d32f2f',
                            fontSize: '12px',
                            mt: -2,
                            ml: 3,
                          }}
                        >
                          {props.errors.restrictions.bookings_per_person}
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        type="number"
                        name="restrictions.waiting_list_per_person_min"
                        label="Min interests per person"
                        placeholder=""
                        autoComplete="off"
                        component={FormTextField}
                      />
                      {props.errors.restrictions &&
                      props.touched.restrictions ? (
                        <Box
                          sx={{
                            color: '#d32f2f',
                            fontSize: '12px',
                            mt: -2,
                            ml: 3,
                          }}
                        >
                          {
                            props.errors.restrictions
                              .waiting_list_per_person_max
                          }
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        type="number"
                        name="restrictions.waiting_list_per_person_max"
                        label="Max interests per person"
                        placeholder=""
                        autoComplete="off"
                        component={FormTextField}
                      />
                      {props.errors.restrictions &&
                      props.touched.restrictions ? (
                        <Box
                          sx={{
                            color: '#d32f2f',
                            fontSize: '12px',
                            mt: -2,
                            ml: 3,
                          }}
                        >
                          {
                            props.errors.restrictions
                              .waiting_list_per_person_max
                          }
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="restrictions.waiting_list_start_date"
                        placeholder="Select date"
                      >
                        {({ field, form, meta }) => (
                          <FormDateField
                            {...field}
                            {...form}
                            label="Interest Start Date"
                            onChange={(value) => {
                              form
                                .setFieldValue(
                                  'restrictions.waiting_list_start_date',
                                  moment(value).toDate(),
                                )
                                .then(() => {
                                  form.setFieldTouched(
                                    'restrictions.waiting_list_start_date',
                                    true,
                                  )
                                })
                            }}
                            views={['day']}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="restrictions.waiting_list_end_date"
                        placeholder="Select date"
                        validate={(value) =>
                          validateInterestEndDate(value, props)
                        }
                      >
                        {({ field, form, meta }) => (
                          <FormDateField
                            {...field}
                            {...form}
                            label="Interest End Date"
                            onChange={(value) => {
                              form
                                .setFieldValue(
                                  'restrictions.waiting_list_end_date',
                                  moment(value).toDate(),
                                )
                                .then(() => {
                                  form.setFieldTouched(
                                    'restrictions.waiting_list_end_date',
                                    true,
                                  )
                                })
                            }}
                            views={['day']}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ p: 1 }}>
                  <Box sx={{ m: 2 }}>
                    <Accordion
                      sx={{
                        background: '#F6F7F8',
                        boxShadow: '0px 0px 3px 0px #888888',
                        //  m: 3,
                      }}

                      // onChange={handleChange(item)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          variant="h4"
                          sx={{ fontSize: 20, fontWeight: 700, mb: 1 }}
                        >
                          Media info (Optional)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            p: 0,
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              display: 'flex',
                            }}
                          >
                            <Grid item xs={12}>
                              <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                Mentorship image thumbnail*
                              </span>
                              <Grid
                                container
                                sx={{
                                  my: 1,
                                  border: '2px dashed #D6D6D6',
                                  background: '#F0F0F0',
                                }}
                              >
                                <Grid item xs={12} md={4}>
                                  <Field
                                    name="mentorship_image"
                                    label="Drop file or click to upload"
                                    maxHeight={200}
                                    component={FormMediaField}
                                  />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  >
                                    <Field
                                      type="text"
                                      name="thumbnail_alt_text"
                                      label="Alt Text"
                                      placeholder="Enter alt text for the image"
                                      autoComplete="off"
                                      component={FormTextField}
                                    />
                                    <Field
                                      type="text"
                                      name="thumbnail_title"
                                      label="Title"
                                      placeholder="Enter title for the image"
                                      autoComplete="off"
                                      component={FormTextField}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>

                {customFields && customFields.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{ fontSize: 20, fontWeight: 700, mb: 1 }}
                    >
                      Custom Fields
                    </Typography>
                    <Box
                      sx={{
                        border: '2px dashed #B5B5B5',
                        p: 2,
                        minHeight: 630,
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                        }}
                      >
                        {customFields.map((customField, key) => {
                          return (
                            <Grid item xs={12} key={key}>
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {editFields.includes(key) ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <TextField
                                      sx={{ border: 0, ml: 1 }}
                                      size="small"
                                      placeholder="Enter field name"
                                      value={customNames?.[key]}
                                      onChange={(e) =>
                                        setCustomNames({
                                          ...customNames,
                                          [key]: e.target.value,
                                        })
                                      }
                                    />
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        addName(
                                          key,
                                          props.values?.[
                                            `_${snakeCaseify(
                                              customField.name,
                                            )}_${customField.type}`
                                          ],
                                          props.setValues,
                                          props.values,
                                        )
                                      }}
                                    >
                                      <Check />
                                    </IconButton>
                                  </Box>
                                ) : (
                                  <Button
                                    sx={{ textTransform: 'none' }}
                                    size="small"
                                    onClick={() =>
                                      setEditFields([...editFields, key])
                                    }
                                  >
                                    <FormLabel sx={{ ml: 1 }}>
                                      {customField.name
                                        ? customField.name
                                        : 'Add name for the field'}
                                    </FormLabel>
                                    <Edit sx={{ fontSize: 16, ml: 1 }} />
                                  </Button>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: 2,
                                }}
                              >
                                {customField?.name ? (
                                  <>
                                    {customField.type === 'date' ? (
                                      <Field
                                        name={`_${snakeCaseify(
                                          customField.name,
                                        )}_${customField.type}`}
                                        type="date"
                                        placeholder="Select"
                                      >
                                        {({ field, form, meta }) => (
                                          <FormDateField
                                            {...field}
                                            {...form}
                                            sx={{ p: 1 }}
                                            onChange={(value) => {
                                              form.setFieldValue(
                                                field.name,
                                                moment(value).toDate(),
                                              )
                                            }}
                                          />
                                        )}
                                      </Field>
                                    ) : (
                                      <Field
                                        autoComplete="off"
                                        name={`_${snakeCaseify(
                                          customField.name,
                                        )}_${customField.type}`}
                                        aria-label={customField.name}
                                        disabled={!customField.name}
                                        component={FormFieldLoader}
                                        sx={{ p: 1, pb: 1 }}
                                        type={customField.type}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Placeholder
                                    style={{
                                      flexGrow: 1,
                                      marginLeft: 8,
                                      marginTop: 3,
                                    }}
                                  >
                                    <Placeholder.Rect
                                      height="50px"
                                      emphasized
                                    />
                                  </Placeholder>
                                )}
                                <IconButton
                                  size="small"
                                  sx={{ height: 36, width: 36 }}
                                  onClick={() => deleteCustomField(key)}
                                >
                                  <Delete sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Box>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      m: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: 'none',
                        height: 35,
                        zIndex: 1,
                      }}
                      color="secondary"
                      type="submit"
                      disabled={isSuccess}
                      className="btn btn-primary btn-block mt-4"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export default FormForCreate
