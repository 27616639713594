/* eslint-disable no-unused-vars */
import {
  Clear,
  Close,
  CloudUpload,
  Delete,
  Edit,
  GridOn,
  Image,
  List as ListIcon,
  MoreVert,
  PreviewRounded,
  Check,
  FileCopy,
  InsertLink,
  Sort,
  CheckBox,
  Info,
  CloseOutlined,
  InfoOutlined,
} from '@mui/icons-material'
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  ListItemButton,
  Menu,
  MenuItem,
  Pagination,
  Slide,
  Stack,
  TextField,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Chip,
  Tooltip,
  LinearProgress,
  Link,
  FormControl,
  InputLabel,
  Select,
  Icon,
  FormControlLabel,
  Popover,
  Radio,
  Badge,
  Autocomplete,
  FormGroup,
  Checkbox,
  Switch,
  Divider,
} from '@mui/material'
import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SearchIcon from '@mui/icons-material/Search'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { FileUploader } from 'react-drag-drop-files'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import {
  useGetFilesQuery,
  useUploadfileMutation,
  useDeleteMultipleFileMutation,
  useVerifyfileMutation,
  useListDeletedFilesQuery,
  useGetPagesAndPostListQuery,
  useVerifyFilesBeforeDeleteMutation,
} from '../../api/mediaLib'
import { useGetUsedMediaQuery } from '../../api/page'
import LineDrawer from '../../components/LineDrawer'
import CircularProgress from '@mui/material/CircularProgress'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import GridList from '../../components/GridList'
import { Fade } from '@mui/material'
import MyCarousel from '../../components/MyCarousel'
import EditItem from '../../components/EditItem'
import {
  AUDIOGRID,
  AUDIOLIST,
  BGAUDIO,
  BGFILE,
  BGVIDEO,
  ICONAUDIO,
  ICONFILE,
  ICONPDF,
  ICONVIDEO,
  NOPREVIEWIMAGE,
} from '../../assets/Image'
import WithRights from '../../components/withRights'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from '../../apiConfig'
import { DialogVideo } from 'enterprise-stencil'
import noData from '../../assets/Image/no_data.gif'
import { useDropzone } from 'react-dropzone'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { getSize, formatDate } from '../../utils/helper'
import FileExistDialog from './FileExistDialog'
import DeleteDialog from './DeleteDialog'
import CustomSnackbar from './CustomSnackbar'
import FileuploadComponent from './FileuploadComponent'
import RecentlyDeleted from './RecentlyDeleted'
import { toggleEditMode } from '../../api/session'
import ShowPagesListForEachMedia from './ShowPagesListForEachMedia'
import CopyAllIcon from '@mui/icons-material/CopyAll'
const MediaLibrary = (props) => {
  const { rights } = props
  const { superRights, permittedActions } = rights
  const { isFromModal = false, callBack } = props
  const fileTypes = [
    'JPEG',
    'JPG',
    'PNG',
    'GIF',
    'PDF',
    'ZIP',
    'MP4',
    'VTT',
    'WAV',
    'MP3',
    'WEBP',
  ]
  const { media } = apiConfig
  const itemPerPage = 12
  const BASEURL = media.host
  const mediaTypesGrid = ['image']
  const mediaTypesList = ['video', 'audio', 'image']

  const [currnetTab, setCurrnetTab] = React.useState('all')
  const [itemViews, setItemViews] = React.useState('grid')
  const [currnetPage, setCurrnetPage] = React.useState(1)
  const [searchValue, setCurrnetSearchValue] = React.useState(null)
  const [totalPage, setTotalPage] = React.useState(0)
  const [pickedFile, setFile] = React.useState(null)
  const [actionOpened, setActionOpened] = React.useState()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(true)
  const [previewOpen, setPreviewOpen] = React.useState(false)
  const handleDialogOpen = () => setDialogOpen(true)
  const handleDialogClose = () => {
    setDialogOpen(false)
    setEditableItem(null)
    setCheckedState({})
  }
  const [editableItem, setEditableItem] = React.useState(null)
  const [isAlertOpen, setAlertOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [snackMsg, setSnackMsg] = React.useState(null)
  const [selectedFiles, setSelectedFiles] = React.useState(1)
  const [currentSelectedItem, setCurrentSelectedItem] = React.useState(null)
  const searchRef = React.createRef()
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userId = useSelector((state) => state?.session?.profile?._id)
  const isEditModeOn = useSelector((state) => state?.session?.isEditModeOn)
  const siteId = useSelector((state) => state.tenant.activeSite?._id)
  const [videoModelOpen, setVideoModelOpen] = React.useState(false)
  const [filterBy, setFilterBy] = React.useState('')
  const [acValue, setAcValue] = React.useState(null)
  const [sortBy, setSortBy] = React.useState('createdAt')
  const [sortOrder, setSortOrder] = React.useState(false)
  const [userList, setUserList] = React.useState([])
  const [pageAndPostList, setPageAndPostList] = React.useState([])
  const [checkedState, setCheckedState] = React.useState({})
  const [nonDeleteFiles, setNonDeleteFiles] = React.useState([])
  const [mutateItem, setMutateItem] = React.useState(null)
  const [isFileDialogOpen, setIsFileDialogOpen] = React.useState(false)
  const [existFiles, setExistFiles] = React.useState([])
  const [singlePicked, setSinglePicked] = React.useState(undefined)
  const [filterByColumn, setFilterByColumn] = React.useState('')
  const [operatorByColumn, setOperatorByColumn] = React.useState('')
  const [filterBadgeCount, setFilterBadgeCount] = React.useState(true)
  const [isRecentlyDeletedOpen, setIsRecentlyDeletedOpen] =
    React.useState(false)

  const [filesData, setFilesData] = React.useState({})

  const [anchorPopOverEl, setAnchorPopOverEl] = React.useState(null)
  const popOverRef = React.useRef(null)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorPopOverEl(popOverRef.current)
  }

  const handleEditMode = (event) => {
    dispatch(toggleEditMode())
    //reFetchFiles() //todo this need after sometime
    setCheckedState({})
  }

  const handleClose = () => {
    setAnchorPopOverEl(null)
    setFilterByColumn('')
    setOperatorByColumn('')
    setFilterBy('')
    setAcValue(null)
    setFilterBadgeCount(true)
  }

  const isPopOverOpen = Boolean(anchorPopOverEl)
  const popOverId = isPopOverOpen ? 'simple-popover' : undefined
  const filterByColumnObj = { created_by: 'Created by', page_post: 'Page/Post' }
  const operatorColumnObj = {
    contains: 'contains',
    equals: 'equals',
    starstwith: 'starts with',
    endswith: 'ends with',
  }
  const listOfSort = {
    createdAt: 'Create Date',
    size: 'Size',
  }

  const handleOnChange = (checked, value) => {
    const tempState = { ...checkedState }
    if (checked) {
      tempState[value._id] = value
    } else {
      delete tempState[value._id]
    }
    setCheckedState(tempState)
    setMutateItem(value)
  }

  const handleFliterChange = (event, value) => {
    setFilterBy(
      value !== null ? value?.page?.id || value?.page?._id || value?._id : '',
    )
    setAcValue(value)
    setFilterBadgeCount(value !== null ? false : true)
  }

  const handleFliterColumnChange = (event) => {
    setFilterBy('')
    setAcValue(null)
    setFilterByColumn(event.target.value)
  }

  const handleOperatorColumnChange = (event) => {
    setOperatorByColumn(event.target.value)
  }
  const handleSortChange = (event) => {
    setSortBy(event.target.value)
  }
  const closeVideoModel = () => {
    setVideoModelOpen(false)
  }
  const [state, setState] = React.useState({
    open: true,
    Transition: Fade,
  })

  const closeSnackbar = () => {
    setSnackMsg(null)
  }

  const openSnackbarWithMsg = (msg, type, color) => {
    setSnackMsg({ message: msg, type: type, color: color })
  }

  const changeCurrentTab = (event, newValue) => {
    setCurrnetTab(newValue)
    setCurrnetPage(1)
  }

  const changeItemViews = (evebt, newView) => {
    setItemViews(newView)
  }
  const {
    data: mediaList,
    isLoading: loadingPageData,
    isFetching: refetchIsInprogress,
    refetch: reFetchFiles,
  } = useGetFilesQuery(
    {
      currnetTab,
      currnetPage,
      searchValue,
      sortBy,
      sortOrder,
      filter: { column: filterByColumn, value: filterBy },
      userId,
      siteId,
      isEditModeOn,
    },
    { skip: !(userInfo.lanId && activeSite) },
  )

  const { data: savedMediaList, refetch: reFetchFilterFiles } =
    useGetUsedMediaQuery(
      { pageId: filterBy, revisionId: null },
      {
        refetchOnMountOrArgChange: true,
        skip: !activeSite || !filterBy,
      },
    )

  React.useEffect(() => {
    filterByColumn === 'page_post'
      ? savedMediaList &&
        setFilesData({
          total: savedMediaList[0]?.data?.length,
          files: savedMediaList[0]?.data,
          users: [],
        })
      : mediaList && setFilesData(mediaList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaList, savedMediaList])

  const [uploadFile, { isSuccess: isUploadSuccess, isLoading: isUploading }] =
    useUploadfileMutation()

  const [checkFileExist] = useVerifyfileMutation()

  const [deleteMultipleFiles] = useDeleteMultipleFileMutation()
  const [verifyFilesForDelete] = useVerifyFilesBeforeDeleteMutation()
  const { data: allPagesAndPosts } = useGetPagesAndPostListQuery(
    { siteId },
    { skip: !(userInfo.lanId && activeSite && siteId) },
  )

  const doDeleteMultipleFiles = async (body) => {
    await deleteMultipleFiles(body).then((res) => {
      res?.data?.status === 'deleted' &&
        openSnackbarWithMsg(
          'Selected Files has been Deleted Successfully',
          'success',
          '#303F9F',
        )
      setCheckedState({})
    })
  }
  const doValidateBeforeDelete = async (body) => {
    const response = await verifyFilesForDelete(body)

    if (response?.data?.data?.length > 0) {
      setNonDeleteFiles(response?.data?.data)
    } else {
      setNonDeleteFiles([])
    }
    setAlertOpen(true)
  }

  const handlePagination = (event, value) => {
    setCurrnetPage(value)
  }

  function getDecimalPart(num) {
    if (Number.isInteger(num)) {
      return num
    }

    const decimalStr = num.toString().split('.')[0]
    return Number(decimalStr)
  }

  React.useEffect(() => {
    if (filesData && filesData?.files?.length > 0) {
      if (filesData && filesData?.total > itemPerPage) {
        const temp =
          filesData?.total / itemPerPage <= 1
            ? 1
            : getDecimalPart(filesData?.total / itemPerPage)
        const exactTotalPage =
          filesData?.total % itemPerPage > 0 ? temp + 1 : temp
        setTotalPage(exactTotalPage)
      } else if (filesData && filesData?.total === 0) {
        setTotalPage(0)
      } else {
        setTotalPage(1)
      }
    } else if (filesData?.total > 0) {
      const temp =
        filesData?.total / itemPerPage <= 1
          ? 1
          : getDecimalPart(filesData?.total / itemPerPage)
      const exactTotalPage =
        filesData?.total % itemPerPage > 0 ? temp + 1 : temp
      setCurrnetPage(exactTotalPage)
    } else {
      setTotalPage(0)
    }

    if (filesData && filesData.users) {
      const arr = []
      filesData.users.forEach((element) => {
        if (element !== null) {
          arr.push(element)
        }
      })
      setUserList(arr)
    }
  }, [filesData])

  React.useEffect(() => {
    allPagesAndPosts && setPageAndPostList(allPagesAndPosts)
  }, [allPagesAndPosts])

  const deleteConfirmationAction = () => {
    let data = Object.keys(checkedState)

    if (nonDeleteFiles.length > 0) {
      data = data.filter((i) => {
        return !nonDeleteFiles.some((e) => {
          return i === e.id
        })
      })
    }

    const body = { data: data, site: siteId }
    doDeleteMultipleFiles(body)
    setActionOpened(false)
    setAnchorEl(null)
    setAlertOpen(false)
    reFetchFilterFiles()
  }
  const deleteCancelAction = () => {
    setAlertOpen(false)
    !isAlertOpen && setCheckedState({})
  }

  const deselectAll = (e, value) => {
    setCheckedState({})
  }

  const handlePreview = (value) => {
    if (isFromModal) {
      setSinglePicked(value._id)
      callBack(value)
    } else {
      if (value.type.includes('image')) {
        setSelectedItem(value)
        setPreviewOpen(true)
      } else if (
        !(value.type.includes('audio') || value.type.includes('video'))
      ) {
        window.open(`${BASEURL}${value.path}`)
      }
    }
  }
  const handleMedia = (value) => {
    return value.type.split('/')[0] === 'image'
      ? `${BASEURL}${value && value.path}`
      : value.type.includes('audio')
      ? `${BASEURL}${value && value.path}`
      : value.type.includes('video')
      ? `${BASEURL}${value && value.path}`
      : value.type.includes('pdf')
      ? `${BASEURL}${value && value.path}`
      : BGFILE
  }

  const ListView = (value, index) => {
    const mediaId = value?.id || value?._id
    return (
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!isFromModal && isEditModeOn && (
          <input
            aria-label={`${value.name.split('.')[0]}`}
            type="checkbox"
            checked={checkedState[value._id] !== undefined}
            onChange={(e) => {
              handleOnChange(e.target.checked, value)
            }}
            style={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
              accentColor: '#CC0000',
            }}
            onKeyDown={(e) => {
              e.key === 'Enter' && handleOnChange(!e?.target?.checked, value)
            }}
          />
        )}
        {isFromModal && isEditModeOn && (
          <Radio
            aria-label={`${value.name.split('.')[0]}`}
            checked={singlePicked === value._id}
            onChange={(e) => {
              setSinglePicked(e.target.value)
              callBack(value)
            }}
            value={value._id}
            name="radio-buttons"
            inputProps={{ 'aria-label': `${value.name}` }}
            onKeyDown={(e) => {
              e.key === 'Enter' && handleOnChange(!e?.target?.checked, value)
            }}
          />
        )}
        <Card
          key={`card-${value._id}`}
          variant="elevation"
          elevation={2}
          sx={{
            margin: '10px',
            flexGrow: 1,
            borderColor:
              checkedState[value._id] !== undefined || singlePicked === mediaId
                ? '#CC0000'
                : 'transparent',
            borderWidth: 4,
            borderStyle:
              checkedState[value._id] !== undefined || singlePicked === mediaId
                ? 'solid'
                : 'none',
          }}
        >
          <ListItemButton
            onClick={(e) => {
              if (isFromModal) {
                setSinglePicked(value._id)
                callBack(value)
              }
            }}
          >
            <ListItem
              key={`list-item-${value._id}`}
              secondaryAction={
                <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                  <Box sx={{ m: 0.5 }}>{`Size: ${
                    value.size ? getSize(value.size) : 'NA'
                  }`}</Box>
                  <IconButton
                    disableFocusRipple
                    aria-label={`${value.name.split('.')[0]}-copy`}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${BASEURL}${value && value.path}`,
                      )
                      openSnackbarWithMsg('Link copied', 'success', '#303F9F')
                    }}
                    sx={{ ':focus': { border: '2px dashed #666666' } }}
                  >
                    <CopyAllIcon />
                  </IconButton>
                  {<ShowPagesListForEachMedia mediaId={value} />}
                  <Chip
                    size={'small'}
                    label={
                      value &&
                      value.type &&
                      value.type.split('/').length > 1 &&
                      value.type.split('/')[1]
                    }
                    sx={{
                      borderRadius: 0,
                      justifyContent: 'end',
                    }}
                  />
                  <IconButton
                    disableFocusRipple
                    aria-haspopup="true"
                    aria-label={`${value.name.split('.')[0]}-preview`}
                    sx={{ ':focus': { border: '2px dashed #666666' } }}
                    onClick={(event) => {
                      if (value.type.includes('video')) {
                        setVideoModelOpen(!videoModelOpen)
                        setSelectedItem(value)
                      } else if (
                        mediaTypesList.some((word) => value.type.includes(word))
                      ) {
                        setSelectedItem(value)
                        setPreviewOpen(true)
                      } else {
                        //open to new window
                        window.open(`${BASEURL}${value.path}`)
                      }
                    }}
                  >
                    <PreviewRounded />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemAvatar>
                <Avatar
                  src={
                    value.type.split('/')[0] === 'image'
                      ? `${BASEURL}${value && value.path}`
                      : value.type.includes('audio')
                      ? ICONAUDIO
                      : value.type.includes('video')
                      ? ICONVIDEO
                      : value.type.includes('pdf')
                      ? ICONPDF
                      : ICONFILE
                  }
                  alt={value && value.name.split('.')[0]}
                  sx={{
                    width: 72,
                    height: 72,
                    margin: '0px 20px 0px 20px',
                    objectFit: 'cover',
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={value.name.split('.')[0]}
                secondary={
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        m: '5px 0px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        color="text.main"
                        noWrap={true}
                        sx={{ mr: 2 }}
                      >
                        {`Uploaded by : ${value?.created_by?.displayName}`}
                      </Typography>
                      |
                      <Typography
                        variant="subtitle2"
                        color="text.main"
                        noWrap={true}
                        sx={{ ml: 2 }}
                      >
                        {`${formatDate(value?.createdAt)}`}
                      </Typography>
                    </Box>
                    <Stack
                      direction={'row'}
                      spacing={1}
                      sx={{ overflowY: 'scroll' }}
                    >
                      {value.tags.length > 0 ? (
                        value.tags.map((tag, key) => {
                          return tag !== '' ? (
                            <Chip
                              size={'small'}
                              label={tag}
                              sx={{
                                borderRadius: 10,
                                m: '10px 0px',
                              }}
                            />
                          ) : (
                            <></>
                          )
                        })
                      ) : (
                        <Box
                          sx={{
                            borderRadius: 1,
                            borderWidth: 1,
                            borderStyle: 'double',
                            padding: 0.5,
                            opacity: 0,
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.main"
                            noWrap={true}
                          >
                            {'PlaceHolder'}
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </>
                }
              />
            </ListItem>
          </ListItemButton>
        </Card>
      </div>
    )
  }

  const controlMedia = (event) => {
    const playPromise = event.target.play && event.target.play()
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          event.target.pause()
        })
        .catch((error) => {
          // Auto-play was prevented
        })
    }
  }
  const GridView = (value, index) => {
    const mediaId = value?.id || value?._id
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        {!isFromModal && isEditModeOn && (
          <input
            aria-label={`${value.name.split('.')[0]}`}
            type="checkbox"
            checked={checkedState[value._id] !== undefined}
            onChange={(e) => {
              handleOnChange(e.target.checked, value)
            }}
            onKeyDown={(e) => {
              e.key === 'Enter' && handleOnChange(!e?.target?.checked, value)
            }}
            style={{
              width: 17,
              height: 17,
              cursor: 'pointer',
              margin: '10px 0px',
              ':focus': { border: '2px dashed #666666' },
              accentColor: '#CC0000',
            }}
          />
        )}
        {isFromModal && isEditModeOn && (
          <Radio
            aria-label={`${value.name.split('.')[0]}`}
            checked={singlePicked === value._id}
            onChange={(e) => {
              setSinglePicked(e.target.value)
              callBack(value)
            }}
            value={value._id}
            name="radio-buttons"
            inputProps={{ 'aria-label': `${value.name}` }}
            onKeyDown={(e) => {
              e.key === 'Enter' && handleOnChange(!e?.target?.checked, value)
            }}
            sx={{
              width: 25,
              height: 25,
              cursor: 'pointer',
              margin: '10px 0px',
              ':focus': { border: '2px dashed #666666' },
            }}
          />
        )}
        <Card
          key={`card-${value._id}`}
          variant="elevation"
          elevation={2}
          sx={{
            margin: '10px',
            maxHeight: 320,
            width: 350,
            borderColor:
              checkedState[value._id] !== undefined || singlePicked === mediaId
                ? '#CC0000'
                : 'transparent',
            borderWidth: 4,
            borderStyle:
              checkedState[value._id] !== undefined || singlePicked === mediaId
                ? 'solid'
                : 'none',

            flexGrow: 1,
          }}
        >
          <div
            tabIndex={0}
            onKeyPress={() => {
              handlePreview(value)
            }}
            style={{ focus: { border: 'thin solid black' } }}
          >
            <CardMedia
              key={`card-media-${value._id}`}
              component={
                value.type.includes('audio')
                  ? 'audio'
                  : value.type.includes('video')
                  ? 'video'
                  : value.type.includes('pdf')
                  ? 'iframe'
                  : 'img'
              }
              image={handleMedia(value)}
              autoPlay={false}
              controls
              alt={value && value.name.split('.')[0]}
              sx={{
                objectFit: 'cover',
                height: 200,
                cursor: 'pointer',
                backgroundColor: value.type.includes('audio')
                  ? '#ECB22E'
                  : 'transparent',
                '&::-webkit-media-controls-enclosure': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => {
                handlePreview(value)
              }}
              onMouseOver={(event) => event.target.play && event.target.play()}
              onMouseOut={(event) => controlMedia(event)}
            />
          </div>
          <CardContent sx={{ padding: '5px 20px', height: 70 }}>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: isFromModal ? 'pointer' : 'default',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '5px 0px',
                }}
              >
                <Chip
                  size={'small'}
                  label={
                    value &&
                    value.type &&
                    value.type.split('/').length > 1 &&
                    value.type.split('/')[1]
                  }
                  sx={{
                    borderRadius: 0,
                  }}
                />
                <Stack
                  direction={'row'}
                  spacing={1}
                  sx={{
                    overflowY: 'scroll',
                    cursor: isFromModal ? 'pointer' : 'default',
                    paddingLeft: '10px',
                    '&::-webkit-scrollbar': {
                      width: '0.4em',
                    },
                  }}
                >
                  {value.tags.length > 0 ? (
                    value.tags.map((tag) => {
                      return tag !== '' ? (
                        <Chip
                          size={'small'}
                          label={tag}
                          sx={{
                            borderRadius: 10,
                          }}
                        />
                      ) : (
                        <></>
                      )
                    })
                  ) : (
                    <Box
                      sx={{
                        borderRadius: 1,
                        borderWidth: 1,
                        borderStyle: 'double',
                        padding: 0.5,
                        opacity: 0,
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.main"
                        noWrap={true}
                      >
                        {'PlaceHolder'}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Box>
              <Tooltip title={value.name.split('.')[0]}>
                <Typography
                  gutterBottom
                  // variant="h6"
                  component="div"
                  color="text.secondary"
                  noWrap={true}
                  sx={{}}
                >
                  {value.name.split('.')[0]}
                </Typography>
              </Tooltip>
            </div>
          </CardContent>
          <CardActions
            sx={{
              padding: '10px 15px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <IconButton
                disableFocusRipple
                aria-label={`${value.name.split('.')[0]}-copy`}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${BASEURL}${value && value.path}`,
                  )
                  openSnackbarWithMsg('Link copied', 'success', '#303F9F')
                }}
                sx={{ ':focus': { border: '2px dashed #666666' } }}
              >
                <CopyAllIcon />
              </IconButton>
              {<ShowPagesListForEachMedia mediaId={value} />}
            </Box>

            <Box>{`Size: ${value.size ? getSize(value.size) : 'NA'}`}</Box>
          </CardActions>
        </Card>
      </div>
    )
  }

  const onDropAccepted = React.useCallback(
    async (files) => {
      if (isEditModeOn) {
        var tempArr = []
        for (var i = 0; i < files.length; i++) {
          var temp = { key: `${Date.now()}-${Math.random()}`, file: files[i] }
          tempArr.push(temp)
        }
        setExistFiles([...tempArr])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isEditModeOn],
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    // accept: { fileTypes },
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'audio/mpeg': [],
      'image/png': [],
      'image/webp': [],
      'video/mp4': [],
      'image/gif': [],
      'application/msword': [],
      'application/pdf': [],
      'text/vtt': [],
      'text/srt': [],
      'application/x-zip': [],
    },
    noClick: true,
    onDropAccepted,
  })

  const onSuccess = (res, key) => {
    const tempState = existFiles.filter((item) => item.key !== key)
    setExistFiles(tempState)
    reFetchFiles()
    if (res) {
      setTimeout(() => {
        reFetchFiles()
        res?.data?.id
          ? openSnackbarWithMsg(
              'File uploaded successfully',
              'success',
              '#303F9F',
            )
          : openSnackbarWithMsg()
      }, 1000)
    }
  }

  const binCallback = () => {
    setIsRecentlyDeletedOpen(false)
    setCurrnetSearchValue(null)
  }

  return (
    <>
      {isEditModeOn && existFiles?.length > 0 && (
        <FileuploadComponent data={existFiles[0]} onSuccess={onSuccess} />
      )}

      <DeleteDialog
        open={isAlertOpen}
        close={setAlertOpen}
        deleteConfirmationAction={deleteConfirmationAction}
        deleteCancelAction={deleteCancelAction}
        deleteItemCount={Object.keys(checkedState).length}
        nonDeleteFiles={nonDeleteFiles}
      />
      {editableItem && (
        <EditItem
          open={dialogOpen}
          close={handleDialogClose}
          data={editableItem}
          callBack={(msg) => {
            openSnackbarWithMsg(msg, 'success', '#303F9F')
            setCheckedState({})
            reFetchFilterFiles()
          }}
        />
      )}
      {snackMsg && (
        <CustomSnackbar open={true} close={closeSnackbar} msg={snackMsg} />
      )}

      {selectedItem && (
        <>
          <MyCarousel
            shouldArrowShow={true}
            itemPicked={selectedItem}
            items={[
              selectedItem && `${BASEURL}${selectedItem && selectedItem.path}`,
            ]}
            open={previewOpen}
            close={setPreviewOpen}
            itemName={selectedItem?.name?.split('.')[0]}
          />
          <DialogVideo
            open={videoModelOpen}
            video={{
              title: 'SAMPLE TITLE',
              poster: 'Sample Banner',
              source: [`${BASEURL}${selectedItem.path}`],
              tracks: [],
              transcript: [],
            }}
            close={closeVideoModel}
          />
        </>
      )}
      {isRecentlyDeletedOpen ? (
        <RecentlyDeleted
          BASEURL={BASEURL}
          callBack={binCallback}
          openSnackbarWithMsg={openSnackbarWithMsg}
        />
      ) : (
        <Box
          sx={{
            width: !isFromModal ? 'calc(100vw - 50px)' : '100%',
          }}
        >
          <Stack spacing={2}>
            {/* Media library label */}
            <Box
              sx={{
                display: 'flex',
                p: 2,
                background: '#FFF',
                height: '90px',
              }}
            >
              <Box>
                <Typography
                  variant={'h2'}
                  sx={{
                    fontFamily: 'Helvetica For Target',
                    fontWeight: 'Bold',
                    color: '#3D3D3D',
                    position: 'relative',
                    top: 15,
                    fontSize: '1.5rem',
                  }}
                >
                  Media Library
                </Typography>
              </Box>
              <TextField
                sx={{
                  ml: 5,
                  justifyContent: 'center',
                  ':hover': { outlineStyle: 'dotted', outline: '1px' },
                  ':focus': { border: '2px dashed #666666' },
                }}
                placeholder="Search…"
                variant="standard"
                size="small"
                inputProps={{ 'aria-label': 'search' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setCurrnetSearchValue(e.target.value)
                }}
              />
              <Box sx={{ flexGrow: 1 }} />
              {isEditModeOn && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '30px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: '50px',
                  }}
                >
                  <Button
                    disableFocusRipple
                    variant={'contained'}
                    onClick={() => open()}
                    startIcon={<CloudUpload fontSize="large" />}
                    // disabled={!userCanCreatePage}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#CC0000',
                      ':hover': { backgroundColor: '#CC0000' },
                      ':focus': { border: '2px dashed #fff' },
                      width: '150px',
                    }}
                    size="medium"
                  >
                    Upload Media
                  </Button>

                  <span>
                    <Tooltip
                      title={`Supported file types: ${fileTypes.join(', ')}`}
                    >
                      <div>
                        <InfoOutlined
                          sx={{
                            color: '#CC0000',
                            position: 'relative',
                            top: 5,
                            right: 5,
                            fontSize: '18px',
                          }}
                        />
                        <Typography
                          variant={'h8'}
                          sx={{
                            fontFamily: 'Helvetica For Target',
                            fontSize: '12px',
                          }}
                        >
                          Use optimized files for best performance
                        </Typography>
                      </div>
                    </Tooltip>
                  </span>
                </div>
              )}
              <Divider orientation="vertical" variant="middle" flexItem />
              <FormControlLabel
                value={isEditModeOn}
                control={
                  <Switch
                    checked={isEditModeOn}
                    onChange={handleEditMode}
                    inputProps={{ 'aria-label': 'Edit mode' }}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#5FBF5F',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: '#5FBF5F',
                        },
                    }}
                  />
                }
                label={`Edit Mode ${isEditModeOn ? 'On' : 'Off'}`}
                labelPlacement="start"
                sx={{
                  '.MuiButtonBase-root .MuiSwitch-switchBase .Mui-checked': {
                    color: 'green',
                  },
                  mr: '10px',
                }}
              />
            </Box>
            {refetchIsInprogress && <LinearProgress color="inherit" />}
            <Box
              sx={{
                maxHeight: { xs: 320, sm: 480, lg: 650 },
                background: 'white',
                px: '0px',
                height: 650,
              }}
            >
              {/* Tabs and search bar */}
              <Stack
                direction={'row'}
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  borderColor: '#959595',
                  borderBottom: 1,
                  backgroundColor: '#F0F0F0',
                  pt: '10px',
                }}
              >
                <Box
                  sx={{
                    maxWidth: { xs: 320, sm: 480, lg: 1000 },
                  }}
                >
                  <Tabs
                    value={currnetTab}
                    onChange={changeCurrentTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                      '& .MuiTabs-indicator': { backgroundColor: '#CC0000' },
                    }}
                  >
                    <Tab
                      disableFocusRipple
                      label="All"
                      value={'all'}
                      sx={{ ':focus': { border: '2px dashed #666666' } }}
                    />
                    <Tab
                      disableFocusRipple
                      label="Images"
                      value={'image'}
                      sx={{ ':focus': { border: '2px dashed #666666' } }}
                    />
                    <Tab
                      disableFocusRipple
                      label="PDF"
                      value={'pdf'}
                      sx={{ ':focus': { border: '2px dashed #666666' } }}
                    />
                    <Tab
                      disableFocusRipple
                      label="Audio"
                      value={'audio'}
                      sx={{ ':focus': { border: '2px dashed #666666' } }}
                    />
                    <Tab
                      disableFocusRipple
                      label="Videos"
                      value={'video'}
                      sx={{ ':focus': { border: '2px dashed #666666' } }}
                    />
                    <Tab
                      disableFocusRipple
                      label="Files"
                      value={'files'}
                      sx={{ ':focus': { border: '2px dashed #666666' } }}
                    />
                  </Tabs>
                </Box>

                <Stack direction={'row'} spacing={1}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {!isFromModal && isEditModeOn && (
                      <>
                        <Button
                          disableFocusRipple
                          variant="text"
                          sx={{
                            textTransform: 'none',
                            fontSize: '16px',
                            ':focus': { border: '2px dashed #666666' },
                            color: '#CC0000',
                          }}
                          disabled={
                            Object.keys(checkedState).length === 1 &&
                            (superRights ||
                              mutateItem?.created_by?.lanId === userInfo.lanId)
                              ? false
                              : true
                          }
                          onClick={() => {
                            const mItem = mutateItem
                            setEditableItem(mItem)
                            handleDialogOpen()
                          }}
                        >
                          Edit
                        </Button>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                        />
                      </>
                    )}
                    {!isFromModal && isEditModeOn && (
                      <>
                        <Button
                          disableFocusRipple
                          variant="text"
                          sx={{
                            textTransform: 'none',
                            fontSize: '16px',
                            ':focus': { border: '2px dashed #666666' },
                            color: '#CC0000',
                          }}
                          onClick={() => {
                            const data = Object.keys(checkedState)
                            const body = { data: data, site: siteId }
                            doValidateBeforeDelete(body)
                          }}
                          disabled={
                            Object.keys(checkedState).length !== 0 &&
                            (superRights ||
                              mutateItem?.created_by?.lanId === userInfo.lanId)
                              ? false
                              : true
                          }
                        >
                          Delete
                        </Button>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                        />
                      </>
                    )}
                    {!isEditModeOn && (
                      <>
                        <Badge
                          color="warning"
                          variant="dot"
                          invisible={true}
                          sx={{
                            '& .MuiBadge-dot': {
                              height: 10,
                              minWidth: 10,
                              borderRadius: 10,
                            },
                          }}
                        >
                          <Button
                            disableFocusRipple
                            variant="text"
                            sx={{
                              textTransform: 'none',
                              fontSize: '16px',
                              ':focus': { border: '2px dashed #666666' },
                              color: '#CC0000',
                            }}
                            onClick={handleClick}
                          >
                            Filter
                          </Button>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                          />
                        </Badge>
                      </>
                    )}

                    {isEditModeOn && (
                      <>
                        <Button
                          disableFocusRipple
                          variant="text"
                          sx={{
                            textTransform: 'none',
                            fontSize: '16px',
                            ':focus': { border: '2px dashed #666666' },
                            color: '#CC0000',
                          }}
                          onClick={() => {
                            setIsRecentlyDeletedOpen(true)
                          }}
                        >
                          Recently Deleted
                        </Button>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                        />
                      </>
                    )}

                    <Popover
                      id={popOverId}
                      open={isPopOverOpen}
                      anchorEl={anchorPopOverEl}
                      onClose={() => {
                        setAnchorPopOverEl(null)
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Stack direction={'row'} sx={{ minHeight: '75px' }}>
                        <IconButton onClick={handleClose}>
                          <CloseOutlined />
                        </IconButton>
                        {/* Fliter by coulmn */}
                        <FormControl fullWidth sx={{ m: 1, minWidth: '200px' }}>
                          <InputLabel id="fliter-label">Filter</InputLabel>
                          <Select
                            labelId="fliter-label-id"
                            id="demo-simple-select-required"
                            value={filterByColumn}
                            label="Fliter"
                            variant="standard"
                            onChange={handleFliterColumnChange}
                          >
                            {Object.entries(filterByColumnObj).map(
                              ([key, value], i) => (
                                <MenuItem
                                  id={`filter_by_column${i}`}
                                  value={key}
                                >
                                  {value}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                        {/* operator by coulmn */}
                        <FormControl fullWidth sx={{ m: 1, minWidth: '200px' }}>
                          <InputLabel id="fliter-label">Operator</InputLabel>
                          <Select
                            labelId="fliter-label-id"
                            id="demo-simple-select-required"
                            value={operatorByColumn}
                            label="Fliter"
                            variant="standard"
                            onChange={handleOperatorColumnChange}
                          >
                            {Object.entries(operatorColumnObj).map(
                              ([key, value], i) => (
                                <MenuItem
                                  id={`operator_by_column${i}`}
                                  value={key}
                                >
                                  {value}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                        {filterByColumn === 'created_by' ||
                        filterByColumn === 'page_post' ? (
                          <Autocomplete
                            id="created-by-autocomp"
                            sx={{ minWidth: '250px', m: 1 }}
                            options={
                              filterByColumn === 'page_post'
                                ? pageAndPostList
                                : userList
                            }
                            autoHighlight
                            autoComplete
                            getOptionLabel={(options) =>
                              filterByColumn === 'page_post'
                                ? options?.page?.name || options?.post?.title
                                : options?.displayName
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label={`Choose a ${
                                  filterByColumn === 'page_post'
                                    ? 'Page/Post'
                                    : 'name'
                                }`}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                            value={acValue}
                            onChange={handleFliterChange}
                            isOptionEqualToValue={(option, value) =>
                              option?.displayName === value?.displayName
                            }
                          />
                        ) : (
                          <TextField
                            sx={{
                              ml: 3,
                              justifyContent: 'center',
                              minWidth: '200px',
                              mt: 1,
                              mr: 5,
                            }}
                            placeholder="Search…"
                            variant="standard"
                            size="small"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => {
                              //TODO search logic will be here
                            }}
                          />
                        )}
                      </Stack>
                    </Popover>
                    {/* sort */}
                    <>
                      <Typography sx={{ m: 1 }}>Sort by</Typography>
                      <Select
                        labelId="sort-label-id"
                        id="demo-simple-select-required"
                        value={sortBy}
                        onChange={handleSortChange}
                        sx={{
                          fontSize: '15px',
                          p: 0.7,
                          backgroundColor: '#E6F0FB',
                          '& .MuiInputBase-input': {
                            p: 0,
                          },
                          ml: 1,
                          width: '150px',
                          ':focus': { border: '2px dashed #666666' },
                        }}
                      >
                        {Object.entries(listOfSort).map(([key, value], i) => (
                          <MenuItem id={`sort_options_${key}`} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <IconButton
                        disableFocusRipple
                        aria-label={`${
                          sortOrder ? 'Ascending order' : 'Descending order'
                        }`}
                        onClick={() => {
                          setSortOrder(!sortOrder)
                        }}
                        size="medium"
                        sx={{ ':focus': { border: '2px dashed #666666' } }}
                      >
                        {sortOrder ? (
                          <ArrowUpwardIcon fontSize="inherit" />
                        ) : (
                          <ArrowDownwardIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    </>
                  </div>
                </Stack>
              </Stack>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  style={{
                    borderColor:
                      isDragActive && isEditModeOn ? '#303F9F' : 'transparent',
                    borderWidth: 5,
                    borderStyle:
                      isDragActive && isEditModeOn ? 'dashed' : 'none',
                  }}
                >
                  {/* Orientation view items */}
                  <Stack
                    direction={'row'}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'start',
                      margin: '10px 40px 0px 40px',
                    }}
                  >
                    {Object.keys(checkedState).length < 1 ? (
                      <ToggleButtonGroup
                        ref={popOverRef}
                        value={itemViews}
                        exclusive
                        onChange={changeItemViews}
                        aria-label="item view"
                      >
                        <ToggleButton
                          disableFocusRipple
                          value="grid"
                          aria-label="grid view"
                          sx={{ ':focus': { border: '2px dashed #666666' } }}
                        >
                          <GridOn />
                        </ToggleButton>
                        <ToggleButton
                          disableFocusRipple
                          value="list"
                          aria-label="list view"
                          sx={{ ':focus': { border: '2px dashed #666666' } }}
                        >
                          <ListIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    ) : (
                      isEditModeOn && (
                        <>
                          <Typography
                            variant={'h6'}
                            sx={{
                              fontFamily: 'Helvetica For Target',
                            }}
                          >
                            {`${Object.keys(checkedState).length} item${
                              Object.keys(checkedState).length > 1 ? 's' : ''
                            } selected`}
                          </Typography>

                          <FormGroup>
                            <FormControlLabel
                              control={
                                <input
                                  type="checkbox"
                                  checked={
                                    Object.keys(checkedState).length > 0
                                      ? true
                                      : false
                                  }
                                  onChange={deselectAll}
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    accentColor: '#CC0000',
                                  }}
                                  onKeyDown={(e) => {
                                    e.key === 'Enter' && deselectAll()
                                  }}
                                />
                              }
                              label="Deselect all"
                            />
                          </FormGroup>
                        </>
                      )
                    )}
                  </Stack>
                  {loadingPageData ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: 500,
                        margin: '10px',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : filesData &&
                    filesData.files &&
                    filesData.files.length === 0 ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: 500,
                        margin: '10px',
                      }}
                    >
                      <img
                        src={noData}
                        alt="no data found"
                        style={{ height: '250px', width: '250px' }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        maxHeight: 500,
                        margin: '10px',
                        overflowY: 'scroll',
                        ':focus': { border: '2px dashed #666666' },
                      }}
                    >
                      {itemViews === 'list' ? (
                        <List
                          sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                            padding: '10px',
                          }}
                        >
                          {filesData !== null &&
                            filesData !== undefined &&
                            filesData?.files?.map((value, index) =>
                              ListView(value, index),
                            )}
                        </List>
                      ) : (
                        filesData !== null &&
                        filesData !== undefined &&
                        filesData?.files && (
                          <GridList
                            key={'grid-list'}
                            data={filesData.files}
                            children={GridView}
                          />
                        )
                      )}
                    </Box>
                  )}
                </div>
              </div>
            </Box>
            <Stack alignItems={'flex-end'}>
              <Pagination
                count={totalPage}
                showFirstButton
                showLastButton
                color="primary"
                page={currnetPage}
                onChange={handlePagination}
                disabled={totalPage === 0 ? true : false}
                sx={{ ':focus': { border: '2px dashed #666666' } }}
              />
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  )
}

const moduleAttr = {
  name: 'Media',
  actions: [
    {
      slug: 'Read',
      access: 'read',
    },
    {
      slug: 'Edit',
      access: 'edit',
    },
  ],
}

export default WithRights(MediaLibrary, moduleAttr)
