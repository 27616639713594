import React, { useState, useEffect } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import {
  Box,
  Button,
  Grid,
  SvgIcon,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import {
  FormDateField,
  FormMediaField,
  FormTextField,
  FormSelectSpeaker,
} from '../../../FormComponents'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import { Close } from '@mui/icons-material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  useLinkSpeakersToWorskhopMutation,
  useRemoveSpeakersFromWorshopMutation,
} from '../../../../../api/workshop'

let speakerSchema = yup.object().shape({
  name: yup.array().required(),
  designation: yup.string().required(),
  company: yup.string().required(),
  email: yup.string().required(),
  start_time: yup
    .date()
    .typeError('Enter valid time')
    .nullable()
    .required('Start time is a required field'),
  end_time: yup
    .date()
    .typeError('Enter valid time')
    .nullable()
    .required('End time is a required field'),
  description: yup.string().required(),
  engagement_image: yup.string().required(),
})

// var initialValues = {}
const DEFAULT_VALUES = {
  name: [],
  designation: '',
  company: '',
  email: '',
  start_time: '',
  end_time: '',
  description: '',
  engagement_image: '',
}

const FormForEditingSpeaker = (props) => {
  const {
    //disableFields,
    speakersToAdd,
    setSpeakersToAdd,
    workshopId,
    eventId,
    speakerForDropDown,
    speakerListData,
    speakersFetching,
    workshopData,
    speakersSavedList,
    setSpeakersSavedList,
  } = props

  const [expanded, setExpanded] = useState(0)
  const navigate = useNavigate()
  const [speakerRemoveDialog, setSpeakerRemoveDialog] = useState(false)
  const [speakerIndex, setSpeakerIndex] = useState(0)
  const [speakerIdTobeRemoved, setSpeakerIdTobeRemoved] = useState('')

  const activeSite = useSelector((state) => state.tenant.activeSite)

  const [removeSpeakersFromWorkshop, { isLoading: removeSpeakersLoading }] =
    useRemoveSpeakersFromWorshopMutation()

  const handleChange = (index) => (event, newExpanded) => {
    setExpanded(newExpanded ? index : '')
  }

  const onAddSpeaker = () => {
    const temp = [...speakersToAdd]
    temp.push({})
    setSpeakerIndex(temp.length - 1)
    setSpeakersToAdd(temp)
  }

  const onSpeakerRemove = (index, speakerToRemove) => {
    const temp = [...speakersToAdd]
    temp.splice(index, 1)
    setSpeakersToAdd(temp)
    const tempSaved = [...speakersSavedList]
    tempSaved.splice(index, 1)
    setSpeakersSavedList(tempSaved)
    if (speakerToRemove) {
      var ids = []
      ids.push(speakerToRemove)
      removeSpeakersFromWorkshop({
        ids: ids,
        workshopid: workshopId,
        eventid: eventId,
        activeSite,
      })
    }
  }

  // const clearValues = () => {
  //   initialValues = {}
  // }

  const handleClose = () => {
    setSpeakerRemoveDialog(false)
  }

  const validateTime = (value) => {
    let error = ''
    let startTimeVal = moment(workshopData?.start_time, ['h:mm:ss']).toDate()
    let endTimeVal = moment(workshopData?.end_time, ['h:mm:ss']).add(
      1,
      'second',
    )
    if (
      moment(value).isSameOrAfter(startTimeVal) &&
      moment(value).isSameOrBefore(endTimeVal)
    ) {
      return error
    }
    error = 'Time slots are not between workshop session timings'
    return error
  }

  const validateStartTime = (value) => {
    return validateTime(value)
  }

  const validateEndTime = (value) => {
    return validateTime(value)
  }

  const [
    linkSpeakerToWorkshop,
    { data: savedSpeakerData, isLoading, isSuccess },
  ] = useLinkSpeakersToWorskhopMutation()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Speaker edited successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: (key) => (
          <>
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Close></Close>
            </IconButton>
          </>
        ),
      })
      // navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, isSuccess, navigate])

  useEffect(() => {
    if (savedSpeakerData) {
      let list = []
      list = [...speakersSavedList]
      list.push({ id: savedSpeakerData.data.member })
      setSpeakersSavedList(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSpeakerData])

  return (
    <Box
      sx={{
        p: 1,
        pl: 3,
        // overflow: 'auto',
        display: 'flex',
        // height: '70vh',
        mt: 6.5,
      }}
    >
      <Grid container spacing={1} sx={{ display: 'flex' }}>
        {/* Right Section */}

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: 20, fontWeight: 700, mb: 1, flexGrow: 1 }}
            >
              Speaker Detail Info
            </Typography>
            <Button
              size="small"
              variant="outlined"
              startIcon={<SvgIcon component={AddIcon} />}
              color="secondary"
              sx={{
                textTransform: 'none',
                height: 35,
                mr: 1,
              }}
              onClick={() => {
                // clearValues()
                onAddSpeaker()
                setExpanded(speakersToAdd?.length)
              }}
            >
              {'Add Speaker'}
            </Button>
          </Box>
          <Box
            sx={{
              border: '2px dashed #B5B5B5',
              p: 2,
            }}
          >
            {(isLoading || removeSpeakersLoading) && (
              <LinearProgress indeterminate />
            )}
            {speakersToAdd &&
              speakersToAdd.length > 0 &&
              speakersToAdd.map((item, index) => {
                return (
                  Object.keys(item) && (
                    <Formik
                      key={item?.id}
                      initialValues={{
                        ...DEFAULT_VALUES,
                        ...item,
                      }}
                      validationSchema={speakerSchema}
                      onSubmit={(values) => {
                        linkSpeakerToWorkshop({
                          speakerData: {
                            start_time: moment(values.start_time).format(
                              'HH:mm',
                            ),
                            end_time: moment(values.end_time).format('HH:mm'),
                            engagement_image: values.engagement_image,
                            description: values.description,
                            id: values.id,
                          },
                          workshop_id: workshopId,
                          eventid: eventId,
                          activeSite,
                        })
                      }}
                    >
                      {({ values, isValid }) => {
                        const selectedSpeakerId = values?.name?.[0]
                        const speakerData = speakerListData?.[selectedSpeakerId]
                        const speakerName = speakerData?.user?.name
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              mb: 3,
                            }}
                          >
                            <Form className="formik-form">
                              <Accordion
                                expanded={expanded === index}
                                sx={{
                                  background: '#F6F7F8',
                                }}
                                onChange={handleChange(index)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    {speakerName ? speakerName : 'New Speaker'}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box
                                    sx={{
                                      border: '2px dashed #B5B5B5',
                                      p: 2,
                                      background: '#F0F0F0',
                                    }}
                                  >
                                    <Grid
                                      container
                                      sx={{
                                        display: 'flex',
                                      }}
                                    >
                                      <Grid item xs={6}>
                                        {!speakersFetching &&
                                          speakerForDropDown &&
                                          speakerForDropDown.length > 0 && (
                                            <Field
                                              type="text"
                                              name="name"
                                              label="Speaker Name*"
                                              component={FormSelectSpeaker}
                                              options={speakerForDropDown.map(
                                                (item) => {
                                                  return {
                                                    label: item.name,
                                                    value: item.id,
                                                  }
                                                },
                                              )}
                                              disableFields={
                                                item.id ? true : false
                                              }
                                              multiple={false}
                                              speakerListData={speakerListData}
                                              speakersSavedList={
                                                speakersSavedList
                                              }
                                              module={'workshop'}
                                              speakerIndex={index}
                                              setSpeakersToAdd={
                                                setSpeakersToAdd
                                              }
                                              speakersToAdd={speakersToAdd}
                                            />
                                          )}
                                        {speakerForDropDown &&
                                          speakerForDropDown.length === 0 && (
                                            <Field
                                              type="text"
                                              name="name"
                                              label="Speaker Name*"
                                              component={FormSelectSpeaker}
                                              options={[]}
                                              disableFields={
                                                item.id ? true : false
                                              }
                                              multiple={false}
                                              speakerListData={[]}
                                              speakersSavedList={[]}
                                              module={'workshop'}
                                              speakerIndex={index}
                                              setSpeakersToAdd={
                                                setSpeakersToAdd
                                              }
                                              speakersToAdd={[]}
                                            />
                                          )}
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          type="text"
                                          name="designation"
                                          label="Designation*"
                                          placeholder="Designation"
                                          autoComplete="off"
                                          disableFields={true}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          type="text"
                                          name="company"
                                          label="Company*"
                                          placeholder="Company"
                                          autoComplete="off"
                                          disableFields={true}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          type="text"
                                          name="email"
                                          label="Email ID*"
                                          placeholder="Email Id"
                                          autoComplete="off"
                                          disableFields={true}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          name="start_time"
                                          placeholder="Select session start time"
                                          validate={validateStartTime}
                                        >
                                          {({ field, form, meta }) => (
                                            <FormDateField
                                              {...field}
                                              {...form}
                                              type="time"
                                              label="Session Start Time*"
                                              onChange={(value) => {
                                                form
                                                  .setFieldValue(
                                                    'start_time',
                                                    moment(value).toDate(),
                                                  )
                                                  .then(() => {
                                                    form.setFieldTouched(
                                                      'start_time',
                                                      true,
                                                    )
                                                  })
                                              }}
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          name="end_time"
                                          placeholder="Select session end time"
                                          validate={validateEndTime}
                                        >
                                          {({ field, form, meta }) => (
                                            <FormDateField
                                              {...field}
                                              {...form}
                                              type="time"
                                              label="Session End Time*"
                                              onChange={(value) => {
                                                form
                                                  .setFieldValue(
                                                    'end_time',
                                                    moment(value).toDate(),
                                                  )
                                                  .then(() => {
                                                    form.setFieldTouched(
                                                      'end_time',
                                                      true,
                                                    )
                                                  })
                                              }}
                                            />
                                          )}
                                        </Field>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <span
                                          style={{
                                            color: 'rgba(0, 0, 0, 0.6)',
                                            marginLeft: '20px',
                                            fontSize: '12px',
                                          }}
                                        >
                                          Speaker Image Thumbnail*
                                        </span>

                                        <Grid item xs={12}>
                                          <Field
                                            name="engagement_image"
                                            label="Drop file or click to upload"
                                            maxHeight={108}
                                            component={FormMediaField}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={6} sx={{ mt: 3 }}>
                                        <Field
                                          type="text"
                                          name="description"
                                          label="Description"
                                          placeholder="Enter description"
                                          autoComplete="off"
                                          multiline
                                          rows={4}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box
                                    sx={{
                                      background: '#F6F7F8',
                                      p: 1,
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                      gap: '20px',
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      sx={{
                                        textTransform: 'none',
                                        height: 35,
                                        color: '#cc0000',
                                      }}
                                      type="submit"
                                      className="btn btn-primary btn-block mt-4"
                                    >
                                      Save
                                    </Button>

                                    <Button
                                      variant="text"
                                      sx={{
                                        textTransform: 'none',
                                        color: '#000',
                                      }}
                                      type="button"
                                      className="btn btn-primary btn-block mt-4"
                                      onClick={() => {
                                        setSpeakerRemoveDialog(true)
                                        setSpeakerIndex(index)
                                        setSpeakerIdTobeRemoved(
                                          isValid ? values.id : '',
                                        )
                                        setExpanded('')
                                      }}
                                    >
                                      Remove
                                    </Button>
                                    <Dialog
                                      open={speakerRemoveDialog}
                                      onClose={handleClose}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogTitle id="alert-dialog-title">
                                        {'Are you sure want to remove?'}
                                      </DialogTitle>
                                      <DialogActions>
                                        <Button
                                          onClick={() => {
                                            onSpeakerRemove(
                                              speakerIndex,
                                              speakerIdTobeRemoved,
                                            )
                                            setSpeakerRemoveDialog(false)
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button onClick={handleClose} autoFocus>
                                          No
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Form>
                          </Box>
                        )
                      }}
                    </Formik>
                  )
                )
              })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FormForEditingSpeaker
