import React from 'react'
import FileExistDialog from './FileExistDialog'
import {
  useVerifyfileMutation,
  useUploadfileMutation,
} from '../../api/mediaLib'
import { useSelector } from 'react-redux'

const FileuploadComponent = (props) => {
  const { data, onSuccess } = props
  const { key, file } = data
  const [showPopup, setShowPopup] = React.useState(false)
  const [oldData, setOldData] = React.useState()
  const siteId = useSelector((state) => state.tenant.activeSite?._id)

  const [uploadFile] = useUploadfileMutation()
  const [checkFileExist] = useVerifyfileMutation()

  React.useEffect(() => {
    file && verify(file)
    return () => {
      setShowPopup(false)
      setOldData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const verify = (file) => {
    const body = { name: `${file?.name}`, site: siteId }
    checkFileExist(body).then(async (res) => {
      if (res?.data?.status === 'already_exists') {
        //file exist and ask user input.
        setOldData(res?.data?.data)
        setShowPopup(true)
      } else {
        // file will be upload
        setShowPopup(false)
        upload(file)
      }
    })
  }

  const upload = (file) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('site', siteId)
    file &&
      uploadFile(formData)
        .then((res) => {
          setShowPopup(false)
          onSuccess(res, key)
        })
        .catch((e) => {
          //error
          setShowPopup(false)
        })
  }

  const keepBothAction = () => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('site', siteId)
    file &&
      uploadFile(formData)
        .then((res) => {
          setShowPopup(false)
          onSuccess(res, key)
        })
        .catch((e) => {
          //error
          setShowPopup(false)
        })
  }

  const replaceAction = (id) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('site', siteId)
    formData.append('id', id)
    file &&
      uploadFile(formData)
        .then((res) => {
          onSuccess(res, key)
        })
        .catch((e) => {
          //error
          setShowPopup(false)
        })
  }

  return (
    <FileExistDialog
      open={showPopup}
      close={onSuccess}
      oldData={oldData}
      keepBothAction={keepBothAction}
      replaceAction={replaceAction}
      closeFileExistDialog={() => {
        setShowPopup(false)
        onSuccess(undefined, key)
      }}
    />
  )
}

export default React.memo(FileuploadComponent)
