import React, { useState, useRef, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import {
  Card,
  Box,
  Chip,
  Typography,
  ButtonGroup,
  IconButton,
  CardMedia,
  TextField,
  Tooltip,
} from '@mui/material'
import Preview from '../Preview'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import DeleteConfirmation from '../../../../../components/DeleteConfirmation'
import { useGetComponentsQuery } from '../../../../../api/component'
import { getComponentIcons } from '../../../../../utils/helper'
import CloneComponent from './CloneComponent'
import { getValueByPath, objectMerge } from '../../../../../utils/dataParser'
import { useAnalytics } from '@praxis/component-analytics'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import _ from 'lodash'

export default function Section(props) {
  const {
    section,
    sectionNumber,
    setSection,
    moveSection,
    select,
    onSectionClick,
    selectedComponent,
    onDelete,
    pageChangesAllowed = false,
    onDeleteSection,
  } = props

  const [previewOpen, setPreviewOpen] = useState(false)
  const [deleteConfirm, initDeleteConfirm] = useState(false)
  const [selectedForDelete, setSelectedForDelete] = useState(null)
  const [componentListData, setComponentListData] = useState([])
  // const [sectionLocal, setSectionData] = React.useState({ ...section })
  const [openCloneOption, setOpenCloneOption] = React.useState(false)
  const [sectionNameEdit, setSectionNameEdit] = useState(false)
  const sectionNameRef = useRef(null)
  // const [sectionName, setSectionName] = useState(
  //   section.sectionName ? section.sectionName : `Section ${sectionNumber}`
  // )
  const [initDelSectionConfrim, setinitDelSectionConfrim] = useState(false)

  // React.useEffect(() => {
  //   const updatedData = {
  //     ...section,
  //     sectionName: section.sectionName
  //       ? section.sectionName
  //       : `Section ${sectionNumber}`,
  //   }
  //   setSectionData(updatedData)
  //   setSection(updatedData)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [section])

  const { trackEvent } = useAnalytics()
  const activeSite = useSelector((state) => state.tenant.activeSite)
  let { id: pageID } = useParams()
  const { data: componentList, isLoading: componentListLoading } =
    useGetComponentsQuery()

  useEffect(() => {
    if (componentList) {
      setComponentListData(componentList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentList])

  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'section',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = sectionNumber - 1
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveSection(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'section',
    item: () => {
      return { ...{ index: sectionNumber - 1 } }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const addNewComponentToSection = (newComponent, column, isCloned) => {
    if (process.env.REACT_APP_ENV === 'prod') {
      trackEvent({
        customMetrics: {
          metric1: activeSite._id,
          metric2: pageID,
          metric3: '',
        },
        customInteraction: {
          key: newComponent.name,
          value: null,
        },
        event: {
          name: 'add_new_component',
          type: 'click',
        },
      })
    }
    const updatedComp = [...section.components.slice(0, column)]
    if (column === 2 && section.components.length === 1) {
      updatedComp.push({})
    }
    updatedComp.push({
      ...newComponent,
      attributes: isCloned ? newComponent.attributes : {},
    })
    if (column === 1 && section.components.slice(column + 1).length > 0) {
      updatedComp.push(...section.components.slice(column + 1))
    }
    const updated = {
      ...section,
      components: updatedComp,
    }
    //  setSectionData(updated)
    setSection(updated)
  }

  const onDeleteClick = (row, column, secNo) => {
    setSelectedForDelete({ row, column, secNo })
    initDeleteConfirm(true)
  }

  const deleteComponent = () => {
    onDelete(selectedForDelete)
    setSelectedForDelete(null)
    initDeleteConfirm(false)
  }

  const onDeleteSectionClick = () => {
    setinitDelSectionConfrim(true)
  }

  const deleteSection = () => {
    onDeleteSection(sectionNumber - 1)
    setinitDelSectionConfrim(false)
  }

  const initClone = () => {
    setOpenCloneOption(true)
  }

  const cloneTheCopy = (component, key) => {
    addNewComponentToSection(component, key, true)
    setOpenCloneOption(false)
  }

  const handleNameChange = (val) => {
    const updatedData = { ...section, sectionName: val }
    // setSectionName(val)
    //setSectionData(updatedData)
    setSection(updatedData)
  }

  const setSectionName = () => {
    setSectionNameEdit(false)
  }

  const opacity = isDragging ? 0 : 1
  if (pageChangesAllowed) {
    drag(drop(ref))
  }

  const setComponentAttr = (...args) => {
    const [slug, value, compPos] = args
    // eslint-disable-next-line no-unused-vars
    const { master = '', blanket = '', ...restValue } = value
    const currentData = getValueByPath(
      section?.components?.[compPos].attributes,
      slug,
    )
    if (!_.isEqual(currentData, restValue)) {
      // console.log('Change deteted! updating...', slug, currentData, restValue)
      const componentAttr = { ...section?.components?.[compPos]?.attributes }
      const updatedCompAttr = objectMerge(componentAttr, slug, restValue)
      setSection({
        ...section,
        components: [
          ...section.components.slice(0, compPos),
          { ...section.components?.[compPos], attributes: updatedCompAttr },
          ...section.components.slice(compPos + 1),
        ],
      })
    }
  }
  return (
    <Box ref={ref} sx={{ opacity }} data-handler-id={handlerId}>
      <Card
        sx={{
          borderRadius: 0,
          marginBottom: 2,
          p: 2,
          position: 'relative',
          background: '#FFF',
        }}
        elevation={1}
        onClick={() => onSectionClick(sectionNumber - 1)}
      >
        <Box sx={{ display: 'flex', ml: 1 }}>
          {!sectionNameEdit ? (
            <>
              <Typography
                sx={{
                  cursor: 'pointer',
                  color: '#CC0000',
                  mr: '4px',
                  fontSize: 20,
                  fontFamily: 'Helvetica for Target',
                  fontWeight: '700',
                  wordWrap: 'break-word',
                }}
                variant="subtitle1"
              >
                {section.sectionName}
              </Typography>
              <IconButton
                sx={{
                  border: '1px solid #E0E0E0',
                  borderRadius: 0,
                  px: 1,
                  py: 0,
                }}
                disabled={!pageChangesAllowed}
                onClick={() => setSectionNameEdit(true)}
              >
                <EditIcon fontSize="small" sx={{ fontSize: '1rem' }}></EditIcon>
              </IconButton>
            </>
          ) : (
            <Box>
              <TextField
                ref={sectionNameRef}
                size="small"
                variant="outlined"
                onChange={(e) => handleNameChange(e.target.value)}
                placeholder="Enter section name"
                sx={{ mb: 1, mr: '4px' }}
                disabled={!pageChangesAllowed}
                value={section.sectionName}
              />
              <IconButton
                sx={{
                  border: '1px dashed #E0E0E0',
                  borderRadius: 0,
                  mt: '4px',
                }}
                disabled={!pageChangesAllowed}
                onClick={() => setSectionName()}
              >
                <DoneOutlineIcon
                  fontSize="small"
                  sx={{ fontSize: '1rem' }}
                ></DoneOutlineIcon>
              </IconButton>
            </Box>
          )}
          {section.components[0] && section.components[0].name ? (
            ''
          ) : (
            <IconButton
              aria-label="delete-section"
              disabled={!pageChangesAllowed}
              sx={{
                border: '1px solid #E0E0E0',
                borderRadius: 0,
                px: 1,
                py: 0,
              }}
              onClick={() => onDeleteSectionClick()}
            >
              <DeleteIcon
                fontSize="small"
                sx={{ fontSize: '1rem' }}
              ></DeleteIcon>
            </IconButton>
          )}
        </Box>
        <IconButton
          disabled={!pageChangesAllowed}
          sx={{ position: 'absolute', right: -10, top: 90 }}
        >
          <DragIndicatorIcon />
        </IconButton>
        <Chip
          size={'small'}
          label={`Section #${sectionNumber}`}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            borderRadius: 0,
            color: 'black',
            fontSize: 14,
            fontFamily: 'Helvetica for Target',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {section.componentWidths.map((currentWidth, key) => {
            return (
              <React.Fragment key={key}>
                {section.components[key] && section.components[key].name ? (
                  <>
                    <Card
                      key={key}
                      sx={{
                        borderRadius: 0,
                        m: 1,
                        background: '#F6F7F8',
                      }}
                      elevation={
                        selectedComponent && selectedComponent.col === key
                          ? 8
                          : 0
                      }
                      onClick={() => select(sectionNumber - 1, key)}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'right',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '40px',
                          }}
                        >
                          <Chip
                            size={'small'}
                            label={`Component: ${section.components[key].name}`}
                            sx={{
                              borderRadius: 0,
                              color: 'black',
                              fontSize: 12,
                              fontFamily: 'Helvetica for Target',
                              fontWeight: '400',
                              wordWrap: 'break-word',
                            }}
                          />
                          <ButtonGroup size={'small'} sx={{ flexGrow: 1 }}>
                            <IconButton
                              sx={{
                                border: '1px solid #E0E0E0',
                                borderRadius: 0,
                              }}
                              // disabled={!pageChangesAllowed}
                              onClick={() => setPreviewOpen(!previewOpen)}
                            >
                              {previewOpen ? (
                                <Tooltip title="Hide Preview">
                                  <VisibilityOffIcon fontSize="small"></VisibilityOffIcon>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Show Preview">
                                  <VisibilityIcon fontSize="small"></VisibilityIcon>
                                </Tooltip>
                              )}
                            </IconButton>
                            <IconButton
                              sx={{
                                border: '1px solid #E0E0E0',
                                borderRadius: 0,
                              }}
                              disabled={!pageChangesAllowed}
                              onClick={() => select(sectionNumber - 1, key)}
                            >
                              <Tooltip title="Edit Component">
                                <EditIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              sx={{
                                border: '1px solid #E0E0E0',
                                borderRadius: 0,
                              }}
                              disabled={!pageChangesAllowed}
                              onClick={() =>
                                onDeleteClick(
                                  sectionNumber - 1,
                                  key,
                                  sectionNumber,
                                )
                              }
                            >
                              <Tooltip title="Delete Component">
                                <DeleteIcon fontSize="small" />
                              </Tooltip>
                            </IconButton>
                          </ButtonGroup>
                        </Box>

                        <Box flexGrow={1} />
                        <Box sx={{ alignSelf: 'center', margin: '20px' }}>
                          <CardMedia
                            component="img"
                            image={getComponentIcons(
                              section.components[key].slug,
                            )}
                            alt={`${section.components[key].slug}Icon`}
                            sx={{
                              borderRadius: '8px',
                              padding: '8px',
                              border: '1px dashed #9E9E9E',
                            }}
                          ></CardMedia>
                        </Box>
                      </Box>
                    </Card>
                    {previewOpen && (
                      <Card
                        draggable={true}
                        onDragStart={(event) =>
                          `${section.components[key].slug}` === 'agenda' &&
                          event.preventDefault()
                        }
                        sx={{
                          borderRadius: 0,
                          m: 1,
                          background: '#F6F7F8',
                        }}
                        elevation={
                          selectedComponent && selectedComponent.col === key
                            ? 8
                            : 0
                        }
                        onClick={() => select(sectionNumber - 1, key)}
                      >
                        <Preview
                          width={currentWidth}
                          component={section.components[key]}
                          defaultZoomVal={40}
                          onChangeAttr={(slug, value) => {
                            setComponentAttr(slug, value, key)
                          }}
                          disableEdit={!pageChangesAllowed}
                        />
                      </Card>
                    )}
                  </>
                ) : (
                  <Card
                    variant="outlined"
                    sx={{
                      borderRadius: 0,
                      m: 1,
                      p: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <Typography variant="h6" sx={{ color: '#CC0000' }}>
                      Select Component
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        borderRadius: '4px',
                      }}
                    >
                      {!componentListLoading &&
                        componentListData.length > 0 &&
                        componentListData.map((component, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                padding: '20px',
                                backgroundColor: '#F8FBFF',
                                cursor: 'pointer',
                                border: '1px solid #F0F0F0',
                                '&:hover': {
                                  filter: 'brightness(50%)',
                                },
                              }}
                              onClick={() =>
                                addNewComponentToSection(component, key, false)
                              }
                            >
                              <Tooltip title={component.name}>
                                <CardMedia
                                  component="img"
                                  image={getComponentIcons(component.slug)}
                                  alt={component.name}
                                />
                              </Tooltip>
                            </Box>
                          )
                        })}
                      {!componentListLoading &&
                        componentListData.length > 0 && (
                          <Box
                            sx={{
                              padding: '20px',
                              backgroundColor: '#F8FBFF',
                              border: '1px solid #F0F0F0',
                              '&:hover': {
                                filter: 'brightness(50%)',
                                cursor: 'pointer',
                              },
                            }}
                            onClick={initClone}
                          >
                            <Tooltip title="Clone">
                              <CardMedia
                                component="img"
                                image={getComponentIcons('clone')}
                                alt={'clone'}
                              />
                            </Tooltip>
                          </Box>
                        )}
                      <CloneComponent
                        open={openCloneOption}
                        handleClose={() => setOpenCloneOption(false)}
                        handleAdd={(component) => cloneTheCopy(component, key)}
                      />
                    </Box>
                  </Card>
                )}
              </React.Fragment>
            )
          })}
        </Box>
      </Card>
      <DeleteConfirmation
        onConfirm={deleteComponent}
        onClose={() => initDeleteConfirm(false)}
        open={deleteConfirm}
      />
      <DeleteConfirmation
        onConfirm={deleteSection}
        onClose={() => setinitDelSectionConfrim(false)}
        open={initDelSectionConfrim}
      />
    </Box>
  )
}
