import React, { useState } from 'react'
import withRights from '../../../components/withRights'
import AccessDenied from '../../../Layout/AccessDenied'
import { checkUserIsAllowed } from '../../../utils/rightUtils'
import { Alert, AlertTitle, Box, Grid } from '@mui/material'
import Loader from '../../../components/Loader'
// import LeftArea from './LeftArea/LeftArea'
import RightArea from './RightArea/RightArea'
import Main from './Main/Main'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  useGetRevisionQuery,
  useUpdateRevisionMutation,
  useGetSubStatusQuery,
} from '../../../api/page'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  currentVersionEditable,
  getStatusOfThePageVersion,
  versionAssignedToThisUser,
} from '../pageUtils'
import { useSnackbar } from 'notistack'
import TopLayout from './TopLayout/TopLayout'
import { useAnalytics } from '@praxis/component-analytics'
import useTimer from '../../../hooks/useTimer'

// import mockPage from './mockPage.json'

const PageEditor = (props) => {
  const { rights, rightsLoading } = props
  const { moduleAccess, permittedActions, superRights } = rights
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const { enqueueSnackbar } = useSnackbar()

  let { id } = useParams()

  const [editedLayout, setLayout] = useState([])
  const [name, setPageName] = useState('')
  const [activity, setActivity] = useState([])
  const [clickedSection, setClickedSection] = useState(null)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const selectComponentForEdit = (row, col, reset) => {
    setSelectedComponent(reset ? null : { row, col })
  }

  const { trackEvent } = useAnalytics()
  const [startTimer, endTimer] = useTimer()

  const {
    data: pageData,
    isLoading: loadingPageData,
    isFetching: statusChangeRefetch,
    refetch: reFetchPage,
  } = useGetRevisionQuery(
    { id, activeSite },
    {
      refetchOnMountOrArgChange: true,
      skip: !(id && activeSite),
    },
  )

  const { data: pageSubStatus, refetch: reFetchStatus } = useGetSubStatusQuery(
    { revisionId: id, siteId: activeSite?._id },
    {
      refetchOnMountOrArgChange: true,
      skip: !(id && activeSite),
    },
  )

  React.useEffect(() => {
    startTimer()
    return () => endTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setClickedSection(null)
    }, 3000)
  }, [clickedSection])

  React.useEffect(() => {
    if (pageData) {
      setLayout(pageData.layout)
      setPageName(pageData?.content?.name)
      setActivity([...pageData.activity].reverse())
    }
  }, [pageData])

  // const [
  //   updatePageLayout,
  //   { isSuccess: updateLayoutSuccess, isLoading: updateInProgress },
  // ] = useUpdatePageMutation()

  const [
    updateVersionLayout,
    {
      isSuccess: updateLayoutSuccess,
      isError: updateFailed,
      isLoading: updateInProgress,
    },
  ] = useUpdateRevisionMutation()

  // const onUpdatePageLayout = () => {
  //   console.log('Why')
  //   const payload = {
  //     id,
  //     ...pageData,
  //     layout: editedLayout,
  //     lanId: userInfo.lanId,
  //     category: pageData.category ? pageData.category.id : null,
  //     tags:
  //       pageData.tags && pageData.tags.length > 0
  //         ? pageData.tags.map((tag) => tag.id)
  //         : [],
  //   }
  //   updatePageLayout(payload)
  // }

  React.useEffect(() => {
    if (updateFailed) {
      enqueueSnackbar('Unable to save the layout!. Please try again', {
        action: null,
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }, [enqueueSnackbar, updateFailed])

  const onUpdateVersionLayout = () => {
    const timeElapsed = endTimer()
    startTimer()
    if (timeElapsed && process.env.REACT_APP_ENV === 'prod') {
      trackEvent({
        customMetrics: {
          metric1: activeSite._id,
          metric2: id,
          metric3: '',
          metric4: timeElapsed,
        },
        customInteraction: {},
        event: {
          name: activity.some((item) => item.status === 'published')
            ? 'page-edit-time'
            : 'page-create-time',
          type: 'timer',
        },
      })
    }
    const payload = {
      id,
      // ...pageData,
      layout: editedLayout,
      lanId: userInfo.lanId,
      category: pageData.category ? pageData.category.id : null,
      tags:
        pageData.tags && pageData.tags.length > 0
          ? pageData.tags.map((tag) => tag.id)
          : [],
      site: activeSite._id,
    }
    updateVersionLayout(payload)
  }

  const versionStatus = getStatusOfThePageVersion(pageData)
  const checkOnUserAssignment = versionAssignedToThisUser(
    pageData,
    userInfo.lanId,
  )

  const pageActions = {
    pageChangesAllowed:
      checkUserIsAllowed('Edit', permittedActions, superRights) &&
      currentVersionEditable(versionStatus) &&
      checkOnUserAssignment,
    addCommentAllowed: checkUserIsAllowed(
      'Review',
      permittedActions,
      superRights,
    ),
    previewAllowed: checkUserIsAllowed(
      'Preview',
      permittedActions,
      superRights,
    ),
  }

  return (
    <Box sx={{ width: 'calc(100vw - 58px)', padding: 0 }}>
      {rightsLoading || loadingPageData ? (
        <Loader />
      ) : moduleAccess &&
        (pageActions.pageChangesAllowed || pageActions.addCommentAllowed) ? (
        <>
          {activeSite._id === pageData?.content?.site ? (
            <Box>
              <TopLayout
                id={id}
                status={pageData.status}
                layout={editedLayout}
                pageId={pageData ? pageData?.content?.id : ''}
                // updatePageLayout={onUpdatePageLayout}
                updatePageLayout={onUpdateVersionLayout}
                updateLayoutSuccess={updateLayoutSuccess}
                updateInProgress={updateInProgress}
                reFetchPage={reFetchPage}
                loadingPageData={statusChangeRefetch}
                pageData={pageData}
                pageActions={pageActions}
                pageName={name}
                activity={activity}
                reFetchStatus={reFetchStatus}
                pageSubStatus={pageSubStatus}
              ></TopLayout>
              <Grid container>
                {/* <Grid item sx={{ width: '20%' }}>
                <LeftArea
                  layout={editedLayout}
                  setLayout={setLayout}
                  selectComponentForEdit={selectComponentForEdit}
                  pageName={name}
                  clickedSection={clickedSection}
                  pageActions={pageActions}
                />
              </Grid> */}
                <Grid item sx={{ width: '75%' }}>
                  <DndProvider backend={HTML5Backend}>
                    <Main
                      id={id}
                      status={pageData.status}
                      layout={editedLayout}
                      setLayout={setLayout}
                      selectComponentForEdit={selectComponentForEdit}
                      onSectionClick={setClickedSection}
                      selectedComponent={selectedComponent}
                      loadingPageData={statusChangeRefetch}
                      pageData={pageData}
                      pageActions={pageActions}
                      pageName={name}
                      allowUserToEdit={checkUserIsAllowed(
                        'Edit',
                        permittedActions,
                        superRights,
                      )}
                    />
                  </DndProvider>
                </Grid>
                <Grid item sx={{ width: '25%' }}>
                  <RightArea
                    id={id}
                    pageId={pageData ? pageData?.content?.id : ''}
                    setLayout={setLayout}
                    selectedComponent={selectedComponent}
                    layout={editedLayout}
                    activity={activity}
                    pageActions={pageActions}
                    pageData={pageData}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box sx={{ m: 2 }}>
              <Alert severity={'error'}>
                <AlertTitle>
                  <strong>Access Denied</strong>
                </AlertTitle>
                You are trying to access the resource of other project!.
              </Alert>
            </Box>
          )}
        </>
      ) : (
        <AccessDenied module="Pages" />
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Page',
  actions: [
    {
      slug: 'Edit',
      access: 'Edit',
    },
    {
      slug: 'Review',
      access: 'Review',
    },
    {
      slug: 'Preview',
      access: 'Preview',
    },
  ],
}

export default withRights(PageEditor, moduleAttr)
