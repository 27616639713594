import { Alert, Box, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { Loader, withRights } from '../../components'
import UserWebsiteDashboard from './UserWebsiteDashboard'
import AdminWebsiteDashboard from './AdminWebsiteDashboard'

import DogLogo from '../../assets/Image/Dog.svg'
import { checkUserIsAllowed } from '../../utils/rightUtils'
import EventOverviewDashboard from './EventOverviewDashboard'

const TABS = ['website', 'event']

const NewDashboard = (props) => {
  const { rightsLoading, noSiteAccess, rights } = props
  const { permittedActions, superRights } = rights

  const [selectedTab, setSelectedTab] = useState(TABS[0])

  const userInfo = useSelector((state) => state.session.userInfo)
  const stencilAccessEnabled =
    userInfo &&
    userInfo.memberOf &&
    userInfo.memberOf.includes('APP-STNCL-USERS')

  const actionsAllowed = {
    userCanEditPage: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
      'Page',
    ),
    userCanReviewPage: checkUserIsAllowed(
      'review',
      permittedActions,
      superRights,
      'Page',
    ),
    userCanEditPost: checkUserIsAllowed(
      'edit',
      permittedActions,
      superRights,
      'Post',
    ),
    userCanReviewPost: checkUserIsAllowed(
      'review',
      permittedActions,
      superRights,
      'Post',
    ),
  }

  return (
    <Box sx={{ width: 'calc(100vw - 61px)' }} height="100%">
      {rightsLoading && !noSiteAccess ? (
        <Loader />
      ) : (
        <>
          <Box
            px={2}
            py={1}
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: 28,
                fontWeight: 600,
                lineHeight: 2,
                color: '#3D3D3D',
                marginRight: 2,
              }}
            >
              Hi {userInfo.firstName} Welcome!
            </Typography>
            <DogLogo />
          </Box>
          <Box sx={{ backgroundColor: '#F0F0F0' }}>
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              //indicatorColor="secondary"
              textColor="inherit"
              variant="standard"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#CC0000',
                },
                ml: 3,
                fontSize: 20,
              }}
            >
              <Tab
                sx={{ textTransform: 'none' }}
                label={<span>Website Dashboard</span>}
                value="website"
              />
              {/* {eventEnabled ? ( */}
              <Tab
                sx={{ textTransform: 'none' }}
                label={<span>Event Dashboard</span>}
                value="event"
              />
              {/* ) : (
            ''
          )} */}
            </Tabs>
          </Box>
          {noSiteAccess ? (
            <>
              <Alert
                severity={'success'}
                sx={{ width: 'calc(100% - 40px)', m: '20px' }}
              >
                {stencilAccessEnabled
                  ? 'Your stencil entitlement has been successfully activated!'
                  : 'You dont have Stencil access'}
              </Alert>
              <Alert
                severity="warning"
                sx={{ width: 'calc(100% - 40px)', m: '20px' }}
              >
                Role assignment on your profile is in <strong>pending</strong>{' '}
                state. Ask your site administrator to assign the role for you.
                <br />
                If you are the admin of the site please reach out to{' '}
                <strong>Stencil</strong> team to onboard the site and
                administrator
              </Alert>
            </>
          ) : (
            <>
              {selectedTab === TABS[0] && superRights && (
                <AdminWebsiteDashboard />
              )}
              {selectedTab === TABS[0] && !superRights && (
                <UserWebsiteDashboard actionsAllowed={actionsAllowed} />
              )}
              {selectedTab === TABS[1] && (
                <Box>
                  <EventOverviewDashboard />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}

const moduleAttr = {
  name: 'Dashboard',
  actions: [
    {
      overrideModuleName: 'Page',
      slug: 'Edit',
      access: 'Edit',
    },
    {
      overrideModuleName: 'Page',
      slug: 'Review',
      access: 'Review',
    },
    {
      overrideModuleName: 'Post',
      slug: 'Edit',
      access: 'Edit',
    },
    {
      overrideModuleName: 'Post',
      slug: 'Review',
      access: 'Review',
    },
  ],
}

export default withRights(NewDashboard, moduleAttr)
