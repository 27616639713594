import React from 'react'
import { Box } from '@mui/material'
import FormTextField from './FormTextField'
import FormToggle from './FormToggle'
import FormDateField from './FormDateField'

const loadField = (props) => {
  const types = {
    text: FormTextField,
    switch: FormToggle,
    date: FormDateField,
  }
  const Field = types[props.type]
  return <Field {...props}></Field>
}

export const FormFieldLoader = (props) => {
  return <Box sx={{ flexGrow: 1 }}>{loadField(props)}</Box>
}

export default FormFieldLoader
