import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import PageList from '../../../../Menu/Common/PageList'
import {
  Step,
  Stepper,
  StepLabel,
  Typography,
  Alert,
  Card,
  Popover,
} from '@mui/material'
import { useGetRevisionQuery } from '../../../../../api/page'
import { useSelector } from 'react-redux'
import ListLoader from '../../../../../components/ListLoader'
import Preview from '../../Main/Preview'
import { getLatestVersionIdFromPage } from '../../../../Pages/pageUtils'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const LoadSection = ({
  section,
  setSelectedComponent,
  selectedComponent,
  sectionNumber,
  allowed,
}) => {
  const { componentWidths, components, sectionName } = section
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const selectTheComponent = (sectionNo, columnNo, component) => {
    setSelectedComponent(
      selectedComponent &&
        selectedComponent.sectionNo === sectionNo &&
        selectedComponent.columnNo === columnNo
        ? null
        : {
            sectionNo,
            columnNo,
            component,
          },
    )
  }

  return (
    <>
      <Typography variant="caption" sx={{ ml: 1 }}>
        Section name: {sectionName}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {componentWidths &&
          componentWidths.map((width, key) => {
            const cardSelected =
              selectedComponent &&
              selectedComponent.sectionNo === sectionNumber &&
              selectedComponent.columnNo === key
            return (
              <React.Fragment key={key}>
                {components[key] && (
                  <React.Fragment key={key}>
                    <Card
                      disabled={allowed.includes(components[key].slug)}
                      sx={(theme) => ({
                        width: `${width}%`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        boxShadow: cardSelected ? 4 : 1,
                        background: !allowed.includes(components[key].slug)
                          ? 'grey'
                          : cardSelected
                          ? theme.palette.primary.main
                          : '#fff',
                        color: cardSelected ? '#fff' : 'default',
                        cursor: !allowed.includes(components[key].slug)
                          ? 'not-allowed'
                          : 'pointer',
                        '&:hover': {
                          boxShadow: 4,
                        },
                      })}
                      onClick={() =>
                        selectTheComponent(sectionNumber, key, components[key])
                      }
                      {...(allowed.includes(components[key].slug)
                        ? {
                            onMouseEnter: handlePopoverOpen,
                            onMouseLeave: handlePopoverClose,
                          }
                        : {})}
                    >
                      {components[key].name}
                    </Card>
                    <Popover
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                    >
                      <Box sx={{ p: 3 }}>
                        <Preview
                          component={components[key]}
                          width={width}
                          hideControls={true}
                          defaultVal={false}
                          defaultZoomVal={30}
                        />
                      </Box>
                    </Popover>
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          })}
      </Box>
    </>
  )
}

const ListPageSections = (props) => {
  const {
    selectedPage,
    setSelectedComponent,
    selectedComponent,
    allowed = [],
  } = props
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const latestVersionId = getLatestVersionIdFromPage(selectedPage)
  const {
    data: pageData,
    isLoading: loadingPageData,
    error: loadError,
  } = useGetRevisionQuery(
    { id: latestVersionId ? latestVersionId : null, activeSite },
    {
      skip: !(latestVersionId && activeSite),
      refetchOnMountOrArgChange: true,
    },
  )
  // const {
  //   data: pageData,
  //   isLoading: loadingPageData,
  //   error: loadError,
  // } = useGetPageQuery(
  //   { id: selectedPage ? selectedPage.id : null, activeSite },
  //   {
  //     skip: !(selectedPage && selectedPage.id && activeSite),
  //     refetchOnMountOrArgChange: true,
  //   }
  // )
  return (
    <>
      <Box sx={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Typography sx={{ mr: 2 }}>
            <strong>Selected page:</strong>{' '}
            {selectedPage ? selectedPage.name : ''}
          </Typography>
          <Typography>
            <strong>Selected component:</strong>{' '}
            {selectedComponent ? selectedComponent.component.name : 'none'}
          </Typography>
        </Box>
        {loadError && (
          <Alert severity="error">
            Unable to load page component!. Please try again later or report to
            product team
          </Alert>
        )}
        {loadingPageData && <ListLoader />}
        {pageData && (
          <Box>
            {pageData.layout &&
              pageData.layout.map((section, sectionKey) => {
                return (
                  <LoadSection
                    allowed={allowed}
                    key={sectionKey}
                    section={section}
                    setSelectedComponent={setSelectedComponent}
                    selectedComponent={selectedComponent}
                    sectionNumber={sectionKey}
                  />
                )
              })}
          </Box>
        )}
      </Box>
    </>
  )
}

export default function CloneComponent(props) {
  const { open, handleClose, handleAdd, allowed = [] } = props
  const [openPageList, setOpenPageList] = React.useState(false)
  const handleClosePageList = () => {
    setOpenPageList(false)
  }

  const steps = [
    'Select a Page',
    'Select component from the page',
    'Add the component to current page',
  ]
  const [activeStep, setActiveStep] = React.useState(0)
  const [resetPageSelection, setResetPageSelection] = React.useState(false)
  const [selectedPage, setSelectedPage] = React.useState(null)
  const [selectedComponent, setSelectedComponent] = React.useState(null)

  const handleNext = () => {
    if (activeStep === 2) {
      handleClone()
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const selectPageFromList = () => {
    setResetPageSelection(true)
    setOpenPageList(true)
  }

  const selectPage = (pageInfo) => {
    setActiveStep(1)
    setSelectedPage(pageInfo)
    setOpenPageList(false)
  }

  const handleClone = () => {
    handleAdd(selectedComponent.component)
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 800 }}>
          <h2 id="parent-modal-title">Clone a component from page</h2>
          <Box sx={{ pt: 3 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            <Box sx={{ p: 3, m: 1, border: '1px solid' }}>
              {activeStep === 0 && (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'none' }}
                  onClick={() => selectPageFromList()}
                >
                  Click to select the page
                </Button>
              )}
              {activeStep === 1 && (
                <ListPageSections
                  selectedPage={selectedPage}
                  selectedComponent={selectedComponent}
                  setSelectedComponent={setSelectedComponent}
                  allowed={allowed}
                />
              )}
              {activeStep === 2 && (
                <>You have successfully choosen the component. click Finish</>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={{ textTransform: 'none', mr: 1 }}
                onClick={() => handleBack()}
                variant={'outlined'}
                disabled={activeStep === 0}
              >
                Back
              </Button>
              <Button
                sx={{ textTransform: 'none' }}
                variant={'contained'}
                onClick={() => handleNext()}
                disabled={
                  (activeStep === 0 && !selectedPage) ||
                  (activeStep === 1 && !selectedComponent)
                }
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
          <PageList
            open={openPageList}
            handleClose={handleClosePageList}
            addPage={selectPage}
            reset={resetPageSelection}
            setReset={setResetPageSelection}
            includeId={true}
            forClone={true}
          />
        </Box>
      </Modal>
    </div>
  )
}
