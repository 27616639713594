import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { SvgIcon } from '@mui/material'
import {
  TOP_LEFT,
  TOP_CENTER,
  TOP_RIGHT,
  MIDDLE_LEFT,
  MIDDLE_CENTER,
  MIDDLE_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_CENTER,
  BOTTOM_RIGHT,
} from '../../../../../../../assets/Image'
import { disableControlsBasedOnDevice } from '../../Helpers'
import { Label } from '../Label'

const Position = (props) => {
  const {
    value = null,
    onChange,
    disabled = false,
    slug = null,
    helperText = 'position helper text',
    size = 'small',
    device = 'desktop',
    enableLabel = false,
    name = '',
    forDevices = [],
    noSlugChange = false,
  } = props

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )

  return (
    <Box
      sx={{
        height: '100%',
        p: 0.5,
      }}
    >
      {enableLabel && (
        <Label name={name} disabled={disabledOrNot} helperText={helperText} />
      )}
      <Tooltip arrow placement={'top'} title={helperText}>
        <>
          <ToggleButtonGroup
            value={value}
            size={size}
            onChange={(_, val) =>
              onChange(
                val,
                noSlugChange
                  ? slug
                  : `${slug}${
                      device && device !== 'desktop'
                        ? device.charAt(0).toUpperCase() + device.slice(1)
                        : ''
                    }`,
              )
            }
            aria-label="text position"
            disabled={disabled}
            fullWidth
            exclusive
            sx={{
              '& .MuiToggleButtonGroup-grouped': {
                textTransform: 'none',
                lineHeight: 'normal',
                borderRadius: 0,
              },
              '& .Mui-selected': {
                background: 'grey.light',
                fontWeight: 'bold',
                borderRadius: 0,
              },
              textOverflow: 'scroll',
            }}
            data-testid="top-position-comp"
          >
            <ToggleButton
              value="top-left"
              aria-label="top-left"
              data-testid="position-top-left"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <TOP_LEFT />
              </SvgIcon>
            </ToggleButton>
            <ToggleButton
              value="top-center"
              aria-label="top-center"
              data-testid="position-top-center"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <TOP_CENTER />
              </SvgIcon>
            </ToggleButton>
            <ToggleButton
              value="top-right"
              aria-label="top-right"
              data-testid="position-top-right"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <TOP_RIGHT />
              </SvgIcon>
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={value}
            size={size}
            onChange={(_, val) =>
              onChange(
                val,
                noSlugChange
                  ? slug
                  : `${slug}${
                      device && device !== 'desktop'
                        ? device.charAt(0).toUpperCase() + device.slice(1)
                        : ''
                    }`,
              )
            }
            aria-label="text position"
            disabled={disabled}
            fullWidth
            exclusive
            sx={{
              '& .MuiToggleButtonGroup-grouped': {
                textTransform: 'none',
                lineHeight: 'normal',
                borderRadius: 0,
              },
              '& .Mui-selected': {
                background: 'grey.light',
                fontWeight: 'bold',
                borderRadius: 0,
              },
              textOverflow: 'scroll',
            }}
            data-testid="middle-position-comp"
          >
            <ToggleButton
              value="middle-left"
              aria-label="middle-left"
              data-testid="position-middle-left"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <MIDDLE_LEFT />
              </SvgIcon>
            </ToggleButton>
            <ToggleButton
              value="middle-center"
              aria-label="middle-center"
              data-testid="position-middle-center"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <MIDDLE_CENTER />
              </SvgIcon>
            </ToggleButton>
            <ToggleButton
              value="middle-right"
              aria-label="middle-right"
              data-testid="position-middle-right"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <MIDDLE_RIGHT />
              </SvgIcon>
            </ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            value={value}
            size={size}
            onChange={(_, val) =>
              onChange(
                val,
                noSlugChange
                  ? slug
                  : `${slug}${
                      device && device !== 'desktop'
                        ? device.charAt(0).toUpperCase() + device.slice(1)
                        : ''
                    }`,
              )
            }
            aria-label="text position"
            disabled={disabled}
            fullWidth
            exclusive
            sx={{
              '& .MuiToggleButtonGroup-grouped': {
                textTransform: 'none',
                lineHeight: 'normal',
                borderRadius: 0,
              },
              '& .Mui-selected': {
                background: 'grey.light',
                fontWeight: 'bold',
                borderRadius: 0,
              },
              textOverflow: 'scroll',
            }}
            data-testid="bottom-position-comp"
          >
            <ToggleButton
              value="bottom-left"
              aria-label="bottom-left"
              data-testid="position-bottom-left"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <BOTTOM_LEFT />
              </SvgIcon>
            </ToggleButton>
            <ToggleButton
              value="bottom-center"
              aria-label="bottom-center"
              data-testid="position-bottom-center"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <BOTTOM_CENTER />
              </SvgIcon>
            </ToggleButton>
            <ToggleButton
              value="bottom-right"
              aria-label="bottom-right"
              data-testid="position-bottom-right"
              fullWidth
              sx={{ maxHeight: 40 }}
            >
              <SvgIcon fontSize="small">
                <BOTTOM_RIGHT />
              </SvgIcon>
            </ToggleButton>
          </ToggleButtonGroup>
        </>
      </Tooltip>
    </Box>
  )
}

Position.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  slug: PropTypes.string,
  helperText: PropTypes.string,
}

export default Position
