import {
  Card,
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import ColorPicker from 'react-best-gradient-color-picker'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useSelector } from 'react-redux'
import {
  useGetConfigurationBySlugQuery,
  useSaveConfigurationMutation,
} from '../../api/configuration'
import ListLoader from '../../components/ListLoader'

const ColorPalette = (props) => {
  const { allow } = props
  const [color, setColor] = useState(
    'linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)',
  )
  const [selectedColors, setSelectedColors] = useState([])
  const [open, setOpen] = useState(false)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const userInfo = useSelector((state) => state.session.userInfo)
  const [confId, setConfId] = useState(null)

  const { data: paletteData, isLoading: paletteDataLoading } =
    useGetConfigurationBySlugQuery(
      {
        slug: 'palette',
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  const [saveConfiguration, { isLoading: saveConfigLoading }] =
    useSaveConfigurationMutation()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleCancel = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    setOpen(false)
    setSelectedColors([...selectedColors, color])
  }

  const handleRemove = (i) => {
    setSelectedColors([
      ...selectedColors.slice(0, i),
      ...selectedColors.slice(i + 1),
    ])
  }

  const savePalleteColors = () => {
    saveConfiguration({
      configuration: {
        name: 'palette',
        value: selectedColors,
      },
      activeSite,
      id: confId,
    })
  }

  useEffect(() => {
    const updateMenuData = () => {
      const { settings = [] } = paletteData
      let found = false
      settings.map((conf) => {
        if (conf.name === 'palette') {
          found = true
          setSelectedColors(conf.value ? conf.value : [])
          setConfId(conf._id)
        }
        return true
      })
      if (!found) {
        setSelectedColors([])
        setConfId(null)
      }
    }
    if (!paletteDataLoading && paletteData) {
      updateMenuData(paletteData)
    }
  }, [paletteData, paletteDataLoading])

  return (
    <>
      {!paletteDataLoading ? (
        <Card>
          {saveConfigLoading && <LinearProgress />}
          <Box
            sx={{
              background: '#E6E7EA',
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Color Palette</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              sx={{
                textTransform: 'none',
                ml: 2,
                display: 'flex',
                backgroundColor: '#CC0000',
              }}
              size="small"
              startIcon={<SaveIcon />}
              disabled={!allow.edit || saveConfigLoading}
              onClick={savePalleteColors}
            >
              Save
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              ml: 5,
            }}
          >
            <Card
              sx={{
                textTransform: 'none',
                width: '100px',
                height: '100px',
                display: 'flex',
                m: 2,
                justifyContent: 'center',
              }}
              onClick={handleClickOpen}
            >
              <IconButton
                aria-label="add"
                size="large"
                sx={{
                  '&:hover': { backgroundColor: 'transparent' },
                }}
                disabled={!allow.edit}
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </Card>
            {selectedColors &&
              selectedColors?.map((item, index) => {
                return (
                  <Card
                    key={index}
                    sx={{
                      height: '100px',
                      width: '100px',
                      borderradius: '4px',
                      background: item,
                      m: 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleRemove(index)}
                      sx={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
                    >
                      <ClearIcon fontSize="inherit" sx={{ color: 'white' }} />
                    </IconButton>
                  </Card>
                )
              })}
          </Box>
        </Card>
      ) : (
        <ListLoader />
      )}
      <Dialog
        sx={{ '& .MuiDialog-paper': { maxHeight: '70%' } }}
        maxWidth="xl"
        open={open}
      >
        <DialogTitle>Color Picker</DialogTitle>
        <DialogContent>
          <Typography>Select and submit color to add it to the list</Typography>
          <Box
            sx={{
              background: '#fff',
              borderRadius: '8px',
              boxShadow: '0 0 6px rgb(0 0 0 / 25%)',
              padding: '8px',
              position: 'relative',
              width: '310px',
              zIndex: '102',
            }}
          >
            <ColorPicker value={color} onChange={setColor} hidePresets={true} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ColorPalette
