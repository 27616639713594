import React from 'react'
import { ProtectedElement } from '@praxis/component-auth'
import apiConfig from '../apiConfig'

export default function StencilProtectedElement(props) {
  const { children, ...restProps } = props
  const updatedProps = {
    ...restProps,
    ...(apiConfig.isPOL ? { allowed: null } : {}),
  }
  return <ProtectedElement {...updatedProps}>{children}</ProtectedElement>
}
