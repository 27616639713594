import React, { useEffect } from 'react'
import {
  Box,
  Typography,
  Tab,
  Tabs,
  TextField,
  Paper,
  Pagination,
  Button,
  LinearProgress,
  TableSortLabel,
} from '@mui/material'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'
import TabPanel from '../../components/TabPanel'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import SplitButton from '../../components/SplitButton'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import moment from 'moment'
import { Download } from '@mui/icons-material'
import { useGetSubscrptionListQuery } from './../../api/reports'
import apiConfig from '../../apiConfig'
import { orderBy } from 'lodash'
import AccessDenied from '../../Layout/AccessDenied'
import withRights from '../../components/withRights'
import BiotechIcon from '@mui/icons-material/Biotech'

const { api } = apiConfig

const Reports = (props) => {
  const { rightsLoading, rights } = props
  const { moduleAccess } = rights
  const userInfo = useSelector((state) => state.session.userInfo)
  const activeSite = useSelector((state) => state.tenant.activeSite)
  const [typeOfMenu, setTypeOfMenu] = React.useState('subscriptions')
  const [fromDate, setFromDate] = React.useState(moment().startOf('month'))
  const [toDate, setToDate] = React.useState(moment())
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [activeRecords, setActiveRecords] = React.useState([])
  const [disableDates, setDisableDates] = React.useState(true)
  const [order, setOrder] = React.useState('asc')
  const [sortby, setSortBy] = React.useState('createdAt')
  const noSiteAccess = useSelector(
    (state) => state.session.profile.noSiteAccess,
  )
  const handleChange = (_, newValue) => {
    setTypeOfMenu(newValue)
  }

  const { data: subscipersData, isFetching: dataLoading } =
    useGetSubscrptionListQuery(
      {
        filter: {
          from: moment(fromDate).format('YYYY-MM-DD'),
          to: moment(toDate).format('YYYY-MM-DD'),
          per_page: 20,
          page: page,
          order,
          sortby,
        },
        activeSite,
      },
      {
        skip: !(userInfo.lanId && activeSite),
      },
    )

  useEffect(() => {
    if (subscipersData) {
      const { total = 0, data = [] } = subscipersData
      setTotalPages(total ? Math.ceil(total / 20) : 0)
      setActiveRecords(data)
    }
  }, [subscipersData])

  return (
    <div>
      <Box sx={{ width: 'calc(100vw - 58px)', padding: 5 }}>
        {rightsLoading && !noSiteAccess ? (
          <Loader />
        ) : !rights || moduleAccess ? (
          <>
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Typography
                  variant={'h5'}
                  component={'h3'}
                  sx={{
                    fontFamily: 'Helvetica For Target',
                    fontWeight: 'Bold',
                    marginBottom: 1,
                  }}
                >
                  Settings
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Box sx={{ height: '80vh', boxShadow: 5 }}>
              <Box sx={{ width: '100%', height: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={typeOfMenu} onChange={handleChange}>
                    <Tab
                      icon={<ContactMailIcon />}
                      sx={{ textTransform: 'none' }}
                      value="subscriptions"
                      label="Subscriptions"
                    />
                  </Tabs>
                </Box>
                <Box sx={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
                  <TabPanel value={typeOfMenu} index={'subscriptions'}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mb: 2,
                      }}
                    >
                      <Box>
                        <SplitButton
                          options={[
                            {
                              label: 'Current month',
                              value: 'currentMonth',
                              onSelectCb: true,
                              cb: () => {
                                setDisableDates(true)
                                setFromDate(moment().startOf('month'))
                                setToDate(moment())
                              },
                            },
                            {
                              label: 'Last month',
                              value: 'lastMonth',
                              onSelectCb: true,
                              cb: () => {
                                setDisableDates(true)
                                setFromDate(
                                  moment()
                                    .subtract(1, 'months')
                                    .startOf('month'),
                                )
                                setToDate(
                                  moment().subtract(1, 'months').endOf('month'),
                                )
                              },
                            },
                            {
                              label: 'Current year',
                              value: 'currentYear',
                              onSelectCb: true,
                              cb: () => {
                                setDisableDates(true)
                                setFromDate(moment().startOf('year'))
                                setToDate(moment())
                              },
                            },
                            {
                              label: 'Last year',
                              value: 'lastYear',
                              onSelectCb: true,
                              cb: () => {
                                setDisableDates(true)
                                setFromDate(
                                  moment().subtract(1, 'year').startOf('year'),
                                )
                                setToDate(
                                  moment().subtract(1, 'year').endOf('year'),
                                )
                              },
                            },
                            {
                              label: 'Custom selection',
                              value: 'custom',
                              onSelectCb: true,
                              cb: () => {
                                setDisableDates(false)
                                setFromDate(
                                  moment()
                                    .subtract(1, 'month')
                                    .startOf('month'),
                                )
                                setToDate(moment())
                              },
                            },
                          ]}
                          icon={<HistoryToggleOffIcon />}
                        />
                      </Box>
                      <MobileDatePicker
                        label="Start date"
                        disabled={disableDates}
                        inputFormat="DD/MM/YYYY"
                        value={fromDate}
                        onChange={(newValue) => {
                          setFromDate(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField size="small" sx={{ ml: 2 }} {...params} />
                        )}
                      />
                      <MobileDatePicker
                        label="End date"
                        disabled={disableDates}
                        inputFormat="DD/MM/YYYY"
                        value={toDate}
                        onChange={(newValue) => {
                          setToDate(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField size="small" sx={{ ml: 2 }} {...params} />
                        )}
                      />
                      <Button
                        variant="contained"
                        startIcon={<Download />}
                        sx={{ ml: 2, textTransform: 'none' }}
                        onClick={() =>
                          window.open(
                            `${api.host}subscriptions/download?site=${
                              activeSite._id
                            }&key=${api.apiKey}&from=${moment(fromDate).format(
                              'YYYY-MM-DD',
                            )}&to=${moment(toDate).format('YYYY-MM-DD')}`,
                          )
                        }
                      >
                        Download
                      </Button>
                    </Box>
                    {dataLoading && <LinearProgress />}
                    <Box
                      sx={{
                        height: '55vh',
                        overflowY: 'auto',
                        p: 3,
                        background: '#d3d3d3',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <TableContainer
                        sx={{ width: '50%', height: '100%' }}
                        component={Paper}
                      >
                        <Table size="small" sx={{ minWidth: 400 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align={'left'}
                                sortDirection={
                                  orderBy === 'email' ? order : false
                                }
                              >
                                <TableSortLabel
                                  active={sortby === 'email'}
                                  direction={sortby === 'email' ? order : 'asc'}
                                  onClick={() => {
                                    setSortBy('email')
                                    setOrder(order === 'asc' ? 'desc' : 'asc')
                                  }}
                                >
                                  <strong>Email</strong>
                                  {sortby === 'email' ? (
                                    <Box
                                      component="span"
                                      sx={{ display: 'none' }}
                                    >
                                      {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              </TableCell>
                              <TableCell align={'left'}>
                                <TableSortLabel
                                  active={sortby === 'createdAt'}
                                  direction={
                                    sortby === 'createdAt' ? order : 'asc'
                                  }
                                  onClick={() => {
                                    setSortBy('createdAt')
                                    setOrder(order === 'asc' ? 'desc' : 'asc')
                                  }}
                                >
                                  <strong>Subscribed at</strong>
                                </TableSortLabel>
                              </TableCell>
                              <TableCell align={'center'} sx={{ width: 180 }}>
                                <strong>Action</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(!activeRecords || activeRecords.length === 0) && (
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  sx={{ textAlign: 'center' }}
                                >
                                  <BiotechIcon sx={{ fontSize: 150 }} />
                                  <br />
                                  <Typography variant="caption">
                                    Oops! No records found. Try other date
                                    ranges
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                            {activeRecords &&
                              activeRecords.map((val, key) => {
                                return (
                                  <TableRow key={key}>
                                    <TableCell align={'left'}>
                                      {val.email}
                                    </TableCell>
                                    <TableCell align={'left'}>
                                      {moment(val.createdAt).format(
                                        'MMMM Do YYYY',
                                      )}
                                    </TableCell>
                                    <TableCell align={'center'}>
                                      <a href={`mailto:${val.email}`}>
                                        Send email
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box
                      sx={{ display: 'flex', p: 1, justifyContent: 'flex-end' }}
                    >
                      <Pagination
                        onChange={(_, page) => setPage(page)}
                        page={page}
                        color="primary"
                        count={totalPages}
                      />
                    </Box>
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <AccessDenied module="Reports" />
        )}
      </Box>
    </div>
  )
}

const moduleAttr = {
  name: 'Reports',
  actions: [
    {
      slug: 'read',
      access: 'read',
    },
    {
      slug: 'update',
      access: 'update',
    },
  ],
}

export default withRights(Reports, moduleAttr)
