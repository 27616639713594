import {
  Box,
  // ButtonGroup,
  // IconButton,
  // ToggleButton,
  // ToggleButtonGroup,
} from '@mui/material'
import React from 'react'
import SpacingControl from './SpacingControl'
import { disableControlsBasedOnDevice } from '../Helpers'
import { Label } from '../Common'
// import CropFreeIcon from '@mui/icons-material/CropFree'
// import CropDinIcon from '@mui/icons-material/CropDin'
// import _ from 'lodash'

export default function Spacing(props) {
  const {
    value = {},
    onChange,
    disabled = false,
    variant = 'm',
    device = '',
    slug = '',
    enableLabel = false,
    name = '',
    forDevices = [],
    helperText = '',
    customIdentifiers = {},
  } = props
  const deviceKeys = {
    desktop: 'md',
    tablet: 'sm',
    mobile: 'xs',
  }

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )
  // const [linked, setLinked] = React.useState('linked')
  const marginAttributes = [
    { name: 'mt', pos: { gridColumn: 'span 2 / auto', margin: '0px auto' } },
    { name: 'ml', pos: { margin: '0px' } },
    { name: 'mr', pos: { margin: '0px 0px 0px auto' } },
    { name: 'mb', pos: { gridColumn: 'span 2 / auto', margin: '0px auto' } },
  ]
  // const marginSides = _.map(marginAttributes, 'name')
  const paddingAttributes = [
    { name: 'pt', pos: { gridColumn: 'span 2 / auto', margin: '0px auto' } },
    { name: 'pl', pos: { margin: '0px' } },
    { name: 'pr', pos: { margin: '0px 0px 0px auto' } },
    { name: 'pb', pos: { gridColumn: 'span 2 / auto', margin: '0px auto' } },
  ]
  // const paddingSides = _.map(paddingAttributes, 'name')

  // const handleChange = (_, linkState) => {
  //   if (linkState !== null) {
  //     setLinked(linkState)
  //   }
  // }

  return (
    <Box data-testid="spacing-ctl">
      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ToggleButtonGroup
          size="small"
          variant="outlined"
          value={linked}
          onChange={handleChange}
          exclusive
        >
          <ToggleButton value={'linked'}>
            <CropDinIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton value={'unlinked'}>
            <CropFreeIcon fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box> */}
      {enableLabel && (
        <Label name={name} disabled={disabledOrNot} helperText={helperText} />
      )}
      <Box
        sx={{
          display: 'grid',
          gap: 'calc(30px)',
          gridTemplateColumns: 'repeat(2, 1fr)',
          position: 'relative',
          flex: '1 1 0%',
        }}
      >
        {/* For border preview */}
        <Box
          sx={{
            position: 'absolute',
            inset: '34% 39%',
            border: '1px solid rgba(0, 0, 0, 0.23)',
          }}
        />
        {variant === 'm' &&
          marginAttributes.map((attr, key) => {
            return (
              <Box
                key={key}
                sx={{
                  border: 0,
                  padding: 0,
                  boxSizing: 'border-box',
                  ...attr.pos,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 'calc(12px)',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: 30,
                    p: 0,
                  }}
                >
                  <SpacingControl
                    {...attr}
                    type={variant}
                    onChange={onChange}
                    slug={
                      device
                        ? `${
                            customIdentifiers?.[attr.name]
                              ? customIdentifiers?.[attr.name]
                              : attr.name
                          }.${deviceKeys[device]}`
                        : `${attr.name}`
                    }
                    parentSlug={slug}
                    // allSides={marginSides}
                    // linked={linked}
                    value={
                      device
                        ? value?.[
                            customIdentifiers?.[attr.name]
                              ? customIdentifiers?.[attr.name]
                              : attr.name
                          ]?.[deviceKeys[device]]
                        : value?.[attr.name]
                    }
                    disabled={disabledOrNot}
                  />
                </Box>
              </Box>
            )
          })}
        {variant === 'p' &&
          paddingAttributes.map((attr, key) => {
            return (
              <Box
                key={key}
                sx={{
                  border: 0,
                  padding: 0,
                  boxSizing: 'border-box',
                  ...attr.pos,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 'calc(12px)',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: 30,
                    p: 0,
                  }}
                >
                  <SpacingControl
                    {...attr}
                    type={variant}
                    onChange={onChange}
                    slug={
                      device
                        ? `${
                            customIdentifiers?.[attr.name]
                              ? customIdentifiers?.[attr.name]
                              : attr.name
                          }.${deviceKeys[device]}`
                        : `${attr.name}`
                    }
                    value={
                      device
                        ? value?.[
                            customIdentifiers?.[attr.name]
                              ? customIdentifiers?.[attr.name]
                              : attr.name
                          ]?.[deviceKeys[device]]
                        : value?.[attr.name]
                    }
                    parentSlug={slug}
                    disabled={disabledOrNot}
                    // linked={linked}
                    // allSides={paddingSides}
                  />
                </Box>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}
