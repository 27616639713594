import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Zoom } from '@mui/material'

const CustomizedToolTip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    TransitionComponent={Zoom}
    followCursor
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 440,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const HTMLTooltip = (props) => {
  const { content } = props
  return (
    <CustomizedToolTip
      title={<React.Fragment>{content}</React.Fragment>}
      {...props}
    />
  )
}

export default HTMLTooltip
