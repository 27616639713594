export const checkConditions = (peerValues, key, condition) => {
  const fieldCond = condition[key]
  if (typeof fieldCond === 'object') {
    if (fieldCond.neq) {
      return peerValues[key] !== fieldCond.neq
    } else {
      let check = true
      Object.keys(fieldCond).map((sKey) => {
        const condValue = fieldCond[sKey]
        const localCheck =
          peerValues[key] &&
          peerValues[key][sKey] &&
          peerValues[key][sKey] === condValue
        if (!localCheck) {
          check = false
        }
        return localCheck
      })
      return check
    }
  } else {
    if (
      !peerValues ||
      !peerValues[key] ||
      (peerValues[key] && fieldCond && peerValues[key] !== fieldCond)
    ) {
      return false
    } else {
      return true
    }
  }
}

export const showComponent = (field, peerValues) => {
  let show = true
  if (field.when && field.when.length > 0) {
    field.when.map((c) => {
      let keys = Object.keys(c)
      keys.map((k) => {
        if (show) {
          show = checkConditions(peerValues, k, c)
        }
        return show
      })
      return show
    })
  }
  return show
}

export const disableControlsBasedOnDevice = (
  device = '',
  forDevices = [],
  editDisabled = false,
) => {
  if (
    editDisabled ||
    (forDevices.length === 0 && device !== 'desktop') ||
    (forDevices.length > 0 &&
      device !== 'desktop' &&
      !forDevices.includes(device))
  ) {
    return true
  }

  return false
}
