import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import FormStyledInput from './FormStyledInput'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Delete } from '@mui/icons-material'
import { useField } from 'formik'
import MediaPicker from '../../../components/MediaPicker'
import { getSize } from '../../../utils/helper'

export const FormMediaField = (props) => {
  const {
    label = '',
    maxHeight = 'auto',
    form = {},
    disableFields = false,
  } = props

  // eslint-disable-next-line no-unused-vars
  const [field, _state, { setValue }] = useField(props?.field?.name)
  const { value = '', name = '' } = field
  const { touched = {}, errors = {} } = { ...form }
  const error = touched?.[name] && errors?.[name] !== undefined
  const [currentFile, setCurrentFile] = React.useState(value)
  const [open, setOpen] = React.useState(false)
  const [pickedItemMeta, setPickedItemMeta] = React.useState(null)

  useEffect(() => {
    if (value) {
      setValue(value)
    } else {
      setCurrentFile(currentFile)
    }

    if (currentFile || value === '') {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile, value])

  return (
    <FormStyledInput>
      {currentFile ? (
        <Card
          sx={{
            position: 'relative',
            maxHeight: maxHeight,
            minHeight: '100px',
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            background: 'rgba(0,0,0,0.1)',
          }}
        >
          <CardMedia
            component="img"
            image={currentFile}
            sx={{
              backgroundSize: 'contain',
              objectFit: 'none',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              backgroundColor: '#FFFFFF',
              opacity: '0.7',
            }}
          >
            <Typography
              sx={{
                color: 'black',
                pl: '10px',
                fontWeight: 'bolder',
                fontSize: '14px',
              }}
            >
              {`${
                pickedItemMeta?.name
                  ? pickedItemMeta?.name?.split('.')[0]
                  : 'NA'
              }`}
            </Typography>
            <Tooltip title="Delete the image">
              <IconButton
                onClick={() => {
                  setCurrentFile('')
                  setValue('')
                }}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  bottom: 2,
                  padding: '0px',
                }}
                disabled={disableFields}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              backgroundColor: '#FFFFFF',
              opacity: '0.7',
            }}
          >
            <Typography
              sx={{
                color: 'black',
                pl: '10px',
                fontStyle: 'italic',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {`size: ${
                pickedItemMeta?.size ? getSize(pickedItemMeta?.size) : 'NA'
              }`}
            </Typography>
          </Box>
        </Card>
      ) : (
        <Box>
          <Card
            sx={{
              borderColor: '#D6D6D6',
              borderWidth: 5,
              borderStyle: 'dashed',
              width: '100%',
              minHeight: '100px',
              border:
                error && errors?.[name]
                  ? '2px dashed #d32f2f'
                  : '2px dashed #D6D6D6',
              height: 200,
              maxHeight: maxHeight,
              background: '#F0F0F0',
            }}
          >
            <CardActionArea
              onClick={() => {
                !open && setOpen(true)
              }}
              disabled={disableFields}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {open ? (
                <MediaPicker
                  dataCallBack={(response, obj) => {
                    if (response) {
                      setCurrentFile(response)
                      setValue(response)
                      obj && setPickedItemMeta(obj)
                    } else {
                      setCurrentFile('')
                      setValue('')
                    }
                    setOpen(false)
                  }}
                  fileNameshrink={true}
                  disabled={false}
                  isMediaOnly={true}
                  value={value}
                />
              ) : (
                <>
                  <CloudUploadIcon sx={{ fontSize: 30 }} />
                  <Typography
                    sx={{
                      fontSize: 12,
                      textAlign: 'center',
                      color: '#666666',
                      pl: 3,
                      pr: 3,
                      ml: 3,
                      mr: 3,
                    }}
                  >
                    {label}
                  </Typography>
                </>
              )}
            </CardActionArea>
          </Card>
          <Typography
            variant="body1"
            sx={{ fontSize: '12px', color: '#d32f2f' }}
          >
            {error ? errors?.[name] : ''}
          </Typography>
        </Box>
      )}
    </FormStyledInput>
  )
}

export default FormMediaField
