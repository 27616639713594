import * as React from 'react'
import Stack from '@mui/material/Stack'
import { Box, Typography } from '@mui/material'
import { formatDate } from '../../../../utils/helper'

const StatusStepper = (props) => {
  const { pageStatus } = props

  return pageStatus ? (
    pageStatus?.map((s, index) => {
      return (
        <Stack
          key={`stepper-${index}`}
          sx={{
            mt: '-8px !important',
            display: 'flex',
            alignItems: 'flex-start',
            overflow: 'scroll',
          }}
          spacing={-0}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                width: '3px',
                borderTopWidth: 3,
                borderRadius: 1,
                height: '80px',
                backgroundColor: '#888888',
                ml: '12px',
                my: '-5px',
              }}
            />
            <Box
              sx={{
                width: '150px',
                borderTopWidth: 3,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                height: '30px',
                backgroundColor: '#F0F0F0',
                ml: '12px',
                my: '5px',
                position: 'relative',
                left: -11,
                mt: '20px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" sx={{ ml: '5px' }}>{`${formatDate(
                s?.createdAt,
              )}`}</Typography>
            </Box>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: 13,
                height: 13,
                borderRadius: '50%',
                backgroundColor: '#888888',
                ml: '7px',
                my: '0px !important',
              }}
            />
            <Typography variant="body1" sx={{ ml: '5px', color: '#CC0000' }}>
              {s?.team}
            </Typography>
          </div>
        </Stack>
      )
    })
  ) : (
    <>something went wrong!</>
  )
}

export default StatusStepper
