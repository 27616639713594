import React from 'react'
import { Box, Typography } from '@mui/material'
import { POWER_OF_PEN } from '../../../../../assets/Image'

const NoSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ margin: '70px' }}>
        <img src={POWER_OF_PEN} alt="powerofpen" />
      </Box>
      <Box>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Build your design by adding a New Section
        </Typography>
      </Box>
      {/* <Box sx={{ mb: 15 }}>
        <Typography variant="body1" sx={{ fontSize: '24px' }}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry
        </Typography>
      </Box> */}
    </Box>
  )
}

export default NoSection
