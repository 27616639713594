import React from 'react'
import { Box, TextField, InputAdornment } from '@mui/material'

export const Number = (props) => {
  const {
    value,
    onChange,
    slug = '',
    type = 'number',
    defaultValue = '',
    endAdorment = null,
    min = 0,
    max = 100000,
    float = false,
    disabled = false,
    width,
    device = '',
    testid = '',
  } = props
  const inputProps = {
    ...(endAdorment
      ? {
          endAdornment: (
            <InputAdornment sx={{ '& p': { fontSize: 12 } }} position="end">
              {endAdorment}
            </InputAdornment>
          ),
          step: float ? '0.1' : '1',
          min,
          max,
          'data-testid': testid ? testid : 'text-input',
        }
      : {
          min,
          max,
          step: float ? '0.1' : '1',
          'data-testid': testid ? testid : 'text-input',
        }),
    style: { fontSize: 13, borderRadius: 0 },
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          width: width ? width : '100%',
          justifyContent: 'center',
        }}
      >
        <TextField
          value={value ? value : defaultValue}
          onChange={(e) =>
            onChange(
              float ? e.target.value : parseInt(e.target.value),
              `${slug}${
                device && device !== 'desktop'
                  ? device.charAt(0).toUpperCase() + device.slice(1)
                  : ''
              }`,
            )
          }
          fullWidth
          type={type}
          size="small"
          inputProps={inputProps}
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}

export default Number
