import { Box, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material'
import React from 'react'
import { IconSelector, disableControlsBasedOnDevice } from '../../Helpers'
import { Label } from '../Label'

export const AttrButtonGroup = (props) => {
  const {
    disabled = false,
    value = '',
    onChange,
    slug = null,
    allowedValues = [],
    helperText = '',
    enableIconBtn = false,
    disabledOptions = [],
    size = 'medium',
    device = 'desktop',
    enableLabel = false,
    name = '',
    forDevices = [],
    noSlugChange = false,
    testid = '',
    disableTooltip = false,
    fontSize = 'normal',
  } = props

  const disabledOrNot = disableControlsBasedOnDevice(
    device,
    forDevices,
    disabled,
  )

  return (
    <Box
      sx={{
        cursor: disabledOrNot ? 'not-allowed' : 'inherit',
      }}
    >
      {enableLabel && (
        <Label name={name} disabled={disabledOrNot} helperText={helperText} />
      )}
      <Tooltip
        arrow
        placement={'top'}
        title={helperText}
        followCursor
        disableHoverListener={disableTooltip}
      >
        <ToggleButtonGroup
          value={value}
          exclusive
          fullWidth
          size={size}
          onChange={(_, val) =>
            onChange(
              val,
              noSlugChange
                ? slug
                : `${slug}${
                    device && device !== 'desktop'
                      ? device.charAt(0).toUpperCase() + device.slice(1)
                      : ''
                  }`,
            )
          }
          sx={{
            '& .MuiToggleButtonGroup-grouped': {
              textTransform: 'none',
              lineHeight: 'normal',
              borderRadius: 0,
            },
            '& .Mui-selected': {
              background: 'grey.light',
              fontWeight: 'bold',
              borderRadius: 0,
            },
            overflow: 'auto',
          }}
          disabled={disabledOrNot}
          data-testid={testid ? testid : 'icon-btn-group'}
        >
          {allowedValues &&
            allowedValues.length > 0 &&
            allowedValues.map((option, key) => {
              return (
                <ToggleButton
                  key={key}
                  value={typeof option === 'string' ? option : option.value}
                  data-testid={
                    testid ? `${testid}-${key}` : `icon-btn-group-${key}`
                  }
                  disabled={disabledOptions.includes(option.value)}
                  sx={{
                    maxHeight: 40,
                    fontSize,
                  }}
                >
                  {enableIconBtn ? (
                    <>
                      {option.icon ? (
                        <IconSelector name={option.icon} />
                      ) : (
                        option
                      )}
                    </>
                  ) : (
                    <>{typeof option === 'string' ? option : option.label}</>
                  )}
                </ToggleButton>
              )
            })}
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  )
}

export default AttrButtonGroup
