import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Box,
  Divider,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Tooltip,
} from '@mui/material'

import { useGetAdminComponentsCountQuery } from '../../../api/newDashboard'
import CheckIcon from '../../../assets/Image/check_icon.svg'

const COLORS = ['#519B80', '#E2BA0C', '#CC0000']

const FrequentlyUsedComponentsCard = (props) => {
  const [data, setData] = useState([])
  const [publishedPagesCount, setPublishedPagesCount] = useState(0)
  const [selectedYear, setSelectedYear] = useState(moment().year())

  const { data: componentCountData, isFetching: isComponentCountLoading } =
    useGetAdminComponentsCountQuery({
      year: selectedYear,
    })

  useEffect(() => {
    if (componentCountData) {
      const countData = []
      const { pages_published, ...monthlyCountData } = componentCountData
      Object.keys(monthlyCountData).forEach((month) => {
        Object.keys(monthlyCountData[month]).forEach((component) => {
          const indexAt = countData.findIndex((item) => item.name === component)
          if (indexAt === -1) {
            countData.push({
              name: component,
              count: monthlyCountData[month][component],
            })
          } else {
            countData[indexAt].count += monthlyCountData[month][component]
          }
        })
      })
      const totalComponents = countData.reduce(
        (acc, item) => acc + item.count,
        0,
      )
      countData.forEach((item) => {
        item.value = Math.round((item.count * 10000) / totalComponents) / 100
        item.tooltipData = (
          <div>
            <h3>Count: {item.count}</h3>
            {Object.keys(monthlyCountData).map((month) => (
              <div key={month}>
                {month}: {monthlyCountData[month][item.name] || 0}
              </div>
            ))}
          </div>
        )
      })
      countData.sort((a, b) => (a.value > b.value ? -1 : 1))
      setData(countData)
      setPublishedPagesCount(pages_published)
    }
  }, [componentCountData])

  return (
    <Paper sx={{ borderRadius: '20px', padding: '20px' }}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
      >
        <CheckIcon style={{ margin: '2px', flex: '0 0 20px' }} />
        <div style={{ flex: '1 1 100%', margin: '0 10px' }}>
          <h4>Frequently used components</h4>
          <p
            style={{
              fontSize: 'smaller',
              fontWeight: 'bold',
              color: '#A3AED0',
            }}
          >
            Published Pages : {publishedPagesCount}
          </p>
        </div>
        <Select
          size="small"
          value={selectedYear}
          onChange={(event) => {
            setSelectedYear(event.target.value)
          }}
        >
          {Array.from({ length: 5 }, (_, i) => moment().year() - i).map(
            (year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ),
          )}
        </Select>
      </div>
      <Divider />
      {isComponentCountLoading ? (
        <Skeleton height={300} />
      ) : (
        <Box py={2}>
          {data?.map(({ name, value, tooltipData }, index) => (
            <div key={index} style={{ margin: '5px 0' }}>
              <div
                style={{
                  color: '#A3AED0',
                  fontSize: '14px',
                  wordBreak: 'break-all',
                  textTransform: 'capitalize',
                }}
              >
                {name}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={tooltipData}>
                  <LinearProgress
                    variant="determinate"
                    value={value}
                    sx={{
                      flex: '1 1 100%',
                      height: '40px',
                      borderRadius: '10px',
                      backgroundColor: '#F7F7F7',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: COLORS[index % COLORS.length],
                        borderRadius: '10px',
                      },
                    }}
                  />
                </Tooltip>
                <h4
                  style={{
                    marginLeft: '10px',
                    flex: '0 0 65px',
                    textAlign: 'right',
                  }}
                >
                  {value}%
                </h4>
              </div>
            </div>
          ))}
        </Box>
      )}
    </Paper>
  )
}

export default FrequentlyUsedComponentsCard
