/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Field, Form, Formik, FieldArray } from 'formik'
import * as yup from 'yup'
import {
  Box,
  Button,
  Grid,
  SvgIcon,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import {
  FormMediaField,
  FormTextField,
  FormSelectSpeaker,
} from '../../../FormComponents'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import moment from 'moment'
import { Close } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  useLinkMentorsToMentorshipMutation,
  useGenerateSlotsMutation,
  useRemoveMentorsFromMentorshipMutation,
} from '../../../../../api/mentorship'
import FormForEditingSessions from './FormForEditingSessions'

let mentorSchema = yup.object().shape({
  name: yup.array().required(),
  designation: yup.string().required(),
  company: yup.string().required(),
  email: yup.string().required(),
  description: yup.string().required(),
  engagement_image: yup.string().required(),
  sessions: yup.array().of(
    yup.object().shape({
      start_time: yup
        .date()
        .typeError('Enter a valid time')
        .nullable()
        .required('Start time is a required field'),
      end_time: yup
        .date()
        .typeError('Enter a valid time')
        .nullable()
        .required('End time is a required field'),
      time_slot: yup.number().required('Time slot is required'),
      break_time: yup.number().required('Break time is required'),
    }),
  ),
})

const DEFAULT_VALUES = {
  name: [],
  designation: '',
  company: '',
  email: '',
  description: '',
  engagement_image: '',
  sessions: [
    {
      start_time: '',
      end_time: '',
      time_slot: '',
      break_time: '',
    },
  ],
}

const FormForEditingMentors = (props) => {
  const {
    mentorsToAdd,
    setMentorsToAdd,
    mentorshipId,
    eventId,
    mentorsForDropDown,
    mentorsListData,
    mentorsLoading,
    mentorshipData,
    speakersSavedList,
    setSpeakersSavedList,
  } = props

  const [expanded, setExpanded] = useState(0)
  const [sessionIndex, setSessionIndex] = useState(0)
  const [speakerRemoveDialog, setSpeakerRemoveDialog] = useState(false)
  const [speakerIdTobeRemoved, setSpeakerIdTobeRemoved] = useState('')
  const [mentorIndex, setMentorIndex] = useState(0)

  const [slotsDataRes, setSlotsRes] = useState([])
  const navigate = useNavigate()

  const activeSite = useSelector((state) => state.tenant.activeSite)

  const handleChange = (mentor, index, values) => (event, newExpanded) => {
    setExpanded(newExpanded ? index : '')
    setMentorIndex(index)
    values['mentorIndexValue'] = index
  }

  const handleClose = () => {
    setSpeakerRemoveDialog(false)
  }

  const [
    generateSlotsForMentorship,
    { isLoading: addingSessionLoading, data: slotsData },
  ] = useGenerateSlotsMutation()

  const [
    linkMentorToMentorship,
    { data: savedSpeakerData, isLoading, isSuccess },
  ] = useLinkMentorsToMentorshipMutation()

  const [removeMentorsFromMentorship, { isLoading: removeMentorsLoading }] =
    useRemoveMentorsFromMentorshipMutation()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (slotsData) {
      setSlotsRes(slotsData)
    }
  }, [slotsData])

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Mentor Edited successfully!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: (key) => (
          <>
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Close></Close>
            </IconButton>
          </>
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, isSuccess, navigate])

  useEffect(() => {
    if (savedSpeakerData) {
      let list = []
      list = [...speakersSavedList]
      list.push({ id: savedSpeakerData.data.member })
      setSpeakersSavedList(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSpeakerData])

  const generateSlots = (session, index, parentIndex, values) => {
    setSessionIndex(index)
    setMentorIndex(parentIndex)
    values['mentorIndexValue'] = parentIndex
    let obj = { ...session }
    obj.start_time = moment(session.start_time).format('HH:mm')
    obj.end_time = moment(session.end_time).format('HH:mm')
    generateSlotsForMentorship({
      sessionData: [{ ...obj }],
      eventId: eventId,
    })
  }

  const onAddMentor = () => {
    const temp = [...mentorsToAdd]
    temp.push({})
    setMentorIndex(temp.length - 1)
    // temp.push(`mentor${temp.length + 1}`)
    setMentorsToAdd(temp)
  }

  const onMentorRemove = (index, mentorToRemove) => {
    const temp = [...mentorsToAdd]
    temp.splice(index, 1)
    setMentorsToAdd(temp)
    const tempSaved = [...speakersSavedList]
    tempSaved.splice(index, 1)
    setSpeakersSavedList(tempSaved)
    if (mentorToRemove) {
      var ids = []
      ids.push(mentorToRemove)
      removeMentorsFromMentorship({
        ids: ids,
        mentorship_id: mentorshipId,
        eventid: eventId,
        activeSite,
      })
    }
  }

  return (
    <Box
      sx={{
        p: 1,
        pl: 3,
        display: 'flex',
        mt: 6.5,
      }}
    >
      <Grid container spacing={1} sx={{ display: 'flex' }}>
        {/* Right Section */}

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: 20, fontWeight: 700, mb: 1, flexGrow: 1 }}
            >
              Mentor Detail Info
            </Typography>
            <Button
              size="small"
              variant="outlined"
              startIcon={<SvgIcon component={AddIcon} />}
              color="secondary"
              sx={{
                textTransform: 'none',
                height: 35,
                mr: 1,
              }}
              onClick={() => {
                setExpanded(mentorsToAdd?.length)
                onAddMentor()
              }}
            >
              {'Add Mentor'}
            </Button>
          </Box>
          <Box
            sx={{
              border: '2px dashed #B5B5B5',
              p: 2,
            }}
          >
            {(isLoading || removeMentorsLoading || addingSessionLoading) && (
              <LinearProgress indeterminate />
            )}
            {mentorsToAdd &&
              mentorsToAdd.length > 0 &&
              mentorsToAdd.map((item, index) => {
                return (
                  Object.keys(item) && (
                    <Formik
                      key={item?.id}
                      initialValues={{
                        ...DEFAULT_VALUES,
                        ...item,
                      }}
                      validationSchema={mentorSchema}
                      onSubmit={(values) => {
                        linkMentorToMentorship({
                          mentorData: {
                            sessions: values.sessions.map((item) => {
                              return {
                                ...item,
                                start_time: moment(item.start_time).format(
                                  'HH:mm',
                                ),
                                end_time: moment(item.end_time).format('HH:mm'),
                              }
                            }),
                            engagement_image: values.engagement_image,
                            description: values.description,
                            id: values.id,
                          },
                          mentorship_id: mentorshipId,
                          eventid: eventId,
                        })
                      }}
                    >
                      {({ values, setFieldTouched, errors, isValid }) => {
                        const selectedMentorId = values?.name?.[0]
                        const mentorData = mentorsListData?.[selectedMentorId]
                        const mentorName = mentorData?.user?.name
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              mb: 3,
                            }}
                          >
                            <Form className="formik-form">
                              <Accordion
                                expanded={expanded === index}
                                sx={{
                                  background: '#F6F7F8',
                                }}
                                onChange={handleChange(item, index, values)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography>
                                    {mentorName ? mentorName : 'New Speaker'}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box
                                    sx={{
                                      border: '2px dashed #B5B5B5',
                                      p: 2,
                                      background: '#F0F0F0',
                                    }}
                                  >
                                    <Grid
                                      container
                                      sx={{
                                        display: 'flex',
                                      }}
                                    >
                                      <Grid item xs={6}>
                                        {mentorsForDropDown &&
                                          !mentorsLoading &&
                                          mentorsForDropDown.length > 0 && (
                                            <Field
                                              type="text"
                                              name="name"
                                              label="Mentor Name*"
                                              disableFields={
                                                item.id ? true : false
                                              }
                                              component={FormSelectSpeaker}
                                              options={mentorsForDropDown.map(
                                                (item) => {
                                                  return {
                                                    label: item.name,
                                                    value: item.id,
                                                  }
                                                },
                                              )}
                                              multiple={false}
                                              speakerListData={mentorsListData}
                                              speakersSavedList={
                                                speakersSavedList
                                              }
                                              module={'mentorship'}
                                              speakerIndex={index}
                                              setSpeakersToAdd={setMentorsToAdd}
                                              speakersToAdd={mentorsToAdd}
                                            />
                                          )}
                                        {mentorsForDropDown &&
                                          mentorsForDropDown.length === 0 && (
                                            <Field
                                              type="text"
                                              name="name"
                                              label="Speaker Name*"
                                              component={FormSelectSpeaker}
                                              options={[]}
                                              disableFields={
                                                item.id ? true : false
                                              }
                                              multiple={false}
                                              speakerListData={[]}
                                              speakersSavedList={[]}
                                              module={'mentorship'}
                                              speakerIndex={index}
                                              setSpeakersToAdd={setMentorsToAdd}
                                              speakersToAdd={[]}
                                            />
                                          )}
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          type="text"
                                          name="designation"
                                          label="Designation*"
                                          placeholder="Designation"
                                          autoComplete="off"
                                          disableFields={true}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          type="text"
                                          name="company"
                                          label="Company*"
                                          placeholder="Company"
                                          autoComplete="off"
                                          disableFields={true}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Field
                                          type="text"
                                          name="email"
                                          label="Email ID*"
                                          placeholder="Email Id"
                                          autoComplete="off"
                                          disableFields={true}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                      <FieldArray
                                        name="sessions"
                                        render={(arrayHelpers) => {
                                          return (
                                            <FormForEditingSessions
                                              values={values}
                                              mentorIndex={mentorIndex}
                                              parentIndex={index}
                                              sessions={values.sessions}
                                              arrayHelpers={arrayHelpers}
                                              sessionIndex={sessionIndex}
                                              generateSlots={generateSlots}
                                              slotsData={slotsDataRes}
                                              setSlotsRes={setSlotsRes}
                                              setSessionIndex={setSessionIndex}
                                              setFieldTouched={(name, bool) => {
                                                setFieldTouched(name, bool)
                                              }}
                                              errors={errors}
                                              startTimeVal={
                                                mentorshipData?.start_time
                                              }
                                              endTimeVal={
                                                mentorshipData?.end_time
                                              }
                                              bookingsConfirmed={item.confirmed}
                                            ></FormForEditingSessions>
                                          )
                                        }}
                                      ></FieldArray>
                                      <Grid item xs={6}>
                                        <span
                                          style={{
                                            color: 'rgba(0, 0, 0, 0.6)',
                                            marginLeft: '20px',
                                            fontSize: '12px',
                                          }}
                                        >
                                          Mentor image thumbnail*
                                        </span>

                                        <Grid item xs={12}>
                                          <Field
                                            name="engagement_image"
                                            label="Drop file or click to upload"
                                            maxHeight={108}
                                            component={FormMediaField}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={6} sx={{ mt: 3 }}>
                                        <Field
                                          type="text"
                                          name="description"
                                          label="Description"
                                          placeholder="Enter description"
                                          autoComplete="off"
                                          multiline
                                          rows={4}
                                          component={FormTextField}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box
                                    sx={{
                                      background: '#F6F7F8',
                                      p: 1,
                                      display: 'flex',
                                      flexDirection: 'row-reverse',
                                      gap: '20px',
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      sx={{
                                        textTransform: 'none',
                                        height: 35,
                                        color: '#cc0000',
                                      }}
                                      type="submit"
                                      className="btn btn-primary btn-block mt-4"
                                    >
                                      Save
                                    </Button>

                                    <Button
                                      variant="text"
                                      sx={{
                                        textTransform: 'none',
                                        color: '#000',
                                      }}
                                      type="button"
                                      disabled={item.confirmed > 0}
                                      className="btn btn-primary btn-block mt-4"
                                      onClick={() => {
                                        setSpeakerRemoveDialog(true)
                                        setSpeakerIdTobeRemoved(
                                          isValid ? values.id : '',
                                        )
                                        setExpanded('')
                                      }}
                                    >
                                      Remove
                                    </Button>
                                    <Dialog
                                      open={speakerRemoveDialog}
                                      onClose={handleClose}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogTitle id="alert-dialog-title">
                                        {'Are you sure want to remove?'}
                                      </DialogTitle>
                                      <DialogActions>
                                        <Button
                                          onClick={() => {
                                            onMentorRemove(
                                              mentorIndex,
                                              speakerIdTobeRemoved,
                                            )
                                            setSpeakerRemoveDialog(false)
                                          }}
                                        >
                                          Yes
                                        </Button>
                                        <Button onClick={handleClose} autoFocus>
                                          No
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Form>
                          </Box>
                        )
                      }}
                    </Formik>
                  )
                )
              })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FormForEditingMentors
