import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Radio,
} from '@mui/material'
import { formatDate, getSize } from '../../utils/helper'
import { Box } from '@mui/system'
const FileExistDialog = (props) => {
  const {
    open,
    close,
    oldData,
    keepBothAction,
    replaceAction,
    closeFileExistDialog,
  } = props

  const [selectedFiles, setSelectedFiles] = React.useState(null)
  const [singlePicked, setSinglePicked] = React.useState(undefined)

  React.useEffect(() => {
    return () => {
      setSinglePicked(undefined)
    }
  }, [])
  return (
    <Dialog
      open={open}
      onClose={close}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Warning!</DialogTitle>
      <DialogContent dividers={true} sx={{ height: '350px', width: '500px' }}>
        <Typography sx={{ mb: '30px' }}>
          The file you are trying to upload already exists.
        </Typography>
        {oldData?.map((item, index) => {
          return (
            <div
              key={`item- ${item?.name}-${index}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                overflow: 'scroll',
              }}
            >
              {oldData?.length > 1 && (
                <Radio
                  aria-label={`${item.name}`}
                  checked={singlePicked === item.id}
                  onChange={(e) => {
                    setSinglePicked(e.target.value)
                    setSelectedFiles(item?.id)
                  }}
                  value={item.id}
                  name="radio-buttons"
                  inputProps={{ 'aria-label': `${item.name}` }}
                  onKeyDown={(e) => {
                    setSinglePicked(e.target.value)
                    setSelectedFiles(item?.id)
                  }}
                  sx={{
                    width: 25,
                    height: 25,
                    cursor: 'pointer',
                    margin: '0px 15px',
                    ':focus': { border: '2px dashed #666666' },
                  }}
                />
              )}
              <Box
                key={`duplicate_file_${0}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                }}
              >
                <div style={{ marginBottom: '10px' }}>
                  File name :{' '}
                  <span
                    style={{ fontWeight: 'bolder', color: 'black' }}
                  >{`${item?.name}`}</span>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  Uploaded on : <span>{`${formatDate(item?.createdAt)}`}</span>
                </div>
                <div style={{ marginBottom: '30px' }}>
                  Size :{' '}
                  <span>{`${item?.size ? getSize(item?.size) : 'NA'}`}</span>
                </div>
              </Box>
            </div>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button
          disableFocusRipple
          onClick={keepBothAction}
          sx={{
            color: '#2E68FB',
            ':hover': { backgroundColor: 'transparent' },
            ':focus': { border: '2px dashed #666666' },
          }}
        >
          keep both
        </Button>
        <Button
          disableFocusRipple
          onClick={() => {
            replaceAction(oldData?.length <= 1 ? oldData[0].id : selectedFiles)
          }}
          sx={{
            color: '#2E68FB',
            ':hover': { backgroundColor: 'transparent' },
            ':focus': { border: '2px dashed #666666' },
          }}
          disabled={oldData?.length > 1 && selectedFiles === null}
        >
          replace
        </Button>
        <Button
          disableFocusRipple
          onClick={closeFileExistDialog}
          sx={{
            color: '#2E68FB',
            ':hover': { backgroundColor: 'transparent' },
            ':focus': { border: '2px dashed #666666' },
          }}
        >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FileExistDialog
