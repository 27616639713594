import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { Box, Divider, Typography, Button, Grid } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { FormDateField, FormTextField } from '../../../FormComponents'
import moment from 'moment'

const FormForAddingSessions = (props) => {
  const {
    values,
    mentorIndex,
    parentIndex,
    disableFields,
    sessions,
    arrayHelpers,
    generateSlots,
    setSessionIndex,
    sessionIndex,
    slotsData,
    setSlotsRes,
    startTimeVal,
    endTimeVal,
    setFieldTouched,
    errors,
  } = props

  if (mentorIndex === values['mentorIndexValue']) {
    sessions.map((item, index) => {
      if (index === sessionIndex && slotsData?.data?.length > 0) {
        sessions[index] = { ...item, slotData: slotsData?.data?.length }
        setSlotsRes([])
      }
      return sessions
    })
  }

  const removeSessions = (index) => {
    arrayHelpers.remove(index)
    sessions[index].slotData = ''
  }

  const validateTime = (value) => {
    let error = ''
    let startTime = moment(startTimeVal, ['h:mm:ss']).toDate()
    let endTime = moment(endTimeVal, ['h:mm:ss']).add(1, 'second').toDate()
    if (
      moment(value).isSameOrAfter(startTime) &&
      moment(value).isSameOrBefore(endTime)
    ) {
      return error
    }
    error = 'Time slots are not between mentorship session timings'
    return error
  }

  const validateStartTime = (value) => {
    validateTime(value)
  }

  const validateEndTime = (value) => {
    validateTime(value)
  }

  const validateSessionTimeFields = (session) => {
    setFieldTouched(`${session}.start_time`, true)
    setFieldTouched(`${session}.end_time`, true)
    setFieldTouched(`${session}.time_slot`, true)
    setFieldTouched(`${session}.break_time`, true)
  }

  const checkErrors = (errors, index) => {
    let error = false
    if (errors.sessions && errors.sessions[index]) {
      Object.keys(errors.sessions[index]).map((item) => {
        if (errors.sessions[index][item] !== '') {
          error = true
        }
        return error
      })
    }
    if (!error) {
      generateSlots(sessions[index], index, parentIndex, values)
    }
  }

  return (
    <Box
      sx={{
        margin: '20px',
        background: '#F5F5F5',
        boxShadow: '0px 0px 3px 0px #888888',
      }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>Add Time Slots</Typography>
        <Button
          sx={{
            textTransform: 'none',
            height: 35,
          }}
          variant="contained"
          disabled={disableFields}
          color="secondary"
          type="button"
          className="btn btn-primary btn-block mt-4"
          onClick={() => {
            setSessionIndex('')
            arrayHelpers.push({
              start_time: '',
              end_time: '',
              time_slot: '',
              break_time: '',
            })
          }}
        >
          Add
        </Button>
      </Box>
      <Divider></Divider>
      {sessions &&
        sessions.length > 0 &&
        sessions.map((session, index) => {
          return (
            <>
              <Grid container key={index}>
                <Grid item xs={6}>
                  <Field
                    name={`sessions.${index}.start_time`}
                    placeholder="Select session start time"
                    validate={validateStartTime}
                  >
                    {({ field, form, meta, errors }) => (
                      <FormDateField
                        {...field}
                        {...form}
                        type="time"
                        label="Session start time*"
                        disableFields={disableFields}
                        onChange={(value) => {
                          form
                            .setFieldValue(
                              `sessions.${index}.start_time`,
                              moment(value).toDate(),
                            )
                            .then(() => {
                              form.setFieldTouched(
                                `sessions.${index}.start_time`,
                                true,
                              )
                            })
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name={`sessions.${index}.end_time`}
                    placeholder="Select session end time"
                    validate={validateEndTime}
                  >
                    {({ field, form, meta }) => (
                      <FormDateField
                        {...field}
                        {...form}
                        type="time"
                        label="Session end time*"
                        disableFields={disableFields}
                        onChange={(value) => {
                          form
                            .setFieldValue(
                              `sessions.${index}.end_time`,
                              moment(value).toDate(),
                            )
                            .then(() => {
                              form.setFieldTouched(
                                `sessions.${index}.end_time`,
                                true,
                              )
                            })
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    type="number"
                    name={`sessions.${index}.time_slot`}
                    label="Set time slot for each session*"
                    placeholder="Enter time slot"
                    autoComplete="off"
                    component={FormTextField}
                    disableFields={disableFields}
                    showMins={true}
                  />
                  <ErrorMessage
                    component="p"
                    style={{
                      color: '#d32f2f',
                      fontSize: 12,
                      paddingLeft: '24px',
                      marginTop: '-18px',
                    }}
                    name={`sessions.${index}.time_slot`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    type="number"
                    name={`sessions.${index}.break_time`}
                    label="Set break time between each session*"
                    placeholder="Enter break time"
                    autoComplete="off"
                    component={FormTextField}
                    disableFields={disableFields}
                    showMins={true}
                  />
                  <ErrorMessage
                    component="p"
                    style={{
                      color: '#d32f2f',
                      fontSize: 12,
                      marginTop: '-18px',
                      paddingLeft: '24px',
                    }}
                    name={`sessions.${index}.break_time`}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: sessions[index].slotData
                    ? 'space-between'
                    : 'flex-end',
                  mx: 3,
                  mb: 2,
                }}
              >
                {sessions[index].slotData && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexGrow: 1,
                      color: '#666',
                      gap: '5px',
                    }}
                  >
                    {' '}
                    <InfoOutlinedIcon sx={{ color: '#888' }}></InfoOutlinedIcon>
                    <span>
                      {sessions[index].slotData} slots have been created
                    </span>
                  </Box>
                )}
                <Button
                  variant="text"
                  disabled={disableFields}
                  sx={{
                    textTransform: 'none',
                    height: 35,
                    color: '#cc0000',
                  }}
                  onClick={() => {
                    validateSessionTimeFields(`sessions.${index}`)
                    checkErrors(errors, index, parentIndex, values)
                  }}
                  type="button"
                  className="btn btn-primary btn-block mt-4"
                >
                  Save
                </Button>
                {index > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                    onClick={() => removeSessions(index)}
                  >
                    <Button
                      variant="text"
                      disabled={disableFields}
                      sx={{
                        textTransform: 'none',
                      }}
                      color="secondary"
                      type="button"
                      className="btn btn-primary btn-block mt-4"
                    >
                      Remove
                    </Button>
                  </Box>
                )}
              </Box>
              <Divider></Divider>
            </>
          )
        })}
    </Box>
  )
}

export default FormForAddingSessions
