import React from 'react'
import PropTypes from 'prop-types'
import { Box, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'

export const Format = (props) => {
  const {
    value,
    onChange,
    disabled = false,
    slug = null,
    helperText = '',
  } = props
  const [formatState, setFormatState] = React.useState([])

  React.useEffect(() => {
    const formats = []
    if (value) {
      if (value.fontWeight && value.fontWeight === 'bold') {
        formats.push('bold')
      }
      if (value.fontStyle && value.fontStyle === 'italic') {
        formats.push('italic')
      }
      if (value.textDecoration && value.textDecoration === 'underline') {
        formats.push('underline')
      }
    }
    setFormatState(formats)
  }, [value])

  const handleFormat = (_, vals) => {
    const updated = {
      fontWeight: vals.includes('bold') ? 'bold' : 'normal',
      fontStyle: vals.includes('italic') ? 'italic' : 'normal',
      textDecoration: vals.includes('underline') ? 'underline' : 'none',
    }
    onChange(updated, slug)
  }

  return (
    <Box sx={{ height: '100%', cursor: disabled ? 'not-allowed' : 'inherit' }}>
      <Tooltip arrow placement={'top'} title={helperText} followCursor>
        <ToggleButtonGroup
          value={formatState}
          size={'small'}
          onChange={handleFormat}
          aria-label="text formatting"
          disabled={disabled}
          fullWidth
          sx={{ height: '100%' }}
          data-testid="format-comp"
        >
          <ToggleButton
            value="bold"
            aria-label="bold"
            data-testid="format-bold"
          >
            <FormatBoldIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton
            value="italic"
            aria-label="italic"
            data-testid="format-italic"
          >
            <FormatItalicIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton
            value="underline"
            aria-label="underline"
            data-testid="format-underline"
          >
            <FormatUnderlinedIcon fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  )
}

Format.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  slug: PropTypes.string,
  helperText: PropTypes.string,
}
export default Format
